<div id="content_leave_application">
    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'inouttimeapplication.inouttime' | translate }}</span>
    </div>

    <!-- =========== Search ========== -->
    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_application"></div>
            <div class="fl">{{ 'inouttimeapplication.application' | translate }}</div>
        </div>

        <div class="box_content_inquiry"
            *ngIf='inOutTimeApplication && staffPunchDetailList && rawPunchDetailList && selectedShiftDetail'>
            <br /> <a name="list_type" class="list_bar_mt"></a>
            <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplication.date' | translate }}</div>
            <div class="fl input_new_calendar">
                <div>
                    <mat-form-field appearance='fill' (click)="startPicker.open()">
                        <input matInput [matDatepicker]="startPicker" [(ngModel)]="Date" [min]='minDate' [max]='maxDate'
                            readonly (dateInput)="getShiftDetailList()" name='sPicker'>
                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                        <mat-datepicker #startPicker (opened)="openAppend('start')"></mat-datepicker>
                    </mat-form-field>
                    <div style="display: none">
                        <div class="datepicker-footer" #startFooter>
                            <button mat-raised-button (click)="today('start')" #todayButton>
                                {{'TODAY' | translate}}
                            </button>
                            <button mat-raised-button (click)="close('start')" #doneButton>
                                {{'close' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fl input_field_large btn_two_margin_left_m">
                <button id="btn_check_punch" class="btn btn-block btn-lg btn_grey" (click)="checkPunchRecords()"><img
                        src="assets/img/btn_search_a.png" width="18" />{{ 'inouttimeapplication.checkpunchrecords' |
                    translate }}</button>
            </div>
            <div style="clear: both"></div><br />


            <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplication.shift' | translate }}</div>
            <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="inOutTimeApplication.ShiftCode"
                (change)="UpdateSelectedShiftDetail()">
                <option *ngFor="let shiftDetail of shiftDetailList" value="{{shiftDetail.Code}}">{{shiftDetail.Code}}
                </option>
            </select>
            <textarea id="ProjectDescription" name="ProjectDescription"
                class="form-control detail_staff_remark_input txt_box_1 txt_size_2" type="text"
                readonly>{{ commonUI.formatDisplayDate(selectedShiftDetail.ShiftInTime, 'h:mm a') }} - {{ commonUI.formatDisplayDate(selectedShiftDetail.ShiftOutTime, 'h:mm a') }} ({{selectedShiftDetail.Desc}})</textarea>
            <div style="clear: both"></div><br />

            <mat-tab-group>
                <mat-tab label="{{ 'inouttimeapplication.currentpunch' | translate }}" style='height: 150px;'>
                    <div class="txt_box_1_mod txt_grey_1 txt_size_2">

                        <div class="col col3_n_btn1 m-0 p-0">
                            <div>
                                <div>{{ 'inouttimeapplication.time' | translate }}</div>
                                <div>{{ 'inouttimeapplication.detail' | translate }}</div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>

                            <div *ngFor="let staffPunchDetail of staffPunchDetailList">
                                <div>
                                    {{ commonUI.formatDisplayDate(staffPunchDetail.PunchDateTime, 'h:mm A') }}
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{
                                        commonUI.formatDisplayDate(staffPunchDetail.PunchDateTime, 'D MMM YYYY (ddd)')
                                        }}</span>
                                </div>
                                <div>
                                    <div *ngIf="staffPunchDetail.Status == 'I'">
                                        <span class="txt_label bc_blue txt_size_3">{{ 'inouttimeapplication.intime' |
                                            translate }}</span>
                                    </div>
                                    <div *ngIf="staffPunchDetail.Status == 'O'">
                                        <span class="txt_label bc_green txt_size_3">{{ 'inouttimeapplication.outtime' |
                                            translate }}</span>
                                    </div>
                                    <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplication.site' | translate
                                        }}</span>
                                    {{ staffPunchDetail.PunchLocation }}
                                </div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{ 'inouttimeapplication.rawpunch' | translate }}">
                    <div class="txt_box_1_mod txt_grey_1 txt_size_2">

                        <div class="col col3_n_btn1 m-0 p-0">
                            <div>
                                <div>{{ 'inouttimeapplication.time' | translate }}</div>
                                <div>{{ 'inouttimeapplication.detail' | translate }}</div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>

                            <div *ngFor="let rawPunchDetail of rawPunchDetailList">
                                <div>
                                    {{ commonUI.formatDisplayDate(rawPunchDetail.PunchDateTime, 'h:mm A') }}
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{
                                        commonUI.formatDisplayDate(rawPunchDetail.PunchDateTime, 'D MMM YYYY (ddd)')
                                        }}</span>
                                </div>
                                <div>
                                    <div *ngIf="rawPunchDetail.Status == 'I'">
                                        <span class="txt_label bc_blue txt_size_3">{{ 'inouttimeapplication.intime' |
                                            translate }}</span>
                                    </div>
                                    <div *ngIf="rawPunchDetail.Status == 'O'">
                                        <span class="txt_label bc_green txt_size_3">{{ 'inouttimeapplication.outtime' |
                                            translate }}</span>
                                    </div>
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplication.site' | translate
                                        }}</span>
                                    {{ rawPunchDetail.PunchLocation }}
                                </div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>

                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <!-- <div id="check_punch" class="remark_tabs normal">
                <div class="tab first">
                    <input type="radio" id="tab-r1" name="tab-group-1" checked="">
                    <label for="tab-r1">{{ 'inouttimeapplication.currentpunch' | translate }}</label>

                    <div class="content txt_box_1 txt_grey_1 txt_size_2">

                        <div class="col col3_n_btn1">
                            <div>
                                <div>{{ 'inouttimeapplication.time' | translate }}</div>
                                <div>{{ 'inouttimeapplication.detail' | translate }}</div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>

                            <div *ngFor="let staffPunchDetail of staffPunchDetailList">
                                <div>
                                    {{ commonUI.formatDisplayDate(staffPunchDetail.PunchDateTime, 'h:mm A') }}
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(staffPunchDetail.PunchDateTime, 'D MMM YYYY (ddd)') }}</span>
                                </div>
                                <div>
                                    <div *ngIf="staffPunchDetail.Status == 'I'">
                                        <span class="txt_label bc_blue txt_size_3">{{ 'inouttimeapplication.intime' | translate }}</span>
                                    <div>
                                    <div *ngIf="staffPunchDetail.Status == 'O'">
                                        <span class="txt_label bc_green txt_size_3">{{ 'inouttimeapplication.outtime' | translate }}</span>
                                    </div>
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplication.site' | translate }}</span>
                                    {{ staffPunchDetail.PunchLocation }}
                                </div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="tab">
                    <input type="radio" id="tab-r2" name="tab-group-1">
                    <label for="tab-r2">{{ 'inouttimeapplication.rawpunch' | translate }}</label>

                    <div class="content txt_box_1 txt_grey_1 txt_size_2">

                        <div class="col col3_n_btn1">
                            <div>
                                <div>{{ 'inouttimeapplication.time' | translate }}</div>
                                <div>{{ 'inouttimeapplication.detail' | translate }}</div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>

                            <div *ngFor="let rawPunchDetail of rawPunchDetailList">
                                <div>
                                    {{ commonUI.formatDisplayDate(rawPunchDetail.PunchDateTime, 'h:mm A') }}
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(rawPunchDetail.PunchDateTime, 'D MMM YYYY (ddd)') }}</span>
                                </div>
                                <div>
                                    <div *ngIf="rawPunchDetail.Status == 'I'">
                                        <span class="txt_label bc_blue txt_size_3">{{ 'inouttimeapplication.intime' | translate }}</span>
                                    </div>
                                    <div *ngIf="rawPunchDetail.Status == 'O'">
                                        <span class="txt_label bc_green txt_size_3">{{ 'inouttimeapplication.outtime' | translate }}</span>
                                    </div>
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplication.site' | translate }}</span>
                                    {{ rawPunchDetail.PunchLocation }}
                                </div>
                                <div></div>
                                <div style="clear:both; float:none"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> -->





            <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplication.adjustedpunch' | translate }}</div>
            <div class="col col3_n_btn1 m-0 p-0">
                <div class="maincolor_bg2">
                    <div>{{ 'inouttimeapplication.time' | translate }}</div>
                    <div>{{ 'inouttimeapplication.site' | translate }}</div>
                    <div></div>
                    <div style="clear:both; float:none"></div>
                </div>

                <div *ngFor="let adjustedPunchDetail of adjustedPunchDetailList; let i = index;">
                    <div>
                        {{ commonUI.formatDisplayDate(adjustedPunchDetail.PunchDateTime, 'h:mm A') }}
                        <br>
                        <span class="txt_size_3 txt_grey_2">{{
                            commonUI.formatDisplayDate(adjustedPunchDetail.PunchDateTime, 'D MMM YYYY (ddd)') }}</span>
                    </div>
                    <div>
                        <div *ngIf="adjustedPunchDetail.Status == 'I'">
                            <span class="txt_label bc_blue txt_size_3">{{ 'inouttimeapplication.intime' | translate
                                }}</span>
                        </div>
                        <div *ngIf="adjustedPunchDetail.Status == 'O'">
                            <span class="txt_label bc_green txt_size_3">{{ 'inouttimeapplication.outtime' | translate
                                }}</span>
                        </div>
                        <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplication.site' | translate }}</span>
                        {{ adjustedPunchDetail.PunchLocation }}
                    </div>
                    <div class="btn_org pp_contact" title="{{ 'inouttimeapplication.edit' | translate }}"
                        (click)="showEditPunchPopup(adjustedPunchDetail, i)"><img src="assets/img/btn_edit_inf.png"
                            width="18"></div>
                    <div style="clear:both; float:none"></div>
                </div>

            </div>

            <br>

            <div class="inquiry_box_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 pp_contact" style="min-width: 140px;" type="submit" value="submit"
                    (click)="showEditPunchPopup()"><img src="assets/img/btn_addform_w.png" width="18">{{
                    'inouttimeapplication.addpunch' | translate }}</button>
                <div style="clear:both;"></div>
            </div>

            <br>

            <div class="form_content_input_field">
                <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'inouttimeapplication.description' | translate
                    }}</span><br>
                <textarea class="form-control detail_staff_remark_input txt_box_1 txt_size_2"
                    style="max-width: 100% !important;" [(ngModel)]="inOutTimeApplication.Description"></textarea>
            </div>


            <div class="mult_select_approval_area" style="display: block;">
                <div class="pop_functions_two_btn_center">
                    <div class="header_bar_icon fl list_icon_border add m_hide pp_contact" title="Add Punch"><img alt=""
                            src="assets/img/btn_addform_g.png" width="20"></div>
                    <button class="btn btn-block btn-lg two_btn maincolor_btn2" type="submit" value="submit"
                        [disabled]="noAttendance" (click)="submitEInOutApplication()"><img
                            src="assets/img/btn_tick_w.png" width="18" />{{ 'inouttimeapplication.submit' | translate
                        }}</button>
                    <div class="btn btn-block btn-lg two_btn btn_grey" (click)="resetApplication()"><img
                            src="assets/img/btn_reset_a.png" width="18">{{ 'inouttimeapplication.reset' | translate }}
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>

        </div>
    </div>
    <!-- end id=content_box -->

    <!-- <in-out-time-edit-popup popupcontrol="inOutTimeEditPopupControl"
                              popupmode="inOutTimePopMode"
                              popupid="inOutTimePopUp"
                              edit-popupid="inOutTimeEditPopUp"
                              edit-punch-event="editPunchRecord()"
                              delete-punch-event="deletePunchRecord()">
    </in-out-time-edit-popup> -->

</div>