import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';

@Component({
  selector: 'app-popup-message-with-callback',
  templateUrl: './popup-message-with-callback.component.html',
  styleUrls: ['./popup-message-with-callback.component.scss']
})
export class PopupMessageWithCallbackComponent implements OnInit {

  msgcode: string
  reload = false

  constructor(public matDialogRef: MatDialogRef<PopupMessageWithCallbackComponent>, public commonUI: CommonUIService, @Inject(MAT_DIALOG_DATA) public code: string) { 
    this.msgcode = code
    if(code == 'error.undefined') this.reload = true
  }

  ngOnInit(): void {
  }

  close() {
    try{
      this.matDialogRef.close(this.reload)
    } catch {}
  }

}
