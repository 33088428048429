import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ApprovalService } from 'src/app/services/approval.service';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-popup-delegation-delete',
  templateUrl: './popup-delegation-delete.component.html',
  styleUrls: ['./popup-delegation-delete.component.scss']
})
export class PopupDelegationDeleteComponent implements OnInit {

  settings = [];
  selectedIndex;
  employee = JSON.parse(sessionStorage.Employee);
  confirm = false;
  currentSetting;

  constructor(public matDialogRef: MatDialogRef<PopupDelegationDeleteComponent>, public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data, public commonUI: CommonUIService, public commonService: CommonServiceService, public approvalService: ApprovalService) { 
    this.currentSetting = data['currentSetting']
    this.selectedIndex = data['selectedIndex']
    this.settings = data['settings']
  }

  ngOnInit(): void {
  }

  deleteDelegationSetting() {
    this.settings.splice(this.selectedIndex, 1);
    this.commonUI.addLoadingTask();
    this.approvalService.submitDelegationSettings(this.employee, this.settings).subscribe((result) => {
      
      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.settings = result.body['Data'].DelegationSettings;
          if (this.settings != undefined) {
              for (var i = 0; i < this.settings.length; i++) this.settings[i].index = i;
          }
          this.confirm = true
          this.commonUI.finishLoadingTask();
          this.close()
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
}

  close() {
    const returnVal = {
      confirm: this.confirm
    }
    try {
      this.matDialogRef.close(returnVal) 
    } catch {}
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
