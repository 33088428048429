import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupAppointmentDetailComponent } from '../common/popup-appointment-detail/popup-appointment-detail.component';
import { PopupInOutDetailComponent } from '../common/popup-in-out-detail/popup-in-out-detail.component';
import { PopupLeaveDetailComponent } from '../common/popup-leave-detail/popup-leave-detail.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ApprovalService } from '../services/approval.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';

@Component({
  selector: 'app-delegation-pending-approval',
  templateUrl: './delegation-pending-approval.component.html',
  styleUrls: ['./delegation-pending-approval.component.scss']
})
export class DelegationPendingApprovalComponent implements OnInit {

  multiMode = false;
  swipeMode = false;
  showSortsMenu = false;
  hasSwiped = false;
  allSelected = false;

  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  staffInfo = {};

  search = {
    keywords: '',
    SourceType: '',
  }

  objList = [];
  resultList = [];
  leaveListCount = 0;
  leaveCancelListCount = 0;
  appointmentListCount = 0;
  claimListCount = 0;

  sourceTypeList = this.commonService.SourceTypeList.slice();
  startDate = "";
  endDate = "";

  selectedIndex = -1;
  selectedLeaveApplication;
  reason = "";
  remarkPrefixApproved = "[Approved] ";
  remarkPrefixRejected = "[Rejected] ";
  leavePopMode = "APPROVAL";
  leavePopupControl = {};
  approverRemarkList = {};
  appointmentPopMode = "APPROVAL";
  appointmentPopupControl = {};

  reverse;
  propertyName = 'id';
  queryType = ''

  constructor(public idleTimer: IdleTimerService, public commonService: CommonServiceService, public commonUI: CommonUIService, public approvalService: ApprovalService, public matDialog: MatDialog, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.route.queryParams.subscribe((data) => {
      if (data.source == undefined || data.source == null) this.search.SourceType = 'ELEAVE';
      else {
        this.search.SourceType = data.source
      }
    })
    this.getModule();
    this.getStaffInfo();
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.resetSelectedAll(this.allSelected);
  }

  resetSelectedAll(trueOrFalse) {
    for (var i = 0; i < this.objList.length; i++) {
      this.objList[i].checked = trueOrFalse;
    }
  }

  multiModeToggle() {
    if (!this.multiMode) {
      this.multiMode = true;
      // turn off swipeMode
      this.swipeMode = false;
    } else {
      this.multiMode = false;
      this.allSelected = false;
      this.resetSelectedAll(false);
    }
  }

  showSortsMenuToggle() {
    if (!this.showSortsMenu) {
      this.showSortsMenu = true;
    } else {
      this.showSortsMenu = false;
    }
  }

  swipeModeEnable(objIndex) {
    this.hasSwiped = true;
    setTimeout(() => {
      this.hasSwiped = false;
    }, 1000);
    if (!this.multiMode) {
      this.swipeMode = true;
      // this.swipeTarget = id;
      this.selectedIndex = objIndex;
    }
  }
  swipeModeDisable() {
    this.hasSwiped = true;
    setTimeout(() => {
      this.hasSwiped = false;
    }, 1000);
    if (!this.multiMode) {
      this.swipeMode = false;
      this.selectedIndex = -1;
    }
  }

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch (propertyName) {
      case 'StaffInfo.FullName':
        this.objList = this.objList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'StaffInfo.PositionCode':
        this.objList = this.objList.sort((a, b) => {
          return a.StaffInfo.PositionCode.localeCompare(b.StaffInfo.PositionCode)
        })
        break;
      case 'StartDate':
        this.objList = this.objList.sort((a, b) => {
          return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime()
        })
        break;
      case 'LeaveTypeDesc':
        this.objList = this.objList.sort((a, b) => {
          return a.LeaveTypeDesc.localeCompare(b.LeaveTypeDesc)
        })
        break;
    }
    if (this.reverse) this.objList = this.objList.slice().reverse()
    this.showSortsMenu = false;
  }

  searchByInput() {
    if (this.search.keywords.length <= 0) return this.objList = this.resultList
    else this.objList = this.resultList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  getModule() {
    this.commonUI.addLoadingTask();
    this.commonService.getModule(this.employee).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          for (var i = 0; i < this.sourceTypeList.length; i++) {
            if (result.body['Data'].Module.indexOf(this.sourceTypeList[i].module) == -1) {
              this.sourceTypeList.splice(i, 1);
              i = -1;
            }
          }
          this.queryRecordList();
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getStaffInfo() {
    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfo(this.employee).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.staffInfo = result.body['Data'].StaffInfo;
          this.commonUI.finishLoadingTask();
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  queryRecordList() {
    this.objList = [];
    this.leaveListCount = 0;
    this.leaveCancelListCount = 0;
    this.appointmentListCount = 0;
    this.claimListCount = 0;
    //for (var i = 0; i < this.sourceTypeList.length; i++) {
    this.commonUI.addLoadingTask();
    /*
        if (!this.search.SourceType) {
            this.queryType = this.sourceTypeList[0].sourceTypeCode;
            this.search.SourceType = this.sourceTypeList[0].sourceTypeCode;
        }
    */
    this.approvalService.getDelegationPendingApprovalList(this.employee, "ELEAVE").subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.onGetLeavePendingSuccess(result.body['Data'].ApprovalList)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    //}
  }

  onGetLeavePendingSuccess(resultList) {


    if (resultList.length > 0) {
      if (resultList[0].SourceType == "ELEAVE") {
        this.leaveListCount = resultList.length;
      } else if (resultList[0].SourceType == "LEAVECAN") {
        this.leaveCancelListCount = resultList.length;
      } else if (resultList[0].SourceType == "APPOINTMENT") {
        this.appointmentListCount = resultList.length;
      } else if (resultList[0].SourceType == "CLAIMHDR") {
        this.claimListCount = resultList.length;
      }
    }

    resultList.forEach((record, key) => {
      if (record.AppointStart) record.StartDate = record.AppointStart;
      if (record.AppointEnd) record.EndDate = record.AppointEnd;
      if (record.Date) {
        record.StartDate = record.Date;
        record.EndDate = record.Date;
      }

      record.LeaveDateRange = this.commonUI.formatDisplayDate(record.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatDisplayDate(record.EndDate, 'D MMM YYYY (ddd)');
      record.keywords = "";
      record.keywords += record.LeaveDateRange + " ";
      record.keywords += record.LeaveTypeCode + " ";
      record.keywords += record.StaffInfo.FullName + " ";
      record.keywords += record.StaffInfo.PositionCode + " ";
      record.keywords += record.SourceType + " ";
      record.keywords += record.NumOfLeaveDay + " ";
    });

    this.resultList = [...resultList, ...this.resultList]

    for (var i = 0; i < this.resultList.length; i++) {
      this.resultList[i].index = i;
      this.resultList[i].checked = false;
    }
    if (resultList.length == 0) this.multiMode = false;
    this.objList = this.resultList;
    this.commonUI.finishLoadingTask();
  }

  showPopupLeaveDetail(obj) {
    this.selectedIndex = obj.index;
    if (!this.hasSwiped) {
      if (this.multiMode) {
        if (obj.checked === undefined || !obj.checked) obj.checked = true;
        else if (obj.checked) obj.checked = false;
      } else if (!this.swipeMode && !this.multiMode) {
        this.selectedLeaveApplication = obj;

        this.commonUI.addLoadingTask();
        // this.commonService.getApproverRemarkList(this.selectedLeaveApplication.RID, "ELEAVE", this.selectedLeaveApplication.StartDate, this.onGetApproverRemarkListSuccess, this.onCallAPIFail);
        var rid = this.selectedLeaveApplication.RID;
        this.commonService.getApproverRemarkList(rid, this.selectedLeaveApplication.SourceType, this.selectedLeaveApplication.StartDate).subscribe((result) => {

          if (!result['conn_fail'])
            if (result.body['ErrorMsg'] == null) {
              this.onGetApproverRemarkListSuccess(result.body['Data'].ApproverRemarkList)
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
      }
    }
  }

  onGetApproverRemarkListSuccess(remarkList) {
    this.approverRemarkList = this.commonService.getApproverRemarkTranslateList(remarkList);
    this.selectedLeaveApplication.showCutoff = false

    if (this.selectedLeaveApplication.SourceType == "ELEAVE") {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL',
          isDelegate: true
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "LEAVECAN") {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL',
          isDelegate: true
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "APPOINTMENT") {
      const dialog = this.matDialog.open(PopupAppointmentDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL',
          isDelegate: true
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "CLAIMHDR") {
      // this.claimListCount = resultList.length;
    } else if (this.selectedLeaveApplication.SourceType == "EINOUT") {
      const dialog = this.matDialog.open(PopupInOutDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL',
          isDelegate: true
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    }
    else {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL',
          isDelegate: true
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    }
    // this.leavePopupControl.showPopup(this.selectedLeaveApplication);
    this.commonUI.finishLoadingTask();
    this.reason = ''
  }

  doMultiApprove() {
    var approvalRecordList = [];
    for (var i = 0; i < this.objList.length; i++) {
      if (this.objList[i].checked) {
        if (this.objList[i].SourceType == "EINOUT") {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].Date
          };
        }
        else {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].StartDate
          };
        }
        if (this.search.SourceType == approvalRecord.SourceType) {
          approvalRecordList.push(approvalRecord);
        }
      }
    }

    if (approvalRecordList.length > 0) {
      this.commonUI.addLoadingTask();
      this.approvalService.delegationApproveRecord(this.employee, 'APV', this.remarkPrefixApproved, approvalRecordList).subscribe((result) => {

        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == null) {
            this.queryRecordList();
            this.swipeModeDisable();
            this.commonUI.finishLoadingTask();
            this.commonUI.showMsgBox("message.M0008");
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  doMultiReject() {
    var approvalRecordList = [];
    for (var i = 0; i < this.objList.length; i++) {
      if (this.objList[i].checked) {
        if (this.objList[i].SourceType == "EINOUT") {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].Date
          };
        }
        else {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].StartDate
          };
        }
        if (this.search.SourceType == approvalRecord.SourceType) {
          approvalRecordList.push(approvalRecord);
        }
      }
    }

    if (approvalRecordList.length > 0) {
      this.commonUI.addLoadingTask();
      this.approvalService.delegationApproveRecord(this.employee, 'REJ', this.remarkPrefixRejected, approvalRecordList).subscribe((result) => {

        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == null) {
            this.queryRecordList();
            this.swipeModeDisable();
            this.commonUI.finishLoadingTask();
            this.commonUI.showMsgBox("message.M0008");
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
