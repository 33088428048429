<div id="preview_setting" class="pp4_content">
    <div class="pop_functions_box pop_functions_box_size_1">
        <!--<div class="pop_functions_close btn_grey_2b pp_close">x</div>-->
        <div style="clear: both"></div>

        <div class="txt_size_1">

            <div class="list_1_box" style="width: 100%;margin: 0;border: none;">
                <div>
                    <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(currentSetting.StaffInfo) + ')'}">
                        <div class="txt_photo_mask_small p_normal"></div>
                    </div>

                    <div class="txt_content_area_2line txt_size_2">
                        <div class="txt_name txt_name_spaceforlabel">{{ currentSetting.StaffInfo.FullName }}</div>

                        <div style="clear:both;"></div>
                        <div class="txt_jobposition txt_grey_2 txt_size_3">{{ currentSetting.StaffInfo.PositionDesc }}</div>
                        <div class="txt_jobposition txt_grey_2 txt_size_3">{{ 'delegationsetting.delegated' | translate }}</div>
                        <div class="txt_date txt_grey_1 txt_size_3">
                            {{commonUI.formatDisplayDate(currentSetting.EffStartDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(currentSetting.EffStartDate, 'ddd')}})</span> - {{commonUI.formatDisplayDate(currentSetting.EffEndDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(currentSetting.EffEndDate, 'ddd')}})</span>
                        </div>
                        <div class="txt_jobposition txt_grey_2 txt_size_3">{{ 'delegationsetting.accessibleleave' | translate }}</div>
                        <div class="txt_date txt_grey_1 txt_size_3">
                            {{commonUI.formatDisplayDate(currentSetting.LeaveStartDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(currentSetting.LeaveStartDate, 'ddd')}})</span> - {{commonUI.formatDisplayDate(currentSetting.LeaveEndDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(currentSetting.LeaveEndDate, 'ddd')}})</span>
                        </div>
                    </div>

                    <div style="clear:both;"></div>
                </div>
            </div>

        </div>
        <div class="pop_functions_two_btn_center">
            <!--class="two_btn_fixed_bottom"-->
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish" (click)="submitDelegationSettings()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'yes' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'no' | translate }}</button>
            <div style="clear: both"></div>
        </div>
    </div>
</div>