import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentInquiryComponent } from './appointment-inquiry/appointment-inquiry.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { ApprovalCenterDelegationSettingsComponent } from './approval-center-delegation-settings/approval-center-delegation-settings.component';
import { ApprovalCenterOverrideApprovalModComponent } from './controllers_mod/approval-center-override-approval-mod/approval-center-override-approval-mod.component';
import { ApprovalCenterPendingApprovalModComponent } from './controllers_mod/approval-center-pending-approval-mod/approval-center-pending-approval-mod.component';
import { CalendarStaffLeaveComponent } from './calendar-staff-leave/calendar-staff-leave.component';
import { CalendarComponent } from './calendar/calendar.component';
import { EclaimInquiryComponent } from './eclaim-inquiry/eclaim-inquiry.component';
import { EclaimComponent } from './controllers_mod/eclaim/eclaim.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DelegationApplicationInquiryComponent } from './delegation-application-inquiry/delegation-application-inquiry.component';
import { DelegationBalanceInquiryComponent } from './delegation-balance-inquiry/delegation-balance-inquiry.component';
import { DelegationPendingApprovalComponent } from './delegation-pending-approval/delegation-pending-approval.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordACComponent } from './forgot-password-ac/forgot-password-ac.component';
import { HomeComponent } from './home/home.component';
import { InOutInquiryComponent } from './in-out-inquiry/in-out-inquiry.component';
import { InOutComponent } from './in-out/in-out.component';
import { LeaveApplicationInquiryComponent } from './leave-application-inquiry/leave-application-inquiry.component';
import { LeaveApplicationComponent } from './leave-application/leave-application.component';
import { LeaveBalanceInquiryComponent } from './leave-balance-inquiry/leave-balance-inquiry.component';
import { LoginComponent } from './login/login.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ProfileAmendInquiryComponent } from './profile-amend-inquiry/profile-amend-inquiry.component';
import { PunchInquiryComponent } from './punch-inquiry/punch-inquiry.component';
import { ReportLeaveBalanceComponent } from './report-leave-balance/report-leave-balance.component';
import { ReportPayslipComponent } from './report-payslip/report-payslip.component';
import { ReportTaxFormComponent } from './report-tax-form/report-tax-form.component';
import { ReportTaxSummaryComponent } from './report-tax-summary/report-tax-summary.component';
import { ServerMaintenanceComponent } from './server-maintenance/server-maintenance.component';
import { ReportEClaimComponent } from './controllers_mod/report-e-claim/report-e-claim.component';
import { ContainerLoginComponent } from './container-login/container-login.component';
import { DashboardModComponent } from './controllers_mod/dashboard-mod/dashboard-mod.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ACChangePasswordComponent } from './ac-change-password/ac-change-password.component';

export const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'maintenance', component: ServerMaintenanceComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'forgot-password-ac', component: ForgotPasswordACComponent},
  {path: 'container-login', component: ContainerLoginComponent},
  {path: 'landingpage', component: LandingPageComponent},
  {path: 'ac-change-password', component: ACChangePasswordComponent},
  {
    path: 'ess',
    component: HomeComponent,
    children: [
      {path: 'dashboard', component: DashboardModComponent},
      {path: 'appointment', component: AppointmentComponent},
      {path: 'appointment-inquiry', component: AppointmentInquiryComponent},
      {path: 'in-out', component: InOutComponent},
      {path: 'in-out-inquiry', component: InOutInquiryComponent},
      {path: 'eclaims', component: EclaimComponent},
      {path: 'eclaims-inquiry', component: EclaimInquiryComponent},
      {path: 'punch-inquiry', component: PunchInquiryComponent},
      {path: 'leave-application', component: LeaveApplicationComponent},
      {path: 'leave-balance-inquiry', component: LeaveBalanceInquiryComponent},
      {path: 'leave-application-inquiry', component: LeaveApplicationInquiryComponent},
      {path: 'calendar', component: CalendarComponent},
      {path: 'calendar-staff-leave-view', component: CalendarStaffLeaveComponent},
      {path: 'my-profile', component: MyProfileComponent},
      {path: 'profile-amend-inquiry', component: ProfileAmendInquiryComponent},
      {path: 'approval-center-pending-approval', component: ApprovalCenterPendingApprovalModComponent},
      {path: 'approval-center-override-approval', component: ApprovalCenterOverrideApprovalModComponent},
      {path: 'approval-center-delegation-settings', component: ApprovalCenterDelegationSettingsComponent},
      {path: 'delegation-balance-inquiry', component: DelegationBalanceInquiryComponent},
      {path: 'delegation-application-inquiry', component: DelegationApplicationInquiryComponent},
      {path: 'delegation-pending-approval', component: DelegationPendingApprovalComponent},
      {path: 'report-payslip', component: ReportPayslipComponent},
      {path: 'report-tax-form', component: ReportTaxFormComponent},
      {path: 'report-leave-balance', component: ReportLeaveBalanceComponent},
      {path: 'report-tax-summary', component: ReportTaxSummaryComponent},
      {path: 'report-e-claim', component: ReportEClaimComponent},
      
    ],
  },
  {path: '**', redirectTo: '/login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
