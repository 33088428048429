import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, QueryList, SecurityContext, ViewChild, ViewChildren } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { CommonHandlerService } from 'src/app/services/common-handler.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { PersonalService } from 'src/app/services/personal.service';

declare var $: any;

@Component({
  selector: 'app-popup-personal-profile-quali',
  templateUrl: './popup-personal-profile-quali.component.html',
  styleUrls: ['./popup-personal-profile-quali.component.scss']
})
export class PopupPersonalProfileQualiComponent implements OnInit {

  fileProgress = 0
  fileID = 0
  obj;
  selectedIndex = 0
  displaySwitch;
  qualiTypeList;
  EffectiveDateValue = moment().startOf('day').toDate()
  ExpiryDateValue = '';
  ObtainedDateValue = '';
  StartPeriodValue = '';
  EndPeriodValue = '';
  employee = JSON.parse(sessionStorage.Employee);

  @ViewChildren('effPicker') effPicker: QueryList<MatDatepicker<any>>
  @ViewChild('effFooter', { static: false }) effFooter: ElementRef;

  @ViewChildren('expPicker') expPicker: QueryList<MatDatepicker<any>>
  @ViewChild('expFooter', { static: false }) expFooter: ElementRef;

  @ViewChildren('obPicker') obPicker: QueryList<MatDatepicker<any>>
  @ViewChild('obFooter', { static: false }) obFooter: ElementRef;

  @ViewChildren('startPicker') startPicker: QueryList<MatDatepicker<any>>
  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;

  @ViewChildren('endPicker') endPicker: QueryList<MatDatepicker<any>>
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;

  constructor(public matDialogRef: MatDialogRef<PopupPersonalProfileQualiComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public errorUI: ErrorUIService, public commonUI: CommonUIService, public commonService: CommonServiceService, public personalService: PersonalService, public commonHandler: CommonHandlerService, public domSanitizer: DomSanitizer) { 
    this.obj = data['obj']
    this.qualiTypeList = data['qualiTypeList']
    this.displaySwitch = data['displaySwitch']
  }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch { }
  }

  /* Datepicker Footer */

  today(picker, date, n) {
    date = new Date()
    switch(picker) {
      case 'effPicker':
        this.obj[n].EffectiveDateValue = date
        break;
      case 'expPicker':
        this.obj[n].ExpiryDateValue = date
        break;
      case 'obPicker':
        this.obj[n].ObtainedDateValue = date
        break;
      case 'startPicker':
        this.obj[n].StartPeriodValue = date
        break;
      case 'endPicker':
        this.obj[n].EndPeriodValue = date
        break;
    }
    this.closePicker(picker, n)
  }

  closePicker(picker, n) {  
    switch(picker) {
      case 'effPicker':
        this.effPicker.forEach(function(e, idx) {
          if(idx == n) e.close()
        })
        break;
      case 'expPicker':
        this.expPicker.forEach(function(e, idx) {
          if(idx == n) e.close()
        })
        break;
      case 'obPicker':
        this.obPicker.forEach(function(e, idx) {
          if(idx == n) e.close()
        })
        break;
      case 'startPicker':
        this.startPicker.forEach(function(e, idx) {
          if(idx == n) e.close()
        })
        break;
      case 'endPicker':
        this.endPicker.forEach(function(e, idx) {
          if(idx == n) e.close()
        })
        break;
    }
  }

  openAppend(picker, i) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch(picker) {
      case 'effPicker':
        matCalendar.appendChild(this.effFooter.nativeElement);
        break;
      case 'expPicker':
        matCalendar.appendChild(this.expFooter.nativeElement);
        break;
      case 'obPicker':
        matCalendar.appendChild(this.obFooter.nativeElement);
        break;
      case 'startPicker':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'endPicker':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
    this.selectedIndex = i
  }

  /*******************/

  submitEvent() {
    var PersnlQualiFormList = [];
    this.obj.forEach((quali, k) => {
      if (quali.Action != "K") {
        var qualiEntry = {
            "EmploymentRID": this.employee.RID,
            "Action": quali.Action,
            "QualiRID": quali.QualiRID,
            "QualiTypeCode": quali.QualiTypeCode,
            "Name": quali.Name || "",
            "ObtainedDate": this.commonUI.formatJsonDate(quali.ObtainedDateValue, "YYYY-MM-DDT00:00:00"),
            "StartPeriod": this.commonUI.formatJsonDate(quali.StartPeriodValue, "YYYY-MM-DDT00:00:00"),
            "EndPeriod": this.commonUI.formatJsonDate(quali.EndPeriodValue, "YYYY-MM-DDT00:00:00"),
            "EffectiveDate": this.commonUI.formatJsonDate(quali.EffectiveDateValue, "YYYY-MM-DDT00:00:00"),
            "ExpiryDate": this.commonUI.formatJsonDate(quali.ExpiryDateValue, "YYYY-MM-DDT00:00:00"),
            "SerialNumber": quali.SerialNumber || "",
            "IssuingAuthority": quali.IssuingAuthority || "",
            "Level": quali.Level || "",
            "Remark": quali.Remarks || "",
            "AttachmentName": "",
            "AttachmentAddr": ""
        }
        PersnlQualiFormList.push(qualiEntry);

        var attachments = this.obj.attachments.filter((attach) => attach.uuid.value == quali.uuid);
        if (attachments.length > 0) {
            for (var i = 0; i < attachments.length; i++) {
                if (!attachments[i].deleted) {
                    qualiEntry.AttachmentName = attachments[i].file.name;
                    qualiEntry.AttachmentAddr = attachments[i].uploadFilename;
                }
            }
        }
      }
    });
    this.commonUI.addLoadingTask();
    this.personalService.submitQualiForm(PersnlQualiFormList).subscribe((result) => {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0001");
          this.obj.IsEditable = false;
          this.matDialogRef.close()
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  addNewQualiForEdit() {
    this.obj.forEach((record, key) => {
      record.onFocus = false;
    });

    $('#' + this.displaySwitch + ' .form_content_input').slideUp();

    var qualiRecord = {
        Action: "I",
        onFocus : true,
        QualiRID: "",
        QualiTypeCode: "",
        QualiTypeDesc: "",
        Name: "",
        IssuingAuthority: "",
        Level: "",
        Remarks: "",
        isNotExpire: false,
        deleted : false,
        uuid: this.commonUI.genUUID(),
        EffectiveDateValue: this.EffectiveDateValue,
        ExpiryDateValue: this.ExpiryDateValue,
        ObtainedDateValue: this.ObtainedDateValue,
        StartPeriodValue: this.StartPeriodValue,
        EndPeriodValue: this.EndPeriodValue,
        EffectiveDate: this.commonUI.formatJsonDate(this.EffectiveDateValue, "YYYY-MM-DDT00:00:00"),
        ExpiryDate: this.commonUI.formatJsonDate(this.ExpiryDateValue, "YYYY-MM-DDT00:00:00"),
        ObtainedDate: this.commonUI.formatJsonDate(this.ObtainedDateValue, "YYYY-MM-DDT00:00:00"),
        StartPeriod: this.commonUI.formatJsonDate(this.StartPeriodValue, "YYYY-MM-DDT00:00:00"),
        EndPeriod: this.commonUI.formatJsonDate(this.EndPeriodValue, "YYYY-MM-DDT00:00:00"),
    };
    this.obj.push(qualiRecord);
  }

  getQualiTypeDesc(code) {
    var qualiTypeDesc = "";
    this.qualiTypeList.forEach((qualiType, k) => {
      if (qualiType.Code == code) qualiTypeDesc = qualiType.Desc
    });
    return qualiTypeDesc;
  }

  deleteQualification(quali) {
    quali.deleted = true;
  }

  isRecordsNotReady() {
    var notReady = true;
    if (!this.obj) return notReady;
    var records = this.obj.filter(function (record) {
      return !record.deleted;
    })
    for (var i = 0; i < records.length; i++) {
      var record = this.obj[i];
      if (record.Action != 'K') {
        notReady = false;
        break;
      }
    }
    return notReady;
  }

  attachmentExistedFromRecord(currentRecord) {
    if (this.obj == undefined) return false;
    if (this.obj.attachments == undefined) return false;
    // return this.obj.attachments.filter(function (attach) {
    //     return !attach.deleted && attach.uuid == currentRecord.uuid;
    // }).length > 0;
    if (this.obj.attachments.filter(attach => {
      !attach.deleted && attach.uuid == currentRecord.uuid;
    }).length > 0)
      return true

    return false
  }

  // checkFileType(attachment) {
  //   var ext = attachment.file.name.split('.')[1].toLowerCase()
  //   if(ext.match('pdf.*'))
  //       attachment.dataURL = "assets/img/icon_pdf.png";
  //   else if(ext.match('xls.*') || ext.match('xlsx.*'))
  //       attachment.dataURL = "assets/img/icon_excel.png";
  //   else 
  //     attachment.dataURL = "assets/img/icon_claim_maintenance.png";
  // }

  convertSizeFormat(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  };

  uploadListening(event) {
    var files = event.target.files;
    var uuid = event.target.attributes.uuid;
    /*
    if ((this.countFilesReadyForUpload() + files.length) > 3) {
        this.commonUI.finishLoadingTask();
        this.commonUI.showWarning("message.M0005");
        this.$apply();
        return;
    }
    */
    for (var i = 0; i < files.length; i++) {
      var attachment = { fileID: this.fileID++, file: null, dataURL: null, uploadProgress: 0, uploadFilename: "", cancelled: false, uploaded: false, deleted: false };
      attachment['file'] = files[i];
      attachment['uuid'] = uuid;
      this.setPreview(attachment);
    }
    //this.commonUI.finishLoadingTask();
  }

  setPreview(attachment) {
    let instance = this
    var fileReader = new FileReader();
    fileReader.readAsDataURL(attachment.file)
    fileReader.onload = () => {
    if(attachment.file.type.match('image.*')) {
      attachment.dataURL = fileReader.result;
    } 
    else if(attachment.file.type.match('pdf.*'))
        attachment.dataURL = "assets/img/icon_pdf.png";
    else if(attachment.file.type.match('xls.*') || attachment.file.type.match('xlsx.*') || attachment.file.type.match('vnd.ms-excel.*'))
        attachment.dataURL = "assets/img/icon_excel.png";
    else 
      attachment.dataURL = "assets/img/icon_claim_maintenance.png";
    instance.obj.attachments.push(attachment);
    $("#btn_delete_attach_" + attachment.fileID).hide();
    this.uploadAttachment(attachment);
    }
  }

  uploadAttachment(attachment) {
    $("#btn_upload_attach_" + attachment.fileID).hide();
    this.commonService.uploadAttachment(attachment).subscribe((result) => {
      if (result.type == HttpEventType.UploadProgress) {
        this.fileProgress = Math.round(100 * result.loaded / result.total);
      } else if (result instanceof HttpResponse) {
        if (!result['conn_fail']) {
          $("#btn_delete_attach_" + attachment.fileID).show();
          $("#btn_upload_attach_" + attachment.fileID).hide();
          $("#btn_cancel_attach_" + attachment.fileID).hide();
          $("#file_UploadAttachment").val('');
          $("#file_BankUploadAttachment").val('');
          $("input[uuid*='" + attachment.uuid + "']").val('');
          attachment.uploadFilename = result.body['Data'].UploadFileName
        } else 
        this.onCallAPIFail(result.body, result.status, result.headers, result.body['Data'])
      }
    }, err => {
      this.fileProgress = 0
      $("#file_UploadAttachment").val('');
      $("#file_BankUploadAttachment").val('');
      $("input[uuid*='" + attachment.uuid + "']").val(''); //use uuid delete
      this.deleteAttachment(attachment);
      // this.commonUI.showMsgBox("status." + err);
      this.commonUI.showMsgBox("status." + err.status + ': ' + err.statusText);
      //if (error != undefined && error == 501)
      //    this.commonUI.showMsgBox("error.00022");
      //else
      //    this.commonUI.showMsgBox("error.00021");
    })
  }

  deleteAttachment(attachment) {
    //Worked
    attachment.deleted = true;
    $("#attach_" + attachment.fileID).fadeOut();
    this.obj.attachments = this.obj.attachments.filter(o => o.deleted == false);
    //this.obj.attachments = []
  }

  slideUp(event) {
    this.obj.forEach(record => {
      record.onFocus = false
    });
    $('#' + this.displaySwitch + ' .form_content_input').slideUp();
    $('#' + this.displaySwitch + ' #' + event.currentTarget.parentNode.id + ' .form_content_input').slideToggle();
    // $('.form_content_input').slideUp();
    // $('#' + event.currentTarget.id + ' .form_content_input').slideToggle();
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
