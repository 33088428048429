<div id="popup_LeavePreview" class="pp4_content"  >
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_1column">
        <!--<div class="pop_functions_close btn_grey_2b pp_close">x</div>-->
        <div style="clear: both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_application"></div>
            <div class="fl">{{ 'leaveapplication.confirmofapplication' | translate }}</div>
        </div>
        <div class="confirm_application_position txt_size_1">

            <div class="box_1_staff">
                <div class="txt_photo_area_big fl" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(staffInfo) + ')'}">
                    <div class="txt_photo_mask_big p_normal"></div>
                </div>
                <div class="box_1_staff_name">
                    <span class="txt_size_1">{{staffInfo.FullName}}</span><br> <span class="txt_size_3 txt_grey_2">{{ staffInfo.PositionDesc }}</span>
                </div>
            </div>
            <br />

            <div class="txt_grey_2 txt_size_3 txt_detail_sub_adjust fl">{{ 'leaveapplication.type' | translate }}&nbsp;</div>
            <div class="fl">{{leaveApplication.LeaveTypeCode}}</div>
            <div style="clear: both"></div>
            <br />

            <div class="txt_grey_2 txt_size_3 txt_detail_sub_adjust fl">{{ 'leaveapplication.start' | translate }}&nbsp;</div>
            <div class="fl txt_data_confirm">
                {{commonUI.formatDisplayDate(startDate, 'DD MMM YYYY')}}<span class="txt_size_3"> ({{commonUI.formatDisplayDate(startDate, 'ddd')}})</span>
            </div>
            <div class="txt_label bc_grey txt_size_3 fl">{{startDateTakingUnit}}</div>
            <div style="clear: both"></div>

            <div class="txt_grey_2 txt_size_3 txt_detail_sub_adjust fl">{{ 'leaveapplication.end' | translate }}&nbsp;</div>
            <div class="fl txt_data_confirm">
                {{commonUI.formatDisplayDate(endDate, 'DD MMM YYYY')}}<span class="txt_size_3"> ({{commonUI.formatDisplayDate(endDate, 'ddd')}})</span>
            </div>
            <div class="txt_label bc_grey txt_size_3 fl">{{endDateTakingUnit}}</div>
            <div style="clear: both"></div>
            <br />
            <div class="txt_grey_2 txt_size_3">{{ 'leaveapplication.noofdays' | translate }}&nbsp;</div>
            <div class="fl">{{ leaveDays }}</div>
            <div style="clear: both"></div>
            <br /> <span class="txt_grey_2 txt_size_3">{{ 'leaveapplication.description' | translate }}</span><br />
            <div class="txt_size_2" style="white-space: pre-wrap;">{{leaveApplication.Description}}</div>
            <br /> <span class="txt_grey_2 txt_size_3">{{ 'leaveapplication.attachment' | translate }}</span>
            <div>
                <div *ngFor="let attach of attachments">
                    <div class="attachment_photo" *ngIf="!attach.deleted && attach.uploaded">
                        <img [src]="attach.dataURL">
                    </div>
                </div>
                <div style="clear: both"></div>
            </div>
        </div>
        <br />

        <br />
        <hr class="hr_preview" />
        <div class="pop_functions_two_btn_center">
            <!--class="two_btn_fixed_bottom"-->
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish" href="javascript:void(0)" (click)="submitLeaveApplication()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'yes' | translate }}</button>
            <button class="btn btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'no' | translate }}</button>
            <div style="clear: both"></div>
        </div>
    </div>
</div>