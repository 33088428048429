<div id="popup_msg_box_with_callback" class="pop_msg">
    <div class="pop_functions_box pop_functions_box_size_1">
        <br />
        <img src="assets/img/msg_tick.png" width="48">
        <br /><br />{{ msgcode | translate:commonUI.translationData }}<br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg one_btn maincolor_btn2" (click)="close()">
                <img src="assets/img/btn_tick_w.png" width="18">{{ 'ok' | translate }}
            </button>
            <div style="clear: both"></div>
        </div>
    </div>
</div>