<div>

    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'menu.personalprofile' | translate }}</span>
    </div>

    <div class="btn_reset_profile">
        <ul id="filter_options">
            <li [ngClass]="{'active': currentTab.cssClass == 'f_general'}" >
            <a (click)="showTab('f_general')" class="f_general">
                <div><img src="assets/img/btn_filter_profile_general.png" width="20" alt=""><br/><span>{{ 'personalprofile.tabgeneral' | translate }}</span></div>
            </a>
            </li>
            <li [ngClass]="{'active': currentTab.cssClass == 'f_employment'}" >
            <a (click)="showTab('f_employment')" class="f_employment">
                <div><img src="assets/img/btn_filter_profile_employment.png" width="20" alt=""><br/><span>{{ 'personalprofile.tabemployment' | translate }}</span></div>
            </a>
            </li>
            <li [ngClass]="{'active': currentTab.cssClass == 'f_address'}" >
            <a (click)="showTab('f_address')" class="f_address">
                <div><img src="assets/img/btn_filter_profile_address.png" width="20" alt=""><br/><span>{{ 'personalprofile.tabaddress' | translate }}</span></div>
            </a>
            </li>
            <li [ngClass]="{'active': currentTab.cssClass == 'f_bank'}" >
                <a (click)="showTab('f_bank')" class="f_bank">
                    <div><img src="assets/img/btn_filter_profile_bank.png" width="20" alt=""><br/><span>{{ 'personalprofile.tabbank' | translate }}</span></div>
                </a>
            </li>
            <li [ngClass]="{'active': currentTab.cssClass == 'f_dependents'}" >
                <a (click)="showTab('f_dependents')" class="f_dependents">
                    <div><img src="assets/img/btn_filter_profile_dependents.png" width="20" alt=""><br/><span>{{ 'personalprofile.tabdependents' | translate }}</span></div>
                </a>
            </li>
            <li [ngClass]="{'active': currentTab.cssClass == 'f_qualification'}" >
                <a  (click)="showTab('f_qualification')" class="f_qualification">
                    <div><img src="assets/img/btn_filter_profile_qualification.png" width="20" alt=""><br/><span>{{ 'personalprofile.tabqualification' | translate }}</span></div>
                </a>
            </li>
            <div style="clear:both;"></div>
        </ul>
    </div>




    <!-- =========== Result ========== -->
    <a name="list_result" class="list_bar_mt"></a>
    <div class="list_result_area content_box content_box_show">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon {{ currentTab.cssTitleClass }}"></div>
            <div class="fl tit_name">{{ currentTab.displayName | translate }}</div>
        </div><!--
        <div class="box_title_sub maincolor_txt">General</div>-->

        <div style="clear:both;"></div>

        <!-- ============ search result txt  ============ -->

        <!--<a name="list_result_filter" class="list_bar_mt"></a>-->

        <!--<hr class="m0">
        <br/>-->

        <div class="box_content_inquiry">

            <div id="filter_holder" class="form_area personal_profile_area">

                <div *ngIf="currentTab.cssClass == 'f_general'" style="display:block" class="f_general">
                    <div class="form_content" *ngIf='PersnlProfile'>

                        <div (click)="showSelectedSection(1)" [ngClass]="{'form_content_title-selected': currentTab.selectedSection == 1 }" class="form_content_title ">{{ 'personalprofile.profile' | translate }}</div>

                        <!--<div class="btn_edit_inf" title="Edit"><img src="assets/img/btn_edit_inf.png" width="18"></div>-->

                        <div class="form_content_input" tag="form_content_input_1">
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.staffid' | translate }}</span><br>
                                {{ employee.StaffID }}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.personalid' | translate }}</span><br>
                                {{ PersnlProfile.PersonalID }}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.hkid' | translate }}</span><br>
                                {{PersnlProfile.HKID}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.chinesename' | translate }}</span><br>
                                {{PersnlProfile.ChineseName}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.surname' | translate }}</span><br>
                                {{PersnlProfile.LastName}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.givenname' | translate }}</span><br>
                                {{PersnlProfile.FirstName}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.alias' | translate }}</span><br>
                                {{PersnlProfile.OtherName}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.gender' | translate }}</span><br>
                                {{PersnlProfile.Gender}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.salutation' | translate }}</span><br>
                                {{PersnlProfile.Salutation}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.bloodtype' | translate }}</span><br>
                                {{PersnlProfile.BloodType}}&nbsp;
                            </div>

                            <div style="clear:both"></div>
                            <hr class="m0">

                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.firstjoin' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(PersnlProfile.FirstJoinDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(PersnlProfile.FirstJoinDate, '(ddd)')}}&nbsp;</span>
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.lastjoin' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(PersnlProfile.LastJoinDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(PersnlProfile.LastJoinDate, '(ddd)')}}&nbsp;</span>
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.socialjoin' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(PersnlProfile.SocialJoinDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(PersnlProfile.SocialJoinDate, '(ddd)')}}&nbsp;</span>
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.termination' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(PersnlProfile.TermDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(PersnlProfile.TermDate, '(ddd)')}}&nbsp;</span>
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div> <!-- ========== end : form_content =========== -->

                    <div class="form_content" *ngIf='PersnlProfile'>

                        <div (click)="showSelectedSection(2)" [ngClass]="{'form_content_title-selected': currentTab.selectedSection == 2 }"  class="form_content_title">{{ 'personalprofile.contact' | translate }}</div>

                        <div *ngIf='PersnlProfile.IsContactEditable' (click)="showEditPopupContact()" class="btn_edit_inf pp_contact" title="Edit"><img src="assets/img/btn_edit_inf.png" width="18"></div>

                        <div class="form_content_input" tag="form_content_input_2">

                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.officalemail' | translate }}</span><br/>
                                {{PersnlProfile.Email}}
                            </div>
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.personalemail' | translate }}</span><br/>
                                {{PersnlProfile.Email2}}
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.phone' | translate }} 1</span><br/>
                                {{PersnlProfile.TelNum1}}
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.phone' | translate }} 2</span><br/>
                                {{PersnlProfile.TelNum2}}
                            </div>
                            <div style="clear:both"></div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.mobile' | translate }} 1</span><br/>
                                {{PersnlProfile.MobileNum1}}
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.mobile' | translate }} 2</span><br/>
                                {{PersnlProfile.MobileNum2}}
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div> <!-- ========== end : form_content =========== -->

                    <div class="form_content" *ngIf='PersnlProfile'>

                        <div (click)="showSelectedSection(3)" [ngClass]="{'form_content_title-selected': currentTab.selectedSection == 3 }" class="form_content_title">{{ 'personalprofile.maritalStatus' | translate }}</div>

                        <div *ngIf='PersnlProfile.IsMaritalStatusEditable' (click)="showEditPopupMarital()" class="btn_edit_inf pp_marital" title="Edit"><img src="assets/img/btn_edit_inf.png" width="18"></div>

                        <div class="form_content_input" tag="form_content_input_3">
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.status' | translate }} </span><br>
                                {{ maritalStatus | translate}}
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div> <!-- ========== end : form_content =========== -->
                </div> <!-- ========== end : f_general =========== -->

                <div *ngIf="currentTab.cssClass == 'f_employment' && PersnlEmploy" style="display:block" class="f_employment">
                    <div class="form_content">

                        <div class="form_content_title form_content_title-selected">{{PersnlEmploy.PositionDesc}}</div>

                        <div class="form_content_input" tag="form_content_input_1">
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.startdate' | translate }} </span><br>
                                {{commonUI.formatDisplayDate(PersnlEmploy.EmploymentDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(PersnlEmploy.EmploymentDate, '(ddd)')}}&nbsp;</span>
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.enddate' | translate }} </span><br>
                                {{commonUI.formatDisplayDate(PersnlEmploy.EmploymentEndDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(PersnlEmploy.EmploymentEndDate, '(ddd)')}}&nbsp;</span>
                            </div>

                            <div style="clear:both"></div>
                            <hr class="m0">

                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.department' | translate }}</span><br>
                                {{PersnlEmploy.DepartmentDesc}}
                            </div>
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.division' | translate }}</span><br>
                                {{PersnlEmploy.DivisionDesc}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.section' | translate }}</span><br>
                                {{PersnlEmploy.SectionDesc}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.serviceunit' | translate }}</span><br>
                                {{PersnlEmploy.ServiceUnitDesc}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.grade' | translate }}</span><br>
                                {{PersnlEmploy.GradeDesc}}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.site' | translate }}</span><br>
                                {{PersnlEmploy.SiteDesc}}&nbsp;
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div>
                </div> <!-- ========== end : f_employment =========== -->

                <div *ngIf="currentTab.cssClass == 'f_address' && PersnlAddressList" style="display:block" class="f_address">

                    <div class="btn_add_profile_data" *ngIf='PersnlAddressList.IsEditable'>
                        <button class="pp_qualification btn_inquiry_search btn btn-block btn-lg one_btn maincolor_btn2" (click)="showEditPopupAddress()"><img src="assets/img/btn_edit_inf_w.png" width="18">{{ 'edit' | translate }}</button>
                    </div>

                    <div class="form_content" *ngFor="let address of PersnlAddressList; let i = index">

                        <div class="form_content_title form_content_title" (click)="showSelectedSection(address.index)" [ngClass]="{'form_content_title-selected': currentTab.selectedSection == address.index }" >{{ address.AddressTypeDesc }}&nbsp;</div>

                        <!--<div class="btn_edit_inf pp_address" title="Edit"><img src="assets/img/btn_edit_inf.png" width="18"></div>-->

                        <div class="form_content_input" style="display: block;" [attr.tag]="'form_content_input_' + address.index">
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.effectivedate' | translate }}</span><br>
                                {{ commonUI.formatDisplayDate(address.EffectiveDate, 'DD MMM YYYY') }}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(address.EffectiveDate, '(ddd)')}}&nbsp;</span>
                            </div>
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.address' | translate }}</span><br>
                                {{ address.ContactAddress }}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.district' | translate }}</span><br>
                                {{ address.DistrictDesc }}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.area' | translate }}</span><br>
                                {{ getAreaDesc(address.Area) }}&nbsp;
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div> <!-- ========== end : form_content =========== -->

                </div> <!-- ========== end : f_address =========== -->

                <div *ngIf="currentTab.cssClass == 'f_bank' && PersnlBankAcc" style="display:block" class="f_bank">

                    <!--<div class="btn_add_profile_data">
                        <button class="btn_inquiry_search btn btn-block btn-lg one_btn maincolor_btn2"><img src="assets/img/btn_addform_w.png" width="18">Add New</button>
                    </div>-->

                    <div class="form_content">

                        <div class="form_content_title form_content_title-selected" >{{ 'personalprofile.account' | translate }} : {{ PersnlBankAcc.AccountNum }}</div>

                        <div *ngIf='PersnlBankAcc.IsEditable' (click)="showEditPopupBank()" class="btn_edit_inf pp_bank" title="Edit"><img src="assets/img/btn_edit_inf.png" width="18"></div> <!-- if iseditable-->

                        <div class="form_content_input" style="display: block;">
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.bank' | translate }}</span><br>
                                {{ PersnlBankAcc.BankDesc }}&nbsp;
                            </div>
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.accountname' | translate }}</span><br>
                                {{ PersnlBankAcc.AccountName }}&nbsp;
                            </div>
                            <!--
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.effectivedate' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(PersnlBankAcc.EffectiveDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(PersnlBankAcc.EffectiveDate, '(ddd)')}}&nbsp;</span>
                            </div>
                            -->
                            <div class="full_line" *ngIf="bankAttach">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.attachment' | translate }}</span><br>
                                <a href="assets/img/letter_sample.jpg" target="_blank">
                                    <div class="attachment_photo">
                                        <div><img src="{{bankAttach.dataURL}}"></div>
                                        <div class="attachment_photo_touch"></div>
                                    </div>
                                </a>
                                <div style="clear:both"></div>
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div> <!-- ========== end : form_content =========== -->


                </div> <!-- ========== end : f_bank =========== -->

                <div *ngIf="currentTab.cssClass == 'f_dependents' && PersnlDependtList" style="display:block" class="f_dependents">

                    <div class="btn_add_profile_data" *ngIf="PersnlDependtList.IsEditable" >
                        <button (click)="showEditPopupDependt()"class="pp_dependents btn_inquiry_search btn btn-block btn-lg one_btn maincolor_btn2"><img src="assets/img/btn_edit_inf_w.png" width="18">{{ 'edit' | translate }}</button>
                    </div>

                    <div class="form_content"  *ngFor="let dependent of PersnlDependtList; let i = index">
                        <div class="form_content_title" (click)="showSelectedSection(dependent.index)" [ngClass]="{'form_content_title-selected': currentTab.selectedSection == dependent.index }">{{ dependent.RelationshipDesc }}</div>

                        <!--<div class="btn_edit_inf pp_dependents" title="Edit"><img src="assets/img/btn_edit_inf.png" width="18"></div>-->

                        <div class="form_content_input" style="display: block;" [attr.tag]="'form_content_input_' + dependent.index">
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.name' | translate }}</span><br>
                                {{ dependent.FullName }}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.mobile' | translate }}</span><br>
                                {{ dependent.MobileNum1 }}&nbsp;
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.emergencycontact' | translate }}</span><br>
                                <span *ngIf="dependent.IsEmergency">{{ 'yes' | translate }}</span><span *ngIf="!dependent.IsEmergency">{{ 'no' | translate }}</span>
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.birthday' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(dependent.Birthday, 'DD MMM YYYY')}}
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.hkid' | translate }}</span><br>
                                {{ dependent.HKID }}
                            </div>
                            <div class="same_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.idnumber' | translate }}</span><br>
                                {{ dependent.IdentityNum }}&nbsp;
                            </div>
                            <div class="full_line">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.idtype' | translate }}</span><br>
                                {{ dependent.IdentityTypeDesc }}&nbsp;
                            </div>
                            <div class="full_line" *ngIf="dependent.Attachment">
                                <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.attachment' | translate }}</span><br>
                                <a href="assets/img/letter_sample.jpg" target="_blank">
                                    <div class="attachment_photo">
                                        <div><img src="{{dependent.dataURL}}"></div>
                                        <div class="attachment_photo_touch"></div>
                                    </div>
                                </a>
                                <div style="clear:both"></div>
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div> <!-- ========== end : form_content =========== -->
                </div> <!-- ========== end : f_dependents =========== -->

                <div *ngIf="currentTab.cssClass == 'f_qualification' && PersnlQualiList" style="display:block" class="f_qualification">
                    <div class="btn_add_profile_data" *ngIf="PersnlQualiList.IsEditable">
                        <button class="pp_qualification btn_inquiry_search btn btn-block btn-lg one_btn maincolor_btn2" (click)="showEditQualification()"><img src="assets/img/btn_edit_inf_w.png" width="18">{{ 'edit' | translate }}</button>
                    </div>
                        <div class="form_content" *ngFor="let quali of PersnlQualiList; let i = index;">
                            <div class="form_content_title" (click)="showSelectedSection(quali.index)" [ngClass]="{'form_content_title-selected': currentTab.selectedSection == quali.index}">{{ quali.QualiTypeDesc }}&nbsp;</div>

                            <!--<div class="btn_edit_inf pp_qualification" title="Edit"><img src="assets/img/btn_edit_inf.png" width="18"></div>-->

                            <div class="form_content_input" style="display: block;" [attr.tag]="'form_content_input_' + quali.index">
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.effectivedate' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(quali.EffectiveDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(quali.EffectiveDate, '(ddd)')}}&nbsp;</span>
                                </div>
                                <div class="same_line" [ngClass]="{'hide' : commonUI.formatDisplayDate(quali.ExpiryDate, 'YYYY') == '0001'}">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.expirydate' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(quali.ExpiryDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(quali.ExpiryDate, '(ddd)')}}&nbsp;</span>
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.obtaineddate' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(quali.ObtainedDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(quali.ObtainedDate, '(ddd)')}}&nbsp;</span>
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.startperiod' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(quali.StartPeriod, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(quali.StartPeriod, '(ddd)')}}&nbsp;</span>
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.endperiod' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(quali.EndPeriod, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(quali.EndPeriod, '(ddd)')}}&nbsp;</span>
                                </div>

                                <div style="clear:both"></div>
                                <hr class="m0">

                                <div class="full_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.name' | translate }}</span><br>
                                    {{ quali.Name }}&nbsp;
                                </div>
                                <div class="full_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.issuingauthority' | translate }}</span><br>
                                    {{ quali.IssuingAuthority }}&nbsp;
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.qualilevel' | translate }}</span><br>
                                    {{ quali.Level }}&nbsp;
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.serialnumber' | translate }}</span><br>
                                    {{ quali.SerialNumber }}&nbsp;
                                </div>
                                <div class="full_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.remark' | translate }}</span><br>
                                    {{ quali.Remarks }}&nbsp;
                                </div>
                                <div class="full_line" *ngIf="quali.AttachmentLink">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.attachment' | translate }}</span><br>
                                        <div class="attachment_photo" (click)="commonHandler.saveAttachment(quali.AttachmentLink, quali.AttachmentName)">
                                            <div><img [src]="quali.Attachment.dataURL"></div>
                                            <div class="attachment_photo_touch"></div>
                                        </div>
                                    <div style="clear:both"></div>
                                </div>
                                <div style="clear:both"></div>
                            </div>

                        </div> 
                        <!-- ========== end : form_content =========== -->
                </div> <!-- ========== end : f_qualification =========== -->

                <div style="clear:both"></div>
            </div> <!-- ========== end : form_area =========== -->


            <div style="clear:both"></div>
        </div>



    </div> <!-- end id=content_box --><br/>

    <!-- <personal-profile-edit-popup type="contact" display-switch="displayPopupName" obj="PersnlProfileForEdit" submit-event= "popupSubmit()"></personal-profile-edit-popup>
    <personal-profile-edit-popup type="marital" display-switch="displayPopupName" obj="PersnlProfileForEdit" submit-event= "popupSubmit()"></personal-profile-edit-popup>
    <personal-profile-edit-popup type="bank" display-switch="displayPopupName" obj="PersnlBankAccForEdit" submit-event="popupSubmit()"></personal-profile-edit-popup>
    <personal-profile-edit-popup type="address" display-switch="displayPopupName" obj="PersnlAddressListForEdit" submit-event="popupSubmit()"></personal-profile-edit-popup>
    <personal-profile-edit-popup type="dependents" display-switch="displayPopupName" obj="PersnlDependtListForEdit" submit-event="popupSubmit()"></personal-profile-edit-popup>
    <personal-profile-edit-popup type="qualification" display-switch="displayPopupName" obj="PersnlQualiListForEdit" submit-event="popupSubmit()"></personal-profile-edit-popup> -->
</div>
