import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ImageEnlargePreviewComponent } from '../common/image-enlarge-preview/image-enlarge-preview.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonHandlerService } from '../services/common-handler.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';
import { LeaveService } from '../services/leave.service';
import { ReportService } from '../services/report.service';

declare var $: any;

@Component({
  selector: 'app-report-leave-balance',
  templateUrl: './report-leave-balance.component.html',
  styleUrls: ['./report-leave-balance.component.scss']
})
export class ReportLeaveBalanceComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  employee = JSON.parse(sessionStorage.Employee);
  leaveTypeList = [];
  leaveType = "";
  asAtDay = new Date();
  approvalGroupList = [];
  approvalGroupListSettings = {};
  isTeammateViewSelected = false;

  multipleSelection = {
    selectedApprovalGroupList: []
  };

  leaveBalanceReportData = [];
  reportFile = {};
  fileFormat = "pdf";
  criteria = {
    FileFormat: this.fileFormat
  }

  constructor(public idleTimer: IdleTimerService, public commonUI: CommonUIService, public translate: TranslateService, public commonService: CommonServiceService, public reportService: ReportService, public commonHandler: CommonHandlerService, public matDialog: MatDialog, public leaveService: LeaveService) { 
    this.translate.onLangChange.subscribe(() => {
      this.asAtDay = moment(this.asAtDay).toDate();
  })
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.getLists();
    this.getLeaveTypeList();
  }

  isChangedTeamView() {
    if (this.isTeammateViewSelected) {
        this.getLeaveTypeList()
    }
}

asAtCutOffDate() {
  this.commonUI.addLoadingTask();
  this.leaveService.getLeaveCutOffDate(this.employee, this.leaveType, this.asAtDay).subscribe((result: any) => {
    if (result.body['ErrorMsg'] == null) {
      this.asAtDay = this.commonUI.isoDateStringToDate(result.body['Data'].CutOffDate);
      this.commonUI.finishLoadingTask();
    }
    else
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  }, err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

getLeaveTypeList() {
  this.commonUI.addLoadingTask();

  this.leaveTypeList = [];
  this.leaveType = "";
  var em = this.employee
  if (this.isTeammateViewSelected) em = ""
  this.leaveService.getLeaveTypeList(em).subscribe((result: any) => {
    if (result.body['ErrorMsg'] == null) {
      this.leaveTypeList = result.body['Data'].LeaveTypeList;
      this.leaveTypeList = this.leaveTypeList.filter((l) => l.EnableBalInqESS == 'Y')
      this.leaveType = this.leaveTypeList[0].Code;
      this.commonUI.finishLoadingTask();
    }
    else
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  }, err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

  showSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

    this.showReportLeaveBalanceSearchResult();
  }

  showReportLeaveBalanceSearchResult() {
    let instance = this;
    this.commonUI.addLoadingTask();

    var approvalGroupList = [];
    for (var i = 0; i < this.multipleSelection.selectedApprovalGroupList.length; i++) {
        approvalGroupList.push(this.multipleSelection.selectedApprovalGroupList[i].Code);
    }

    this.reportService.getLeaveBalanceReport(
        this.employee,
        this.leaveType,
        this.commonUI.formatJsonDate(this.asAtDay, "YYYY-MM-DDT00:00:00"),
        approvalGroupList,
        this.isTeammateViewSelected,
        this.criteria).subscribe((result) => {
          if (result.body['ErrorMsg'] == null) {
            this.leaveBalanceReportData = result.body['Data'];
            $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", function () {
                instance.commonUI.finishLoadingTask();
                $('html, body').animate({
                    scrollTop: $(".list_result_area").offset().top
                }, 1000);
            });
          }
          else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
 }

getLists() {
  this.getApprovalGroupList();
}

getApprovalGroupList() {
  this.commonUI.addLoadingTask();
  this.reportService.getApprovalGroupList(this.employee).subscribe((result) => {
    if (result.body['ErrorMsg'] == null) {
      this.approvalGroupList = result.body['Data'].ApprovalGroupList
      this.approvalGroupListSettings = {
        singleSelection: false,
        idField: 'Code',
        textField: 'Desc',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true
      }
      this.commonUI.finishLoadingTask();
    }
    else
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  }, err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

viewReport(attachmentPath) {
  this.commonUI.addLoadingTask();
  if (environment.isMobileDevice() || environment.isIE() || this.fileFormat != "pdf") {
      this.commonUI.finishLoadingTask();
      this.commonHandler.saveAttachment(attachmentPath, 'LeaveBalanceReport.' + this.fileFormat);
  } else {
      this.commonService.viewAttachment(attachmentPath, this.fileFormat, this.reportFile).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.matDialog.open(ImageEnlargePreviewComponent, {
            data: {
              dataURL: result.body['dataURL']
            },
            panelClass: 'dialog-responsive'
          })
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }
}

  resetSearchResult() {
      $('html, body').animate({ scrollTop: 0 }, 1000);
      $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

      this.leaveType = this.leaveTypeList[0].Code;
      this.asAtDay = new Date();
      this.isTeammateViewSelected = false;
      this.multipleSelection.selectedApprovalGroupList = [];
  }

    /* Datepicker Footer */

    today() {
      let instance = this
      instance.asAtDay = new Date()
      instance.startPicker.close()

    }

    close() {
      this.startPicker.close()
    }

    openAppend() {
      const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
      matCalendar.appendChild(this.startFooter.nativeElement);
    }

    /*******************/
    onCallAPIFail(data, status, headers, config) {
      this.commonUI.finishLoadingTask();
      this.commonService.onCallAPIFail(data, status, headers, config);
    }

}