import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { InOutService } from 'src/app/services/in-out.service';
import { LeaveService } from 'src/app/services/leave.service';
import { EclaimService } from 'src/app/services/eclaim.service';

@Component({
  selector: 'app-popup-confirm-withdraw',
  templateUrl: './popup-confirm-withdraw.component.html',
  styleUrls: ['./popup-confirm-withdraw.component.scss']
})
export class PopupConfirmWithdrawComponent implements OnInit {

  withdrawConfirmPopupid
  appointmentapp
  leaveapplication
  inoutapplication
  claimapplication
  employee = JSON.parse(sessionStorage.Employee);
  confirm = false
  type = 'leave';

  constructor(public inoutService: InOutService ,public matDialogRef: MatDialogRef<PopupConfirmWithdrawComponent>, public commonUI: CommonUIService, public leaveService: LeaveService, @Inject(MAT_DIALOG_DATA) public data: {}, public appointmentService: AppointmentService, public eclaimService : EclaimService) { 
    this.leaveapplication = data['app']
    this.appointmentapp = data['apmapp']
    this.inoutapplication = data['inoutapp']
    this.claimapplication = data['claimapp']
    this.withdrawConfirmPopupid = data['popid']
    this.type = data['type']
  }

  ngOnInit(): void {
  }

  withdrawLeaveApplication() {
    this.commonUI.addLoadingTask();
    this.leaveService.withdrawLeaveApplication(this.employee, this.leaveapplication).subscribe((res) => {
      this.confirm = true
      this.commonUI.finishLoadingTask();
      this.commonUI.showMsgBoxWithCallback("message.M0012", null);
      this.close()
    }, err => {
      err['conn_fail'] = true
      return err
    })
}

withdrawAppointmentApplication() {
  this.commonUI.addLoadingTask();
  this.appointmentService.withdrawAppointmentApplication(this.appointmentapp.StaffInfo, this.appointmentapp).subscribe((result) => {
    this.confirm = true
    this.commonUI.finishLoadingTask();
    this.commonUI.showMsgBoxWithCallback("message.M0011", null);
    this.close();
  })
}

withdrawInOutTimeApplication() {
  this.commonUI.addLoadingTask();
  this.inoutService.withdrawEInOutApplication(this.employee, this.inoutapplication).subscribe((result) => {
    this.confirm = true
    this.commonUI.finishLoadingTask();
    this.commonUI.showMsgBoxWithCallback("message.M0012", null);
    this.close();
  })
}

withdrawClaimApplication() {
  this.commonUI.addLoadingTask();
  this.eclaimService.withdrawEClaimApplication(this.employee, this.claimapplication).subscribe((result) => {
    this.confirm = true
    this.commonUI.finishLoadingTask();
    this.commonUI.showMsgBoxWithCallback("message.M0030", null);
    this.close();
  })
}

  close() {
    const returnValue = {
      confirm: this.confirm
    }
    try {
      this.matDialogRef.close(returnValue)
    } catch {}
  }

}