<div class="pp5_content" id="pop_LeaveBalance"  >
	<div class="pop_functions_box pop_functions_box_size_2column mask_balance_inquiry">
    	<div class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top" *ngIf='StaffInfo'>
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
        	<div class="detail_box_top_photo" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
              <span class="txt_size_1">{{ StaffInfo.FullName }}</span><br>
              <span class="txt_opacity_1">{{ StaffInfo.PositionDesc }}</span><br>
              <span class="txt_size_3 txt_opacity_1 txt_popup_staffid">ID&nbsp;:&nbsp;{{ StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

        	<div class="detail_box_bottom_2point_leave_balance">
                <!--
                <div class="detail_box_catalog_area">
                	<div class="maincolor_bg color_label_pending">Pending</div>
    			</div>-->
                <div class="detail_box_session1 txt_grey_2 txt_size_3">&nbsp;</div>
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(leaveBalance.LeaveYearStart, 'D MMM YYYY')}}
                    <span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(leaveBalance.LeaveYearStart, '(ddd)')}}</span>
                </div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'leaveapplication.cutoffday' | translate }}</div>
                <div class="detail_box_date2 txt_size_1">{{commonUI.formatDisplayDate(leaveBalance.LeaveYearEnd, 'D MMM YYYY')}}
                    <span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(leaveBalance.LeaveYearEnd, '(ddd)')}}</span>
                </div>
                <!-- <div class="detail_box_sub_icon_txtonly d_box_sub_icon_leave" *ngIf='leaveapplication != undefined'></div>
                <div class="detail_box_type_appointment" *ngIf='leaveapplication'>{{leaveapplication.LeaveTypeCode}}</div> -->

                <div class="detail_box_sub_icon_txtonly d_box_sub_icon_leave" *ngIf='leaveTypeCode'></div>
                <div class="detail_box_type_appointment" *ngIf='leaveTypeCode'>{{leaveTypeCode}}</div>
            </div>

            <div class="detail_box_other txt_size_1">

            	<div class="txt_grey_2 txt_size_3">{{ 'leaveapplication.cutoffdayasat' | translate }}</div>
                <div>
                    {{commonUI.formatDisplayDate(asAtDate, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(asAtDate, '(ddd)')}}</span>
                </div>
                
                    <div class="fl input_new_calendar" *ngIf='leaveapplication && leaveapplication.showCutoff'>
                    <div>
                        <mat-form-field appearance='fill' (click)="balancePicker.open()">
                            <input matInput [matDatepicker]="balancePicker" [(ngModel)]="asAtDate" [min]='minDate' [max]='maxDate' (dateChange)='calSelfLeaveBalance()' readonly>
                            <mat-datepicker-toggle matSuffix [for]="balancePicker"></mat-datepicker-toggle>
                            <mat-datepicker #balancePicker (opened)="openAppend()"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #balanceFooter>
                                <button mat-raised-button  (click)="today()" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="closePicker()" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                        <!-- <am-date-picker ng-model="asAtDate" am-show-input-icon="true" am-allow-clear="false" ng-change="calSelfLeaveBalance()" /> -->
                    </div>
                    </div>
                    <!--
                	<input id="datepicker-1" class="btn btn-block btn-lg btn_date maincolor_btn2" type="text" ng-attr-placeholder="31 Dec 2016 (Thu)" readonly>
                    <div><img src="assets/img/input_date.png" width="18"></div>
                -->
                 <a id="btnCutOffDate" class="btn btn_grey" (click)="getCutOffDateBalance()" *ngIf='leaveapplication && leaveapplication.showCutoff'>
                 {{ 'leaveapplication.cutoffday' | translate }}
                 </a>
                 <div style="clear:both"></div>
                 <br>
                <div class="txt_grey_2 txt_size_3">{{ 'leaveapplication.availablebalance' | translate }}</div>
                {{leaveBalance.currentYearDetail.AvailableBalance}}<span class="txt_size_3">&nbsp;{{ 'balanceinquery.days' | translate }}</span>

            </div>

        </div>


        <div class="detail_box_bottom_area mt0_m">

                <!--<div class="txt_grey_2 txt_size_3">Detail</div>-->
                <div class="btn_balance_inquiry_search_result">
                        <!--
                        <div class="btn_balance_title"></div>
                        <div class="btn_balance_title_num txt_grey_1">2016</div>
                        <div style="clear:both"></div>
                        -->
                        <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'leaveapplication.openingbalance' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1">{{leaveBalance.currentYearDetail.OpenBalance}}</div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_inquiry_search_result_break"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.graceexpiry' | translate }}</div>

                        <div *ngIf="leaveBalance.currentYearDetail.GraceExpiry == '0001-01-01T00:00:00'" class="btn_balance_title_num txt_grey_1"><span>N.A.</span>
                        </div>
                        <div *ngIf="leaveBalance.currentYearDetail.GraceExpiry != '0001-01-01T00:00:00'" class="btn_balance_title_num txt_grey_1"><span>{{commonUI.formatDisplayDate(leaveBalance.currentYearDetail.GraceExpiry, 'D MMM YYYY (ddd)')}}</span>
                        </div>

                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.graceentitlement' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceEntitlement}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.gracetaken' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceTaken}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.gracebalance' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceBalance}}</div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_inquiry_search_result_break"></div>

                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.entitlement' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Entitlement}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.adjustment' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Adjustment}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.taken' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Taken}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.othertakenincate' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.OtherTaken}}</div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_inquiry_search_result_break"></div>

                        <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'leaveapplication.totalbalance' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1">{{leaveBalance.currentYearDetail.TotalBalance}}</div>
                        <!--
                        <div class="btn_balance_title bg_grey_2b">Available Balance</div>
                        <div class="btn_balance_title_num bg_grey_2b">5</div>
                        -->
                        <div style="clear:both"></div>

                </div>

        </div>

	</div>
</div>