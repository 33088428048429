import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'
import { CommonUIService } from '../services-UI/common-ui.service'
import { ErrorUIService } from '../services-UI/error-ui.service'
import { HttpClient, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { InterceptorService } from './interceptor.service'
import { DomSanitizer } from '@angular/platform-browser';


declare var JSNetRSA: any;

@Injectable({
    providedIn: 'root'
})

export class CommonServiceService {
    /** */
    SourceTypeList = [
        {
            seq: 1, sourceTypeCode: "ELEAVE",
            displayName: "approval.leave",
            displayNameForDashboard: "dashboard.leave",
            module: "LeaveApv",
            url: "home.approval-pending-approval",
            numOfPending: 0,
            cssClass: "f_leave"
        },
        {
            seq: 2, sourceTypeCode: "LEAVECAN",
            displayName: "approval.cancellation",
            displayNameForDashboard: "dashboard.leaveCancellation",
            module: "LeaveCancelApv",
            url: "home.approval-pending-approval",
            numOfPending: 0,
            cssClass: "f_cancellation"
        },
        {
            seq: 3, sourceTypeCode: "APPOINTMENT",
            displayName: "approval.appointment",
            displayNameForDashboard: "dashboard.appointment",
            module: "AppointmentApv",
            url: "home.approval-pending-approval",
            numOfPending: 0,
            cssClass: "f_appointment"
        },
        {
            seq: 4, sourceTypeCode: "EINOUT",
            displayName: "approval.einout",
            displayNameForDashboard: "dashboard.einout",
            module: "EInOutApv",
            url: "home.e-in-out-pending-approval",
            numOfPending: 0,
            cssClass: "f_einout"
        },
        {
            seq: 5, sourceTypeCode: "CLAIMHDR",
            displayName: "approval.claim",
            displayNameForDashboard: "dashboard.claim",
            module: "ClaimApv",
            url: "home.approval-pending-approval",
            numOfPending: 0,
            cssClass: "f_claim"
        }
    ];

    constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService, public domSanitizer: DomSanitizer) { }

    getServerEncrypt() {
        sessionStorage.clear()
        var body = { 'Action': environment.apiList.GetServerEncryption };
        return this.http.post(environment.serverHost + '/DO', body)
    }

    login(_key, _usercode, _password) {
        var password
        // sessionStorage.clear();
        sessionStorage.UserCode = _usercode
        password = JSNetRSA.encrypt(_key['Data'].TempSKN, _key['Data'].TempSKE, _password);
        var body = {
            "Action": environment.apiList.Login,
            "UserCode": _usercode,
            "Password": password,
            "TempSKN": _key['Data'].TempSKN
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result
    }

    doChangePassword(_currPwd, _newPassword) {

        var body = {
            "Action": environment.apiList.ChangePwd,
            "SessionID": sessionStorage.SessionID,
            "UserCode": sessionStorage.UserCode,
            "Password": JSNetRSA.encrypt(sessionStorage.TempSKN, sessionStorage.TempSKE, _currPwd),
            "NewPassword": JSNetRSA.encrypt(sessionStorage.TempSKN, sessionStorage.TempSKE, _newPassword)
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result
    }

    loginCustom(_key, _usercode, _password, _esspassword) {
        sessionStorage.UserCode = _usercode;

        var tempSKN = _key['Data'].TempSKN;
        var tempSKE = _key['Data'].TempSKE;
        var password = JSNetRSA.encrypt(tempSKN, tempSKE, _password);
        var essPassword = JSNetRSA.encrypt(tempSKN, tempSKE, _esspassword);
        var body = {
            "Action": environment.apiList.LoginCustom,
            "UserCode": _usercode,
            "Password": password,
            "ESSPassword": essPassword,
            "TempSKN": tempSKN
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result
    }

    setCompany(company) {
        var body = {
            "Action": environment.apiList.SetCompany,
            "SessionID": sessionStorage.SessionID,
            "CompanyCode": company.CompanyCode
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res.body['conn_fail'] = false
            sessionStorage.removeItem("StaffMenu");
            sessionStorage.removeItem("StaffModule");
            sessionStorage.removeItem("StaffInfo");
            sessionStorage.removeItem("LeaveTypeList");
            sessionStorage.removeItem("TakingUnitList");
            sessionStorage.removeItem("AppointmentTypeList");
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))

        return result

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         reset StaffModule, StaffInfo, StaffMenu, LeaveTypeList, TakingUnitList
        //         sessionStorage.removeItem("StaffMenu");
        //         sessionStorage.removeItem("StaffModule");
        //         sessionStorage.removeItem("StaffInfo");
        //         sessionStorage.removeItem("LeaveTypeList");
        //         sessionStorage.removeItem("TakingUnitList");
        //         sessionStorage.removeItem("AppointmentTypeList");
        //         onSuccessCallback(service);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.SetCompany,
        //         "SessionID": sessionStorage.SessionID,
        //         "CompanyCode": company.CompanyCode
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    containerLogin() {
        var body = {
            "Action": environment.apiList.GetContainerLoginSetting
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {

            //Set Session Storage
            sessionStorage.UserCode = res.body['Data'].UserCode;

            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result
    }

    logout() {
        var body = {
            "Action": environment.apiList.Logout,
            "SessionID": sessionStorage.SessionID
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            sessionStorage.clear();
            return
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))

        return result

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined)
        //         onSuccessCallback(service);
        //     sessionStorage.clear();
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.Logout,
        //         "SessionID": sessionStorage.SessionID
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getLastLogin(employee) {
        var result;

        if (sessionStorage.LastLogin != undefined) {
            result = JSON.parse(sessionStorage.LastLogin);
        } else {
            var body = {
                "Action": environment.apiList.GetLastLogin,
                "SessionID": sessionStorage.SessionID,
                "EmploymentRID": employee.RID
            }
            result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
                res.body['conn_fail'] = false
                sessionStorage.LastLogin = JSON.stringify(res.body['Data'].LastLogin)
                return res
            }, err => {
                err['conn_fail'] = true
                return err.body
            }))
        }

        return result

        // var service = this;
        // if (sessionStorage.LastLogin != undefined) {
        //     onSuccessCallback(service, JSON.parse(sessionStorage.LastLogin));
        //     return;
        // }

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         sessionStorage.LastLogin = JSON.stringify(data.Data.LastLogin);
        //         onSuccessCallback(service, data.Data.LastLogin);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetLastLogin,
        //         "SessionID": sessionStorage.SessionID,
        //         "EmploymentRID": employee.RID
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getModule(employee) {
        var body = {
            "Action": environment.apiList.GetModule,
            "SessionID": sessionStorage.SessionID,
            "EmploymentRID": employee.RID
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            sessionStorage.StaffModule = JSON.stringify(res.body['Data'].Module)
            return res
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))

        return result
    }

    getSelfCalendar(employee, year, month) {
        var body = {
            "Action": environment.apiList.GetSelfCalendar,
            "SessionID": sessionStorage.SessionID,
            "EmploymentRID": employee.RID,
            "Year": year,
            "Month": month
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))
        return result
    }

    getTeammateCalendar(employee, year, month) {
        var body = {
            "Action": environment.apiList.GetTeammateCalendar,
            "SessionID": sessionStorage.SessionID,
            "EmploymentRID": employee.RID,
            "Year": year,
            "Month": month
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))
        return result
    }

    getCalendarStaffList(employee) {
        var body = {
            "Action": environment.apiList.GetCalendarStaffList,
            "SessionID": sessionStorage.SessionID,
            "EmploymentRID": employee.RID
        }
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))

        return result

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service, data.Data.StaffInfoList);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetCalendarStaffList,
        //         "SessionID": sessionStorage.SessionID,
        //         "EmploymentRID": employee.RID,
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getAllStaffsViewCalendar(employee, year, month, staffList) {
        var body = {
            "Action": environment.apiList.GetAllStaffsViewCalendar,
            "SessionID": sessionStorage.SessionID,
            "EmploymentRID": employee.RID,
            "Year": year,
            "Month": month,
            "EmploymentRIDList": (staffList.length != 0) ? JSON.stringify(staffList) : "",
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))

        return result

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service, data.Data.Calendar);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetAllStaffsViewCalendar,
        //         "SessionID": sessionStorage.SessionID,
        //         "EmploymentRID": employee.RID,
        //         "Year": year,
        //         "Month": month,
        //         "EmploymentRIDList": (staffList.length != 0) ? JSON.stringify(staffList) : "",
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getApproverRemarkList(sourceRID, sourceType, remarkDate) {

        var body = {
            "Action": environment.apiList.GetApproverRemarkList,
            "SessionID": sessionStorage.SessionID,
            "SourceType": sourceType,
            "SourceRID": sourceRID,
            "StartDate": remarkDate
        };
        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))

        return result

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service, data.Data.ApproverRemarkList);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetApproverRemarkList,
        //         "SessionID": sessionStorage.SessionID,
        //         "SourceType": sourceType,
        //         "SourceRID": sourceRID,
        //         "StartDate": remarkDate
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    viewAttachment(attachmentLink, ext, attachment) {

        var body = { "Action": environment.apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": 'attachment.' + ext }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response', responseType: 'blob' }).pipe(tap(res => {
            var contentType = res.body['type'];
            var blob = new Blob([res.body], { type: contentType });

            var downloadUrl = URL.createObjectURL(blob);
            res.body['dataURL'] = downloadUrl
            // attachment.downloaded = true;
            // attachment.dataURL = downloadUrl;

            // window.open(downloadUrl)
            return res

        }), err => {
            err['conn_fail']
            return err
        })

        return result
        // var actionSuccess = function (data, config) {
        //     var contentType = data.type;
        //     var blob = new Blob([data], { type: contentType });

        //     var downloadUrl = URL.createObjectURL(blob);

        //     attachment.downloaded = true;
        //     attachment.dataURL = downloadUrl;

        //     if (onSuccessCallback != undefined) onSuccessCallback(downloadUrl);
        // }

        // var actionFail = function (data, status, headers, config) {
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": 'attachment.' + ext
        //     }),
        //     "blob"
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data, response.config);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    saveAttachment(attachmentLink, attachmentName) {
        //if (isIOS()) {
        //    this.saveAttachmentFormPost(attachmentLink, attachmentName);
        //    return;
        //}

        //modified by Jonathan 20190215 start - Try to use original attachment name
        //var attachmentExt = "";
        //var attachmentDownloadName = "";
        //if (attachmentName) {
        //    attachmentExt = attachmentName.split('.').pop();
        //    attachmentDownloadName = "attachment." + attachmentExt;
        //}
        var attachmentDownloadName = attachmentName;
        //modified by Jonathan 20190215 end
        var body = { "Action": environment.apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": attachmentDownloadName }
        this.http.post(environment.serverHost + '/DO', body, { observe: 'response', responseType: 'blob' as 'json' }).subscribe((res: any) => {
            var contentType = res.body['type'];
            var blob = new Blob([res.body], { type: contentType });
            // var downloadUrl = URL.createObjectURL(blob);
            var downloadUrl = URL.createObjectURL(blob)

            if (environment.isIE())
                window.navigator.msSaveOrOpenBlob(blob, attachmentDownloadName);
            else {
                var link = document.createElement("a");
                link.href = downloadUrl;
                link.download = attachmentDownloadName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }, err => {
            err['conn_fail']
            return err
        })


        // var actionSuccess = function (data, config) {
        //     var contentType = data.type;
        //     var blob = new Blob([data], { type: contentType });
        //     var downloadUrl = URL.createObjectURL(blob);

        //     if (environment.isIE())
        //         window.navigator.msSaveOrOpenBlob(blob, JSON.parse(config.data.JSON).FileName);
        //     else {
        //         var link = document.createElement("a");
        //         link.href = downloadUrl;
        //         link.download = JSON.parse(config.data.JSON).FileName;
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": environment.apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": attachmentDownloadName
        //     }),
        //     "blob"
        // ).then(
        //     function (response) {
        //         actionSuccess(response['data'], response['config']);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );

    }

    saveAttachmentFormPost(attachmentLink, attachmentName) {
        var attachmentDownloadName = attachmentName;
        var body = {
            "Action": environment.apiList.Download,
            "SessionID": sessionStorage.SessionID,
            "Link": attachmentLink,
            "FileName": attachmentDownloadName
        }

        this.http.post(environment.serverHost + '/DO', body, { observe: 'response', responseType: 'blob' }).subscribe((res) => {
            console.log(res)

            var contentType = res.body['type'];
            var blob = new Blob([res.body], { type: contentType });

            var downloadUrl = URL.createObjectURL(blob)

            var link = document.createElement("a");
            link.href = downloadUrl;
            link.download = attachmentDownloadName;
            link.click();
        })


        //ios chrome, only can view
        // this.JSONFormPostService(
        //     JSON.stringify({
        //         "Action": environment.apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": attachmentDownloadName
        //     })
        // );
    }

    saveAttachmentFormGet(attachmentLink, attachmentName) {
        var attachmentDownloadName = attachmentName;

        this.http.get(environment.serverHost + '/DO', { observe: 'response', responseType: 'blob' }).subscribe((res) => {
            console.log(res);
        })

        // this.JSONFormGetService(
        //     JSON.stringify({
        //         "Action": environment.apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": attachmentDownloadName
        //     })
        // );
    }

    getApproverRemarkTranslateList(remarkList) {
        var approverRemarkList = remarkList;
        let instance = this

        approverRemarkList.forEach(approverRemark => {
            instance.translate.get('approved').subscribe((status_approved) => {
                approverRemark.Remark = approverRemark.Remark.replace("[Approved]", "[" + status_approved + "]");
            })
            instance.translate.get('pending').subscribe((status_pending) => {
                approverRemark.Remark = approverRemark.Remark.replace("[Pending]", "[" + status_pending + "]");
            })
            instance.translate.get('cancelpending').subscribe((status_cancelpending) => {
                approverRemark.Remark = approverRemark.Remark.replace("[CancelPending]", "[" + status_cancelpending + "]");
            })
            instance.translate.get('rejected').subscribe((status_rejected) => {
                approverRemark.Remark = approverRemark.Remark.replace("[Rejected]", "[" + status_rejected + "]");
            })
        });

        return approverRemarkList;
    }

    downloadAttachment(attachmentLink, ext, attachment) {

        var result, body
        if (!environment.isIE()) {
            body = { "Action": environment.apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": 'attachment.' + ext }
            result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response', responseType: 'blob' }).pipe(tap(res => {
                var contentType = res.body['type'];
                var blob = new Blob([res.body], { type: contentType });

                // var downloadUrl = URL.createObjectURL(blob);
                var downloadUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))

                attachment.downloaded = true;
                attachment.dataURL = downloadUrl;
                if (contentType.indexOf("application/") > -1) {
                    var ext = contentType.replace("application/", "").toLowerCase();
                    if (ext == "pdf")
                        attachment.dataURL = "assets/img/icon_pdf.png";
                    else if (ext == "xls" || ext == "xlsx")
                        attachment.dataURL = "assets/img/icon_excel.png";
                    else {
                        attachment.dataURL = "assets/img/icon_claim_maintenance.png";
                    }
                }
            }))
        } else {
            body = { "Action": environment.apiList.Download, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": 'attachment.' + ext }
            result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response', responseType: 'blob' }).pipe(tap(res => {
                var contentType = res.body.type;
                var blob = new Blob([res.body], { type: contentType });

                var downloadUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));

                attachment.downloaded = true;
                attachment.dataURL = downloadUrl;
                if (contentType.indexOf("application/") > -1) {
                    var ext = contentType.replace("application/", "").toLowerCase();
                    if (ext == "pdf")
                        attachment.dataURL = "assets/img/icon_pdf.png";
                    else if (ext == "xls" || ext == "xlsx")
                        attachment.dataURL = "assets/img/icon_excel.png";
                    else
                        attachment.dataURL = "assets/img/icon_claim_maintenance.png";
                }
            }))
            var extension = ext.toLowerCase();
            var imageExtList = ["png", "jpg", "jpeg", "gif"];
            if (imageExtList.indexOf(extension) >= 0)
                attachment.dataURL = environment.serverHost + '/DO?JSON=' + encodeURIComponent(body) + '&X-FlexInstance=' + encodeURIComponent(sessionStorage.FlexInstance);
            else if (extension == "pdf")
                attachment.dataURL = "assets/img/icon_pdf.png";
            else if (extension == "xls" || extension == "xlsx")
                attachment.dataURL = "assets/img/icon_excel.png";
            else
                attachment.dataURL = "assets/img/icon_claim_maintenance.png";
        }

        return result
    }

    /**
     *
     * @param {type} userid
     * @param {type} email
     * @param {type} onSuccessCallback
     * @param {type} onFailCallback
     */

    forgetPwd(userid, email) {
        var body = {
            "Action": environment.apiList.ForgetPwd,
            "UserCode": userid,
            "Email": email
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))

        return result

        // var service = this;
        // if (userid == "" || email == "") return;

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": environment.apiList.ForgetPwd,
        //         "UserCode": userid,
        //         "Email": email
        //     })
        // ).then(
        //     function (response: any) {
        //         console.log('S: ' + response.data)
        //         actionSuccess(response);
        //     }
        //     ,
        //     function (response: any) {
        //         console.log('F: ' + response.data)
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    /**
     *
     * @param {type} token
     * @param {type} onSuccessCallback
     * @param {type} onFailCallback
     */
    validToken(token) {

        var body = {
            "Action": environment.apiList.ValidToken,
            "SessionToken": token
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))

        return result
        // var service = this;

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": environment.apiList.ValidToken,
        //         "SessionToken": token
        //     })
        // ).then(
        //     function (response: any) {
        //         actionSuccess(response);
        //     }
        //     ,
        //     function (response: any) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    /*        Navigate        */
    navigatedLogin() {
        if (environment.isV11Container)
            window.location.href = `${document.baseURI}/api/auth/login`;
        else
            this.router.navigateByUrl("/login"); // use for local test
    }
    /*                        */

    /* From MainCtrl */
    onCallAPIFail(data, status, headers, config) {
        this.commonUI.finishLoadingTask();
        if (status == "200") {
            this.errorUI.showErrorBox(data.ErrorCode);
        } else {
            if (status == "503")
                this.errorUI.showErrorBox(status);
            else {
                this.errorUI.showErrorBox("00035");
                sessionStorage.clear();
            }
            this.navigatedLogin();
        }
    }

    onCallAPIFailWithCallBack(data, status, headers, config, errorBoxCallback) {
        this.commonUI.finishLoadingTask();
        if (status == "200") {
            this.errorUI.showErrorBoxWithCallback(data.ErrorCode, errorBoxCallback);
        } else {
            if (status == "503")
                this.errorUI.showErrorBox(status);
            else {
                this.errorUI.showErrorBox("00035");
                sessionStorage.clear();
            }
            this.navigatedLogin();
        }
    }


    /* Post Request function */
    JSONPostService(json, responseType?) {
        const headers = { 'content-type': 'application/json;charset=UTF-8' }
        return new Promise((resolve, reject) => {
            if (!responseType)
                responseType = 'json'

            return this.http.post(environment.serverHost + '/DO', json, { 'headers': headers })
                .toPromise()
                .then((response: any) => {
                    if (response.data && //logout response no data return
                        response.data.ErrorCode && //some respone no error code
                        response.data.ErrorCode != environment.ErrorCode.NoError) {
                        reject(response)
                    }
                    else {
                        resolve(response)
                    }
                }, err => {
                    reject(err)
                })
        })
    }

    refreshSessToScope(scope) {
        scope.sessionId = sessionStorage.SessionID;
        scope.usercode = sessionStorage.UserCode;
        scope.employee = JSON.parse(sessionStorage.Employee);
        scope.company = JSON.parse(sessionStorage.Company);
        scope.companyList = JSON.parse(sessionStorage.companyList);
        scope.person = JSON.parse(sessionStorage.Person);
        scope.passwordPolicy = JSON.parse(sessionStorage.PasswordPolicy);
        scope.hTML5Link = JSON.parse(sessionStorage.HTML5Link);
        scope.v12HTML5OpenNewWindow = JSON.parse(sessionStorage.V12HTML5OpenNewWindow);
    }

    getStaffInfo(staff) {
        var result
        if (sessionStorage.StaffInfo != undefined) {
            result = JSON.parse(sessionStorage.StaffInfo);
        } else {
            var body = {
                "Action": environment.apiList.GetStaffInfo,
                "SessionID": sessionStorage.SessionID,
                "EmploymentRID": staff.RID
            };
            result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
                res['conn_fail'] = false
                return res.body['Data'].StaffInfo
            }, err => {
                err['conn_fail'] = true
                return err.body
            }))
        }
        return result

        // var service = this;
        // if (sessionStorage.StaffInfo != undefined) {
        //     onSuccessCallback(service, JSON.parse(sessionStorage.StaffInfo));
        //     return;
        // }

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         sessionStorage.StaffInfo = JSON.stringify(data.Data.StaffInfo);
        //         onSuccessCallback(service, data.Data.StaffInfo);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetStaffInfo,
        //         "SessionID": sessionStorage.SessionID,
        //         "EmploymentRID": staff.RID
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getDepartmentList() {

        var body = {
            "Action": environment.apiList.GetDepartmentList,
            "SessionID": sessionStorage.SessionID
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result

        // var service = this;

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service, data.Data.DepartmentList);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetDepartmentList,
        //         "SessionID": sessionStorage.SessionID
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getMenu(employee) {
        var result
        if (sessionStorage.StaffMenu != undefined) {
            ;
            result = JSON.parse(sessionStorage.StaffMenu);

        } else {
            var body = {
                "Action": environment.apiList.GetMenu,
                "SessionID": sessionStorage.SessionID,
                "EmploymentRID": employee.RID
            };
            result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
                res['conn_fail'] = false
                return res.body['Data'].Menu
            }, err => {
                err['conn_fail'] = true
                return err.body
            }))
        }
        return result

        // var service = this;
        // if (sessionStorage.StaffMenu != undefined) {
        //     onSuccessCallback(service, JSON.parse(sessionStorage.StaffMenu));
        //     return;
        // }

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        /*
        data.Data.Menu.push("Calendar");
        data.Data.Menu.push("Report");
        data.Data.Menu.push("ReportPaySlip");
        data.Data.Menu.push("ReportTaxForm");
        */
        //         sessionStorage.StaffMenu = JSON.stringify(data.Data.Menu);
        //         onSuccessCallback(service, data.Data.Menu);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetMenu,
        //         "SessionID": sessionStorage.SessionID,
        //         "EmploymentRID": employee.RID
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    uploadAttachment(attachment) {

        var statusCode = environment.validateUploadAttachment(attachment); //P2019030144
        if (statusCode) { //invalid
            // onFailCallback(attachment, statusCode);
            return;
        }
        var body = {
            attachment: attachment.file,
            request: {
                "Action": environment.apiList.Upload,
                "SessionID": sessionStorage.SessionID,
                "FileName": encodeURIComponent(attachment.file.name)
            }
        };

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'events', reportProgress: true }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))
        return result

        // xhr.open("POST", serverHost + '/DO', true);
        // if (sessionStorage.FlexInstance !== undefined)
        //     xhr.setRequestHeader("X-FlexInstance", sessionStorage.FlexInstance);
        // xhr.send(formData);
    }

    getStaffInfoById(staffid) {

        var body = {
            "Action": environment.apiList.GetStaffInfoByID,
            "SessionID": sessionStorage.SessionID,
            "StaffID": staffid
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))

        return result
        // var service = this;

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service, data.Data.StaffInfo);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetStaffInfoByID,
        //         "SessionID": sessionStorage.SessionID,
        //         "StaffID": staffid
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getSiteDetailList() {

        var body = {
            "Action": environment.apiList.GetSiteDetailList,
            "SessionID": sessionStorage.SessionID
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))

        return result
        // var service = this;

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service, data.Data.SiteDetailList);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetSiteDetailList,
        //         "SessionID": sessionStorage.SessionID
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    getNextApprover(approvalRecord) {
        var eApprovalRecord = {
            "RID": approvalRecord.RID,
            "SourceType": approvalRecord.SourceType,
            "StartDate": approvalRecord.StartDate,
        }

        var body = {
            "Action": environment.apiList.GetNextApprover,
            "SessionID": sessionStorage.SessionID,
            "ApprovalRecord": JSON.stringify(eApprovalRecord)
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))

        return result
    }

    loginAC(acToken, userCode) {
        sessionStorage.clear();
        sessionStorage.UserCode = userCode;

        var body = {
            "Action": environment.apiList.LoginAC,
            "ACToken": acToken,
            "UserCode": userCode
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result

    }

    GetACSessionCriteria(acToken) {

        var body = {
            "Action": environment.apiList.GetACSessionCriteria,
            "ACToken": acToken
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result
    }

    /**
 *
 * @param {type} attachmentLink
 * @param {type} attachmentName
 */
    DownloadAttachmentToken(attachmentLink, attachmentName) {
        //Click URL
        let paramObj = { "Action": environment.apiList.DownloadAttachmentSessionToken, "SessionID": sessionStorage.SessionID, "Link": attachmentLink, "FileName": attachmentName };
        let downloadUrl = this.GetAttachmentURL(paramObj);
        var link = document.createElement("a");
        link.href = downloadUrl;
        link.download = attachmentName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /**
 *
 * @param {type} attachmentLink
 * @param {type} attachmentName
 */
    GetAttachmentToken(attachmentLink, attachmentName) {

        var body = {
            "Action": environment.apiList.GetAttachmentSessionToken,
            "UserCode": sessionStorage.UserCode,
            "SessionID": sessionStorage.SessionID,
            "Link": attachmentLink,
            "FileName": attachmentName
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result
    }

    GetAttachmentURL = function (paramObj) {
        let json = JSON.stringify(paramObj);
        let url = environment.serverHost + '/DO?JSON=' + encodeURIComponent(json) + '&X-FlexInstance=' + encodeURIComponent(sessionStorage.FlexInstance);

        return url;
    }



}