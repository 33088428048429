import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { CommonUIService } from '../../services-UI/common-ui.service'
import { ErrorUIService } from '../../services-UI/error-ui.service'
import { MatDialog } from '@angular/material/dialog';
import { PopupTermsComponent } from '../popup-terms/popup-terms.component'
import { Idle } from '@ng-idle/core';
import { PopupLogoutComponent } from '../popup-logout/popup-logout.component';
import { PopupSaveCompletedComponent } from '../popup-save-completed/popup-save-completed.component';
import { PopupChangePasswordComponent } from '../popup-change-password/popup-change-password.component';
import { PopupLanguagesComponent } from '../popup-languages/popup-languages.component';
import { PopupIntroComponent } from '../popup-intro/popup-intro.component';
import { PopupCurrentVersionComponent } from '../popup-current-version/popup-current-version.component';
import { PopupAboutFlexsystemComponent } from '../popup-about-flexsystem/popup-about-flexsystem.component';

declare var $: any;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit {

	title = "Toolbar";
	toolbarConfig = environment.siteConfig.toolbar;
	language = localStorage.CurrentLanguage !== undefined ? localStorage.CurrentLanguage : "en";

	currentPassword = "";
	newPassword = "";
	newPassword2 = "";

	passwordPolicy = JSON.parse(sessionStorage.PasswordPolicy);
	hideToolBarButton = sessionStorage.HideToolBarButton;
	translationData = {
	    numOfChars: this.passwordPolicy.MinPwdLen
	};

    introImageList = ["intro_1.png", "intro_2.png", "intro_3.png", "intro_4.png", "intro_5.png", "intro_6.png"];
    currentIntroImage = 0;
    translate_px = 0;
    mytransform = "translate(" + this.translate_px + "px, 0px)";
    languageList = [];

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public translate: TranslateService, public router: Router, public matDialog: MatDialog, public idle: Idle) { }

  ngOnInit(): void {

    this.setLanguageList();
    if (this.passwordPolicy.RequireChangePassword) {
      setTimeout(() => { this.displayPasswordPolicy() }, 500);
    }

    this.translate.use(localStorage.CurrentLanguage !== undefined ? localStorage.CurrentLanguage : 'en');
  }

//   showModalLogout = function() {
// 		$('.pp1_content').fadeToggle();
// 	}

// 	closeModalLogout = function() {
// 		$('.pp1_content').hide();
// 	}

	showModalLogout() {
		this.matDialog.open(PopupLogoutComponent, {
		panelClass: 'dialog-responsive'
		})
	}

	showModalSetting = function() {
		$('#toolbarSettingPopup').fadeToggle();
	}

	closeModalSetting = function() {
		$('#toolbarSettingPopup').hide();
	}

	showModalSavedCompleted() {
		this.matDialog.open(PopupSaveCompletedComponent, {
			panelClass: 'dialog-responsive'
		})
	}

	// showModalSavedCompleted = function() {
	// 	$('#popup_saved_completed').fadeToggle();
	// }

	// closeModalSavedCompleted = function() {
	// 	$('#popup_saved_completed').hide();
	// }

	showModalChangePassword() {
		const changedPassword = this.matDialog.open(PopupChangePasswordComponent, {
			panelClass: 'dialog-responsive',
			data: {
				td: this.translationData,
				pp: this.passwordPolicy
			}
		})

		changedPassword.afterClosed().subscribe(data => {
			if (data == null || data == undefined) return;
			this.currentPassword = data.currentPassword
			this.newPassword = data.newPassword
			this.newPassword2 = data.newPassword2
		})
	}

	// showModalChangePassword = function() {
	// 	$('#popup_change_password').fadeToggle();
	// }

	// closeModalChangePassword = function() {
	// 	$('#popup_change_password').hide();
	// }

	showModalResetPassword() {
		const resetPassword = this.matDialog.open(PopupChangePasswordComponent, {
			panelClass: 'dialog-responsive',
			data: {
				td: this.translationData,
				pp: this.passwordPolicy
			},
			disableClose: true
		})

		resetPassword.afterClosed().subscribe(data => {
			if (data == null || data == undefined) return;
			this.currentPassword = data.currentPassword
			this.newPassword = data.newPassword
			this.newPassword2 = data.newPassword2
		})
	}

	// showModalResetPassword = function () {
	//     $('#popup_reset_password').show();
	// }

	// closeModalResetPassword = function () {
	//     $('#popup_reset_password').hide();
	// }

	showModalLanguages() {
		this.matDialog.open(PopupLanguagesComponent, {
			data: this.languageList,
			panelClass: 'dialog-responsive'
		})
	}

	// showModalLanguages = function() {
	// 	$('#popup_languages').fadeToggle();
	// }

	// closeModalLanguages = function() {
	// 	$('#popup_languages').hide();
	// }

	showModalIntroToHrms() {
		this.matDialog.open(PopupIntroComponent, {
			data: this.language,
			panelClass: 'dialog-responsive'
		})
	}

	// showModalIntroToHrms = function() {
	// 	$('#popup_intro_of_hrms').fadeToggle();
	// }

	// closeModalIntroToHrms = function() {
	// 	$('#popup_intro_of_hrms').hide();
	// }

	showModalCurrentVersion() {
		this.matDialog.open(PopupCurrentVersionComponent, {
			panelClass: 'dialog-responsive'
			})
	}

	// showModalCurrentVersion = function() {
	// 	$('#popup_current_version').fadeToggle();
	// }

	// closeModalCurrentVersion = function() {
	// 	$('#popup_current_version').hide();
	// }

	showModalTermsAndConditions() {
		this.matDialog.open(PopupTermsComponent, {
		panelClass: 'dialog-responsive'
		})
	}

	// showModalTermsAndConditions = function() {
	// 	$('#popup_t_and_c').fadeToggle();
	// }

	// closeModalTermsAndConditions = function() {
	// 	$('#popup_t_and_c').hide();
	// }

	showModalAboutFlexsystem() {
		this.matDialog.open(PopupAboutFlexsystemComponent, {
			panelClass: 'dialog-responsive'
			})
	}

	// showModalAboutFlexsystem = function() {
	// 	$('#popup_about_flexsystem').fadeToggle();
	// }

	// closeModalAboutFlexsystem = function() {
	// 	$('#popup_about_flexsystem').hide();
	// }

	// showModalPasswordPolicy = function () {
	//     $('#popup_pw_policy').fadeToggle();
	// }

	// closeModalPasswordPolicy = function() {
	//     $('#popup_pw_policy').hide();
	// }

	displayPasswordPolicy() {
	    var msgCode = "";
	    if (this.passwordPolicy.IsNewAccount)
	        msgCode = "message.M0016";
	    else if (this.passwordPolicy.IsAfterReset)
	        msgCode = "message.M0017";
		else if (this.passwordPolicy.PasswordExpiryDays > 0)
			msgCode = "message.M0018";
	    
		if (!msgCode) {
			this.showModalResetPassword();
		} else if (msgCode == "message.M0018") {
			this.translate.get(msgCode).subscribe((text) => {
				var msgText = text.replace("{PasswordExpiryDays}", this.passwordPolicy.PasswordExpiryDays);
				this.showModalResetPassword();
				this.commonUI.showMsgBox(msgText)
			});
	    } else {
			this.showModalResetPassword();
	        this.commonUI.showMsgBox(msgCode);
	    }
	}

	setLanguageList = function () {
	    var languageList = environment.siteConfig.languages.replace(/ /g, '').split(',');

	    for (var i = 0; i < languageList.length; i++) {
	        var desc = "";
	        switch (languageList[i]) {
	            case "en":
	                desc = "English";
	                break;
	            case "zh_HK":
	                desc = "繁體中文";
	                break;
	            case "zh_CN":
	                desc = "简体中文";
	                break;
	        }
	        this.languageList.push({ value: languageList[i], desc: desc });
	    }
	}

  	goToPrevIntro = function () {
		if (this.currentIntroImage <= 0) return;
		this.currentIntroImage = this.currentIntroImage - 1;

		this.translate_px = (this.currentIntroImage * -280);
		this.mytransform = "translate(" + this.translate_px + "px, 0px)";
	}

	goToNextIntro = function () {
		if (this.currentIntroImage >= this.introImageList.length - 1) return;
		this.currentIntroImage = this.currentIntroImage + 1;

		this.translate_px = (this.currentIntroImage * -280);
		this.mytransform = "translate(" + this.translate_px + "px, 0px)";
	}

	// $(document).ready(function(){
	// 	$('.pp1').click(function(e) {
	// 		$('.pp1_content').fadeToggle();
	// 	})
	// });

	// switchToEn = function() {
	// this.translate.use('en');
	// this.closeModalLanguages();
	// }

	// switchToZhHK = function() {
	// this.translate.use('zh_HK');
	// this.closeModalLanguages();
	// }

	// switchToZhCN = function() {
	// this.translate.use('zh_CN');
	// this.closeModalLanguages();
	// }

	// switchToLang = function (lang) {
	// 	localStorage.CurrentLanguage = lang;
	// 	this.translate.use(lang);
	// 	this.closeModalLanguages();
	// }

	// this.onCallAPIFail = function (data, status, headers, config) {
	// this.commonUI.finishLoadingTask();

	// if (status == "200") {
		// this.errorUI.showErrorBox(data.ErrorCode);
	// } else {
		// $location.path("login");
	// }
	// }

	onLogoutSuccess = function (service) {
	$('#pp1_login_content').fadeOut();
	$('.pp3_content').fadeOut();
	this.commonUI.finishLoadingTask();
	//$location.path("login");
	// $window.location.assign(window.location.pathname);   //Return to base and redirect to login/maintenance
	this.router.navigate(['/' + window.location.pathname])
	}

	onDoChangePasswordSuccess = function (service) {
	this.commonUI.finishLoadingTask();

	this.closeModalChangePassword();
	this.closeModalResetPassword();
	this.commonUI.showMsgBox(!sessionStorage.IsACLogin ? "message.M0003" : "message.M0025");
	this.currentPassword = this.newPassword = this.newPassword2 = null;
	this.passwordPolicy.RequireChangePassword = false;

	sessionStorage.PasswordPolicy = JSON.stringify(this.passwordPolicy);
	}

	logout = function () {
		this.idle.unwatch();

		this.commonUI.addLoadingTask();
		this.commonService.logout(this.onLogoutSuccess, this.onCallAPIFail);
	}

	doChangePassword = function () {
		if (this.currentPassword == "" || this.newPassword == "" || this.newPassword2 == "") return;
	if (this.newPassword != this.newPassword2) {
		this.closeModalChangePassword();
		this.commonUI.showMsgBoxWithCallback("message.M0002", function () {
			this.showModalChangePassword();
		});

	return;
	}
	this.commonUI.addLoadingTask();
	this.commonService.doChangePassword(this.currentPassword, this.newPassword, this.onDoChangePasswordSuccess, this.onCallAPIFail);
	}

	doResetPassword = function () {
	if (this.currentPassword == "" || this.newPassword == "" || this.newPassword2 == "") return;
	if (this.newPassword != this.newPassword2) {
		this.closeModalResetPassword();
		this.commonUI.showMsgBoxWithCallback("message.M0002", function () {
			this.showModalResetPassword();
		});

		return;
	}
	this.commonUI.addLoadingTask();
	this.commonService.doChangePassword(this.currentPassword, this.newPassword, this.onDoChangePasswordSuccess, this.onCallAPIFail);
	}

}