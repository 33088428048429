import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog'

declare var $: any;

@Component({
  selector: 'app-popup-terms',
  templateUrl: './popup-terms.component.html',
  styleUrls: ['./popup-terms.component.scss']
})

export class PopupTermsComponent implements OnInit {



  constructor(public dialogRef: MatDialogRef<PopupTermsComponent>) { }

  ngOnInit(): void {    

  }

  closeModalTermsAndConditions() {
    $('#popup_t_and_c').hide();
    // Run this if it is a modal view
    try {
      this.dialogRef.close()
    } catch {}
  }
}
