<div id="content_report_leave_balance">

    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'menu.report' | translate }}</span>
    </div>

    <!-- =========== Search ========== -->

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_leaveapplicationinquiry"></div>
            <div class="fl">{{ 'reportleavebalance.reportleavebalanceinquiry' | translate }}</div>
        </div>

        <div class="box_content_inquiry">

            <div class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'reportleavebalance.asatday' | translate }}</div>
                <div class="fl input_new_calendar">
                    <div>
                        <mat-form-field appearance='fill' (click)="startPicker.open()">
                            <input matInput [matDatepicker]="startPicker" [(ngModel)]="asAtDay" [min]='minDate' [max]='maxDate' readonly>
                            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                            <mat-datepicker #startPicker (opened)="openAppend()"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #startFooter>
                                <button mat-raised-button  (click)="today()" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close()" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'balanceinquery.leaveType' | translate }}</div>
                <div class="input_field_large">
                    <select class="btn btn_dropdown_1 maincolor_btn_1" autofocus [(ngModel)]="leaveType" name='leave_type'>
                        <option *ngFor="let leavetype of leaveTypeList" [value]="leavetype.Code">{{leavetype.Desc}}</option>
                    </select>

                </div>
            </div>

            <div style="clear:both;"></div>
            <div  class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'leaveapplicationinquery.view' | translate }}</div>
                <div class="onoffswitch" [attr.isteam]="isTeammateViewSelected">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" [(ngModel)]="isTeammateViewSelected" checked (ngModelChange)='isChangedTeamView()'>
                    <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner" [attr.data-content-before]="'leaveapplicationinquery.subordinate' | translate" [attr.data-content-after]="'leaveapplicationinquery.personal' | translate"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </div>

            <div class="box_content_inquiry_field" *ngIf="!isTeammateViewSelected">
                <div class="btn_sub_title txt_grey_2">&nbsp;</div>
                <div class="input_field_small fl">
                    <button class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="asAtCutOffDate()">{{ 'reportleavebalance.cutoffday' | translate }}</button>
                </div>
            </div>
            <div style="clear:both;"></div>
            <div>
                <div class="btn_sub_title txt_grey_2">{{ 'reportleavebalance.approvalgroup' | translate }}</div>

                <div>
                    <!-- <mat-select [(ngModel)]="multipleSelection.selectedApprovalGroupList" multiple name='selected_aprroval_group_list' placeholder="{{ 'reportleavebalance.selectapprovalgroup' | translate }}">
                        <mat-option *ngFor="let item of approvalGroupList" [value]="item">ID: {{item.Code}} - {{item.Desc}}</mat-option>
                    </mat-select> -->

                    <ng-multiselect-dropdown 
                    name='selected_staff'
                    [placeholder]="'reportleavebalance.selectapprovalgroup' | translate"
                    [settings]="approvalGroupListSettings"
                    [data]="approvalGroupList"
                    [(ngModel)]="multipleSelection.selectedApprovalGroupList"
                    >
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div style="clear:both;"></div>
            <hr>
            <div class="inquiry_box_two_btn_center">
                <button id="btn_inquiry_search" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="showSearchResult()"><img src="assets/img/btn_search_a.png" width="18" />{{ 'search' | translate }}</button>
                <button id="btn_inquiry_reset" class="btn btn-block btn-lg two_btn btn_grey" (click)="resetSearchResult()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}</button>
                <div style="clear:both;"></div>
            </div>

        </div>


        <!-- ============ end : content_box ============ -->
    </div>



    <!-- =========== Result ========== -->
    <a name="list_result" class="list_bar_mt" id="anchor_list_result"></a>
    <div class="list_result_area content_box appointmentinquiry_mark" id="listResult">


        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_leaveapplicationinquiry"></div>
            <div class="fl">{{ 'reportleavebalance.reportleavebalanceinquiry' | translate }}</div>
        </div>

        <div style="clear:both;"></div><br />
        <hr class="m0">

        <!-- ============ list_data ============ -->
        <div id="filter_holder" class="list_data" *ngIf='leaveBalanceReportData' [(ngModel)]="leaveBalanceReportData" (click)="viewReport(leaveBalanceReportData.AttachmentPath)" name='leave_balance_data' ngDefaultControl>

            <!-- ============  list1box  ============ -->
            <div class="list_1_box f_2016">
                <div>
                    <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(assets/img/' + commonUI.GetFileIcon(fileFormat) + ')'}">
                        <div class="txt_photo_mask_small"></div>
                    </div>

                    <div class="txt_content_area_1line txt_size_2">
                        <div class="txt_name_2">{{ 'reportleavebalance.reportleavebalance' | translate }}</div>
                    </div>

                    <div style="clear:both;"></div>
                </div>
            </div>
        </div>
        <!-- ============ end list_data ============ -->
    </div>
    <!-- end id=content_box -->
    <div style="display:none"><iframe id="pdf_target" name="pdf_target" style="width:100%;height:100%"></iframe></div>
</div>
