<div class="header">
    <div class="header_bar">
  
      <!-- Sign Off -->
      <div id="toolbarLogoutBtn" class="pp1 header_bar_icon fr" title="Sign Off" *ngIf="!hideToolBarButton" (click)="showModalLogout()" onclick="javascript:void(0)">
  
      </div>
  
      <!-- Setting -->
      <div id="toolbarSettingBtn" class="header_bar_icon fr" title="Setting" *ngIf="!hideToolBarButton" (click)="showModalSetting()">
  
      </div>
      <div id="toolbarSettingPopup" class="alert_box">
        <div class="alert_box_arrow arrow_position_1"></div>
        <div class="alert_box_content txt_grey_2">
            <div class="alert_box_content_title txt_size_3">{{ 'toolbar.setting' | translate }}</div>
          <div class="alert_box_content_item_m">
            <a *ngIf="toolbarConfig.enableChangePassword"><div class="alert_box_content_item icon_change_password" (click)="showModalChangePassword()">
                {{ 'toolbar.changepassword' | translate }}
              </div></a>
            <a *ngIf="toolbarConfig.enableLanguages"><div class="alert_box_content_item icon_languages" (click)="showModalLanguages()">
                {{ 'toolbar.languages' | translate }}
              </div></a>
            <a *ngIf="toolbarConfig.enableSystemIntro"><div class="alert_box_content_item alert_box_content_item_line icon_system_intro" (click)="showModalIntroToHrms()">
                {{ 'toolbar.introtohrms' | translate }}
              </div></a>
            <a *ngIf="toolbarConfig.enableCurrentVersion"><div class="alert_box_content_item icon_current_version" (click)="showModalCurrentVersion()">
                {{ 'toolbar.currentversion' | translate }}
              </div></a>
            <a *ngIf="toolbarConfig.enableTermsAndConditions"><div class="alert_box_content_item icon_terms_and_conditions" (click)="showModalTermsAndConditions()">
                {{ 'toolbar.termsandconditions' | translate }}
              </div></a>
            <a *ngIf="toolbarConfig.enableAbout"><div class="alert_box_content_item alert_box_content_item_line icon_about_flexSystem" (click)="showModalAboutFlexsystem()">
                {{ 'toolbar.aboutflexsystem' | translate }}
              </div></a>
          </div>
        </div>
      </div>
  
      <!-- Notice -->
      <!--
                  <ng-include src="'views/common/toolbar-notice.html'"></ng-include>
                  -->
      <!-- Outstanding Approval -->
      <!--
                  <ng-include src="'views/common/toolbar-outstanding-approval.html'"></ng-include>
                   -->
    </div>
  
  
    <!-- Migrate to common/popup-logout -->
    <!--============ popup : logout  ============ -->
    <!-- <div class="pop_functions_div pp1_content">
      <div class="pop_functions_box pop_functions_box_size_1">
        <br /> <img src="assets/img/msg_alert.png" width="48"> <br /> <br />{{ 'message.M0007' | translate }}<br /> <br />
        <div class="pop_functions_two_btn_center">
          <a id="link_logout" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="logout()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'yes' | translate }}</a>
          <a class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="closeModalLogout()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'no' | translate }}</a>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- end header --> <!-- toolbar -->
  
  
  <!-- Migrate to common/popup-save-completed -->  
  <!--============ popup : finished submit  ============ -->
  <!-- <div id="popup_saved_completed" class="flag_submit_finish_content pop_functions_div" >
    <div class="pop_functions_box pop_functions_box_size_msgauto">
        <br/>
          <img src="assets/img/msg_tick.png" width="48">
          <br/><br/>
        {{ 'message.M0004' | translate }}
        <br/><br/>
    </div>
  </div> -->
  
    <!-- Migrate to common/popup-change-password -->  
  <!--============ popup : Change Password  ============ -->
  <!-- <div id="popup_change_password" class="pop_functions_div" >
    <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition">
        <div class="pop_functions_close btn_grey_2b pp_close" (click)="closeModalChangePassword()">x</div>
        <br/><br/>
        <div style="clear:both"></div>
        <span class="txt_size_1 txt_grey_2">{{ 'toolbar.changepassword' | translate }}</span>
        <br/><br/>
  
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="currentPassword" placeholder="{{ 'toolbar.currentpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <br/>
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="newPassword" placeholder="{{ 'toolbar.newpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="newPassword2" placeholder="{{ 'toolbar.confirmnewpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
        
        
          <div class="pp2 btn_opacity txt_size_3" style="text-align: left;padding-left: 20px;" (click)="showModalPasswordPolicy()">{{ 'toolbar.passwordpolicy' | translate }}</div>
  
          <div class="pop_functions_one_btn_center">
              <button (click)="doChangePassword()" class="btn btn-block btn-lg one_btn maincolor_btn2 flag_submit_finish pp_close" type="submit" name="submit"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'confirm' | translate }}</button>
              <button class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="closeModalChangePassword()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}</button>
              <div style="clear:both"></div>
          </div>
  
    </div>
  </div> -->
  
    <!-- Migrate to common/popup-reset-password -->  
  <!--============ popup : Reset Password  ============ -->
  <!-- <div id="popup_reset_password" class="pop_functions_div" >
    <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition">
        <br/><br/>
        <div style="clear:both"></div>
        <span class="txt_size_1 txt_grey_2">{{ 'toolbar.resetpassword' | translate }}</span>
        <br/><br/>
  
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="currentPassword" placeholder="{{ 'toolbar.currentpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <br/>
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="newPassword" placeholder="{{ 'toolbar.newpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="newPassword2" placeholder="{{ 'toolbar.confirmnewpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <div class="pp2 btn_opacity txt_size_3" style="text-align: left;padding-left: 20px;" (click)="showModalPasswordPolicy()">{{ 'toolbar.passwordpolicy' | translate }}</div>
  
          <div class="pop_functions_one_btn_center">
              <button (click)="doResetPassword()" class="btn btn-block btn-lg one_btn maincolor_btn2 flag_submit_finish pp_close" type="submit" name="submit"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'confirm' | translate }}</button>
          </div>
  
          
          <div style="clear: both"></div>
    </div>
  </div> -->
  
    <!-- Migrate to common/popup-languages -->  
  <!--============ popup : Languages  ============ -->
  <!-- <div id="popup_languages" class="pop_functions_div" >
    <div class="pop_functions_box pop_functions_box_size_company">
      <div class="pop_functions_close btn_grey_2b pp_close" (click)="closeModalLanguages()">x</div>
        <div style="clear:both"></div>
        <br/>
        <span class="txt_size_1 txt_grey_2">{{ 'toolbar.languages' | translate }}</span>
        <br/><br/>
        <div class="languagesposition_content bg_grey_4" style="overflow: hidden;" *ngIf='languageList'>    
            <button id="pp1_login" *ngFor="let language of languageList" (click)="switchToLang(language.value)" class="btn btn-block btn-lg maincolor_btn2 icon_arrow_r pp_close">
                <div class="btn_txt_icon_right">{{ language.desc }}</div>
            </button>
        </div>
  
    </div>
  </div> -->
  
    <!-- Migrate to common/popup-intro -->  
  <!--============ popup : About Intro of HRMS  ============ -->
  <!-- <div id="popup_intro_of_hrms" class="pop_functions_div" >
      <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition" style="overflow:hidden;">
          <div class="pop_functions_close btn_grey_2b pp_close" (click)="closeModalIntroToHrms()">x</div>
          <div style="clear:both"></div>
          <br/>
          <div class="txt_size_1 txt_grey_2">{{ 'toolbar.introtohrms' | translate }}</div>
  
  
          <div (swipeLeft)="goToNextIntro()" (swipeRight)="goToPrevIntro()" class="intro_area_img">
              <div class="imgs" [ngStyle]="{ 'transform': mytransform }">
                  <img *ngFor="let image of introImageList" [src]="'assets/img/' + language + '/' + image" alt="">
              </div>
          </div>
  
          <div class="intro_area">
              <button *ngIf="currentIntroImage > 0" (click)="goToPrevIntro()" ><img src="assets/img/btn_arrow_back.png" width="20" alt=""></button>
              <button *ngIf="currentIntroImage < introImageList.length-1" (click)="goToNextIntro()" ><img src="assets/img/btn_arrow_right.png" width="20" alt=""></button>
          </div>
  
          <div class="img_counter txt_grey_2">{{ currentIntroImage+1 }}/{{ introImageList.length }}</div> -->
  
          <!--
          <div class="intro_area_img">
              <div id="imgs" style="transition-duration: 0.5s; transform: translate(0px, 0px);">
                  <img src="assets/img/intro_1.png" alt="">
                  <img src="assets/img/intro_2.png" alt="">
                  <img src="assets/img/intro_3.png" alt="">
                  <img src="assets/img/intro_4.png" alt="">
                  <img src="assets/img/intro_5.png" alt="">
              </div>
          </div>
  
          <div class="intro_area">
              <button id="t_left"><img src="assets/img/btn_arrow_back.png" width="20" alt=""></button>
              <button id="t_right" href="#"><img src="assets/img/btn_arrow_right.png" width="20" alt=""></button>
          </div>
  
          <div class="img_counter txt_grey_2">1/6</div>
      -->
  
      <!-- </div>
  </div> -->
  
    <!-- Migrate to common/popup-current-version -->  
  <!--============ popup : Current Version  ============ -->
  <!-- <div id="popup_current_version"  class="pop_functions_div" >
    <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition" style="overflow:hidden;">
      <div class="pop_functions_close btn_grey_2b pp_close" (click)="closeModalCurrentVersion()">x</div>
        <div style="clear:both"></div>
          <br/>
          <span class="txt_size_1 txt_grey_2">{{ 'toolbar.currentversion' | translate }}</span>
          <br/><br/>
          <div class="pop_functions_box_content_tc">
  
              <div class="txt_grey_2 txt_size_3">{{ 'toolbar.version' | translate }}</div>
              <span class="txt_size_1">1.00</span>&nbsp;&nbsp;<span class="txt_size_3">(Up-to-date)</span>
              <br/><br/>
  
              <div class="txt_grey_2 txt_size_3">{{ 'toolbar.publicationdate' | translate }}</div>
              <span class="txt_size_1">20 February 2019</span>
              <br/><br/>
  
              <div class="txt_grey_2 txt_size_3">©2019 aCube Solutions Limited. <br/>All Rights Reserved.</div>
  
      </div>
  
    </div>
  </div> -->

  <!-- Dont know still have use or not -->
  <!-- Call t_and_c & password-policy components -->
  <!-- <app-popup-terms></app-popup-terms>
  <app-popup-password-policy></app-popup-password-policy> -->
  
  <!--   
    <ng-include src="'views/common/popup-terms.html'"></ng-include>
    <ng-include src="'views/common/popup-password-policy.html'"></ng-include> 
  -->
  
  
  
  
  <!--============ popup : About FlexSystem  ============ -->
  <!-- <div id="popup_about_flexsystem"  class="pop_functions_div" >
    <div class="pop_functions_box pop_functions_box_size_tc">
      <div class="pop_functions_close btn_grey_2b pp_close" (click)="close()">x</div>
        <div style="clear:both"></div>
          <br/>
          <span class="txt_size_1 txt_grey_2">{{ 'toolbar.aboutflexsystem' | translate }}</span>
          <br/><br/>
          <div class="pop_functions_box_content_tc">
  
              <div>
              aCube Solutions, a member of FlexSystem Group, is a leading IT company that provides new generation of enterprise management software platform to maximize client’s operational efficiency. In the past decade, we understood the unique needs of the every customer and became the trusted partner of thousands of organizations from mid-sized companies to enterprises.
              </div><br/>
  
              <div>
              <img src="assets/img/banner_a.png" width="100%" />
              </div><br/>
  
              <div>
              There are over 2,000 companies using our solutions including 1 in 10 Forbes Global 2000. With our history of proven accomplishments and enthusiasm for technology excellence, aCube guides users to both maximize return on investment and to build competitive advantage.
              </div><br/>
  
              <div>
              <img src="assets/img/banner_b.png" width="100%" />
              </div><br/>
  
              <br/>
              <span class="txt_size_3 txt_grey_2">©2020 aCube Solutions Limited. <br/>All Rights Reserved.</span>
  
      </div>
  
    </div>
  </div> -->