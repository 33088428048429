<!--============ popup : Password Policy  ============ -->
<div>
<div id="popup_pw_policy">
    <div class="pop_functions_box pop_functions_box_size_tc">
    <div class="pop_functions_close btn_grey_2b pp_close" (click)="close()">x</div>
        <div style="clear:both"></div>
        <br/>
        <span class="txt_size_1 txt_grey_2">{{ 'toolbar.passwordpolicy' | translate }}</span>
        <br/><br/>
        <div class="pop_functions_box_content_tc" style="overflow-x: hidden;">

            <div class="row">
                <div class="col-1"><img src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.maxpwdlen' | translate }}</div>
            </div>
            <br />
        
            <div class="row">
                <div class="col-1"><img src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.minpwdlen' | translate : passwordPolicy }}</div>
            </div>
            <br />
        
            <div class="row">
                <div class="col-1"><img *ngIf="passwordPolicy.ExcludeAccountName" src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.excludeaccountname' | translate }}</div>
            </div>
            <br />

            <div class="row">
                <div class="col-1"><img *ngIf="passwordPolicy.ExcludeSpace" src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.excludespace' | translate }}</div>
            </div>
            <br />

            <div class="row">
                <div class="col-1"><img *ngIf="passwordPolicy.IncludeLowercase" src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.includelowercase' | translate }}</div>
            </div>
            <br />

            <div class="row">
                <div class="col-1"><img *ngIf="passwordPolicy.IncludeUppercase" src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.includeuppercase' | translate }}</div>
            </div>
            <br />

            <div class="row">
                <div class="col-1"><img *ngIf="passwordPolicy.IncludeDigit" src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.includedigit' | translate }}</div>
            </div>
            <br />

            <div class="row">
                <div class="col-1"><img *ngIf="passwordPolicy.IncludeSymbol" src="assets/img/tick_valid.png" height="20" width="20"></div>
                <div class="col-10">{{ 'toolbar.includesymbol' | translate }}</div>
            </div>
            <br />
    </div>

    </div>
</div>
</div>