<div id="content_punch_inquiry">

    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'inouttimeapplicationinquiry.inouttime' | translate }}</span>
    </div>

    <!-- =========== Search ========== -->

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_leaveapplicationinquiry"></div>
            <div class="fl">{{ 'inouttimeapplicationinquiry.applicationinquiry' | translate }}</div>
        </div>

        <div class="box_content_inquiry">


            <div class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplicationinquiry.daterange' | translate }}</div>
                <div class="fl mr input_new_calendar">
                    <div>
                        <mat-form-field appearance='fill' (click)="startPicker.open()">
                            <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('start')">
                            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                            <mat-datepicker #startPicker (opened)="openAppend('start')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #startFooter>
                                <button mat-raised-button  (click)="today('start')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('start')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="date_to_line"></div>
                <div class="fl input_new_calendar">
                    <div>
                        <mat-form-field appearance='fill' (click)="endPicker.open()">
                            <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('end')">
                            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                            <mat-datepicker #endPicker (opened)="openAppend('end')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #endFooter>
                                <button mat-raised-button  (click)="today('end')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('end')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="clear:both;"></div>

            <div class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplicationinquiry.status' | translate }}</div>
                <div class="input_field_large">
                    <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="selectedStatus">
                        <option selected value="All">{{ 'inouttimeapplicationinquiry.status_all' | translate }}</option>
                        <option value="Pending">{{ 'inouttimeapplicationinquiry.status_pending' | translate }}</option>
                        <option value="Approved">{{ 'inouttimeapplicationinquiry.status_approved' | translate }}</option>
                        <option value="Rejected">{{ 'inouttimeapplicationinquiry.status_rejected' | translate }}</option>
                    </select>
                </div>
            </div>

            <div class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplicationinquiry.view' | translate }}</div>
                <div class="onoffswitch" attr.isteam="{{isTeammateViewSelected}}">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" [(ngModel)]="isTeammateViewSelected" checked>
                    <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner" attr.data-content-before="{{ 'inouttimeapplicationinquiry.subordinate' | translate }}" attr.data-content-after="{{ 'inouttimeapplicationinquiry.personal' | translate }}"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </div>

            <div style="clear: both"></div>
            <hr>

            <div class="inquiry_box_two_btn_center">
                <button id="btn_inquiry_search" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="showSearchResult()"><img src="assets/img/btn_search_a.png" width="18" />{{ 'search' | translate }}</button>
                <button id="btn_inquiry_reset" class="btn btn-block btn-lg two_btn btn_grey" (click)="resetSearchResult()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}</button>
                <div style="clear:both;"></div>
            </div>

        </div>

        <!-- ============ end : content_box ============ -->
    </div>



    <!-- =========== Result ========== -->
    <a name="list_result" class="list_bar_mt" id="anchor_list_result"></a>
    <div class="list_result_area content_box" id="listResult">



        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_leaveapplicationinquiry"></div>
            <div class="fl">{{ 'inouttimeapplicationinquiry.applicationinquiry' | translate }}</div>
        </div>
        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">
            <div class="btn_small_cross_back" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_arrow_back.png" width="12" (click)="hideSearchResult()"></div>
            <div class="header_bar_icon fl list_icon_border mt0 ml0 btn_filter_inquiry" title="{{ 'inouttimeapplicationinquiry.backtofiltertochangeinquiry' | translate }}" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_filter.png" width="20" /></div>


            <div id="p4" class="header_bar_icon fl list_icon_border mt0" title="{{ 'inouttimeapplicationinquiry.sorts' | translate }}" (click)="toggleSortMenu()"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list">
                <div class="alert_box_arrow arrow_position_4"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'inouttimeapplicationinquiry.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <div class="alert_box_content_item" id="sortItem_0" (click)="sortByType('StaffInfo.FullName')">{{ 'inouttimeapplicationinquiry.sortbyname' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_1" (click)="sortByType('Date')">{{ 'inouttimeapplicationinquiry.sortbydate' | translate }}</div>
                    </div>
                </div>
            </div>

            <input id="myInp" type="text" name="search" placeholder="{{ 'search' | translate }}" size="15" maxlength="15" [(ngModel)]="search.keywords" (ngModelChange)='searchByInput()'><button class="btn_search btn_opacity" title="Search" type="submit" value="submit"></button>

            <div style="clear:both;"></div>

        </div>

        <div style="clear:both;"></div>

        <a name="list_result_filter" class="list_bar_mt"></a>

        <ul id="filter_options">
            <li class="active" (click)="clearFilter()" id="filterItem_0">
                <a href="javascript:void(0)" class="f_all">{{ 'inouttimeapplicationinquiry.status_all' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("")}}</div>
            </li>
            <li (click)="applyFilter('Pending', 1)" id="filterItem_1">
                <a href="javascript:void(0)" class="f_pending">{{ 'inouttimeapplicationinquiry.status_pending' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("Pending")}}</div>
            </li>
            <li (click)="applyFilter('Approved', 2)" id="filterItem_3">
                <a href="javascript:void(0)" class="f_approved">{{ 'inouttimeapplicationinquiry.status_approved' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("Approved")}}</div>
            </li>
            <li (click)="applyFilter('Rejected', 3)" id="filterItem_4">
                <a href="javascript:void(0)" class="f_rejected">{{ 'inouttimeapplicationinquiry.status_rejected' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("Rejected")}}</div>
            </li>
        </ul>

        <div style="clear:both;"></div>

        <hr class="m0">

        <!-- ============ list_data ============ -->
        <div id="filter_holder" class="list_data page_leave_application_inquiry">
            <div *ngFor="let eInOutApplication of eInOutApplicationList; let i = index;">
                <!-- ============  list1box  ============ -->
                <div class="list_1_box list_flag f_{{eInOutApplication.StatusDesc | lowercase}}" style="display:block" (click)="showEInOutApplication(eInOutApplication)">
                    <div>
                        <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(eInOutApplication.StaffInfo) + ')'}">
                            <div class="txt_photo_mask_small p_{{eInOutApplication.StatusDesc | lowercase}}"></div>
                        </div>

                        <div class="txt_content_area_2line txt_size_2">
                            <div class="txt_name">{{eInOutApplication.StaffInfo.FullName}}</div>
                            <div style="clear:both;"></div>
                            <div class="txt_date txt_grey_1">
                                {{commonUI.formatDisplayDate(eInOutApplication.Date, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(eInOutApplication.Date, 'ddd')}})</span>
                            </div>
                            <div class="txt_status"><span class="txt_grey_2 txt_size_3">{{ 'inouttimeapplicationinquiry.status' | translate }}&nbsp;</span>{{eInOutApplication.StatusDesc}}</div>
                        </div>
                        <div style="clear:both;"></div>

                    </div>
                </div>
            </div>
            <div class="f_all f_pending f_cancelpending f_approved f_rejected" style="clear:both;"></div>
        </div>
        <!-- ============ end list_data ============ -->
    </div>
    <!-- end id=content_box -->

    <!-- <in-out-time-detail-popup
        popupmode="popupmode"
        popupcontrol="popupControl"
        popupid="popup_in_out_time_details"
        withdraw-confirm-popupid="withdrawConfirmPopUp"
        approver-remark-list="approverRemarkList"
        withdraw-in-out-event="withdrawInOutTimeApplication()">
    </in-out-time-detail-popup> -->
</div>
