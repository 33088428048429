import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'
import { CommonUIService } from '../services-UI/common-ui.service'
import { ErrorUIService } from '../services-UI/error-ui.service'
import { HttpClient, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { InterceptorService } from '../services/interceptor.service'
import { DomSanitizer } from '@angular/platform-browser';
import { CommonServiceService } from '../services/common-service.service';


declare var JSNetRSA: any;

@Injectable({
    providedIn: 'root'
})

export class CommonServiceServiceMod extends CommonServiceService {
    constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService, public domSanitizer: DomSanitizer) { 
        super(commonUI, errorUI, router, http, translate, interceptor, domSanitizer);
    }

        /** */
        SourceTypeList = [
            {
                seq: 1, sourceTypeCode: "ELEAVE",
                displayName: "approval.leave",
                displayNameForDashboard: "dashboard.leave",
                module: "LeaveApv",
                url: "home.approval-pending-approval",
                numOfPending: 0,
                cssClass: "f_leave"
            },
            {
                seq: 2, sourceTypeCode: "LEAVECAN",
                displayName: "approval.cancellation",
                displayNameForDashboard: "dashboard.leaveCancellation",
                module: "LeaveCancelApv",
                url: "home.approval-pending-approval",
                numOfPending: 0,
                cssClass: "f_cancellation"
            },
            {
                seq: 3, sourceTypeCode: "APPOINTMENT",
                displayName: "approval.appointment",
                displayNameForDashboard: "dashboard.appointment",
                module: "AppointmentApv",
                url: "home.approval-pending-approval",
                numOfPending: 0,
                cssClass: "f_appointment"
            },
            {
                seq: 4, sourceTypeCode: "CLAIMHDR",
                displayName: "approval.claim",
                displayNameForDashboard: "dashboard.claim",
                module: "ClaimApv",
                url: "home.approval-pending-approval",
                numOfPending: 0,
                cssClass: "f_claim"
            },
            {
                seq: 5, sourceTypeCode: "EINOUT",
                displayName: "approval.einout",
                displayNameForDashboard: "dashboard.einout",
                module: "EInOutApv",
                url: "home.e-in-out-pending-approval",
                numOfPending: 0,
                cssClass: "f_einout"
            },
        ];

    getDepartmentList() {

        var body = {
            "Action": environment.apiList.GetDepartmentList,
            "SessionID": sessionStorage.SessionID
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result

        // var service = this;

        // var actionSuccess = function (data) {
        //     if (onSuccessCallback != undefined) {
        //         onSuccessCallback(service, data.Data.DepartmentList);
        //     }
        // }

        // var actionFail = function (data, status, headers, config) {
        //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
        //     return;
        // }

        // this.JSONPostService(
        //     JSON.stringify({
        //         "Action": apiList.GetDepartmentList,
        //         "SessionID": sessionStorage.SessionID
        //     })
        // ).then(
        //     function (response) {
        //         actionSuccess(response.data);
        //     }
        //     ,
        //     function (response) {
        //         actionFail(response.data, response.status, response.headers, response.config);
        //     }
        // );
    }

    loginAC(acToken, userCode) {
        sessionStorage.clear();
        sessionStorage.UserCode = userCode;

        var body = {
                "Action": environment.apiList.LoginAC,
                "ACToken": acToken,
                "UserCode": userCode
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result

    }

    GetACSessionCriteria(acToken) {
        
        var body = {
                "Action": environment.apiList.GetACSessionCriteria,
                "ACToken": acToken
        }

        const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res
        }, err => {
            err['conn_fail'] = true
            return err
        }))
        return result
    }

}
