<!--============ popup : detail of Marital Status ============ -->

<div id='MaritalStatus' class="pp_marital_content" style="display: block;">
	<div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_2column">
    	<div class="pop_functions_close btn_grey_2b pp_close" title="close" (click)="close()">x</div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
              <div class="box_title_icon t_general"></div>
              <div class="fl">{{ 'personalprofileinquiry.detailof' | translate }}{{ 'personalprofile.tabgeneral' | translate }}</div>
        </div>

        <div class="d_box_top"></div>

        <div class="detail_box_bottom_area_profile mt0_m">

            <div class="box_content_inquiry">
            	<form id="form_application">

                    <div class="form_area">

                    	<!-- ========== a record =========== -->
                    	<a name="top_form-1"></a>
                        <div id="form-1" class="form_content form_content_popup">

                        	<div class="form_content_title form_content_title-selected">{{ 'personalprofile.maritalStatus' | translate }}
                            	<span class="txt_label txt_label_profile_action color_label_update txt_size_3">{{ 'personalprofile.typeAction.U' | translate }}</span>
                            </div>

                        	<div class="form_content_input">
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.status' | translate }}</span><br>
                                    {{ 'personalprofile.maritalStatusDesc.'+obj.MaritalStatus | translate}}
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.effectivedate' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(obj.ApprovalDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(obj.ApprovalDate, '(ddd)')}}</span>
                                </div>
                                <div class="full_line" *ngIf="obj.AttachmentLink">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.attachment' | translate }}</span><br>
                                        <div class="attachment_photo">
                                            <div><img [src]="obj.attachment.dataURL"></div>
                                            <div id="dl_attach" class="attachment_photo_touch" (click)="commonHandler.saveAttachment(obj.AttachmentLink, obj.AttachmentName)"></div>
                                        </div>
                                    <div style="clear:both"></div>
                                </div>
                                <div style="clear:both"></div>
                            </div> <!-- ========== end : form_content_input =========== -->
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                        </div> <!-- ========== end : form_content =========== -->





                    </div> <!-- ========== end : form_area =========== -->

            	</form>
            </div> <!-- ========== end : form_content_inquiry =========== -->
		</div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

	</div>
</div>
