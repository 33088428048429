import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-popup-password-finished',
  templateUrl: './popup-password-finished.component.html',
  styleUrls: ['./popup-password-finished.component.scss']
})
export class PopupPasswordFinishedComponent implements OnInit {

  constructor(public router: Router, public dialogRef: MatDialogRef<PopupPasswordFinishedComponent>, public commonService: CommonServiceService) { }

  ngOnInit(): void {
  }

  backToLogin() {
    this.commonService.navigatedLogin();
    this.close()
  }

  close() {
    try {
      this.dialogRef.close()
    } catch {}
  }

}
