import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from '../../services-UI/common-ui.service';
import { CommonServiceService } from '../../services/common-service.service';
import { EclaimService } from '../../services-mod/eclaim.service';
import { ImageEnlargePreviewComponent } from '../../common/image-enlarge-preview/image-enlarge-preview.component';

@Component({
  selector: 'app-popup-claim-confirm',
  templateUrl: './popup-claim-confirm.component.html',
  styleUrls: ['./popup-claim-confirm.component.scss']
})
export class PopupClaimConfirmComponent implements OnInit {

  eclaims;
  employee;
  staffInfo;
  dateMinValue = new Date('0001-01-01T00:00:00').toDateString();

  constructor(public matDialog: MatDialog, public commonService: CommonServiceService ,public commonUI: CommonUIService, public matDialogRef: MatDialogRef<PopupClaimConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, private translate: TranslateService, public eclaimService: EclaimService) { 
    this.eclaims = data['eclaims'];
    this.employee = data['employee'];
    this.staffInfo = data['staffInfo'];
  }

  ngOnInit(): void {
  }

  submitClaimsApplication() {
    var cloneEClaims = [];
    this.eclaims.forEach(eclaim => {
      //Clone object for remove attachment object
      var obj = Object.assign({}, eclaim);
      obj.Attachments = {};
      cloneEClaims.push(obj);
    });

    this.commonUI.addLoadingTask();
    this.eclaimService.submitClaimsApplication(this.employee, cloneEClaims).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.close({confirm: true});
        this.commonUI.finishLoadingTask();
      }
      else {
        this.commonUI.finishLoadingTask();
        result.body['ErrorCode'] = result.body['ErrorCode'];
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }
  
  close(data?) {
    try {
      this.matDialogRef.close(data)
    } catch { }
  }
  
  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}
