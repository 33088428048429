import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { InOutService } from 'src/app/services/in-out.service';

@Component({
  selector: 'app-popup-punch-zoom',
  templateUrl: './popup-punch-zoom.component.html',
  styleUrls: ['./popup-punch-zoom.component.scss']
})
export class PopupPunchZoomComponent implements OnInit {

  popupid;
  popupmode = 'READONLY'
  inOutTimeApplication

  constructor(public commonUI: CommonUIService, public matDialogRef: MatDialogRef<PopupPunchZoomComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) { 
    this.inOutTimeApplication = data['app']
  }

  ngOnInit(): void {
  }
  
  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

}
