<div class="pp8_content" id="previewApp">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_1column">
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_application"></div>
            <div class="fl">{{ 'eclaim.confirmofeclaim' | translate }}?</div>
        </div>
        <div class="confirm_application_position txt_size_1">
            <div class="box_1_staff">
                <div class="txt_photo_area_big fl" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(staffInfo) + ')' }">
                    <div class="txt_photo_mask_big p_normal"></div>
                </div>
                <div class="box_1_staff_name">
                    <span class="txt_size_1">{{staffInfo.FullName}}</span><br>
                    <span class="txt_size_3 txt_grey_2">{{staffInfo.PositionDesc}}</span>
                </div>
                <div style="clear:both"></div>
            </div>
            <div *ngFor='let eclaim of eclaims; let i = index;'>
                <ng-container *ngIf='!eclaim.deleted'>
                    <div class="form_area form_content_title bg_grey_2b"><span class="txt_size_3">No.&nbsp;</span>{{eclaim.index}}</div>

                    <br>
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaim.claimtype' | translate }}</div>
                    <div class="txt_grey_2">{{ eclaim.TypeDesc }}  ({{ eclaim.ClaimTypeCode }})</div>
    
                    <hr class="hr_preview">
    
                    <div style=" margin-right:-10px;">
                        <div class="txt_grey_2 txt_size_3 txt_detail_sub_adjust fl">{{ 'eclaim.claimdate' | translate }}&nbsp;</div>
                        <div class="fl txt_data_confirm txt_size_3 txt_grey_2">
                            {{ commonUI.formatDisplayDate(eclaim.ClaimDate, 'DD MMM YYYY') }} <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(eclaim.ClaimDate, '(ddd)') }}</span>
                        </div>
                        <div style="clear:both"></div>
                    </div>
    
                    <hr class="hr_preview">
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaim.dollar' | translate }}</div>
                    <div class="txt_grey_2">{{eclaim.ClaimCcy}} {{ eclaim.ClaimAmount }}</div>
                    <hr class="hr_preview">
                    <ng-container *ngIf='eclaim.ProjectCode'>
                        <div class="txt_grey_2 txt_size_3">{{ 'eclaim.project_code' | translate }}</div>
                        <div class="txt_grey_2">{{eclaim.ProjectCode}}</div>
                        <hr class="hr_preview">
                    </ng-container>
                    <ng-container *ngIf='eclaim.MAINVOCode'>
                        <div class="txt_grey_2 txt_size_3">{{ 'eclaim.mainvo_code' | translate }}</div>
                        <div class="txt_grey_2">{{eclaim.MAINVOCode}}</div>
                        <hr class="hr_preview">
                    </ng-container>
                    <ng-container *ngIf='eclaim.CarCode'>
                        <div class="txt_grey_2 txt_size_3">{{ 'eclaim.car_code' | translate }}</div>
                        <div class="txt_grey_2">{{eclaim.CarCode}}</div>
                        <hr class="hr_preview">
                    </ng-container>
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaim.max_amount' | translate }}</div>
                    <div class="txt_grey_2">{{eclaim.ClaimCcy}} {{ eclaim.TranMaxAmount }}</div>
                    <hr class="hr_preview" *ngIf='eclaim.TrainingApplicationNo'>
                    <div class="txt_grey_2 txt_size_3" *ngIf='eclaim.TrainingApplicationNo'>{{ 'eclaim.course_no' | translate }}</div>
                    <div class="txt_grey_2" *ngIf='eclaim.TrainingApplicationNo'>{{ eclaim.TrainingApplicationNo }}</div>
                    <hr class="hr_preview">
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaim.remarks' | translate }}</div>
                    <div class="txt_size_3 txt_grey_2">{{ eclaim.Remarks }}</div>
                    <hr class="hr_preview">
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaim.attachment' | translate }}</div>
                    <div class="d-flex flex-row">
                        <ng-container *ngFor='let attach of eclaim.Attachments'>
                            <div class="detail_box_attachment_area">
                                <div class="attachment_photo">
                                    <div><img src="{{attach.dataURL}}"></div>
                                </div>
                                <div style="clear:both"></div>
                            </div>
                        </ng-container>
                    </div>
                    <hr class="hr_preview">
                </ng-container>
            </div>
        </div>
        <br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_multform_finish" (click)="submitClaimsApplication()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'yes' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'no' | translate }}</button>
            <div style="clear:both"></div>
        </div>
    </div>
</div>