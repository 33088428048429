<!--============ popup : About FlexSystem  ============ -->
<div id="popup_about_flexsystem" >
    <div class="pop_functions_box pop_functions_box_size_tc">
      <div class="pop_functions_close btn_grey_2b pp_close" (click)="close()">x</div>
        <div style="clear:both"></div>
          <br/>
          <span class="txt_size_1 txt_grey_2">{{ 'toolbar.aboutflexsystem' | translate }}</span>
          <br/><br/>
          <div class="pop_functions_box_content_tc">
  
              <div>
              aCube Solutions, a member of FlexSystem Group, is a leading IT company that provides new generation of enterprise management software platform to maximize client’s operational efficiency. In the past decade, we understood the unique needs of the every customer and became the trusted partner of thousands of organizations from mid-sized companies to enterprises.
              </div><br/>
  
              <div>
              <img src="assets/img/banner_a.png" width="100%" />
              </div><br/>
  
              <div>
              There are over 2,000 companies using our solutions including 1 in 10 Forbes Global 2000. With our history of proven accomplishments and enthusiasm for technology excellence, aCube guides users to both maximize return on investment and to build competitive advantage.
              </div><br/>
  
              <div>
              <img src="assets/img/banner_b.png" width="100%" />
              </div><br/>
  
              <br/>
              <span class="txt_size_3 txt_grey_2">©2020 aCube Solutions Limited. <br/>All Rights Reserved.</span>
  
      </div>
  
    </div>
  </div>