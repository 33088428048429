import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ImageEnlargePreviewComponent } from '../common/image-enlarge-preview/image-enlarge-preview.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonHandlerService } from '../services/common-handler.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-report-tax-summary',
  templateUrl: './report-tax-summary.component.html',
  styleUrls: ['./report-tax-summary.component.scss']
})
export class ReportTaxSummaryComponent implements OnInit {

  taxSummarylist = [];
  employee = JSON.parse(sessionStorage.Employee);
  taxSummaryfile = {};
  fileFormat = "pdf";
  criteria = {
    FileFormat: this.fileFormat
  };

  constructor(public idleTimer: IdleTimerService, public commonService: CommonServiceService, public commonUI: CommonUIService, public reportService: ReportService, public commonHandler: CommonHandlerService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.getTaxSummaryList();
  }

  getTaxSummaryList() {
    this.commonUI.addLoadingTask();
    this.reportService.getTaxFormList(this.employee).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.taxSummarylist = result.body['Data'].TaxFormList;
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
}

viewTaxSummary(taxSummary) {
  this.commonUI.addLoadingTask();
  this.reportService.getTaxSummaryReport(this.employee, taxSummary, this.criteria).subscribe((result) => {

    if (!result['conn_fail'])
      if (result.body['ErrorMsg'] == null) {
        const taxSummaryLink = result.body['Data'].AttachmentPath
        if (environment.isMobileDevice() || environment.isIE() || this.fileFormat != "pdf") {
          this.commonUI.finishLoadingTask();
          this.commonHandler.saveAttachment(taxSummaryLink, 'TaxSummary.' + this.fileFormat);
        } else {
          this.commonService.viewAttachment(taxSummaryLink, this.fileFormat, this.taxSummaryfile).subscribe((result) => {
            if (result.body['ErrorMsg'] == null) {
              this.matDialog.open(ImageEnlargePreviewComponent, {
                data: {
                  dataURL: result.body['dataURL']
                },
                panelClass: 'dialog-responsive'
              })
              this.commonUI.finishLoadingTask();
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          }, err => {
            this.onCallAPIFail(err.body, err.status, err.headers, err)
          })
        }

      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    else
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  }, err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

onCallAPIFail(data, status, headers, config) {
  this.commonUI.finishLoadingTask();
  this.commonService.onCallAPIFail(data, status, headers, config);
}

}
