<div id="content_approval_pending_approval" class="title_reset_2">
    <span class="txt_size_1 txt_grey_2">{{ 'menu.approvalcentre' | translate }}</span>
    <!-- =========== Result ========== -->
    <div class="list_result_area content_box content_box_show" id="listResult">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_pendingapproval"></div>
            <div class="fl">{{ 'menu.overridependingapproval' | translate }}</div>
        </div>

        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">


            <div id="btn_mult_select" (click)="multiModeToggle()" class="header_bar_icon fl list_icon_border mt0 ml0" title="Multi Select Mode"><img alt="" src="assets/img/btn_mult.png" width="20" /></div>

            <div id="p4" (click)="showSortsMenuToggle()" class="header_bar_icon fl list_icon_border mt0" title="Sorts"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list" *ngIf="showSortsMenu" style="display: block;">
                <div class="alert_box_arrow arrow_position_4"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'approval.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <a (click)="sortByType('StaffInfo.FullName')"><div class="alert_box_content_item">{{ 'approval.sortbyname' | translate }}</div></a>
                        <a (click)="sortByType('StaffInfo.PositionCode')"><div class="alert_box_content_item">{{ 'approval.sortbyposition' | translate }}</div></a>
                        <a (click)="sortByType('StartDate')"><div class="alert_box_content_item">{{ 'approval.sortbydate' | translate }}</div></a>
                        <a (click)="sortByType('LeaveTypeDesc')"><div class="alert_box_content_item">{{ 'approval.sortbytype' | translate }}</div></a>
                    </div>
                </div>
            </div>


            <input id="myInp" type="text" name="search" placeholder="{{ 'search' | translate }}" size="15" maxlength="15" [(ngModel)]="search.keywords"><button class="btn_search btn_opacity" title="Search" type="submit"></button>
            <div style="clear:both;"></div>

        </div><!-- end list_bar -->
        <!-- ============ search result txt  ============ -->
        <div id="search_bar_result">
            <a rel="external" href="#list_result">
                <div id="search_close"><img alt="" src="assets/img/btn_cross_grey.png" width="20" /></div>
            </a>
            Search result of "&nbsp;<span id="search_bar_result_show" class="txt_size_2 maincolor_txt"></span>&nbsp;"<br /><span class="txt_grey_2">(2 staffs)</span>
        </div>

        <div style="clear:both;"></div>

        <a name="list_result_filter" class="list_bar_mt"></a>

            <ul id="filter_options">
                <li *ngFor="let sourceType of sourceTypeList" [ngClass]="{'active': search.SourceType == sourceType.sourceTypeCode }" id="filter_item_{{sourceType.sourceTypeCode}}" (click)="showSearchResult(sourceType.sourceTypeCode)">
                    <ng-container>
                        <a *ngIf="sourceType.sourceTypeCode !== 'LEAVECAN'" class="{{sourceType.cssClass}}">{{ sourceType.displayName | translate }}</a>
                        <a *ngIf="sourceType.sourceTypeCode == 'LEAVECAN'" class="{{sourceType.cssClass}}">{{ 'approval.leavecancellation' | translate }}</a>
                        <div *ngIf="sourceType.sourceTypeCode == 'ELEAVE'" class="filter_icon_num">{{sourceTypeListCountDict["ELEAVE"]}}</div>
                        <div *ngIf="sourceType.sourceTypeCode == 'LEAVECAN'" class="filter_icon_num">{{sourceTypeListCountDict["LEAVECAN"]}}</div>
                        <div *ngIf="sourceType.sourceTypeCode == 'APPOINTMENT'" class="filter_icon_num">{{sourceTypeListCountDict["APPOINTMENT"]}}</div>
                        <!-- Mod Here-->
                        <div *ngIf="sourceType.sourceTypeCode == 'CLAIMHDR'" class="filter_icon_num">{{sourceTypeListCountDict["CLAIMHDR"]}}</div>
                        <div *ngIf="sourceType.sourceTypeCode == 'EINOUT'" class="filter_icon_num">{{sourceTypeListCountDict["EINOUT"]}}</div>
                    </ng-container>
                </li>
            </ul>

            <div style="clear:both;"></div>

        <hr class="m0">


        <!-- ============ list_data ============ -->
        <div id="filter_holder" class="list_data page_pending_approval" data-role="page">

            <!-- ============  list1box  ============ -->
            <!-- <div class="list_1_box list_swipe_flag f_leave" *ngIf="swipeMode" ng-swipe-right="multiModeToggle()"> -->
            <div *ngFor="let obj of objList; let i=index">
                <div attr.listitemsourcetype="{{obj.SourceType}}" id="pending_record_{{i}}" [ngClass]="{'list_1_box-selected': obj.checked, 'f_leave': obj.SourceType == 'ELEAVE', 'f_cancellation': obj.SourceType == 'LEAVECAN', 'f_appointment': obj.SourceType == 'APPOINTMENT'}" class="list_1_box list_swipe_flag" (click)="showPopupLeaveDetail(obj)" (swipeLeft)="swipeModeEnable(obj.index)" (swipeRight)="swipeModeDisable()">


                    <div class="swipe_mode" *ngIf="swipeMode && obj.index == selectedIndex" style="display: block; opacity: 1; right: 0px;">
                        <div class="swipe_txt_hide">{{ 'approval.swiperighttohide' | translate }}</div>
                        <div class="pop_functions_two_btn_center">
                            <button id="btn_SwipeApprove" class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish" (click)="doApprove(obj, '')"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                            <button id="btn_SwipeReject"  class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" (click)="doReject(obj, '')"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                            <div style="clear:both"></div>
                        </div>
                    </div>

                    <div style="clear:both;"></div>

                    <div>
                        <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(obj.StaffInfo) + ')'}">
                            <div class="txt_photo_mask_small p_{{obj.StatusDesc | lowercase}}"></div>
                        </div>

                        <div class="mult_select_checkbox" *ngIf="multiMode" style="display: block;">
                            <input [(ngModel)]="obj.checked" type="checkbox" disabled><label for="select1"><span></span></label>
                        </div>

                        <div *ngIf="obj.SourceType == 'ELEAVE' || obj.SourceType == 'LEAVECAN'" class="txt_content_area_2line txt_size_2">
                            <div class="txt_name txt_name_spaceforlabel">{{ obj.StaffInfo.FullName }}</div>
                            <div *ngIf="obj.SourceType == 'ELEAVE'" class="txt_label_catalog txt_size_3">{{ 'approval.leave' | translate }}</div>
                            <div *ngIf="obj.SourceType == 'LEAVECAN'" class="txt_label_catalog txt_size_3">{{ 'approval.cancellation' | translate }}</div>

                            <div style="clear:both;"></div>
                            <div class="txt_jobposition txt_grey_2 txt_size_3">{{ obj.StaffInfo.PositionDesc }}</div>
                            <div class="txt_date txt_grey_1">
                                {{commonUI.formatDisplayDate(obj.StartDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.StartDate, 'ddd')}})</span> - {{commonUI.formatDisplayDate(obj.EndDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.EndDate, 'ddd')}})</span>
                            </div>
                            <div class="txt_days"><span class="txt_grey_2 txt_size_3">{{ 'approval.numofday' | translate }}&nbsp;</span>{{ obj.NumOfLeaveDay }}</div>
                            <div class="txt_type"><span class="txt_grey_2 txt_size_3">{{ 'approval.type' | translate }}&nbsp;</span><span>{{ obj.LeaveTypeDesc }}</span></div>
                        </div>

                        <div *ngIf="obj.SourceType == 'APPOINTMENT'" class="txt_content_area_2line txt_size_2">
                            <div class="txt_name txt_name_spaceforlabel">{{ obj.StaffInfo.FullName }}</div>
                            <div class="txt_label_catalog txt_size_3">{{ 'approval.appointment' | translate }}</div>

                            <div style="clear:both;"></div>
                            <div class="txt_jobposition txt_jobposition_approval txt_grey_2 txt_size_3">{{ obj.StaffInfo.PositionDesc }}</div>
                            <div class="txt_add_time_area">
                                <div class="txt_date txt_add_time txt_grey_1">{{commonUI.formatDisplayDate(obj.StartDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(obj.StartDate, 'ddd')}})</span>
                                {{commonUI.formatDisplayDate(obj.StartDate, ' h:mm a')}}</div>
                                <div class="txt_add_time_hypen"></div>
                                <div class="txt_date txt_add_time txt_grey_1">{{commonUI.formatDisplayDate(obj.EndDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(obj.EndDate, 'ddd')}})</span>
                                {{commonUI.formatDisplayDate(obj.EndDate, ' h:mm a')}}</div>
                                <div style="clear:both;"></div>
                            </div>
                            <div class="txt_type"><span class="txt_grey_2 txt_size_3">{{ 'approval.type' | translate }}&nbsp;</span><span>{{ obj.AppointTypeDesc }}</span></div>
                        </div>

                        <div *ngIf="obj.SourceType == 'CLAIMHDR'" class="txt_content_area_2line txt_size_2">
                            <div class="txt_name txt_name_spaceforlabel">{{ obj.StaffInfo.FullName }}</div>
                            <div class="txt_label_catalog txt_size_3">{{ 'approval.claim' | translate }}</div>

                            <div style="clear:both;"></div>
                            <div class="txt_jobposition txt_grey_2 txt_size_3">{{ obj.StaffInfo.PositionDesc }}</div>
                            <div class="txt_date txt_grey_1">
                                {{commonUI.formatDisplayDate(obj.StartDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.StartDate, 'ddd')}})</span> - {{commonUI.formatDisplayDate(obj.EndDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.EndDate, 'ddd')}})</span>
                            </div>
                            <div class="txt_days"><span class="txt_grey_2 txt_size_3">{{ 'approval.numofday' | translate }}&nbsp;</span>{{ obj.NumOfLeaveDay }}</div>
                            <div class="txt_type"><span class="txt_grey_2 txt_size_3">{{ 'approval.type' | translate }}&nbsp;</span><span>{{ obj.LeaveTypeDesc }}</span></div>
                        </div>

                        <div style="clear:both;"></div>
                    </div>
                </div>

            </div>
            <div class="f_all f_pending f_cancelpending f_approved f_rejected" style="clear:both;"></div>
        </div><!-- ============ end list_data ============ -->



        <div id="panel_multi_select" class="mult_select_approval_area" *ngIf="multiMode" style="display: block;">
            <div class="pop_functions_two_btn_center">
                <div id="btn_mult_select_all" class="header_bar_icon fl list_icon_border" (click)="toggleSelectAll()"><img alt="" src="assets/img/btn_select_all.png" width="20" /></div>
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="doMultiApprove()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" (click)="doMultiReject()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>

    </div> <!-- end id=content_box -->
</div>
