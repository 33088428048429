import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { PopupConfirmWithdrawComponent } from '../popup-confirm-withdraw/popup-confirm-withdraw.component';
import { PopupSubmitApprovalRemarksComponent } from '../popup-submit-approval-remarks/popup-submit-approval-remarks.component';
import { PopupSubmitRejectRemarksComponent } from '../popup-submit-reject-remarks/popup-submit-reject-remarks.component';

declare var $: any;

@Component({
  selector: 'popup-appointment-detail',
  templateUrl: './popup-appointment-detail.component.html',
  styleUrls: ['./popup-appointment-detail.component.scss']
})
export class PopupAppointmentDetailComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  @ViewChild('endPicker', { static: false }) endPicker: MatDatepicker<any>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  @Input() popupid: "@"
  @Input() editPopupid: "@"
  @Input() withdrawConfirmPopupid: "@"
  @Input() approveRemarkPopupid: "@"
  @Input() rejectRemarkPopupid: "@"
  @Input() approvereason: '='
  @Input() rejectreason: '='
  @Input() popupcontrol: '='
  @Input() typeList: '='
  @Input() popupmode_1: string
  @Input() approverRemarkList_1: []
  @Input() staffInfo: '='
  @Input() editAppointmentEvent: '&'
  @Input() withdrawAppointmentEvent: '&'
  @Input() approveAppointmentEvent: '&'
  @Input() rejectAppointmentEvent: '&'

  appointment;
  approverRemarkList;
  appointmentForEdit = {
    Event: '',
    Desc: '',
    AppointStartDate: new Date(),
    AppointEndDate: new Date(),
    AppointStartHour: new Date(),
    AppointStartMinute: new Date(),
    AppointStartDayPart: new Date(),
    AppointEndHour: new Date(),
    AppointEndMinute: new Date(),
    AppointEndDayPart: new Date(),
    MustInputEventDetail: true
  };

  startDate = new Date()
  endDate = new Date()
  isDelegate = false;
  isOverride = false;
  popupmode;

  employee = JSON.parse(sessionStorage.Employee);

  constructor(public matDialog: MatDialog ,public commonUI: CommonUIService, public matDialogRef: MatDialogRef<PopupAppointmentDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public translate: TranslateService, public appointmentService: AppointmentService) {
    this.appointment = data['app']
    this.approverRemarkList = data['approver_remark_list']
    this.isDelegate = data['isDelegate']
    this.isOverride = data['isoverride']
    this.popupmode = data['popupmode']
  }

  ngOnInit(): void {
    this.showPopup()
  }

  close(data?) {
    try {
      this.matDialogRef.close(data)
    } catch { }
  }

  // editAppointmentApplication = function () {
  //   //var appointStart = $scope.commonUI.formatJsonDate($scope.appointmentForEdit.AppointStartDate, "YYYY-MM-DDT" + $scope.appointmentForEdit.AppointStartHour + ":" + $scope.appointmentForEdit.AppointStartMinute + ":00");
  //   //var appointEnd = $scope.commonUI.formatJsonDate($scope.appointmentForEdit.AppointEndDate, "YYYY-MM-DDT" + $scope.appointmentForEdit.AppointEndHour + ":" + $scope.appointmentForEdit.AppointEndMinute + ":00");

  //   var appointment = this.appointmentPopupControl.getEditedAppointment();
  //   var startDateString = moment(appointment.AppointStartDate).format("YYYY-MM-DDT") +
  //                   appointment.AppointStartHour + ":" + appointment.AppointStartMinute + ":00" +
  //                   appointment.AppointStartDayPart;
  //   appointment.AppointStart = moment(startDateString, "YYYY-MM-DDThh:mm:ssA").format("YYYY-MM-DDTHH:mm:ss");

  //   var endDateString = moment(appointment.AppointEndDate).format("YYYY-MM-DDT") +
  //                   appointment.AppointEndHour + ":" + appointment.AppointEndMinute + ":00" +
  //                   appointment.AppointEndDayPart;
  //   appointment.AppointEnd = moment(endDateString, "YYYY-MM-DDThh:mm:ssA").format("YYYY-MM-DDTHH:mm:ss");

  //   this.commonUI.addLoadingTask();
  //   this.appointmentService.editAppointmentApplication(this.appointment.StaffInfo, appointment);
  // }

  // this.$watch('approvereason', function(newVal, oldVal) {
  //   if (newVal != undefined && newVal.length > 200) {
  //     this.approvereason = oldVal;
  //   }
  // });

  // this.$watch('rejectreason', function(newVal, oldVal) {
  //   if (newVal != undefined && newVal.length > 200) {
  //     this.rejectreason = oldVal;
  //   }
  // });

  submitEdit(form_application) {
    // this.editAppointmentEvent();
  }
  getEditedAppointment = function () {
    return this.appointmentForEdit;
  }
  showPopup() {

    $.extend(this.appointmentForEdit, this.appointment);

    this.appointmentForEdit.AppointStartDate = this.startDate
    this.appointmentForEdit.AppointEndDate = this.endDate

    // this.appointmentForEdit.AppointStartDate = this.commonUI.parseJsonDate(this.appointmentForEdit.AppointStart, 'YYYY-MM-DD').toDate();
    // this.appointmentForEdit.AppointStartHour = this.commonUI.formatJsonDate(this.appointmentForEdit.AppointStart, 'hh');
    // this.appointmentForEdit.AppointStartMinute = this.commonUI.formatJsonDate(this.appointmentForEdit.AppointStart, 'mm');
    // this.appointmentForEdit.AppointStartDayPart = this.commonUI.formatJsonDate(this.appointmentForEdit.AppointStart, 'a').toUpperCase();

    // this.appointmentForEdit.AppointEndDate = this.commonUI.parseJsonDate(this.appointmentForEdit.AppointEnd, 'YYYY-MM-DD').toDate();
    // this.appointmentForEdit.AppointEndHour = this.commonUI.formatJsonDate(this.appointmentForEdit.AppointEnd, 'hh');
    // this.appointmentForEdit.AppointEndMinute = this.commonUI.formatJsonDate(this.appointmentForEdit.AppointEnd, 'mm');
    // this.appointmentForEdit.AppointEndDayPart = this.commonUI.formatJsonDate(this.appointmentForEdit.AppointEnd, 'a').toUpperCase();

    $("#" + this.popupid).fadeIn();
  }
  closePopup = function () {
    $("#" + this.popupid).fadeOut();
  }
  closeEditPopUp = function () {
    $("#" + this.editPopupid).fadeOut();
  }
  showEditPopup = function () {
    $("#" + this.popupid).fadeOut();
    $("#" + this.editPopupid).fadeIn();
  }

  showApprovePopup() {
    const dialog = this.matDialog.open(PopupSubmitApprovalRemarksComponent, {
      panelClass: 'responsive-dialog', 
      data: {
        app: this.appointment,
        popid: 'appointPopUp',
        isDelegate: this.isDelegate,
        isoverride: this.isOverride
      }
    })
    dialog.afterClosed().subscribe((data?) => {
      if(data == null || data == undefined) return; 
      if(data.confirm) 
        this.close(data)
      else 
        this.close()
    })
  }

  showRejectPopup() {
    const dialog = this.matDialog.open(PopupSubmitRejectRemarksComponent, {
      panelClass: 'responsive-dialog', 
      data: {
        app: this.appointment,
        popid: 'appointPopUp',
        isDelegate: this.isDelegate,
        isoverride: this.isOverride
      }
    })
    dialog.afterClosed().subscribe((data?) => {
      if(data == null || data == undefined) return; 
      if(data.confirm) 
        this.close(data)
      else 
        this.close()
    })
  }

  closeApprovePopup = function () {
    $("#" + this.approveRemarkPopupid).fadeOut();
  }

  closeRejectPopup = function () {
    $("#" + this.rejectRemarkPopupid).fadeOut();
  }

  showWithdrawConfirm() {
    const dialog = this.matDialog.open(PopupConfirmWithdrawComponent, {
      panelClass: 'dialog-responsive',
      data: {
        apmapp: this.appointment,
        popid: 'withdrawConfirmPopUp',
        type: 'appointment'
      }
    })

    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data) {
        this.close(data);
      }
    })
  }
  cancelWithdraw = function () {
    $("#" + this.withdrawConfirmPopupid).fadeOut();
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.startDate = new Date()
        instance.startPicker.close()
        break;
      case 'end':
        instance.endDate = new Date()
        instance.endPicker.close()
        break;
    }
  }

  closePicker(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
      case 'end':
        this.endPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
  }

  /*******************/
}
