import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component'
import { CommonUIService } from '../services-UI/common-ui.service'
import { Router } from '@angular/router';
import { CommonServiceService } from '../services/common-service.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { IdleTimerService } from '../services/idle-timer.service';
import { PopupCompanySelectComponent } from '../common/popup-company-select/popup-company-select.component';
//import * as JSNetRSA_2 from '../../assets/js/nrsa/JSNetRSA.js';

declare var $: any;

@Component({
  selector: 'app-container-login',
  templateUrl: './container-login.component.html',
  styleUrls: ['./container-login.component.scss']
})
export class ContainerLoginComponent extends LoginComponent {

  title = "ContainerLogin";

  constructor(public idleTimer: IdleTimerService, public translateService: TranslateService, public matDialog: MatDialog, public commonUI: CommonUIService, public commonService: CommonServiceService, public idle: Idle, public router: Router) {
    super(idleTimer, translateService, matDialog, commonUI, commonService, idle, router);
  }

  ngOnInit(): void {
    this.commonUI.initLoading()
    this.commonUI.addLoadingTask();
    this.login();
    this.commonUI.finishLoadingTask()
  }

  login() {
    this.loginInprogress = true;
    this.commonUI.addLoadingTask();

    if (environment.loginCustom)
      console.log('login custom') // this.commonService.loginCustom(this.userCode, this.password, essPassword, this.onLoginSuccess, this.onCallLoginAPIFail);
    else {
      //Container Login (Get Login Setting and set sessionStorage)
      this.commonService.containerLogin().subscribe((result) => {
        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == '') {
            this.onLoginSuccess(result.body['Data'], result.body['Data'].isTickOldSession);
            this.commonUI.finishLoadingTask();
          }
          else {
            this.commonUI.finishLoadingTask();
            this.onCallLoginAPIFail(result.body, result.status, result.headers, result);
          }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallLoginAPIFail(result.body, result.status, result.headers, result);
        }
      })
    }
  }
}