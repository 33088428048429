import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ApprovalService } from 'src/app/services/approval.service';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-popup-submit-approval-remarks',
  templateUrl: './popup-submit-approval-remarks.component.html',
  styleUrls: ['./popup-submit-approval-remarks.component.scss']
})
export class PopupSubmitApprovalRemarksComponent implements OnInit {

  approveRemarkPopupid
  approvereason = "";
  leaveapplication;
  approver_staffinfo;
  employee = JSON.parse(sessionStorage.Employee);
  remarkPrefixApproved = "[Approved] "
  confirm = false
  isDelegate = false
  special;
  isOverride = false;

  constructor(public matDialog: MatDialog, public commonUI: CommonUIService, public matDialogRef: MatDialogRef<PopupSubmitApprovalRemarksComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public commonService: CommonServiceService, public approvalService: ApprovalService) {
    this.leaveapplication = data['app']
    this.approveRemarkPopupid = data['popid']
    this.isDelegate = data['isDelegate']
    this.special = data['special']
    this.isOverride = data['isoverride']    
    this.getStaffInfo()
  }

  ngOnInit(): void {
  }

  getStaffInfo() {
    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfo(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.approver_staffinfo = result.body['Data'].StaffInfo;
        this.commonUI.finishLoadingTask();
      }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  approveEvent() {
    var approvalRecord = {
      "RID": this.leaveapplication.RID,
      "SourceType": this.leaveapplication.SourceType,
      "StartDate": this.leaveapplication.StartDate
    };
    if (this.isDelegate) {
      if (this.isOverride) {
        this.commonUI.addLoadingTask();
        var approvalRecord = {
          "RID": this.leaveapplication.RID,
          "SourceType": this.leaveapplication.SourceType,
          "StartDate": this.leaveapplication.StartDate
        };
        this.approvalService.overrideApproveRecord(this.employee, 'APV', this.remarkPrefixApproved.concat(this.approvereason), [approvalRecord]).subscribe((result) => {
          if (result.body['ErrorMsg'] == null) {
            this.commonUI.finishLoadingTask();
            this.commonUI.showMsgBox("message.M0008");
            this.confirm = true
            this.close(this.confirm)
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
      } else {
        this.approvalService.delegationApproveRecord(this.employee, 'APV', this.remarkPrefixApproved.concat(this.approvereason), [approvalRecord]).subscribe((result) => {
          if (result.body['ErrorMsg'] == null) {
            this.commonUI.finishLoadingTask();
            this.commonUI.showMsgBox("message.M0008");
            this.confirm = true
            this.close(this.confirm)
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        }) 
      }
    }  else {
      this.approvalService.approveRecord(this.employee, 'APV', this.remarkPrefixApproved.concat(this.approvereason), [approvalRecord]).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0008");
          this.confirm = true
          this.close(this.confirm)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  close(data?) {
    const returnValue = data ? {
      confirm: this.confirm
    } : false;
    try {
      this.matDialogRef.close(returnValue)
    } catch { }
  }
}