import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { PersonalService } from 'src/app/services/personal.service';

declare var $: any;

@Component({
  selector: 'app-popup-personal-profile-bank',
  templateUrl: './popup-personal-profile-bank.component.html',
  styleUrls: ['./popup-personal-profile-bank.component.scss']
})
export class PopupPersonalProfileBankComponent implements OnInit {

  bankList;
  obj;
  selectedBankDesc;
  fileID = 0;
  fileProgress = 0;
  employee = JSON.parse(sessionStorage.Employee);

  constructor(public matDialogRef: MatDialogRef<PopupPersonalProfileBankComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public errorUI: ErrorUIService, public commonUI: CommonUIService, public commonService: CommonServiceService, public personalService: PersonalService) { 
    this.obj = data['obj']
    this.bankList = data['bankList']
  }

  ngOnInit(): void {
  }

  submitEvent() {
    var bankACForm = {
      "EmploymentRID": this.employee.RID,
      "AccountNum": this.obj.AccountNum,
      "AccountName": this.obj.AccountName,
      "BankCode": this.obj.BankCode || "",
      "BankACRID": this.obj.BankACRID,
      "AttachmentName": "",
      "AttachmentAddr": ""
    };
    if (this.obj.attachments.length > 0) {
        for (var i = 0; i < this.obj.attachments.length; i++) {
            if (!this.obj.attachments[i].deleted) {
                bankACForm.AttachmentName = this.obj.attachments[i].file.name;
                bankACForm.AttachmentAddr = this.obj.attachments[i].uploadFilename;
            }
        }
    }
    this.commonUI.addLoadingTask();
    this.personalService.submitBankAccount(bankACForm).subscribe((result) => {
      if(!result['conn_fail'])
      if(result.body['ErrorMsg'] == null) {
        this.commonUI.finishLoadingTask();
        this.commonUI.showMsgBox("message.M0001");
        this.obj.IsEditable = true;
        this.close()
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    else 
      this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  convertSizeFormat(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  };

  attachmentExisted() {
    if (this.obj == undefined) return false;
    if (this.obj.attachments == undefined) return false;
    return this.obj.attachments.filter(function (attach) {
        return !attach.deleted;
    }).length > 0;
  }
  
  uploadListening(event) {
    var files = event.target.files;
    var uuid = event.target.attributes.uuid;
    /*
    if ((this.countFilesReadyForUpload() + files.length) > 3) {
        this.commonUI.finishLoadingTask();
        this.commonUI.showWarning("message.M0005");
        this.$apply();
        return;
    }
    */
    for (var i = 0; i < files.length; i++) {
      var attachment = { fileID: this.fileID++, file: null, dataURL: null, uploadProgress: 0, uploadFilename: "", cancelled: false, uploaded: false, deleted: false };
      attachment['file'] = files[i];
      attachment['uuid'] = uuid;
      this.setPreview(attachment);
    }
    //this.commonUI.finishLoadingTask();
  }

  setPreview(attachment) {
    let instance = this
    var fileReader = new FileReader();
    fileReader.readAsDataURL(attachment.file)
    fileReader.onload = () => {
      if(attachment.file.type.match('image.*')) {
        attachment.dataURL = fileReader.result;
      } 
      else if(attachment.file.type.match('pdf.*'))
          attachment.dataURL = "assets/img/icon_pdf.png";
      else if(attachment.file.type.match('xls.*') || attachment.file.type.match('xlsx.*') || attachment.file.type.match('vnd.ms-excel.*'))
          attachment.dataURL = "assets/img/icon_excel.png";
      else 
        attachment.dataURL = "assets/img/icon_claim_maintenance.png";
      instance.obj.attachments.push(attachment);
      $("#btn_delete_attach_" + attachment.fileID).hide();
      this.uploadAttachment(attachment);
    }
  }

  uploadAttachment(attachment) {
    $("#btn_upload_attach_" + attachment.fileID).hide();
    this.commonService.uploadAttachment(attachment).subscribe((result) => {
      if(result.type == HttpEventType.UploadProgress) {
        this.fileProgress = Math.round(100 * result.loaded / result.total);
      } else if(result instanceof HttpResponse) {
        if(!result['conn_fail']) {
          $("#btn_delete_attach_" + attachment.fileID).show();
          $("#btn_upload_attach_" + attachment.fileID).hide();
          $("#btn_cancel_attach_" + attachment.fileID).hide();
          $("#file_UploadAttachment").val('');
          $("#file_BankUploadAttachment").val('');
          $("input[uuid*='" + attachment.uuid + "']").val('');
          attachment.uploadFilename = result.body['Data'].UploadFileName
        } else 
        this.onCallAPIFail(result.body, result.status, result.headers, result.body['Data'])
      }
    }, err => {
      this.fileProgress = 0
      $("#file_UploadAttachment").val('');
      $("#file_BankUploadAttachment").val('');
      $("input[uuid*='" + attachment.uuid + "']").val(''); //use uuid delete
      this.deleteAttachment(attachment);
      // this.commonUI.showMsgBox("status." + err);
      this.commonUI.showMsgBox("status." + err.status + ': ' + err.statusText);
      //if (error != undefined && error == 501)
      //    this.commonUI.showMsgBox("error.00022");
      //else
      //    this.commonUI.showMsgBox("error.00021");
    })
  }

  deleteAttachment(attachment) {
    //Worked
    attachment.deleted = true;
    $("#attach_" + attachment.fileID).fadeOut();
    this.obj.attachments = this.obj.attachments.filter(o => o.deleted == false);
    //this.obj.attachments = []
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

}
