import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupPasswordPolicyComponent } from '../popup-password-policy/popup-password-policy.component';

@Component({
  selector: 'app-popup-reset-password',
  templateUrl: './popup-reset-password.component.html',
  styleUrls: ['./popup-reset-password.component.scss']
})
export class PopupResetPasswordComponent implements OnInit {

  currentPassword: string;
  newPassword: string;
  newPassword2: string

  constructor(public matDialogRef: MatDialogRef<PopupResetPasswordComponent>, public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: {}) { }

  ngOnInit(): void {
  }

  doResetPassword() {
    return
  }

  showModalPasswordPolicy() {
    this.matDialog.open(PopupPasswordPolicyComponent, {
      panelClass: 'dialog-responsive',
      data: this.data
    })
  }

  close() {
    const returnValue = {
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      newPassword2: this.newPassword2
    }
    try {
      this.matDialogRef.close(returnValue)
    } catch {}
  }


}
