import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { PersonalService } from 'src/app/services/personal.service';

@Component({
  selector: 'app-popup-personal-profile-contact',
  templateUrl: './popup-personal-profile-contact.component.html',
  styleUrls: ['./popup-personal-profile-contact.component.scss']
})
export class PopupPersonalProfileContactComponent implements OnInit {

  obj;
  displaySwitch;
  employee = JSON.parse(sessionStorage.Employee);

  constructor(public matDialogRef: MatDialogRef<PopupPersonalProfileContactComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public commonUI: CommonUIService, public commonService: CommonServiceService, public personalService: PersonalService) { 
    this.obj = data
  }

  ngOnInit(): void {
  }

  submitEvent() {
    var contactForm = {
      "EmploymentRID": this.employee.RID,
      "Email": this.obj.Email,
      "Email2": this.obj.Email2,
      "TelNum1": this.obj.TelNum1,
      "TelNum2": this.obj.TelNum2,
      "MobileNum1": this.obj.MobileNum1,
      "MobileNum2": this.obj.MobileNum2,
      "AttachmentName": "",
      "AttachmentAddr": ""
    };
    this.commonUI.addLoadingTask();
    this.personalService.submitContactStatus(contactForm).subscribe((result) => {
      if(result.body['ErrorMsg'] == null) {
        this.commonUI.finishLoadingTask();
        this.commonUI.showMsgBox("message.M0001");
        this.obj.IsContactEditable = false;
        this.close()
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    })
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
