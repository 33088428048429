<div id="{{ popupid }}" class="list_swipe_flag-selected">
    <div
         [ngClass]="popupmode == 'READONLY'? 'pop_functions_box pop_functions_box_size_2column' : 'pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval'"
    >
        <div class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top" *ngIf='leaveapplication'>
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(leaveapplication.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
                <span class="txt_popup_staffname">{{ leaveapplication.StaffInfo.FullName }}</span><br>
                <span class="txt_popup_position">{{ leaveapplication.StaffInfo.PositionDesc }}</span><br>
                <span class="txt_popup_staffid">ID : {{ leaveapplication.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right" *ngIf='leaveapplication'>

            <div class="detail_box_bottom_2point">
                <div class="detail_box_catalog_area">
                    <div class="maincolor_bg color_label_{{ leaveapplication.StatusDesc | lowercase }}">{{ leaveapplication.StatusDesc | lowercase | translate }}</div>
                </div>
                <div class="detail_box_session1 txt_grey_2 txt_size_3">{{startDateTakingUnit}}</div>
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(leaveapplication.StartDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(leaveapplication.StartDate, 'ddd')}})</span></div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{endDateTakingUnit}}</div>
                <div class="detail_box_date2 txt_size_1">{{commonUI.formatDisplayDate(leaveapplication.EndDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(leaveapplication.EndDate, 'ddd')}})</span></div>
                <div class="detail_box_sub_icon d_box_sub_icon_leave"></div>
                <div class="detail_box_date_num maincolor_txt"><span class="txt_size_0">{{ leaveapplication.NumOfLeaveDay }}</span><span class="txt_size_3">&nbsp;{{ 'balanceinquery.days' | translate }}</span></div>
                <div class="detail_box_type">{{ leaveapplication.LeaveTypeDesc }}</div>
            </div>

            <div class="detail_box_other txt_size_1">

                <div class="txt_grey_2 txt_size_3">{{ 'approval.submittedon' | translate }}</div>
                {{commonUI.formatDisplayDate(leaveapplication.CreateDate, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(leaveapplication.CreateDate, 'ddd')}})</span>&nbsp;{{commonUI.formatDisplayDate(leaveapplication.CreateDate, 'h:mm a')}}<br />
                <div class="txt_grey_2 txt_size_3">
                    {{ 'approval.description' | translate }}

                    <div class="remark_tabs">
                        <div class="tab first">
                            <input type="radio" id="leave-tab-r1" name="tab-group-1" [checked]="leaveapplication.SourceType =='ELEAVE'">
                            <label for="leave-tab-r1">{{ 'approval.application' | translate }}</label>
                            <div class="content txt_box_1 txt_grey_1 txt_size_2" style="white-space: pre-wrap;">{{ leaveapplication.Description }}</div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="leave-tab-r2" name="tab-group-1" [checked]="leaveapplication.SourceType =='LEAVECAN'">
                            <label for="leave-tab-r2">{{ 'approval.cancellation' | translate }}</label>
                            <div class="content txt_box_1 txt_grey_1 txt_size_2" style="white-space: pre-wrap;">{{ leaveapplication.CancelReason }}</div>
                        </div>
                    </div>
                </div>

                <div class="txt_grey_2 txt_size_3">{{ 'approval.attachment' | translate }}</div>
                <div class="detail_box_attachment_area">
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink1">
                        <div><img [src]="attachment1.dataURL" *ngIf="leaveapplication.AttachmentLink1"></div>
                        <div id="dl_attach1" class="attachment_photo_touch" (click)="viewAttachment(leaveapplication.AttachmentLink1, leaveapplication.AttachmentName1)"></div>
                    </div>
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink2">
                        <div><img [src]="attachment2.dataURL" *ngIf="leaveapplication.AttachmentLink2"></div>
                        <div id="dl_attach2" class="attachment_photo_touch" (click)="viewAttachment(leaveapplication.AttachmentLink2, leaveapplication.AttachmentName2)"></div>
                    </div>
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink3">
                        <div><img [src]="attachment3.dataURL" *ngIf="leaveapplication.AttachmentLink3"></div>
                        <div id="dl_attach3" class="attachment_photo_touch" (click)="viewAttachment(leaveapplication.AttachmentLink3, leaveapplication.AttachmentName3)"></div>
                    </div>
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink4">
                        <div><img [src]="attachment4.dataURL" *ngIf="leaveapplication.AttachmentLink4"></div>
                        <div id="dl_attach4" class="attachment_photo_touch" (click)="viewAttachment(leaveapplication.AttachmentLink4, leaveapplication.AttachmentName4)"></div>
                    </div>
                    <div style="clear:both"></div>
                </div>

            </div>
        </div>


        <div class="detail_box_bottom_area mt0_m">

            <div class="txt_grey_2 txt_size_3">{{ 'approval.approverremarks' | translate }}</div>
            <div class="detail_box_approver_remarks_area" *ngIf='approverRemarkList'>
                <div *ngFor="let approverRemark of approverRemarkList">
                    <div>
                        <div class="txt_photo_area_small fl" [ngStyle]="{'background-image':'url('+ commonUI.getStaffImage(approverRemark.StaffInfo) + ')'}">
                            <div class="txt_photo_mask_small p_normal_grey"></div>
                        </div>
                        <div class="approver_staff_content">
                            <div class="txt_bold">{{approverRemark.StaffInfo?.FullName}}</div>
                            <div class="txt_grey_2 txt_size_3">{{commonUI.formatDisplayDate(approverRemark.ProcessDate, 'D MMM YYYY (ddd) - h:mm A')}}</div>
                            <!--<div class="approver_label txt_size_3">
                                <span class="color_label_{{approverRemark.StatusDesc | lowercase}}">{{approverRemark.StatusDesc}}</span>
                            </div>-->
                            <div class="approver_staff_remark txt_box_1">{{approverRemark.Remark}}</div>
                        </div>
                        <div style="clear:both"></div>
                    </div>

                    <hr class="hr_profile">
                </div>
            </div>
            <button class="btn_next_approver" (click)="commonUI.showNextApprover(leaveapplication)">
                <img src="assets/img/btn_show_next_approver.png" width="18">
                {{'show-next-approver' | translate}}
            </button>
        </div>
    </div>

    <div *ngIf="popupmode == 'APPROVAL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center" style="width:auto;">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" style="margin-left:30px;margin-bottom: 3px;" (click)="showApprovePopup()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" style="margin-bottom: 3px;" (click)="showRejectPopup()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                <button class="btn btn-block btn-lg btn_grey" style="width:150px;float:right;margin-right:35px;" (click)="calStaffLeaveBalance()">
                    <img src="assets/img/btn_search_a.png" width="18" />{{ 'leaveapplication.balanceinquiry' | translate }}
                </button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

    <div *ngIf="popupmode == 'CANCEL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn btn_red pp_close flag_submit_finish" (click)="showCancelPopup()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplicationinquery.leavecancellation' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

    <div *ngIf="popupmode == 'WITHDRAW'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn btn_red pp_close flag_submit_finish" (click)="showWithdrawConfirm()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplicationinquery.leavewithdrawal' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
</div>