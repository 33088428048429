import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss']
})
export class PopupMessageComponent implements OnInit {

  msgcode: string

  constructor(public matDialogRef: MatDialogRef<PopupMessageComponent>, public commonUI: CommonUIService, @Inject(MAT_DIALOG_DATA) public data) { 
    this.msgcode = data
  }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

}
