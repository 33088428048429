import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ImageEnlargePreviewComponent } from '../common/image-enlarge-preview/image-enlarge-preview.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonHandlerService } from '../services/common-handler.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-report-payslip',
  templateUrl: './report-payslip.component.html',
  styleUrls: ['./report-payslip.component.scss']
})
export class ReportPayslipComponent implements OnInit {

  payslipYear = moment().get("year");
  paysliplist = [];
  employee = JSON.parse(sessionStorage.Employee);
  payslipfile = {};
  payslipYearlist = [];
  fileFormat = "pdf";
  criteria = {
    FileFormat: this.fileFormat
  }

  constructor(public idleTimer: IdleTimerService, public commonService: CommonServiceService, public commonUI: CommonUIService, public reportService: ReportService, public commonHandler: CommonHandlerService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.getPaySlipYearList();
  }

  getPaySlipYearList() {
    this.commonUI.addLoadingTask();
    this.reportService.getPaySlipYearList(this.employee).subscribe((result) => 
    {
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.payslipYearlist = result.body['Data'].PaySlipYearList;
          this.commonUI.finishLoadingTask();
  
          if (this.payslipYearlist.length > 0)
              this.selectPaySlipYear(this.payslipYearlist[0]);
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
}

selectPaySlipYear(year) {
  this.payslipYear = year;
  this.getPaySlipList();
}

getPaySlipList() {
  this.commonUI.addLoadingTask();
  this.reportService.getPaySlipList(this.employee, this.payslipYear).subscribe((result) => 
  {
    if(!result['conn_fail'])
      if(result.body['ErrorMsg'] == null) {
        this.paysliplist = result.body['Data'].PaySlipList;
        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    else 
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  },err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

viewPaySlip(payslip) {
  this.commonUI.addLoadingTask();
  this.reportService.getPaySlip(this.employee, payslip.Year, payslip.Month, payslip.Ext, this.criteria).subscribe((result) => 
  {
    
    if(!result['conn_fail'])
      if(result.body['ErrorMsg'] == null) {
        const payslipLink = result.body['Data'].AttachmentPath
        if (environment.isMobileDevice() || environment.isIE() || this.fileFormat != "pdf") {
          this.commonUI.finishLoadingTask();
          this.commonHandler.saveAttachment(payslipLink, 'Payslip.' + this.fileFormat);
      } else {
          this.commonService.viewAttachment(payslipLink, this.fileFormat, this.payslipfile).subscribe((result) => {
            if (result.body['ErrorMsg'] == null) {
              this.matDialog.open(ImageEnlargePreviewComponent, {
                data: {
                  dataURL: result.body['dataURL']
                },
                panelClass: 'dialog-responsive'
              })
              this.commonUI.finishLoadingTask();
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          }, err => {
            this.onCallAPIFail(err.body, err.status, err.headers, err)
          })
      }

      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    else 
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  },err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

onCallAPIFail(data, status, headers, config) {
  this.commonUI.finishLoadingTask();
  this.commonService.onCallAPIFail(data, status, headers, config);
}

}
