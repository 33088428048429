import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PopupClaimConfirmComponent } from '../../common_mod/popup-claim-confirm/popup-claim-confirm.component';
import { PopupDetailOfClaimBalanceComponent } from '../../common/popup-detail-of-claim-balance/popup-detail-of-claim-balance.component';
import { CommonUIService } from '../../services-UI/common-ui.service'
import { ErrorUIService } from '../../services-UI/error-ui.service';
import { CommonHandlerService } from '../../services/common-handler.service';
import { CommonServiceService } from '../../services/common-service.service';
import { EclaimService } from '../../services-mod/eclaim.service';

declare var $: any;

@Component({
  selector: 'app-eclaim',
  templateUrl: './eclaim.component.html',
  styleUrls: ['./eclaim.component.scss']
})
export class EclaimComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChildren('startPicker') startPicker: QueryList<MatDatepicker<any>>;
  @ViewChildren('endPicker') endPicker: QueryList<MatDatepicker<any>>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear(), 5, 1);
  maxDate = new Date();
  /*********** */

  eclaims = [];
  selectedIndex;
  attachments = [];
  numOfClaim = 0;
  activeClaim;
  staffInfo;
  employee = JSON.parse(sessionStorage.Employee);
  fileID = 0;
  fileProgress = 0;
  courseCodeList;
  claimTypeList;
  currencyList;
  dateMinValue = new Date('0001-01-01T00:00:00').toDateString();
  notAllowBalanceInquiry = false;

  /* PlanInHRMS */
  projectCodeList = [];
  carCodeList = [];
  // mainvoCodeList = [];
  expenseTypeCodeList = [];
  /* **************** */


  constructor(public errorUI: ErrorUIService, public commonUI: CommonUIService, public commonService: CommonServiceService, public commonHandler: CommonHandlerService, public matDialog: MatDialog, public eclaimService: EclaimService, private translate: TranslateService) {

  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.commonUI.addLoadingTask();
    this.runSyncFunction()
    this.commonUI.finishLoadingTask();
  }

  async runSyncFunction() {
    const a = await this.getStaffInfo();
    const b =  await this.getClaimType();
    const c = await this.getCurrencyList();
    const d = await this.getProjectCodeList();
    const e = await this.getCarCodeList();
    const f = await this.getExpenseTypeCodeList();
    Promise.all([a,b,c,d,e,f]).then(() => this.addEClaim())
  }

  async getMAINVOCodeList(projectCode) {
    return new Promise(resolve => {
      this.eclaimService.getMAINVOCodeList(projectCode, this.employee).subscribe((result) => {
        if(result.body['ErrorMsg'] == null) {
      //     this.mainvoCodeList = result.body['Data'].MAINVOCodeList;
		  // this.mainvoCodeList = this.orderMainVOCode(this.mainvoCodeList);
          let mainvoCodeList = result.body['Data'].MAINVOCodeList;
          mainvoCodeList = this.orderMainVOCode(mainvoCodeList);
          resolve(mainvoCodeList);
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    })
  }

  async getExpenseTypeCodeList() {
    return new Promise(resolve => {
      this.eclaimService.getExpenseTypeCodeList(this.employee).subscribe((result) => {
        if(result.body['ErrorMsg'] == null) {
          this.expenseTypeCodeList = result.body['Data'].ExpenseTypeCodeList;
          resolve(this.expenseTypeCodeList);
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    })
  }

  async getCarCodeList() {
    return new Promise(resolve => {
      this.eclaimService.getCarCodeList(this.employee).subscribe((result) => {
        if(result.body['ErrorMsg'] == null) {
          this.carCodeList = result.body['Data'].CarCodeList;
          resolve(this.carCodeList);
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    })
  }

  async getProjectCodeList() {
    return new Promise(resolve => {
      this.eclaimService.getProjectCodeList(this.employee).subscribe((result) => {
        if(result.body['ErrorMsg'] == null) {
          this.projectCodeList = result.body['Data'].ProjectCodeList;
		  if(this.projectCodeList.length > 0)
			this.projectCodeList = this.projectCodeList.sort(this.sortAlphaNum);
          resolve(this.projectCodeList.sort(this.sortAlphaNum));
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    })
  }

  async getClaimType() {
    return new Promise(resolve => {
      this.eclaimService.getClaimType(this.employee).subscribe((result) => {
        if(result.body['ErrorMsg'] == null) {
          this.claimTypeList = result.body['Data'].ClaimTypeList;
          this.claimTypeList = this.claimTypeList.filter((t) => t.EnableAppInESS)
          resolve(this.claimTypeList);
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    })
  }
  
  async getCurrencyList() {
    return new Promise((resolve, reject) => {
      this.eclaimService.getCurrency(this.employee).subscribe((result) => {
        if(result.body['ErrorMsg'] == null) {
          this.currencyList = result.body['Data'].CurrencyList;
          resolve(this.currencyList);
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
          reject();
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err);
        reject();
      })
    })
  }

  async getStaffInfo() {
    return new Promise((resolve, reject) => {
      this.commonService.getStaffInfo(this.employee).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.staffInfo = result.body['Data'].StaffInfo;
          resolve(this.staffInfo)
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result);
          reject();
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err);
        reject();
      })
    })
  }

  updateSelectType(eclaim) { 
    var filter = this.claimTypeList.filter((l) => l.Code == eclaim.ClaimTypeCode)[0];
    if(filter) {
      
      if(filter.FixAmountPerTran)
        eclaim.ClaimAmount = filter.FixAmountPerTran;
      else
        eclaim.ClaimAmount = 0;

      eclaim.TranMaxAmount = filter.MaxAmountPerTran;
      eclaim.FixAmountPerTranCheck = filter.FixAmountPerTranCheck;
      eclaim.MaxAmountPerTranCheck = filter.FixAmountPerTranCheck;
      eclaim.TypeDesc = filter.Desc;
      eclaim.CarCodeFlag = filter.ApplyCarCode;
	    eclaim.IsProject = filter.HaveAnalysisCode3;

      if(!eclaim.CarCodeFlag)
        eclaim.CarCode = "";
	
      if(!eclaim.IsProject)
        eclaim.ProjectCode = "";
	
      this.notAllowBalanceInquiry = !filter.EnableBalInqESS;
    }
  }

  async refreshMAINVOlist(eclaim) {
    const mainvoCodeList = await this.getMAINVOCodeList(eclaim.ProjectCode);
    console.log(mainvoCodeList);
    if (mainvoCodeList) {
      eclaim.MAINVOCodeList = mainvoCodeList;
      eclaim.MAINVOCode = mainvoCodeList[0]?.Code ?? '';
    }
      
  }

  updateCourseDetail(eclaim) {
    var filter = this.courseCodeList.filter((l) => l.Code == eclaim.TrainingApplicationNo)[0];
    if(filter) 
      eclaim.TrainingApplicationDate = filter.AppDate;
      eclaim.ClaimAmount = filter.Amount;
  }

  resetEClaimApplication() { 
    this.numOfClaim = 0;
    this.attachments = [];
    this.eclaims = [];
    this.addEClaim();
  }

  getNumOfActiveEClaims() { 
    var numOfActive = 0;
    for (var i = 0; i < this.eclaims.length; i++) {
      if (!this.eclaims[i].deleted) numOfActive++;
    }
    return numOfActive;
  }

  deleteEClaims(claim, idx) { 
    if (this.getNumOfActiveEClaims() == 1) return;

    this.eclaims = this.eclaims.filter((l) => l.index != claim.index);
    for (var i = 0; i < this.eclaims.length; i++)
      this.eclaims[i].index = i + 1;
    
    return false;
  }

  checkClaimBalance(desc, type) {
            
    if( this.eclaims[0].ClaimAmount == null || this.eclaims[0].ClaimAmount == "undefined")
        this.eclaims[0].ClaimAmount = 0;
    
    this.eclaims.filter((e) => e.deleted == false);
    this.eclaims.forEach((eclaim) => {
      eclaim.ReimbursementAmount = eclaim.ClaimAmount;
      eclaim.AdjustedAmount = eclaim.ClaimAmount;
    });
    
    const dialog = this.matDialog.open(PopupDetailOfClaimBalanceComponent, {
      panelClass: 'responsive-dialog',
      data: {
        app: this.eclaims,
        type: type,
        desc: desc,
        staffInfo: this.staffInfo
      }
    })
  }

  getFormattedDate(dateString) {
    var date = new Date(dateString);
    date.setHours(8, 0, 0);   // Set hours, minutes and seconds
    return date.toISOString();
 }

  confirmEClaimForm() {
    var checkDone = true;

    this.eclaims = this.eclaims.filter((l) => l.deleted == false);

    // if(this.eclaims.filter((e) => e.ClaimTypeCode == 'MARRIAGE$').length > 1) {
    //   this.errorUI.showErrorBox('AB.D0001');
    //   checkDone = false;
    //   return;
    // }
    if (this.eclaims.filter((e) => e.IsProject && (!e.ProjectCode || !e.MAINVOCode)).length > 0) {
      this.errorUI.showErrorBox('PI.C3017');
      checkDone = false;
      return;
    }

    if (this.eclaims.filter((e) => e.CarCodeFlag && !e.CarCode).length > 0) {
      this.errorUI.showErrorBox('PI.C3016');
      checkDone = false;
      return;
    } 

    //Finalizing Claim Transaction Records
    this.eclaims.forEach((eclaim) => {
      if(eclaim.Attachments) {
        eclaim.Attachments.forEach((att, fileseq) => {
          if (fileseq == 0) {
            eclaim.AttachmentName1 = att.file.name;
            eclaim.AttachmentAddr1 = att.uploadFilename;
          }
          if (fileseq == 1) {
            eclaim.AttachmentName2 = att.file.name;
            eclaim.AttachmentAddr2 = att.uploadFilename;
          }
          if (fileseq == 2) {
            eclaim.AttachmentName3 = att.file.name;
            eclaim.AttachmentAddr3 = att.uploadFilename;
          }
          if (fileseq == 3) {
            eclaim.AttachmentName4 = att.file.name;
            eclaim.AttachmentAddr4 = att.uploadFilename;
          }
        });
      }
      eclaim.ReimbursementAmount = eclaim.ClaimAmount
      eclaim.ClaimDate = this.getFormattedDate(eclaim.ClaimDate)
      
    })

    if(checkDone) {
      const dialog = this.matDialog.open(PopupClaimConfirmComponent, {
        panelClass: 'responsive-dialog',
        data: {
          eclaims: this.eclaims,
          staffInfo: this.staffInfo,
          employee: this.employee
        }
      })
  
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return
        if (data.confirm) {
          this.resetEClaimApplication();
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0001");
        }
      })
    }
  }

  addEClaim() { 
    this.numOfClaim = this.eclaims.length + 1;
    var claim = {
      index: this.numOfClaim,
      deleted: false,
      ClaimCcy: '',
      // ClaimTypeCode: this.claimTypeList[0].Code,
      ClaimTypeCode: '',
      ClaimDate: moment().format('YYYY-MM-DD') + 'T00:00:00',
      // ClaimAmount: this.claimTypeList[0].FixAmountPerTranCheck? this.claimTypeList[0].FixAmountPerTran : 0,
      ClaimAmount: 0,
      ReimbursementCcy: this.currencyList[0].Code,
      ReimbursementAmount: 0,
      // TranMaxAmount: this.claimTypeList[0].MaxAmountPerTranCheck? this.claimTypeList[0].MaxAmountPerTran : 0,
      TranMaxAmount: this.claimTypeList[0].MaxAmountPerTranCheck? this.claimTypeList[0].MaxAmountPerTran : 0,
      // FixAmountPerTranCheck: this.claimTypeList[0].FixAmountPerTranCheck,
      FixAmountPerTranCheck: false,
      // MaxAmountPerTranCheck: this.claimTypeList[0].MaxAmountPerTranCheck,
      MaxAmountPerTranCheck: false,
      TrainingApplicationNo: '',
      TrainingApplicationDate: moment().format('YYYY-MM-DD') + 'T00:00:00',
      Remarks: '',
      Attachments: [],
      AttachmentName1: '',
      AttachmentName2: '',
      AttachmentName3: '',
      AttachmentName4: '',
      AttachmentAddr1: '',
      AttachmentAddr2: '',
      AttachmentAddr3: '',
      AttachmentAddr4: '',
      ProjectCode: "",
      CarCode: "",
      ExpenseTypeCode: "",
      MAINVOCode: "",
      CarCodeFlag: false,
	    IsProject: false,
      MAINVOCodeList: [],
    }

    this.currencyList.forEach((currency) => {
      if(currency.IsBaseCurrency) claim.ClaimCcy = currency.Code
    })

    this.activeClaim = claim;
    this.eclaims.push(claim);
    this.updateSelectType(claim);

    $('.form_content_input').slideUp("slow");
  }

  calculateExceed(claim) {
    if(claim.ClaimAmount > claim.MaxAmount) {
      return claim.ClaimAmount = claim.MaxAmount
    };
  }

  slideUp(claim, idx) {
    this.activeClaim = claim;

    $('.form_content_input').slideUp();
    $("#form_content_input_" + idx).slideToggle();
  }

  convertSizeFormat(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  };

  setPreview(attachment, claim) {
    let instance = this
    var fileReader = new FileReader();
    fileReader.readAsDataURL(attachment.file)
    fileReader.onload = () => {
      if (attachment.file.type.match('image.*')) {
        attachment.dataURL = fileReader.result;
      }
      else if (attachment.file.type.match('pdf.*'))
        attachment.dataURL = "assets/img/icon_pdf.png";
      else if (attachment.file.type.match('xls.*') || attachment.file.type.match('xlsx.*') || attachment.file.type.match('vnd.ms-excel.*'))
        attachment.dataURL = "assets/img/icon_excel.png";
      else
        attachment.dataURL = "assets/img/icon_claim_maintenance.png";
      claim.Attachments.push(attachment);
      $("#btn_delete_attach_" + attachment.fileID).hide();
      this.uploadAttachment(attachment, claim);
    }
  }

  countFilesReadyForUpload(claim) {
    var count = 0;
    for (var i = 0; i < claim.Attachments.length; i++) {
      if (!claim.Attachments[i].deleted) count++;
    }
    return count
  }

  uploadListening(event, claim) {
    $("#btnPreviewApplication").attr("disabled", true);
    //$scope.commonUI.addLoadingTask();
    var files = event.target.files;
    if ((this.countFilesReadyForUpload(claim) + files.length) > 4) {
      this.commonUI.finishLoadingTask();
      this.commonUI.showWarning("message.M0029");
      $("#btnPreviewApplication").attr("disabled", false);
      return;
    }
    for (var i = 0; i < files.length; i++) {
      var attachment = { fileID: this.fileID++, file: null, dataURL: null, uploadProgress: 0, uploadFilename: "", cancelled: false, uploaded: false, deleted: false, group: this.activeClaim.index };
      attachment.file = files[i];
      this.setPreview(attachment, claim);
    }
  }

  uploadAttachment(attachment, claim) {
    $("#btn_upload_attach_" + attachment.fileID).hide();
    this.commonService.uploadAttachment(attachment).subscribe((result) => {
      if (result.type == HttpEventType.UploadProgress) {
        this.fileProgress = Math.round(100 * result.loaded / result.total);
      } else if (result instanceof HttpResponse) {
        if (!result['conn_fail']) {
          $("#btn_delete_attach_" + attachment.fileID).show();
          $("#btn_upload_attach_" + attachment.fileID).hide();
          $("#btn_cancel_attach_" + attachment.fileID).hide();
          $("#file_UploadAttachment").val('');
          $("#btnPreviewApplication").attr("disabled", false);
          attachment.uploadFilename = result.body['Data'].UploadFileName
          attachment.uploadFilePath = result.body['Data'].UploadFilePath
          attachment.uploaded = true
        } else 
        this.onCallAPIFail(result.body, result.status, result.headers, result.body['Data'])
      }
    }, err => {
      this.fileProgress = 0
      $("#file_UploadAttachment").val('');
      $("#btnPreviewApplication").attr("disabled", false);
      this.deleteAttachment(attachment, claim);
      this.commonUI.showMsgBox("status." + err.status + ': ' + err.statusText);
      // this.errorUI.showErrorBox(err.status)
      //if (error != undefined && error == 501)
      //    $scope.commonUI.showMsgBox("error.00022");
      //else
      //    $scope.commonUI.showMsgBox("error.00021");
    })
  }

  downloadAttachment(attachment) {
    //this.commonUI.saveTempAttachment(attachment);
    this.commonHandler.saveAttachment(attachment.uploadFilePath, attachment.file.name);
  }

  deleteAttachment(attachment, claim) {
    //Worked
    attachment.deleted = true;
    claim.Attachments = claim.Attachments.filter((a) => a.deleted == false);
    $("#attach_" + attachment.fileID).fadeOut();
    if(this.countFilesReadyForUpload(claim) < 4 ) 
      $("#btnPreviewApplication").attr("disabled", false); 
    //this.obj.attachments = []
  }

  cancelAttachment(attachment, claim) {
    // $("#btnPreviewApplication").attr("disabled", false);
    //Worked
    if (this.fileProgress == 0) {
      this.deleteAttachment(attachment, claim);
      return;
    }
    attachment.cancelled = true;
  }

  /* Datepicker Footer */

  today(picker: string, n) {
    switch (picker) {
      case 'start':
        this.eclaims[n].ClaimDate = moment().format('YYYY-MM-DD') + 'T00:00:00';
        break;
    }
    this.close(picker, n)
  }

  close(picker: string, n) {
    switch (picker) {
      case 'start':
        this.startPicker.forEach(function (e, idx) {
          if (idx == n) e.close()
        })
        break;
    }
  }

  openAppend(picker: string, i) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }

    this.selectedIndex = i
  }

  sortAlphaNum(a, b) {
	var reA = `/[^a-zA-Z]/g`;
	var reN = `/[^0-9]/g`;
	  
	var aA = a.Code.replace(reA, "");
	var bA = b.Code.replace(reA, "");
	if (aA === bA) {
		var aN = parseInt(a.replace(reN, ""), 10);
		var bN = parseInt(b.replace(reN, ""), 10);
		return aN === bN ? 0 : aN > bN ? 1 : -1;
	} else {
		return aA > bA ? 1 : -1;
	}
  }
  orderMainVOCode(mainVOCodeList) {

	if(mainVOCodeList.length == 0)
		return mainVOCodeList;
	  
	var startWithVO = [];
	var endWithM = [];

	for(let i = 0; i < mainVOCodeList.length; i++) {
		if(mainVOCodeList[i].Code.startsWith("VO-"))
			startWithVO.push(mainVOCodeList[i]);
		if(mainVOCodeList[i].Code.endsWith("-M"))
			endWithM.push(mainVOCodeList[i]);
	}
	startWithVO = startWithVO.sort(this.sortAlphaNum);
	endWithM = endWithM.sort(this.sortAlphaNum);
	
	return endWithM.concat(startWithVO);
  }
  /*******************/

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}
