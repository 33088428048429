import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ImageEnlargePreviewComponent } from '../../common/image-enlarge-preview/image-enlarge-preview.component';
import { CommonUIService } from '../../services-UI/common-ui.service';
import { CommonHandlerService } from '../../services/common-handler.service';
import { CommonServiceService } from '../../services/common-service.service';
import { IdleTimerService } from '../../services/idle-timer.service';
import { EclaimService } from '../../services-mod/eclaim.service';
import { ReportService } from '../../services-mod/report.service';
declare var $: any;

@Component({
  selector: 'app-report-e-claim',
  templateUrl: './report-e-claim.component.html',
  styleUrls: ['./report-e-claim.component.scss']
})
export class ReportEClaimComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  /* Datepicker */
  minDate = new Date(2010, 0, 1);
  maxDate = new Date(new Date().getFullYear() , 12, 31);

  employee = JSON.parse(sessionStorage.Employee);
  approvalGroupList = [];
  departmentList = [];
  // yearList = [];
  claimTypeList = [];
  departmentCode = "";
  startDate = this.getFormattedDate(new Date().setMonth(new Date().getMonth() - 1));
  endDate = this.getFormattedDate(new Date().setMonth(new Date().getMonth() + 1));
  confirmStatus = '';
  claimType = '';
  claimStatus = '';
  isTeammateViewSelected = false;
  multipleSelection = {
    selectedApprovalGroupList: [],
    selectedDepartmentList: []
  };

  claimReportData = [];
  reportFile = {};
  fileFormat = "pdf";
  criteria = {
    FileFormat: this.fileFormat
  }

  constructor(private idleTimer: IdleTimerService, public commonUI: CommonUIService, private translate: TranslateService, private commonService: CommonServiceService, private reportService: ReportService, private commonHandler: CommonHandlerService, private matDialog: MatDialog, private eclaimService: EclaimService, private commonServiceMod:CommonServiceService) {
    this.translate.onLangChange.subscribe(() => {
    })
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.getLists();
  }

  isChangedTeamView() {
    if (this.isTeammateViewSelected) {
  
    }
  }


  showSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

    this.showReportClaimSearchResult();
  }

  showReportClaimSearchResult() {
    let instance = this;
    this.commonUI.addLoadingTask();

    var approvalGroupList = [];
    // for (var i = 0; i < this.multipleSelection.selectedApprovalGroupList.length; i++) {
    //   approvalGroupList.push(this.multipleSelection.selectedApprovalGroupList[i].Code);
    // }

    var departmentList = [];
    for (var i = 0; i < this.multipleSelection.selectedDepartmentList.length; i++) {
      departmentList.push(this.multipleSelection.selectedDepartmentList[i].Code);
    }

    this.reportService.getClaimReport(
      this.employee,
      departmentList,
      this.getFormattedDate(this.startDate),
      this.getFormattedDate(this.endDate),
      this.confirmStatus,
      this.claimType,
      this.claimStatus,
      approvalGroupList,
      this.isTeammateViewSelected,
      this.criteria).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.claimReportData = result.body['Data'];
          $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", function () {
            instance.commonUI.finishLoadingTask();
            $('html, body').animate({
              scrollTop: $(".list_result_area").offset().top
            }, 1000);
          });
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })

  }

  getLists() {
    this.getApprovalGroupList();
    this.getDepartmentList();
    this.getClaimType();
  }

  getApprovalGroupList() {
    this.commonUI.addLoadingTask();
    this.reportService.getApprovalGroupListMod(this.employee,"CLAIMHDR").subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.approvalGroupList = result.body['Data'].ApprovalGroupList
        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getDepartmentList() {
    this.commonUI.addLoadingTask();
    this.commonServiceMod.getDepartmentList().subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.onGetDepartmentListSuccess(result.body['Data'].DepartmentList);
      } else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  // getYearList() {
  //   var i =0;
  //   for(var year = new Date().getFullYear();year>=2010;year--){
  //     this.yearList[i] = year;
  //     i++;
  //   }
  // }

  getClaimType() {
    this.eclaimService.getClaimType(this.employee).subscribe((result) => {
      if(result.body['ErrorMsg'] == null) {
        this.claimTypeList = result.body['Data'].ClaimTypeList;
      }
      else {
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }


  onGetDepartmentListSuccess(departmentListData) {
    this.departmentList = [];
    this.departmentList = departmentListData;
    this.departmentCode = "";
    this.commonUI.finishLoadingTask();
  }

  viewReport(attachmentPath) {
    this.commonUI.addLoadingTask();
    if (environment.isMobileDevice() || environment.isIE() || this.fileFormat != "pdf") {
        this.commonUI.finishLoadingTask();
        this.commonHandler.saveAttachment(attachmentPath, 'ClaimReport.' + this.fileFormat);
    } else {
        this.commonService.viewAttachment(attachmentPath, this.fileFormat, this.reportFile).subscribe((result) => {
          if (result.body['ErrorMsg'] == null) {
            this.matDialog.open(ImageEnlargePreviewComponent, {
              data: {
                dataURL: result.body['dataURL']
              },
              panelClass: 'dialog-responsive'
            })
            this.commonUI.finishLoadingTask();
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
    }
  }

  resetSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

    this.isTeammateViewSelected = false;
    this.departmentCode = "";
    if(this.claimTypeList.length > 0)  
      this.claimType = this.claimTypeList[0].Code;
    // this.year = new Date().getFullYear();
    this.multipleSelection.selectedApprovalGroupList = [];
    this.multipleSelection.selectedDepartmentList = [];
  }
  

  /* Datepicker Footer */

  today() {
    let instance = this
    instance.startPicker.close()

  }

  dateValidator(date) {
    switch(date) {
      case 'start':
        if(this.startDate > this.endDate)
          this.endDate = this.getFormattedDate(this.startDate)
        break;
      case 'end':
        if(this.endDate < this.startDate)
          this.startDate = this.getFormattedDate(this.endDate)
        break;
    }
  }

  getFormattedDate(dateString) {
    var date = new Date(dateString);
    date.setHours(8, 0, 0, 0);   // Set hours, minutes and seconds
    return date.toISOString();
  }

  close() {
    this.startPicker.close()
  }

  openAppend() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.startFooter.nativeElement);
  }
  

  /*******************/
  onCallAPIFail(data, status, headers, config) {
    this.commonUI.finishLoadingTask();
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}
