import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { PopupDelegationDeleteComponent } from '../popup-delegation-delete/popup-delegation-delete.component';
import { PopupDelegationSettingsPreviewComponent } from '../popup-delegation-settings-preview/popup-delegation-settings-preview.component';

@Component({
  selector: 'app-popup-delegation-settings',
  templateUrl: './popup-delegation-settings.component.html',
  styleUrls: ['./popup-delegation-settings.component.scss']
})
export class PopupDelegationSettingsComponent implements OnInit {

  @ViewChild('effStartFooter', { static: false }) effStartFooter: ElementRef;
  @ViewChild('effEndFooter', { static: false }) effEndFooter: ElementRef;
  @ViewChild('leaveStartFooter', { static: false }) leaveStartFooter: ElementRef;
  @ViewChild('leaveEndFooter', { static: false }) leaveEndFooter: ElementRef;

  @ViewChild('effStartPicker', { static: false }) effStartPicker: MatDatepicker<any>;
  @ViewChild('effEndPicker', { static: false }) effEndPicker: MatDatepicker<any>;
  @ViewChild('leaveStartPicker', { static: false }) leaveStartPicker: MatDatepicker<any>;
  @ViewChild('leaveEndPicker', { static: false }) leaveEndPicker: MatDatepicker<any>;

  selectedIndex;
  currentSetting;
  settings

  constructor(public matDialogRef: MatDialogRef<PopupDelegationSettingsComponent>, public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data, public commonUI: CommonUIService, public commonService: CommonServiceService) { 
    this.currentSetting = data['currentSetting']
    this.selectedIndex = data['selectedIndex']
    this.settings = data['settings']
  }

  ngOnInit(): void {
  }

  showPreviewSetting() {
    this.currentSetting.StaffID = this.currentSetting.StaffID.toUpperCase();

    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfoById(this.currentSetting.StaffID).subscribe((result) => {
      
        if(result.body['ErrorMsg'] == null) {
          this.currentSetting.StaffInfo = result.body['Data'].StaffInfo;
          this.currentSetting.EffStartDate = this.commonUI.formatDisplayDate(this.currentSetting.EffStart, "YYYY-MM-DDT00:00:00")
          this.currentSetting.EffEndDate = this.commonUI.formatDisplayDate(this.currentSetting.EffEnd, "YYYY-MM-DDT00:00:00")
          this.currentSetting.LeaveStartDate = this.commonUI.formatDisplayDate(this.currentSetting.LeaveStart, "YYYY-MM-DDT00:00:00")
          this.currentSetting.LeaveEndDate = this.commonUI.formatDisplayDate(this.currentSetting.LeaveEnd, "YYYY-MM-DDT00:00:00")
          this.commonUI.finishLoadingTask();
          const dialog = this.matDialog.open(PopupDelegationSettingsPreviewComponent, {
            panelClass: 'dialog-responsive',
            data: {
              currentSetting: this.currentSetting,
              selectedIndex: this.selectedIndex,
              settings: this.settings
            }
          })
          dialog.afterClosed().subscribe((data?) => {if(data) this.closePopup(data)})
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  confirmDeleteSetting() {
    const dialog = this.matDialog.open(PopupDelegationDeleteComponent, {
      panelClass: 'dialog-responsive',
      data: {
        currentSetting: this.currentSetting,
        selectedIndex: this.selectedIndex,
        settings: this.settings
      }
    })
    dialog.afterClosed().subscribe((data?) => {
      if(data == undefined || data == null) return
      if(data) this.closePopup(data)
    })
  }

  isSettingInvalid() {
    return this.currentSetting.StaffID == undefined || this.currentSetting.EffStart == undefined || this.currentSetting.EffEnd == undefined || this.currentSetting.LeaveStart == undefined || this.currentSetting.LeaveEnd == undefined;
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'eff_start':
        this.currentSetting.EffStart = new Date()
        this.effStartPicker.close()
        break;
      case 'eff_end':
        this.currentSetting.EffEnd = new Date()
        this.effEndPicker.close()
        break;
      case 'leave_start':
        this.currentSetting.LeaveStart = new Date()
        this.leaveStartPicker.close()
        break;
      case 'leave_end':
        this.currentSetting.LeaveEnd = new Date()
        this.leaveEndPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'eff_start':
        this.effStartPicker.close();
        break;
      case 'eff_end':
        this.effEndPicker.close();
        break;
      case 'leave_start':
        this.leaveStartPicker.close();
        break;
      case 'leave_end':
        this.leaveEndPicker.close();
        break;
    }
  }

  dateValidator(date) {
    switch (date) {
      case 'eff_start':
        if (this.currentSetting.EffStart > this.currentSetting.EffEnd)
          this.currentSetting.EffEnd = new Date(this.currentSetting.EffStart)
        break;
      case 'eff_end':
        if (this.currentSetting.EffEnd < this.currentSetting.EffStart)
          this.currentSetting.EffStart = new Date(this.currentSetting.EffEnd);
        break;
      case 'leave_start':
        if (this.currentSetting.LeaveStart > this.currentSetting.LeaveEnd)
          this.currentSetting.LeaveEnd = new Date(this.currentSetting.LeaveStart)
        break;
      case 'leave_end':
        if (this.currentSetting.LeaveEnd < this.currentSetting.LeaveStart)
          this.currentSetting.LeaveStart = new Date(this.currentSetting.LeaveEnd);
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'eff_start':
        matCalendar.appendChild(this.effStartFooter.nativeElement);
        break;
      case 'eff_end':
        matCalendar.appendChild(this.effEndFooter.nativeElement);
        break;
      case 'leave_start':
        matCalendar.appendChild(this.leaveStartFooter.nativeElement);
        break;
      case 'leave_end':
        matCalendar.appendChild(this.leaveEndFooter.nativeElement);
        break;
    }
  }

  /*******************/

  closePopup(data?) {
    try {
      this.matDialogRef.close(data)
    } catch { }
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
