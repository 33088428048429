<div class="pp5_content" id="pop_claimBalance"  *ngIf='claimBalance'>
	<div class="pop_functions_box pop_functions_box_size_2column mask_balance_inquiry">
    	<div class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
        	<div class="detail_box_top_photo" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(staffInfo) + ')'}"></div>
            <div class="detail_box_name">
              <span class="txt_size_1">{{ staffInfo.FullName }}</span><br>
              <span class="txt_opacity_1">{{ staffInfo.PositionDesc }}</span><br>
              <span class="txt_size_3 txt_opacity_1 txt_popup_staffid">ID&nbsp;:&nbsp;{{ staffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

        	<div class="detail_box_bottom_2point_leave_balance">
                <!--
                <div class="detail_box_catalog_area">
                	<div class="maincolor_bg color_label_pending">Pending</div>
    			</div>-->
                <div class="detail_box_session1 txt_grey_2 txt_size_3">&nbsp;</div>
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(claimBalance.StartDate, 'D MMM YYYY')}}
                    <span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(claimBalance.StartDate, '(ddd)')}}</span>
                </div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'eclaim.cutoffday' | translate }}</div>
                <div class="detail_box_date2 txt_size_1">{{commonUI.formatDisplayDate(claimBalance.CutOffDate, 'D MMM YYYY')}}
                    <span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(claimBalance.CutOffDate, '(ddd)')}}</span>
                </div>
                <div class="detail_box_sub_icon_txtonly d_box_sub_icon_leave"></div>
                <div class="detail_box_type_appointment">{{claimTypeDesc}}</div>
            </div>

            <div class="detail_box_other txt_size_1">

            	<div class="txt_grey_2 txt_size_3">{{ 'eclaim.cutoffdayasat' | translate }}</div>
                <div>
                    {{commonUI.formatDisplayDate(claimBalance.CutOffDate, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(claimBalance.CutOffDate, '(ddd)')}}</span>
                </div>
                
                 <div style="clear:both"></div>
                 <br>

                <div class="txt_grey_2 txt_size_3">{{ 'eclaim.availablebalance' | translate }}</div>
                <span class="txt_size_3">$</span>{{claimBalance.AvailableAmt}}

                <div style="clear:both"></div>
                <br>

                <div class="txt_grey_2 txt_size_3">{{ 'eclaim.numofrecord' | translate }}</div>
                <span class="txt_size_3"></span>{{claimBalance.AvailableTime}}

            </div>

        </div>


        <div class="detail_box_bottom_area mt0_m">

                <!--<div class="txt_grey_2 txt_size_3">Detail</div>-->
                <div class="btn_balance_inquiry_search_result">
                        <!--
                        <div class="btn_balance_title"></div>
                        <div class="btn_balance_title_num txt_grey_1">2016</div>
                        <div style="clear:both"></div>
                        -->
                        <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'eclaim.openingbalance' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1"></div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.balance' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1"><span>{{claimBalance.OpenBalanceAmt}}</span></div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.numofclaim' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1"><span>{{claimBalance.OpenBalanceTime}}</span></div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'eclaim.entitlement' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1"></div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.balance' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{claimBalance.EntitlementAmt}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.numofclaim' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{claimBalance.EntitlementTime}}</div>
                        <div style="clear:both"></div>


                        <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'eclaim.adjustment' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1"></div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.balance' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{claimBalance.AdjustmentAmt}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.numofclaim' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{claimBalance.AdjustmentTime}}</div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'eclaim.claimed' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1"></div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.balance' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{claimBalance.TakenAmt}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'eclaim.numofclaim' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{claimBalance.TakenTime}}</div>
                        <div style="clear:both"></div>

                </div>

        </div>

	</div>
</div>