import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { PopupConfirmWithdrawComponent } from '../popup-confirm-withdraw/popup-confirm-withdraw.component';
import { PopupPunchZoomComponent } from '../popup-punch-zoom/popup-punch-zoom.component';
import { PopupSubmitApprovalRemarksComponent } from '../popup-submit-approval-remarks/popup-submit-approval-remarks.component';
import { PopupSubmitRejectRemarksComponent } from '../popup-submit-reject-remarks/popup-submit-reject-remarks.component';

@Component({
  selector: 'app-popup-in-out-detail',
  templateUrl: './popup-in-out-detail.component.html',
  styleUrls: ['./popup-in-out-detail.component.scss']
})
export class PopupInOutDetailComponent implements OnInit {

  inOutTimeApplication;
  popupid;
  popupmode;
  isDelegate = false;
  isOverride = false;
  approverRemarkList;
  employee = JSON.parse(sessionStorage.Employee);

  constructor(public matDialog: MatDialog, public matDialogRef: MatDialogRef<PopupInOutDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public translate: TranslateService, public commonUI: CommonUIService) {
    this.inOutTimeApplication = data['app']
    this.approverRemarkList = data['approver_remark_list']
    this.isDelegate = data['isDelegate']
    this.isOverride = data['isoverride']
    this.popupmode = data['popupmode']
  }

  ngOnInit(): void {
  }

  showApprovePopup() {
    const dialog =this.matDialog.open(PopupSubmitApprovalRemarksComponent, {
      panelClass: 'responsive-dialog',
      data: {
        app: this.inOutTimeApplication,
        popid: this.popupid,
        isDelegate: this.isDelegate,
        isoverride: this.isOverride
      }
    })
    
    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data) {
        this.close(data);
      }
    })
  }

  showRejectPopup() {
    const dialog = this.matDialog.open(PopupSubmitRejectRemarksComponent, {
      panelClass: 'responsive-dialog',
      data: {
        app: this.inOutTimeApplication,
        popid: this.popupid,
        isDelegate: this.isDelegate,
        isoverride: this.isOverride
      }
    })

    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data) {
        this.close(data);
      }
    })
  }

  showWithdrawConfirm() {
    const dialog = this.matDialog.open(PopupConfirmWithdrawComponent, {
      panelClass: 'responsive-dialog',
      data: {
        inoutapp: this.inOutTimeApplication,
        popid: 'withdrawConfirmPopUp',
        type: 'inout'
      }
    })

    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data) {
        this.close(data);
      }
    })
  }

  showZoomPopup() {
    this.matDialog.open(PopupPunchZoomComponent, {
      panelClass: 'responsive-dialog',
      data: {
        app: this.inOutTimeApplication,
        popid: 'withdrawConfirmPopUp',
        popupmode: 'READONLY'
      }
    })
  }

  close(data?) {
    try {
      this.matDialogRef.close(data);
    } catch {}
  }

}
