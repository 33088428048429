import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PopupEclaimDetailComponent } from '../common_mod/popup-eclaim-detail/popup-eclaim-detail.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { EclaimService } from '../services/eclaim.service';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-eclaim-inquiry',
  templateUrl: './eclaim-inquiry.component.html',
  styleUrls: ['./eclaim-inquiry.component.scss']
})
export class EclaimInquiryComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  @ViewChild('endPicker', { static: false }) endPicker: MatDatepicker<any>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  employee = JSON.parse(sessionStorage.Employee);
  selectedEClaimApplication;
  selectedEClaimRemarkList;
  claimPopMode = 'WITHDRAWAL';
  startDate = this.getFormattedDate(new Date().setMonth(new Date().getMonth() - 1));
  endDate = this.getFormattedDate(new Date().setMonth(new Date().getMonth() + 1));
  status = '';
  claimStatusList = [];
  claimStatus = '';
  isTeammateViewSelected = false;
  iseExcessiveClaimMode = false;
  claimList = [];
  resultClaimList = [];
  search = {
    keywords: ''
  }

  constructor(public commonService: CommonServiceService, public commonUI: CommonUIService, public eclaimService: EclaimService, public translate: TranslateService, private matDialog: MatDialog) { 
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('eclaiminquiry.status_all').subscribe((all) => {
        if(this.claimStatusList.length > 0) this.claimStatusList[0].Desc = all;
      });
    })
  }

  ngOnInit(): void {
    this.getClaimType();
  }

  getClaimType() {
    this.claimStatus = '';
    this.claimStatusList = [];
    if (this.isTeammateViewSelected)
    {
      this.eclaimService.getClaimType(null).subscribe((result) => {
        if(result.body['ErrorMsg'] == null) {
          this.claimStatusList = result.body['Data'].ClaimTypeList;
          if(this.claimStatusList.length > 0) this.claimStatusList.unshift({
            Desc: 'All',
            Code: ''
          });
        }
        else {
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
    else
    {
    this.eclaimService.getClaimType(this.employee).subscribe((result) => {
      if(result.body['ErrorMsg'] == null) {
        this.claimStatusList = result.body['Data'].ClaimTypeList;
        if(this.claimStatusList.length > 0) this.claimStatusList.unshift({
          Desc: 'All',
          Code: ''
        });
      }
      else {
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }
  }

  dateValidator(date) {
    switch(date) {
      case 'start':
        if(this.startDate > this.endDate)
          this.endDate = this.getFormattedDate(this.startDate)
        break;
      case 'end':
        if(this.endDate < this.startDate)
          this.startDate = this.getFormattedDate(this.endDate)
        break;
    }
  }

  isChangedTeamView() {
    this.getClaimType();
  }

  searchByInput() {
    if (this.search.keywords.length <= 0) return this.claimList = this.resultClaimList
    else this.claimList = this.resultClaimList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  propertyName = "StatusDesc";
  reverse = true;

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch (propertyName) {
      case 'StaffInfo.FullName':
        this.claimList = this.claimList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'ClaimDate':
        this.claimList = this.claimList.sort((a, b) => {
          return a.ClaimDate - b.ClaimDate
        })
        break;
      case 'ClaimType':
        this.claimList = this.claimList.sort((a, b) => {
          return a.ClaimType.localeCompare(b.ClaimType)
        })
        break;
      case 'StatusDesc':
        this.claimList = this.claimList.sort((a, b) => {
          return a.StatusDesc.localeCompare(b.StatusDesc)
        })
        break;
    }
    if (this.reverse) this.claimList = this.claimList.slice().reverse()
    this.toggleSortMenu()
  }

  getFormattedDate(dateString) {
    var date = new Date(dateString);
    date.setHours(8, 0, 0, 0);   // Set hours, minutes and seconds
    return date.toISOString();
 }

  showSearchResult() {
    this.commonUI.addLoadingTask();
    if (this.isTeammateViewSelected) {
      this.eclaimService.getTeamEclaimApplicationList(this.employee, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.status, this.claimStatus, this.iseExcessiveClaimMode).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.claimList = result.body['Data'].TeammateClaimApplicationList;
          this.onGetResultSuccess()
        }
      }, err => {
        this.claimList = err.Data;
        this.commonUI.finishLoadingTask();
      })
    } else {
      this.eclaimService.getSelfEclaimApplicationList(this.employee, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.status, this.claimStatus, this.iseExcessiveClaimMode).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.claimList = result.body['Data'].SelfClaimApplicationList;
          this.onGetResultSuccess();
        } else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        // this.claimList = err.Data;
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err);
      })
    }
  }

  onGetResultSuccess() {
    $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", () => {
      this.commonUI.finishLoadingTask();
      $('html, body').animate({
        scrollTop: $(".list_result_area").offset().top
      }, 1000);
    });
    this.prepareListInfo();
    this.commonUI.finishLoadingTask();
  }

  prepareListInfo() {
    var index = 0;

    this.claimList.forEach(claim => {
      // claim.LeaveDateRange = this.commonUI.formatDisplayDate(claim.StartDate, 'D MMM YYYY (ddd) hh:mm a') + " - " + this.commonUI.formatDisplayDate(claim.EndDate, 'D MMM YYYY (ddd) hh:mm a');
      claim.keywords = "";
      // claim.keywords += claim.LeaveDateRange + " ";
      claim.keywords += claim.StaffInfo.FullName + " ";
      claim.keywords += claim.ClaimDate + " ";
      claim.keywords += claim.ClaimAmount + " ";
      claim.keywords += claim.Status + " ";
      claim.keywords += claim.StaffInfo.PositionDesc + " ";
      claim.keywords += claim.ClaimTypeCode + " ";
      claim.index = index++;
    });
    this.resultClaimList = this.claimList;
  }

  resetSearchResult() {
    this.claimList = [];
    this.hideSearchResult();
    this.startDate = this.getFormattedDate(new Date().setMonth(new Date().getMonth() - 1));
    this.endDate =this.getFormattedDate(new Date().setMonth(new Date().getMonth() + 1));
    this.isTeammateViewSelected = false;
    this.search.keywords = "";
  }

  toggleSortMenu() {
    $("#p4_content").fadeToggle();
  }

  hideSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();
  }

  showEClaimApplication(claim) {
    this.commonUI.addLoadingTask();
    this.selectedEClaimApplication = claim;

    this.claimPopMode = "READONLY";
    if (claim.IsWithdrawable) this.claimPopMode = "WITHDRAWALNOEDIT";

    this.commonService.getApproverRemarkList(claim.RID, claim.SourceType, this.getFormattedDate(claim.ClaimDate)).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.onGetApproverRemarkListSuccess(result.body['Data'].ApproverRemarkList);
      }
      else {
        this.onCallAPIFail(result.body, result.status, result.headers, result)
        this.commonUI.finishLoadingTask();
      }
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
      this.commonUI.finishLoadingTask();
    })

  }

  onGetApproverRemarkListSuccess(remarklist) {
    this.selectedEClaimRemarkList = remarklist

    this.selectedEClaimRemarkList.forEach((approverRemark) => {
      this.translate.get('appointmentapplicationinquery.status_approved').subscribe((status_approved) => {
        approverRemark.Remark = approverRemark.Remark.replace("[Approved]", "[" + status_approved + "]");
      })

      this.translate.get('appointmentapplicationinquery.status_pending').subscribe((status_pending) => {
        approverRemark.Remark = approverRemark.Remark.replace("[Pending]", "[" + status_pending + "]");
      })

      this.translate.get('appointmentapplicationinquery.status_rejected').subscribe((status_rejected) => {
        approverRemark.Remark = approverRemark.Remark.replace("[Rejected]", "[" + status_rejected + "]");
      })
    });

    const dialog = this.matDialog.open(PopupEclaimDetailComponent, {
      panelClass: 'responsive-dialog',
      data: {
        app: this.selectedEClaimApplication,
        approver_remark_list: this.selectedEClaimRemarkList,
        isDelegate: false,
        popupmode: this.claimPopMode,
      }
    })
    this.commonUI.finishLoadingTask();

    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data.confirm) {
        this.showSearchResult();
      }
    })
  }

  getNumOfApplication(status) {
    var count = 0;
    if (status == "") return this.resultClaimList.length;
    for (var i = 0; i < this.resultClaimList.length; i++) {
      if (this.resultClaimList[i].StatusDesc == status) count++;
    }
    return count;
  }

  clearFilter() {
    this.search.keywords = "";
    this.claimList = this.resultClaimList
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(0).addClass("active");

    this.commonUI.scrollToTop();
  }

  applyFilter(keyword, i) {
    this.search.keywords = keyword + " ";
    this.claimList = this.resultClaimList.filter(o => o.StatusDesc == keyword)
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(i).addClass("active");

    this.commonUI.scrollToTop();
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.startDate = this.getFormattedDate(new Date())
        instance.startPicker.close()
        break;
      case 'end':
        instance.endDate = this.getFormattedDate(new Date())
        instance.endPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
      case 'end':
        this.endPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
  }

  /*******************/
  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
