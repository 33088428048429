<div id="content_leave_application">
    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'eclaim.eclaim' | translate }}</span>
    </div>

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_application"></div>
            <div class="fl">{{ 'eclaim.application' | translate }}</div>
        </div>

        <div class="box_content_inquiry">

            <div class="form_area">
                <form name="form_application" novalidate>
                    <div *ngFor="let eclaim of eclaims; let i = index;">
                        <div id="form_{{i}}" class="form_content">

                            <div class="form_content_title bg_grey_3" (click)="slideUp(eclaim, i)">
                                <div class="bg_grey_2b"><span class="txt_size_3">No.&nbsp;</span>{{eclaim.index}}</div>
                                <div>
                                    <span class="txt_size_2 txt_grey_2">
                                        &nbsp;{{ commonUI.formatDisplayDate(eclaim.ClaimDate, 'D MMM YYYY (ddd)') }}
                                    </span>
                                    <span class="txt_size_2 m-0">&nbsp;{{ eclaim.TypeDesc }}</span>
                                </div>
                            </div>

                            <div class="form_content_input" id="form_content_input_{{i}}">

                                <div class="box_content_inquiry_field" *ngIf='claimTypeList'>
                                    <div class="btn_sub_title txt_grey_2">{{ 'eclaim.claimtype' | translate }}</div>
                                    <div class="fl input_field_large">
                                        <select class="btn btn_dropdown_1 maincolor_btn_1" name='claimtype_{{i}}'
                                            [(ngModel)]='eclaim.ClaimTypeCode' (change)='updateSelectType(eclaim)'>
                                            <option selected value=""></option>
                                            <!-- <option *ngFor='let prop of NatureOfSubs; let i = index' [value]="prop" [title]='prop'>{{prop}} </option> -->
                                            <option *ngFor='let claimType of claimTypeList' [title]='claimType.Desc' [value]='claimType.Code'>{{claimType.Desc}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="box_content_inquiry_field">
                                    <div class="btn_sub_title txt_grey_2">&nbsp;</div>
                                    <button [disabled]="notAllowBalanceInquiry" class="btn btn-block btn_grey btn-lg" (click)="checkClaimBalance(eclaim.TypeDesc, eclaim.ClaimTypeCode)"><img src="assets/img/btn_search_a.png" width="18" />{{ 'eclaim.balanceinquiry' | translate }}</button>
                                </div>

                                <div style="clear:both"></div>
                                <br />

                                <div class="box_content_inquiry_field">
                                    <div class="btn_sub_title txt_grey_2">{{ 'eclaim.claimdate' | translate }}</div>

                                    <div class="fl input_new_calendar">
                                        <div>
                                            <mat-form-field appearance='fill' (click)="startPicker.open()">
                                                <input matInput [matDatepicker]="startPicker"
                                                    [(ngModel)]="eclaim.ClaimDate"
                                                    name='startPicker_{{i}}' class='disabledDatePicker'>
                                                <mat-datepicker-toggle matSuffix [for]="startPicker" name='spicker'>
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #startPicker (opened)="openAppend('start', i)">
                                                </mat-datepicker>
                                            </mat-form-field>
                                            <div style="display: none">
                                                <div class="datepicker-footer" #startFooter>
                                                    <button mat-raised-button (click)="today('start', selectedIndex)"
                                                        #todayButton name="startDateButton_{{selectedIndex}}">
                                                        {{'TODAY' | translate}}
                                                    </button>
                                                    <button mat-raised-button (click)="close('start', selectedIndex)"
                                                        #doneButton>
                                                        {{'close' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="clear:both"></div>
                                <br />

                                <div class="box_content_inquiry_field">
                                    <div class="btn_sub_title txt_grey_2">{{ 'eclaim.dollar' | translate }}</div>
                                    <div class="fl time_field btn_two_margin_left_m txt_grey_2" *ngIf='currencyList'>
                                        <div class="d-flex flex-row">
                                            <div>
                                                <select id="dollar_{{i}}" class="btn btn_dropdown_1 maincolor_btn_1"
                                                name='dollar_{{i}}' [(ngModel)]="eclaim.ClaimCcy">
                                                <!-- <option *ngFor="let hour of hours" [title]="hour" [value]="hour">{{hour}}</option> -->
                                                <option *ngFor='let currency of currencyList' [title]='currency.Desc' [value]='currency.Code'>{{currency.Desc}}</option>
                                            </select>
                                            </div>
                                            <div>
                                                <input type="number"
                                                class="form-control bg_none txt_grey_1 txt_size_2" [disabled]="eclaim.FixAmountPerTranCheck"
                                                [(ngModel)]='eclaim.ClaimAmount' required name='claim_amount_{{i}}' min='0' value='0' [pattern] = "'[1-9][0-9]'" /> <!--(keyup)='calculateExceed(eclaim)'-->
                                            </div>
                                        </div>
                                        <div class="fl">&nbsp;&nbsp;</div>
                                    </div>
                                </div>

                                <div class="box_content_inquiry_field">
                                    <div class="btn_sub_title txt_grey_2">{{ 'eclaim.max_amount' | translate }}</div>
                                    <input class="form-control bg_none txt_grey_1 txt_size_2" disabled
                                        type='number' name='max_amount_{{i}}' [ngModel]='eclaim.TranMaxAmount' min="0">
                                </div>

                                <div style="clear:both"></div>
                                <br />

                                <div class="box_content_inquiry_field">
									<ng-container *ngIf='eclaim.IsProject'>
										<div class="btn_sub_title txt_grey_2">{{ 'eclaim.project_code' | translate }}</div>
										<div class="fl input_field_large">
											<select class="btn btn_dropdown_1 maincolor_btn_1" name='project_code_{{i}}'
												[(ngModel)]='eclaim.ProjectCode' (change)="refreshMAINVOlist(eclaim)">              
												<option selected value=""></option>
												<!-- <option *ngFor='let prop of NatureOfSubs; let i = index' [value]="prop" [title]='prop'>{{prop}} </option> -->
												<option *ngFor='let projectCode of projectCodeList' [title]='projectCode.Desc' [value]='projectCode.Code'>{{projectCode.Code}}</option>
											</select>
										</div>
									</ng-container>
                                </div>

                                <div class="box_content_inquiry_field">
                                    <ng-container *ngIf='eclaim.CarCodeFlag'>
                                        <div class="btn_sub_title txt_grey_2">{{ 'eclaim.car_code' | translate }}</div>
                                        <div class="fl input_field_large">
                                            <select class="btn btn_dropdown_1 maincolor_btn_1" name='car_code_{{i}}'
                                                [(ngModel)]='eclaim.CarCode'>
                                                <option selected value=""></option>
                                                <!-- <option *ngFor='let prop of NatureOfSubs; let i = index' [value]="prop" [title]='prop'>{{prop}} </option> -->
                                                <option *ngFor='let carCode of carCodeList' [title]='carCode.Desc' [value]='carCode.Code'>{{carCode.Desc}}</option>
                                            </select>
                                        </div>
                                    </ng-container>
                                </div>

                                <div style="clear:both"></div>
                                <br />

                                <div class="box_content_inquiry_field">
                                    <ng-container *ngIf='eclaim.ProjectCode'>
                                        <div class="btn_sub_title txt_grey_2">{{ 'eclaim.mainvo_code' | translate }}</div>
                                        <div class="fl input_field_large">
                                            <select class="btn btn_dropdown_1 maincolor_btn_1" name='mainvo_code_{{i}}'
                                                [(ngModel)]='eclaim.MAINVOCode'>
                                                <!-- <option *ngFor='let prop of NatureOfSubs; let i = index' [value]="prop" [title]='prop'>{{prop}} </option> -->         
                                                <option selected value=""></option>
                                                <option *ngFor='let mainvoCode of eclaim.MAINVOCodeList' [title]='mainvoCode.Desc' [value]='mainvoCode.Code'>{{mainvoCode.Code}}</option>
                                            </select>
                                        </div>
                                    </ng-container>
                                </div>

                                <div style="clear:both"></div>
                                <br />

                                <div class="btn_sub_title txt_grey_2">{{ 'eclaim.remarks' | translate }}</div>
                                <textarea [(ngModel)]="eclaim.Remarks"
                                    class="form-control detail_staff_remark_input txt_box_1 txt_size_2" maxlength='25'
                                    name='event_{{i}}'></textarea>

                                <div style="clear:both"></div>
                                <br />

                                <div class="btn_sub_title txt_grey_2">{{ 'eclaim.attachment' | translate }}</div>
                                <div class="fl input_field_small">
                                    <label class="btn btn-block btn-lg maincolor_btn_1">
                                        <img src="assets/img/btn_add_files.png" width="18"> {{ 'eclaim.addfiles' | translate
                                        }}
                                        <input type="file" id="file_UploadAttachment_{{i}}" multiple style="display: none;"
                                            (change)="uploadListening($event, eclaim)" />
                                    </label>
                                </div>
                
                                <ng-container *ngIf='eclaim.Attachments'>
                                    <div *ngFor="let attach of eclaim.Attachments">
                                        <div id="attach_{{attach.fileID}}">
                                            <div style="clear: both"></div>
                                            <br />
                
                                            <div class="attachment_file_area bg_grey_4">
                
                                                <div class="attachment_file_txt">
                                                    <div class="attachment_photo">
                                                        <img [src]="attach.dataURL">
                                                    </div>
                                                    <div>{{attach.file.name}}</div>
                                                    <div class="txt_grey_2 txt_size_3" *ngIf="attach.file.size">
                                                        {{convertSizeFormat(attach.file.size)}}</div>
                                                    <div style="clear: both"></div>
                                                    <div *ngIf="fileProgress != 100">
                                                        <div class="bar_chart_data maincolor_bg2"
                                                            [ngStyle]="{'width' : fileProgress + '%' }"></div>sample uploading %
                                                    </div>
                                                </div>
                
                                                <div class="fr">
                                                    <div class="btn_attachment_position" (click)="downloadAttachment(attach)"
                                                        id="btn_download_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img
                                                                src="assets/img/btn_download.png" width="18">{{ 'download' | translate
                                                            }}</a>
                                                    </div>
                                                    <div class="btn_attachment_position" (click)="uploadAttachment(attach, eclaim)"
                                                        id="btn_upload_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img
                                                                src="assets/img/btn_start_upload.png" width="18">{{
                                                            'eclaim.upload' | translate }}</a>
                                                    </div>
                                                    <div class="btn_attachment_position" (click)="cancelAttachment(attach, eclaim)"
                                                        id="btn_cancel_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg btn_grey tl"><img
                                                                src="assets/img/btn_cancel_upload.png" width="18">{{
                                                            'eclaim.cancel' | translate }}</a>
                                                    </div>
                                                    <div class="btn_attachment_position" (click)="deleteAttachment(attach, eclaim)"
                                                        id="btn_delete_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg btn_grey tl"><img
                                                                src="assets/img/btn_del_doc.png" width="18">{{
                                                            'eclaim.delete' | translate }}</a>
                                                    </div>
                                                </div>
                
                                                <div style="clear: both"></div>
                
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </ng-container>

                                <div style="clear:both"></div>
                                <br />

                            </div> <!-- ========== end : form_content_input =========== -->
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this"
                                *ngIf="getNumOfActiveEClaims() != 1" (click)="deleteEClaims(eclaim, i)">x</div>
                        </div> <!-- ========== end : form_content =========== -->
                    </div>
                </form>
            </div> <!-- ========== end : form_area =========== -->
            <div style="clear:both"></div>
        </div> <!-- ========== end : box_content_inquiry =========== -->

        <div class="mult_select_approval_area" style="display: block;">
            <div class="pop_functions_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 add" type="button" (click)="addEClaim()"><img
                        src="assets/img/btn_addform_w.png" width="18" />{{ 'add' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn maincolor_btn2" type="submit"
                    (click)="confirmEClaimForm()"><img src="assets/img/btn_tick_w.png" width="18" />{{ 'submit' |
                    translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey" type="button"
                    (click)="resetEClaimApplication()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' |
                    translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>

    </div><!-- ============ end : content_box ============ -->
    <br /><br /><br />
</div>