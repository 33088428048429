import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-about-flexsystem',
  templateUrl: './popup-about-flexsystem.component.html',
  styleUrls: ['./popup-about-flexsystem.component.scss']
})
export class PopupAboutFlexsystemComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<PopupAboutFlexsystemComponent>) { }

  ngOnInit(): void {
  }

  close() {
    try{
      this.matDialogRef.close()
    } catch {}
  }

}
