import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from '../../services-UI/common-ui.service';
import { PopupPasswordPolicyComponent } from '../popup-password-policy/popup-password-policy.component';
import { CommonServiceService } from '../../services/common-service.service';

@Component({
  selector: 'app-popup-change-password',
  templateUrl: './popup-change-password.component.html',
  styleUrls: ['./popup-change-password.component.scss']
})
export class PopupChangePasswordComponent implements OnInit {

  currentPassword: string = '';
  newPassword: string = '';
  newPassword2: string = '';
  passwordPolicy;

  constructor(public matDialogRef: MatDialogRef<PopupChangePasswordComponent>, public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: {}, public commonUI: CommonUIService, public commonService: CommonServiceService) { 
    this.passwordPolicy = data['pp']
  }

  ngOnInit(): void {
  }

  doChangePassword() {
    if(this.currentPassword.length < 0 || this.newPassword.length < 0 || this.newPassword2.length < 0) return;
    if (this.newPassword != this.newPassword2) {
      //this.close();
      this.commonUI.showMsgBoxWithCallback("message.M0002", function () {
        this.showModalChangePassword();
      });
    return;
    }
      
    this.commonUI.addLoadingTask();
    this.commonService.doChangePassword(this.currentPassword, this.newPassword).subscribe((result) => {
      if(result.body['ErrorMsg']) this.commonUI.showMsgBox(`error.${result.body['ErrorCode']}`);
      else this.onDoChangePasswordSuccess();
      this.commonUI.finishLoadingTask();
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err);
      this.commonUI.finishLoadingTask();
    })
  }

  onDoChangePasswordSuccess() {
    this.commonUI.finishLoadingTask();
  
    // this.closeModalChangePassword();
    // this.closeModalResetPassword();
    this.close();
    this.commonUI.showMsgBox(!sessionStorage.IsACLogin ? "message.M0003" : "message.M0025");
    this.currentPassword = this.newPassword = this.newPassword2 = null;
    this.passwordPolicy.RequireChangePassword = false;
  
    sessionStorage.PasswordPolicy = JSON.stringify(this.passwordPolicy);
  }


  showModalPasswordPolicy() {
    this.matDialog.open(PopupPasswordPolicyComponent, {
      panelClass: 'dialog-responsive',
      data: this.data
    })
  }

  close() {
    const returnValue = {
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      newPassword2: this.newPassword2
    }
    try {
      this.matDialogRef.close(returnValue)
    } catch {}
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
