import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var formData = new FormData();
    if(req.body?.attachment && req.body.attachment != undefined && req.body.attachment != null) {
      formData.append('JSON', JSON.stringify(req.body.request))
      formData.append('Attachment', req.body.attachment)

      req = req.clone({
        body: formData
      })
    } else {
      if (environment.isV11Container) {
        req = req.clone({
          setHeaders: {
            'Content-Type': 'application/json'
          },
          //will not set to JSON=
        });
      } else {
        req = req.clone({
          setHeaders: {
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'application/json;charset=utf-8;'
          },
          body: 'JSON=' + encodeURIComponent(JSON.stringify(req.body))
        });
      }
    }
    return next.handle(req)
}


}
