<div class="pp_contact_content list_flag-selected" style="display: block;">
    <form id="form_application_contact" name="form_application_contact" (submit)="submitEvent();close();">
        <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval">
            <!--<div class="pop_functions_close maincolor_btn2 pp_close">x</div>-->

            <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
                <div class="box_title_icon t_edit"></div>
                <div class="fl">{{ 'personalprofile.editofgeneral' | translate }}</div>
            </div>

            <div class="d_box_top"></div>

            <div class="detail_box_bottom_area_profile mt0_m">

                <div class="box_content_inquiry">
                        <div class="form_area">

                            <!-- ========== a record =========== -->
                            <a name="top_form-1"></a>
                            <div id="form-1" class="form_content form_content_popup">

                                <div class="form_content_title form_content_title-selected">{{ 'personalprofile.contact' | translate }}</div>

                                <div class="form_content_input">

                                    <a name="list_type" class="list_bar_mt"></a>

                                    <div class="form_content_input_field">
                                        <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.officalemail' | translate }}</span><br>
                                        <input required class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" name='email' [(ngModel)]="obj.Email">
                                    </div>

                                    <div class="form_content_input_field">
                                        <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.personalemail' | translate }}</span><br>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" name='email2' [(ngModel)]="obj.Email2">
                                    </div>

                                    <div class="form_content_input_field">
                                        <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.phone' | translate }} 1</span><br>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" name='tel' [(ngModel)]="obj.TelNum1">
                                    </div>

                                    <div class="form_content_input_field">
                                        <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.phone' | translate }} 2</span><br>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" name='tel2' [(ngModel)]="obj.TelNum2">
                                    </div>

                                    <div class="form_content_input_field">
                                        <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.mobile' | translate }} 1</span><br>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" name='phone1' [(ngModel)]="obj.MobileNum1">
                                    </div>

                                    <div class="form_content_input_field">
                                        <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.mobile' | translate }} 2</span><br>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" name='phone2' [(ngModel)]="obj.MobileNum2">
                                    </div>

                                    <div style="clear:both"></div>

                                </div> <!-- ========== end : form_content_input =========== -->
                                <!--
                                <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                            </div> <!-- ========== end : form_content =========== -->





                        </div> <!-- ========== end : form_area =========== -->

                </div> <!-- ========== end : form_content_inquiry =========== -->
            </div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

        </div>
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <!--<div class="header_bar_icon fl list_icon_border" title="Add New"><img alt="" src="img/btn_addform_g.png" width="20"></div>-->
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" type="submit"><img src="assets/img/btn_tick_w.png" width="18">{{ 'submit' | translate }}</button>
                <a class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'cancel' | translate }}</a>
                <div style="clear:both"></div>
            </div>
        </div>
    </form>
  </div>
