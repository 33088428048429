import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PopupAppointmentDetailComponent } from '../common/popup-appointment-detail/popup-appointment-detail.component';
import { PopupInOutDetailComponent } from '../common/popup-in-out-detail/popup-in-out-detail.component';
import { PopupLeaveDetailComponent } from '../common/popup-leave-detail/popup-leave-detail.component';
import { PopupEclaimDetailComponent } from '../common_mod/popup-eclaim-detail/popup-eclaim-detail.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ApprovalService } from '../services/approval.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';

@Component({
  selector: 'app-approval-center-pending-approval',
  templateUrl: './approval-center-pending-approval.component.html',
  styleUrls: ['./approval-center-pending-approval.component.scss']
})

export class ApprovalCenterPendingApprovalComponent implements OnInit {
  employee = JSON.parse(sessionStorage.Employee);
  objList;
  resultList = [];
  staffInfo;
  multiMode;
  showSortsMenu;
  sourceTypeList = this.commonService.SourceTypeList.slice();
  search = {
    keywords: '',
    SourceType: '',
  }
  hasSwiped;
  swipeMode;
  queryType;
  allSelected;

  reverse = true;
  propertyName = 'StartDate';

  selectedIndex;
  selectedLeaveApplication;

  reason;
  approverRemarkList;
  leavePopMode;
  remarkPrefixApproved = "[Approved] ";
  remarkPrefixRejected = "[Rejected] ";
  sourceTypeListCountDict = [];

  constructor(public idleTimer: IdleTimerService, public commonUI: CommonUIService, public el: ElementRef, public commonService: CommonServiceService, public matDialog: MatDialog, public approvalService: ApprovalService, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.getModule();
    this.getStaffInfo();
    // this.checkQueryString();
  }

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    this.objList = this.resultList.filter((l) => l.SourceType == this.search.SourceType)
    switch (propertyName) {
      case 'StaffInfo.FullName':
        this.objList = this.objList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'StaffInfo.PositionCode':
        this.objList = this.objList.sort((a, b) => {
          return a.StaffInfo.PositionCode.localeCompare(b.StaffInfo.PositionCode)
        })
        break;
      case 'StartDate':
        this.objList = this.objList.sort((a, b) => {
          return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime()
        })
        break;
      case 'LeaveTypeDesc':
        this.objList = this.objList.sort((a, b) => {
          return a.SourceType.localeCompare(b.SourceType)
        })
        break;
    }
    if (this.reverse) this.objList = this.objList.slice().reverse()
    this.showSortsMenu = false;
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.resetSelectedAll(this.allSelected);
  }

  resetSelectedAll(trueOrFalse) {
    for (var i = 0; i < this.objList.length; i++) {
      this.objList[i].checked = trueOrFalse;
    }
  }

  multiModeToggle() {
    if (!this.multiMode) {
      this.multiMode = true;
      // turn off swipeMode
      this.swipeMode = false;
    } else {
      this.multiMode = false;
      this.allSelected = false;
      this.resetSelectedAll(false);
    }
  }

  showSortsMenuToggle() {
    if (!this.showSortsMenu) {
      this.showSortsMenu = true;
    } else {
      this.showSortsMenu = false;
    }
  }

  showSearchResult(selectedSourceType) {
    this.queryType = undefined;
    this.search.SourceType = selectedSourceType;
    this.objList = this.resultList.filter((l) => l.SourceType == this.search.SourceType)
    this.allSelected = false;
    this.resetSelectedAll(false);

    // $("#filter_options").children().removeClass("active");
    // $("#filter_item_"+selectedSourceType).addClass("active");
    // angular.element(document.querySelector("#filter_options")).children().removeClass("active");
    // this.el.nativeElement.querySelectorAll("#filter_options").forEach(element => {
    //   element.classList.remove("active");
    // });
    // document.querySelector("#filter_item_" + selectedSourceType)).addClass("active");
    // this.el.nativeElement.querySelector("#filter_item_" + selectedSourceType).addClass("active");

    this.commonUI.scrollToTop();
  }

  swipeModeEnable(objIndex) {
    this.hasSwiped = true;
    setTimeout(() => {
      this.hasSwiped = false;
    }, 1000);
    if (!this.multiMode) {
      this.swipeMode = true;
      // this.swipeTarget = id;
      this.selectedIndex = objIndex;
    }
  }
  swipeModeDisable() {
    this.hasSwiped = true;
    setTimeout(() => {
      this.hasSwiped = false;
    }, 1000);
    if (!this.multiMode) {
      this.swipeMode = false;
      this.selectedIndex = -1;
    }
  }

  searchByInput() {
    if (this.search.keywords.length <= 0) return this.objList = this.resultList
    else this.objList = this.resultList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  showPopupLeaveDetail(obj) {
    this.selectedIndex = obj.index;
    if (!this.hasSwiped) {
      if (this.multiMode) {
        if (obj.checked === undefined || !obj.checked) obj.checked = true;
        else if (obj.checked) obj.checked = false;
      } else if (!this.swipeMode && !this.multiMode) {
        this.selectedLeaveApplication = obj;

        this.commonUI.addLoadingTask();
        // this.commonService.getApproverRemarkList(this.selectedLeaveApplication.RID, "ELEAVE", this.selectedLeaveApplication.StartDate, this.onGetApproverRemarkListSuccess, this.onCallAPIFail);
        var rid = this.selectedLeaveApplication.RID;
        if(obj.SourceType == 'CLAIMHDR') this.selectedLeaveApplication.StartDate = obj.ClaimDate;
        this.commonService.getApproverRemarkList(rid, this.selectedLeaveApplication.SourceType, this.selectedLeaveApplication.StartDate).subscribe((result) => {

          if (!result['conn_fail'])
            if (result.body['ErrorMsg'] == null) {
              this.onGetApproverRemarkListSuccess(result.body['Data'].ApproverRemarkList)
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
      }
    }
  }

  onGetApproverRemarkListSuccess(remarkList) {
    this.approverRemarkList = this.commonService.getApproverRemarkTranslateList(remarkList);
    this.selectedLeaveApplication.showCutoff = false

    if (this.selectedLeaveApplication.SourceType == "ELEAVE") {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "LEAVECAN") {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "APPOINTMENT") {
      const dialog = this.matDialog.open(PopupAppointmentDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "CLAIMHDR") {
      const dialog = this.matDialog.open(PopupEclaimDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm) { 
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "EINOUT") {
      const dialog = this.matDialog.open(PopupInOutDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL',
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) {
          this.resultList = []
          this.queryRecordList()
        }
      })
    }
    else {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if (data == undefined || data == null) return;
        if (data.confirm) this.getModule()
      })
    }
    // this.leavePopupControl.showPopup(this.selectedLeaveApplication);
    this.commonUI.finishLoadingTask();
    this.reason = ''
  }

  approveLeaveRecord() {
    this.selectedIndex = this.selectedLeaveApplication.index;
    this.commonUI.addLoadingTask();
    var approvalRecord = {
      "RID": this.selectedLeaveApplication.RID,
      "SourceType": this.selectedLeaveApplication.SourceType,
      "StartDate": this.selectedLeaveApplication.StartDate
    };
    this.approvalService.approveRecord(this.employee, 'APV', this.remarkPrefixApproved.concat(this.reason), [approvalRecord]).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.queryRecordList();
          this.swipeModeDisable();
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0008");
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  rejectLeaveRecord() {
    this.selectedIndex = this.selectedLeaveApplication.index;
    this.commonUI.addLoadingTask();
    var approvalRecord = {
      "RID": this.selectedLeaveApplication.RID,
      "SourceType": this.selectedLeaveApplication.SourceType,
      "StartDate": this.selectedLeaveApplication.StartDate
    };
    this.approvalService.approveRecord(this.employee, 'REJ', this.remarkPrefixRejected.concat(this.reason), [approvalRecord]).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.queryRecordList();
          this.swipeModeDisable();
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0008");
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  approveAppointmentRecord() {
    this.selectedIndex = this.selectedLeaveApplication.index;
    this.commonUI.addLoadingTask();
    var approvalRecord = {
      "RID": this.selectedLeaveApplication.RID,
      "SourceType": this.selectedLeaveApplication.SourceType,
      "StartDate": this.selectedLeaveApplication.StartDate
    };
    this.approvalService.approveRecord(this.employee, 'APV', this.remarkPrefixApproved.concat(this.reason), [approvalRecord]).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.queryRecordList();
          this.swipeModeDisable();
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0008");
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  rejectAppointmentRecord() {
    this.selectedIndex = this.selectedLeaveApplication.index;
    this.commonUI.addLoadingTask();
    var approvalRecord = {
      "RID": this.selectedLeaveApplication.RID,
      "SourceType": this.selectedLeaveApplication.SourceType,
      "StartDate": this.selectedLeaveApplication.StartDate
    };
    this.approvalService.approveRecord(this.employee, 'REJ', this.remarkPrefixRejected.concat(this.reason), [approvalRecord]).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.queryRecordList();
          this.swipeModeDisable();
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0008");
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  // this.approveInOutRecord = function () {
  //   this.inOutPopupControl.closeApprovePopup();
  //   this.selectedIndex = this.selectedLeaveApplication.index;
  //   this.commonUI.addLoadingTask();
  //   var approvalRecord = {
  //       "RID": this.selectedLeaveApplication.RID,
  //       "SourceType": this.selectedLeaveApplication.SourceType,
  //       "StartDate": this.selectedLeaveApplication.Date
  //   };
  //   approvalService.approveRecord(this.employee, 'APV', this.remarkPrefixApproved.concat(this.reason), [approvalRecord], this.onApproveRecordSuccess, this.onCallAPIFail);
  // }

  // this.rejectInOutRecord = function () {
  //   this.inOutPopupControl.closeRejectPopup();
  //   this.selectedIndex = this.selectedLeaveApplication.index;
  //   this.commonUI.addLoadingTask();
  //   var approvalRecord = {
  //       "RID": this.selectedLeaveApplication.RID,
  //       "SourceType": this.selectedLeaveApplication.SourceType,
  //       "StartDate": this.selectedLeaveApplication.Date
  //   };
  //   approvalService.approveRecord(this.employee, 'REJ', this.remarkPrefixRejected.concat(this.reason), [approvalRecord], this.onApproveRecordSuccess, this.onCallAPIFail);
  // }

  doMultiApprove() {
    var approvalRecordList = [];
    for (var i = 0; i < this.objList.length; i++) {
      if (this.objList[i].checked) {
        if (this.objList[i].SourceType == "EINOUT") {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].Date
          };
        }
        else if (this.objList[i].SourceType == "CLAIMHDR") {
            var approvalRecord = {
                "RID": this.objList[i].RID,
                "SourceType": this.objList[i].SourceType,
                "StartDate": this.objList[i].ClaimDate
            };
        }
        else {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].StartDate
          };
        }
        if (this.search.SourceType == approvalRecord.SourceType) {
          approvalRecordList.push(approvalRecord);
        }
      }
    }
    // console.log("approvalRecordList.length="+ approvalRecordList.length);

    if (approvalRecordList.length > 0) {
      this.commonUI.addLoadingTask();
      this.approvalService.approveRecord(this.employee, 'APV', this.remarkPrefixApproved, approvalRecordList).subscribe((result) => {

        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == null) {
            this.objList = [];
            this.resultList = [];
            this.commonUI.finishLoadingTask();
            this.commonUI.showMsgBox("message.M0008");
            this.queryRecordList();
            this.swipeModeDisable();
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  doMultiReject() {
    var approvalRecordList = [];
    for (var i = 0; i < this.objList.length; i++) {
      if (this.objList[i].checked) {
        if (this.objList[i].SourceType == "EINOUT") {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].Date
          };
        }
        else if (this.objList[i].SourceType == "CLAIMHDR") {
          var approvalRecord = {
              "RID": this.objList[i].RID,
              "SourceType": this.objList[i].SourceType,
              "StartDate": this.objList[i].ClaimDate
          };
        }
        else {
          var approvalRecord = {
            "RID": this.objList[i].RID,
            "SourceType": this.objList[i].SourceType,
            "StartDate": this.objList[i].StartDate
          };
        }
        if (this.search.SourceType == approvalRecord.SourceType) {
          approvalRecordList.push(approvalRecord);
        }
      }
    }

    if (approvalRecordList.length > 0) {
      this.commonUI.addLoadingTask();
      this.approvalService.approveRecord(this.employee, 'REJ', this.remarkPrefixRejected, approvalRecordList).subscribe((result) => {

        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == null) {
            this.objList = [];
            this.resultList = [];
            this.commonUI.finishLoadingTask();
            this.commonUI.showMsgBox("message.M0008");
            this.queryRecordList();
            this.swipeModeDisable();
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  queryRecordList() {
    this.objList = [];
    this.route.queryParams.subscribe((data) => {
      if (data.source == undefined || data.source == null) return;
      else {
        this.search.SourceType = data.source
      }
    })
    this.sourceTypeList.forEach((sourceType, idx) => {
      this.commonUI.addLoadingTask();

      if (this.sourceTypeListCountDict[sourceType.sourceTypeCode] == undefined) {
        this.sourceTypeListCountDict.push({
          key: sourceType.sourceTypeCode,
          value: 0
        });
      }
      this.sourceTypeListCountDict[sourceType.sourceTypeCode] = 0;

      if (!this.search.SourceType) {
        this.queryType = this.sourceTypeList[0].sourceTypeCode;
        this.search.SourceType = this.sourceTypeList[0].sourceTypeCode;
      }
      this.approvalService.getPendingApprovalList(this.employee, sourceType.sourceTypeCode).subscribe((result) => {
        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == null) {
            this.onGetLeavePendingSuccess(sourceType.sourceTypeCode, result.body['Data'].ApprovalList)
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    })


    // this.objList = [];
    // for (var i = 0; i < this.sourceTypeList.length; i++) {
    //     this.commonUI.addLoadingTask();

    //     if (this.sourceTypeListCountDict[this.sourceTypeList[i].sourceTypeCode] == undefined) {
    //         this.sourceTypeListCountDict.push({
    //             key: this.sourceTypeList[i].sourceTypeCode,
    //             value: 0
    //         });
    //     }
    //     this.sourceTypeListCountDict[this.sourceTypeList[i].sourceTypeCode] = 0;

    //     if (!this.search.SourceType) {
    //         this.queryType = this.sourceTypeList[0].sourceTypeCode;
    //         this.search.SourceType = this.sourceTypeList[0].sourceTypeCode;
    //     }
    //     this.approvalService.getPendingApprovalList(this.employee, this.sourceTypeList[i].sourceTypeCode).subscribe((result) => {
    //       if (!result['conn_fail'])
    //         if (result.body['ErrorMsg'] == null) {
    //           this.onGetLeavePendingSuccess(this.sourceTypeList[i].sourceTypeCode, result.body['Data'].ApprovalList)
    //         }
    //         else
    //           this.onCallAPIFail(result.body, result.status, result.headers, result)
    //       else
    //         this.onCallAPIFail(result.body, result.status, result.headers, result)
    //     }, err => {
    //       this.onCallAPIFail(err.body, err.status, err.headers, err)
    //     })
    // }
  }

  onGetLeavePendingSuccess(sourceType, resultList) {
    this.sourceTypeListCountDict[sourceType] = resultList.length;

    resultList.forEach((record, key) => {
      if (record.AppointStart) record.StartDate = record.AppointStart;
      if (record.AppointEnd) record.EndDate = record.AppointEnd;
      if (record.Date) {
        record.StartDate = record.Date;
        record.EndDate = record.Date;
      }

      record.LeaveDateRange = this.commonUI.formatDisplayDate(record.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatDisplayDate(record.EndDate, 'D MMM YYYY (ddd)');
      record.keywords = "";
      record.keywords += record.LeaveDateRange + " ";
      record.keywords += record.LeaveTypeCode + " ";
      record.keywords += record.StaffInfo.FullName + " ";
      record.keywords += record.StaffInfo.PositionCode + " ";
      record.keywords += record.SourceType + " ";
      record.keywords += record.NumOfLeaveDay + " ";
    });

    this.resultList = [...resultList, ...this.resultList]

    this.resultList.forEach((obj, k) => {
      obj.index = k;
      obj.checked = false;
    });
    if (resultList.length == 0) this.multiMode = false;
    this.showSearchResult(this.search.SourceType)
    this.commonUI.finishLoadingTask();
  }

  getModule() {
    this.commonUI.addLoadingTask();
    this.commonService.getModule(this.employee).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          for (var i = 0; i < this.sourceTypeList.length; i++) {
            if (result.body['Data'].Module.indexOf(this.sourceTypeList[i].module) == -1) {
              this.sourceTypeList.splice(i, 1);
              i = -1;
            }
          }
          this.queryRecordList();
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getStaffInfo() {
    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfo(this.employee).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.staffInfo = result.body['Data'].StaffInfo;
          this.commonUI.finishLoadingTask();
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}