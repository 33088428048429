<div id="popup_languages">
    <div class="pop_functions_box pop_functions_box_size_company">
      <div class="pop_functions_close btn_grey_2b pp_close" (click)="close()">x</div>
        <div style="clear:both"></div>
        <br/>
        <span class="txt_size_1 txt_grey_2">{{ 'toolbar.languages' | translate }}</span>
        <br/><br/>
        <div class="languagesposition_content bg_grey_4" style="overflow: hidden;" *ngIf='languageList'>    
            <button id="pp1_login" *ngFor="let language of languageList" (click)="switchToLang(language.value)" class="btn btn-block btn-lg maincolor_btn2 icon_arrow_r pp_close">
                <div class="btn_txt_icon_right">{{ language.desc }}</div>
            </button>
        </div>
  
    </div>
  </div>