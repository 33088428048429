import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ApprovalService } from 'src/app/services/approval.service';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-popup-delegation-settings-preview',
  templateUrl: './popup-delegation-settings-preview.component.html',
  styleUrls: ['./popup-delegation-settings-preview.component.scss']
})
export class PopupDelegationSettingsPreviewComponent implements OnInit {

  employee = JSON.parse(sessionStorage.Employee);
  selectedIndex;
  currentSetting;
  settings = [];
  confirm = false

  constructor(public commonUI: CommonUIService, public commonService: CommonServiceService, public approvalService: ApprovalService, public matDialogRef: MatDialogRef<PopupDelegationSettingsPreviewComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.selectedIndex = data['selectedIndex'];
    this.currentSetting = data['currentSetting'];
    this.settings = data['settings'];
  }

  ngOnInit(): void {
  }

  submitDelegationSettings() {
    if (this.selectedIndex == -1) {
      this.settings.push(this.currentSetting)
    } else {
      this.settings[this.selectedIndex] = this.currentSetting;
    }
    this.commonUI.addLoadingTask();
    this.approvalService.submitDelegationSettings(this.employee, this.settings).subscribe((result) => {
      
      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.settings = result.body['Data'].DelegationSettings;
          if (this.settings != undefined) {
            this.settings.forEach((v, key) => {
              v.index = key;
              this.commonUI.finishLoadingTask();
              this.confirm = true
              this.close()
            })
          }
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  close() {
    const returnVal = {
      confirm: this.confirm
    }
    try {
      this.matDialogRef.close(returnVal)
    } catch { }
  }

}
