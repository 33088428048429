// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  scarfSettings: {
    enable: false
  },
  siteConfig: {
    toolbar: {
      enableChangePassword: true,
      enableLanguages: true,
      enableSystemIntro: true,
      enableCurrentVersion: true,
      enableTermsAndConditions: true,
      enableAbout: true
    },
    idleTimer: {
      timeout: 720,
      countDown: 120,
    },

    enableForgetPassword: true,
    standardVersion: true,
    homeTemplateUrl: 'views/home.html',
    menuTemplateUrl: 'views/common/menu.html',
    languages: 'en,zh_HK,zh_CN', //en,zh_HK,zh_CN
    /* Config to use MOD MENU */
    enableModMenu: true,
  },

  //Define global variable
  //var serverHost = "http://127.0.0.1:6082";
  //var serverHost = "http://172.20.6.104:6082";
  serverHost: './api/V11', //"handler/apiHandler.ashx", 
  //var serverHost = "http://172.20.6.166:6082";
  //var serverHost = "http://172.20.3.254:6082";

  isV11Container: false,

  SECMSHost: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/SECMS/App/V12ESSLogin',

  ErrorCode: {
    NoError: "00000"
  },

  displayMaintenancePage: false,

  apiList: {
    GetServerEncryption: "SE",

    Login: 0,
    Logout: 1,
    LoginSSO: 2,
    LoginCustom: 3,
    LoginAC: 5,
    ForgetPwd: 'FP',
    ValidToken: "VT",
    ValidApprovalToken: "VAT",
    Upload: 'U',
    Download: 'D',
    ChangePwd: 'C',
    UpdateSession: 'US',
    GetSECMSLoginToken: 'ST',
    GetOTP: "OTP",
    GetACSessionCriteria: 'ACTKU',

    GetAttachmentSessionToken: "GATTACTOKEN",
    DownloadAttachmentSessionToken: 'DATTACTOKEN',

    GetCompany: '0004',
    SetCompany: '0007',
    GetStaffInfo: '2101',
    GetStaffInfoByID: '2102',
    GetDepartmentList: '2103',
    GetGradeList: '2104',
    GetAllStaffList: '2105',
    GetPositionList: '2106',
    GetSectionList: '2107',
    GetDepartmentStaffList: '2108',
    GetMenu: '0003',
    GetModule: '0008',
    GetLastLogin: '0005',
    UpdateLastLogin: '0006',

    GetLeaveTypeList: '5001',
    GetTakingUnits: '5002',
    GetLeaveCutOffDate: '5003',
    CalSelfLeaveBalance: '5102',
    CalTeamLeaveBalance: '5103',
    CalDelegationLeaveBalance: '5104',
    GetSelfLeaveApplicationList: '5202',
    GetTeammateLeaveApplicationList: '5203',
    SubmitLeaveApplication: '5204',
    GetApproverRemarkList: '5201',
    CalNumOfLeaveDays: "5205",
    GetDelegationLeaveApplicationList: '5206',
    WithdrawLeaveApplication: '5301',
    CancelLeaveApplication: '5401',

    GetPendingApprovalList: '8101',
    ApproveRecord: '8102',
    GetApprovalLogList: "8103",
    GetNextApprover: "8104",
    GetOverridePendingApprovalList: '8105',
    OverrideApproveRecord: '8106',
    GetDelegationSettings: '8201',
    SubmitDelegationSettings: '8202',
    GetDelegationPendingApprovalList: '8203',
    DelegationApproveRecord: '8204',
    GetSingleNextApprover: '8205',

    GetSelfAppointmentList: "6001",
    GetTeammateAppointmentList: "6002",
    SubmitAppointmentApplication: "6003",
    WithdrawAppointmentApplication: "6004",
    EditAppointmentApplication: "6006",

    GetShiftDetailList: "6007",
    GetRawPunchDetailList: "6008",
    GetStaffPunchDetailList: "6009",
    GetSiteDetailList: "6010",
    SubmitEInOutApplication: "6011",
    GetSelfEInOutApplicationList: "6012",
    GetTeammateEInOutApplicationList: "6013",
    GetSelfPunchDetailList: "6014",
    GetTeammatePunchDetailList: "6015",
    WithdrawEInOutAppilcation: "6016",

    GetSelfCalendar: "7001",
    GetTeammateCalendar: "7002",
    GetDepartmentCalendar: "7003",
    GetDepartmentCalendarBySection: "7004",
    GetAllStaffsViewCalendar: "7005",
    GetCalendarStaffList: '7006',
    GetAllStaffsViewDepartmentCalendar: '7007',
    GetHolidayDateList: '7008',

    GetPaySlipList: "9000",
    GetPaySlip: "9001",
    GetTaxFormList: "9002",
    GetTaxForm: "9003",
    GetPaySlipYearList: "9004",
    GetApprovalGroupList: "9005",
    GetLeaveBalanceReport: "9006",
    GetTaxSummaryReport: "9007",

    GetPersnlProfile: "1001",
    GetPersnlEmploy: "1002",
    GetPersnlAddressList: "1003",
    GetPersnlBankAccList: "1004",
    GetPersnlDependtList: "1005",
    GetPersnlQualiList: "1006",

    GetAddressTypeList: "1101",
    GetAreaList: "1102",
    GetDistrictList: "1103",
    GetBankList: "1104",
    GetQualiTypeList: "1105",
    GetRelationshipList: "1106",
    GetIdentityTypeList: "1107",

    SubmitMaritalStatus: "1010",
    SubmitContact: "1011",
    SubmitBankAccount: "1012",
    SubmitAddress: "1013",
    SubmitDepndt: "1014",
    SubmitQuali: "1015",

    GetProfileAmendDict: "1201",

    GetContainerLoginSetting: "CTLogin",

    GetClaimTypeList: '3002',
    GetClaimBalance: '3004',
    GetCurrencyList: '3001',
    SubmitClaimApplication: '3003',
    GetTeamEclaimApplicationList: '3006',
    GetSelfEclaimApplicationList: '3005',

    GetPunchQRCode: "Q1001",

    /* EClaim Mod 23/7/2021 by Alvin */
    WithdrawEClaimApplication: '3007',
    GetProjectCodeList: "PI_3008",
    GetCarCodeList: "PI_3009",
    GetExpenseTypeCodeList: "PI_3010",
    GetMAINVOCodeList: "PI_3011",

    /*Report Mod 31/8/2021*/
    GetClaimReport: 'PI_RP3004',
    GetApprovalGroupListMod: "PI_RP9005",
  },

  SourceTypeList: [
    {
      seq: 1, sourceTypeCode: "ELEAVE",
      displayName: "approval.leave",
      displayNameForDashboard: "dashboard.leave",
      // module: "LeaveApv",
      url: "home.approval-pending-approval",
      numOfPending: 0,
      cssClass: "f_leave"
    },
    {
      seq: 2, sourceTypeCode: "LEAVECAN",
      displayName: "approval.cancellation",
      displayNameForDashboard: "dashboard.leaveCancellation",
      // module: "LeaveCancelApv",
      url: "home.approval-pending-approval",
      numOfPending: 0,
      cssClass: "f_cancellation"
    },
    {
      seq: 3, sourceTypeCode: "APPOINTMENT",
      displayName: "approval.appointment",
      displayNameForDashboard: "dashboard.appointment",
      // module: "AppointmentApv",
      url: "home.approval-pending-approval",
      numOfPending: 0,
      cssClass: "f_appointment"
    },
    {
      seq: 4, sourceTypeCode: "CLAIMHDR",
      displayName: "approval.claim",
      displayNameForDashboard: "dashboard.claim",
      // module: "ClaimApv",
      url: "home.approval-pending-approval",
      numOfPending: 0,
      cssClass: "f_claim"
    },
    {
      seq: 5, sourceTypeCode: "EINOUT",
      displayName: "approval.einout",
      displayNameForDashboard: "dashboard.einout",
      // module: "EInOutApv",
      url: "home.e-in-out-pending-approval",
      numOfPending: 0,
      cssClass: "f_einout"
    },
    {
      seq: 6, sourceTypeCode: "COMLEAVEENT",
      displayName: "approval.comleaveent",
      displayNameForDashboard: "dashboard.comleaveent",
      // module: "LeaveApv",
      url: "home.approval-pending-approval",
      numOfPending: 0,
      cssClass: "f_comleaveent"
    }
  ],

  //Login Custom, if true, system will use custom mechanism
  loginCustom: false,

  // Get URL
  $urlParam: function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)')
      .exec(window.location.href);
    return results[1] || 0;
  },

  clone: function (src) {
    return JSON.parse(JSON.stringify(src));
  },

  GetFileExtension: function (filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  },

  padLeft: function (str, paddingChar, size) {
    var orgStr = str.toString();
    var numOfCharNeeded = size - orgStr.length;
    var padding = '';
    while (numOfCharNeeded > 0) {
      padding += paddingChar.toString();
      numOfCharNeeded -= padding.length;
    }
    return padding + orgStr;
  },

  isIOS: function () {
    if (navigator.platform) {
      if (/iPad|iPhone|iPod/.test(navigator.platform))
        return true;
      if (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform))
        return true;
    }
    return false;
  },

  isMobileDevice: function () {
    if (this.isIOS())
      return true;

    return /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },

  isChromeIOS: function () {
    return navigator.userAgent.match('CriOS');
  },

  isIE: function () {
    return navigator.appVersion.toString().indexOf('.NET') > 0;
  },

  validateUploadAttachment: function (attachment) {
    return ""; //default "" for valid
  },

  isValidatePreviewAttachment: function (format) {
    switch (format.toLowerCase()) {
      case "pdf":
      case "gif":
      case "png":
      case "jpg":
      case "jpeg":
        return true;
    }
    return false;
  },

  isIOSWebview: function () {
    // const standalone = window.matchMedia('(display-mode: standalone)').matches; // is web app full screen
    // const isSafari = navigator.userAgent.toLowerCase().includes('safari');
    // return !standalone && !isSafari;
    const userAgent = window.navigator.userAgent;
    return /((iPhone|iPod|iPad).+WebKit[^S]*$)|(Version\/[\d.]+ Mobile\/\w+ Safari\/[\d.]+$)/i.test(userAgent);
  },

  isAndroidWebview: function () {
    let isAndroidWebView = navigator.userAgent.toLowerCase().match(/android.*applewebkit(?=.*version)/);
    return isAndroidWebView;
  }


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
