<!--============ popup : Edit of bank ============ -->

<div class="pp_bank_content list_flag-selected" style="display: block;">
    <form id="form_application_bank" name="form_application_bank" (submit)="submitEvent()">
        <div class="pp_bank_content pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval">
            <!--<div class="pop_functions_close maincolor_btn2 pp_close">x</div>-->

            <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
                <div class="box_title_icon t_edit"></div>
                <div class="fl">{{ 'personalprofile.editof' | translate }} {{ 'personalprofile.tabbank' | translate }}</div>
            </div>

            <div class="d_box_top"></div>

            <div class="detail_box_bottom_area_profile mt0_m">

                <div class="box_content_inquiry">
                    <div class="form_area">

                        <!-- ========== a record =========== -->
                        <a name="top_form-1"></a>
                        <div id="form-1" class="form_content form_content_popup">

                            <div class="form_content_title form_content_title-selected">{{ 'personalprofile.account' | translate }} : {{ obj.AccountNum }}</div>

                            <div class="form_content_input">

                                <a name="list_type" class="list_bar_mt"></a>

                                <div class="form_content_input_field">
                                    <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.bank' | translate }}</span><br>
                                    <div class="input_field_large bankcode_dropdown">
                                        <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="obj.BankCode" name='bankCode'>
                                            <option *ngFor="let bank of bankList" [value]="bank.Code">{{ bank.Code }}</option>
                                        </select>
                                    </div>
                                    <input required class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [(ngModel)]="obj.AccountNum" name='accountnum'>
                                    <textarea class="form-control input_profile_manyline bg_none txt_grey_1 txt_size_2" style="height:76px;" readonly="" [(ngModel)]="selectedBankDesc" name='bankdsec'></textarea>
                                </div>

                                <div class="form_content_input_field">
                                    <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'personalprofile.accountname' | translate }}</span><br>
                                    <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [(ngModel)]="obj.AccountName" name='accountname'>
                                </div>

                                <div class="form_content_input_field_full">
                                    <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.attachment' | translate }}</div>
                                    <div class="fl input_field_small">
                                        <label class="btn btn-block btn-lg maincolor_btn_1" [class.disabled]="attachmentExisted()">
                                            <img src="assets/img/btn_add_files.png" width="18"> {{ 'leaveapplication.addfiles' | translate }}
                                            <input type="file" id="file_BankUploadAttachment" multiple style="display: none;" (change)="uploadListening($event)" name='attachment' [disabled]='attachmentExisted()'/>
                                        </label>
                                    </div>
                                    <div *ngFor="let attach of obj.attachments">
                                        <div id="attach_{{attach.fileID}}">
                                            <div style="clear: both"></div>
                                            <br />

                                            <div class="attachment_file_area bg_grey_4">

                                                <div class="attachment_file_txt">
                                                    <div class="attachment_photo">
                                                        <img [src]="attach.dataURL">
                                                    </div>
                                                    <div>{{attach.file.name}}</div>
                                                    <div class="txt_grey_2 txt_size_3" *ngIf="attach.file.size">{{convertSizeFormat(attach.file.size)}}</div>
                                                    <div style="clear: both"></div>
                                                    <div *ngIf="fileProgress != 100">
                                                        <div class="bar_chart_data maincolor_bg2" [ngStyle]="{'width': fileProgress + '%'}"></div><!-- sample uploading % -->
                                                    </div>
                                                </div>

                                                <div class="fr">
                                                    <!-- <div class="btn_attachment_position" (click)="uploadAttachment(attach)" id="btn_upload_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img src="assets/img/btn_start_upload.png" width="18">{{ 'leaveapplication.upload' | translate }}</a>
                                                    </div>
                                                    <div class="btn_attachment_position" (click)="cancelAttachment(attach)" id="btn_cancel_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_cancel_upload.png" width="18">{{ 'leaveapplication.cancel' | translate }}</a>
                                                    </div> -->
                                                    <div class="btn_attachment_position" (click)="deleteAttachment(attach)" id="btn_delete_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplication.delete' | translate }}</a>
                                                    </div>
                                                </div>

                                                <div style="clear: both"></div>

                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div style="clear:both"></div>

                            </div> <!-- ========== end : form_content_input =========== -->
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                        </div> <!-- ========== end : form_content =========== -->





                    </div> <!-- ========== end : form_area =========== -->

                </div> <!-- ========== end : form_content_inquiry =========== -->
            </div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

        </div><div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <!--<div class="header_bar_icon fl list_icon_border" title="Add New"><img alt="" src="assets/img/btn_addform_g.png" width="20"></div>-->
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" type="submit"><img src="assets/img/btn_tick_w.png" width="18">{{ 'submit' | translate }}</button>
                <a class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'cancel' | translate }}</a>
                <div style="clear:both"></div>
            </div>
        </div>
    </form>
</div>
