import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PopupLeaveDetailComponent } from '../common/popup-leave-detail/popup-leave-detail.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';
import { LeaveService } from '../services/leave.service';

declare var $: any;

@Component({
  selector: 'app-delegation-application-inquiry',
  templateUrl: './delegation-application-inquiry.component.html',
  styleUrls: ['./delegation-application-inquiry.component.scss']
})
export class DelegationApplicationInquiryComponent implements OnInit {
  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  @ViewChild('endPicker', { static: false }) endPicker: MatDatepicker<any>;
  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  title = "LeaveApplicationInquiry";
  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  StaffModule;
  startDate = moment().add(-1, 'months').toDate();
  endDate = moment().add(1, 'months').toDate();
  leaveTypeCode = "";
  leaveTypeList = [];
  selectedStatus = "";
  leaveApplicationList = [];
  resultList = [];
  isTeammateViewSelected = true;
  approverRemarkList = [];
  cancelReason;
  popupmode;
  selectedLeaveApplication;


  search = {
    keywords: ""
  };
  propertyName = "StartDate";
  reverse = true;

  constructor(public idleTimer: IdleTimerService,public commonService: CommonServiceService, public commonUI: CommonUIService, public leaveService: LeaveService, public translate: TranslateService, public matDialog: MatDialog) { 
    this.translate.onLangChange.subscribe(() => {
      this.startDate = moment(this.startDate).toDate();
      this.endDate = moment(this.endDate).toDate();
      this.translate.get('leaveapplicationinquery.status_all').subscribe((res) => {
        if(this.leaveTypeList) this.leaveTypeList[0].Desc = res;
    })
  })
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.commonUI.addLoadingTask();
    this.getModule();
    this.getLeaveTypeList();
    this.commonUI.finishLoadingTask();
  }

  getModule() {
    this.commonUI.addLoadingTask();
    this.commonService.getModule(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.StaffModule = result.body['Data'].Module;
        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  dateValidator(date) {
    switch (date) {
      case 'start':
        if (this.startDate > this.endDate)
          this.endDate = new Date(this.startDate)
        break;
      case 'end':
        if (this.endDate < this.startDate)
          this.startDate = new Date(this.endDate)
        break;
    }
  }

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch (propertyName) {
      case 'StaffInfo.FullName':
        this.leaveApplicationList = this.leaveApplicationList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'LeaveTypeCode':
        this.leaveApplicationList = this.leaveApplicationList.sort((a, b) => {
          return a.LeaveTypeCode.localeCompare(b.LeaveTypeCode)
        })
        break;
      case 'StartDate':
        this.leaveApplicationList = this.leaveApplicationList.sort((a, b) => {
          return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime()
        })
        break;
    }
    if(this.reverse) this.leaveApplicationList = this.leaveApplicationList.slice().reverse()
    this.toggleSortMenu()
  }

  toggleSortMenu() {
    $("#p4_content").fadeToggle();
  }

  clearFilter() {
    this.search.keywords = "";
    this.leaveApplicationList = this.resultList
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(0).addClass("active");

    this.commonUI.scrollToTop();
  }

  applyFilter(keyword, i) {
    this.search.keywords = keyword + " ";
    this.leaveApplicationList = this.resultList.filter(o => o.StatusDesc == keyword)
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(i).addClass("active");

    this.commonUI.scrollToTop();
  }

  getNumOfApplication(status) {
    var count = 0;
    if (status == "") return this.resultList.length;
    for (var i = 0; i < this.resultList.length; i++) {
      if (this.resultList[i].StatusDesc == status) count++;
    }
    return count;
  }

  resetSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

    this.startDate = moment().add(-1, 'years').toDate();
    this.endDate = moment().add(1, 'years').toDate();
    this.leaveTypeCode = "";
    this.selectedStatus = "";
    this.isTeammateViewSelected = true;
  }

  getLeaveTypeList() {
    let instance = this
    this.commonUI.addLoadingTask();

    this.leaveTypeList = [];
    if (this.isTeammateViewSelected)
      this.leaveService.getLeaveTypeList().subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.leaveTypeList = result.body['Data'].LeaveTypeList;
          this.translate.get('all').subscribe((all_type) => {
              instance.leaveTypeList.splice(0, 0, { "Code": "", "Desc": all_type, "EnableInESS": "Y" });
          })
          this.leaveTypeList = this.leaveTypeList.filter((l) => l.EnableInESS == 'Y')
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  prepareListInfo() {
    var index = 0;

    this.leaveApplicationList.forEach(leaveApplication => {
      leaveApplication.LeaveDateRange = this.commonUI.formatDisplayDate(leaveApplication.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatDisplayDate(leaveApplication.EndDate, 'D MMM YYYY (ddd)');
      leaveApplication.keywords = "";
      leaveApplication.keywords += leaveApplication.LeaveDateRange + " ";
      leaveApplication.keywords += leaveApplication.StaffInfo.FullName + " ";
      leaveApplication.keywords += leaveApplication.LeaveTypeCode + " ";
      leaveApplication.keywords += leaveApplication.StatusDesc + " ";
      leaveApplication.keywords += leaveApplication.Description + " ";
      leaveApplication.index = index++;
    });
  }

  showTeammateSearchResult() {
    let instance = this
    this.commonUI.addLoadingTask();
    this.leaveService.getDelegationLeaveApplicationList(
      this.employee,
      this.commonUI.formatJsonDate(this.startDate, "YYYY-MM-DDT00:00:00"),
      this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00"),
      this.leaveTypeCode, this.selectedStatus).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.leaveApplicationList = result.body['Data'].LeaveApplicationList;
          this.resultList = this.leaveApplicationList;
          this.prepareListInfo();
          $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", function () {
            instance.commonUI.finishLoadingTask();
            $('html, body').animate({
              scrollTop: $(".list_result_area").offset().top
            }, 1000);
          });
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  showLeaveApplication(leaveApplication) {
    this.popupmode = leaveApplication.IsApprovable ? "APPROVAL" : "READONLY";
    this.selectedLeaveApplication = leaveApplication;
    var isLeaveCancelApp = this.StaffModule.find(o => o == 'LeaveCancelApp')
    if (isLeaveCancelApp && leaveApplication.IsCancellable) this.popupmode = "CANCEL";
    if (leaveApplication.IsWithdrawable) this.popupmode = "WITHDRAW";

    this.commonUI.addLoadingTask();
    this.commonService.getApproverRemarkList(leaveApplication.RID, "ELEAVE", leaveApplication.StartDate).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.approverRemarkList = this.commonService.getApproverRemarkTranslateList(result.body['Data'].ApproverRemarkList);
        // this.popupControl.showPopup(this.selectedLeaveApplication);
        this.commonUI.finishLoadingTask();
        this.cancelReason = "";
        this.matDialog.open(PopupLeaveDetailComponent, {
          panelClass: 'dialog-panel',
          data: {
            app: leaveApplication,
            approver_remark_list: this.approverRemarkList,
            popmode: this.popupmode
          }
        })
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  showSearchResult() {
    if (this.isTeammateViewSelected) {
        this.showTeammateSearchResult();
    }
  }

  hideSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();
  }

  /* Datepicker Footer */
  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.startDate = new Date()
        instance.startPicker.close()
        break;
      case 'end':
        instance.endDate = new Date()
        instance.endPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
      case 'end':
        this.endPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
  }

  /* *************** */

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }


}
