import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { LeaveService } from 'src/app/services/leave.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'leave-detail-popup',
  templateUrl: './leave-detail-popup.component.html',
  styleUrls: ['./leave-detail-popup.component.scss']
})
export class LeaveDetailPopupComponent implements OnInit {

  @Input() popupid: "@"
  @Input() withdrawConfirmPopupid: "@"
  @Input() cancelRemarkPopupid: "@"
  @Input() cancelConfirmPopupid: "@"
  @Input() approveRemarkPopupid: "@"
  @Input() rejectRemarkPopupid: "@"
  @Input() leaveBalancePopupid: "@"
  @Input() cancelreason: '='
  @Input() approvereason: string
  @Input() rejectreason: '='
  @Input() popupcontrol: '='
  @Input() popupmode: string
  @Input() approverRemarkList: '='
  @Input() cancelLeaveEvent: '&'
  @Input() withdrawLeaveEvent: '&'
  @Input() approveLeaveEvent: '&'
  @Input() rejectLeaveEvent: '&'

  employee = sessionStorage.Employee //JSON.parse(sessionStorage.Employee);
  translatedFully: any;
  attachmentFile = {};
  asAtDay: '';
  leaveapplication = {
    SourceType: '',
    Description: '',
    CancelReason: '',
    AttachmentLink1: '',
    AttachmentLink2: '',
    AttachmentLink3: '',
    AttachmentLink4: '',
    LeaveTypeDesc: '',
    CreateDate: new Date(),
    StartDate: new Date(),
    EndDate: new Date(),
    NumOfLeaveDay: '',
    StatusDesc: '',
    StaffInfo: {
        FullName: '',
        PositionDesc: '',
        StaffID: ''
    }
  }
  leaveBalance = {
    LeaveYearStart: '',
    LeaveYearEnd: '',
    currentYearDetail: {
        AvailableBalance: '',
        GraceExpiry: '',
        GraceEntitlement: '',
        GraceTaken: '',
        GraceBalance: '',
        OpenBalance: '',
        Entitlement: '',
        Adjustment: '',
        Taken: '',
        TotalBalance: ''
    },
    OpenBalance: '',
    StaffInfo: {
        FullName: '',
        PositionDesc: '',
        StaffID: ''
    }
  }
  attachment1
  attachment2
  attachment3
  attachment4

  startDateTakingUnit: ''
  endDateTakingUnit: ''

  staffInfo = {
    FullName: 'TT',
    PositionDesc: 'Programmer', 
    StaffID: '1104',
    ImagePath: '',
    Gender: 'M',
  }

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public commonService: CommonServiceService, public leaveService: LeaveService, public translate: TranslateService) { 
    this.translate.get('leaveapplication.fullday').subscribe((fulldayStr) => {
      this.translatedFully = fulldayStr
    })
  }

//   ngOnChanges(changes: SimpleChange) {
//     if(changes.currentValue.approvereason != undefined && changes.currentValue.approvereason.length > 0) {
//       this.approvereason = changes.previousValue.approvereason
//     }

//     if (changes.currentValue.rejectreason != undefined && changes.currentValue.rejectreason.length > 180) {
//       this.rejectreason = changes.previousValue.rejectreason;
//     }
//   }

  ngOnInit(): void {
  }

  onCalSelfLeaveBalanceSuccess = function (service, leaveBalance) {
      this.commonUI.finishLoadingTask();
      this.leaveBalance = leaveBalance;
      this.showLeaveBalancePopup();
  }

  onCallAPIFail = function (data, status, headers, config) {
      this.commonUI.finishLoadingTask();
      if (status == "200") {
          this.errorUI.showErrorBox(data.ErrorCode);
      } else {
          this.commonService.navigatedLogin();
      }
  }

  onDownloadAttachmentSuccess = function (data) {
      this.commonUI.finishLoadingTask();

      $.colorbox({
          inline: true, width: "95%", height: "80%", href: "#pdf_target",
          scrolling: false,
          scalePhotos: true,
          onCleanup: this.onColorBoxCleanUp,
          onComplete: this.onColorBoxComplete
      });
  }

  onColorBoxComplete = function () {
      $("#pdf_target").attr("src", this.attachmentFile.dataURL);
  }

  onColorBoxCleanUp = function () {
      $("#pdf_target").attr("src", "about:blank");
  }


  showPopup(leaveapplication) {
    this.attachment1 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment2 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment3 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment4 = { downloaded: false, dataURL: "", ext: "" };
    this.leaveapplication = leaveapplication;

    $("#" + this.popupid).fadeIn();

    // if (leaveapplication.AttachmentLink1 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink1, environment.GetFileExtension(leaveapplication.AttachmentName1), this.attachment1);
    // if (leaveapplication.AttachmentLink2 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink2, environment.GetFileExtension(leaveapplication.AttachmentName2), this.attachment2);
    // if (leaveapplication.AttachmentLink3 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink3, environment.GetFileExtension(leaveapplication.AttachmentName3), this.attachment3);
    // if (leaveapplication.AttachmentLink4 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink4, environment.GetFileExtension(leaveapplication.AttachmentName4), this.attachment4);

    // this.startDateTakingUnit = leaveapplication.StartDateTakingUnit ? leaveapplication.StartDateTakingUnit : this.transalatedFullday;
    // this.endDateTakingUnit = leaveapplication.EndDateTakingUnit ? leaveapplication.EndDateTakingUnit : (leaveapplication.IsEndDateAllDay == "Y" ? this.transalatedFullday : this.startDateTakingUnit);
  }

  closePopup = function () {
      $("#" + this.popupid).fadeOut();
  }

  showCancelPopup = function () {
      $("#" + this.popupid).fadeOut();
      $("#" + this.cancelRemarkPopupid).fadeIn();
  }

  showCancelConfirmPopup = function () {
      $("#" + this.cancelRemarkPopupid).fadeOut();
      $("#" + this.cancelConfirmPopupid).fadeIn();
  }

  showApprovePopup = function () {
      $("#" + this.popupid).fadeOut();
      $("#" + this.approveRemarkPopupid).fadeIn();
  }

  showRejectPopup = function () {
      $("#" + this.popupid).fadeOut();
      $("#" + this.rejectRemarkPopupid).fadeIn();
  }

  closeCancelPopup = function () {
      $("#" + this.cancelRemarkPopupid).fadeOut();
  }

  closeCancelConfirmPopup = function () {
      $("#" + this.cancelConfirmPopupid).fadeOut();
  }

  closeApprovePopup = function () {
      $("#" + this.approveRemarkPopupid).fadeOut();
  }

  closeRejectPopup = function () {
      $("#" + this.rejectRemarkPopupid).fadeOut();
  }

  showWithdrawConfirm = function () {
      $("#" + this.popupid).fadeOut();
      $("#" + this.withdrawConfirmPopupid).fadeIn();
  }

  closeWithdrawConfirm = function () {
      $("#" + this.withdrawConfirmPopupid).fadeOut();
  }

  cancelWithdraw = function () {
      $("#" + this.withdrawConfirmPopupid).fadeOut();
  }

  calStaffLeaveBalance = function () {
      this.commonUI.addLoadingTask();
      this.asAtDay = this.commonUI.formatJsonDate(new Date(), "YYYY-MM-DDT00:00:00")
      var employee = { RID: this.leaveapplication.EmploymentRID };
      this.leaveService.calSelfLeaveBalance(employee, this.leaveapplication.LeaveTypeCode, this.asAtDay,
          this.onCalSelfLeaveBalanceSuccess, this.onCallAPIFail);
  }

  showLeaveBalancePopup = function () {
    $("#" + this.popupid).fadeOut();
    $("#" + this.leaveBalancePopupid).fadeIn();
}

closeLeaveBalancePopup = function () {
    $("#" + this.leaveBalancePopupid).fadeOut();
    $("#" + this.popupid).fadeIn();
}

viewAttachment = function (link, fileName) {
  var ext = environment.GetFileExtension(fileName);

  if (environment.isMobileDevice() || environment.isIE() || !environment.isValidatePreviewAttachment(ext)) {
      this.commonUI.finishLoadingTask();
      this.commonHandler.saveAttachment(link, 'Attachment.' + ext);
  } else {
      this.commonService.viewAttachment(link, ext, this.attachmentFile, this.onDownloadAttachmentSuccess, this.onCallAPIFail);
  }
}

}
