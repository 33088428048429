<div [id]="displaySwitch" class="pp_qualification_content  list_flag-selected" style="display: block;">
    <form id="form_application_qualifications" name="form_application_qualifications" (submit)="submitEvent()">
        <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval">
            <!--<div class="pop_functions_close maincolor_btn2 pp_close">x</div>-->

            <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
                <div class="box_title_icon t_edit"></div>
                <div class="fl">{{ 'personalprofile.editof' | translate }} {{ 'personalprofile.tabqualification' | translate }}</div>
            </div>

            <div class="d_box_top"></div>

            <div class="detail_box_bottom_area_profile mt0_m">
                <div class="box_content_inquiry">

                    <div class="form_area">

                        <div *ngFor="let quali of obj; let i = index;">
                        <!-- ========== a record =========== -->
                        <div id="form-{{i}}" class="form_content form_content_popup" *ngIf='!quali.deleted'>
                            <div class="form_content_title" (click)="slideUp($event);quali.onFocus = true" [ngClass]="{ 'form_content_title-selected': quali.onFocus }">
                                {{ getQualiTypeDesc(quali.QualiTypeCode) }}
                                <span class="txt_label txt_label_profile_action color_label_new_edit txt_size_3" *ngIf="quali.Action == 'I'">{{ 'personalprofile.typeAction.I' | translate }}</span>
                                <span class="txt_label txt_label_profile_action color_label_keep txt_size_3" *ngIf="quali.Action == 'K'">{{ 'personalprofile.typeAction.K' | translate }}</span>
                                <span class="txt_label txt_label_profile_action color_label_update txt_size_3" *ngIf="quali.Action == 'U'">{{ 'personalprofile.typeAction.U' | translate }}</span>
                                <span class="txt_label txt_label_profile_action color_label_remove txt_size_3" *ngIf="quali.Action == 'D'">{{ 'personalprofile.typeAction.D' | translate }}</span>
                            </div>

                            <div class="form_content_input">

                                <a name="list_type" class="list_bar_mt"></a>

                                <div class="btn_sub_title txt_grey_2" *ngIf="quali.Action != 'I'">{{ 'personalprofile.action' | translate }}</div>
                                <div class="btn_3select1" *ngIf="quali.Action != 'I'">
                                    <div id="f_keep" class="btn btn-block btn-lg two_btn maincolor_btn2" [ngClass]="{'active': quali.Action == 'K'}" title="{{ 'personalprofile.typeActionDesc.K' | translate }}" (click)="quali.Action = 'K'">{{ 'personalprofile.typeAction.K' | translate }}</div>
                                    <div id="f_update" class="btn btn-block btn-lg two_btn maincolor_btn2" [ngClass]="{'active': quali.Action == 'U'}" title="{{ 'personalprofile.typeActionDesc.U' | translate }}" (click)="quali.Action = 'U'">{{ 'personalprofile.typeAction.U' | translate }}</div>
                                    <div id="f_delete" class="btn btn-block btn-lg two_btn maincolor_btn2" [ngClass]="{'active': quali.Action == 'D'}" title="{{ 'personalprofile.typeActionDesc.D' | translate }}" (click)="quali.Action = 'D'">{{ 'personalprofile.typeAction.D' | translate }}</div>
                                    <div style="clear:both;"></div>
                                </div>

                                <div class="btn_3select1_form" *ngIf="quali.Action != 'D'">
                                    <!-- ====== start : btn_3select1 next div ====== -->

                                    <div class="form_content_input_field_full">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.name' | translate }}</div>
                                        <textarea class="form-control detail_staff_remark_input txt_size_2" [disabled]="quali.Action == 'K'" [(ngModel)]="quali.Name" name='name_{{i}}'></textarea>
                                    </div>

                                    <div class="form_content_input_field" [style.pointerEvents]="quali.Action == 'K'? 'none' : ''">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.effectivedate' | translate }}</div>
                                        <div class="fl input_new_calendar" [ngClass]="quali.Action == 'K' ? 'input_new_calendar_readonly' : '' ">
                                            <mat-form-field appearance='fill' (click)="effPicker.open()">
                                                <input matInput [matDatepicker]="effPicker" [(ngModel)]="quali.EffectiveDateValue" [min]='minDate' [max]='maxDate' readonly name="effPicker_{{i}}">
                                                <mat-datepicker-toggle matSuffix [for]="effPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #effPicker (opened)="openAppend('effPicker', i)"></mat-datepicker>
                                            </mat-form-field>
                                            <div style="display: none">
                                                <div class="datepicker-footer" #effFooter>
                                                    <button mat-raised-button  (click)="today('effPicker', quali.EffectiveDateValue, selectedIndex)" #todayButton name="startDateButton_{{selectedIndex}}">
                                                        {{'TODAY' | translate}}
                                                    </button>
                                                    <button mat-raised-button (click)="closePicker('effPicker',selectedIndex)" #doneButton>
                                                        {{'close' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form_content_input_field" [style.pointerEvents]="quali.Action == 'K'? 'none' : ''">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.expirydate' | translate }}</div>

                                        <div class="fl input_new_calendar" [ngClass]="quali.Action == 'K' ? 'input_new_calendar_readonly' : '' ">
                                            <mat-form-field appearance='fill' (click)="expPicker.open()">
                                                <input matInput [matDatepicker]="expPicker" [(ngModel)]="quali.ExpiryDateValue" [min]='minDate' [max]='maxDate' readonly name="expPicker_{{i}}">
                                                <mat-datepicker-toggle matSuffix [for]="expPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #expPicker (opened)="openAppend('expPicker', i)"></mat-datepicker>
                                            </mat-form-field>
                                            <div style="display: none">
                                                <div class="datepicker-footer" #expFooter>
                                                    <button mat-raised-button  (click)="today('expPicker', quali.ExpiryDateValue, selectedIndex)" #todayButton name="startDateButton_{{selectedIndex}}">
                                                        {{'TODAY' | translate}}
                                                    </button>
                                                    <button mat-raised-button (click)="closePicker('expPicker', selectedIndex)" #doneButton>
                                                        {{'close' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form_content_input_field" [style.pointerEvents]="quali.Action == 'K'? 'none' : ''">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.obtaineddate' | translate }}</div>

                                        <div class="fl input_new_calendar" [ngClass]="quali.Action == 'K' ? 'input_new_calendar_readonly' : '' ">
                                            <mat-form-field appearance='fill' (click)="obPicker.open()">
                                                <input matInput [matDatepicker]="obPicker" [(ngModel)]="quali.ObtainedDateValue" [min]='minDate' [max]='maxDate' readonly name="obPicker_{{i}}">
                                                <mat-datepicker-toggle matSuffix [for]="obPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #obPicker (opened)="openAppend('obPicker', i)"></mat-datepicker>
                                            </mat-form-field>
                                            <div style="display: none">
                                                <div class="datepicker-footer" #obFooter>
                                                    <button mat-raised-button  (click)="today('obPicker', quali.ObtainedDateValue, selectedIndex)" #todayButton name="startDateButton_{{selectedIndex}}">
                                                        {{'TODAY' | translate}}
                                                    </button>
                                                    <button mat-raised-button (click)="closePicker('obPicker', selectedIndex)" #doneButton>
                                                        {{'close' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form_content_input_field" [style.pointerEvents]="quali.Action == 'K'? 'none' : ''" >
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.startperiod' | translate }}</div>

                                        <div class="fl input_new_calendar" [ngClass]="quali.Action == 'K' ? 'input_new_calendar_readonly' : ''">
                                            <mat-form-field appearance='fill' (click)="startPicker.open()">
                                                <input matInput [matDatepicker]="startPicker" [(ngModel)]="quali.StartPeriodValue" [min]='minDate' [max]='maxDate' readonly name="startPicker_{{i}}">
                                                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startPicker (opened)="openAppend('startPicker', i)"></mat-datepicker>
                                            </mat-form-field>
                                            <div style="display: none">
                                                <div class="datepicker-footer" #startFooter>
                                                    <button mat-raised-button  (click)="today('startPicker', quali.StartPeriodValue, selectedIndex)" #todayButton name="startDateButton_{{selectedIndex}}">
                                                        {{'TODAY' | translate}}
                                                    </button>
                                                    <button mat-raised-button (click)="closePicker('startPicker', selectedIndex)" #doneButton>
                                                        {{'close' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form_content_input_field" [style.pointerEvents]="quali.Action == 'K'? 'none' : ''">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.endperiod' | translate }}</div>

                                        <div class="fl input_new_calendar" [ngClass]="quali.Action == 'K' ? 'input_new_calendar_readonly' : '' ">
                                            <mat-form-field appearance='fill' (click)="endPicker.open()">
                                                <input matInput [matDatepicker]="endPicker" [(ngModel)]="quali.EndPeriodValue" [min]='minDate' [max]='maxDate' readonly name="endPicker_{{i}}">
                                                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #endPicker (opened)="openAppend('endPicker', i)"></mat-datepicker>
                                            </mat-form-field>
                                            <div style="display: none">
                                                <div class="datepicker-footer" #endFooter>
                                                    <button mat-raised-button  (click)="today('endPicker', quali.EndPeriodValue, selectedIndex)" #todayButton name="startDateButton_{{selectedIndex}}">
                                                        {{'TODAY' | translate}}
                                                    </button>
                                                    <button mat-raised-button (click)="closePicker('endPicker', selectedIndex)" #doneButton>
                                                        {{'close' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_content_input_field">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.qualitype' | translate }}</div>
                                        <div class="input_field_large">
                                            <select required class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="quali.QualiTypeCode" [ngClass]="{'disabled': quali.Action == 'K'}" name='qualiCode_{{i}}'>
                                                <option disabled value="">{{ 'please-select' | translate }}</option>
                                                <option *ngFor="let qualitype of qualiTypeList" [value]="qualitype.Code">{{ qualitype.Desc }}</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="form_content_input_field">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.serialnumber' | translate }}</div>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [(ngModel)]="quali.SerialNumber" [disabled]="quali.Action == 'K'" name='qualiSerial_{{i}}'>
                                    </div>

                                    <div class="form_content_input_field">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.issuingauthority' | translate }}</div>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [(ngModel)]="quali.IssuingAuthority" [disabled]="quali.Action == 'K'" name='qualiAuthor_{{i}}'>
                                    </div>

                                    <div class="form_content_input_field">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.qualilevel' | translate }}</div>
                                        <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [(ngModel)]="quali.Level" [disabled]="quali.Action == 'K'" name='qualiLevel_{{i}}'>
                                    </div>

                                    <div class="form_content_input_field_full">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.remark' | translate }}</div>
                                        <textarea class="form-control detail_staff_remark_input txt_size_2" [disabled]="quali.Action == 'K'" [(ngModel)]="quali.Remarks" name='qualiRemarks_{{i}}'></textarea>
                                    </div>

                                    <div class="form_content_input_field_full">
                                        <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.attachment' | translate }}</div>
                                        <div class="fl input_field_small">
                                            <label class="btn btn-block btn-lg maincolor_btn_1" [class.disabled]="attachmentExistedFromRecord(quali) || quali.Action == 'K'">
                                                <img src="assets/img/btn_add_files.png" width="18"> {{ 'leaveapplication.addfiles' | translate }}
                                                <input type="file" multiple style="display: none;" (change)="uploadListening($event)" [attr.uuid]="quali.uuid" [disabled]="attachmentExistedFromRecord(quali) || quali.Action == 'K'" name='uuid__{{i}}'/>
                                            </label>
                                        </div>
                                        <div *ngFor="let attach of obj.attachments">
                                            <div id="attach_{{attach.fileID}}"  *ngIf='attach.uuid == quali.uuid || attach.uuid.value == quali.uuid'>
                                                <div style="clear: both"></div>
                                                <br />

                                                <div class="attachment_file_area bg_grey_4">

                                                    <div class="attachment_file_txt">
                                                        <div class="attachment_photo">
                                                            <img [src]="attach.dataURL">
                                                        </div>
                                                        <div>{{attach.file.name}}</div>
                                                        <div class="txt_grey_2 txt_size_3" *ngIf="attach.file.size">{{convertSizeFormat(attach.file.size)}}</div>
                                                        <div style="clear: both"></div>
                                                        <div *ngIf="fileProgress != 100">
                                                            <div class="bar_chart_data maincolor_bg2" [ngStyle]="{'width': uploadProgress + '%'}"></div><!-- sample uploading % -->
                                                        </div>
                                                    </div>

                                                    <div class="fr">
                                                        <!-- <div class="btn_attachment_position" (click)="uploadAttachment(attach)" id="btn_upload_attach_{{attach.fileID}}">
                                                            <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img src="assets/img/btn_start_upload.png" width="18">{{ 'leaveapplication.upload' | translate }}</a>
                                                        </div> -->
                                                        <!-- <div class="btn_attachment_position" (click)="cancelAttachment(attach)" id="btn_cancel_attach_{{attach.fileID}}">
                                                            <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_cancel_upload.png" width="18">{{ 'leaveapplication.cancel' | translate }}</a>
                                                        </div> -->
                                                        <div class="btn_attachment_position" (click)="deleteAttachment(attach)" id="btn_delete_attach_{{attach.fileID}}">
                                                            <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplication.delete' | translate }}</a>
                                                        </div>
                                                    </div>

                                                    <div style="clear: both"></div>

                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>

                                    <div style="clear:both"></div>
                                </div> <!-- ====== END : btn_3select1 next div ====== -->

                            </div> <!-- ========== end : form_content_input =========== -->
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" (click)="deleteQualification(quali)" *ngIf="quali.Action == 'I'">x</div>
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                        </div> 
                        </div><!-- ========== end : form_content =========== -->
                        <!-- ========== a record =========== -->
                    </div> <!-- ========== end : form_area =========== -->


                </div> <!-- ========== end : form_content_inquiry =========== -->
            </div> <!-- ========== end : detail_box_bottom_area_profile =========== -->


        </div>
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <div class="header_bar_icon fl list_icon_border" title="Add New" (click)="addNewQualiForEdit();"><img alt="" src="assets/img/btn_addform_g.png" width="20"></div>
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" [disabled]="isRecordsNotReady()" type="submit"><img src="assets/img/btn_tick_w.png" width="18">{{ 'submit' | translate }}</button>
                <a class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'cancel' | translate }}</a>
                <div style="clear:both"></div>
            </div>
        </div>
    </form>
</div>
