<div id="popup_intro_of_hrms" >
    <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition" style="overflow:hidden;">
        <div class="pop_functions_close btn_grey_2b pp_close" (click)="close()">x</div>
        <div style="clear:both"></div>
        <br/>
        <div class="txt_size_1 txt_grey_2">{{ 'toolbar.introtohrms' | translate }}</div>


        <div (swipeLeft)="goToNextIntro()" (swipeRight)="goToPrevIntro()" class="intro_area_img">
            <div class="imgs" [ngStyle]="{ 'transform': mytransform }">
                <img *ngFor="let image of introImageList" [src]="'assets/img/' + language + '/' + image" alt="">
            </div>
        </div>

        <div class="intro_area">
            <button *ngIf="currentIntroImage > 0" (click)="goToPrevIntro()" ><img src="assets/img/btn_arrow_back.png" width="20" alt=""></button>
            <button *ngIf="currentIntroImage < introImageList.length-1" (click)="goToNextIntro()" ><img src="assets/img/btn_arrow_right.png" width="20" alt=""></button>
        </div>

        <div class="img_counter txt_grey_2">{{ currentIntroImage+1 }}/{{ introImageList.length }}</div>

        <!--
        <div class="intro_area_img">
            <div id="imgs" style="transition-duration: 0.5s; transform: translate(0px, 0px);">
                <img src="assets/img/intro_1.png" alt="">
                <img src="assets/img/intro_2.png" alt="">
                <img src="assets/img/intro_3.png" alt="">
                <img src="assets/img/intro_4.png" alt="">
                <img src="assets/img/intro_5.png" alt="">
            </div>
        </div>

        <div class="intro_area">
            <button id="t_left"><img src="assets/img/btn_arrow_back.png" width="20" alt=""></button>
            <button id="t_right" href="#"><img src="assets/img/btn_arrow_right.png" width="20" alt=""></button>
        </div>

        <div class="img_counter txt_grey_2">1/6</div>
    -->

    </div>
</div>