<div class="pp8_content" id="previewApp">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_1column">
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_application"></div>
            <div class="fl">{{ 'appointmentapplication.confirmofapplication' | translate }}</div>
        </div>
        <div class="confirm_application_position txt_size_1">
            <div class="box_1_staff">
                <div class="txt_photo_area_big fl" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(staffInfo) + ')' }">
                    <div class="txt_photo_mask_big p_normal"></div>
                </div>
                <div class="box_1_staff_name">
                    <span class="txt_size_1">{{staffInfo.FullName}}</span><br>
                    <span class="txt_size_3 txt_grey_2">{{staffInfo.PositionDesc}}</span>
                </div>
                <div style="clear:both"></div>
            </div>
            <div *ngIf='appointments'>
                <div *ngFor="let appointment of appointments; let i = index;">
                    <div *ngIf='!appointment.deleted' class="preview_area">
                        <div class="bg_grey_2b">
                            <div><span class="txt_size_3">No.&nbsp;</span>{{appointment.index}}</div>
                        </div>
    
                        <div style=" margin-right:-10px;">
                            <div class="txt_grey_2 txt_size_3 txt_detail_sub_adjust fl">{{ 'appointmentapplication.start' | translate }}&nbsp;</div>
                            <div class="fl txt_data_confirm txt_size_3 txt_grey_2">
                                {{ commonUI.formatDisplayDate(appointment.AppointStartDate, 'DD MMM YYYY') }} <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(appointment.AppointStartDate, '(ddd)') }}</span>
                            </div>
                            <div class="txt_label bc_grey txt_size_3 fl">
                                {{ appointment.AppointStartHour }} : {{ appointment.AppointStartMinute }}&nbsp;{{ appointment.AppointStartDayPart }}
                            </div>
                            <div style="clear:both"></div>
                        </div>
    
                        <div style=" margin-right:-10px;">
                            <div class="txt_grey_2 txt_size_3 txt_detail_sub_adjust fl">{{ 'appointmentapplication.end' | translate }}&nbsp;</div>
                            <div class="fl txt_data_confirm txt_size_3 txt_grey_2">
                                {{ commonUI.formatDisplayDate(appointment.AppointEndDate, 'DD MMM YYYY') }} <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(appointment.AppointEndDate, '(ddd)') }}</span>
                            </div>
                            <div class="txt_label bc_grey txt_size_3 fl">{{ appointment.AppointEndHour }} : {{ appointment.AppointEndMinute }}&nbsp;{{ appointment.AppointEndDayPart }}</div>
                            <div style="clear:both"></div>
                        </div>
    
                        <hr class="hr_preview">

                        <div class="txt_grey_2 txt_size_3" *ngIf='appointment.Event'>{{ 'appointmentapplication.event' | translate }}</div>
                        <div class="txt_size_3 txt_grey_2" *ngIf='appointment.Event'>{{ appointment.Event }}</div>
                        <hr class="hr_preview" *ngIf='appointment.Event'>

                        <div class="txt_grey_2 txt_size_3">{{ 'appointmentapplication.details' | translate }}</div>
                        <div class="txt_size_3 txt_grey_2">{{ appointment.Desc }}</div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_multform_finish" (click)="submitAppointmentApplication()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'yes' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'no' | translate }}</button>
            <div style="clear:both"></div>
        </div>
    </div>
</div>