<div id="content_approval_pending_approval" class="title_reset_2">
    <span class="txt_size_1 txt_grey_2">{{ 'menu.approvalcentre' | translate }}</span>
    <!-- =========== Result ========== -->
    <div class="list_result_area content_box content_box_show" id="listResult">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_delegationsetting"></div>
            <div class="fl">{{ 'menu.delegationsetting' | translate }}</div>
        </div>
        <div class="box_title_sub txt_grey_2">{{ 'delegationsetting.leaveapplication' | translate }}</div>
        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">


            <div (click)="addDelegationSetting()" class="header_bar_icon fl list_icon_border mt0 ml0"  title="Add"><img alt="" src="assets/img/btn_addform_g.png" width="20" /></div>

            <div id="p4" (click)="showSortsMenuToggle()" class="header_bar_icon fl list_icon_border mt0" title="Sorts"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list" *ngIf="showSortsMenu" style="display: block;">
                <div class="alert_box_arrow arrow_position_4"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'approval.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <a (click)="sortByType('StaffInfo.FullName')"><div class="alert_box_content_item">{{ 'approval.sortbyname' | translate }}</div></a>
                        <a (click)="sortByType('StaffInfo.PositionCode')"><div class="alert_box_content_item">{{ 'approval.sortbyposition' | translate }}</div></a>
                        <a (click)="sortByType('EffStartDate')"><div class="alert_box_content_item">{{ 'approval.sortbydate' | translate }}</div></a>
                    </div>
                </div>
            </div>


            <input id="myInp" type="text" name="search" placeholder="{{ 'search' | translate }}" size="15" maxlength="15" [(ngModel)]="search.keyword" (ngModelChange)='searchByInput()'><button class="btn_search btn_opacity" title="Search" type="submit"></button>


            <div style="clear:both;"></div>

        </div><!-- end list_bar -->
        <!-- ============ search result txt  ============ -->
        <div id="search_bar_result">
            <a rel="external" href="#list_result">
                <div id="search_close"><img alt="" src="assets/img/btn_cross_grey.png" width="20" /></div>
            </a>
            Search result of "&nbsp;<span id="search_bar_result_show" class="txt_size_2 maincolor_txt"></span>&nbsp;"<br /><span class="txt_grey_2">(2 staffs)</span>
        </div>

        <div style="clear:both;"></div>

        <a name="list_result_filter" class="list_bar_mt"></a>

            <ul id="filter_options">
                <li  *ngFor="let sourceType of sourceTypeList" [ngClass]="queryType == sourceType.sourceTypeCode? 'active' : ''" id="filter_item_{{sourceType.sourceTypeCode}}" (click)="showSearchResult(sourceType.sourceTypeCode)">
                    <a class="{{sourceType.cssClass}}">{{ sourceType.displayName | translate }}</a>
                    <div *ngIf="sourceType.sourceTypeCode == 'ELEAVE'" class="filter_icon_num">{{leaveListCount}}</div>
                    <div *ngIf="sourceType.sourceTypeCode == 'LEAVECAN'" class="filter_icon_num">{{leaveCancelListCount}}</div>
                    <div *ngIf="sourceType.sourceTypeCode == 'APPOINTMENT'" class="filter_icon_num">{{appointmentListCount}}</div>
                    <div *ngIf="sourceType.sourceTypeCode == 'CLAIMHDR'" class="filter_icon_num">{{claimListCount}}</div>
                </li>
            </ul>

            <div style="clear:both;"></div>

        <hr class="m0">


        <!-- ============ list_data ============ -->
        <div id="filter_holder" class="list_data page_delegation_setting" data-role="page" *ngIf='settings'>

            <!-- ============  list1box  ============ -->
            <div *ngFor="let obj of settings; let i=index;">
                <div class="list_1_box list_flag" [id]="'setting_record_' +i" (click)="showPopupSettingDetail(obj.index)">
                    <div>
                        <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(obj.StaffInfo) + ')'}">
                            <div class="txt_photo_mask_small p_normal"></div>
                        </div>

                        <div class="txt_content_area_2line txt_size_2">
                            <div class="txt_name">{{ obj.StaffInfo.FullName }}</div>
                            <div style="clear:both;"></div>
                            <div class="txt_jobposition txt_grey_2 txt_size_3">{{ obj.StaffInfo.PositionDesc }}</div>
                            <div class="txt_date txt_grey_1">
                                <span class="txt_grey_2 txt_size_3">{{ 'delegationsetting.delegated' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(obj.EffStartDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.EffStartDate, 'ddd')}})</span> - {{commonUI.formatDisplayDate(obj.EffEndDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.EffEndDate, 'ddd')}})</span>
                            </div>
                            <div class="txt_date txt_grey_1">
                                <span class="txt_grey_2 txt_size_3">{{ 'delegationsetting.accessibleleave' | translate }}</span><br>
                                {{commonUI.formatDisplayDate(obj.LeaveStartDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.LeaveStartDate, 'ddd')}})</span> - {{commonUI.formatDisplayDate(obj.LeaveEndDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.LeaveEndDate, 'ddd')}})</span>
                            </div>
                        </div>

                        <div style="clear:both;"></div>
                    </div>
                </div>
            </div>
            <div class="f_all f_pending f_cancelpending f_approved f_rejected" style="clear:both;"></div>
        </div><!-- ============ end list_data ============ -->



        <div id="panel_multi_select" class="mult_select_approval_area" *ngIf="multiMode" style="display: block;">
            <div class="pop_functions_two_btn_center">
                <div id="btn_mult_select_all" class="header_bar_icon fl list_icon_border" title="{{ 'selectAll' | translate }}" (click)="toggleSelectAll()"><img alt="" src="assets/img/btn_select_all.png" width="20"/></div>
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="doMultiApprove()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" (click)="doMultiReject()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>

    </div> <!-- end id=content_box -->
</div>
