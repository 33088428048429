import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { InterceptorService } from './interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService) { }

  getAddressTypeList() {
    var result
    if (sessionStorage.AddressTypeList != undefined)
      result = JSON.parse(sessionStorage.AddressTypeList)
    else {
      var body = {
        "Action": environment.apiList.GetAddressTypeList,
        "SessionID": sessionStorage.SessionID
      }
      result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
        res['conn_fail'] = false
        return res.body['Data'].AddressTypeList
      }, err => {
        err['conn_fail'] = true
        return err.body
      }))
    }
    return result
  }

  getAreaList() {
    var result
    if (sessionStorage.AreaList != undefined)
      result = JSON.parse(sessionStorage.AreaList)
    else {
      var body = {
        "Action": environment.apiList.GetAreaList,
        "SessionID": sessionStorage.SessionID
      }
      result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
        res['conn_fail'] = false
        return res.body['Data'].AreaList
      }, err => {
        err['conn_fail'] = true
        return err.body
      }))
    }
    return result
  }

  getDistrictList() {
    var result
    if (sessionStorage.AreaList != undefined)
      result = JSON.parse(sessionStorage.DistrictList)
    else {
      var body = {
        "Action": environment.apiList.GetDistrictList,
        "SessionID": sessionStorage.SessionID
      }
      result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
        res['conn_fail'] = false
        return res.body['Data'].DistrictList
      }, err => {
        err['conn_fail'] = true
        return err.body
      }))
    }
    return result
  }

  getQualiTypeList() {
    var result
    if (sessionStorage.AreaList != undefined)
      result = JSON.parse(sessionStorage.QualiTypeList)
    else {
      var body = {
        "Action": environment.apiList.GetQualiTypeList,
        "SessionID": sessionStorage.SessionID
      }
      result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
        res['conn_fail'] = false
        return res.body['Data'].QualiTypeList
      }, err => {
        err['conn_fail'] = true
        return err.body
      }))
    }
    return result
  }

  getRelationshipList() {
    var result
    if (sessionStorage.AreaList != undefined)
      result = JSON.parse(sessionStorage.RelationshipList)
    else {
      var body = {
        "Action": environment.apiList.GetRelationshipList,
        "SessionID": sessionStorage.SessionID
      }
      result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
        res['conn_fail'] = false
        return res.body['Data'].RelationshipList
      }, err => {
        err['conn_fail'] = true
        return err.body
      }))
    }
    return result
  }

  getIdentityTypeList() {
    var result
    if (sessionStorage.AreaList != undefined)
      result = JSON.parse(sessionStorage.IdentityTypeList)
    else {
      var body = {
        "Action": environment.apiList.GetIdentityTypeList,
        "SessionID": sessionStorage.SessionID
      }
      result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
        res['conn_fail'] = false
        return res.body['Data'].IdentityTypeList
      }, err => {
        err['conn_fail'] = true
        return err.body
      }))
    }
    return result
  }

  getPersnlProfile(employee, person) {

    var body = {
      "Action": environment.apiList.GetPersnlProfile,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "PersonalRID": person.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.PersnlGeneral, data.Data.IsContactEditable, data.Data.IsMaritalStatusEditable);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPersnlProfile,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "PersonalRID": person.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getPersnlEmploy(employee) {

    var body = {
      "Action": environment.apiList.GetPersnlEmploy,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result
    // var service = this;
    /*
    if (sessionStorage.PersnlEmploy != undefined) {
        onSuccessCallback(service, JSON.parse(sessionStorage.PersnlEmploy));
        return;
    }
    */

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         //sessionStorage.PersnlEmploy = JSON.stringify(data.Data.PersnlEmploy);
    //         onSuccessCallback(service, data.Data.PersnlEmploy);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPersnlEmploy,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getPersnlAddressList(employee, person) {

    var body = {
      "Action": environment.apiList.GetPersnlAddressList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "PersonalRID": person.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.PersnlAddressList, data.Data.IsEditable);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPersnlAddressList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "PersonalRID": person.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getBankList() {

    var body = {
      "Action": environment.apiList.GetBankList,
      "SessionID": sessionStorage.SessionID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;
    // if (sessionStorage.BankList != undefined) {
    //     onSuccessCallback(service, JSON.parse(sessionStorage.BankList));
    //     return;
    // }

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         sessionStorage.BankList = JSON.stringify(data.Data.BankList);
    //         onSuccessCallback(service, data.Data.BankList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetBankList,
    //         "SessionID": sessionStorage.SessionID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getPersnlBankAccList(employee) {

    var body = {
      "Action": environment.apiList.GetPersnlBankAccList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.PersnlBankAcc, data.Data.IsEditable);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPersnlBankAccList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getPersnlDependtList(employee, person) {

    var body = {
      "Action": environment.apiList.GetPersnlDependtList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "PersonalRID": person.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.PersnlDependtList, data.Data.IsEditable);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPersnlDependtList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "PersonalRID": person.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getPersnlQualiList(employee, person) {

    var body = {
      "Action": environment.apiList.GetPersnlQualiList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "PersonalRID": person.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.PersnlQualiList, data.Data.IsEditable);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPersnlQualiList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "PersonalRID": person.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getProfileAmendDict(employee, status) {

    var body = {
      "Action": environment.apiList.GetProfileAmendDict,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Status": status
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.ProfileAmendList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    // "Action": apiList.GetProfileAmendDict,
    // "SessionID": sessionStorage.SessionID,
    // "EmploymentRID": employee.RID,
    // "Status": status
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  submitAddressForm(addressForm) {

    var body = {
          "Action": environment.apiList.SubmitAddress,
          "SessionID": sessionStorage.SessionID,
          "AddressFormList": JSON.stringify(addressForm)
        }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitAddress,
    //         "SessionID": sessionStorage.SessionID,
    //         "AddressFormList": JSON.stringify(addressForm)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
}

  submitContactStatus(contactForm) {
    var body = {
      "Action": environment.apiList.SubmitContact,
      "SessionID": sessionStorage.SessionID,
      "ContactForm": JSON.stringify(contactForm)
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitContact,
    //         "SessionID": sessionStorage.SessionID,
    //         "ContactForm": JSON.stringify(contactForm)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  submitMaritalStatus(maritalForm) {

    var body = {
      "Action": environment.apiList.SubmitMaritalStatus,
      "SessionID": sessionStorage.SessionID,
      "MaritalStatusForm": JSON.stringify(maritalForm)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result
    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitMaritalStatus,
    //         "SessionID": sessionStorage.SessionID,
    //         "MaritalStatusForm": JSON.stringify(maritalForm)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    //   );
    // }
  }

  submitBankAccount(bankACForm) {

    var body = {
      "Action": environment.apiList.SubmitBankAccount,
      "SessionID": sessionStorage.SessionID,
      "BankACForm": JSON.stringify(bankACForm)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitBankAccount,
    //         "SessionID": sessionStorage.SessionID,
    //         "BankACForm": JSON.stringify(bankACForm)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  submitDependt(dependts) {

    var body = {
      "Action": environment.apiList.SubmitDepndt,
      "SessionID": sessionStorage.SessionID,
      "DependtFormList": JSON.stringify(dependts)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitDepndt,
    //         "SessionID": sessionStorage.SessionID,
    //         "DependtFormList": JSON.stringify(dependts)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  submitQualiForm(qualiForm) {
    var body = {
      "Action": environment.apiList.SubmitQuali,
      "SessionID": sessionStorage.SessionID,
      "QualiFormList": JSON.stringify(qualiForm)
    }
    
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err.body
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitQuali,
    //         "SessionID": sessionStorage.SessionID,
    //         "QualiFormList": JSON.stringify(qualiForm)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }
}
