import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment.mod';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { InterceptorService } from '../services/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, private router: Router, private http: HttpClient, private translate: TranslateService, private interceptor: InterceptorService) { }

  getApprovalGroupListMod(employee,approvalGroupType) {

    var result
    if (sessionStorage.StaffInfo != undefined) {
      result = JSON.parse(sessionStorage.ApprovalGroupList);
    } else {
      var body = {
        "Action": environment.apiList.GetApprovalGroupListMod,
        "SessionID": sessionStorage.SessionID,
        "EmploymentRID": employee.RID,
        "ApprovalGroupType": approvalGroupType
      }
      result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
        res['conn_fail'] = false
        return res.body['Data'].ApprovalGroupList
      }, err => {
        err['conn_fail'] = true
        return err.body
      }))
    }
    return result
  }

  getClaimReport(employee, departmentCodeList, startDate, endDate, confirmStatus, claimType, claimStatus, approvalGroupList, isTeammateViewSelected, criteria) {
    var body = {
        "Action": environment.apiList.GetClaimReport,
        "SessionID": sessionStorage.SessionID,
        "EmploymentRID": employee.RID,
        "DepartmentCode": (departmentCodeList.length != 0) ? JSON.stringify(departmentCodeList) : "",
        "StartDate": startDate,
        "EndDate": endDate,
        "ConfirmStatus": confirmStatus,
        "ClaimTypeCode": claimType,
        "ClaimStatus": claimStatus,
        "ApprovalGroupList": (approvalGroupList.length != 0) ? JSON.stringify(approvalGroupList) : "",
        "IsTeammateViewSelected": isTeammateViewSelected,
        "Criteria": JSON.stringify(criteria)
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
  }

}
