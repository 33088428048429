import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupTimeoutDialogComponent } from '../common/popup-timeout-dialog/popup-timeout-dialog.component';
import { count, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleTimerService {

  IDLE_TIMER = 720;
  IDLE_TIMEOUT = 100;
  isOpen: boolean = false;
  isStarting: boolean = false;

  constructor(public dialog: MatDialog, public commonService: CommonServiceService, public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public idle: Idle) {
  }

  /* Start Monitoring */
  idleStart() {
    if(!this.isStarting) {
      this.idle.setIdle(this.IDLE_TIMER) //60
      this.idle.setTimeout(this.IDLE_TIMEOUT) //30
      /* Interrupt */
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
      this.idle.watch();
      this.isStarting = true;
      this.idle.onTimeoutWarning.pipe(first()).subscribe(countdown => {
        if(!this.isOpen) {
          this.isOpen = true;
          this.dialog.open(PopupTimeoutDialogComponent, {
            data: countdown
          }).afterClosed().subscribe(() => {
            this.isOpen = false;
          })
        }
      })
    }

    this.idle.onIdleEnd.subscribe(() => {
      this.idleTimerRestart();
    });

  //   this.idle.onTimeout.subscribe(() => {
  //     this.commonService.logout().subscribe((res) => {
  //       this.dialog.closeAll();
  //       sessionStorage.clear();
  //       this.router.navigateByUrl('/');
  //       this.errorUI.showErrorBox("00035");
  //     }, err => {
  //       this.dialog.closeAll();
  //       sessionStorage.clear();
  //       this.router.navigateByUrl('/');
  //       this.errorUI.showErrorBox("00035");
  //     })
  //   })
  }

  idleCancel() {
    this.idleTimerRestart();
  }

  idleStop() {
    this.isStarting = false;
    this.idle.stop();
    this.idle.clearInterrupts();
  }

  idleTimerRestart() {
    this.idleStop();
    this.idleStart();
  }

  onCallLoginAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
    //this.router.navigateByUrl('/login')  }
  }
}
