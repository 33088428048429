<div id="{{displaySwitch}}" class="pp_address_content list_flag-selected" style="display: block;">
    <form id="form_application_address" name="form_application_address" (submit)="submitEvent()">
        <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval">
            <!--<div class="pop_functions_close maincolor_btn2 pp_close">x</div>-->

            <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
                <div class="box_title_icon t_edit"></div>
                <div class="fl">{{ 'personalprofile.editof' | translate }} {{ 'personalprofile.tabaddress' | translate }}</div>
            </div>

            <div class="d_box_top"></div>

            <div class="detail_box_bottom_area_profile mt0_m">
                <div class="box_content_inquiry">

                    <div class="form_area" *ngIf='obj'>

                        <!-- ========== a record =========== -->
                        <div id="form-{{i}}" class="form_content form_content_popup" *ngFor="let address of obj; let i=index">
                            <ng-container *ngIf='!address.deleted'>
                            <div class="form_content_title" (click)="slideUp($event);address.onFocus = true" [ngClass]="{ 'form_content_title-selected': address.onFocus }">
                                {{ address.AddressTypeDesc }}
                                <span class="txt_label txt_label_profile_action color_label_new_edit txt_size_3" *ngIf="address.Action == 'I'">{{ 'personalprofile.typeAction.I' | translate }}</span>
                                <span class="txt_label txt_label_profile_action color_label_keep txt_size_3" *ngIf="address.Action == 'K'">{{ 'personalprofile.typeAction.K' | translate }}</span>
                                <span class="txt_label txt_label_profile_action color_label_update txt_size_3" *ngIf="address.Action == 'U'">{{ 'personalprofile.typeAction.U' | translate }}</span>
                                <!--<span class="txt_label txt_label_profile_action color_label_remove txt_size_3" [ngClass]="{'hide' : address.Action != 'D'}">{{ 'personalprofile.typeAction.D' | translate }}</span>-->
                            </div>

                            <div class="form_content_input">

                                <a name="list_type" class="list_bar_mt"></a>

                                <div class="btn_sub_title txt_grey_2" *ngIf="address.Action != 'I'">{{ 'personalprofile.action' | translate }}</div>
                                <div class="btn_3select1" *ngIf="address.Action != 'I'">
                                    <div id="f_keep" class="btn btn-block btn-lg two_btn maincolor_btn2" [ngClass]="{'active': address.Action == 'K'}" title="{{ 'personalprofile.typeActionDesc.K' | translate }}" (click)="address.Action = 'K'">{{ 'personalprofile.typeAction.K' | translate }}</div>
                                    <div id="f_delete" class="btn btn-block btn-lg two_btn maincolor_btn2" style="display:none" [ngClass]="{'active': address.Action == 'D'}" title="{{ 'personalprofile.typeActionDesc.D' | translate }}" (click)="address.Action = 'D'">{{ 'personalprofile.typeAction.D' | translate }}</div>
                                    <div id="f_update" class="btn btn-block btn-lg two_btn maincolor_btn2" [ngClass]="{'active': address.Action == 'U'}" title="{{ 'personalprofile.typeActionDesc.U' | translate }}" (click)="address.Action = 'U'">{{ 'personalprofile.typeAction.U' | translate }}</div>
                                    <div style="clear:both;"></div>
                                </div>

                                <!--<div class="btn_3select1_form" [ngClass]="{'hide' : address.Action == 'D' || address.Action == 'K'}">-->
                                <div class="btn_3select1_form">
                                    <!-- ====== start : btn_3select1 next div ====== -->

                                    <div class="form_content_input_field">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.addresstype' | translate }}</div>
                                        <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="address.AddressTypeCode" (change)="changeAddressType(address, addressTypeList)" [disabled]="address.Action == 'K'" name='addtype_{{i}}'>
                                            <option *ngFor="let addtype of addressTypeList" [ngValue]="addtype.Code">{{ addtype.Desc }}</option>
                                        </select>
                                    </div>

                                    <div class="form_content_input_field">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.addressdistrict' | translate }}</div>
                                        <div class="input_field_large">
                                            <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="address.DistrictCode" [disabled]="address.Action == 'K'" name='district_{{i}}'>
                                                <option *ngFor="let district of districtList" [value]="district.Code ">{{ district.Desc }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form_content_input_field">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.area' | translate }}</div>
                                        <div class="input_field_large">
                                            <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="address.Area" [disabled]="address.Action == 'K'" name='area_{{i}}'>
                                                <option *ngFor="let area of areaList" [value]="area.Code">{{ area.Desc }}</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="form_content_input_field_full">
                                        <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.address' | translate }}</div>
                                        <textarea id="name" name="remark_{{i}}" class="form-control detail_staff_remark_input txt_size_2" [(ngModel)]="address.ContactAddress" [disabled]="address.Action == 'K'"></textarea>
                                    </div>

                                    <div class="form_content_input_field_full">
                                        <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.attachment' | translate }}</div>
                                        <div class="fl input_field_small">
                                            <label class="btn btn-block btn-lg maincolor_btn_1" [class.disabled]="attachmentExistedFromRecord(address) || address.Action == 'K'">
                                                <img src="assets/img/btn_add_files.png" width="18"> {{ 'leaveapplication.addfiles' | translate }}
                                                <input type="file" multiple style="display: none;" (change)="uploadListening($event)" [attr.uuid]="address.uuid" name='uuid_{{i}}' [disabled]="attachmentExistedFromRecord(address) || address.Action == 'K'"/>
                                            </label>
                                        </div>
                                        <div *ngFor="let attach of obj.attachments">
                                            <div id="attach_{{attach.fileID}}" *ngIf='attach.uuid.value == address.uuid || attach.uuid == address.uuid'>
                                                <div style="clear: both"></div>
                                                <br />

                                                <div class="attachment_file_area bg_grey_4">

                                                    <div class="attachment_file_txt">
                                                        <div class="attachment_photo">
                                                            <img [src]="attach.dataURL">
                                                        </div>
                                                        <div>{{attach.file.name}}</div>
                                                        <div class="txt_grey_2 txt_size_3" *ngIf="attach.file.size">{{convertSizeFormat(attach.file.size)}}</div>
                                                        <div style="clear: both"></div>
                                                        <div *ngIf="fileProgress != 100">
                                                            <div class="bar_chart_data maincolor_bg2" [ngStyle]="{'width': + fileProgress + '%'}"></div><!-- sample uploading % -->
                                                        </div>
                                                    </div>

                                                    <div class="fr">
                                                        <!-- <div class="btn_attachment_position" (click)="uploadAttachment(attach)" id="btn_upload_attach_{{attach.fileID}}">
                                                            <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img src="assets/img/btn_start_upload.png" width="18">{{ 'leaveapplication.upload' | translate }}</a>
                                                        </div>
                                                        <div class="btn_attachment_position" (click)="cancelAttachment(attach)" id="btn_cancel_attach_{{attach.fileID}}">
                                                            <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_cancel_upload.png" width="18">{{ 'leaveapplication.cancel' | translate }}</a>
                                                        </div> -->
                                                        <div class="btn_attachment_position" (click)="deleteAttachment(attach)" id="btn_delete_attach_{{attach.fileID}}">
                                                            <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplication.delete' | translate }}</a>
                                                        </div>
                                                    </div>

                                                    <div style="clear: both"></div>

                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>

                                    <div style="clear:both"></div>
                                </div> <!-- ====== END : btn_3select1 next div ====== -->

                            </div> <!-- ========== end : form_content_input =========== -->
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" (click)="deleteAddress(address)" *ngIf="address.Action == 'I'">x</div>
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->
                            </ng-container>
                        </div> <!-- ========== end : form_content =========== -->
                        <!-- ========== a record =========== -->
                    </div> <!-- ========== end : form_area =========== -->


                </div> <!-- ========== end : form_content_inquiry =========== -->
            </div> <!-- ========== end : detail_box_bottom_area_profile =========== -->


        </div>
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <div class="header_bar_icon fl list_icon_border" title="Add New" (click)="addPersnlAddressListForEdit()"><img alt="" src="assets/img/btn_addform_g.png" width="20"></div>
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" [disabled]="isRecordsNotReady()" type="submit"><img src="assets/img/btn_tick_w.png" width="18">{{ 'submit' | translate }}</button>
                <a class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'cancel' | translate }}</a>
                <div style="clear:both"></div>
            </div>
        </div>
    </form>
</div>
