import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { InOutService } from 'src/app/services/in-out.service';

@Component({
  selector: 'app-popup-punch-detail',
  templateUrl: './popup-punch-detail.component.html',
  styleUrls: ['./popup-punch-detail.component.scss']
})
export class PopupPunchDetailComponent implements OnInit {

  selectedEEmployPunchDetail;

  constructor(public inoutService: InOutService, public commonUI: CommonUIService, public commonService: CommonServiceService, public matDialogRef: MatDialogRef<PopupPunchDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) { 
    this.selectedEEmployPunchDetail = data['selectedEEmployPunchDetail'];
  }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

}
