import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { IdleTimerService } from 'src/app/services/idle-timer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-timeout-dialog',
  templateUrl: './popup-timeout-dialog.component.html',
  styleUrls: ['./popup-timeout-dialog.component.scss']
})
export class PopupTimeoutDialogComponent implements OnInit, OnDestroy {

  countdown: number;
  interval: any

  constructor(public matDialogRef: MatDialogRef<PopupTimeoutDialogComponent>, public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: number, public commonService: CommonServiceService,  public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public idleTimer: IdleTimerService) { 
    this.countdown = data;
    this.idleStart();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.countdown = 999;
  }

  idleStart() {
    this.interval = setInterval(() => {
      while(this.countdown > 0) {
        this.countdown --;
        if(this.countdown == 0) {
          this.idleLogout();
        }
        break;  
      }
    }, 1000);
  }

  idleLogout() {
    this.commonService.logout().subscribe((res) => {
      // this.matDialogRef.close();
      this.matDialog.closeAll();
      this.idleTimer.idleStop();
      sessionStorage.clear();
      this.errorUI.showErrorBox("00035");
      
      if (environment.isV11Container)
        window.location.href = `${document.baseURI}api/auth/logout`;
      else
        this.commonService.navigatedLogin();
    
    }, err => {
      // this.matDialogRef.close();
      this.matDialog.closeAll();
      this.idleTimer.idleStop();
      sessionStorage.clear();
      this.onCallLoginAPIFail(err.body, err.status, err.headers, err['Data'])
      // this.router.navigateByUrl('/');
    });
  }

  idleCancel() {
    try {
      this.matDialogRef.close();
      this.idleTimer.idleTimerRestart();
    } catch {}
  }

  onCallLoginAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
