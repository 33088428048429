<div class="flag_submit_remarks_content" id="{{ withdrawConfirmPopupid }}" *ngIf="type == 'leave' && leaveapplication">
    <div class="pop_functions_box pop_functions_box_size_1">

        <br />
        <img src="assets/img/msg_alert.png" width="48">
        <br /><br />
        {{ 'message.M0009' | translate }}
        <br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="withdrawLeaveApplication()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'yes' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'no' | translate }}</button>
            <div style="clear:both"></div>
        </div>

    </div>
</div>

<div class="flag_submit_remarks_content" id="{{ withdrawConfirmPopupid }}" *ngIf="type == 'appointment' && appointmentapp">
    <div class="pop_functions_box pop_functions_box_size_1">

        <br />
        <img src="assets/img/msg_alert.png" width="48">
        <br /><br />
        {{ 'message.M0010' |translate }}
        <br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="withdrawAppointmentApplication()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'yes' |translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'no' |translate }}</button>
            <div style="clear:both"></div>
        </div>

    </div>
</div>

<div class="flag_submit_remarks_content" id="{{ withdrawConfirmPopupid }}" *ngIf="type == 'inout' && inoutapplication">
    <div class="pop_functions_box pop_functions_box_size_1">

        <br />
        <img src="assets/img/msg_alert.png" width="48">
        <br /><br />
        {{ 'message.M0020' |translate }}
        <br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="withdrawInOutTimeApplication()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'yes' |translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'no' |translate }}</button>
            <div style="clear:both"></div>
        </div>

    </div>
</div>

<div class="flag_submit_remarks_content" id="{{ withdrawConfirmPopupid }}" *ngIf="type == 'claim' && claimapplication">
    <div class="pop_functions_box pop_functions_box_size_1">

        <br />
        <img src="assets/img/msg_alert.png" width="48">
        <br /><br />
        {{ 'message.M0031' |translate }}
        <br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="withdrawClaimApplication()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'yes' |translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'no' |translate }}</button>
            <div style="clear:both"></div>
        </div>

    </div>
</div>
