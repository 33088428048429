import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';

@Component({
  selector: 'app-popup-error-dialog',
  templateUrl: './popup-error-dialog.component.html',
  styleUrls: ['./popup-error-dialog.component.scss']
})
export class PopupErrorDialogComponent implements OnInit {

  errorCode: string;
  errorMsg: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {}, public matDialogRef: MatDialogRef<PopupErrorDialogComponent>, public translate: TranslateService) { 
    this.errorCode = data['code'];
    this.errorMsg = data['msg'];
    this.translate.get(this.errorMsg).subscribe((result) => {
      this.errorMsg = result
    })
    
  }

  ngOnInit(): void {
  }
  
  close() {
    try{ 
      this.matDialogRef.close()
    } catch{}
  }

}