import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popup-languages',
  templateUrl: './popup-languages.component.html',
  styleUrls: ['./popup-languages.component.scss']
})
export class PopupLanguagesComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<PopupLanguagesComponent>, @Inject(MAT_DIALOG_DATA) public languageList: {}, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  switchToLang = function (lang) {
		localStorage.CurrentLanguage = lang;
		this.translate.use(lang);
		this.close();
	}

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }
}
