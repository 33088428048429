<div id="{{ popupid }}_zoom">
    <div [ngClass]="popupmode == 'READONLY'? 'pop_functions_box pop_functions_box_size_2column':'pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval'">
        <div id="btn_punch_zoom" class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(inOutTimeApplication.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
                <span class="txt_popup_staffname">{{ inOutTimeApplication.StaffInfo.FullName }}</span><br>
                <span class="txt_popup_position">{{ inOutTimeApplication.StaffInfo.PositionDesc }}</span><br>
                <span class="txt_popup_staffid">ID : {{ inOutTimeApplication.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

            <div class="detail_box_bottom_1point thin">
                <div class="detail_box_catalog_area">
                    <div class="maincolor_bg color_label_{{ inOutTimeApplication.StatusDesc | lowercase }}">{{ inOutTimeApplication.StatusDesc }}</div>
                </div>
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(inOutTimeApplication.Date, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(inOutTimeApplication.Date, 'ddd')}})</span></div>
                <div class="detail_box_sub_icon d_box_sub_icon_appointment"></div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'inouttimeapplicationinquiry.shift' | translate }}</div>
                <div class="detail_box_date_num maincolor_txt"><span class="txt_size_0">{{ inOutTimeApplication.ShiftCode }}</span></div>
                <div class="detail_box_type">{{commonUI.formatDisplayDate(inOutTimeApplication.ShiftStart, 'h:mm A')}}&nbsp;-&nbsp;{{commonUI.formatDisplayDate(inOutTimeApplication.ShiftEnd, 'h:mm A')}}</div>
            </div>

            <div class="detail_box_other txt_size_1">

                <div class="txt_grey_2 txt_size_3">{{ 'inouttimeapplicationinquiry.adjustedpunch' | translate }}</div>
                <div class="col col2 adjusted_punch_detail_left" style="border-top: 1px solid #e6e6e6;margin: 0;">
                    <div style="display:none">
                        <div>Time</div>
                        <div>Detail</div>
                        <div style="clear:both; float:none"></div>
                    </div>

                    <div *ngFor="let adjustedList of inOutTimeApplication.newPunchList">
                        <div>
                            {{commonUI.formatDisplayDate(adjustedList.PunchDateTime, 'h:mm A')}}
                            <br>
                            <span class="txt_size_3 txt_grey_2">{{commonUI.formatDisplayDate(adjustedList.PunchDateTime, 'DD MMM YYYY (A)')}}</span>
                        </div>
                        <div>
                            <span class="txt_label bc_green txt_size_3" *ngIf="adjustedList.Status == 'O'">{{ 'inouttimeapplicationinquiry.outtime' | translate }}</span>
                            <span class="txt_label bc_blue txt_size_3" *ngIf="adjustedList.Status == 'I'">{{ 'inouttimeapplicationinquiry.intime' | translate }}</span>
                            <br>
                            <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplicationinquiry.site' | translate }}</span>
                            {{ adjustedList.PunchLocation }}
                        </div>
                        <div style="clear:both; float:none"></div>
                    </div>


                </div>
            </div>
        </div>

        <div class="detail_box_bottom_area mt0_m">

            <div class="remark_tabs" style="height: 434px; margin:0;">
                <div class="tab first" style="margin-left: 0;">
                    <input type="radio" id="tab-r01" name="tab-group-2" checked>
                    <label for="tab-r01">{{ 'inouttimeapplicationinquiry.current' | translate }}</label>

                    <div class="content txt_box_1 txt_grey_1 txt_size_2">
                        <div class="col col2">
                            <div style="display:none">
                                <div>Time</div>
                                <div>Detail</div>
                                <div style="clear:both; float:none"></div>
                            </div>

                            <div *ngFor="let currentList of inOutTimeApplication.orgPunchList">
                                <div>
                                    {{commonUI.formatDisplayDate(currentList.PunchDateTime, 'h:mm A')}}
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{commonUI.formatDisplayDate(currentList.PunchDateTime, 'DD MMM YYYY (A)')}}</span>
                                </div>
                                <div>
                                    <span class="txt_label bc_green txt_size_3" *ngIf="currentList.Status == 'O'">{{ 'inouttimeapplicationinquiry.outtime' | translate }}</span>
                                    <span class="txt_label bc_blue txt_size_3" *ngIf="currentList.Status == 'I'">{{ 'inouttimeapplicationinquiry.intime' | translate }}</span>
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplicationinquiry.site' | translate }}</span>
                                    {{ currentList.PunchLocation }}
                                </div>
                                <div style="clear:both; float:none"></div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="radio" id="tab-r02" name="tab-group-2">
                    <label for="tab-r02">{{ 'inouttimeapplicationinquiry.raw' | translate }}</label>

                    <div class="content txt_box_1 txt_grey_1 txt_size_2">
                        <div class="col col2">
                            <div style="display:none">
                                <div>Time</div>
                                <div>Detail</div>
                                <div style="clear:both; float:none"></div>
                            </div>

                            <div *ngFor="let rawList of inOutTimeApplication.rawPunchList">
                                <div>
                                    {{commonUI.formatDisplayDate(rawList.PunchDateTime, 'h:mm A')}} 
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{commonUI.formatDisplayDate(rawList.PunchDateTime, 'DD MMM YYYY (A)')}}</span>
                                </div>
                                <div>
                                    <span class="txt_label bc_green txt_size_3" *ngIf="rawList.Status == 'O'">{{ 'inouttimeapplicationinquiry.outtime' | translate }}</span>
                                    <span class="txt_label bc_blue txt_size_3" *ngIf="rawList.Status == 'I'">{{ 'inouttimeapplicationinquiry.intime' | translate }}</span>
                                    <br>
                                    <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplicationinquiry.site' | translate }}</span>
                                    {{ rawList.PunchLocation }}
                                </div>
                                <div style="clear:both; float:none"></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div style="clear:both;"></div>
            </div>

        </div>

    </div>
</div>