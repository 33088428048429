import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { LeaveService } from '../services/leave.service';
import moment from 'moment'
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { PopupDetailOfCheckBalanceInquiryComponent } from '../common/popup-detail-of-check-balance-inquiry/popup-detail-of-check-balance-inquiry.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CommonHandlerService } from '../services/common-handler.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { PopupLeavePreviewComponent } from '../common/popup-leave-preview/popup-leave-preview.component';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { IdleTimerService } from '../services/idle-timer.service';

declare var $: any;

@Component({
  selector: 'app-leave-application',
  templateUrl: './leave-application.component.html',
  styleUrls: ['./leave-application.component.scss']
})
export class LeaveApplicationComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  // @ViewChild('balanceFooter', {static: false}) balanceFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  @ViewChild('endPicker', { static: false }) endPicker: MatDatepicker<any>;
  // @ViewChild('balancePicker', {static: false}) balancePicker: MatDatepicker<any>;



  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */
  title = "LeaveApplication";
  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  staffInfo;
  leaveTypeList;
  startDateTakingUnit;
  endDateTakingUnit;
  takingunitList;
  leaveBalance;

  defaultLeaveTypeCode = "";
  leaveTypeDesc = "";
  notAllowBalanceInquiry = false;
  transalatedFullday;

  attachments;
  asAtDate;
  fileID = 0;
  fileProgress = 0;
  startDate;
  endDate;
  leaveDays;

  leaveApplication;

  constructor(public commonService: CommonServiceService, public leaveService: LeaveService, public errorUI: ErrorUIService, public commonUI: CommonUIService, public translate: TranslateService, public matDialog: MatDialog, public commonHandler: CommonHandlerService, public router: Router) {
    this.translate.get('leaveapplication.fullday').subscribe((fulldayStr) => {
      this.transalatedFullday = fulldayStr;
    });
    this.translate.onLangChange.subscribe(() => {
      this.startDate = moment(this.startDate);
      this.endDate = moment(this.endDate);
      this.translate.get('leaveapplication.fullday').subscribe((fulldayStr) => {
        if (this.takingunitList) this.takingunitList[0].Display = fulldayStr;
      });
    })
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.commonUI.addLoadingTask();
    // this.$watch('leaveApplication.Description', function (newVal, oldVal) {
    //   if (newVal != undefined && newVal.length > 200) {
    //     this.leaveApplication.Description = oldVal;
    //   }
    // });

    // this.$watch('startDate', function (newVal, oldVal) {
    //   this.endDate = this.startDate;
    // });

    // this.$watch('endDate', function (newVal, oldVal) {
    //   if (this.endDate < this.startDate) {
    //     this.endDate = this.startDate;
    //   }
    // });

    // this.$watch('leaveApplication.LeaveTypeCode', function (newVal, oldVal) {
    //   this.getTakingUnitList();
    //   for (var i = 0; i < this.leaveTypeList.length; i++) {
    //     if (this.leaveTypeList[i].Code == this.leaveApplication.LeaveTypeCode)
    //       this.leaveTypeDesc = this.leaveTypeList[i].Desc;
    //   }
    // });
    this.getLeaveTypeList();
    this.getStaffInfo();
    this.resetApplication();
    this.getTakingUnitList();
    this.commonUI.finishLoadingTask();
  }

  dateValidator(date) {
    switch (date) {
      case 'start':
        if (this.startDate > this.endDate)
          this.endDate = new Date(this.startDate)
        break;
      case 'end':
        if (this.endDate < this.startDate)
          this.startDate = new Date(this.endDate)
        break;
    }
  }

  resetApplication() {
    this.leaveApplication = {
      "EmploymentRID": this.employee.RID,
      "LeaveTypeCode": this.defaultLeaveTypeCode,
      "StartDate": "",
      "EndDate": "",

      "IsStartDateAllDay": "",
      "IsEndDateAllDay": "",
      "StartDateTakingUnit": "",
      "EndDateTakingUnit": "",

      "Description": "",

      "AttachmentName1": "",
      "AttachmentAddr1": "",
      "AttachmentName2": "",
      "AttachmentAddr2": "",
      "AttachmentName3": "",
      "AttachmentAddr3": "",
      "AttachmentName4": "",
      "AttachmentAddr4": "",
    };

    this.attachments = [];
    this.asAtDate = new Date();
    this.fileID = 0;
    this.startDate = moment().add(1, "days").toDate();
    this.endDate = this.startDate;
    $("#btnPreviewApplication").attr("disabled", false);

    //reset other control
    this.customResetApplication();
  }

  customResetApplication() {
  }

  // tempShowLeaveBalance() {
  //   const dialog = this.matDialog.open(PopupDetailOfCheckBalanceInquiryComponent, {
  //     data: {
  //       lb: this.leaveBalance,
  //       asAtDate: this.asAtDate,
  //       ap: this.leaveApplication
  //     },
  //     panelClass: 'dialog-responsive'
  //   })

  //   dialog.afterClosed().subscribe(data => {
  //     if (data == null || data == undefined) return;
  //     this.asAtDate = data
  //     console.log(this.asAtDate)
  //   })
  // }

  showLeaveBalance() {
    //$("#btn_balance_inquiry_search").click(function() {
    this.calSelfLeaveBalance();
    $("#p1_content").hide();
    $("#p2_content").hide();
    $("#p3_content").hide();
    $("#p4_content").hide();
    //})
  }

  hideLeaveBalance() {
    //$("#btn_balance_inquiry_search").click(function() {
    $("#pop_LeaveBalance").fadeOut();
    //})
  }

  //mod at GoodHope
  showPreviewApplication() {
    //alert($("#datepicker-1").datepicker( "getDate" ));
    //alert(this.startDate);
    // $("#popup_LeavePreview").fadeIn();
    this.calNumOfLeaveDays();
  }

  hidePreviewApplication = function () {
    //alert($("#datepicker-1").datepicker( "getDate" ));
    //alert(this.startDate);
    $("#popup_LeavePreview").fadeOut();
  }

  calNumOfLeaveDays() {
    this.setLeaveApplicationRec();

    this.commonUI.addLoadingTask();
    this.leaveService.calNumOfLeaveDays(this.leaveApplication).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.leaveDays = result.body['Data'].NumOfLeaveDays;

        this.startDateTakingUnit = this.leaveApplication.StartDateTakingUnit ? this.leaveApplication.StartDateTakingUnit : this.transalatedFullday;
        this.endDateTakingUnit = this.leaveApplication.EndDateTakingUnit ? this.leaveApplication.EndDateTakingUnit : (this.leaveApplication.IsEndDateAllDay == "Y" ? this.transalatedFullday : this.startDateTakingUnit);

        this.commonUI.finishLoadingTask();
        // $("#popup_LeavePreview").fadeIn();
        const dialog = this.matDialog.open(PopupLeavePreviewComponent, {
          data: {
            startDate: this.startDate,
            endDate: this.endDate,
            leaveApplication: this.leaveApplication,
            staffInfo: this.staffInfo,
            leaveDays: this.leaveDays,
            attachments: this.attachments,
            startDateTakingUnit: this.startDateTakingUnit,
            endDateTakingUnit: this.endDateTakingUnit
          }
        })

        dialog.afterClosed().subscribe((data?) => {
          if (data)
          this.resetApplication()
        })
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  setLeaveApplicationRec() {
    this.leaveApplication.StartDate = this.commonUI.formatJsonDate(this.startDate, "YYYY-MM-DDT00:00:00");
    this.leaveApplication.EndDate = this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00");

    var fileseq = 0;
    for (var i = 0; i < this.attachments.length; i++) {
      if (!this.attachments[i].deleted) {
        if (fileseq == 0) {
          this.leaveApplication.AttachmentName1 = this.attachments[i].file.name;
          this.leaveApplication.AttachmentAddr1 = this.attachments[i].uploadFilename;
        }
        if (fileseq == 1) {
          this.leaveApplication.AttachmentName2 = this.attachments[i].file.name;
          this.leaveApplication.AttachmentAddr2 = this.attachments[i].uploadFilename;
        }
        if (fileseq == 2) {
          this.leaveApplication.AttachmentName3 = this.attachments[i].file.name;
          this.leaveApplication.AttachmentAddr3 = this.attachments[i].uploadFilename;
        }
        fileseq++;
      }
    }

    this.leaveApplication.IsEndDateAllDay = "";
    this.leaveApplication.IsStartDateAllDay = "";
    if (this.leaveApplication.EndDateTakingUnit == "") this.leaveApplication.IsEndDateAllDay = "Y";
    if (this.leaveApplication.StartDateTakingUnit == "") this.leaveApplication.IsStartDateAllDay = "Y";

    if (this.leaveApplication.StartDate == this.leaveApplication.EndDate) {
      this.leaveApplication.EndDateTakingUnit = this.leaveApplication.StartDateTakingUnit;
      this.leaveApplication.IsEndDateAllDay = "";
      if (this.leaveApplication.StartDateTakingUnit == "") {
        this.leaveApplication.IsStartDateAllDay = "Y";
      }
    }

    //set other data to leave application
    this.customSetLeaveApplicationRec();
  }

  //mod at WKF, OceanGarden, Citic, GoodHope, HKACC, WilsonCom, CEB
  customSetLeaveApplicationRec() {
  }

  convertSizeFormat(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  };

  setPreview(attachment) {
    let instance = this
    var fileReader = new FileReader();
    fileReader.readAsDataURL(attachment.file)
    fileReader.onload = () => {
      if (attachment.file.type.match('image.*')) {
        attachment.dataURL = fileReader.result;
      }
      else if (attachment.file.type.match('pdf.*'))
        attachment.dataURL = "assets/img/icon_pdf.png";
      else if (attachment.file.type.match('xls.*') || attachment.file.type.match('xlsx.*') || attachment.file.type.match('vnd.ms-excel.*'))
        attachment.dataURL = "assets/img/icon_excel.png";
      else
        attachment.dataURL = "assets/img/icon_claim_maintenance.png";
      instance.attachments.push(attachment);
      $("#btn_delete_attach_" + attachment.fileID).hide();
      this.uploadAttachment(attachment);
    }
  }

  countFilesReadyForUpload() {
    var count = 0;
    for (var i = 0; i < this.attachments.length; i++) {
      if (!this.attachments[i].deleted) count++;
    }
    return count;
  }

  uploadListening(event) {
    $("#btnPreviewApplication").attr("disabled", true);
    //$scope.commonUI.addLoadingTask();
    var files = event.target.files;
    if ((this.countFilesReadyForUpload() + files.length) > 3) {
      this.commonUI.finishLoadingTask();
      this.commonUI.showWarning("message.M0005");
      $("#btnPreviewApplication").attr("disabled", false);
      return;
    }
    for (var i = 0; i < files.length; i++) {
      var attachment = { fileID: this.fileID++, file: null, dataURL: null, uploadProgress: 0, uploadFilename: "", cancelled: false, uploaded: false, deleted: false };
      attachment.file = files[i];
      this.setPreview(attachment);
    }
  }

  uploadAttachment(attachment) {
    $("#btn_upload_attach_" + attachment.fileID).hide();
    this.commonService.uploadAttachment(attachment).subscribe((result) => {
      if (result.type == HttpEventType.UploadProgress) {
        this.fileProgress = Math.round(100 * result.loaded / result.total);
      } else if (result instanceof HttpResponse) {
        if (!result['conn_fail']) {
          $("#btn_delete_attach_" + attachment.fileID).show();
          $("#btn_upload_attach_" + attachment.fileID).hide();
          $("#btn_cancel_attach_" + attachment.fileID).hide();
          $("#file_UploadAttachment").val('');
          $("#btnPreviewApplication").attr("disabled", false);
          attachment.uploadFilename = result.body['Data'].UploadFileName
          attachment.uploadFilePath = result.body['Data'].UploadFilePath
          attachment.uploaded = true
        } else
          this.onCallAPIFail(result.body, result.status, result.headers, result.body['Data'])
      }
    }, err => {
      this.fileProgress = 0
      $("#file_UploadAttachment").val('');
      $("#btnPreviewApplication").attr("disabled", false);
      this.deleteAttachment(attachment);
      this.commonUI.showMsgBox("status." + err.status + ': ' + err.statusText);
      // this.errorUI.showErrorBox(err.status)
      //if (error != undefined && error == 501)
      //    $scope.commonUI.showMsgBox("error.00022");
      //else
      //    $scope.commonUI.showMsgBox("error.00021");
    })
  }

  downloadAttachment(attachment) {
    //this.commonUI.saveTempAttachment(attachment);
    this.commonHandler.saveAttachment(attachment.uploadFilePath, attachment.file.name);
  }

  deleteAttachment(attachment) {
    //Worked
    attachment.deleted = true;
    this.attachments = this.attachments.filter((a) => a.deleted == false);
    $("#attach_" + attachment.fileID).fadeOut();
    if (this.countFilesReadyForUpload() < 3)
      $("#btnPreviewApplication").attr("disabled", false);
    //this.obj.attachments = []
  }

  cancelAttachment(attachment) {
    // $("#btnPreviewApplication").attr("disabled", false);
    //Worked
    if (this.fileProgress == 0) {
      this.deleteAttachment(attachment);
      return;
    }
    attachment.cancelled = true;
  }

  //mod at Citic, Ricacorp
  submitLeaveApplication() {
    this.setLeaveApplicationRec();

    this.commonUI.addLoadingTask();
    this.leaveService.submitLeaveApplication(this.leaveApplication).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        // $("#popup_LeavePreview").fadeOut();
        this.commonUI.finishLoadingTask();
        this.resetApplication();
        this.commonUI.showMsgBox("message.M0001");
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getStaffInfo() {
    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfo(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.staffInfo = result.body['Data'].StaffInfo;
        this.commonUI.finishLoadingTask();
      }

      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  //mod at Citic
  calSelfLeaveBalance() {
    this.commonUI.addLoadingTask();

    var index = this.leaveTypeList.findIndex(x => x.Code == this.leaveApplication.LeaveTypeCode);
    var leaveTypeDesc = index >= 0 ? this.leaveTypeList[index].Desc : "";

    $("#pop_LeaveBalance").fadeOut();
    this.leaveService.calSelfLeaveBalance(
      this.employee, this.leaveApplication.LeaveTypeCode,
      this.commonUI.formatJsonDate(this.asAtDate, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.leaveBalance = result.body['Data'].LeaveBalance;
          this.leaveApplication.showCutoff = true
          this.leaveApplication.StaffInfo = this.staffInfo
          // $("#pop_LeaveBalance").fadeIn().animate({ opacity: 1, marginTop: "0" }, 500, "swing", function () { $scope.commonUI.finishLoadingTask(); });
          const dialog = this.matDialog.open(PopupDetailOfCheckBalanceInquiryComponent, {
            data: {
              lb: this.leaveBalance,
              asAtDate: this.asAtDate,
              ap: this.leaveApplication,
              ltc: leaveTypeDesc,
            },
            panelClass: 'dialog-responsive'
          })

          dialog.afterClosed().subscribe(data => {
            if (data == null || data == undefined) return;
            this.asAtDate = data
          })
          this.commonUI.finishLoadingTask()
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  //mod at WilsonCom
  getLeaveTypeList() {
    this.commonUI.addLoadingTask();
    this.leaveService.getLeaveTypeList(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.leaveTypeList = result.body['Data'].LeaveTypeList;
        this.leaveTypeList = this.leaveTypeList.filter((l) => l.EnableAppInESS == 'Y')

        //Set Default Leave Type Code
        var containsAL = false;
        for (var i = 0; i < this.leaveTypeList.length; i++) {
          if (this.leaveTypeList[i].Code == "AL") {
            containsAL = true;
            break;
          }
        }
        this.leaveApplication.LeaveTypeCode = containsAL ? "AL" : this.leaveTypeList[0].Code;
        this.defaultLeaveTypeCode = containsAL ? "AL" : this.leaveTypeList[0].Code;
        this.leaveTypeDesc = containsAL ? "AL" : this.leaveTypeList[0].Desc;
        this.isNotAllowBalanceInquriy();
        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  //mod at CEB
  getTakingUnitList() {
    let instance = this
    this.commonUI.addLoadingTask();

    var currentDatetime = new Date();
    var currentDate = new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate());
    this.leaveService.getTakingUnitList(
      this.employee,
      this.leaveApplication.LeaveTypeCode,
      this.commonUI.formatJsonDate(currentDate, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.takingunitList = result.body['Data'].TakingUnitList;
          this.takingunitList.forEach((takingunit) => {
            takingunit.Display = takingunit.Code;
            if (takingunit.Display == "") {
              instance.translate.get('leaveapplication.fullday').subscribe((fulldayStr) => {
                takingunit.Display = fulldayStr;
              });
            }
          });

          if (!this.checkTakingUnitExistence(this.leaveApplication.StartDateTakingUnit))
            this.leaveApplication.StartDateTakingUnit = "Full Day";
          if (!this.checkTakingUnitExistence(this.leaveApplication.EndDateTakingUnit))
            this.leaveApplication.EndDateTakingUnit = "Full Day";

          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }


  isNotAllowBalanceInquriy() {
    this.notAllowBalanceInquiry = false;
    for (var i = 0; i < this.leaveTypeList.length; i++) {
      if (this.leaveTypeList[i].Code == this.leaveApplication.LeaveTypeCode) {
        if (this.leaveTypeList[i].EnableBalInqESS != 'Y') {
          this.notAllowBalanceInquiry = true;
          break;
        }
      }
    }
  }

  checkTakingUnitExistence(takingUnit) {
    if (this.takingunitList != undefined) {
      for (var i = 0; i < this.takingunitList.length; i++) {
        if (this.takingunitList[i].Code == takingUnit) {
          return true;
        }
      }
    }
    return false;
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.startDate = new Date()
        instance.startPicker.close()
        break;
      case 'end':
        instance.endDate = new Date()
        instance.endPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
      case 'end':
        this.endPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
  }

  /*******************/
  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}