import { Component, OnInit } from '@angular/core';
import { CommonUIService } from '../services-UI/common-ui.service'

@Component({
  selector: 'app-common-control',
  templateUrl: './common-control.component.html',
  styleUrls: ['./common-control.component.scss']
})
export class CommonControlComponent implements OnInit {

  constructor(public commonUI: CommonUIService) { }

  ngOnInit(): void {
  }

}
