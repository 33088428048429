<div>
    <div class="title_reset_2" style="width:auto;">
        <span class="txt_size_1 txt_grey_2">&nbsp;</span>
    </div>

    <!-- =========== Search ========== -->

    <div id="inquiry_box" class="content_box_no_overflow content_box_team_roster content_box_calendar_staffs_view" style="display:none;top:-300px;opacity:0">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_leaveapplicationinquiry"></div>
            <div class="fl"> {{ 'staffsviewcalendar.filter' | translate }}</div>
        </div>

        <form id="form_application_inquiry">
            <div class="box_content_inquiry">

                <div class="box_content_inquiry_field">
                    <div class="btn_sub_title txt_grey_2">{{ 'staffsviewcalendar.period' | translate }}</div>

                    <div class="fl input_field_large">
                        <select class="btn btn_dropdown_1 maincolor_btn_1"  [(ngModel)]="viewMonth" name='month_view'>
                            <option *ngFor='let month of viewMonthList' [value]="month">{{(commonUI.formatDisplayDate(month, 'MMM') | translate) + ' ' + commonUI.formatDisplayDate(month, 'YYYY')}}</option>
                        </select>
                    </div>


                    <div style="clear:both;"></div>
                </div>

                <div style="clear:both;"></div>
                <div>
                    <div class="btn_sub_title txt_grey_2">{{ 'staffsviewcalendar.staff' | translate }}</div>

                    <ng-multiselect-dropdown
                        name='selected_staff'   
                        [placeholder]="'staffsviewcalendar.selectstaff' | translate"
                        [settings]="dropdownSettings"
                        [data]="calendarStaffList"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                        [(ngModel)]="multipleSelection.addedStaff"
                        >
                    </ng-multiselect-dropdown>
                </div>

                <div style="clear:both;"></div>

                <hr>

                <div class="inquiry_box_two_btn_center">

                    <button class="btn_inquiry_search_sh btn btn-block btn-lg two_btn maincolor_btn2" type="button" (click)="showSearchResult()"><img src="assets/img/btn_search_a.png" width="18" />{{ 'search' | translate }}</button>

                    <button id="reset" name="reset" class="btn btn-block btn-lg two_btn btn_grey" type="button" (click)="resetSearchResult()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}</button>
                    <div style="clear:both;"></div>
                </div>

            </div>
        </form>


        <!-- ============ end : content_box ============ -->
    </div>





    <!-- =========== Result ========== -->
    <div class="list_result_area content_box content_box_team_roster content_box_calendar_staffs_view">

        <div class="box_title box_title_roster maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_staffs_view_calendar"></div>
            <div class="fl tit_name">Staffs View</div>
        </div>

        <div style="clear:both;"></div>
        <a name="list_result" class="list_bar_mt"></a>

        <div class="remark_bar txt_size_3 txt_grey_2">
            <div class="remark">
                <div>{{ 'approved' | translate }}</div>
                <div class="l_approved i"></div>
                <div>{{ 'pending' | translate }} &nbsp;&nbsp;&nbsp;</div>
                <div class="l_pending i"></div>
                <div style="clear:both;"></div>
            </div>
            <div style="clear:both;"></div>
        </div>

        <!-- ============ list's area ============ -->
        <div class="">

            <div id="filter_holder" class="form_area duty_roster_area">

                <div class="f_month_roster">

                    <div class="roster_list_left">

                        <!-- ========== start staff data name ========== -->
                        <div *ngFor="let staff of calendarScope.calendarData.StaffInfoList">
                            <div class="pp_day_roster cell_roster_left" style="border-bottom: 1px solid #e6e6e6; padding-top: 2px;">

                                <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(staff) + ')'}">
                                    <div class="txt_photo_mask_small p_normal"></div>
                                </div>

                                <div class="txt_content_area_2line txt_size_2">
                                    <div class="txt_name" title="{{staff.FullName}}">{{staff.FullName}}</div>
                                    <div style="clear:both;"></div>
                                    <div class="txt_size_3 txt_grey_2">{{staff.PositionDesc}}</div>
                                    <div class="txt_size_3 txt_grey_2">ID: {{staff.StaffID}}</div>
                                </div>

                                <div style="clear:both;"></div>
                            </div>
                            <div class="pop_name">
                                <div class="txt_name" title="{{staff.FullName}}">{{staff.FullName}}</div>
                                <div class="txt_size_3 txt_grey_2">{{staff.StaffID}}</div>
                                <div class="txt_size_3 txt_grey_2">{{staff.PositionDesc}}</div>
                            </div>
                        </div>

                    </div><!-- end : roster_list_left -->



                    <div id="roster_list_title_bar-month_roster" class="roster_list_title_bar" onscroll="document.getElementById('roster_list_right').scrollLeft = this.scrollLeft;">
                        <!--not use : document.getElementById('roster_list_right_sub').scrollLeft = this.scrollLeft;-->

                        <div class="roster_list_left_tool roster_list_title_bar_left_reset">
                            <div class="calendar_date_current">
                                <div>{{commonUI.formatDisplayDate(viewMonth, 'YYYY')}}</div>
                                <div>{{commonUI.formatDisplayDate(viewMonth, 'MMM')}}</div>
                                <select class="btn btn_dropdown_1"  [(ngModel)]='viewMonth' (change)='refreshCalendar()'>
                                    <option *ngFor='let month of viewMonthList' [value]="month">{{(commonUI.formatDisplayDate(month, 'MMM') | translate) + ' ' + commonUI.formatDisplayDate(month, 'YYYY')}}</option>
                                </select>
                            </div>

                            <div class="calendar_date_bar">
                                <button class="btn_grey_linewhite" title="Last Month" (click)="goToPrevMonth()" [hidden]="viewMonth <= minMonth"><img src="assets/img/btn_arrow_back.png" width="20" alt=""></button>
                                <div *ngIf='viewMonthList'>
                                    <select class="btn btn_dropdown_1" [(ngModel)]="viewMonth" (change)="refreshCalendar()">
                                        <option *ngFor='let month of viewMonthList' [value]="month">{{(commonUI.formatDisplayDate(month, 'MMM') | translate) + ' ' + commonUI.formatDisplayDate(month, 'YYYY')}}</option>
                                    </select>
                                </div>
                                <button class="btn_grey_linewhite" title="Next Month" (click)="goToNextMonth()" *ngIf="viewMonth < maxMonth"><img src="assets/img/btn_arrow_right.png" width="20" alt=""></button>
                                <div style="clear:both;"></div>
                            </div>
                            <a rel="external">
                                <div id="p4" class="header_bar_icon fl list_icon_border mt0 btn_filter_inquiry_sh" title="Filter" (click)="showSearchFilter()"><img alt="" src="assets/img/btn_filter.png" width="20"></div>
                            </a>
                            <div style="clear:both;"></div>

                        </div><!-- ==== end : roster_list_title_bar_left_reset ==== -->

                        <div class="roster_list_day roster_list_title_bar_right_reset">

                            <div *ngFor="let date of daysList" class='txt_date' [ngClass]="{'bg_roster_cell_sun' : date.isSunday == 'Y' || date.isHoliday == 'Y'}">
                                <div class="date">
                                    <div>{{date.desc | translate}}</div>
                                    <div>{{date.day}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both"></div>

                    </div><!-- end : roster_list_title_bar -->



                    <div id="roster_list_right" class="roster_list_right" onscroll="document.getElementById('roster_list_title_bar-month_roster').scrollLeft = this.scrollLeft;">
                        <div *ngIf='calendarScope.calendarData?.StaffInfoList'>
                            <div *ngFor="let staffDetails of calendarScope.calendarData.StaffInfoList" class="data">
                                <div *ngFor='let staffView of  calendarScope.calendarData.StaffsViewDict[staffDetails.StaffID]' (click)="showPopupLeave(staffView.LeaveList)" class="day" [ngClass]="{'day_apm' : staffView.LeaveList.length > 1 || staffView.isAM || staffView.isPM, 'bg_roster_cell_sun' : staffView.isSunday || staffView.isHoliday, 'day_full' : staffView.LeaveList.length == 1 && !staffView.isAM && !staffView.isPM}">
                                    <div *ngIf="staffView.isPM && staffView.LeaveList.length == 1"></div>
                                    <div *ngFor="let leave of staffView.LeaveList" [ngClass]="{'l_pending' : leave.StatusDesc == 'Pending' || leave.StatusDesc == 'CancelPending', 'l_approved' : leave.StatusDesc == 'Approved' }" title="{{leave.LeaveTypeDesc}}">{{leave.LeaveTypeCode}}</div>
                                    <div *ngIf="staffView.isAM && staffView.LeaveList.length == 1"></div>
                                </div>
                            </div>
                        </div>
                    </div><!-- end : roster_list_right -->

                </div> <!-- ========== end : f_month_roster =========== -->

                <div style="clear:both"></div>
            </div> <!-- ========== end : form_area =========== -->


            <div style="clear:both"></div>
        </div>

        <div class="btn_pop_bar">
            <!--<div class="icon_right_c" title="Next Month"></div>
            <div class="icon_left_c" title="Last Month"></div>-->
            <div class="icon_filter btn_filter_inquiry_sh" title="Filter" ng-click="showSearchFilter()"></div>
            <div class="btn_hide_staff_photo icon_staff_sh btn_grey_3b" title="Staff's photo" ng-click="showStaffName()"></div>
        </div>

    </div> <!-- end id=content_box --><br />


    <!-- <leave-detail-popup popupmode="leavePopMode"
                        popupcontrol="leavePopupControl"
                        popupid="popup_leave_details"
                        withdraw-confirm-popupid="withdrawConfirmPopUp"
                        cancel-confirm-popupid="cancelConfirmPopup"
                        approve-remark-popupid="approveRemarkPopup"
                        reject-remark-popupid="rejectRemarkPopup"
                        leave-balance-popupid="leaveBalancePopup"
						cancel-remark-popupid="cancelRemarkPopup"
                        approvereason="reason"
                        rejectreason="reason"
                        cancelreason="cancelReason"
                        approver-remark-list="approverRemarkList"
                        withdraw-leave-event="withdrawLeaveApplication()"
                        cancel-leave-event="cancelLeaveApplication()"
                        approve-leave-event="approveLeaveRecord()"
                        reject-leave-event="rejectLeaveRecord()"
                        staff-info="staffInfo">
    </leave-detail-popup>

    <leave-detail-multi-types-popup popupmode="leaveMultiTypesPopMode"
                                    popupcontrol="leaveMultiTypesPopupControl"
                                    popupid="popup_leave_details_multi_types"
                                    show-leave-event="showSelectedLeaveRecord()"
                                    staff-info="staffInfo">
    </leave-detail-multi-types-popup> -->


</div>