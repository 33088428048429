import { Injectable } from '@angular/core';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from './common-service.service';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CommonHandlerService {

  attachmentLink
  attachmentName

  constructor(public commonUI: CommonUIService, public commonService: CommonServiceService) { }

  saveAttachment(attachmentLink, attachmentName) {
    this.attachmentLink = attachmentLink;
    this.attachmentName = attachmentName;

    if (environment.isChromeIOS()) {
      if (sessionStorage.AllowIOSChromeSaveAttachment != "Y") {
        this.commonUI.showMsgBoxWithCallback('message.M0022', this.saveAttachmentAction());
      }
      else {
        this.commonService.saveAttachmentFormPost(this.attachmentLink, this.attachmentName);
      }
    }
    else
      this.saveAttachmentAction();

  }

  saveAttachmentAction() {
    if (environment.isIOS()) {
      if (environment.isIOSWebview())
        this.commonService.GetAttachmentToken(this.attachmentLink, this.attachmentName).subscribe((result) => {
          this.commonService.DownloadAttachmentToken(result.body['Data'].Token, result.body['Data'].FileName)
        })
      else
        this.commonService.saveAttachmentFormPost(this.attachmentLink, this.attachmentName);
    } else {
      this.commonService.saveAttachment(this.attachmentLink, this.attachmentName);
    }
  }
}
