import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-intro',
  templateUrl: './popup-intro.component.html',
  styleUrls: ['./popup-intro.component.scss']
})
export class PopupIntroComponent implements OnInit {

  introImageList = ["intro_1.png", "intro_2.png", "intro_3.png", "intro_4.png", "intro_5.png", "intro_6.png"];
  currentIntroImage = 0;
  translate_px = 0;
  mytransform = "translate(" + this.translate_px + "px, 0px)";
	language = localStorage.CurrentLanguage !== undefined ? localStorage.CurrentLanguage : "en";

  constructor(public matDialogRef: MatDialogRef<PopupIntroComponent>) { }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }
  
  goToPrevIntro = function () {
		if (this.currentIntroImage <= 0 ) return;
		this.currentIntroImage = this.currentIntroImage - 1;

		this.translate_px = ( this.currentIntroImage * -280 );
		this.mytransform = "translate(" + this.translate_px +"px, 0px)";
	}

	goToNextIntro = function () {
		if (this.currentIntroImage >= this.introImageList.length -1 ) return;
		this.currentIntroImage = this.currentIntroImage + 1;

		this.translate_px = ( this.currentIntroImage * -280 );
		this.mytransform = "translate(" + this.translate_px +"px, 0px)";
	}

}
