<!-- Mod at CEB, CIB, Citic, GoodHope, HKACC, OceanGarden, Ricacorp, WIlsonCom, WKF -->
<!-- Migrated to common/popup-leave-detail -->
<!--============ popup : detail of Leave ============ -->
<!-- <div id="{{ popupid }}" class="list_swipe_flag-selected">
    <div
         [ngClass]="popupmode == 'READONLY'? 'pop_functions_box pop_functions_box_size_2column' : 'pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval'"
    >
        <div class="pop_functions_close maincolor_btn2 pp_close" click="popupcontrol.closePopup()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(leaveapplication.StaffInfo) + ');'}"></div>
            <div class="detail_box_name">
                <span class="txt_popup_staffname">{{ leaveapplication.StaffInfo.FullName }}</span><br>
                <span class="txt_popup_position">{{ leaveapplication.StaffInfo.PositionDesc }}</span><br>
                <span class="txt_popup_staffid">ID : {{ leaveapplication.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

            <div class="detail_box_bottom_2point">
                <div class="detail_box_catalog_area">
                    <div class="maincolor_bg color_label_{{ leaveapplication.StatusDesc | lowercase }}">{{ leaveapplication.StatusDesc | lowercase | translate }}</div>
                </div>
                <div class="detail_box_session1 txt_grey_2 txt_size_3">{{startDateTakingUnit}}</div>
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(leaveapplication.StartDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(leaveapplication.StartDate, 'ddd')}})</span></div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{endDateTakingUnit}}</div>
                <div class="detail_box_date2 txt_size_1">{{commonUI.formatDisplayDate(leaveapplication.EndDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(leaveapplication.EndDate, 'ddd')}})</span></div>
                <div class="detail_box_sub_icon d_box_sub_icon_leave"></div>
                <div class="detail_box_date_num maincolor_txt"><span class="txt_size_0">{{ leaveapplication.NumOfLeaveDay }}</span><span class="txt_size_3">&nbsp;{{ 'balanceinquery.days' | translate }}</span></div>
                <div class="detail_box_type">{{ leaveapplication.LeaveTypeDesc }}</div>
            </div>

            <div class="detail_box_other txt_size_1">

                <div class="txt_grey_2 txt_size_3">{{ 'approval.submittedon' | translate }}</div>
                {{commonUI.formatDisplayDate(leaveapplication.CreateDate, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(leaveapplication.CreateDate, 'ddd')}})</span>&nbsp;{{commonUI.formatDisplayDate(leaveapplication.CreateDate, 'h:mm a')}}<br />
                <div class="txt_grey_2 txt_size_3">
                    {{ 'approval.description' | translate }}

                    <div class="remark_tabs">
                        <div class="tab first">
                            <input type="radio" id="leave-tab-r1" name="tab-group-1" [checked]="leaveapplication.SourceType =='ELEAVE'">
                            <label for="leave-tab-r1">{{ 'approval.application' | translate }}</label>
                            <div class="content txt_box_1 txt_grey_1 txt_size_2" style="white-space: pre-wrap;">{{ leaveapplication.Description }}</div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="leave-tab-r2" name="tab-group-1" [checked]="leaveapplication.SourceType =='LEAVECAN'">
                            <label for="leave-tab-r2">{{ 'approval.cancellation' | translate }}</label>
                            <div class="content txt_box_1 txt_grey_1 txt_size_2" style="white-space: pre-wrap;">{{ leaveapplication.CancelReason }}</div>
                        </div>
                    </div>
                </div>

                <div class="txt_grey_2 txt_size_3">{{ 'approval.attachment' | translate }}</div>
                <div class="detail_box_attachment_area">
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink1">
                        <div><img src="{{attachment1.dataURL}}" *ngIf="leaveapplication.AttachmentLink1"></div>
                        <div id="dl_attach1" class="attachment_photo_touch" (click)="popupcontrol.viewAttachment(leaveapplication.AttachmentLink1, leaveapplication.AttachmentName1)"></div>
                    </div>
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink2">
                        <div><img src="{{attachment2.dataURL}}" *ngIf="leaveapplication.AttachmentLink2"></div>
                        <div id="dl_attach2" class="attachment_photo_touch" click="popupcontrol.viewAttachment(leaveapplication.AttachmentLink2, leaveapplication.AttachmentName2)"></div>
                    </div>
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink3">
                        <div><img src="{{attachment3.dataURL}}" *ngIf="leaveapplication.AttachmentLink3"></div>
                        <div id="dl_attach3" class="attachment_photo_touch" click="commonHandler.viewAttachment(leaveapplication.AttachmentLink3, leaveapplication.AttachmentName3)"></div>
                    </div>
                    <div class="attachment_photo" *ngIf="leaveapplication.AttachmentLink4">
                        <div><img src="{{attachment4.dataURL}}" *ngIf="leaveapplication.AttachmentLink4"></div>
                        <div id="dl_attach4" class="attachment_photo_touch" click="commonHandler.viewAttachment(leaveapplication.AttachmentLink4, leaveapplication.AttachmentName4)"></div>
                    </div>
                    <div style="clear:both"></div>
                </div>

            </div>
        </div>


        <div class="detail_box_bottom_area mt0_m">

            <div class="txt_grey_2 txt_size_3">{{ 'approval.approverremarks' | translate }}</div>
            <div class="detail_box_approver_remarks_area">
                <div *ngFor="let approverRemark of approverRemarkList">
                    <div>
                        <div class="txt_photo_area_small fl" [ngStyle]="{'background-image':'url('+ commonUI.getStaffImage(approverRemark.StaffInfo) + ');'}">
                            <div class="txt_photo_mask_small p_normal_grey"></div>
                        </div>
                        <div class="approver_staff_content">
                            <div class="txt_bold">{{approverRemark.StaffInfo.FullName}}</div>
                            <div class="txt_grey_2 txt_size_3">{{commonUI.formatDisplayDate(approverRemark.ProcessDate, 'D MMM YYYY (ddd) - h:mm A')}}</div>
                            <div class="approver_label txt_size_3">
                                <span class="color_label_{{approverRemark.StatusDesc | lowercase}}">{{approverRemark.StatusDesc}}</span>
                            </div>-->
                            <!-- <div class="approver_staff_remark txt_box_1">{{approverRemark.Remark}}</div>
                        </div>
                        <div style="clear:both"></div>
                    </div>

                    <hr class="hr_profile">
                </div>

            </div>
        </div>

    </div>

    <div *ngIf="popupmode == 'APPROVAL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center" style="width:auto;">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" style="margin-left:30px;margin-bottom: 3px;" click="popupcontrol.showApprovePopup()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" style="margin-bottom: 3px;" click="popupcontrol.showRejectPopup()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                <button class="btn btn-block btn-lg btn_grey" style="width:150px;float:right;margin-right:35px;" click="popupcontrol.calStaffLeaveBalance()">
                    <img src="assets/img/btn_search_a.png" width="18" />{{ 'leaveapplication.balanceinquiry' | translate }}
                </button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

    <div *ngIf="popupmode == 'CANCEL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn btn_red pp_close flag_submit_finish" click="popupcontrol.showCancelPopup()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplicationinquery.leavecancellation' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

    <div *ngIf="popupmode == 'WITHDRAW'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn btn_red pp_close flag_submit_finish" click="popupcontrol.showWithdrawConfirm()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplicationinquery.leavewithdrawal' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
</div>

<div class="flag_submit_remarks_content pop_functions_div" id="{{ withdrawConfirmPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_1">

        <br />
        <img src="assets/img/msg_alert.png" width="48">
        <br /><br />
        {{ 'message.M0009' | translate }}
        <br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" click="withdrawLeaveEvent()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'yes' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" click="popupcontrol.cancelWithdraw()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'no' | translate }}</button>
            <div style="clear:both"></div>
        </div>

    </div>
</div> --> 

<!--============ popup : submit application remarks  ============ -->
<div class="flag_submit_remarks_content pop_functions_div" id="{{ cancelRemarkPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_company">
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'leaveapplicationinquery.remarksofcancellation' | translate }}</div>
        </div>

        <div class="submit_remarks_area">
            <div class="txt_photo_area_small fl" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(leaveapplication.StaffInfo) +');'}">
                <div class="txt_photo_mask_small p_normal_grey"></div>
            </div>
            <div class="approver_staff_content">
                <div class="txt_bold">{{leaveapplication.StaffInfo.FullName}}</div>
                <textarea [(ngModel)]="cancelreason" class="approver_staff_remark_input form-control txt_box_1 txt_size_2 bg_none" placeholder="{{ 'leaveapplicationinquery.pleaseinputremarks' | translate }}"></textarea>
            </div>
            <div style="clear:both"></div>
        </div>

        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" click="popupcontrol.showCancelConfirmPopup()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" click="popupcontrol.closeCancelPopup()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}</button>
        </div>

    </div>
</div>


<div class="flag_submit_remarks_content pop_functions_div" id="{{ cancelConfirmPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_1">

        <br />
        <img src="assets/img/msg_alert.png" width="48">
        <br /><br />
        {{ 'message.M0013' | translate }}
        <br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" click="cancelLeaveEvent();popupcontrol.closeCancelConfirmPopup()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'yes' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" click="popupcontrol.closeCancelConfirmPopup()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'no' | translate }}</button>
            <div style="clear:both"></div>
        </div>

    </div>
</div>

<!--============ popup : submit application remarks  ============ -->
<div class="flag_submit_remarks_content pop_functions_div" id="{{ approveRemarkPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_company">
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'approval.remarksofapprove' | translate }}</div>
        </div>

        <div class="submit_remarks_area">
            <div class="txt_photo_area_small fl" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(staffInfo) + ')'}">
                <div class="txt_photo_mask_small p_normal_grey"></div>
            </div>
            <div class="approver_staff_content">
                <!-- <div class="txt_bold">{{staffInfo.FullName}}</div> -->
                <textarea id="txtApproveReason" [(ngModel)]="approvereason" class="approver_staff_remark_input form-control txt_box_1 txt_size_2 bg_none" placeholder="{{ 'leaveapplicationinquery.pleaseinputremarks' | translate }}"></textarea>
            </div>
            <div style="clear:both"></div>
        </div>

        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" click="approveLeaveEvent()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" click="popupcontrol.closeApprovePopup()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}</button>
        </div>

    </div>
</div>

<!--============ popup : submit application remarks  ============ -->
<div class="flag_submit_remarks_content pop_functions_div" id="{{ rejectRemarkPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_company">
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'approval.remarksofreject' | translate }}</div>
        </div>

        <div class="submit_remarks_area">
            <div class="txt_photo_area_small fl" [ngStyle]="{'background-image':'url('+ commonUI.getStaffImage(staffInfo) + ')'}">
                <div class="txt_photo_mask_small p_normal_grey"></div>
            </div>
            <div class="approver_staff_content">
                <div class="txt_bold">{{staffInfo.FullName}}</div>
                <textarea id="txtRejectReason" [(ngModel)]="rejectreason" class="approver_staff_remark_input form-control txt_box_1 txt_size_2 bg_none" placeholder="{{ 'leaveapplicationinquery.pleaseinputremarks' | translate }}"></textarea>
            </div>
            <div style="clear:both"></div>
        </div>

        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" click="rejectLeaveEvent()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" click="popupcontrol.closeRejectPopup()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}</button>
        </div>

    </div>
</div>

<!--============ popup : detail of Check Balance Inquiry ============ -->
<div id="popup_BalanceDetail" class="pop_functions_div pp5_content" id="{{ leaveBalancePopupid }}">
    <div class="pop_functions_box pop_functions_box_size_2column mask_balance_inquiry">
        <div class="pop_functions_close maincolor_btn2 pp_close" click="popupcontrol.closeLeaveBalancePopup()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image':'url('+ commonUI.getStaffImage(leaveBalance.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
                <span class="txt_popup_staffname">{{leaveBalance.StaffInfo.FullName}}</span><br>
                <span class="txt_popup_position">{{ leaveBalance.StaffInfo.PositionDesc }}</span><br>
                <span class="txt_popup_staffid">ID&nbsp;:&nbsp;{{ leaveBalance.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

            <div class="detail_box_bottom_2point_leave_balance">
                <div class="detail_box_session1 txt_grey_2 txt_size_3">&nbsp;</div>
                <div class="detail_box_date1 txt_size_1">
                    {{commonUI.formatDisplayDate(leaveBalance.LeaveYearStart, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(leaveBalance.LeaveYearStart, '(ddd)')}}</span>
                </div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'leaveapplication.cutoffday' | translate }}</div>
                <div class="detail_box_date2 txt_size_1">
                    {{commonUI.formatDisplayDate(leaveBalance.LeaveYearEnd, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(leaveBalance.LeaveYearEnd, '(ddd)')}}</span>
                </div>
                <div class="detail_box_sub_icon_txtonly d_box_sub_icon_leave"></div>
                <div class="detail_box_type_appointment">{{leaveapplication.LeaveTypeDesc}}</div>
            </div>

            <div class="detail_box_other txt_size_1">
                <div class="txt_grey_2 txt_size_3">{{ 'leaveapplication.cutoffdayasat' | translate }}</div>
                {{commonUI.formatDisplayDate(asAtDay, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(asAtDay, '(ddd)')}}</span>
                <div class="txt_grey_2 txt_size_3">{{ 'leaveapplication.availablebalance' | translate }}</div>
                {{leaveBalance.currentYearDetail.AvailableBalance}}<span class="txt_size_3">&nbsp;{{ 'balanceinquery.days' | translate }}</span>
            </div>
        </div>

        <div class="detail_box_bottom_area mt0_m">
            <div class="btn_balance_inquiry_search_result">
                <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'leaveapplication.openingbalance' | translate }}</div>
                <div class="btn_balance_title_num bg_grey_2c txt_grey_1">{{leaveBalance.currentYearDetail.OpenBalance}}</div>
                <div style="clear:both"></div>

                <div class="btn_balance_inquiry_search_result_break"></div>
                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.graceexpiry' | translate }}</div>

                <div *ngIf="leaveBalance.currentYearDetail.GraceExpiry == '0001-01-01T00:00:00'" class="btn_balance_title_num txt_grey_1">
                    <span>N.A.</span>
                </div>
                <div *ngIf="leaveBalance.currentYearDetail.GraceExpiry != '0001-01-01T00:00:00'" class="btn_balance_title_num txt_grey_1">
                    <span>{{commonUI.formatDisplayDate(leaveBalance.currentYearDetail.GraceExpiry, 'D MMM YYYY (ddd)')}}</span>
                </div>

                <div style="clear:both"></div>
                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.graceentitlement' | translate }}</div>
                <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceEntitlement}}</div>
                <div style="clear:both"></div>
                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.gracetaken' | translate }}</div>
                <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceTaken}}</div>
                <div style="clear:both"></div>
                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.gracebalance' | translate }}</div>
                <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceBalance}}</div>
                <div style="clear:both"></div>

                <div class="btn_balance_inquiry_search_result_break"></div>

                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.entitlement' | translate }}</div>
                <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Entitlement}}</div>
                <div style="clear:both"></div>
                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.adjustment' | translate }}</div>
                <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Adjustment}}</div>
                <div style="clear:both"></div>
                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.taken' | translate }}</div>
                <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Taken}}</div>
                <div style="clear:both"></div>
                <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.othertakenincate' | translate }}</div>
                <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Adjustment}}</div>
                <div style="clear:both"></div>

                <div class="btn_balance_inquiry_search_result_break"></div>
                <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'leaveapplication.totalbalance' | translate }}</div>
                <div class="btn_balance_title_num bg_grey_2c txt_grey_1">{{leaveBalance.currentYearDetail.TotalBalance}}</div>
                <div style="clear:both"></div>

            </div>

        </div>

    </div>
</div>

<div style="display:none"><iframe id="pdf_target" style="width:100%;height:100%"></iframe></div>