import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';

@Component({
  selector: 'app-popup-yes-no-message',
  templateUrl: './popup-yes-no-message.component.html',
  styleUrls: ['./popup-yes-no-message.component.scss']
})
export class PopupYesNoMessageComponent implements OnInit {

  msgcode: string

  constructor(public matDialogRef: MatDialogRef<PopupYesNoMessageComponent>, public commonUI: CommonUIService, @Inject(MAT_DIALOG_DATA) public code: string) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close()
  }

}
