import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupTermsComponent } from '../common/popup-terms/popup-terms.component'
import { CommonUIService } from '../services-UI/common-ui.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from '../services/common-service.service';
import { CommonServiceServiceMod } from '../services-mod/common-service-mod.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { IdleTimerService } from '../services/idle-timer.service';
import { PopupCompanySelectComponent } from '../common/popup-company-select/popup-company-select.component';
import { PopupMessageWithCallbackComponent } from '../common/popup-message-with-callback/popup-message-with-callback.component';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  title = "AC Redirect ... ";
  redirect = "ess/dash board";
  acToken = "";
  companyList;
  selectedCompany;
  loginInprogress = false;
  userCode = localStorage.UserCode;
  password = "";
  language = "";
  rememberme = localStorage.RememberMe == "Y" ? true : false;
  isV11Container = environment.isV11Container;
  sessionCriteria;

  constructor(public idleTimer: IdleTimerService, public translateService: TranslateService, public matDialog: MatDialog, public commonUI: CommonUIService, public commonService: CommonServiceServiceMod, public idle: Idle, public router: Router, public location: Location, public route: ActivatedRoute) {   

  }

  ngOnInit(): void {
        
    this.commonUI.addLoadingTask();
    this.route.queryParams.subscribe(params => {
      this.acToken = params['token'];
        if (!this.acToken)
        {
          this.router.navigateByUrl("login");
          this.commonUI.showMsgBox("message.M0021");
        }

      this.commonService.GetACSessionCriteria(this.acToken).subscribe((result) => {
        if (!result['conn_fail']){
            if (result.body['ErrorMsg'] == '' || result.body['ErrorMsg'] == null) {
                  this.onGetACSessionCriteriaSuccess(result.body['ACSessionCriteria']);
            }
            else {
              this.commonUI.showMsgBox(`error.${result.body['ErrorCode']}`);
              this.router.navigateByUrl("login");
              this.commonUI.finishLoadingTask();
            }
        }
        else {
          this.router.navigateByUrl("login");
          this.commonUI.finishLoadingTask();
        }
      });
    });

  }

  onLoginSuccess(loginInfo, isTickOldSession) {
    var sessionid = loginInfo.SessionID;
    var companyList = loginInfo.Company;
    var person = loginInfo.Person;
    var passwordPolicy = loginInfo.PasswordPolicy;
    var hTML5Link = loginInfo.HTML5Link;
    var v12HTML5OpenNewWindow = loginInfo.V12HTML5OpenNewWindow;
    var maxUploadSize = loginInfo.MaxUploadSize;
    var allowIOSChromeSaveAttachment = loginInfo.AllowIOSChromeSaveAttachment;
    var isSECMSEnable = loginInfo.IsSECMSEnable;
    var tempSKE = loginInfo['TempSKE'];
    var tempSKN = loginInfo['TempSKN'];

    this.loginInprogress = false;

    if (loginInfo.DefaultLanguage) {
      this.language = loginInfo.DefaultLanguage;
      this.changeLanguage();
    }

    if (isTickOldSession) {
      this.commonUI.showMsgBox("message.M0021");
    }

    if (loginInfo.RedirectPage)
       this.redirect = loginInfo.RedirectPage;


    this.companyList = companyList;
    sessionStorage.SessionID = sessionid;
    sessionStorage.companyList = JSON.stringify(companyList);
    sessionStorage.Person = JSON.stringify(person);
    sessionStorage.PasswordPolicy = JSON.stringify(passwordPolicy);
    sessionStorage.HTML5Link = JSON.stringify(hTML5Link);
    sessionStorage.V12HTML5OpenNewWindow = JSON.stringify(v12HTML5OpenNewWindow);
    sessionStorage.MaxUploadSize = maxUploadSize;
    sessionStorage.AllowIOSChromeSaveAttachment = allowIOSChromeSaveAttachment;
    sessionStorage.IsSECMSEnable = isSECMSEnable;
    sessionStorage.TempSKE = tempSKE;
    sessionStorage.TempSKN = tempSKN;
    sessionStorage.HideToolBarButton = true;
    sessionStorage.IsACLogin = true;

    if (companyList.length > 1) {
      this.selectCompany()
    } 
    else if(companyList.length == 1 && companyList[0].StaffList.length > 1) {
      this.selectCompany()
    }
    else if(companyList.length == 1 && companyList[0].StaffList.length == 1){
      this.selectEmployee(companyList[0].StaffList[0]);
    }
  }

  onCallLoginAPIFail(data, status, headers, config) {
    this.password = "";
    this.loginInprogress = false;
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  selectCompany() {
    this.matDialog.open(PopupCompanySelectComponent, {
      data: this.companyList
    })
    //this.commonUI.finishLoadingTask();
  }

  selectEmployee(employee) {
    // sessionStorage.Employee = JSON.stringify(employee);
    //$('#popup_menu_select_position').fadeToggle();
    this.commonUI.addLoadingTask();
    this.commonService.setCompany(this.companyList[0]).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == ''|| result.body['ErrorMsg'] == null) {
          this.loginInprogress = false;

          sessionStorage.Employee = JSON.stringify(employee);
          sessionStorage.Person = JSON.stringify(employee.Person);

          sessionStorage.Company = JSON.stringify(this.companyList[0]);
          sessionStorage.Person = JSON.stringify(this.companyList[0].Person);

          if(this.redirect.includes("ess/dash")) {
            this.router.navigateByUrl("ess/dashboard"); 
            this.commonUI.finishLoadingTask();
          } 
          else {
            this.checkAvailableModule();
          }
        }
        else {
          this.onCallAPIFail(result.body, result.status, result.headers, result)
          this.commonUI.finishLoadingTask();
          this.router.navigateByUrl("login");
        }
      else {
        this.onCallAPIFail(result.body, result.status, result.headers, result)
        this.commonUI.finishLoadingTask();
        this.router.navigateByUrl("login");
      }
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
      this.commonUI.finishLoadingTask();
      this.router.navigateByUrl("login");
    })
  }


   onGetACSessionCriteriaSuccess(sessionCriteria) {

    if (sessionCriteria.IsV12SessionRequired)	{
  		this.commonService.loginAC(this.acToken, sessionCriteria.UserCode).subscribe((result) => {           
            if (!result['conn_fail']) {
                  if (result.body['ErrorMsg'] == ''|| result.body['ErrorMsg'] == null) {
                  this.onLoginSuccess(result.body['Data'], result.body['Data'].isTickOldSession);
                  } 
                  else {
                      this.commonUI.finishLoadingTask()
                      this.commonUI.showMsgBox(`error.${result.body['ErrorCode']}`);
                      this.router.navigateByUrl("login");
                  }
            } 
            else { 
              this.commonUI.finishLoadingTask()
              this.commonUI.showMsgBox(`error.${result.body['ErrorCode']}`);
              this.router.navigateByUrl("login");
            }
        }, (err) => {
          this.commonUI.finishLoadingTask()
          this.commonUI.showMsgBoxWithCallback('error.undefined')
          this.router.navigateByUrl("login");
      });
  	}
  	else
  	{
  		if (sessionCriteria.LanguageOption)
          {
              this.language = sessionCriteria.LanguageOption;
              this.changeLanguage();
          }
  		    this.commonUI.finishLoadingTask();
          this.router.navigate([], { 
          queryParams: {
              query: ''
          }
          }); //To remove ALL query parameters
          this.router.navigateByUrl(sessionCriteria.RedirectLink);
  	}
  }

   checkAvailableModule() {
       this.commonService.getModule(JSON.parse(sessionStorage.Employee)).subscribe((result) => {
            if (!result['conn_fail']) {
                  if (result.body['ErrorMsg'] == ''|| result.body['ErrorMsg'] == null) {
                      this.router.navigate([], { 
                        queryParams: {
                            query: ''
                        }
                        }); //To remove ALL query parameters
                    
                        this.router.navigateByUrl(this.redirect);
                  }
            }
       })
   }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  changeLanguage() {
    localStorage.CurrentLanguage = this.language;
    this.translateService.use(this.language);
  }

}