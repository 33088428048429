<!-- menu -->
<div class='active' id="menu">
  <div class="pure-menu">
    <div *ngIf="staffInfo">
      <div>
        <div class="menu_header_L1"></div>
        <!-- <div class="menu_header_L2" style="background-image: url(assets/img/M.png)"> -->
        <div class="menu_header_L2"
          [ngStyle]="{'background-image': 'url( ' + commonUI.getStaffImage(staffInfo) + ')' } ">

          <div class="menu_header_L2_mask"></div>
        </div>
        <div class="menu_header_L3">
          <span class="txt_size_1">{{ staffInfo.FullName }}</span><br /> <span class="txt_opacity_1">{{
            staffInfo.PositionDesc }}</span><br /> <span
            class="txt_size_3 txt_opacity_1 txt_menu_staffid">ID&nbsp;:&nbsp;{{ staffInfo.StaffID }}</span><br />

          <div *ngIf="companyList.length > 1 || company.StaffList.length > 1" class="btn-group">
            <div class="btn_select_company" (click)="openCompanySelect('popup_select_company')">
              {{ company.CompanyName }}
            </div>
            <!--
                        <button class="btn btn_linestyle_white dropdown-toggle txt_opacity_1" ng-click="showDropDownCompanyList()" type="button" data-toggle="dropdown">
                            <span>{{ company.CompanyName }}</span><span class="caret"></span>
                        </button>
  
                        <ul id="dropdown_menu_company_list" class="dropdown-menu" role="menu" ng-repeat="com in companyList" ng-if="companyList">
                            <li class="pp3"><a rel="external" href="javascript:void(0)" ng-click="showPopupSelectPosition(com)">{{ com.CompanyName }}</a></li>
                        </ul>
                        -->
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div routerLinkActive="btn_menu_title_selected" class="btn_menu_title">
          <a routerLink="/ess/dashboard" (click)="showcloseMenu()">
            <div class="m_home"></div>{{ 'menu.home' | translate }}
          </a>
        </div>
      </div>

      <div *ngIf='staffMenu'>
        <div *ngFor="let menu of menuList">
          <!-- leave -->
          <div *ngIf="findMenuIndx(menu.menuCode) > -1">
            <div>
              <div *ngIf="!menu.url">
                <div class="btn_menu_title" (click)="showMenuItem(menu.menuCode)" id="{{ 'menu_' + menu.menuCode }}">
                  <div class="{{menu.cssClass}}"></div>
                  {{ menu.displayName | translate }}
                </div>
              </div>

              <div *ngIf="menu.url">
                <div routerLinkActive="btn_menu_title_selected" class="btn_menu_title"
                  id="{{ 'menu_' + menu.menuCode }}">
                  <a *ngIf="!menu.isDirectUrl" [routerLink]="[ '/ess/' + menu.url]">
                    <div class="{{menu.cssClass}}"></div>
                    {{ menu.displayName | translate }}
                  </a>
                  <a *ngIf="menu.isDirectUrl" href="{{ menu.url }}">
                    <div class="{{menu.cssClass}}"></div>
                    {{ menu.displayName | translate }}
                  </a>
                </div>
              </div>

              <div id="{{ 'subMenu_' + menu.menuCode }}" class="btn_menu_list" *ngIf="menu.menuList != null">
                <div *ngIf='menu.menuList' id="{{ 'subMenuWrapper_' + menu.menuCode }}">
                  <div *ngFor="let submenu of menu.menuList">
                    <div *ngIf="findMenuIndx(submenu.menuCode) > -1">
                      <div routerLinkActive="btn_menu_item-selected" class="btn_menu_item {{submenu.cssClass}}"
                        [routerLink]="['/ess/' + submenu.url]" (click)="closeMenu()">
                        {{submenu.displayName | translate }}
                      </div>
                    </div>
                    <div *ngIf="!submenu.url">
                      <div class="btn_menu_title" (click)="showSubMenuItem(submenu.menuCode)"
                        id="{{ 'subMenu_' + submenu.menuCode }}">
                        <div class="{{submenu.cssClass}}"></div>
                        {{ submenu.displayName | translate }}
                      </div>
                    </div>
                    <div id="{{ 'subMenu_' + submenu.menuCode }}" class="btn_submenu_list"
                      *ngIf="submenu.menuList != null">
                      <div *ngIf='submenu.menuList' id="{{ 'subSubMenuWrapper_' + submenu.menuCode }}">
                        <div *ngFor="let subSubMenu of submenu.menuList">
                          <div>
                            <div routerLinkActive="btn_menu_item-selected" class="btn_menu_item {{subSubMenu.cssClass}}"
                              [routerLink]="subSubMenu.url" (click)="closeMenu()">
                              {{subSubMenu.displayName | translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div id="{{ 'subMenu_' + menu.menuCode }}" class="btn_menu_list" *ngIf="menu.menuList != null">
                <div>
                  <div *ngFor="let submenu of menu.menuList">
                    <div *ngIf="findMenuIndx(submenu.menuCode) > -1">
                      <div routerLinkActive="btn_menu_item-selected" class="btn_menu_item {{submenu.cssClass}}"
                        [routerLink]="['/ess/' + submenu.url]">
                        {{submenu.displayName | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
          <!-- leave -->
        </div>
      </div>
    </div>

  </div>

</div>
<!-- end id:menu -->

<!-- menu -->


<!-- popup -->
<!--
    <div id="popup_menu_select_position" class="pp3_content pop_functions_div">
        <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition">
          <div id="btn_back_company" class="pop_functions_close btn_grey_2b"  ng-click="closePopupSelectPosition()">x</div>
          <div style="clear: both"></div>
    
          <span class="txt_grey_2">{{ 'login.selectposition' | translate }}</span> <br /> <br />
    
          <div class="box_grid_two btn_jobpsoition company_logo_center" title="aCube Solutions Limited" ng-if="selectedCompany">
            <div ng-if="selectedCompany.CompanyCode" class="txt_photo_area_big" style="background-image: url(companylogo/{{selectedCompany.CompanyCode}}.png);">
              <div class="txt_photo_mask_big p_normal"></div>
            </div>
            <div style="clear: both;"></div>
            <div class="txt_box_label_name">{{selectedCompany.CompanyName}}</div>
          </div>
          <div style="clear: both;"></div>
          <br />
    
          <div class="jobposition_content bg_grey_4">
          <div ng-repeat="employee in selectedCompany.StaffList">
            <button class="btn btn-block btn-lg maincolor_btn2 icon_arrow_r" ng-click="selectEmployee(employee)">
              <div class="btn_txt_icon_right">{{employee.StaffID}}</div>
            </button>
          </div>
          </div>
    
        </div>
      </div>
      -->
<!-- popup -->

<!-- Migrate to common/popup-company-select -->
<!--============ popup : company select  ============ -->
<!-- <div id="popup_select_company" class="pop_functions_div"  >
      <div class="pop_functions_box pop_functions_box_size_company">
        <div class="pop_functions_close btn_grey_2b pp_close" (click)="closeDropDownCompanyList()">x</div>
        <div style="clear: both"></div>
        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
          <div class="box_title_icon t_company"></div>
          <div class="fl">{{ 'login.selectcompany' | translate }}</div>
        </div>
        <div>
    
          <div *ngIf='companyList'>
            <div *ngFor="let company of companyList" class="box_1_staff btn_opacity" title={{company.CompanyName}} click="showPopupSelectPosition(company)">
                <div class="txt_photo_area_big" [ngStyle]="{'background-image':'url(' + companylogo + '/' + company.CompanyCode + '.jpg' + ')'}">
                  <div class="txt_photo_mask_big p_normal_big"></div>
                </div>
                <div class="box_1_staff_name">
                  <span class="txt_size_2">{{company.CompanyName}}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
          </div>
    
        </div>
    
      </div>
    </div> -->

<!-- Migrate to common/popup-job-position -->
<!--============ popup : job position select  ============ -->


<!-- <div id="popup_menu_select_position" class="pp3_content pop_functions_div" >
      <div class="pop_functions_box pop_functions_box_size_company">
        <div id="btn_back_company" class="pop_functions_close btn_grey_2b" (click)="closePopupSelectPosition()">x</div>
        <div style="clear: both"></div>
        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
          <div class="box_title_icon t_employment"></div>
          <div class="fl">{{ 'login.selectposition' | translate }}</div>
        </div> -->

<!-- <span class="txt_grey_2">{{ 'login.selectposition' | translate }}</span> <br /> <br /> -->
<!--
        <div class="box_grid_two btn_jobpsoition company_logo_center" title="aCube Solutions Limited" ng-if="selectedCompany != null">
          <div class="txt_photo_area_big" ng-attr-style="background-image: url(companylogo/{{selectedCompany.CompanyCode}}.png);">
            <div class="txt_photo_mask_big p_normal_big"></div>
          </div>
          <div style="clear: both;"></div>
          <div class="txt_box_label_name">{{selectedCompany.CompanyName}}</div>
        </div>
        <div style="clear: both;"></div>
        <br />
    -->

<!-- <div class="jobposition_content bg_grey_4">
        <div *ngIf='selectedCompany'>
            <div *ngFor="let employee of selectedCompany.StaffList">
                <button class="btn btn-block btn-lg maincolor_btn2 icon_arrow_r" click="selectEmployee(employee)">
                    <div class="btn_txt_icon_right">{{employee.StaffID}}</div>
                </button>
            </div>
        </div>
        </div>
    
      </div>
    </div> -->