import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';

@Component({
  selector: 'app-popup-message-warning',
  templateUrl: './popup-message-warning.component.html',
  styleUrls: ['./popup-message-warning.component.scss']
})
export class PopupMessageWarningComponent implements OnInit {

  msgcode: string

  constructor(public matDialogRef: MatDialogRef<PopupMessageWarningComponent>, public commonUI: CommonUIService, @Inject(MAT_DIALOG_DATA) public code: string) { 
    this.msgcode = code
  }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close()
  }

}
