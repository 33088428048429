import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonHandlerService } from 'src/app/services/common-handler.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { LeaveService } from 'src/app/services/leave.service';
import { environment } from 'src/environments/environment';
import { ImageEnlargePreviewComponent } from '../image-enlarge-preview/image-enlarge-preview.component';
import { PopupConfirmWithdrawComponent } from '../popup-confirm-withdraw/popup-confirm-withdraw.component';
import { PopupDetailOfCheckBalanceInquiryComponent } from '../popup-detail-of-check-balance-inquiry/popup-detail-of-check-balance-inquiry.component';
import { PopupNextApproverComponent } from '../popup-next-approver/popup-next-approver.component';
import { PopupSubmitApprovalRemarksComponent } from '../popup-submit-approval-remarks/popup-submit-approval-remarks.component';
import { PopupSubmitCancelRemarksComponent } from '../popup-submit-cancel-remarks/popup-submit-cancel-remarks.component';
import { PopupSubmitRejectRemarksComponent } from '../popup-submit-reject-remarks/popup-submit-reject-remarks.component';

declare var $: any;

@Component({
  selector: 'app-popup-leave-detail',
  templateUrl: './popup-leave-detail.component.html',
  styleUrls: ['./popup-leave-detail.component.scss']
})
export class PopupLeaveDetailComponent implements OnInit {

  @Input() popupid: "@"
  @Input() withdrawConfirmPopupid: "@"
  @Input() cancelRemarkPopupid: "@"
  @Input() cancelConfirmPopupid: "@"
  @Input() approveRemarkPopupid: "@"
  @Input() rejectRemarkPopupid: "@"
  @Input() leaveBalancePopupid: "@"
  @Input() cancelreason: '='
  @Input() approvereason: string
  @Input() rejectreason: '='
  @Input() popupcontrol: '='
  @Input() popupmode_1: string
  @Input() approverRemarkList: '='
  @Input() cancelLeaveEvent: '&'
  @Input() withdrawLeaveEvent: '&'
  @Input() approveLeaveEvent: '&'
  @Input() rejectLeaveEvent: '&'

  employee = JSON.parse(sessionStorage.Employee);
  translatedFully: any;
  attachmentFile = {};
  asAtDay;
  leaveapplication;
  leaveBalance;

  attachment1
  attachment2
  attachment3
  attachment4

  startDateTakingUnit: ''
  endDateTakingUnit: ''

  popupmode = ''
  isDelegate = false
  isOverride = false

  applicant

  constructor(public commonUI: CommonUIService, public matDiglogRef: MatDialogRef<PopupLeaveDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public translate: TranslateService, public commonService: CommonServiceService, public matDialog: MatDialog, public commonHandler: CommonHandlerService, public leaveService: LeaveService) {
    this.translate.get('leaveapplication.fullday').subscribe((fulldayStr) => {
      this.translatedFully = fulldayStr
    })
    this.leaveapplication = data['app']
    this.approverRemarkList = data['approver_remark_list']
    this.popupmode = data['popmode']
    this.isDelegate = data['isDelegate']
    this.isOverride = data['isoverride']

    this.attachment1 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment2 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment3 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment4 = { downloaded: false, dataURL: "", ext: "" };

    this.applicant = { RID: this.leaveapplication.StaffInfo.EmploymentRID };

  }

  ngOnInit(): void {
    if (this.leaveapplication.AttachmentLink1 != "") this.commonService.downloadAttachment(this.leaveapplication.AttachmentLink1, environment.GetFileExtension(this.leaveapplication.AttachmentName1), this.attachment1).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    if (this.leaveapplication.AttachmentLink2 != "") this.commonService.downloadAttachment(this.leaveapplication.AttachmentLink2, environment.GetFileExtension(this.leaveapplication.AttachmentName2), this.attachment2).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    if (this.leaveapplication.AttachmentLink3 != "") this.commonService.downloadAttachment(this.leaveapplication.AttachmentLink3, environment.GetFileExtension(this.leaveapplication.AttachmentName3), this.attachment3).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    if (this.leaveapplication.AttachmentLink4 != "") this.commonService.downloadAttachment(this.leaveapplication.AttachmentLink4, environment.GetFileExtension(this.leaveapplication.AttachmentName4), this.attachment4).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })

    this.startDateTakingUnit = this.leaveapplication.StartDateTakingUnit ? this.leaveapplication.StartDateTakingUnit : this.translatedFully;
    this.endDateTakingUnit = this.leaveapplication.EndDateTakingUnit ? this.leaveapplication.EndDateTakingUnit : (this.leaveapplication.IsEndDateAllDay == "Y" ? this.translatedFully : this.startDateTakingUnit);
  }

  close(data?) {
    try {
      this.matDiglogRef.close(data)
    } catch { }
  }

  onCalSelfLeaveBalanceSuccess = function (service, leaveBalance) {
    this.commonUI.finishLoadingTask();
    this.leaveBalance = leaveBalance;
    this.showLeaveBalancePopup();
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  showPopup() {
    this.attachment1 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment2 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment3 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment4 = { downloaded: false, dataURL: "", ext: "" };

    $("#" + this.popupid).fadeIn();

    // if (this.leaveapplication.AttachmentLink1 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink1, environment.GetFileExtension(leaveapplication.AttachmentName1), this.attachment1);
    // if (this.leaveapplication.AttachmentLink2 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink2, environment.GetFileExtension(leaveapplication.AttachmentName2), this.attachment2);
    // if (this.leaveapplication.AttachmentLink3 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink3, environment.GetFileExtension(leaveapplication.AttachmentName3), this.attachment3);
    // if (this.leaveapplication.AttachmentLink4 != "") this.commonService.downloadAttachment(leaveapplication.AttachmentLink4, environment.GetFileExtension(leaveapplication.AttachmentName4), this.attachment4);

    this.startDateTakingUnit = this.leaveapplication.StartDateTakingUnit ? this.leaveapplication.StartDateTakingUnit : this.translatedFully;
    this.endDateTakingUnit = this.leaveapplication.EndDateTakingUnit ? this.leaveapplication.EndDateTakingUnit : (this.leaveapplication.IsEndDateAllDay == "Y" ? this.translatedFully : this.startDateTakingUnit);
  }

  showCancelPopup() {
    const dialog = this.matDialog.open(PopupSubmitCancelRemarksComponent, {
      panelClass: 'dialog-responsive',
      data: {
        app: this.leaveapplication,
        popid: 'popup_leave_details',
      }
    })

    dialog.afterClosed().subscribe((data) => {
      if(data == null || data == undefined) return; 
      if(data.confirm) 
        this.matDiglogRef.close(data)
      else 
        this.close()
    })
  }

  showWithdrawConfirm() {
    const dialog = this.matDialog.open(PopupConfirmWithdrawComponent, {
      panelClass: 'dialog-responsive',
      data: {
        app: this.leaveapplication,
        popid: 'withdrawConfirmPopUp',
        type: 'leave'
      }
    })

    dialog.afterClosed().subscribe((data?) => {
      if(data == null || data == undefined) return; 
      if(data.confirm) 
        this.matDiglogRef.close(data)
      else 
        this.close()
    })
  }

  closePopup = function () {
    $("#" + this.popupid).fadeOut();
  }

  // showCancelPopup = function () {
  //   $("#" + this.popupid).fadeOut();
  //   $("#" + this.cancelRemarkPopupid).fadeIn();
  // }

  // showCancelConfirmPopup = function () {
  //   $("#" + this.cancelRemarkPopupid).fadeOut();
  //   $("#" + this.cancelConfirmPopupid).fadeIn();
  // }

  showApprovePopup() {
    //approveRemarkPopup
    const dialog = this.matDialog.open(PopupSubmitApprovalRemarksComponent, {
      panelClass: 'dialog-responsive',
      data: {
        popid: 'approveRemarkPopup',
        app: this.leaveapplication,
        isDelegate: this.isDelegate,
        isoverride: this.isOverride
      }
    })

    dialog.afterClosed().subscribe((data) => {
      if(data) this.close(data)
    })
    // $("#" + this.popupid).fadeOut();
    // $("#" + this.approveRemarkPopupid).fadeIn();
  }

  showRejectPopup() {
    const dialog = this.matDialog.open(PopupSubmitRejectRemarksComponent, {
      panelClass: 'dialog-responsive',
      data: {
        popid: 'rejectRemarkPopup',
        app: this.leaveapplication,
        isDelegate: this.isDelegate,
        isoverride: this.isOverride
      }
    })

    dialog.afterClosed().subscribe((data) => {
        if(data) this.close(data)
    })

    // $("#" + this.popupid).fadeOut();
    // $("#" + this.rejectRemarkPopupid).fadeIn();
  }

  // closeCancelPopup = function () {
  //   $("#" + this.cancelRemarkPopupid).fadeOut();
  // }

  // closeCancelConfirmPopup = function () {
  //   $("#" + this.cancelConfirmPopupid).fadeOut();
  // }

  closeApprovePopup = function () {
    $("#" + this.approveRemarkPopupid).fadeOut();
  }

  closeRejectPopup = function () {
    $("#" + this.rejectRemarkPopupid).fadeOut();
  }

  // showWithdrawConfirm = function () {
  //   $("#" + this.popupid).fadeOut();
  //   $("#" + this.withdrawConfirmPopupid).fadeIn();
  // }

  // closeWithdrawConfirm = function () {
  //   $("#" + this.withdrawConfirmPopupid).fadeOut();
  // }

  // cancelWithdraw = function () {
  //   $("#" + this.withdrawConfirmPopupid).fadeOut();
  // }

  calStaffLeaveBalance() {
    this.commonUI.addLoadingTask();
    this.asAtDay = this.commonUI.formatJsonDate(new Date(), "YYYY-MM-DDT00:00:00")
    this.leaveService.calSelfLeaveBalance(this.applicant, this.leaveapplication.LeaveTypeCode, this.asAtDay).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.leaveBalance = result.body['Data'].LeaveBalance;
        const dialog = this.matDialog.open(PopupDetailOfCheckBalanceInquiryComponent, {
          data: {
            lb: this.leaveBalance,
            asAtDate: this.asAtDay,
            ap: this.leaveapplication
          },
          panelClass: 'dialog-responsive'
        })
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  showLeaveBalancePopup = function () {
    $("#" + this.popupid).fadeOut();
    $("#" + this.leaveBalancePopupid).fadeIn();
  }

  closeLeaveBalancePopup = function () {
    $("#" + this.leaveBalancePopupid).fadeOut();
    $("#" + this.popupid).fadeIn();
  }

  viewAttachment(link, fileName) {
    var ext = environment.GetFileExtension(fileName);
    if (environment.isMobileDevice() || environment.isIE() || !environment.isValidatePreviewAttachment(ext)) {
      this.commonUI.finishLoadingTask();
      this.commonHandler.saveAttachment(link, 'Attachment.' + ext);
    } else {
      this.commonService.viewAttachment(link, ext, this.attachmentFile).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.matDialog.open(ImageEnlargePreviewComponent, {
            data: {
              dataURL: result.body['dataURL']
            },
            panelClass: 'dialog-responsive'
          })
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  onDownloadAttachmentSuccess = function (data) {
    this.commonUI.finishLoadingTask();

    $.colorbox({
      inline: true, width: "95%", height: "80%", href: "#pdf_target",
      scrolling: false,
      scalePhotos: true,
      onCleanup: this.onColorBoxCleanUp,
      onComplete: this.onColorBoxComplete
    });
  }

  onColorBoxComplete = function () {
    $("#pdf_target").attr("src", this.attachmentFile.dataURL);
  }

  onColorBoxCleanUp = function () {
    $("#pdf_target").attr("src", "about:blank");
  }

}