<!--============ popup : Loading  ============ -->
<!-- <link href="css/loading/loading.css" rel="stylesheet"> -->
<div id="popup_loading" class="se-pre-con">
	<div class="txt_grey_2">{{ 'loading' | translate }}...</div>
</div>
<!--============ popup : Message  ============ -->
<!-- <div id="popup_msg_box" class="pop_functions_div pop_msg" style="display: none;" >
    <div class="pop_functions_box pop_functions_box_size_1">
        <br />
        <img src="assets/img/msg_tick.png" width="48">
        <br /><br />{{ commonUI.msgcode | translate:commonUI.translationData }}<br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg one_btn maincolor_btn2" (click)="commonUI.hideMsgBox()">
                <img src="assets/img/btn_tick_w.png" width="18">{{ 'ok' | translate }}
            </button>
            <div style="clear: both"></div>
        </div>
    </div>
</div> -->
<!--============ popup : Message with Callback ============ -->
<!-- <div id="popup_msg_box_with_callback" class="pop_functions_div pop_msg" style="display: none;" >
    <div class="pop_functions_box pop_functions_box_size_1">
        <br />
        <img src="assets/img/msg_tick.png" width="48">
        <br /><br />{{ commonUI.msgcode | translate:commonUI.translationData }}<br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg one_btn maincolor_btn2" click="commonUI.hideMsgBoxWithCallback()">
                <img src="assets/img/btn_tick_w.png" width="18">{{ 'ok' | translate }}
            </button>
            <div style="clear: both"></div>
        </div>
    </div>
</div> -->
<!--============ popup : Yes/No Message with Callback ============ -->
<!-- <div id="popup_msg_box_yes_no_with_callback" class="pop_functions_div pop_msg" style="display: none;" >
    <div class="pop_functions_box pop_functions_box_size_1">
        <br />
        <img src="assets/img/msg_tick.png" width="48">
        <br /><br />{{ commonUI.msgcode | translate:commonUI.translationData }}<br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" click="commonUI.onConfirmYesNoMsgBoxWithCallback(); commonUI.hideMsgBoxYesNoWithCallback()">
                <img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}
            </button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" click="commonUI.hideMsgBoxYesNoWithCallback()">
                <img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}
            </button>
            <div style="clear: both"></div>
        </div>
    </div>
</div> -->
<!--============ popup : Message Text with Callback ============ -->
<!-- <div id="popup_msgtext_box_with_callback" class="pop_functions_div pop_msg" style="display: none;" >
    <div class="pop_functions_box pop_functions_box_size_1">
        <br />
        <img src="assets/img/msg_tick.png" width="48">
        <br /><br />{{ commonUI.msgText }}<br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg one_btn maincolor_btn2" (click)="commonUI.hideMsgTextBoxWithCallback()">
                <img src="assets/img/btn_tick_w.png" width="18">{{ 'ok' | translate }}
            </button>
            <div style="clear: both"></div>
        </div>
    </div>
</div> -->
<!--============ popup : Warning  ============ -->
<!-- <div id="popup_warning" class="pop_functions_div pop_msg" style="display: none;" >
    <div class="pop_functions_box pop_functions_box_size_1">
        <br />
        <img src="assets/img/msg_alert.png" width="48">
        <br /><br />{{ commonUI.msgcode | translate:commonUI.translationData }}<br /><br />
        <div class="pop_functions_two_btn_center">
            <button id="link_closePopup" class="btn btn-block btn-lg one_btn maincolor_btn2" (click)="commonUI.hideWarning()">
                <img src="assets/img/btn_tick_w.png" width="18">{{ 'ok' | translate }}
            </button>
            <div style="clear: both"></div>
        </div>
    </div>
</div> -->