import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { CommonHandlerService } from 'src/app/services/common-handler.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { PersonalService } from 'src/app/services/personal.service';

declare var $: any;

@Component({
  selector: 'app-popup-personal-profile-dependt',
  templateUrl: './popup-personal-profile-dependt.component.html',
  styleUrls: ['./popup-personal-profile-dependt.component.scss']
})
export class PopupPersonalProfileDependtComponent implements OnInit {

  obj
  relationshipList;
  identityTypeList;
  displaySwitch;
  fileID = 0;
  fileProgress = 0;
  employee = JSON.parse(sessionStorage.Employee);
  selectedIndex = 0;

  @ViewChildren('birthPicker') birthPicker: QueryList<MatDatepicker<any>>
  // @ViewChildren('birthFooter') birthFooter: QueryList<ElementRef>

  // @ViewChild('birthPicker', { static: false }) birthPicker: MatDatepicker<any>;
  @ViewChild('birthFooter', { static: false }) birthFooter: ElementRef;

  /* Datepicker */
  minDate = new Date(1980, 1, 1)
  /*********** */

  constructor(public matDialogRef: MatDialogRef<PopupPersonalProfileDependtComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public errorUI: ErrorUIService, public commonUI: CommonUIService, public commonService: CommonServiceService, public personalService: PersonalService, public commonHandler: CommonHandlerService) {
    this.obj = data['obj']
    this.relationshipList = data['relationshipList']
    this.identityTypeList = data['identityTypeList']
    this.displaySwitch = data['displaySwitch']
  }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch { }
  }

  /* Datepicker Footer */

  today(date, n) {
    date = new Date()
    this.obj[n].Birthday = date
    this.closePicker(n)
  }

  closePicker(n) {  
    this.birthPicker.forEach(function(e, idx) {
      if(idx == n) {
        e.close()
      }
    })
  }

  openAppend(i) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.birthFooter.nativeElement);
    this.selectedIndex = i
  }

  /*******************/

  isRecordsNotReady() {
    var notReady = true;
    if (!this.obj) return notReady;
    var records = this.obj.filter(function (record) {
      return !record.deleted;
    })
    for (var i = 0; i < records.length; i++) {
      var record = this.obj[i];
      if (record.Action != 'K') {
        notReady = false;
        break;
      }
    }
    return notReady;
  }

  attachmentExistedFromRecord(currentRecord) {
    if (this.obj == undefined) return false;
    if (this.obj.attachments == undefined) return false;
    // return this.obj.attachments.filter(function (attach) {
    //     return !attach.deleted && attach.uuid == currentRecord.uuid;
    // }).length > 0;
    if (this.obj.attachments.filter(attach => {
      !attach.deleted && attach.uuid.value == currentRecord.uuid;
    }).length > 0)
      return true

    return false
  }

  uploadListening(event) {
    var files = event.target.files;
    var uuid = event.target.attributes.uuid;
    /*
    if ((this.countFilesReadyForUpload() + files.length) > 3) {
        this.commonUI.finishLoadingTask();
        this.commonUI.showWarning("message.M0005");
        this.$apply();
        return;
    }
    */
    for (var i = 0; i < files.length; i++) {
      var attachment = { fileID: this.fileID++, file: null, dataURL: null, uploadProgress: 0, uploadFilename: "", cancelled: false, uploaded: false, deleted: false };
      attachment['file'] = files[i];
      attachment['uuid'] = uuid;
      this.setPreview(attachment);
    }
    //this.commonUI.finishLoadingTask();
  }

  setPreview(attachment) {
    let instance = this
    var fileReader = new FileReader();
    fileReader.readAsDataURL(attachment.file)
    fileReader.onload = () => {
      if(attachment.file.type.match('image.*')) {
        attachment.dataURL = fileReader.result;
      } 
      else if(attachment.file.type.match('pdf.*'))
          attachment.dataURL = "assets/img/icon_pdf.png";
      else if(attachment.file.type.match('xls.*') || attachment.file.type.match('xlsx.*') || attachment.file.type.match('vnd.ms-excel.*'))
          attachment.dataURL = "assets/img/icon_excel.png";
      else 
        attachment.dataURL = "assets/img/icon_claim_maintenance.png";
      instance.obj.attachments.push(attachment);
      $("#btn_delete_attach_" + attachment.fileID).hide();
      this.uploadAttachment(attachment);
    }
  }

  uploadAttachment(attachment) {
    $("#btn_upload_attach_" + attachment.fileID).hide();
    this.commonService.uploadAttachment(attachment).subscribe((result) => {
      if (result.type == HttpEventType.UploadProgress) {
        this.fileProgress = Math.round(100 * result.loaded / result.total);
      } else if (result instanceof HttpResponse) {
        if (!result['conn_fail']) {
          $("#btn_delete_attach_" + attachment.fileID).show();
          $("#btn_upload_attach_" + attachment.fileID).hide();
          $("#btn_cancel_attach_" + attachment.fileID).hide();
          $("#file_UploadAttachment").val('');
          $("#file_BankUploadAttachment").val('');
          $("input[uuid*='" + attachment.uuid + "']").val('');
          attachment.uploadFilename = result.body['Data'].UploadFileName
        } else 
        this.onCallAPIFail(result.body, result.status, result.headers, result.body['Data'])
      }
    }, err => {
      this.fileProgress = 0
      $("#file_UploadAttachment").val('');
      $("#file_BankUploadAttachment").val('');
      $("input[uuid*='" + attachment.uuid + "']").val(''); //use uuid delete
      this.deleteAttachment(attachment);
      // this.commonUI.showMsgBox("status." + err);
      this.commonUI.showMsgBox("status." + err.status + ': ' + err.statusText);
      //if (error != undefined && error == 501)
      //    this.commonUI.showMsgBox("error.00022");
      //else
      //    this.commonUI.showMsgBox("error.00021");
    })
  }

  deleteAttachment(attachment) {
    //Worked
    attachment.deleted = true;
    $("#attach_" + attachment.fileID).fadeOut();
    this.obj.attachments = this.obj.attachments.filter(o => o.deleted == false);
    //this.obj.attachments = []
  }

  submitEvent() {
    var DependtList = [];
    this.obj.forEach((record, k) => {
      if (record.Action != 'K' && !record.deleted) {
        var entry = {
            "EmploymentRID": record.EmploymentRID,
            "Action": record.Action,
            "DependtRID": record.DependtRID,
            "IsEmployee": record.IsEmployee || "",
            "RelationshipCode": record.RelationshipCode,
            "FullName": record.FullName,
            "Mobile": record.Mobile,
            "IdentityTypeCode": record.IdentityTypeCode,
            "IdentityNum": record.IdentityNum,
            // "Birthday" : $scope.commonUI.formatJsonDate(record.Birthday, "YYYY-MM-DDT00:00:00"),
            "Birthday": this.commonUI.dateToIsoDateString(record.Birthday),
            "HKID":record.HKID,
            // "EffectiveDate" : record.EffectiveDate || "",
            // "ExpiryDate" : record.ExpiryDate || "",
            "IsEmergency": record.IsEmergency,
            "AttachmentName": record.AttachmentName,
            "AttachmentAddr": record.AttachmentAddr
        };
        var attachments = this.obj.attachments.filter((attach) => attach.uuid.value == record.uuid);
        if (attachments.length > 0) {
            for (var i = 0; i < attachments.length; i++) {
                if (!attachments[i].deleted) {
                    entry.AttachmentName = attachments[i].file.name;
                    entry.AttachmentAddr = attachments[i].uploadFilename;
                }
            }
        }
        DependtList.push(entry);
      }
    });

    this.commonUI.addLoadingTask();
    this.personalService.submitDependt(DependtList).subscribe((result) => {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0001");
          this.obj.IsEditable = false;
          this.matDialogRef.close()
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  insertDependentsRecord() {

    this.obj.forEach((record, key) => {
      record.onFocus = false;
    });
    $('#' + this.displaySwitch + ' .form_content_input').slideUp();

    var record = {
      uuid: this.commonUI.genUUID(),
      onFocus: true,
      EmploymentRID: this.employee.RID,
      Action: "I",
      DependtRID: "",
      Birthday: new Date(1980, 0, 1),
      HKID: "",
      // Birthday : $scope.commonUI.parseJsonDate("0001-01-01T00:00:00").toDate(),
      // Birthday : "0001-01-01T00:00:00",
      FullName: "",
      Mobile: "",
      IdentityNum: "",
      AttachmentName: "",
      AttachmentAddr: "",
      IsEmergency: "",
      RelationshipCode: this.relationshipList[0].Code,
      IdentityTypeCode: this.identityTypeList[0].Code,
      deleted: false,
    };
    this.obj.push(record);
  }

  trackByIndex(index: number) {
    return index;
  }

  convertSizeFormat(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  };

  deleteRecord(r) {
    r.deleted = true;
    this.obj.filter(o => o.deleted == false)
    // $('#' + $scope.displaySwitch + ' .form_content_input').slideUp();
    // $('#' + $scope.displaySwitch + ' #form-0 .form_content_input').slideToggle(); //show first record
  }

  getRelationshipDesc(code) {
    var desc = "";
    this.relationshipList.forEach((r, k) => {
      if (r.Code == code) desc = r.Desc
    });
    return desc;
  }

  slideUp(event) {
    this.obj.forEach(record => {
      record.onFocus = false
    });
    $('#' + this.displaySwitch + ' .form_content_input').slideUp();
    $('#' + this.displaySwitch + ' #' + event.currentTarget.parentNode.id + ' .form_content_input').slideToggle();
    // $('.form_content_input').slideUp();
    // $('#' + event.currentTarget.id + ' .form_content_input').slideToggle();
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}
