<div id="popup_current_version">
    <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition" style="overflow:hidden;">
      <div class="pop_functions_close btn_grey_2b pp_close" (click)="close()">x</div>
        <div style="clear:both"></div>
          <br/>
          <span class="txt_size_1 txt_grey_2">{{ 'toolbar.currentversion' | translate }}</span>
          <br/><br/>
          <div class="pop_functions_box_content_tc">
  
              <div class="txt_grey_2 txt_size_3">{{ 'toolbar.version' | translate }}</div>
              <span class="txt_size_1">1.00</span>&nbsp;&nbsp;<span class="txt_size_3">(Up-to-date)</span>
              <br/><br/>
  
              <div class="txt_grey_2 txt_size_3">{{ 'toolbar.publicationdate' | translate }}</div>
              <span class="txt_size_1">20 February 2019</span>
              <br/><br/>
  
              <div class="txt_grey_2 txt_size_3">©2019 aCube Solutions Limited. <br/>All Rights Reserved.</div>
  
      </div>
  
    </div>
  </div>