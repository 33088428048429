import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { LeaveService } from 'src/app/services/leave.service';
import { PopupSubmitCancelRemarksComponent } from '../popup-submit-cancel-remarks/popup-submit-cancel-remarks.component';

@Component({
  selector: 'app-popup-confirm-cancel-remarks',
  templateUrl: './popup-confirm-cancel-remarks.component.html',
  styleUrls: ['./popup-confirm-cancel-remarks.component.scss']
})
export class PopupConfirmCancelRemarksComponent implements OnInit {

  employee = JSON.parse(sessionStorage.Employee);
  cancelreason = "";
  leaveapplication
  cancelConfirmPopupid
  confirm = false

  constructor(public commonUI: CommonUIService, public matDialogRef: MatDialogRef<PopupSubmitCancelRemarksComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public commonService: CommonServiceService, public leaveService: LeaveService) { 
    this.leaveapplication = data['app']
    this.cancelreason = data['reason']
    this.cancelConfirmPopupid = data['popid']
  }

  ngOnInit(): void {
  }

  close() {
    const returnValue = {
      confirm: this.confirm
    }
    try {
      this.matDialogRef.close(returnValue)
    } catch {}
  }

  cancelLeaveApplication() {
    this.commonUI.addLoadingTask();
    this.leaveService.cancelLeaveApplication(this.employee, this.leaveapplication, this.cancelreason).subscribe((res) => {
      this.confirm = true
      this.commonUI.finishLoadingTask();
      this.commonUI.showMsgBoxWithCallback("message.M0014", null);
      this.close();
    }, err => {
      err['conn_fail'] = true
    })
  }

}
