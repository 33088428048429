import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PopupPersonalProfileAddressComponent } from '../common/popup-personal-profile-address/popup-personal-profile-address.component';
import { PopupPersonalProfileBankComponent } from '../common/popup-personal-profile-bank/popup-personal-profile-bank.component';
import { PopupPersonalProfileContactComponent } from '../common/popup-personal-profile-contact/popup-personal-profile-contact.component';
import { PopupPersonalProfileDependtComponent } from '../common/popup-personal-profile-dependt/popup-personal-profile-dependt.component';
import { PopupPersonalProfileMaritalComponent } from '../common/popup-personal-profile-marital/popup-personal-profile-marital.component';
import { PopupPersonalProfileQualiComponent } from '../common/popup-personal-profile-quali/popup-personal-profile-quali.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonHandlerService } from '../services/common-handler.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';
import { PersonalService } from '../services/personal.service';

declare var $: any;

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  editMode = false;

  generalTab = { cssClass: "f_general", isEditable: false, cssTitleClass: "t_general", displayName: "personalprofile.tabgeneral", selectedSection: 1 };
  employmentTab = { cssClass: "f_employment", isEditable: false, cssTitleClass: "t_employment", displayName: "personalprofile.tabemployment", selectedSection: 1 };
  addressTab = { cssClass: "f_address", isEditable: false, cssTitleClass: "t_address", displayName: "personalprofile.tabaddress", selectedSection: 1 };
  bankTab = { cssClass: "f_bank", isEditable: false, cssTitleClass: "t_bank", displayName: "personalprofile.tabbank", selectedSection: 1 };
  dependentsTab = { cssClass: "f_dependents", isEditable: false, cssTitleClass: "t_dependents", displayName: "personalprofile.tabdependents", selectedSection: 1 };
  qualificationTab = { cssClass: "f_qualification", isEditable: false, cssTitleClass: "t_qualification", displayName: "personalprofile.tabqualification", selectedSection: 1};

  currentTab = this.generalTab;

  displayPopupName = undefined;

  addressTypeList = [];
  areaList = [];
  districtList = [];
  qualiTypeList = [];
  relationshipList = [];
  identityTypeList = [];

  bankList = []
  selectedBankDesc

  employee;
  sessionId;
  usercode;
  company;
  companyList;

  person;
  PersnlProfile;
  PersnlEmploy;
  maritalStatus;
  PersnlAddressList;
  PersnlBankAcc;
  PersnlDependtList;
  PersnlQualiList;

  // PersnlDependtList = {
  //   IsEditable: true,
  //   dependList: [

  //   ]
  // }

  // PersnlQualiList = {
  //   IsEditable: true,
  //   qualiList: [

  //   ]
  // }

  constructor(public idleTimer: IdleTimerService, public commonUI: CommonUIService, public matDialog: MatDialog, public commonService: CommonServiceService, public personalService: PersonalService, public commonHandler: CommonHandlerService) { }
  ngOnInit(): void {
    this.commonUI.addLoadingTask();
    // this.MaritalStatus = "personalprofile.maritalStatusDesc." + this.PersnlProfile.MaritalStatus;
    this.commonService.refreshSessToScope(this);

    this.sessionId = sessionStorage.SessionID;
    this.usercode = sessionStorage.UserCode;
    this.employee = JSON.parse(sessionStorage.Employee);
    this.company = JSON.parse(sessionStorage.Company);
    this.companyList = JSON.parse(sessionStorage.companyList);

    this.showTab("f_general");
    this.commonUI.finishLoadingTask();
    // $scope.$parent.hideMenu();
    this.getLists();
  }

  getLists() {
    this.personalService.getAddressTypeList().subscribe((res) => {
      if(res.body != undefined) 
      this.addressTypeList = res.body['Data'].AddressTypeList
    else
      this.addressTypeList = res
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    this.personalService.getAreaList().subscribe((res) => {
      if(res.body != undefined) 
        this.areaList = res.body['Data'].AreaList
      else
        this.areaList = res
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })

    this.personalService.getDistrictList().subscribe((res) => {
      if(res.body != undefined) 
        this.districtList = res.body['Data'].DistrictList
      else
        this.districtList = res
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    this.personalService.getQualiTypeList().subscribe((res) => {
      if(res.body != undefined) 
        this.qualiTypeList = res.body['Data'].QualiTypeList
      else
        this.qualiTypeList = res
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    this.personalService.getRelationshipList().subscribe((res) => {
      if(res.body != undefined) 
        this.relationshipList = res.body['Data'].RelationshipList
      else
        this.relationshipList = res
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    this.personalService.getIdentityTypeList().subscribe((res) => {
      if(res.body != undefined) 
        this.identityTypeList = res.body['Data'].IdentityTypeList
      else
        this.identityTypeList = res
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  showSelectedSection(section) {
    setTimeout(() => {
      this.currentTab.selectedSection = section;
      //.personal_profile_area > div >
      $('.personal_profile_area > div > .form_content:visible > .form_content_input').not('div[tag="form_content_input_' + section + '"]').slideUp("slow", function () {
          $('div[tag="form_content_input_' + section + '"]').slideDown('slow');
      });
    }, 200);
  }

  showTab(tab) {
    if (tab == "f_general") {
        this.currentTab = this.generalTab;
        this.getPersnlProfile();
        this.showSelectedSection(1);
    } else if (tab == "f_employment") {
        this.currentTab = this.employmentTab;
        this.getPersnlEmploy();
    } else if (tab == "f_address") {
        this.currentTab = this.addressTab;
        this.getPersnlAddressList();
        this.showSelectedSection(1);
    } else if (tab == "f_bank") {
        this.currentTab = this.bankTab;
        this.getPersnlBankAccList();
    } else if (tab == "f_dependents") {
        this.currentTab = this.dependentsTab;
        this.getPersnlDependtList();
        this.showSelectedSection(1);
    } else if (tab == "f_qualification") {
        this.currentTab = this.qualificationTab;
        this.getPersnlQualiList();
        this.showSelectedSection(1);
    } else {
        this.currentTab = this.generalTab;
        this.getPersnlProfile();
    }
  }

  getAreaDesc(area) {
    var desc = ""
    this.areaList.forEach((v) => {
      if(v.Code == area) desc = v.Desc;
    })
    return desc;
  }

  getPersnlProfile() {
    this.commonUI.addLoadingTask();
    this.personalService.getPersnlProfile(this.employee, this.person).subscribe((result) => {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.PersnlProfile = result.body['Data'].PersnlGeneral
          this.PersnlProfile.IsContactEditable = result.body['Data'].IsContactEditable;
          this.PersnlProfile.IsMaritalStatusEditable = result.body['Data'].IsMaritalStatusEditable;
          this.maritalStatus = "personalprofile.maritalStatusDesc." + this.PersnlProfile.MaritalStatus;
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getPersnlEmploy() {
    this.commonUI.addLoadingTask();
    this.personalService.getPersnlEmploy(this.employee).subscribe((result) => {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.PersnlEmploy = result.body['Data'].PersnlEmploy;
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getPersnlAddressList() {
    this.commonUI.addLoadingTask();
    this.personalService.getPersnlAddressList(this.employee, this.person).subscribe((result) => {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          if (result.body['Data'].PersnlAddressList == undefined) {
            this.commonUI.finishLoadingTask();
            return;
          }
          this.PersnlAddressList = result.body['Data'].PersnlAddressList;
          this.PersnlAddressList.IsEditable = result.body['Data'].IsEditable;
          this.commonUI.finishLoadingTask();

          var qIdx = 1;
          this.PersnlAddressList.forEach(v => {
            v.Attachment = null;
            v.index = qIdx++;
            if (v.Attachment != null) {
                this.commonService.downloadAttachment(v.Attachment, environment.GetFileExtension(v.Attachment), v.Attachment).subscribe((res) => {
                  console.log(res)
                })
            }
          });
          this.showSelectedSection(1);
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
}

getPersnlBankAccList() {
  this.commonUI.addLoadingTask();
  this.personalService.getPersnlBankAccList(this.employee).subscribe((result) => {
    
    if(!result['conn_fail'])
      if(result.body['ErrorMsg'] == null) {
        if (result.body['Data'].PersnlBankAcc == undefined) {
          this.commonUI.finishLoadingTask();
          return;
        }
        this.PersnlBankAcc = result.body['Data'].PersnlBankAcc;
        this.PersnlBankAcc.IsEditable = result.body['Data'].IsEditable;
        this.commonUI.finishLoadingTask();
      
        if (this.PersnlBankAcc.Attachment != null) {
            this.PersnlBankAcc.Attachment.downloaded = false;
            this.commonService.downloadAttachment(this.PersnlBankAcc.Attachment, environment.GetFileExtension(this.PersnlBankAcc.Attachment), this.PersnlBankAcc.Attachment).subscribe((res) => {
              console.log(res)
            })
        }
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    else 
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  },err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

getPersnlDependtList() {
  this.commonUI.addLoadingTask();
  this.personalService.getPersnlDependtList(this.employee, this.person).subscribe((result) => {
    
    if(!result['conn_fail'])
      if(result.body['ErrorMsg'] == null) {
        if (result.body['Data'].PersnlDependtList == undefined) {
          this.commonUI.finishLoadingTask();
          return;
      }
      this.PersnlDependtList = result.body['Data'].PersnlDependtList;
      this.PersnlDependtList.IsEditable = result.body['Data'].IsEditable;
      this.commonUI.finishLoadingTask();
    
      var dIdx = 1;
      this.PersnlDependtList.forEach(v => {
        v.Attachment = null;
        v.index = dIdx++;
        if (v.Attachment != null) {
            this.commonService.downloadAttachment(v.Attachment, environment.GetFileExtension(v.Attachment), v.Attachment).subscribe((res) => {
              console.log(res)
            })
        }
      });
      this.showSelectedSection(1);
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    else 
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  },err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

getPersnlQualiList() {
  this.commonUI.addLoadingTask();
  this.personalService.getPersnlQualiList(this.employee, this.person).subscribe((result) => {
    
    if(!result['conn_fail'])
      if(result.body['ErrorMsg'] == null) {
        if (result.body['Data'].PersnlQualiList == undefined) {
          this.commonUI.finishLoadingTask();
          return;
      }
      this.PersnlQualiList = result.body['Data'].PersnlQualiList;
      this.PersnlQualiList.IsEditable = result.body['Data'].IsEditable;
      this.commonUI.finishLoadingTask();
    
      var qIdx = 1;
      this.PersnlQualiList.forEach((v) => {
        v.Attachment = {};
        v.index = qIdx++;
        if (v.AttachmentLink != null && v.AttachmentLink != "") {
            this.commonService.downloadAttachment(v.AttachmentLink, environment.GetFileExtension(v.AttachmentName), v.Attachment).subscribe((res) => {
              console.log(res)
            })
        }
      });
      this.showSelectedSection(1);
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    else 
      this.onCallAPIFail(result.body, result.status, result.headers, result)
  },err => {
    this.onCallAPIFail(err.body, err.status, err.headers, err)
  })
}

  showEditPopupContact() {
    var PersnlProfileForEdit = Object.assign([] ,this.PersnlProfile)
    const result = this.matDialog.open(PopupPersonalProfileContactComponent, {
      panelClass: 'dialog-responsive',
      data: PersnlProfileForEdit
    })
    result.afterClosed().subscribe(() => {
      this.getPersnlProfile()
    })
  }

  showEditPopupMarital() {
    var PersnlProfileForEdit = Object.assign([] ,this.PersnlProfile)
    PersnlProfileForEdit.attachments = [];
    const result = this.matDialog.open(PopupPersonalProfileMaritalComponent, {
      panelClass: 'dialog-responsive',
      data: PersnlProfileForEdit
    })
    result.afterClosed().subscribe(() => {
      this.getPersnlProfile()
    })
  }

  showEditPopupAddress() {
    let instance = this
    var PersnlAddressListForEdit = Object.assign([] ,this.PersnlAddressList)
    PersnlAddressListForEdit.attachments = []
    PersnlAddressListForEdit.forEach((v, k) => {
      v.uuid = instance.commonUI.genUUID()
      if (k == 0) v.onFocus = true;
      else v.onFocus = false;
      v.deleted = false;
      v.Action = "K";
      if (v.AttachmentLink != "" && v.AttachmentLink != undefined) {
          var attach = {
              uuid: v.uuid,
              uploaded: true,
              uploadFilename: v.AttachmentAddr,
              file: {
                  name: v.AttachmentName
              }
          };
          this.commonService.downloadAttachment(v.AttachmentLink, environment.GetFileExtension(v.AttachmentName), attach).subscribe((result) => {
            PersnlAddressListForEdit.attachments.push(result);
          }, err => {
            this.onCallAPIFail(err.body, err.status, err.headers, err)
          })
      }
    });
    this.matDialog.open(PopupPersonalProfileAddressComponent, {
      panelClass: 'dialog-responsive',
      data: {
        address: PersnlAddressListForEdit,
        addresstype: this.addressTypeList,
        area: this.areaList,
        district: this.districtList,
        quali: this.qualiTypeList,
        relation: this.relationshipList,
        identity: this.identityTypeList,
        displaySwitch: 'ppp-address'
      }
    })
  }

  showEditPopupBank() {
    this.commonUI.addLoadingTask();
    var PersnlBankAccForEdit = Object.assign([] ,this.PersnlBankAcc)
    this.personalService.getBankList().subscribe((result) => {
      this.commonUI.finishLoadingTask();
      this.bankList = result.body['Data'].BankList;
      this.bankList.forEach((v, k) => {
        if (v.Code == PersnlBankAccForEdit.BankCode) {
          this.selectedBankDesc = v.Desc;
        }
      })
      PersnlBankAccForEdit.EffectiveDate = this.commonUI.parseJsonDate(PersnlBankAccForEdit.EffectiveDate);
      PersnlBankAccForEdit.attachments = [];
      const dialog = this.matDialog.open(PopupPersonalProfileBankComponent, {
        panelClass: 'dialog-responsive',
        data: {
          obj: PersnlBankAccForEdit,
          bankList: this.bankList,
          selectedBankDesc: this.selectedBankDesc,
          displaySwitch: 'ppp-bank'
        }
      })
      dialog.afterClosed().subscribe(() => {
        this.getPersnlBankAccList()
      })
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  showEditPopupDependt() {
    var PersnlDependtListForEdit = Object.assign([], this.PersnlDependtList)
    PersnlDependtListForEdit.attachments = [];
    PersnlDependtListForEdit.forEach((record, key) => {
      record.uuid = this.commonUI.genUUID();
      if (key == 0) record.onFocus = true;
      else record.onFocus = false;
      record.EmploymentRID = this.employee.RID;
      record.Action = "K";  // I U D K
      record.Mobile = record.MobileNum1;
      record.Birthday = this.commonUI.isoDateStringToDate(record.Birthday);
      record.HKID = record.HKID;
      // record.Birthday = $scope.commonUI.parseJsonDate(record.Birthday).toDate();
      record.AttachmentName = "";
      record.AttachmentAddr = "";
      record.deleted = false;
    });
    const dialog = this.matDialog.open(PopupPersonalProfileDependtComponent, {
      panelClass: 'dialog-responsive',
      data: {
        obj: PersnlDependtListForEdit,
        relationshipList: this.relationshipList,
        identityTypeList: this.identityTypeList,
        displaySwitch: 'ppp-dependents'
      }
    })
    dialog.afterClosed().subscribe(() => {
      this.getPersnlDependtList()
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  showEditQualification() {
    var PersnlQualiListForEdit = Object.assign([], this.PersnlQualiList)
    PersnlQualiListForEdit.attachments = [];
    PersnlQualiListForEdit.forEach((v, k) => {
      v.uuid = this.commonUI.genUUID();
      if (k == 0) v.onFocus = true;
      else v.onFocus = false;
      v.isNotExpire = this.commonUI.parseJsonDate(v.ExpiryDate).toString() == "0001";
      v.EffectiveDateValue = this.commonUI.parseJsonDate(v.EffectiveDate);
      v.ExpiryDateValue = this.commonUI.parseJsonDate(v.ExpiryDate);
      v.StartPeriodValue = this.commonUI.parseJsonDate(v.StartPeriod);
      v.EndPeriodValue = this.commonUI.parseJsonDate(v.EndPeriod);
      v.ObtainedDateValue = this.commonUI.parseJsonDate(v.ObtainedDate);
      v.deleted = false;
      v.Action = "K";
      v
      if (v.AttachmentLink != "") {
          var attach = {
              uuid: v.uuid,
              uploaded: true,
              uploadFilename: v.AttachmentAddr,
              file: {
                  name: v.AttachmentName
              },
              dataURL: v.Attachment.dataURL
          };
          this.commonService.downloadAttachment(v.AttachmentLink, environment.GetFileExtension(v.AttachmentName), attach);
          PersnlQualiListForEdit.attachments.push(attach);
      }
    });

      const dialog = this.matDialog.open(PopupPersonalProfileQualiComponent, {
        panelClass: 'dialog-responsive',
        data: {
          obj: PersnlQualiListForEdit,
          qualiTypeList: this.qualiTypeList,
          displaySwitch: 'ppp-qualification'
        }
      })
      dialog.afterClosed().subscribe(() => {
        this.getPersnlQualiList()
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }


  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}