<!--============ popup : detail of Address ============ -->

<div id='Address' class="pp_address_content" style="display: block;">
	<div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_2column">
    	<div class="pop_functions_close btn_grey_2b pp_close" title="close" (click)="close()">x</div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
              <div class="box_title_icon t_address"></div>
              <div class="fl">{{ 'personalprofileinquiry.detailof' | translate }}{{ 'personalprofile.tabaddress' | translate }}</div>
        </div>

        <div class="d_box_top"></div>

        <div class="detail_box_bottom_area_profile mt0_m">

            <div class="box_content_inquiry">
            	<form id="form_application">

                    <div class="form_area" *ngIf='obj'>

                        <div *ngFor="let sub of obj.SubRecordList; let i=index">
                    	<!-- ========== a record =========== -->
                        <div id="form-{{i}}" class="form_content form_content_popup" (click)="slideUp($event)">

                        	<div class="form_content_title form_content_title-selected">{{ sub.AddressTypeDesc }}
                            	<span class="txt_label txt_label_profile_action color_label_update txt_size_3">{{ 'personalprofileinquiry.typeAction.' + sub.Action | translate }}</span>
                            </div>

                        	<div class="form_content_input">
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.effectivedate' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(sub.EffectiveDate, 'DD MMM YYYY')}}&nbsp;<span class="txt_size_3"> {{commonUI.formatDisplayDate(sub.EffectiveDate, '(ddd)')}}&nbsp;</span>
                                </div>
                                <div class="full_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.address' | translate }}</span><br>
                                    {{ sub.Address }}&nbsp;
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.district' | translate }}</span><br>
                                    {{ sub.DistrictDesc }}&nbsp;
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.area' | translate }}</span><br>
                                    {{ sub.AreaDesc }}&nbsp;
                                </div>
                                <div class="full_line" *ngIf="sub.AttachmentLink">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.attachment' | translate }}</span><br>
                                        <div class="attachment_photo">
                                            <div><img [src]="sub.attachment.dataURL"></div>
                                            <div id="dl_attach" class="attachment_photo_touch" (click)="commonHandler.saveAttachment(sub.AttachmentLink, sub.AttachmentName)"></div>
                                        </div>
                                    <div style="clear:both"></div>
                                </div>
                                <div style="clear:both"></div>
                            </div> <!-- ========== end : form_content_input =========== -->
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                        </div> <!-- ========== end : form_content =========== -->
                        </div>



                    </div> <!-- ========== end : form_area =========== -->

            	</form>
            </div> <!-- ========== end : form_content_inquiry =========== -->
		</div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

	</div>
</div>
