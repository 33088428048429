import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { InterceptorService } from './interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService) { }

  getPaySlipYearList(employee) {
    var body = {
      "Action": environment.apiList.GetPaySlipYearList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.PaySlipYearList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPaySlipYearList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getPaySlipList(employee, year) {

    var body = {
      "Action": environment.apiList.GetPaySlipList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Year": year
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.PaySlipList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPaySlipList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "Year": year
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getPaySlip(employee, year, month, ext, criteria) {

    var body = {
      "Action": environment.apiList.GetPaySlip,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Year": year,
      "Month": month,
      "Ext": ext,
      "Criteria": JSON.stringify(criteria)
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.AttachmentPath);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetPaySlip,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "Year": year,
    //         "Month": month,
    //         "Ext": ext,
    //         "Criteria": JSON.stringify(criteria)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getTaxFormList(employee) {

    var body = {
      "Action": environment.apiList.GetTaxFormList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.TaxFormList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetTaxFormList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getTaxForm(employee, year, criteria) {

    var body = {
      "Action": environment.apiList.GetTaxForm,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Year": year,
      "Criteria": JSON.stringify(criteria)
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.AttachmentPath);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetTaxForm,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "Year": year,
    //         "Criteria": JSON.stringify(criteria)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getTaxSummaryReport(employee, year, criteria) {

    var body = {
      "Action": environment.apiList.GetTaxSummaryReport,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Year": year,
      "Criteria": JSON.stringify(criteria)
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
  }

  getLeaveBalanceReport(employee, leaveTypeCode, asAtDate,
    approvalGroupList, isTeammateViewSelected, criteria) {
    var body = {
        "Action": environment.apiList.GetLeaveBalanceReport,
        "SessionID": sessionStorage.SessionID,
        "EmploymentRID": employee.RID,
        "LeaveTypeCode": leaveTypeCode,
        "AsAtDate": asAtDate,
        "ApprovalGroupList": (approvalGroupList.length != 0) ? JSON.stringify(approvalGroupList) : "",
        "IsTeammateViewSelected": isTeammateViewSelected,
        "Criteria": JSON.stringify(criteria)
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetLeaveBalanceReport,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "AsAtDate": asAtDate,
    //         "ApprovalGroupList": (approvalGroupList.length != 0) ? JSON.stringify(approvalGroupList) : "",
    //         "IsTeammateViewSelected": isTeammateViewSelected,
    //         "Criteria": JSON.stringify(criteria)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );

  }

  getApprovalGroupList(employee) {

    var result
    if (sessionStorage.StaffInfo != undefined) {
        result = JSON.parse(sessionStorage.ApprovalGroupList);
    } else {
        var body = {
            "Action": environment.apiList.GetApprovalGroupList,
            "SessionID": sessionStorage.SessionID,
            "EmploymentRID": employee.RID
        }
        result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
            res['conn_fail'] = false
            return res.body['Data'].ApprovalGroupList
        }, err => {
            err['conn_fail'] = true
            return err.body
        }))
    }
    return result

    // var service = this;
    // if (sessionStorage.ApprovalGroupList != undefined) {
    //     onSuccessCallback(service, JSON.parse(sessionStorage.ApprovalGroupList));
    //     return;
    // }

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         sessionStorage.ApprovalGroupList = JSON.stringify(data.Data.ApprovalGroupList);
    //         onSuccessCallback(service, data.Data.ApprovalGroupList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetApprovalGroupList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
}
}
