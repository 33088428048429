<div>

    <div id="timeoutdialog">
        <div class="pop_functions_box pop_functions_box_size_1 ng-binding">
            <br> <img src="assets/img/msg_alert.png" width="48"> <br> <br>
            {{ 'idle.warningcountdown' | translate }} <span class="maincolor_txt txt_size_1">{{countdown}}</span> {{ 'idle.seconds' | translate }}.<br>
            {{ 'idle.warningsignedin' | translate }} <br> <br>
            <div class="pop_functions_two_btn_center">
                <a id="btnIdleLogout" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="idleLogout()"> {{ 'idle.logout' | translate }} </a>
                <a id="btnIdleCancel" class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="idleCancel()"> {{ 'idle.keepsignedin' | translate }} </a>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>

</div>