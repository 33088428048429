<div id="popup_setting" class="pp4_content">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_1column">
        <div class="pop_functions_close btn_grey_2b pp_close" (click)="closePopup()">x</div>
        <div style="clear: both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl" *ngIf="selectedIndex == -1">{{ 'delegationsetting.title' | translate }}</div>
            <div class="fl" *ngIf="selectedIndex != -1">{{ 'delegationsetting.editof' | translate }}</div>
        </div>
        <br />
        <div class="confirm_application_position txt_size_1">

            <div class="txt_grey_2 txt_size_3 fl">{{ 'delegationsetting.staffid' | translate }}&nbsp;</div>
            <br />
            <div style="clear: both"></div>

            <div class="form_content_input_field">
                <input required class="form-control input_profile_singleline txt_box_1 bg_none txt_grey_1 txt_size_2" style="text-transform:uppercase" [(ngModel)]="currentSetting.StaffID">
            </div>
            <br />
            <div style="clear: both"></div>

            <div class="txt_grey_2 txt_size_3 fl">{{ 'delegationsetting.delegationperiod' | translate }}&nbsp;</div>
            <br/>
            <div style="clear: both"></div>                

            <div class="fl txt_data_confirm">
                <div class="fl input_new_calendar txt_size_2">
                    <div>
                        <mat-form-field appearance='fill' (click)="effStartPicker.open()">
                            <input matInput [matDatepicker]="effStartPicker" [(ngModel)]="currentSetting.EffStart" readonly (dateInput)="dateValidator('eff_start')">
                            <mat-datepicker-toggle matSuffix [for]="effStartPicker"></mat-datepicker-toggle>
                            <mat-datepicker #effStartPicker (opened)="openAppend('eff_start')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #effStartFooter>
                                <button mat-raised-button  (click)="today('eff_start')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('eff_start')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                        <!-- <am-date-picker [(ngModel)]="currentSetting.EffStart" am-show-input-icon="true" am-allow-clear="false"  am-place-holder="delegationsetting.startdate" /> -->
                    </div>
                </div>
            </div>
            <br />
            <div style="clear: both"></div>

            <div class="fl txt_data_confirm">
                <div class="fl input_new_calendar txt_size_2">
                    <div>
                        <mat-form-field appearance='fill' (click)="effEndPicker.open()">
                            <input matInput [matDatepicker]="effEndPicker" [(ngModel)]="currentSetting.EffEnd" readonly (dateInput)="dateValidator('eff_end')">
                            <mat-datepicker-toggle matSuffix [for]="effEndPicker"></mat-datepicker-toggle>
                            <mat-datepicker #effEndPicker (opened)="openAppend('eff_end')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #effEndFooter>
                                <button mat-raised-button  (click)="today('eff_end')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('eff_end')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                        <!-- <am-date-picker [(ngModel)]="currentSetting.EffEnd" am-show-input-icon="true" am-allow-clear="false"  am-place-holder="delegationsetting.enddate" /> -->
                    </div>
                </div>
            </div>
            <br />
            <div style="clear: both"></div>

            <br />
            <div class="txt_grey_2 txt_size_3 fl">{{ 'delegationsetting.accessibleleaveperiod' | translate }}&nbsp;</div>
            <br />
            <div style="clear: both"></div>

            <div class="fl txt_data_confirm">
                <div class="fl input_new_calendar txt_size_2">
                    <div>
                        <mat-form-field appearance='fill' (click)="leaveStartPicker.open()">
                            <input matInput [matDatepicker]="leaveStartPicker" [(ngModel)]="currentSetting.LeaveStart" readonly (dateInput)="dateValidator('leave_start')">
                            <mat-datepicker-toggle matSuffix [for]="leaveStartPicker"></mat-datepicker-toggle>
                            <mat-datepicker #leaveStartPicker (opened)="openAppend('leave_start')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #leaveStartFooter>
                                <button mat-raised-button  (click)="today('leave_start')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('leave_start')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                        <!-- <am-date-picker [(ngModel)]="currentSetting.LeaveStart" am-show-input-icon="true" am-allow-clear="false" am-place-holder="delegationsetting.startdate" /> -->
                    </div>
                </div>
            </div>
            <br />
            <div style="clear: both"></div>

            <div class="fl txt_data_confirm">
                <div class="fl input_new_calendar txt_size_2">
                    <div>
                        <mat-form-field appearance='fill' (click)="leaveEndPicker.open()">
                            <input matInput [matDatepicker]="leaveEndPicker" [(ngModel)]="currentSetting.LeaveEnd" readonly (dateInput)="dateValidator('leave_end')">
                            <mat-datepicker-toggle matSuffix [for]="leaveEndPicker"></mat-datepicker-toggle>
                            <mat-datepicker #leaveEndPicker (opened)="openAppend('leave_end')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #leaveEndFooter>
                                <button mat-raised-button  (click)="today('leave_end')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('leave_end')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                        <!-- <am-date-picker [(ngModel)]="currentSetting.LeaveEnd" am-show-input-icon="true" am-allow-clear="false" am-place-holder="delegationsetting.enddate" /> -->
                    </div>
                </div>
            </div>
            <br />
            <div style="clear: both"></div>
        </div>
        <br />

        <br />
        <hr class="hr_preview" />
        <div class="pop_functions_two_btn_center" *ngIf="selectedIndex != -1">
            <!--class="two_btn_fixed_bottom"-->
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish" (click)="showPreviewSetting()" [disabled]="isSettingInvalid()"><img src="assets/img/btn_tick_w.png" width="18">{{ "submit" | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_red flag_submit_finish ui-link" (click)="confirmDeleteSetting()"><img src="assets/img/btn_del_doc.png" width="18">{{ "delete" | translate }}</button>
            <div style="clear: both"></div>
        </div>
        <div class="pop_functions_two_btn_center" *ngIf="selectedIndex == -1">
            <!--class="two_btn_fixed_bottom"-->
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish" (click)="showPreviewSetting()" [disabled]="isSettingInvalid()"><img src="assets/img/btn_tick_w.png" width="18">{{ "submit" | translate }}</button>
            <div style="clear: both"></div>
        </div>
    </div>
</div>