import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { LeaveService } from '../services/leave.service';
import { PopupDetailOfCheckBalanceInquiryComponent } from '../common/popup-detail-of-check-balance-inquiry/popup-detail-of-check-balance-inquiry.component'
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IdleTimerService } from '../services/idle-timer.service';

declare var $: any;

@Component({
  selector: 'app-leave-balance-inquiry',
  templateUrl: './leave-balance-inquiry.component.html',
  styleUrls: ['./leave-balance-inquiry.component.scss']
})
export class LeaveBalanceInquiryComponent implements OnInit {

  @ViewChild('balanceFooter', { static: false }) balanceFooter: ElementRef;
  @ViewChild('balancePicker', { static: false }) balancePicker: MatDatepicker<any>;
  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  title = "LeaveBalanceInquiry";
  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  StaffInfo;
  leaveBalance;

  defaultLeaveType = "AL";
  // $scope.leaveType = "AL";
  // $scope.leaveYear = new Date();
  asAtDay = new Date();
  inquiryAsAtDate = new Date();
  // $scope.leaveYearList = [];
  leaveBalanceList = [];
  resultBalanceList = [];
  // $scope.staffLeaveBalance;
  isTeammateViewSelected = false;

  search = {
    keywords: ''
  }

  leaveType

  leaveTypeList

  refreshType = "";
  refreshDesc = "";

  constructor(public idleTimer: IdleTimerService, public commonUI: CommonUIService, public commonService: CommonServiceService, public matDialog: MatDialog, public leaveService: LeaveService, public translate: TranslateService) { 
    this.translate.onLangChange.subscribe(() => {
      this.asAtDay = moment(this.asAtDay).toDate();
    })
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.getLeaveTypeList()
  }

  /* Datepicker Footer */

  today() {
    this.asAtDay = new Date()
    this.balancePicker.close()
  }

  close() {
    this.balancePicker.close()
  }

  openAppend() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.balanceFooter.nativeElement);
  }

  /* *************** */

  propertyName = "currentYearDetail.AvailableBalance";
  reverse = true;
  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch(propertyName) {
          case 'StaffInfo.FullName':
              this.leaveBalanceList = this.leaveBalanceList.sort((a,b) => {
                  return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
              })
              break;
          case 'StaffInfo.PositionCode':
              this.leaveBalanceList = this.leaveBalanceList.sort((a,b) => {
                return a.StaffInfo.PositionCode.localeCompare(b.StaffInfo.PositionCode)
              })
              break;
          case 'currentYearDetail.AvailableBalance':
              this.leaveBalanceList = this.leaveBalanceList.sort((a,b) => {
                return a.currentYearDetail.AvailableBalance.localeCompare(b.currentYearDetail.AvailableBalance)
              })
              break;
    }
    if(this.reverse) this.leaveBalanceList = this.leaveBalanceList.slice().reverse()
    this.toggleSortMenu()
}

isChangedTeamView() {
    //if(this.isTeammateViewSelected) {
      this.getLeaveTypeList()
    //}
}

  prepareListInfo() {
    this.leaveBalanceList.forEach((balance) => {
      balance.keywords = "";
      balance.keywords += balance.StaffInfo.StaffID + " ";
      balance.keywords += balance.StaffInfo.FullName + " ";
      balance.keywords += balance.StaffInfo.PositionDesc + " ";
      balance.keywords += balance.currentYearDetail.AvailableBalance + " ";
    });
  }

  toggleSortMenu() {
    $("#p4_content").fadeToggle();
  }

  updateRefreshTypePair(leaveTypeCode) {

    let currTypeDesc = "";
    let index = this.leaveTypeList.findIndex(x => x.Code == leaveTypeCode);
    if(index >= 0)
      currTypeDesc = this.leaveTypeList[index].Desc;


    this.refreshType = leaveTypeCode;
    this.refreshDesc = currTypeDesc;
  }

  showSearchResult() {
    let instance = this
    this.commonUI.addLoadingTask();
    if (this.isTeammateViewSelected) {
      this.leaveService.calTeamLeaveBalance(this.employee, this.leaveType,
        this.commonUI.formatJsonDate(this.asAtDay, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
          if (result.body['ErrorMsg'] == null) {
            
            this.leaveBalanceList = result.body['Data'].LeaveBalance;
            this.prepareListInfo();
            this.resultBalanceList = this.leaveBalanceList
            this.updateRefreshTypePair(this.leaveType);
            $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", function () {
              instance.commonUI.finishLoadingTask();
              $('html, body').animate({
                scrollTop: $(".list_result_area").offset().top
              }, 1000);
            });
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
    } else {
      this.leaveService.calSelfLeaveBalance(this.employee, this.leaveType,
        this.commonUI.formatJsonDate(this.asAtDay, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
          if (result.body['ErrorMsg'] == null) {
            this.leaveBalanceList = [];
            this.leaveBalanceList.push(result.body['Data'].LeaveBalance);
            this.prepareListInfo();
            this.resultBalanceList = this.leaveBalanceList
            this.updateRefreshTypePair(this.leaveType);

            $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", function () {
              instance.commonUI.finishLoadingTask();
              $('html, body').animate({
                scrollTop: $(".list_result_area").offset().top
              }, 1000);
            });
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
    }

    this.inquiryAsAtDate = this.asAtDay

  }

  resetSearchResult() {
    this.hideSearchResult();
    // $scope.leaveType = "AL";
    this.leaveBalance = [];
    this.asAtDay = new Date();
    this.isTeammateViewSelected = false;
    // $scope.leaveYear = moment(new Date()).format("YYYY");
  }

  getLeaveTypeList() {
    this.commonUI.addLoadingTask();

    this.leaveTypeList = [];
    this.leaveType = "";
    if (this.isTeammateViewSelected)
      this.leaveService.getLeaveTypeList(null).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.leaveTypeList = result.body['Data'].LeaveTypeList;
          this.leaveTypeList = this.leaveTypeList.filter((l) => l.EnableBalInqESS == 'Y')
          this.leaveType = this.defaultLeaveType;
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    else
      this.leaveService.getLeaveTypeList(this.employee).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.leaveTypeList = result.body['Data'].LeaveTypeList;
          this.leaveTypeList = this.leaveTypeList.filter((l) => l.EnableBalInqESS == 'Y')
          this.leaveType = this.defaultLeaveType;
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  /*
      $scope.getLeaveYearList = function () {
          $scope.leaveYearList = [];
          var startYear = parseInt($scope.leaveYear) - 2;
          var endYear = parseInt($scope.leaveYear) + 2;
          for (var y = startYear; y <= endYear; y++) {
              $scope.leaveYearList.push(y);
          }
      }
  */
  hideSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();
  }

  showLeaveBalance(staffLeaveBalance) {
    //$scope.staffLeaveBalance = staffLeaveBalance;
    this.leaveBalance = staffLeaveBalance;
    const dialog = this.matDialog.open(PopupDetailOfCheckBalanceInquiryComponent, {
      data: {
        lb: this.leaveBalance,
        asAtDate: this.asAtDay,
        ap: undefined,
        staffInfo: this.leaveBalance.StaffInfo,
        ltc: this.refreshDesc
      },
      panelClass: 'dialog-responsive'
    })

    dialog.afterClosed().subscribe(data => {
      if (data == null || data == undefined) return;
      this.asAtDay = data
    })

    // $("#popup_BalanceDetail").fadeIn();
  }

  // hideLeaveBalance = function () {
  //     $("#popup_BalanceDetail").fadeOut();
  // }

  // $scope.$watch('isTeammateViewSelected', function (newVal, oldVal) {
  //     $scope.getLeaveTypeList();
  // });

  asAtCutOffDate() {
    this.commonUI.addLoadingTask();
    this.leaveService.getLeaveCutOffDate(this.employee, this.leaveType, this.asAtDay).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.asAtDay = this.commonUI.isoDateStringToDate(result.body['Data'].CutOffDate);
        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  searchByInput() {
    if(this.search.keywords.length <= 0) return this.leaveBalanceList = this.resultBalanceList
    else this.leaveBalanceList = this.resultBalanceList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}


