import { Component, Input, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ApprovalService } from '../services/approval.service';
import { CommonServiceService } from '../services/common-service.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { IdleTimerService } from '../services/idle-timer.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  lastLoginString = ''
  title = "Dashboard";
  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  sourceTypeList = this.commonService.SourceTypeList.slice();
  dispalyApprovalBox = false;
  dispalyDelegationApprovalBox = false;
  totalCount = 0;
  reloadPendingButtonReady = false;
  reloadDelegationPendingButtonReady = false;
  loadCounterForPending = 0;
  loadCounterForDelegationPending = 0;
  PasswordPolicy = JSON.parse(sessionStorage.PasswordPolicy);
  language = localStorage.CurrentLanguage !== undefined ? localStorage.CurrentLanguage : "en";
  outstandingApprClass = ["box_1", "box_h_2"];
  displaySECMSApprovalBox = false;
  lastRefreshTime;
  totalDelegationCount = 0
  lastDelegationRefreshTime;
  math = Math

  constructor(public commonService: CommonServiceService, public translate: TranslateService, public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public approvalService: ApprovalService, public idleTimer: IdleTimerService) {
    this.translate.onLangChange.subscribe((lang) => {
      this.language = lang.lang
    })
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);

    this.getLastLogin();
    this.checkAvailableModule();
  }

  introImageList = ["intro_1.png", "intro_2.png", "intro_3.png", "intro_4.png", "intro_5.png", "intro_6.png"];
  currentIntroImage = 0;
  translate_px = 0;
  mytransform = "translate(" + this.translate_px + "px, 0px)";

  goToPrevIntro = function () {
    if (this.currentIntroImage <= 0) return;
    this.currentIntroImage = this.currentIntroImage - 1;

    this.translate_px = (this.currentIntroImage * -280);
    this.mytransform = "translate(" + this.translate_px + "px, 0px)";
  }

  goToNextIntro = function () {
    if (this.currentIntroImage >= this.introImageList.length - 1) return;
    this.currentIntroImage = this.currentIntroImage + 1;

    this.translate_px = (this.currentIntroImage * -280);
    this.mytransform = "translate(" + this.translate_px + "px, 0px)";
  }

  getLastLogin() {
    if (sessionStorage.LastLogin != undefined) {
      this.lastLoginString = this.commonUI.formatDisplayDate(JSON.parse(sessionStorage.LastLogin), 'D MMM YYYY (ddd) - hh:mm A');
    } else {
      this.commonService.getLastLogin(this.employee).subscribe((result) => {
        if (result.body['ErrorMsg'] == null)
          if (result.body['Data'].LastLogin == "") this.lastLoginString = "-";
          else this.lastLoginString = this.commonUI.formatDisplayDate(result.body['Data'].LastLogin, 'D MMM YYYY (ddd) - hh:mm A');
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
    // this.commonUI.addLoadingTask();
  }
  gotoPendingApproval(sourcetypeCode) {
    // $location.path("ess/approval-pending-approval").search('type', sourceTypeCode);
    this.router.navigate(['/ess/approval-center-pending-approval'], {queryParams: {source: sourcetypeCode}})
  }
  gotoDelegationPendingApproval(sourcetypeCode) {
    // $location.path("ess/delegation-pending-approval").search('type', sourceTypeCode);
    this.router.navigate(['/ess/delegation-pending-approval'], {queryParams: {source: sourcetypeCode}})
  }
  // gotoSECMSPendingApproval() {
  //   $window.location.href = new $window.URL($location.absUrl()).origin + "/SECMS/#/ess/pending-approval";
  // }

  checkAvailableModule() {
    this.commonService.getModule(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        for (var i = 0; i < this.sourceTypeList.length; i++) {
          if (result.body['Data'].Module.indexOf(this.sourceTypeList[i].module) == -1) {
            this.sourceTypeList.splice(i, 1);
            i = -1;
          }
        }
        if (this.sourceTypeList.length > 0) {
          this.dispalyApprovalBox = true;
          if (this.sourceTypeList.length > 4)
            this.outstandingApprClass.push("box_1_full");
        }
        this.getPendingList();

        if (result.body['Data'].Module.indexOf("DelegationApv") >= 0) {
          this.dispalyDelegationApprovalBox = true;
          this.getDelegationPendingList();
        }
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  reloadPending() {
    if (this.reloadPendingButtonReady) {
      this.reloadPendingButtonReady = false;
      this.getPendingList();
    }
  }

  reloadDelegationPending() {
    if (this.reloadDelegationPendingButtonReady) {
      this.reloadDelegationPendingButtonReady = false;
      this.getDelegationPendingList();
    }
  }

  getPendingList() {
    this.totalCount = 0;
    this.sourceTypeList.forEach((sourceType, idx) => {
      // this.commonUI.addLoadingTask();
      this.loadCounterForPending++;
      sourceType.numOfPending = 0;
      this.approvalService.getPendingApprovalList(this.employee, sourceType.sourceTypeCode).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          if (result.body['Data'].ApprovalList.length > 0) {

            this.sourceTypeList.forEach((sourceType, key) => {
              if(result.body['Data'].ApprovalList[0].SourceType == sourceType.sourceTypeCode) {
                sourceType.numOfPending = result.body['Data'].ApprovalList.length;
                this.totalCount += sourceType.numOfPending;
              }
            })
          }
          this.lastRefreshTime = this.commonUI.formatDisplayDate(new Date(), 'D MMM YYYY (ddd) - hh:mm A');

          this.reloadPendingButtonReady = true;
          this.loadCounterForPending--;
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    })

    // if (sessionStorage.IsSECMSEnable == "Y") {
    //     this.checkSECMSAvailableModule();
    // }
  }

  getDelegationPendingList() {
    this.totalDelegationCount = 0;
    this.loadCounterForDelegationPending++;
    this.approvalService.getDelegationPendingApprovalList(this.employee, "ELEAVE").subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        if (result.body['Data'].ApprovalList.length > 0) this.totalDelegationCount = result.body['Data'].ApprovalList.length;
        this.lastDelegationRefreshTime = this.commonUI.formatDisplayDate(new Date(), 'D MMM YYYY (ddd) - hh:mm A');

        this.reloadDelegationPendingButtonReady = true;
        this.loadCounterForDelegationPending--;
      }
      else {
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }


  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  onCallLoginAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
    //this.router.navigateByUrl('/login')  }
  }
}