import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { PopupInoutEditComponent } from '../common/popup-inout-edit/popup-inout-edit.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { InOutService } from '../services/in-out.service';

@Component({
  selector: 'app-in-out',
  templateUrl: './in-out.component.html',
  styleUrls: ['./in-out.component.scss']
})
export class InOutComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  employee = JSON.parse(sessionStorage.Employee);
  Date = moment().toDate();
  siteDetailList;
  staffInfo;
  shiftDetailList = [];
  staffPunchDetailList = [];
  rawPunchDetailList = [];
  adjustedPunchDetailList = [];

  inOutTimeEditPopupControl = {};

  editPunch = {};
  noAttendance = false;
  defaultSiteDetailListIndex = 0;
  selectedShiftDetail;

  inOutTimeApplication = {
    "EmploymentRID": this.employee.RID,
    "ShiftCode": "",
    "Date": "",
    "Description": "",
    "orgPunchList": {},
    "newPunchList": {},
    "ShiftStart": {},
    "ShiftEnd": {},
    "CutOffStart": {},
    "CutOffEnd": {}
  };

  constructor(public commonService: CommonServiceService, public inoutService: InOutService, public commonUI: CommonUIService, public errorUI: ErrorUIService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);

    this.commonUI.addLoadingTask();
    this.getStaffInfo();
    this.commonUI.finishLoadingTask();
  }

  resetApplication() {
    this.inOutTimeApplication = {
      "EmploymentRID": this.employee.RID,
      "ShiftCode": "",
      "Date": "",
      "Description": "",
      "orgPunchList": {},
      "newPunchList": {},
      "ShiftStart": {},
      "ShiftEnd": {},
      "CutOffStart": {},
      "CutOffEnd": {}
    };

    this.Date = moment().toDate();
    this.staffPunchDetailList = [];
    this.rawPunchDetailList = [];
    this.adjustedPunchDetailList = [];
    this.getShiftDetailList();
  }

  getStaffInfo() {
    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfo(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.staffInfo = result.body['Data'].StaffInfo;
        this.commonUI.finishLoadingTask();
        this.getShiftDetailList();
        this.getSiteDetailList();
      }

      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getShiftDetailList() {
    this.commonUI.addLoadingTask();
    this.inoutService.getShiftDetailList(this.employee, this.commonUI.formatJsonDate(this.Date, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
      if (result.body['ErrorMsg'] == null)
        this.onGetShiftDetailListSuccess(result.body['Data'].ShiftDetailList);
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onGetShiftDetailListSuccess(shiftDetailList) {
    if (shiftDetailList.length > 0) {
      this.noAttendance = false;
      this.shiftDetailList = shiftDetailList;
      this.inOutTimeApplication.ShiftCode = this.shiftDetailList[0].Code;
      this.inOutTimeApplication.ShiftStart = this.shiftDetailList[0].ShiftInTime;
      this.inOutTimeApplication.ShiftEnd = this.shiftDetailList[0].ShiftOutTime;
      this.inOutTimeApplication.CutOffStart = this.shiftDetailList[0].CutOffStartTime;
      this.inOutTimeApplication.CutOffEnd = this.shiftDetailList[0].CutOffEndTime;
    }
    else {
      this.errorUI.showErrorBox("A6003");
      this.noAttendance = true;
    }
    this.UpdateSelectedShiftDetail();

    this.checkPunchRecords();

    this.commonUI.finishLoadingTask();
  }

  getSiteDetailList() {
    this.commonUI.addLoadingTask();
    this.commonService.getSiteDetailList().subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.siteDetailList = result.body['Data'].SiteDetailList;
        var defaultSiteIndex = 0;
        if (this.staffInfo.SiteCode) {
          for (var i = 0; i < this.siteDetailList.length; i++) {
            if (this.siteDetailList[i].Code == this.staffInfo.SiteCode) {
              defaultSiteIndex = i;
              break;
            }
          }
        }
        this.defaultSiteDetailListIndex = defaultSiteIndex;

        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  UpdateSelectedShiftDetail() {
    this.selectedShiftDetail = this.shiftDetailList[0];
    for (var i = 1; i < this.shiftDetailList.length; i++) {
      if (this.shiftDetailList[i].Code == this.inOutTimeApplication.ShiftCode) {
        this.selectedShiftDetail = this.shiftDetailList[i];
        break;
      }
    }
  }

  checkPunchRecords() {
    this.commonUI.addLoadingTask();

    var startDate = this.commonUI.formatJsonDate(this.Date, "YYYY-MM-DDT00:00:00");
    var endDate = this.commonUI.formatJsonDate(this.Date, "YYYY-MM-DDT23:59:59");

    this.inoutService.getStaffPunchDetailList(this.employee, startDate, endDate).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) this.onGetStaffPunchDetailListSuccess(result.body['Data'].ShiftDetailList);
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    this.inoutService.getRawPunchDetailList(this.employee, startDate, endDate).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) this.onGetRawPunchDetailListSuccess(result.body['Data'].ShiftDetailList);
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onGetStaffPunchDetailListSuccess(shiftDetailList) {
    this.staffPunchDetailList = JSON.parse(JSON.stringify(shiftDetailList));
    this.adjustedPunchDetailList = JSON.parse(JSON.stringify(shiftDetailList));
    this.commonUI.finishLoadingTask();
  }

  onGetRawPunchDetailListSuccess(shiftDetailList) {
    this.rawPunchDetailList = shiftDetailList;
    this.commonUI.finishLoadingTask();
  }

  showEditPunchPopup(punch?, index?) {
    // this.inOutTimeEditPopupControl.showPopup(this.Date, punch, index, this.siteDetailList, this.defaultSiteDetailListIndex);
    const dialog = this.matDialog.open(PopupInoutEditComponent, {
      panelClass: 'responsive-dialog',
      data: {
        employee: this.employee,
        date: this.Date,
        punch: punch ? punch : undefined,
        index: index || index > -1 ? index : undefined,
        siteDetailList: this.siteDetailList,
        defaultSiteDetailListIndex: this.defaultSiteDetailListIndex
      }
    })

    dialog.afterClosed().subscribe((data) => {
      if (data == null || data == undefined) return;
      var punchDetail = data;
      if (data.Mode == 'confirm') {
        if (punchDetail.Index == undefined)
          this.adjustedPunchDetailList.push(punchDetail.Punch);
        else
          this.adjustedPunchDetailList[punchDetail.Index] = punchDetail.Punch;
        this.adjustedPunchDetailList = this.adjustedPunchDetailList.sort((a, b) => a.PunchStr.localeCompare(b.PunchStr));
      } else if (data.Mode == 'delete') {
        this.adjustedPunchDetailList.splice(punchDetail.Index, 1);
      }
    })
  }

  submitEInOutApplication() {
    this.inOutTimeApplication.Date = this.commonUI.formatJsonDate(this.Date, "YYYY-MM-DDT00:00:00");
    this.inOutTimeApplication.ShiftStart = this.shiftDetailList.filter(obj => obj.Code === this.inOutTimeApplication.ShiftCode)[0].ShiftInTime;
    this.inOutTimeApplication.ShiftEnd = this.shiftDetailList.filter(obj => obj.Code === this.inOutTimeApplication.ShiftCode)[0].ShiftOutTime;
    this.inOutTimeApplication.CutOffStart = this.shiftDetailList.filter(obj => obj.Code === this.inOutTimeApplication.ShiftCode)[0].CutOffStartTime;
    this.inOutTimeApplication.CutOffEnd = this.shiftDetailList.filter(obj => obj.Code === this.inOutTimeApplication.ShiftCode)[0].CutOffEndTime;

    this.inOutTimeApplication.orgPunchList = this.staffPunchDetailList;
    this.inOutTimeApplication.newPunchList = this.adjustedPunchDetailList;

    if (this.Equal(this.inOutTimeApplication.orgPunchList, this.inOutTimeApplication.newPunchList)) {
      this.commonUI.showMsgBox("message.M0026");
      return;
    }
    // if (angular.equals(this.inOutTimeApplication.orgPunchList, this.inOutTimeApplication.newPunchList)) {
    //     this.commonUI.showMsgBox("message.M0026");
    //     return;
    // }

    this.commonUI.addLoadingTask();
    this.inoutService.submitEInOutApplication(this.employee, this.inOutTimeApplication).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.commonUI.finishLoadingTask();
        this.resetApplication();
        this.commonUI.showMsgBox("message.M0001");
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.Date = new Date()
        instance.startPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
    }
  }

  /*******************/

  Equal(list1, list2) {
    let result = list1.filter(l1 => !list2.some(l2 => l2.PunchDateTime === l1.PunchDateTime)); // l1 have, l2 dont have
    if (result) return false;
    return true;
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
