<div id="content_punch_inquiry">

    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'punchinquiry.inouttime' | translate }}</span>
    </div>

    <!-- =========== Search ========== -->

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_punch_inquiry"></div>
            <div class="fl">{{ 'punchinquiry.punchinquiry' | translate }}</div>
        </div>

        <div class="box_content_inquiry">

            <div class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'punchinquiry.daterange' | translate }}</div>

                <div class="fl mr input_new_calendar">
                    <div>
                        <mat-form-field appearance='fill' (click)="startPicker.open()">
                            <input matInput [matDatepicker]="startPicker" [(ngModel)]="punchDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('start')">
                            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                            <mat-datepicker #startPicker (opened)="openAppend('start')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #startFooter>
                                <button mat-raised-button  (click)="today('start')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('start')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="date_to_line"></div>
                <div class="fl input_new_calendar">
                    <div>
                        <mat-form-field appearance='fill' (click)="endPicker.open()">
                            <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('end')">
                            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                            <mat-datepicker #endPicker (opened)="openAppend('end')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #endFooter>
                                <button mat-raised-button  (click)="today('end')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('end')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="box_content_inquiry_field">
                <div class="btn_sub_title txt_grey_2">{{ 'punchinquiry.view' | translate }}</div>
                <div class="onoffswitch" attr.isteam="{{isTeammateViewSelected}}">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" [(ngModel)]="isTeammateViewSelected" checked>
                    <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner" attr.data-content-before="{{ 'punchinquiry.subordinate' | translate }}" attr.data-content-after="{{ 'punchinquiry.personal' | translate }}"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </div>

            <div style="clear: both"></div>
            <hr>

            <div class="inquiry_box_two_btn_center">
                <button id="btn_inquiry_search" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="showSearchResult()"><img src="assets/img/btn_search_a.png" width="18" />{{ 'search' | translate }}</button>
                <button id="btn_inquiry_reset" class="btn btn-block btn-lg two_btn btn_grey" (click)="resetSearchResult()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}</button>
                <div style="clear:both;"></div>
            </div>

        </div>






        <!-- ============ end : content_box ============ -->
    </div>



    <!-- =========== Result ========== -->
    <a name="list_result" class="list_bar_mt" id="anchor_list_result"></a>
    <div class="list_result_area content_box" id="listResult">



        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_leaveapplicationinquiry"></div>
            <div class="fl">{{ 'punchinquiry.punchinquiry' | translate }}</div>
        </div>

        <!--
        <div class="txt_result">
            <div class="txt_result_title txt_grey_2">Result :</div>
            <div class="txt_result_item_area">
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">10 Dec 2016<span class="txt_size_3">&nbsp;(Fri)&nbsp;-&nbsp;</span>17 Dec 2016<span class="txt_size_3">&nbsp;(Mon)</span></div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">All Leaves</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">All Status</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">Teammate</div>
                </a>
            </div>
        </div>
        -->
        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">
            <div class="btn_small_cross_back" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_arrow_back.png" width="12" (click)="hideSearchResult()"></div>
            <div class="header_bar_icon fl list_icon_border mt0 ml0 btn_filter_inquiry" title="{{ 'punchinquiry.backtofiltertochangeinquiry' | translate }}" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_filter.png" width="20" /></div>


            <div id="p4" class="header_bar_icon fl list_icon_border mt0" title="{{ 'punchinquiry.sorts' | translate }}" (click)="toggleSortMenu()"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list">
                <div class="alert_box_arrow arrow_position_4"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'punchinquiry.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <div class="alert_box_content_item" id="sortItem_0" (click)="sortByType('StaffInfo.FullName')">{{ 'punchinquiry.sortbyname' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_1" (click)="sortByType('StaffInfo.PositionDesc')">{{ 'punchinquiry.sortbypositiondesc' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_2" (click)="sortByType('FirstPunchIn')">{{ 'punchinquiry.sortbyfirstpunchin' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_3" (click)="sortByType('LastPunchOut')">{{ 'punchinquiry.sortbylastpunchout' | translate }}</div>
                    </div>
                </div>
            </div>

            <input id="myInp" type="text" name="search" placeholder="{{ 'search' | translate }}" size="15" maxlength="15" [(ngModel)]="search.keywords" (ngModelChange)='searchByInput()'><button class="btn_search btn_opacity" title="Search" type="submit" value="submit"></button>

            <div style="clear:both;"></div>

        </div>

        <div style="clear:both;"></div>

        <hr class="m0">

        <!-- ============ list_data ============ -->
        <div id="filter_holder" class="list_data page_punch_inquiry">
            <div *ngFor="let eEmployPunchDetail of eEmployPunchDetailList; let i = index;">
                <!-- ============  list1box  ============ -->
                <!-- <div class="list_1_box list_flag f_{{leaveApplication.StatusDesc | lowercase}}" style="display:block" (click)="showLeaveApplication(leaveApplication)"> -->
                <div class="list_1_box list_flag f_normal" style="display:block" (click)="showEEmployPunchDetail(eEmployPunchDetail)">
                    <div>
                        <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(eEmployPunchDetail.StaffInfo) + ')'}">
                            <!-- <div class="txt_photo_mask_small p_{{leaveApplication.StatusDesc | lowercase}}"></div> -->
                            <div class="txt_photo_mask_small p_normal"></div>
                        </div>

                        <div class="txt_content_area_2line txt_size_2">
                            <div class="txt_name">{{eEmployPunchDetail.StaffInfo.FullName}}</div>
                            <div style="clear:both;"></div>
                            <div class="txt_jobposition txt_grey_1">{{eEmployPunchDetail.StaffInfo.PositionDesc}}</div>
                            <div class="txt_type">
                                <span class="txt_grey_2 txt_size_3">{{ 'punchinquiry.firstin' | translate }}&nbsp;</span>
                                {{commonUI.formatDisplayDate(eEmployPunchDetail.FirstPunchIn, 'DD MMM YYYY')}}
                                <span *ngIf="eEmployPunchDetail.FirstPunchIn != '0001-01-01T00:00:00'" class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(eEmployPunchDetail.FirstPunchIn, 'ddd')}})</span>
                                &nbsp;{{commonUI.formatDisplayDate(eEmployPunchDetail.FirstPunchIn, 'h:mm A')}}
                            </div>
                            <div class="txt_status">
                                <span class="txt_grey_2 txt_size_3">{{ 'punchinquiry.lastout' | translate }}&nbsp;</span>
                                {{commonUI.formatDisplayDate(eEmployPunchDetail.LastPunchOut, 'DD MMM YYYY')}}
                                <span *ngIf="eEmployPunchDetail.FirstPunchIn != '0001-01-01T00:00:00'" class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(eEmployPunchDetail.LastPunchOut, 'ddd')}})</span>
                                &nbsp;{{commonUI.formatDisplayDate(eEmployPunchDetail.LastPunchOut, 'h:mm A')}}
                            </div>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </div>
            </div>
            <div class="f_all f_pending f_cancelpending f_approved f_rejected" style="clear:both;"></div>
        </div>
        <!-- ============ end list_data ============ -->
    </div>
    <!-- end id=content_box -->
    
    <!--============ popup : detail of punch detail ============ -->
    <!-- <div id="pop_punchdetail" class="pop_functions_div pp5_content"> -->
    <!-- <div id="pop_punchdetail" class="pop_functions_div list_swipe_flag-selected">
        <div class='pop_functions_box pop_functions_box_size_2column'>
            <div class="pop_functions_close maincolor_btn2 pp_close" (click)="hideEEmployPunchDetail()">x</div>
    
            <div class="detail_box_top d_box_top">
                <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
                <div class="detail_box_top_photo" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(selectedEEmployPunchDetail.StaffInfo) + ')'}"></div>
                <div class="detail_box_name"> -->
                    <!-- <span class="txt_popup_staffname">{{ selectedEEmployPunchDetail.StaffInfo.FullName }}</span><br>
                    <span class="txt_popup_position">{{ selectedEEmployPunchDetail.StaffInfo.PositionDesc }}</span><br>
                    <span class="txt_popup_staffid">ID : {{ selectedEEmployPunchDetail.StaffInfo.StaffID }}</span><br> -->
                    <!-- <span class="txt_size_1">{{ selectedEEmployPunchDetail.StaffInfo.FullName }}</span><br>
                    <span class="txt_opacity_1">{{ selectedEEmployPunchDetail.StaffInfo.PositionDesc }}</span><br>
                    <span class="txt_size_3 txt_opacity_1 txt_popup_staffid">ID&nbsp;:&nbsp;{{ selectedEEmployPunchDetail.StaffInfo.StaffID }}</span><br>
                </div>
            </div>
    
            <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">
    
                <div class="detail_box_bottom_1point thin">
                    <div class="detail_box_catalog_area"></div> -->
                    <!-- <div class="detail_box_session1 txt_grey_2 txt_size_3"></div> -->
                    <!-- <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(selectedEEmployPunchDetail.PunchDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(selectedEEmployPunchDetail.PunchDate, 'ddd')}})</span></div>
                    <div class="detail_box_sub_icon d_box_sub_icon_appointment"></div>
                    <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'punchinquiry.shift' | translate }}</div>
                    <div class="detail_box_date_num maincolor_txt"><span class="txt_size_0">{{ selectedEEmployPunchDetail.ShiftDetail.Desc }}</span></div>
                    <div class="detail_box_type">
                            {{commonUI.formatDisplayDate(selectedEEmployPunchDetail.ShiftDetail.ShiftInTime, 'h:mm A')}}
                            &nbsp;-&nbsp;
                            {{commonUI.formatDisplayDate(selectedEEmployPunchDetail.ShiftDetail.ShiftOutTime, 'h:mm A')}}
                        </div>
                </div>
    
                <div class="detail_box_other txt_size_1">
                    <div class="txt_grey_2 txt_size_3">{{ 'punchinquiry.currentpunch' | translate }}</div>
                    <div class="col col2 adjusted_punch_detail_left" style="border-top: 1px solid #e6e6e6;margin: 0;">
                        <div style="display:none">
                            <div>Time</div>
                            <div>Detail</div>
                            <div style="clear:both; float:none"></div>
                        </div>
    
                        <div *ngFor="let staffPunch of selectedEEmployPunchDetail.StaffPunchList">
                            <div>
                                {{ commonUI.formatDisplayDate(staffPunch.PunchDateTime, 'h:mm A') }}
                                <br>
                                <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(staffPunch.PunchDateTime, 'DD MMM YYYY (ddd)') }}</span>
                            </div>
                            <div>
                                <span class="txt_label bc_green txt_size_3" *ngIf="staffPunch.Status == 'O'">{{ 'punchinquiry.outtime' | translate }}</span>
                                <span class="txt_label bc_blue txt_size_3" *ngIf="staffPunch.Status == 'I'">{{ 'punchinquiry.intime' | translate }}</span>
                                <br>
                                <span class="txt_size_3 txt_grey_2">{{ 'punchinquiry.site' | translate }}</span>
                                {{ staffPunch.PunchLocation }}
                            </div>
                            <div style="clear:both; float:none"></div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="detail_box_bottom_area mt0_m">
                <div class="txt_grey_2 txt_size_3">{{ 'punchinquiry.rawpunch' | translate }}</div>
                <div class="col col2 adjusted_punch_detail_left" style="border-top: 1px solid #e6e6e6;margin: 0;">
                    <div style="display:none">
                        <div>Time</div>
                        <div>Detail</div>
                        <div style="clear:both; float:none"></div>
                    </div>
                    <div *ngFor="let rawPunch of selectedEEmployPunchDetail.RawPunchList; let i = index;">
                        <div>
                            {{ commonUI.formatDisplayDate(rawPunch.PunchDateTime, 'h:mm A') }}
                            <br>
                            <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(rawPunch.PunchDateTime, 'DD MMM YYYY (ddd)') }}</span>
                        </div>
                        <div>
                            <span class="txt_label bc_green txt_size_3" *ngIf="rawPunch.Status == 'O'">{{ 'punchinquiry.outtime' | translate }}</span>
                            <span class="txt_label bc_blue txt_size_3" *ngIf="rawPunch.Status == 'I'">{{ 'punchinquiry.intime' | translate }}</span>
                            <br>
                            <span class="txt_size_3 txt_grey_2">{{ 'punchinquiry.site' | translate }}</span>
                            {{ rawPunch.PunchLocation }}
                        </div>
                        <div style="clear:both; float:none"></div>
                    </div>
                </div>
            </div>
            <div style="clear:both"></div>
        </div>
    </div> -->
</div>
