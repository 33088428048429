<div id="content_eclaim_inquiry">

    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'eclaiminquiry.eclaim-inquiry' | translate }}</span>
    </div>

    <!-- =========== Search ========== -->

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_leaveapplicationinquiry"></div>
            <div class="fl">{{ 'eclaiminquiry.title' | translate }}</div>
        </div>

            <div class="box_content_inquiry">

                <div  class="box_content_inquiry_field">
                    <div class="btn_sub_title txt_grey_2">{{ 'eclaiminquiry.start_date' | translate }}</div>
                    
                    <div class="fl input_new_calendar">
                        <div>
                            <mat-form-field appearance='fill' (click)="startPicker.open()">
                                <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('start')">
                                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                <mat-datepicker #startPicker (opened)="openAppend('start')"></mat-datepicker>
                            </mat-form-field>
                            <div style="display: none">
                                <div class="datepicker-footer" #startFooter>
                                    <button mat-raised-button  (click)="today('start')" #todayButton>
                                        {{'TODAY' | translate}}
                                    </button>
                                    <button mat-raised-button (click)="close('start')" #doneButton>
                                        {{'close' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box_content_inquiry_field">

                    <div class="btn_sub_title txt_grey_2">{{ 'eclaiminquiry.end_date' | translate }}</div>
                    <div class="fl input_new_calendar">
                        <div>
                            <mat-form-field appearance='fill' (click)="endPicker.open()">
                                <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('end')">
                                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                <mat-datepicker #endPicker (opened)="openAppend('end')"></mat-datepicker>
                            </mat-form-field>
                            <div style="display: none">
                                <div class="datepicker-footer" #endFooter>
                                    <button mat-raised-button  (click)="today('end')" #todayButton>
                                        {{'TODAY' | translate}}
                                    </button>
                                    <button mat-raised-button (click)="close('end')" #doneButton>
                                        {{'close' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div  class="box_content_inquiry_field" *ngIf='claimStatusList'>
                    <div class="btn_sub_title txt_grey_2">{{ 'eclaiminquiry.claimtype' | translate }}</div>
                    <div class="input_field_large">
                        <select class="btn btn_dropdown_1 maincolor_btn_1" autofocus [(ngModel)]='claimStatus'>
                            <!-- <option selected value="">{{ 'eclaiminquiry.status_all' | translate }}</option> -->
                            <option *ngFor='let status of claimStatusList' [value]="status.Code">{{ status.Desc | translate }}</option>
                        </select>

                    </div>
                </div>

                <div style="clear:both;"></div>

                <div  class="box_content_inquiry_field">
                    <div class="btn_sub_title txt_grey_2">{{ 'eclaiminquiry.status' | translate }}</div>
                    <div class="input_field_large">
                        <select class="btn btn_dropdown_1 maincolor_btn_1" autofocus [(ngModel)]='status'>
                            <option selected value="">{{ 'eclaiminquiry.status_all' | translate }}</option>
                            <option value="Pending">{{ 'eclaiminquiry.status_pending' | translate }}</option>
                            <option value="Approved">{{ 'eclaiminquiry.status_approved' | translate }}</option>
                            <option value="Rejected">{{ 'eclaiminquiry.status_rejected' | translate }}</option>
                        </select>

                    </div>
                </div>

                <div  class="box_content_inquiry_field">
                    <div class="btn_sub_title txt_grey_2">{{ 'eclaiminquiry.view' | translate }}</div>
                    <div class="onoffswitch" [attr.isteam]="isTeammateViewSelected">
                        <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" [(ngModel)]="isTeammateViewSelected" (ngModelChange)='isChangedTeamView()' checked>
                        <label class="onoffswitch-label" for="myonoffswitch">
                            <span class="onoffswitch-inner" attr.data-content-before="{{'eclaiminquiry.subordinate' | translate}}" attr.data-content-after="{{ 'eclaiminquiry.personal' | translate }}"></span>
                            <span class="onoffswitch-switch"></span>
                        </label>
                    </div>
                </div>

                <div style="clear:both;"></div>

                <div  class="box_content_inquiry_field">
                    <div class='onoffswitch d-flex align-items-center'>
                        <input type='checkbox' name="excessivecliam" id='excessivecliam' [(ngModel)]="iseExcessiveClaimMode"/>
                        <div class="btn_sub_title txt_grey_2">{{ 'eclaiminquiry.excessivecliam' | translate }}</div>
                    </div>
                </div>

                <div style="clear:both;"></div>

                <hr>
                <div class="inquiry_box_two_btn_center">
                    <button id="btn_inquiry_search" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="showSearchResult()"><img src="assets/img/btn_search_a.png" width="18" />{{ 'search' | translate }}</button>
                    <button id="btn_inquiry_reset" class="btn btn-block btn-lg two_btn btn_grey" (click)="resetSearchResult()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}</button>
                    <div style="clear:both;"></div>
                </div>

            </div>





        <!-- ============ end : content_box ============ -->
    </div>



    <!-- =========== Result ========== -->
    <a name="list_result" class="list_bar_mt"></a>
    <div class="list_result_area content_box" id="listResult">



        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_teamALB"></div>
            <div class="fl">{{ 'eclaiminquiry.eclaim-inquiry' | translate }}</div>
        </div>

        <!--
        <div class="txt_result">
            <div class="txt_result_title txt_grey_2">Result : </div>
            <div class="txt_result_item_area">
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">All Leaves</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">2016</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">Teammate</div>
                </a>
            </div>
        </div>
        -->
        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">

            <div class="btn_small_cross_back" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_arrow_back.png" width="12"></div>
            <div class="header_bar_icon fl list_icon_border mt0 ml0 btn_filter_inquiry" title="{{ 'eclaiminquiry.backtofiltertochangeinquiry' | translate }}" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_filter.png" width="20" /></div>

            <!--<div class="header_bar_icon fl list_icon_border mt0 ml0 btn_filter_inquiry" title="Hide this inquiry"><img alt="" src="assets/img/btn_arrow_back.png" width="20" click="hideSearchResult()" /></div>-->

            <div id="p4" class="header_bar_icon fl list_icon_border mt0" title="Sorts" (click)="toggleSortMenu()"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list">
                <div class="alert_box_arrow arrow_position_4"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'eclaiminquiry.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <div class="alert_box_content_item" id="sortItem_0" (click)="sortByType('StaffInfo.FullName')">{{ 'eclaiminquiry.sortbyname' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_1" (click)="sortByType('ClaimDate')">{{ 'eclaiminquiry.sortbyamount' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_1" (click)="sortByType('ClaimTypeCode')">{{ 'eclaiminquiry.sortbytype' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_2" (click)="sortByType('Status')">{{ 'eclaiminquiry.sortbystatus' | translate }}</div>
                    </div>
                </div>
            </div>

            <input id="myInp" type="text" name="search" placeholder="Search" size="15" maxlength="15" [(ngModel)]="search.keywords" (ngModelChange)='searchByInput()'><button class="btn_search btn_opacity" title="Search" type="submit" value="submit"></button>

            <div style="clear:both;"></div>

        </div><!-- end list_bar -->
        <!-- ============ search result txt  ============ -->
        <div id="search_bar_result">
            <a rel="external" href="#list_result">
                <div id="search_close"><img alt="" src="assets/img/btn_cross_grey.png" width="20" /></div>
            </a>
            Search result of "&nbsp;<span id="search_bar_result_show" class="txt_size_2 maincolor_txt"></span>&nbsp;"<br /><span class="txt_grey_2">(2 staffs)</span>
        </div>

        <div style="clear:both;"></div>

        <a name="list_result_filter" class="list_bar_mt"></a>

        <ul id="filter_options">
            <li class="active" (click)="clearFilter()" id="filterItem_0" >
                <a href="javascript:void(0)" class="f_all">{{ 'eclaiminquiry.status_all' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("")}}</div>
            </li>
            <li (click)="applyFilter('Pending', 1)" id="filterItem_1" >
                <a href="javascript:void(0)" class="f_pending">{{ 'eclaiminquiry.status_pending' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("Pending")}}</div>
            </li>
            <li (click)="applyFilter('Approved', 2)" id="filterItem_2">
                <a href="javascript:void(0)" class="f_pending">{{ 'eclaiminquiry.status_approved' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("Approved")}}</div>
            </li>
            <li (click)="applyFilter('Rejected', 3)" id="filterItem_3" >
                <a href="javascript:void(0)" class="f_approved">{{ 'eclaiminquiry.status_rejected' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfApplication("Rejected")}}</div>
            </li>
        </ul>

        <div style="clear:both;"></div>

        <hr class="m0">


        <!-- ============ list_data ============ -->
        <div *ngIf='claimList'>
        <div class="list_data no_filter page_leave_balance_inquiry flex-list-view" *ngFor="let claim of claimList">

            <!-- ============  list1box  ============ -->
            <div class="list_1_box list_flag f_{{claim.Status | lowercase}}" (click)="showEClaimApplication(claim)">
                <div>
                    <div class="txt_photo_area_medium" [ngStyle]="{'background-image': 'url(' + commonUI.getStaffImage(claim.StaffInfo) +')'}">
                        <div class="txt_photo_mask_small p_{{claim.StatusDesc | lowercase}}"></div>
                    </div>
                    <div class="txt_content_area_2line txt_size_2">
                        <div class="txt_name">{{claim.StaffInfo.FullName}}</div>
                        <div style="clear:both;"></div>
                        <div class="txt_type"><span class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.position' | translate }}&nbsp;</span>{{claim.StaffInfo.PositionDesc}}</div>
                        <div class="txt_type">
                            <span class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.claim' | translate }}&nbsp;</span>
                            <div class="txt_type">{{ 'eclaiminquiry.date' | translate }}:&nbsp;{{commonUI.formatDisplayDate(claim.ClaimDate, 'DD MMM YYYY')}}</div>
                            <div class="txt_type">{{ 'eclaiminquiry.amount' | translate }}:&nbsp;{{claim.ClaimCcy}} {{claim.ClaimAmount}}</div>
                        </div>
                        <div class="txt_type"><span class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.claimtype' | translate }}&nbsp;</span>{{claim.ClaimTypeDesc}}</div>
                        <div class="txt_status"><span class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.status' | translate }}<br></span>{{claim.StatusDesc | lowercase | translate}}</div>
                    </div>
                    <div style="clear:both;"></div>
                </div>
            </div>
            <!-- ============= Hide next approver button as the style is different from other screen
            <div class="approver-btn">
                <button class="btn_next_approver" (click)="commonUI.showNextApprover(claim)" style="width: 50px;">
                    <img src="assets/img/btn_show_next_approver.png" width="18">
                </button>
            </div>
            -->
        </div>
        </div><!-- ============ end list_data ============ -->
        <div class="f_all f_pending f_cancelpending f_approved f_rejected" style="clear:both;"></div>
    </div> <!-- end id=content_box -->
</div> 
