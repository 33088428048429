import { HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { CommonHandlerService } from 'src/app/services/common-handler.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { PersonalService } from 'src/app/services/personal.service';

declare var $: any;

@Component({
  selector: 'app-popup-personal-profile-address',
  templateUrl: './popup-personal-profile-address.component.html',
  styleUrls: ['./popup-personal-profile-address.component.scss']
})
export class PopupPersonalProfileAddressComponent implements OnInit {

  obj;
  addressTypeList;
  areaList;
  districtList;
  qualiTypeList;
  relationshipList;
  identityTypeList;
  displaySwitch = ''
  fileID = 0
  fileProgress = 0
  employee = JSON.parse(sessionStorage.Employee);

  constructor(public matDialogRef: MatDialogRef<PopupPersonalProfileAddressComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public errorUI: ErrorUIService, public commonUI: CommonUIService, public commonService: CommonServiceService, public domSanitizer: DomSanitizer, public commonHandler: CommonHandlerService, public personalService: PersonalService) {
    this.obj = data['address']
    this.addressTypeList = data['addresstype']
    this.areaList = data['area']
    this.districtList = data['district']
    this.qualiTypeList = data['quali']
    this.identityTypeList = data['identity']
    this.displaySwitch = data['displaySwitch']
  }

  ngOnInit(): void {
  }

  submitEvent() {
    var AddressFormList = [];
    this.obj.forEach((addr, k) => {
      var addressEntry = {
        "EmploymentRID": this.employee.RID,
        "Action": addr.Action,
        "AddressTypeCode": addr.AddressTypeCode,
        "Area": addr.Area,
        "AddressRID": addr.AddressRID,
        "Address": addr.ContactAddress || "",
        "District": addr.DistrictCode,
        "AttachmentName": "",
        "AttachmentAddr": ""
    }
    AddressFormList.push(addressEntry);
    var attachments = this.obj.attachments.filter((attach) => attach.uuid.value == addr.uuid);
    if (attachments.length > 0) {
        for (var i = 0; i < attachments.length; i++) {
            if (!attachments[i].deleted) {
                addressEntry.AttachmentName = attachments[i].file.name;
                addressEntry.AttachmentAddr = attachments[i].uploadFilename;
            }
        }
      }
    });

    this.commonUI.addLoadingTask();
    this.personalService.submitAddressForm(AddressFormList).subscribe((result) => {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.commonUI.finishLoadingTask();
          this.commonUI.showMsgBox("message.M0001");
          this.obj.IsEditable = false;
          this.matDialogRef.close()
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch { }
  }

  slideUp(event) {
    this.obj.forEach(record => {
      record.onFocus = false
    });
    $('#' + this.displaySwitch + ' .form_content_input').slideUp();
    $('#' + this.displaySwitch + ' #' + event.currentTarget.parentNode.id + ' .form_content_input').slideToggle();
    // $('.form_content_input').slideUp();
    // $('#' + event.currentTarget.id + ' .form_content_input').slideToggle();
  }

  isRecordsNotReady = function () {
    var notReady = true;
    if (!this.obj) return notReady;
    var records = this.obj.filter((record) => record.deleted == false)
    records.forEach((record, index) => {
      record = this.obj[index]
      if (record.Action != 'K') {
        notReady = false;
        return;
      }
    });
    return notReady;
  }

  attachmentExistedFromRecord(currentRecord) {
    if (this.obj == undefined) return false;
    if (this.obj.attachments == undefined) return false;
    // return this.obj.attachments.filter(function (attach) {
    //     return !attach.deleted && attach.uuid == currentRecord.uuid;
    // }).length > 0;
    if(this.obj.attachments.filter(attach => {
      !attach.deleted && attach.uuid == currentRecord.uuid;
    }).length > 0)
      return true

    return false

  }

  changeAddressType(address, addTypeList) {
    address.AddressTypeDesc = "";
    addTypeList.forEach((addType, k) => {
      if(addType.Code == address.AddressTypeCode)address.AddressTypeDesc = addType.Desc
    });
  }

  deleteAddress(address) {
    address.deleted = true;
    var attachments = this.obj.attachments
    this.obj = this.obj.filter((o) => o.deleted == false)
    this.obj.attachments = attachments
  }

  addPersnlAddressListForEdit() {
    this.obj.forEach(record => {
      record.onFocus = false;
    });
    $('#' + this.displaySwitch + ' .form_content_input').slideUp();

    this.obj.push({
      Action: "I",
      AddressRID: "",
      onFocus: true,
      deleted: false,
      AddressTypeDesc: this.addressTypeList.length > 0  ? this.addressTypeList[0].Desc:  '',
      AddressTypeCode: this.addressTypeList.length > 0  ? this.addressTypeList[0].Code : '',
      Area: this.areaList.length > 0  ? this.areaList[0].Code : '',
      DistrictCode: this.districtList.length > 0  ? this.districtList[0].Code : '',
      uuid: this.commonUI.genUUID()
    });
  }

  convertSizeFormat(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  };

  uploadListening(event) {
    var files = event.target.files;
    var uuid = event.target.attributes.uuid;
    /*
    if (($scope.countFilesReadyForUpload() + files.length) > 3) {
        $scope.commonUI.finishLoadingTask();
        $scope.commonUI.showWarning("message.M0005");
        $scope.$apply();
        return;
    }
    */
    for (var i = 0; i < files.length; i++) {
      var attachment = { fileID: this.fileID++, file: null, dataURL: null, uploadProgress: 0, uploadFilename: "", cancelled: false, uploaded: false, deleted: false };
      attachment['file'] = files[i];
      attachment['uuid'] = uuid;
      this.setPreview(attachment);
    }
    //$scope.commonUI.finishLoadingTask();
  }

  setPreview(attachment) {
    let instance = this
    var fileReader = new FileReader();
    fileReader.readAsDataURL(attachment.file)
    fileReader.onload = () => {
      if(attachment.file.type.match('image.*')) {
        attachment.dataURL = fileReader.result;
      } 
      else if(attachment.file.type.match('pdf.*'))
          attachment.dataURL = "assets/img/icon_pdf.png";
      else if(attachment.file.type.match('xls.*') || attachment.file.type.match('xlsx.*') || attachment.file.type.match('vnd.ms-excel.*'))
          attachment.dataURL = "assets/img/icon_excel.png";
      else 
        attachment.dataURL = "assets/img/icon_claim_maintenance.png";
      instance.obj.attachments.push(attachment);
      $("#btn_delete_attach_" + attachment.fileID).hide();
      this.uploadAttachment(attachment);
    }
  }

  uploadAttachment(attachment) {
    $("#btn_upload_attach_" + attachment.fileID).hide();
    this.commonService.uploadAttachment(attachment).subscribe((result) => {
      if(result.type == HttpEventType.UploadProgress) {
        this.fileProgress = Math.round(100 * result.loaded / result.total);
      } else if(result instanceof HttpResponse) {
        if(!result['conn_fail'] && result['ErrorMsg'] != null) {
          $("#btn_delete_attach_" + attachment.fileID).show();
          $("#btn_upload_attach_" + attachment.fileID).hide();
          $("#btn_cancel_attach_" + attachment.fileID).hide();
          $("#file_UploadAttachment").val('');
          $("#file_BankUploadAttachment").val('');
          $("input[uuid*='" + attachment.uuid + "']").val('');
          attachment.uploadFilename = result.body['Data'].UploadFileName
        } else 
          this.onCallAPIFail(result.body, result.status, result.headers, result.body['Data'])
      }
    }, err => {
      this.fileProgress = 0
      $("#file_UploadAttachment").val('');
      $("#file_BankUploadAttachment").val('');
      $("input[uuid*='" + attachment.uuid + "']").val(''); //use uuid delete
      this.deleteAttachment(attachment);
      // this.commonUI.showMsgBox("status." + err);
      this.commonUI.showMsgBox("status." + err.status + ': ' + err.statusText);
      //if (error != undefined && error == 501)
      //    $scope.commonUI.showMsgBox("error.00022");
      //else
      //    $scope.commonUI.showMsgBox("error.00021");
    })
  }

  deleteAttachment(attachment) {
    //Worked
    attachment.deleted = true;
    $("#attach_" + attachment.fileID).fadeOut();
    this.obj.attachments = this.obj.attachments.filter(o => o.uuid == attachment.uuid.value);
    //$scope.obj.attachments = []
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}
