<!--============ popup : Edit of Marital Status ============ -->

<div class="pp_marital_content list_flag-selected" style="display: block;">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval">
        <!--<div class="pop_functions_close maincolor_btn2 pp_close">x</div>-->

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'personalprofile.editofgeneral' | translate }}</div>
        </div>

        <div class="d_box_top"></div>

        <div class="detail_box_bottom_area_profile mt0_m">

            <div class="box_content_inquiry">
                <form id="form_application">

                    <div class="form_area">

                        <!-- ========== a record =========== -->
                        <a name="top_form-1"></a>
                        <div id="form-1" class="form_content form_content_popup">

                            <div class="form_content_title form_content_title-selected">{{ 'personalprofile.maritalStatus' | translate }}</div>

                            <div class="form_content_input">

                                <a name="list_type" class="list_bar_mt"></a>

                                <div class="form_content_input_field_full">
                                    <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.status' | translate }}</div>
                                    <div class="input_field_large">
                                        <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="obj.MaritalStatus" name='marital'>
                                            <option *ngFor="let option of maritalOptions" value="{{option.key}}">{{option.value | translate }}</option>
                                        </select>
                                    </div>
                                </div>
                                <!--
                                <div class="form_content_input_field">
                                    <div class="btn_sub_title txt_grey_2">Effective Date</div>

                                    <div class="fl input_field_large">
                                        <input id="datepicker-1" class="btn btn-block btn-lg btn_date maincolor_btn2" type="text" value="24 Mar 2015 (Tue)" readonly>

                                    </div>
                                </div>-->

                                <div class="form_content_input_field_full">
                                    <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.attachment' | translate }}</div>
                                    <div class="fl input_field_small">
                                        <label class="btn btn-block btn-lg maincolor_btn_1"  [class.disabled]="attachmentExisted()">
                                            <img src="assets/img/btn_add_files.png" width="18"> {{ 'leaveapplication.addfiles' | translate }}
                                            <input type="file" id="file_UploadAttachment" multiple style="display: none;" (change)="uploadListening($event)" name='upload' [disabled]="attachmentExisted()"/>
                                        </label>
                                    </div>
                                    <div *ngFor="let attach of obj.attachments">
                                        <div id="attach_{{attach.fileID}}">
                                            <div style="clear: both"></div>
                                            <br />

                                            <div class="attachment_file_area bg_grey_4">

                                                <div class="attachment_file_txt">
                                                    <div class="attachment_photo">
                                                        <img src="{{attach.dataURL}}">
                                                    </div>
                                                    <div>{{attach.file.name}}</div>
                                                    <div class="txt_grey_2 txt_size_3" *ngIf="attach.file.size">{{convertSizeFormat(attach.file.size)}}</div>
                                                    <div style="clear: both"></div>
                                                    <div *ngIf="fileProgress != 100">
                                                        <div class="bar_chart_data maincolor_bg2" [ngStyle]="{'width': + fileProgress  +'%'}"></div><!-- sample uploading % -->
                                                    </div>
                                                </div>

                                                <div class="fr">
                                                    <!-- <div class="btn_attachment_position" (click)="uploadAttachment(attach)" id="btn_upload_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img src="assets/img/btn_start_upload.png" width="18">{{ 'leaveapplication.upload' | translate }}</a>
                                                    </div>
                                                    <div class="btn_attachment_position" (click)="cancelAttachment(attach)" id="btn_cancel_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_cancel_upload.png" width="18">{{ 'leaveapplication.cancel' | translate }}</a>
                                                    </div> -->
                                                    <div class="btn_attachment_position" (click)="deleteAttachment(attach)" id="btn_delete_attach_{{attach.fileID}}">
                                                        <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplication.delete' | translate }}</a>
                                                    </div>
                                                </div>

                                                <div style="clear: both"></div>

                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>

                                <div style="clear:both"></div>

                            </div> <!-- ========== end : form_content_input =========== -->
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                        </div> <!-- ========== end : form_content =========== -->





                    </div> <!-- ========== end : form_area =========== -->

                </form>
            </div> <!-- ========== end : form_content_inquiry =========== -->
        </div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

    </div>
    <div class="pop_functions_approval">
        <div class="pop_functions_two_btn_center">
            <!--<div class="header_bar_icon fl list_icon_border" title="Add New"><img alt="" src="assets/img/btn_addform_g.png" width="20"></div>-->
            <a class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="submitEvent()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'submit' | translate }}</a>
            <a class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'cancel' | translate }}</a>
            <div style="clear:both"></div>
        </div>
    </div>
</div>
