import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-save-completed',
  templateUrl: './popup-save-completed.component.html',
  styleUrls: ['./popup-save-completed.component.scss']
})
export class PopupSaveCompletedComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<PopupSaveCompletedComponent>) { }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }
}
