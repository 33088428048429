import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalCenterPendingApprovalComponent } from 'src/app/approval-center-pending-approval/approval-center-pending-approval.component';
import { PopupAppointmentDetailComponent } from 'src/app/common/popup-appointment-detail/popup-appointment-detail.component';
import { PopupInOutDetailComponent } from 'src/app/common/popup-in-out-detail/popup-in-out-detail.component';
import { PopupLeaveDetailComponent } from 'src/app/common/popup-leave-detail/popup-leave-detail.component';
import { PopupEclaimDetailComponent } from 'src/app/common_mod/popup-eclaim-detail/popup-eclaim-detail.component';
import { CommonServiceServiceMod } from 'src/app/services-mod/common-service-mod.service';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ApprovalService } from 'src/app/services/approval.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { IdleTimerService } from 'src/app/services/idle-timer.service';

@Component({
  selector: 'app-approval-center-pending-approval-mod',
  templateUrl: './approval-center-pending-approval-mod.component.html',
  styleUrls: ['./approval-center-pending-approval-mod.component.scss']
})
export class ApprovalCenterPendingApprovalModComponent extends ApprovalCenterPendingApprovalComponent {
  sourceTypeList = this.commonServiceMod.SourceTypeList.slice();

  constructor(public idleTimer: IdleTimerService, public commonUI: CommonUIService, public el: ElementRef, public commonService: CommonServiceService, public commonServiceMod: CommonServiceServiceMod, public matDialog: MatDialog, public approvalService: ApprovalService, public route: ActivatedRoute, public router: Router) { 
    super(idleTimer, commonUI, el, commonService, matDialog, approvalService, route)
  }

  multiModeToggle() {
    if (!this.multiMode) {
      this.multiMode = true;
      this.resetSelectedAll(true);
      // turn off swipeMode
      this.swipeMode = false;
    } else {
      this.multiMode = false;
      this.allSelected = false;
      this.resetSelectedAll(false);
    }
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.resetSelectedAll(this.allSelected);
  }

  resetSelectedAll(trueOrFalse) {
    if(this.objList) {
      for (var i = 0; i < this.objList.length; i++) {
        this.objList[i].checked = trueOrFalse;
      }
    }
  }

  showPopupLeaveDetail(obj) {
    this.selectedIndex = obj.index;
    if (!this.hasSwiped) {
      if (this.multiMode) {
        if (obj.checked === undefined || !obj.checked) obj.checked = true;
        else if (obj.checked) obj.checked = false;
      } else if (!this.swipeMode && !this.multiMode) {
        this.selectedLeaveApplication = obj;

        this.commonUI.addLoadingTask();
        // this.commonService.getApproverRemarkList(this.selectedLeaveApplication.RID, "ELEAVE", this.selectedLeaveApplication.StartDate, this.onGetApproverRemarkListSuccess, this.onCallAPIFail);
        var rid = this.selectedLeaveApplication.RID;
        if(obj.SourceType == 'CLAIMHDR') this.selectedLeaveApplication.StartDate = obj.ClaimDate;
        if(obj.SourceType == 'OT') this.selectedLeaveApplication.StartDate = obj.OTStart;
        this.commonService.getApproverRemarkList(rid, this.selectedLeaveApplication.SourceType, this.selectedLeaveApplication.StartDate).subscribe((result) => {
          
          if (!result['conn_fail'])
            if (result.body['ErrorMsg'] == null) {
              this.onGetApproverRemarkListSuccess(result.body['Data'].ApproverRemarkList)
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
      }
    }
  }

  onGetApproverRemarkListSuccess(remarkList) {
    this.approverRemarkList = this.commonService.getApproverRemarkTranslateList(remarkList);
    this.selectedLeaveApplication.showCutoff = false
    this.selectedLeaveApplication.approver_staffinfo = this.staffInfo;

    if (this.selectedLeaveApplication.SourceType == "ELEAVE") {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm) { 
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "LEAVECAN") {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm) { 
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "APPOINTMENT") {
      const dialog = this.matDialog.open(PopupAppointmentDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm) { 
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "CLAIMHDR") {
      const dialog = this.matDialog.open(PopupEclaimDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm) { 
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else if (this.selectedLeaveApplication.SourceType == "EINOUT") {
      const dialog = this.matDialog.open(PopupInOutDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm) { 
          this.resultList = []
          this.queryRecordList()
        }
      })
    } else {
      const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: 'APPROVAL'
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm) this.getModule()
      })
    }
    // this.leavePopupControl.showPopup(this.selectedLeaveApplication);
    this.commonUI.finishLoadingTask();
    this.reason = ''
  }

  doMultiApprove() {
    var approvalRecordList = [];
    for (var i = 0; i < this.objList.length; i++) {
        if (this.objList[i].checked) {
          if (this.objList[i].SourceType == "EINOUT") {
            var approvalRecord = {
                "RID": this.objList[i].RID,
                "SourceType": this.objList[i].SourceType,
                "StartDate": this.objList[i].Date
            };
           }  
            else if (this.objList[i].SourceType == "CLAIMHDR") {
              var approvalRecord = {
                  "RID": this.objList[i].RID,
                  "SourceType": this.objList[i].SourceType,
                  "StartDate": this.objList[i].ClaimDate
              };
            }
            else {
                var approvalRecord = {
                    "RID": this.objList[i].RID,
                    "SourceType": this.objList[i].SourceType,
                    "StartDate": this.objList[i].StartDate
                };
            }
            if (this.search.SourceType == approvalRecord.SourceType) {
                approvalRecordList.push(approvalRecord);
            }
        }
    }
    // console.log("approvalRecordList.length="+ approvalRecordList.length);
  
    if (approvalRecordList.length > 0) {
        this.commonUI.addLoadingTask();
        this.approvalService.approveRecord(this.employee, 'APV', this.remarkPrefixApproved, approvalRecordList).subscribe((result) => {
          
          if (!result['conn_fail'])
            if (result.body['ErrorMsg'] == null) {
              this.objList = [];
              this.resultList = [];
              this.commonUI.finishLoadingTask();
              this.commonUI.showMsgBox("message.M0008");
              this.queryRecordList();
              this.swipeModeDisable();
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
    }
  }
  
  doMultiReject() {
    var approvalRecordList = [];
    for (var i = 0; i < this.objList.length; i++) {
        if (this.objList[i].checked) {
            if (this.objList[i].SourceType == "EINOUT") {
              var approvalRecord = {
                  "RID": this.objList[i].RID,
                  "SourceType": this.objList[i].SourceType,
                  "StartDate": this.objList[i].Date
              };
            }
            else if (this.objList[i].SourceType == "CLAIMHDR") {
              var approvalRecord = {
                  "RID": this.objList[i].RID,
                  "SourceType": this.objList[i].SourceType,
                  "StartDate": this.objList[i].ClaimDate
              };
            }
            else {
                var approvalRecord = {
                    "RID": this.objList[i].RID,
                    "SourceType": this.objList[i].SourceType,
                    "StartDate": this.objList[i].StartDate
                };
            }
            if (this.search.SourceType == approvalRecord.SourceType) {
                approvalRecordList.push(approvalRecord);
            }
        }
    }
  
    if (approvalRecordList.length > 0) {
        this.commonUI.addLoadingTask();
        this.approvalService.approveRecord(this.employee, 'REJ', this.remarkPrefixRejected, approvalRecordList).subscribe((result) => {
          
          if (!result['conn_fail'])
            if (result.body['ErrorMsg'] == null) {
              this.objList = [];
              this.resultList = [];
              this.commonUI.finishLoadingTask();
              this.commonUI.showMsgBox("message.M0008");
              this.queryRecordList();
              this.swipeModeDisable();
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
    }
  }

  showBigCalendar() {
    //window.open(`${window.location.origin}/ess/calendar`, '_self')
    this.router.navigateByUrl("/ess/calendar");
  }

  showSearchResult(selectedSourceType) {
    this.queryType = undefined;
    this.search.SourceType = selectedSourceType;
    this.objList = this.resultList.filter((l) => l.SourceType == this.search.SourceType)
    // $("#filter_options").children().removeClass("active");
    // $("#filter_item_"+selectedSourceType).addClass("active");
    // angular.element(document.querySelector("#filter_options")).children().removeClass("active");
    // this.el.nativeElement.querySelectorAll("#filter_options").forEach(element => {
    //   element.classList.remove("active");
    // });
    // document.querySelector("#filter_item_" + selectedSourceType)).addClass("active");
    // this.el.nativeElement.querySelector("#filter_item_" + selectedSourceType).addClass("active");

    this.commonUI.scrollToTop();
  }

}
