import { Component, Input, OnInit, Inject  } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PopupJobPositionComponent } from '../popup-job-position/popup-job-position.component';

@Component({
  selector: 'app-popup-company-select',
  templateUrl: './popup-company-select.component.html',
  styleUrls: ['./popup-company-select.component.scss']
})
export class PopupCompanySelectComponent implements OnInit {

  companyList = [];

  constructor(public matDialogRef: MatDialogRef<PopupCompanySelectComponent>, public matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data) { 
    this.companyList = data
    if(this.companyList.length == 1) this.openJobPosition(this.companyList[0])
  }

  ngOnInit(): void {
  }

  close(refresh?) {
    try {
      this.matDialogRef.close(refresh)
    } catch {}
  }

  openJobPosition(company) {
    const dialog = this.matDialog.open(PopupJobPositionComponent, {
      data: company,
      panelClass:  'dialog-responsive'
    })
    dialog.afterClosed().subscribe((data?) => {
      this.close(data)
    })
  }

}