import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PopupAppointmnetConfirmComponent } from '../common/popup-appointmnet-confirm/popup-appointmnet-confirm.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { AppointmentService } from '../services/appointment.service';
import { CommonServiceService } from '../services/common-service.service';

declare var $: any;

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  
  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChildren('startPicker') startPicker: QueryList<MatDatepicker<any>>;
  @ViewChildren('endPicker') endPicker: QueryList<MatDatepicker<any>>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  selectedIndex = 0;
  numOfAppointments = 0;
  appointments = [];
  staffInfo = [];
  employee = JSON.parse(sessionStorage.Employee);
  activeAppointment;

  constructor(public commonService: CommonServiceService, public commonUI: CommonUIService, public appointmentService: AppointmentService, public matDialog: MatDialog, public translateService: TranslateService) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.commonUI.addLoadingTask();
    this.getStaffInfo();
    this.addAppointment();
    this.commonUI.finishLoadingTask();
  }

  dateValidator(date) {
    switch (date) {
      case 'start':
        if (this.activeAppointment.AppointStartDate > this.activeAppointment.AppointEndDate)
          this.activeAppointment.AppointEndDate = new Date(this.activeAppointment.AppointStartDate)
        break;
      case 'end':
        if (this.activeAppointment.AppointEndDate < this.activeAppointment.AppointStartDate)
          this.activeAppointment.AppointStartDate = new Date(this.activeAppointment.AppointEndDate)
        break;
    }
  }

  getStaffInfo() {
    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfo(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.staffInfo = result.body['Data'].StaffInfo;
        this.commonUI.finishLoadingTask();
      }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  createAppointment() {
    return {
      "index": this.numOfAppointments,
      "EmploymentRID": this.employee.RID,
      "Event": "",
      "Desc": "",
      "AppointStart": "",
      "AppointStartDate": moment().add(1, "days").toDate(),
      "AppointStartHour": "08",
      "AppointStartMinute": "00",
      "AppointStartDayPart": "AM",
      "AppointEnd": "",
      "AppointEndDate": moment().add(1, "days").toDate(),
      "AppointEndHour": "09",
      "AppointEndMinute": "00",
      "AppointEndDayPart": "AM",
      "deleted": false
    }
  }

  addAppointment() {
    this.numOfAppointments++;
    var app = this.createAppointment();

    this.activeAppointment = app;
    this.appointments.push(app);
    // this.appointments.unshift(app);

    $('.form_content_input').slideUp("slow");
    return false;
  }

  showPreview() {
    const dialog = this.matDialog.open(PopupAppointmnetConfirmComponent, {
      panelClass: 'responsive-dialog',
      data: {
        appointment: this.appointments,
        staff: this.staffInfo
      }
    })

    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data.confirm) {
        this.resetAppointmentApplication();
        this.commonUI.finishLoadingTask();
        this.commonUI.showMsgBox("message.M0001");
      }
    })
  }

  closePreview = function () {
    $("#previewApp").fadeOut();
  }

  getNumOfActiveAppointments() {
    var numOfActive = 0;
    for (var i = 0; i < this.appointments.length; i++) {
      if (!this.appointments[i].deleted) numOfActive++;
    }
    return numOfActive;
  }

  deleteAppointment(appointment, idx) {
    if (this.getNumOfActiveAppointments() == 1) return;
    $("#form_" + idx).slideUp("slow", () => {
      appointment.deleted = true;
    });
    return false;
  }

  resetAppointmentApplication() {
    this.numOfAppointments = 0;
    this.appointments = [];
    this.addAppointment();
  }

  submitAppointmentApplication() {
    this.commonUI.addLoadingTask();
    var submitAppointments = [];
    this.appointments.forEach((appointment, key) => {
      if (appointment.deleted) return;

      var start = 0
      var end = 0

      if(appointment.AppointStartDayPart == 'PM' && Number(appointment.AppointStartHour) < 12) { 
        var start = parseInt(appointment.AppointStartHour); 
        start += 12 
      }
      var startDateString = moment(appointment.AppointStartDate).format("YYYY-MM-DDT") +
        start + ":" + appointment.AppointStartMinute + ":00" +
        appointment.AppointStartDayPart;
      appointment.AppointStart = moment(startDateString, "YYYY-MM-DDThh:mm:ssA").format("YYYY-MM-DDTHH:mm:ss");

      if(appointment.AppointEndDayPart == 'PM' && Number(appointment.AppointEndHour) < 12) { 
        end = parseInt(appointment.AppointEndHour); 
        end += 12 
      }
      var endDateString = moment(appointment.AppointEndDate).format("YYYY-MM-DDT") +
        end + ":" + appointment.AppointEndMinute + ":00" +
        appointment.AppointEndDayPart;
      appointment.AppointEnd = moment(endDateString, "YYYY-MM-DDThh:mm:ssA").format("YYYY-MM-DDTHH:mm:ss");

      submitAppointments.push(appointment);
    })
    this.appointmentService.submitAppointmentApplication(submitAppointments).subscribe((result) => {
      this.resetAppointmentApplication();
      this.commonUI.finishLoadingTask();
      this.closePreview();
      this.commonUI.showMsgBox("message.M0001");
      return;
    })
  }

  initAppointment() {
    $('html, body').animate({ scrollTop: ($('#layout').offset().top) }, 200);

    $('.form_content:first').hide();
    $('.form_content:first').slideDown("slow");
  }

  slideUp(appointment, idx) {
    this.activeAppointment = appointment;

    $('.form_content_input').slideUp();
    $("#form_content_input_" + idx).slideToggle();
  }

  // submitAppointmentForm(form_application) {
  //   if (form_application.$valid) {
  //     this.showPreview();
  //   }
  // }

  submitAppointmentForm() {
    this.showPreview();
  }

    /* Datepicker Footer */

    today(picker: string, n) {
      switch (picker) {
        case 'start':
          this.appointments[n].AppointStartDate = new Date()
          break;
        case 'end':
          this.appointments[n].AppointEndDate = new Date()
          break;
      }
      this.close(picker, n)
    }
  
    close(picker: string, n) {
      switch (picker) {
        case 'start':
          this.startPicker.forEach(function(e, idx) {
            if(idx == n) e.close()
          })
          break;
        case 'end':
          this.endPicker.forEach(function(e, idx) {
            if(idx == n) e.close()
          })
          break;
      }
    }
  
    openAppend(picker: string, i) {
      const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
      switch (picker) {
        case 'start':
          matCalendar.appendChild(this.startFooter.nativeElement);
          break;
        case 'end':
          matCalendar.appendChild(this.endFooter.nativeElement);
          break;
      }

      this.selectedIndex = i
    }
  
    /*******************/

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}