import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-popup-next-approver',
  templateUrl: './popup-next-approver.component.html',
  styleUrls: ['./popup-next-approver.component.scss']
})
export class PopupNextApproverComponent implements OnInit {

  nextApproverList = []
  record;

  constructor(public dialog: MatDialog, public matDialogRef: MatDialogRef<PopupNextApproverComponent>, public commonService: CommonServiceService, public commonUI: CommonUIService, @Inject(MAT_DIALOG_DATA) public data: {}, public translate: TranslateService,) { 
    this.record = data['record']
  }

  ngOnInit(): void {
    this.getNextApprover()
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  close(data?) {
    try {
      this.matDialogRef.close(data)
    } catch { }
  }

  getNextApprover() {
    this.commonService.getNextApprover(this.record).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.nextApproverList = result.body['Data'].NextApproverList;
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }
}
