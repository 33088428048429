<div id="content_leave_balance_inquiry">

    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'leaveapplicationinquery.delegation' | translate }}</span>
    </div>

    <!-- =========== Search ========== -->

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_teamALB"></div>
            <div class="fl">{{ 'leaveapplicationinquery.delegationbalanceinquiry' | translate }}</div>
        </div>

        <form>
            <div class="box_content_inquiry">

                <div  class="box_content_inquiry_field">
                    <div class="btn_sub_title txt_grey_2">{{ 'balanceinquery.leaveType' | translate }}</div>
                    <div class="input_field_large">
                        <select class="btn btn_dropdown_1 maincolor_btn_1" autofocus [(ngModel)]="leaveType" name='leaveType'>
                            <option *ngFor="let leavetype of leaveTypeList" value="{{leavetype.Code}}">{{leavetype.Desc}}</option>
                        </select>

                    </div>
                </div>

                <div  class="box_content_inquiry_field">
                    <div class="btn_sub_title txt_grey_2">{{ 'balanceinquery.date' | translate }}</div>
                    <div class="fl input_new_calendar">
                        <div>
                            <mat-form-field appearance='fill' (click)="balancePicker.open()">
                                <input matInput [matDatepicker]="balancePicker" [(ngModel)]="asAtDay" [min]='minDate' [max]='maxDate' readonly name='date'>
                                <mat-datepicker-toggle matSuffix [for]="balancePicker"></mat-datepicker-toggle>
                                <mat-datepicker #balancePicker (opened)="openAppend()"></mat-datepicker>
                            </mat-form-field>
                            <div style="display: none">
                                <div class="datepicker-footer" #balanceFooter>
                                    <button mat-raised-button  (click)="today()" #todayButton>
                                        {{'TODAY' | translate}}
                                    </button>
                                    <button mat-raised-button (click)="close()" #doneButton>
                                        {{'close' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--
                <div class="input_field_large">
                    <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="leaveYear">
                        <option ng-repeat="year in leaveYearList" value="{{year}}">{{year}}</option>
                    </select>

                </div>
            -->
                <div style="clear:both;"></div>

                <hr>
                <div class="inquiry_box_two_btn_center">
                    <button id="btn_inquiry_search" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="showSearchResult()"><img src="assets/img/btn_search_a.png" width="18" />{{ 'search' | translate }}</button>
                    <button id="btn_inquiry_reset" class="btn btn-block btn-lg two_btn btn_grey" (click)="resetSearchResult()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}</button>
                    <div style="clear:both;"></div>
                </div>

            </div>
        </form>





        <!-- ============ end : content_box ============ -->
    </div>



    <!-- =========== Result ========== -->
    <a name="list_result" class="list_bar_mt"></a>
    <div class="list_result_area content_box" id="listResult">



        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_teamALB"></div>
            <div class="fl">{{ 'balanceinquery.balanceinquery' | translate }}</div>
        </div>

        <!--
        <div class="txt_result">
            <div class="txt_result_title txt_grey_2">Result : </div>
            <div class="txt_result_item_area">
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">All Leaves</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">2016</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Change the inquiry">
                    <div class="txt_result_item bg_grey_3">Teammate</div>
                </a>
            </div>
        </div>
        -->
        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">

            <div class="btn_small_cross_back" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_arrow_back.png" width="12" (click)="hideSearchResult()"></div>
            <div class="header_bar_icon fl list_icon_border mt0 ml0 btn_filter_inquiry" title="{{ 'leaveapplicationinquery.backtofiltertochangeinquiry' | translate }}" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_filter.png" width="20" /></div>

            <!--<div class="header_bar_icon fl list_icon_border mt0 ml0 btn_filter_inquiry" title="Hide this inquiry"><img alt="" src="assets/img/btn_arrow_back.png" width="20" (click)="hideSearchResult()" /></div>-->

            <div id="p4" class="header_bar_icon fl list_icon_border mt0" title="Sorts" (click)="toggleSortMenu()"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list">
                <div class="alert_box_arrow arrow_position_4"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'balanceinquery.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <div class="alert_box_content_item" id="sortItem_0" (click)="sortByType('StaffInfo.FullName')">{{ 'balanceinquery.sortbyname' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_1" (click)="sortByType('StaffInfo.PositionDesc')">{{ 'balanceinquery.sortbyposition' | translate }}</div>
                        <div class="alert_box_content_item" id="sortItem_2" (click)="sortByType('currentYearDetail.AvailableBalance')">{{ 'balanceinquery.sortbyavailablebalance' | translate }}</div>
                    </div>
                </div>
            </div>

            <input id="myInp" type="text" name="search" placeholder="Search" size="15" maxlength="15" [(ngModel)]="search.keywords" (ngModelChange)='searchByInput()'><button class="btn_search btn_opacity" title="Search" type="submit" value="submit"></button>

            <div style="clear:both;"></div>

        </div><!-- end list_bar -->
        <!-- ============ search result txt  ============ -->
        <div id="search_bar_result">
            <a rel="external" href="#list_result">
                <div id="search_close"><img alt="" src="assets/img/btn_cross_grey.png" width="20" /></div>
            </a>
            Search result of "&nbsp;<span id="search_bar_result_show" class="txt_size_2 maincolor_txt"></span>&nbsp;"<br /><span class="txt_grey_2">(2 staffs)</span>
        </div>

        <div style="clear:both;"></div>

        <hr class="m0">


        <!-- ============ list_data ============ -->
        <div class="list_data no_filter page_leave_balance_inquiry" *ngFor="let balance of leaveBalanceList">

            <!-- ============  list1box  ============ -->
            <div class="list_1_box list_flag" (click)="showLeaveBalance(balance)">
                <div>
                    <div class="txt_photo_area_medium" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(balance.StaffInfo) + ')'}">
                        <div class="txt_photo_mask_small p_normal"></div>
                    </div>

                    <div class="txt_content_area_1line txt_size_2">
                        <div class="txt_name">{{balance.StaffInfo.FullName}}</div>
                        <div class="txt_jobposition txt_grey_2 txt_size_3">{{balance.StaffInfo.PositionDesc}}</div>
                        <div class="txt_a_balance txt_grey_2 txt_size_3">{{ 'balanceinquery.available' | translate }}<br /><span class="txt_grey_1 txt_size_1">{{balance.currentYearDetail.AvailableBalance}}</span></div>
                    </div>

                    <div style="clear:both;"></div>
                </div>
            </div>
        </div><!-- ============ end list_data ============ -->
        <div class="f_all f_pending f_cancelpending f_approved f_rejected" style="clear:both;"></div>
    </div> <!-- end id=content_box -->
</div>
