<div>
    <div class="login_box_abs cpw_box" id="form_forgot_pwd">

        <div class="login_bg">
            <img src="assets/img/logo_fsl.png" height="40" />
        </div>

        <span class="txt_size_0 txt_grey_2">{{ 'forgotpwd.forgotpwd' | translate }}</span>
        <br /><br /><br />

        <div class="input_login_position">
            <input type="text" [(ngModel)]="userid" value="" placeholder="{{ 'forgotpwd.userid' | translate }}" class="form-control input_txt_icon_right" required autofocus>
            <div class="input_icon_1"><img src="assets/img/input_id.png" width="18"></div>
        </div>

        <br />
        <div class="input_login_position">
            <input type="email" [(ngModel)]="email" value="" placeholder="{{ 'forgotpwd.email' | translate }}" class="form-control input_txt_icon_right" required>
            <div class="input_icon_1"><img src="assets/img/input_email.png" width="18"></div>
        </div>
        <br />

        <div class="pop_functions_two_btn_center btn_signin_position">
            <!-- <button class="btn btn-block btn-lg two_btn btn_grey" (click)='backToLogin()'><img src="assets/img/left_arrow_w.png" width="16" alt="">{{ 'back' | translate }}</button> -->
            <button class="btn btn-lg two_btn maincolor_btn2" (click)="forgetPwd()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'confirm' | translate }}</button>
			<div style="clear:both;"></div>
        </div>
		<br/>
    </div>
    <!--<script src="functions/menu/js/1.18(1).13.js"></script>-->

    <!--============ Migrate to common/popup-password-finished  ============-->
    <!--============ popup : password-finished  ============ --> 
    <!-- <div id="popup_forget_password_sent" class="pp1_content pop_functions_div" >
        <div class="pop_functions_box pop_functions_box_size_1">
            <br />
            <img src="assets/img/msg_tick.png" width="48">
            <br /><br />
            {{ 'forgotpwd.emailsent' | translate }}
            <br /><br />
            <div class="pop_functions_two_btn_center btn_signin_position">
                <button class="btn btn-block maincolor_btn2 icon_arrow_r" (click)='backToLogin()'>
                    {{ 'forgotpwd.backtologin' | translate }}
                </button>
            </div>
        </div>
    </div> -->
</div>