import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PopupAmendInquiryAddressComponent } from '../common/popup-amend-inquiry/popup-amend-inquiry-address/popup-amend-inquiry-address.component';
import { PopupAmendInquiryBankComponent } from '../common/popup-amend-inquiry/popup-amend-inquiry-bank/popup-amend-inquiry-bank.component';
import { PopupAmendInquiryContactComponent } from '../common/popup-amend-inquiry/popup-amend-inquiry-contact/popup-amend-inquiry-contact.component';
import { PopupAmendInquiryDependtComponent } from '../common/popup-amend-inquiry/popup-amend-inquiry-dependt/popup-amend-inquiry-dependt.component';
import { PopupAmendInquiryMaritalComponent } from '../common/popup-amend-inquiry/popup-amend-inquiry-marital/popup-amend-inquiry-marital.component';
import { PopupAmendInquiryQualiComponent } from '../common/popup-amend-inquiry/popup-amend-inquiry-quali/popup-amend-inquiry-quali.component';
import { PopupPersonalProfileMaritalComponent } from '../common/popup-personal-profile-marital/popup-personal-profile-marital.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';
import { PersonalService } from '../services/personal.service';

declare var $: any;

@Component({
  selector: 'app-profile-amend-inquiry',
  templateUrl: './profile-amend-inquiry.component.html',
  styleUrls: ['./profile-amend-inquiry.component.scss']
})
export class ProfileAmendInquiryComponent implements OnInit {

  showSortsMenu
  objList
  propertyName = 'id';
  reverse = false;
  objDetail
  displayPopupName
  resultList
  queryStatus
  employee = JSON.parse(sessionStorage.Employee)

  constructor(public idleTimer: IdleTimerService, public commonUI: CommonUIService, public commonService: CommonServiceService, public personalService: PersonalService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);

    this.queryRecordList();

    // default ordering
    this.propertyName = 'CreateDate';
    this.reverse = true;
  }

  showSortsMenuToggle() {
    if (!this.showSortsMenu) {
        this.showSortsMenu = true;
    } else {
        this.showSortsMenu = false;
    }
  }

  sortBy(propertyName) {
      this.reverse = (this.propertyName === propertyName) ? this.propertyName === propertyName : false;
      this.propertyName = propertyName;

      this.showSortsMenu = false;
  };

  sortByType(propertyName: string) {
      this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
      this.propertyName = propertyName;
      switch(propertyName) {
            case 'SourceType':
                this.objList = this.objList.sort((a,b) => {
                    return a.SourceType.localeCompare(b.SourceType)
                })
                break;
            case 'CreateDate':
                this.objList = this.objList.sort((a,b) => {
                    return new Date(b.CreateDate).getTime() - new Date(a.CreateDate).getTime()
                })
                break;
            case 'StatusDesc':
                this.objList = this.objList.sort((a,b) => {
                    return a.StatusDesc.localeCompare(b.StatusDesc)
                })
                break;
      }
      if(this.reverse) this.objList = this.objList.slice().reverse()
      this.showSortsMenu = false;
  }

  search = {
      keywords: "",
      StatusDesc: ""
  };

  clearFilter(){
      this.search.StatusDesc = "";
      this.objList = this.resultList
      $("#filter_options").children().removeClass("active");
      $("#filter_options").children().eq(0).addClass("active");
  }
  applyFilter(status, i) {
      this.search.StatusDesc = status;
      this.objList = this.resultList.filter(o => o.StatusDesc == status)
      $("#filter_options").children().removeClass("active");
      $("#filter_options").children().eq(i).addClass("active");
  }

  getNumOfCount(status) {
      var count = 0;
      if (status == "") return this.resultList.length;
      for (var i = 0; i < this.resultList.length; i++) {
          if (this.resultList[i].StatusDesc == status) count++;
      }
      return count;
  }

  showDetailPopup(target) {
      this.objDetail = target;
      this.displayPopupName = target.SourceType;

      switch (target.SourceType) {
          case 'MaritalStatus' :
              this.matDialog.open(PopupAmendInquiryMaritalComponent, {
                  panelClass: 'dialog-response',
                  data: this.objDetail
              })
              break;
          case 'BankAC' :
              this.matDialog.open(PopupAmendInquiryBankComponent, {
                  panelClass: 'dialog-response',
                  data: this.objDetail
              })
              break;
          case 'Contact' :
              this.matDialog.open(PopupAmendInquiryContactComponent, {
                  panelClass: 'dialog-response',
                  data: this.objDetail
              })
              break;
          case 'Address' :
            this.matDialog.open(PopupAmendInquiryAddressComponent, {
                panelClass: 'dialog-response',
                data: this.objDetail
            })
            break;
          case 'Depndt' :
            this.matDialog.open(PopupAmendInquiryDependtComponent, {
                panelClass: 'dialog-response',
                data: this.objDetail
            })
            break;
          case 'Quali' :
              this.matDialog.open(PopupAmendInquiryQualiComponent, {
                  panelClass: 'dialog-response',
                  data: this.objDetail
              })
            break;
              
              setTimeout(() => {
                $('#' + target.SourceType + ' .form_content_input').slideUp();
                $('#' + target.SourceType + ' .form_content_input').first().slideDown();
              }, 200);
              break;
      }

      $("#"+target.SourceType).fadeIn();
  }

  // this.onCallAPIFail = function (data, status, headers, config) {
      // this.commonUI.finishLoadingTask();
      // if (status == "200") {
          // this.errorUI.showErrorBox(data.ErrorCode);
      // } else {
          // $location.path("login");
      // }
  // }

  queryRecordList() {
      this.objList = [];

      this.commonUI.addLoadingTask();
      this.queryStatus = "";
      this.personalService.getProfileAmendDict(this.employee, this.queryStatus).subscribe((result) => {
        
        if(!result['conn_fail'])
          if(result.body['ErrorMsg'] == null) {
            this.objList = result.body['Data'].ProfileAmendList
            this.objList.forEach((record, key) => {
              record.keywords = "";
                  record.keywords += this.commonUI.formatDisplayDate(record.CreateDate, 'DD MMM YYYY (ddd) hh:mm A') + " ";
                  record.keywords += record.StatusDesc + " ";
                  record.keywords += record.SourceType + " ";
        
                  switch (record.SourceType) {
                      case 'MaritalStatus' :
                      case 'Contact' :
                          record.iconName = 'icon_general';
                          break;
                      case 'BankAC' :
                          record.iconName = 'icon_bank';
                          break;
                      case 'Address' :
                          record.iconName = 'icon_address';
                          break;
                      case 'Depndt' :
                          record.iconName = 'icon_dependents';
                          break;
                      case 'Quali' :
                          record.iconName = 'icon_qualification';
                          break;
                      default:
                          record.iconName = 'icon_general';
                  }
            });
              this.resultList = this.objList
              this.sortByType('SourceType')
              this.commonUI.finishLoadingTask();
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else 
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      },err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })

  }

  searchByInput() {
    if(this.search.keywords.length <= 0) return this.objList = this.resultList
    else this.objList = this.resultList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
