import { Component, OnInit, SystemJsNgModuleLoader } from '@angular/core';
import { CommonServiceService } from '../services/common-service.service'
import { CommonUIService } from '../services-UI/common-ui.service'
import { ErrorUIService } from '../services-UI/error-ui.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PopupPasswordFinishedComponent } from '../common/popup-password-finished/popup-password-finished.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  userid: number;
  email: string;
  title: string = "ForgotPassword";

  constructor(public commonService: CommonServiceService, public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public route: ActivatedRoute, public matDialog: MatDialog) {
    this.route.queryParams.subscribe(param => {
      if(param['token'] != undefined) {
        $("#form_forgot_pwd").hide();
        this.validateToken(unescape(param['token']));
    }
    })
  }

  ngOnInit(): void {
  }

  onCloseValidateToken() {
    this.commonService.navigatedLogin();
  }

  onCallForgetPwdAPIFail(data, status, headers, config) {
      this.commonService.onCallAPIFailWithCallBack(data, status, headers, config, function () { this.commonService.navigatedLogin(); });
  }

  forgetPwd() {
     if(!this.userid || !this.email) {
       this.commonUI.showMsgBoxWithCallback("error.00038");
       return;
     }

      var reg = new RegExp("^[A-Z0-9_%-]+(|([\.][A-Z0-9_%-]+)+)@[A-Z0-9_%-]+(|([\.][A-Z0-9_%-]+)+)$", "i");
      if (!reg.test(this.email)) {
          this.commonUI.showMsgBoxWithCallback("message.M0027");
          return;
      }

      this.commonUI.addLoadingTask();
      this.commonService.forgetPwd(this.userid, this.email).subscribe((result) => {
        
        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == null) {
            this.commonUI.finishLoadingTask();
            this.matDialog.open(PopupPasswordFinishedComponent, {
              panelClass: 'dialog-responsive'
            })
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  validateToken(token) {
      this.commonUI.addLoadingTask();

      this.commonService.validToken(token).subscribe((result) => {
        
        if (!result['conn_fail'])
          if (result.body['ErrorMsg'] == null) {
           this.commonUI.finishLoadingTask();
           this.commonUI.showMsgBoxWithCallback("message.M0006", function () {
              this.onCloseValidateToken();
           })
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  backToLogin() {
    this.commonService.navigatedLogin();
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}
