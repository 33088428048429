import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-amend-inquiry-contact',
  templateUrl: './popup-amend-inquiry-contact.component.html',
  styleUrls: ['./popup-amend-inquiry-contact.component.scss']
})
export class PopupAmendInquiryContactComponent implements OnInit {

  obj

  constructor(public matDialogRef: MatDialogRef<PopupAmendInquiryContactComponent>, @Inject(MAT_DIALOG_DATA) public data, public commonService: CommonServiceService, public commonUI: CommonUIService) { 
    this.obj = data
    this.obj.attachment = { downloaded: false, dataURL: "", ext: "" };
    if (this.obj.AttachmentLink !="") {
        this.commonService.downloadAttachment(this.obj.AttachmentLink, environment.GetFileExtension(this.obj.AttachmentName), this.obj.attachment).subscribe((result) => {
          console.log(result)
      }, err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
