import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { InterceptorService } from '../services/interceptor.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EclaimService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, private router: Router, private http: HttpClient, private translate: TranslateService, private interceptor: InterceptorService, private domSanitizer: DomSanitizer) { }

  getClaimBalance(employee, balanceClaimTypeCode, claimApplicationFormList, asAtDate) {

    var body = {
      "Action": environment.apiList.GetClaimBalance,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "ClaimTypeCode": balanceClaimTypeCode,
      "ClaimApplicationFormList": JSON.stringify(claimApplicationFormList),
      "AsAtDate": asAtDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      // res.body['Data']  = 
      //   {
      //     StaffInfo: {
      //       FullName: 'Lee Siu Ming',
      //       ImagePath: '',
      //       PositionDesc: 'Accounting',
      //       StaffID: '112233'
      //     },
      //     ClaimYearEnd: "2021-12-31T00:00:00",
      //     ClaimYearStart: "2021-01-01T00:00:00",
      //     Year: '2021',
      //     currentYearDetail: {
      //       AvailableBalance: 12000,
      //       NoOfRecord: 2,
      //       OpeningBalance: 1500,
      //       OpeningNoOfClaim: 2,
      //       EntitlementBalance: 1500,
      //       EntitlementNoOfClaim: 5,
      //       AdjustmentBalance: 0,
      //       AdjustmentNoOfClaim: 0,
      //       ClaimedBalance: 300,
      //       ClaimedNoOfClaim: 1
      //     }
      //   }
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  getClaimType(employee) {
    var body = {
      "Action": environment.apiList.GetClaimTypeList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      // var data = [
      //   {
      //     ClaimTypeDesc: "Body Check",
      //     MaxAmount: 3000,
      //     ClaimTypeCode: 'B001'
      //   },
      //   {
      //     ClaimTypeDesc: "Training",
      //     MaxAmount: 1500,
      //     ClaimTypeCode: 'B002'
      //   },
      //   {
      //     ClaimTypeDesc: "Running",
      //     MaxAmount: 1000,
      //     ClaimTypeCode: 'B003'
      //   }
      // ]
      // res.body['Data'] = data;
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  getCurrency(employee) {
    var body = {
      "Action": environment.apiList.GetCurrencyList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      // res.body['Data']  = ['HKD','USD','AUD']
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  submitClaimsApplication(employee, eclaims) {

    var body = {
      "Action": environment.apiList.SubmitClaimApplication,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "ClaimApplicationFormList": JSON.stringify(eclaims)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      // err['Data']  = eclaims
      return err
    }))

    return result;
  }

  getTeamEclaimApplicationList(employee, startDate, endDate, status, claimStatus, isExcessive) {
    var body = {
      "Action": environment.apiList.GetTeamEclaimApplicationList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "Status": status,
      "ClaimTypeCode": claimStatus,
      "IsExcessiveClaim": isExcessive
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      // var data = [
      //   { 
      //     StaffInfo: {
      //       FullName: 'Lee Siu Ming',
      //       ImagePath: '',
      //       PositionDesc: 'Accounting',
      //       StaffID: '112233'
      //     },
      //     ClaimTypeDesc: 'Body Check',
      //     ClaimTypeCode: 'B001',
      //     ClaimDate: '2021-07-22T18:00:00',
      //     VisitingDate: '2021-07-29T08:00:00',
      //     DollarType: 'HKD',
      //     ClaimAmount: 777,
      //     MaxAmount: 11000,
      //     CourseCode: 'ABC2020',
      //     Remarks: 'GGGGGGGGGGGGGGGGGG',
      //     Status: 'PEN',
      //     StatusDesc: 'Pending',
      //     IsExcessive: false,
      //     AdjustedAmount: 900,
      //     Attachments: [
      //       {
      //         AttachmentName: 'ABC.jpg',
      //         Size: 1444,
      //         dataURL: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
      //       }
      //     ],
      //     CreatedDate: '2021-07-15T09:00:00',
      //     IsWithdrawable: true,
      //     RID: 'KKKK12341233',
      //     Date: "2021-07-23T00:00:00"
      //   },
      //   { 
      //     StaffInfo: {
      //       FullName: 'Lee Chung Ming',
      //       ImagePath: '',
      //       PositionDesc: 'Accounting',
      //       StaffID: '112233'
      //     },
      //     ClaimTypeDesc: 'Training',
      //     ClaimTypeCode: 'B002',
      //     ClaimDate: '2021-07-30T14:00:00',
      //     VisitingDate: '2021-07-31T19:00:00',
      //     DollarType: 'HKD',
      //     ClaimAmount: 22200,
      //     MaxAmount: 10000000,
      //     CourseCode: 'ABC2022',
      //     Remarks: 'HHHHHHHHHHHHHHHHHHHHHHHH',
      //     Status: 'REJ',
      //     StatusDesc: 'Rejected',
      //     AdjustedAmount: 200,
      //     IsExcessive: true,
      //     Attachments: [],
      //     CreatedDate: '2021-07-15T09:00:00',
      //     IsWithdrawable: true,
      //     RID: 'KKKK12341232',
      //     Date: "2021-07-23T00:00:00"
      //   },
      //   { 
      //     StaffInfo: {
      //       FullName: 'Lee Tai Ming',
      //       ImagePath: '',
      //       PositionDesc: 'Accounting',
      //       StaffID: '112233'
      //     },
      //     ClaimTypeCode: 'B003',
      //     ClaimTypeDesc: 'Running',
      //     ClaimDate: '2021-08-01T11:00:00',
      //     VisitingDate: '2021-08-03T17:00:00',
      //     DollarType: 'HKD',
      //     ClaimAmount: 1100,
      //     MaxAmount: 5000,
      //     CourseCode: 'ABC2021',
      //     Remarks: 'RERERRERERERE',
      //     Status: 'APV',
      //     StatusDesc: 'Approved',
      //     IsExcessive: true,
      //     AdjustedAmount: 2000,
      //     Attachments: [],
      //     CreatedDate: '2021-07-15T09:00:00',
      //     IsWithdrawable: true,
      //     RID: 'KKKK12341231',
      //     Date: "2021-07-23T00:00:00"
      //   }
      // ]
      // if(status == '' && claimStatus == '') res.body['Data'] = data.filter((l) => l.IsExcessive == isExcessive);
      // else if(status == '') res.body['Data'] = data.filter((l) => l.ClaimTypeCode == claimStatus && l.IsExcessive == isExcessive);
      // else if(claimStatus == '') res.body['Data'] = data.filter((l) => l.StatusDesc == status && l.IsExcessive == isExcessive);
      // else res.body['Data'] = data.filter((l) => l.StatusDesc == status && l.ClaimTypeCode == claimStatus && l.IsExcessive == isExcessive);
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  getSelfEclaimApplicationList(employee, startDate, endDate, status, claimStatus, isExcessive) {
    var body = {
      "Action": environment.apiList.GetSelfEclaimApplicationList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "Status": status,
      "ClaimTypeCode": claimStatus,
      "IsExcessiveClaim": isExcessive
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      // var data = [
      //   { 
      //     StaffInfo: {
      //       FullName: 'Lee Siu Ming',
      //       ImagePath: '',
      //       PositionDesc: 'Accounting',
      //       StaffID: '112233'
      //     },
      //     ClaimTypeDesc: 'Body Check',
      //     ClaimTypeCode: 'B001',
      //     ClaimDate: '2021-07-22T18:00:00',
      //     VisitingDate: '2021-07-29T08:00:00',
      //     DollarType: 'HKD',
      //     ClaimAmount: 777,
      //     MaxAmount: 11000,
      //     CourseCode: 'ABC2020',
      //     Remarks: 'GGGGGGGGGGGGGGGGGG',
      //     Status: 'PEN',
      //     StatusDesc: 'Pending',
      //     IsExcessive: false,
      //     AdjustedAmount: 500,
      //     Attachments: [
      //       {
      //         AttachmentName: 'ABC.jpg',
      //         Size: 1444,
      //         dataURL: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
      //       }
      //     ],
      //     CreatedDate: '2021-07-15T09:00:00',
      //     IsWithdrawable: true,
      //     RID: 'KKKK12341233',
      //     Date: "2021-07-23T00:00:00"
      //   },
      //   { 
      //     StaffInfo: {
      //       FullName: 'Lee Siu Ming',
      //       ImagePath: '',
      //       PositionDesc: 'Accounting',
      //       StaffID: '112233'
      //     },
      //     ClaimTypeDesc: 'Training',
      //     ClaimTypeCode: 'B002',
      //     ClaimDate: '2021-07-30T14:00:00',
      //     VisitingDate: '2021-07-31T19:00:00',
      //     DollarType: 'HKD',
      //     ClaimAmount: 22200,
      //     MaxAmount: 10000000,
      //     CourseCode: 'ABC2022',
      //     Remarks: 'HHHHHHHHHHHHHHHHHHHHHHHH',
      //     Status: 'REJ',
      //     StatusDesc: 'Rejected',
      //     IsExcessive: false,
      //     AdjustedAmount: 1000,
      //     Attachments: [],
      //     CreatedDate: '2021-07-15T09:00:00',
      //     IsWithdrawable: true,
      //     RID: 'KKKK12341232',
      //     Date: "2021-07-23T00:00:00"
      //   },
      //   { 
      //     StaffInfo: {
      //       FullName: 'Lee Siu Ming',
      //       ImagePath: '',
      //       PositionDesc: 'Accounting',
      //       StaffID: '112233'
      //     },
      //     ClaimTypeDesc: 'Running',
      //     ClaimTypeCode: 'B003',
      //     ClaimDate: '2021-08-01T11:00:00',
      //     VisitingDate: '2021-08-03T17:00:00',
      //     DollarType: 'HKD',
      //     ClaimAmount: 1100,
      //     MaxAmount: 5000,
      //     CourseCode: 'ABC2021',
      //     Remarks: 'RERERRERERERE',
      //     Status: 'APV',
      //     StatusDesc: 'Approved',
      //     IsExcessive: true,
      //     AdjustedAmount: 1500,
      //     Attachments: [],
      //     CreatedDate: '2021-07-15T09:00:00',
      //     IsWithdrawable: true,
      //     RID: 'KKKK12341231',
      //     Date: "2021-07-23T00:00:00"
      //   }
      // ]
      // if(status == '' && claimStatus == '') res.body['Data'] = data.filter((l) => l.IsExcessive == isExcessive);
      // else if(status == '') res.body['Data'] = data.filter((l) => l.ClaimTypeCode == claimStatus && l.IsExcessive == isExcessive);
      // else if(claimStatus == '') res.body['Data'] = data.filter((l) => l.StatusDesc == status && l.IsExcessive == isExcessive);
      // else res.body['Data'] = data.filter((l) => l.StatusDesc == status && l.ClaimTypeCode == claimStatus && l.IsExcessive == isExcessive);
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  withdrawEClaimApplication(employee, claimApplication) {
    var body = {
      "Action": environment.apiList.WithdrawEClaimApplication,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "InOutApplicationRID": claimApplication.RID,
      "InOutApplicationDate": claimApplication.Date
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.WithdrawEInOutAppilcation,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "InOutApplicationRID": inOutTimeApplication.RID,
    //         "InOutApplicationDate": inOutTimeApplication.Date
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getProjectCodeList(employee) {
    var body = {
      "Action": environment.apiList.GetProjectCodeList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  getCarCodeList(employee) {
    var body = {
      "Action": environment.apiList.GetCarCodeList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  getExpenseTypeCodeList(employee) {
    var body = {
      "Action": environment.apiList.GetExpenseTypeCodeList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }

  getMAINVOCodeList(projectCode, employee) {
    var body = {
      "Action": environment.apiList.GetMAINVOCodeList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "ProjectCode": projectCode
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
  }


}
