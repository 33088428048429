import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { LoginComponent } from 'src/app/login/login.component';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-popup-logout',
  templateUrl: './popup-logout.component.html',
  styleUrls: ['./popup-logout.component.scss']
})
export class PopupLogoutComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<PopupLogoutComponent>, public commonUI: CommonUIService, public commonService: CommonServiceService, public idle: Idle, public router: Router, public loginComp: LoginComponent) { 

  }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

  logout() {
    this.commonUI.addLoadingTask();
    this.commonService.logout().subscribe((res) => {
        this.onLogoutSuccess()
    }, err => {
      if(err['conn_fail']) {
        this.onCallLoginAPIFail(err.body, err.status, err.headers, err['Data'])
        this.onLogoutSuccess()
      }
    })
  }
  
  onLogoutSuccess() {
    // this.Idle.unwatch();
    $('#pp1_login_content').fadeOut();
    $('.pp3_content').fadeOut();
    this.commonUI.finishLoadingTask();
    this.loginComp.password = "";
    sessionStorage.clear();
    this.idle.stop();
    this.close();
    
    if (environment.isV11Container)
      window.location.href = `${document.baseURI}api/auth/logout`;
    else
      this.commonService.navigatedLogin()   //Return to base and redirect to login/maintenance
  }

  onCallLoginAPIFail(data, status, headers, config) {
    this.loginComp.password = "";
    this.loginComp.loginInprogress = false;
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
