<div>
    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'menu.report' | translate }}</span>
    </div>
    <div class="list_result_area content_box content_box_show">



        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_taxform"></div>
            <div class="fl">{{ 'menu.reporttaxsummary' | translate }}</div>
        </div>


        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">
            <div style="clear:both;"></div>

        </div><!-- end list_bar -->
        <!-- ============ search result txt  ============ -->
        <!--
        <div id="search_bar_result">
            <a rel="external" href="#list_result">
            <div id="search_close"><img alt="" src="img/btn_cross_grey.png" width="20"/></div></a>
                Search result of "&nbsp;<span id="search_bar_result_show" class="txt_size_2 maincolor_txt"></span>&nbsp;"<br/><span class="txt_grey_2">(2 staffs)</span>
        </div>

        <div style="clear:both;"></div>-->

        <div style="clear:both;"></div>
        <hr class="m0">
        <!-- ============ list_data ============ -->
        <div id="filter_holder" class="list_data" *ngFor="let taxSummary of taxSummarylist" (click)="viewTaxSummary(taxSummary)">

            <!-- ============  list1box  ============ -->
            <div class="list_1_box f_2016">
                <div>
                    <div class="txt_photo_area_medium" [ngStyle]="{'background-image' : 'url(assets/img/' + commonUI.GetFileIcon(fileFormat) + ')'}">
                        <div class="txt_photo_mask_small"></div>
                    </div>

                    <div class="txt_content_area_1line txt_size_2">
                        <div class="txt_name_2">{{ taxSummary }}</div>
                    </div>

                    <div style="clear:both;"></div>
                </div>
            </div>
        </div>
    </div> <!-- end id=content_box -->
    <div style="display:none"><iframe id="pdf_target" name="pdf_target" style="width:100%;height:100%"></iframe></div>
</div>
