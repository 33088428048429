<div class="flag_submit_remarks_content" *ngIf='nextApproverList'>
    <div class="pop_functions_box pop_functions_box_size_tc">
        <div class="pop_functions_close btn_grey_2b list_flag" (click)="close()">x</div>
    	<div style="clear:both"></div>
        
        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            	<div class="box_title_icon t_pendingapproval"></div>
            	<div class="fl">{{ "show-next-approver" | translate }}</div>
        </div>
        
        <div class="pop_functions_box_content_tc">
        	<div class="box_1_staff" *ngFor='let nextApprover of nextApproverList'>
                <div class="txt_photo_area_big fl" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(nextApprover) + ')'}">
                    <div class="txt_photo_mask_big p_normal_grey_2"></div>
                </div> 
                <div class="box_1_staff_name">
                    <span class="txt_size_2">{{nextApprover.FullName}}</span><br>
                    <span class="txt_size_3 txt_grey_2">{{nextApprover.PositionDesc}}</span>
                </div> 
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
</div>
