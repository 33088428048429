import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonHandlerService } from 'src/app/services/common-handler.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-popup-amend-inquiry-quali',
  templateUrl: './popup-amend-inquiry-quali.component.html',
  styleUrls: ['./popup-amend-inquiry-quali.component.scss']
})
export class PopupAmendInquiryQualiComponent implements OnInit {

  obj

  constructor(public matDialogRef: MatDialogRef<PopupAmendInquiryQualiComponent>, @Inject(MAT_DIALOG_DATA) public data, public commonUI: CommonUIService, public commonService: CommonServiceService, public commonHandler: CommonHandlerService) { 
    this.obj = data
    this.obj.SubRecordList.forEach((record, key) => {
      record.attachment = { downloaded: false, dataURL: "", ext: "" };
      if (record.AttachmentLink !="") {
          this.commonService.downloadAttachment(record.AttachmentLink, environment.GetFileExtension(record.AttachmentName), record.attachment).subscribe((result) => {
              console.log(result)
          }, err => {
              this.onCallAPIFail(err.body, err.status, err.headers, err)
          })
      }
    });
  }

  ngOnInit(): void {
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  slideUp(event) {
    $('#' + this.obj.SourceType + ' .form_content_input').slideUp();
    $('#' + this.obj.SourceType + ' #' + event.currentTarget.id + ' .form_content_input').slideToggle();
}

}
