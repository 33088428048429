<div id="popup_reset_password">
    <div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_jobposition">
        <br/><br/>
        <div style="clear:both"></div>
        <span class="txt_size_1 txt_grey_2">{{ 'toolbar.resetpassword' | translate }}</span>
        <br/><br/>
  
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="currentPassword" placeholder="{{ 'toolbar.currentpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <br/>
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="newPassword" placeholder="{{ 'toolbar.newpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <div class="input_login_position">
              <input type="password" value="" [(ngModel)]="newPassword2" placeholder="{{ 'toolbar.confirmnewpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
              <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
          </div>
  
          <br/>
          <div class="pp2 btn_opacity txt_size_3" style="text-align: left;padding-left: 20px;" (click)="showModalPasswordPolicy()">{{ 'toolbar.passwordpolicy' | translate }}</div>
  
          <div class="pop_functions_one_btn_center">
              <button (click)="doResetPassword()" class="btn btn-block btn-lg one_btn maincolor_btn2 flag_submit_finish pp_close" type="submit" name="submit"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'confirm' | translate }}</button>
          </div>
  
          
          <div style="clear: both"></div>
    </div>
  </div>