import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupDelegationSettingsComponent } from '../common/popup-delegation-settings/popup-delegation-settings.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ApprovalService } from '../services/approval.service';
import { CommonServiceService } from '../services/common-service.service';
import { IdleTimerService } from '../services/idle-timer.service';

@Component({
  selector: 'app-approval-center-delegation-settings',
  templateUrl: './approval-center-delegation-settings.component.html',
  styleUrls: ['./approval-center-delegation-settings.component.scss']
})
export class ApprovalCenterDelegationSettingsComponent implements OnInit {

  employee = JSON.parse(sessionStorage.Employee);
  showSortsMenu;
  search = {
    keyword: '',
  }
  reverse = true;
  propertyName = 'EffStartDate';
  sourceTypeList;
  settings;
  resultList;
  multiMode;
  selectedIndex;
  currentSetting;

  constructor(public commonUI: CommonUIService, public commonService: CommonServiceService, public matDialog: MatDialog, public approvalService: ApprovalService) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.getDelegationSettings();
  }

  ngOnDestroy(): void {
  }

  addDelegationSetting() {
    this.selectedIndex = -1;
    this.currentSetting = {}
    const dialog = this.matDialog.open(PopupDelegationSettingsComponent, {
      panelClass: 'dialog-responsive',
      data: {
        currentSetting: this.currentSetting,
        selectedIndex: this.selectedIndex,
        settings: this.settings
      }
    })
    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data.confirm) this.getDelegationSettings()
    })
  }

  showSortsMenuToggle() {
    if (!this.showSortsMenu) {
      this.showSortsMenu = true;
    } else {
      this.showSortsMenu = false;
    }
  }

  searchByInput() {
    if (this.search.keyword.length <= 0) return this.settings = this.resultList
    else this.settings = this.resultList.filter((l) => l.keyword.toLowerCase().includes(this.search.keyword.toLowerCase()))
  }

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch (propertyName) {
      case 'StaffInfo.FullName':
        this.settings = this.settings.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'StaffInfo.PositionCode':
        this.settings = this.settings.sort((a, b) => {
          return a.StaffInfo.PositionCode.localeCompare(b.StaffInfo.PositionCode)
        })
        break;
      case 'EffStartDate':
        this.settings = this.settings.sort((a, b) => {
          return new Date(b.EffStartDate).getTime() - new Date(a.EffStartDate).getTime()
        })
        break;
    }
    if(this.reverse) this.settings = this.settings.slice().reverse()

    this.showSortsMenu = false;
  }

  showPopupSettingDetail(objIndex) {
    this.selectedIndex = objIndex;
    this.settings.filter((s) => s.index == objIndex? this.currentSetting = s : {})
    this.currentSetting.EffStart = this.commonUI.parseJsonDate(this.currentSetting.EffStartDate);
    this.currentSetting.EffEnd = this.commonUI.parseJsonDate(this.currentSetting.EffEndDate);
    this.currentSetting.LeaveStart = this.commonUI.parseJsonDate(this.currentSetting.LeaveStartDate);
    this.currentSetting.LeaveEnd = this.commonUI.parseJsonDate(this.currentSetting.LeaveEndDate);
    const dialog = this.matDialog.open(PopupDelegationSettingsComponent, {
      panelClass: 'dialog-responsive',
      data: {
        currentSetting: this.currentSetting,
        selectedIndex: this.selectedIndex,
        settings: this.settings
      }
    })
    dialog.afterClosed().subscribe((data) => {
      if(data == undefined || data == null) return;
      if(data.confirm) this.getDelegationSettings();
    })
}

  getDelegationSettings() {
    this.commonUI.addLoadingTask();
    this.approvalService.getDelegationSettings(this.employee).subscribe((result) => {
      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          if (result.body['Data'].DelegationSettings != undefined) {
            this.settings = result.body['Data'].DelegationSettings;
            this.resultList = this.settings
            for (var i = 0; i < this.settings.length; i++) {
              this.settings[i].index = i;
              this.settings[i].keyword = this.settings[i].StaffInfo.FullName + " " + this.settings[i].StaffInfo.StaffID + " " + this.settings[i].LeaveStartDate + " " + this.settings[i].LeaveEndDate + " " + this.settings[i].EffStartDate + " " + this.settings[i].EffEndDate;
            }
          }
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
