<div>

    <div class="title_reset_2">
            <span class="txt_size_1 txt_grey_2">{{'menu.personalprofile' | translate }}</span><!--
<p><span class="txt_grey_2"> Last login : </span><span>11 Oct 2016 (Thu) - 1:15pm</span><span class="txt_grey_2">.</span></p>-->
    </div>

    <!-- =========== Search ==========

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_pendingapproval"></div>
            <div class="fl">Pending Approval</div>
        </div>


    </div>-->



    <!-- =========== Result ========== -->
    <a name="list_result" class="list_bar_mt"></a>
    <div class="list_result_area content_box content_box_show">



        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_profileamendinquiry"></div>
            <div class="fl">{{'menu.personalprofileinquiry' | translate }}</div>
        </div><!--
        <div class="box_title_sub maincolor_txt">Sales Department 2016</div>-->

        <!--
        <div class="txt_result">
            <div class="txt_result_title txt_grey_2">Result : </div>
            <div class="txt_result_item_area">
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Click to change the inquiry">
                    <div class="txt_result_item bg_grey_3">All Leaves</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Click to change the inquiry">
                    <div class="txt_result_item bg_grey_3">2016</div>
                </a>
                <a rel="external" href="#back_top_page" class="btn_filter_inquiry" title="Click to change the inquiry">
                    <div class="txt_result_item bg_grey_3"> Subordinate</div>
                </a>
            </div>
        </div>-->

        <div style="clear:both;"></div>

        <!-- ============ list's area ============ -->

        <div class="list_bar">

            <div id="p4" (click)="showSortsMenuToggle()" class="header_bar_icon fl list_icon_border mt0 ml0" title="Sorts"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list" *ngIf="showSortsMenu" style="display: block;">
                <div class="alert_box_arrow arrow_position_5"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'personalprofileinquiry.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <a (click)="sortByType('SourceType')"><div class="alert_box_content_item">{{ 'personalprofileinquiry.sortbytype' | translate }}</div></a>
                        <a (click)="sortByType('CreateDate')"><div class="alert_box_content_item">{{ 'personalprofileinquiry.sortbydate' | translate }}</div></a>
                        <a (click)="sortByType('StatusDesc')"><div class="alert_box_content_item">{{ 'personalprofileinquiry.sortbystatus' | translate }}</div></a>
                    </div>
                </div>
            </div>


            <input id="myInp" type="text" name="search" placeholder="{{ 'search' | translate }}" size="15" maxlength="15" [(ngModel)]="search.keywords" (ngModelChange)='searchByInput()'><button class="btn_search btn_opacity" title="Search" type="submit"></button>


            <div style="clear:both;"></div>

        </div><!-- end list_bar -->


        <!-- ============ search result txt  ============ -->
        <div id="search_bar_result">
            <a rel="external" href="#list_result">
            <div id="search_close"><img alt="" src="assets/img/btn_cross_grey.png" width="20"/></div></a>
                Search result of "&nbsp;<span id="search_bar_result_show" class="txt_size_2 maincolor_txt"></span>&nbsp;"<br/><span class="txt_grey_2">(2 staffs)</span>
        </div>

        <div style="clear:both;"></div>

        <a name="list_result_filter" class="list_bar_mt"></a>

        <ul id="filter_options" *ngIf='resultList'>
            <li class="active" (click)="clearFilter()" id="filterItem_0" >
                <a href="javascript:void(0)" class="f_all">{{ 'personalprofileinquiry.status_all' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfCount("")}}</div>
            </li>
            <li (click)="applyFilter('Pending', 1)" id="filterItem_1" >
                <a href="javascript:void(0)" class="f_pending">{{ 'personalprofileinquiry.status_pending' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfCount("Pending")}}</div>
            </li>
            <li (click)="applyFilter('Approved', 2)" id="filterItem_2" >
                <a href="javascript:void(0)" class="f_approved">{{ 'personalprofileinquiry.status_approved' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfCount("Approved")}}</div>
            </li>
            <li (click)="applyFilter('Rejected', 3)" id="filterItem_3" >
                <a href="javascript:void(0)" class="f_rejected">{{ 'personalprofileinquiry.status_rejected' | translate }}</a>
                <div class="filter_icon_num">{{getNumOfCount("Rejected")}}</div>
            </li>
        </ul>

        <div style="clear:both;"></div>

        <hr class="m0">


        <!-- ============ list_data ============ -->
        <div id="filter_holder" class="list_data page_profile_amend_inquiry" *ngIf='objList'>

        <div *ngFor="let obj of objList">
            <!-- ============  list1box  ============ -->
            <div class="list_1_box list_flag f_pending" (click)="showDetailPopup(obj)">
                <div>
                        <div class="txt_photo_area_medium" [ngStyle]="{'background-image': 'url(' + 'assets/img/' + obj.iconName + '.png)'}">
                            <div class="txt_photo_mask_small p_{{ obj.StatusDesc | lowercase}}"></div>
                        </div>

                        <div class="txt_content_area_2line txt_size_2">
                            <div class="txt_name">{{ 'personalprofileinquiry.typeDesc.' + obj.SourceType | translate }}</div>
                            <div class="txt_date txt_grey_1">
                                {{commonUI.formatDisplayDate(obj.CreateDate, 'DD MMM YYYY')}}
                                <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.CreateDate, 'ddd')}})</span>
                                {{commonUI.formatDisplayDate(obj.CreateDate, ' h:mm A')}}
                            </div>
                            <div class="txt_status"><span class="txt_grey_2 txt_size_3">{{ 'personalprofileinquiry.status' | translate }}&nbsp;</span>{{ obj.StatusDesc | lowercase | translate }}</div>
                        </div>

                        <div style="clear:both;"></div>
                </div>
            </div>
        </div>



            <div class="f_all f_pending f_approved f_rejected" style="clear:both; height:initial;padding-top: initial;"></div>
         </div><!-- ============ end list_data ============ -->



    </div> <!-- end id=content_box -->