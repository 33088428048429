<div class="list_flag-selected" id="{{ popupid }}">
    <div
         [ngClass]='popupmode == "READONLY"? "pop_functions_box pop_functions_box_size_2column":"pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval"'
    >
        <div class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image': 'url(' + commonUI.getStaffImage(appointment.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
                <span class="txt_popup_staffname">{{appointment.StaffInfo.FullName}}</span><br>
                <span class="txt_popup_position">{{appointment.StaffInfo.PositionDesc}}</span><br>
                <span class="txt_popup_staffid">ID : {{ appointment.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

            <div class="detail_box_bottom_2point">
                <div class="detail_box_catalog_area">
                    <div class="maincolor_bg color_label_{{ appointment.StatusDesc | lowercase }}">{{ appointment.StatusDesc | lowercase | translate }}</div>
                </div>
                <div class="detail_box_session1 txt_grey_2 txt_size_3">{{ commonUI.formatDisplayDate(appointment.AppointStart, 'h:mm a') | uppercase }}</div>
                <div class="detail_box_date1 txt_size_1">{{ commonUI.formatDisplayDate(appointment.AppointStart, 'DD MMM YYYY') }}<span class="txt_size_3">&nbsp;({{ commonUI.formatDisplayDate(appointment.AppointStart, 'ddd') }})</span></div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ commonUI.formatDisplayDate(appointment.AppointEnd, 'h:mm a') | uppercase }}</div>
                <div class="detail_box_date2 txt_size_1">{{ commonUI.formatDisplayDate(appointment.AppointEnd, 'DD MMM YYYY') }}<span class="txt_size_3">&nbsp;({{ commonUI.formatDisplayDate(appointment.AppointEnd, 'ddd') }})</span></div>
                <div class="detail_box_sub_icon d_box_sub_icon_appointment"></div>
            </div>

            <div class="detail_box_other txt_size_1">

                <div class="txt_grey_2 txt_size_3">{{ 'appointmentapplication.submittedon' | translate }}</div>
                {{commonUI.formatDisplayDate(appointment.CreateDate, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(appointment.CreateDate, 'ddd')}})</span>&nbsp;{{commonUI.formatDisplayDate(appointment.CreateDate, 'h:mm A')}}

                <div class="txt_grey_2 txt_size_3" *ngIf='appointment.Event'>
                    {{ 'appointmentapplication.event' | translate }}
                    <div class="approver_staff_remark_2 txt_box_1 txt_grey_1 txt_size_2">{{ appointment.Event }}</div>
                </div>
                <br *ngIf='appointment.Event' />

                <div class="txt_grey_2 txt_size_3">
                    {{ 'appointmentapplication.details' | translate }}
                    <div class="approver_staff_remark_2 txt_box_1 txt_grey_1 txt_size_2">{{ appointment.Desc }}</div>
                </div>


            </div>

        </div>


        <div class="detail_box_bottom_area mt0_m">

            <div class="txt_grey_2 txt_size_3">{{ 'approval.approverremarks' | translate }}</div>
            <div class="detail_box_approver_remarks_area" *ngIf='approverRemarkList'>
                <div *ngFor="let approverRemark of approverRemarkList">
                    <div>
                        <div class="txt_photo_area_small fl" [ngStyle]="{'background-image': 'url(' + commonUI.getStaffImage(approverRemark.StaffInfo) + ')'}">
                            <div class="txt_photo_mask_small p_normal_grey"></div>
                        </div>
                        <div class="approver_staff_content">
                            <div class="txt_bold">{{ approverRemark.StaffInfo?.FullName }}</div>
                            <div class="txt_grey_2 txt_size_3">{{commonUI.formatDisplayDate(approverRemark.ProcessDate, 'D MMM YYYY (ddd) - h:mm A')}}</div>
                            <div class="approver_staff_remark txt_box_1">{{ approverRemark.Remark }}</div>
                        </div>
                        <div style="clear:both"></div>
                    </div>

                    <hr class="hr_profile">
                </div>
            </div>
            <button class="btn_next_approver" (click)="commonUI.showNextApprover(appointment)">
                <img src="assets/img/btn_show_next_approver.png" width="18">
                {{'show-next-approver' | translate}}
            </button>
        </div>
    </div>

    <div *ngIf="popupmode == 'EDIT'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn maincolor_btn2 flag_edit" ng-click="popupcontrol.showEditPopup()"><img src="assets/img/btn_edit_w.png" width="18">{{ 'appointmentapplication.edit' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
    <div *ngIf="popupmode == 'WITHDRAWALNOEDIT'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn btn_red flag_submit_remarks pp_close" (click)="showWithdrawConfirm()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'appointmentapplication.withdraw' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
    <div *ngIf="popupmode == 'WITHDRAWAL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_edit" ng-click="popupcontrol.showEditPopup()"><img src="assets/img/btn_edit_w.png" width="18">{{ 'appointmentapplication.edit' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_red flag_submit_remarks pp_close" (click)="showWithdrawConfirm()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'appointmentapplication.withdraw' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
    <div *ngIf="popupmode == 'APPROVAL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="showApprovePopup()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" (click)="showRejectPopup()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

</div>

<div class="flag_edit_content pop_functions_div" id="{{ editPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_1column">
        <div class="pop_functions_close btn_grey_2b flag_submit_remarks_close" ng-click="popupcontrol.closeEditPopUp()">x</div>
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">Edit of Appointment</div>
        </div>

        <form name="form_application" novalidate>
            <div class="edit_profile_box">
                <div>
                    <span class="txt_grey_2 txt_size_3">{{ 'appointmentapplication.startdate' | translate }}</span><br>

                    <div class="fl input_new_calendar">
                        <div>
                            <!-- <am-date-picker [(ngModel)]="appointmentForEdit.AppointStartDate" am-show-input-icon="true" am-allow-clear="false" /> -->
                            <div>
                                <mat-form-field appearance='fill' (click)="startPicker.open()">
                                    <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate" [min]='minDate' [max]='maxDate' readonly name="startDatePicker">
                                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startPicker (opened)="openAppend('start')"></mat-datepicker>
                                </mat-form-field>
                                <div style="display: none">
                                    <div class="datepicker-footer" #startFooter>
                                        <button mat-raised-button  (click)="today('start')" #todayButton name="startDateButton">
                                            {{'TODAY' | translate}}
                                        </button>
                                        <button mat-raised-button (click)="closePicker('start')" #doneButton>
                                            {{'close' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="time_field txt_grey_2">

                        <div class="fl">
                            <select id="h1" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointmentForEdit.AppointStartHour" name='AppointStartHour'>
                                <option *ngFor="let hour of commonUI.hourRange()" title="{{hour}}" value="{{hour}}">{{hour}}</option>
                            </select>
                        </div>
                        <div class="fl">&nbsp;:&nbsp;</div>
                        <div class="fl">
                            <select id="m1" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointmentForEdit.AppointStartMinute" name='AppointStartMinute'>
                                <option *ngFor="let minute of commonUI.minuteRange()" title="{{minute}}" value="{{minute}}">{{minute}}</option>
                            </select>
                        </div>
                        <div class="fl">&nbsp;&nbsp;</div>
                        <div class="fl">
                            <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointmentForEdit.AppointStartDayPart" name='AppointStartDayPart'>
                                <option title="00" value="AM">{{ 'appointmentapplication.am' | translate }}</option>
                                <option title="01" value="PM">{{ 'appointmentapplication.pm' | translate }}</option>
                            </select>

                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>

                <div>
                    <span class="txt_grey_2 txt_size_3">{{ 'appointmentapplication.enddate' | translate }}</span><br />

                    <div class="fl input_new_calendar">
                        <div>
                            <!-- <am-date-picker [(ngModel)]="appointmentForEdit.AppointEndDate" am-show-input-icon="true" am-allow-clear="false" /> -->
                            <div>
                                <mat-form-field appearance='fill' (click)="endPicker.open()">
                                    <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" [min]='minDate' [max]='maxDate' readonly name="endDatePicker">
                                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endPicker (opened)="openAppend('end')"></mat-datepicker>
                                </mat-form-field>
                                <div style="display: none">
                                    <div class="datepicker-footer" #endFooter>
                                        <button mat-raised-button  (click)="today('end')" #todayButton>
                                            {{'TODAY' | translate}}
                                        </button>
                                        <button mat-raised-button (click)="closePicker('end')" #doneButton>
                                            {{'close' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="time_field txt_grey_2">

                        <div class="fl">
                            <select id="h2" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointmentForEdit.AppointEndHour" name='AppointEndHour'>
                                <option *ngFor="let hour of commonUI.hourRange()" title="{{hour}}" value="{{hour}}">{{hour}}</option>
                            </select>
                        </div>
                        <div class="fl">&nbsp;:&nbsp;</div>
                        <div class="fl">
                            <select id="m2" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointmentForEdit.AppointEndMinute" name='AppointEndMinute'>
                                <option *ngFor="let minute of commonUI.minuteRange()" title="{{minute}}" value="{{minute}}">{{minute}}</option>
                            </select>
                        </div>
                        <div class="fl">&nbsp;&nbsp;</div>
                        <div class="fl">
                            <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointmentForEdit.AppointEndDayPart" name='AppointEndDayPart'>
                                <option title="00" value="AM">{{ 'appointmentapplication.am' | translate }}</option>
                                <option title="01" value="PM">{{ 'appointmentapplication.pm' | translate }}</option>
                            </select>

                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>

                <div>
                    <span class="txt_grey_2 txt_size_3">{{ 'appointmentapplication.event' | translate }}</span><br>
                    <textarea name="textarea_a" [(ngModel)]="appointmentForEdit.Event" class="form-control input_profile_manyline txt_box_1 bg_none txt_grey_1 txt_size_2"></textarea>
                </div>

                <div>
                    <span class="txt_grey_2 txt_size_3">{{ 'appointmentapplication.details' | translate }}</span><br>
                    <textarea name="textarea_b" [(ngModel)]="appointmentForEdit.Desc" class="form-control input_profile_manyline txt_box_1 bg_none txt_grey_1 txt_size_2" required="appointmentForEdit.MustInputEventDetail"></textarea>
                </div>

            </div>
        </form>
        <hr class="hr_profile">

        <div class="pop_functions_two_btn_center">
            <!--class="two_btn_fixed_bottom"-->
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish" ng-click="popupcontrol.submitEdit(form_application)"><img src="assets/img/btn_tick_w.png" width="18">{{ 'save' |translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" ng-click="popupcontrol.closeEditPopUp()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'cancel' |translate }}</button>
            <div style="clear:both"></div>
        </div>
    </div>
</div>