<div id="{{ popupid }}" class="list_flag-selected">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval">
        <div class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(inOutTimeApplication.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
                <span class="txt_popup_staffname">{{ inOutTimeApplication.StaffInfo.FullName }}</span><br>
                <span class="txt_popup_position">{{ inOutTimeApplication.StaffInfo.PositionDesc }}</span><br>
                <span class="txt_popup_staffid">ID : {{ inOutTimeApplication.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

            <div class="detail_box_bottom_1point thin">
                <div class="detail_box_catalog_area">
                    <div class="maincolor_bg color_label_{{ inOutTimeApplication.StatusDesc | lowercase }}">{{ inOutTimeApplication.StatusDesc }}</div>
                </div>
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(inOutTimeApplication.Date, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(inOutTimeApplication.Date, 'ddd')}})</span></div>
                <div class="detail_box_sub_icon d_box_sub_icon_appointment"></div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'inouttimeapplicationinquiry.shift' | translate }}</div>
                <div class="detail_box_date_num maincolor_txt"><span class="txt_size_0">{{ inOutTimeApplication.ShiftCode }}</span></div>
                <div class="detail_box_type">{{commonUI.formatDisplayDate(inOutTimeApplication.ShiftStart, 'h:mm A')}}&nbsp;-&nbsp;{{commonUI.formatDisplayDate(inOutTimeApplication.ShiftEnd, 'h:mm A')}}</div>
            </div>

            <div class="detail_box_other txt_size_1">

                <div class="txt_grey_2 txt_size_3">{{ 'inouttimeapplicationinquiry.submittedon' | translate }}</div>
                {{commonUI.formatDisplayDate(inOutTimeApplication.CreateDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(inOutTimeApplication.CreateDate, 'ddd')}})</span>&nbsp;{{commonUI.formatDisplayDate(inOutTimeApplication.CreateDate, 'h:mm A')}}
                <br />


                <div class="txt_grey_2 txt_size_3">
                    {{ 'inouttimeapplicationinquiry.punch' | translate }}
                    <div class="remark_tabs" style="height: 155px;">
                        <div class="tab first" style="margin-left: 37px;">
                            <input type="radio" id="in-out-tab-r1" name="tab-group-1" checked>
                            <label for="in-out-tab-r1">{{ 'inouttimeapplicationinquiry.adjusted' | translate }}</label>

                            <div class="content txt_box_1 txt_grey_1 txt_size_2">
                                <div class="col col2">

                                    <div style="display:none">
                                        <div>Time</div>
                                        <div>Detail</div>
                                        <div style="clear:both; float:none"></div>
                                    </div>

                                    <div *ngFor="let adjustedList of inOutTimeApplication.newPunchList">
                                        <div>
                                            {{commonUI.formatDisplayDate(adjustedList.PunchDateTime, 'h:mm A')}}
                                            <br>
                                            <span class="txt_size_3 txt_grey_2">{{commonUI.formatDisplayDate(adjustedList.PunchDateTime, 'DD MMM YYYY (A)')}}</span>
                                        </div>
                                        <div>
                                            <span class="txt_label bc_green txt_size_3" *ngIf="adjustedList.Status == 'O'">{{ 'inouttimeapplicationinquiry.outtime' | translate }}</span>
                                            <span class="txt_label bc_blue txt_size_3" *ngIf="adjustedList.Status == 'I'">{{ 'inouttimeapplicationinquiry.intime' | translate }}</span>
                                            <br>
                                            <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplicationinquiry.site' | translate }}</span>
                                            {{ adjustedList.PunchLocation }}
                                        </div>
                                        <div style="clear:both; float:none"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="in-out-tab-r2" name="tab-group-1">
                            <label for="in-out-tab-r2">{{ 'inouttimeapplicationinquiry.current' | translate }}</label>

                            <div class="content txt_box_1 txt_grey_1 txt_size_2">
                                <div class="col col2">
                                    <div style="display:none">
                                        <div>Time</div>
                                        <div>Detail</div>
                                        <div style="clear:both; float:none"></div>
                                    </div>

                                    <div *ngFor="let currentList of inOutTimeApplication.orgPunchList">
                                        <div>
                                            {{commonUI.formatDisplayDate(currentList.PunchDateTime, 'h:mm A')}}
                                            <br>
                                            <span class="txt_size_3 txt_grey_2">{{commonUI.formatDisplayDate(currentList.PunchDateTime, 'DD MMM YYYY (A)')}}</span>
                                        </div>
                                        <div>
                                            <span class="txt_label bc_green txt_size_3" *ngIf="currentList.Status == 'O'">{{ 'inouttimeapplicationinquiry.outtime' | translate }}</span>
                                            <span class="txt_label bc_blue txt_size_3" *ngIf="currentList.Status == 'I'">{{ 'inouttimeapplicationinquiry.intime' | translate }}</span>
                                            <br>
                                            <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplicationinquiry.site' | translate }}</span>
                                            {{ currentList.PunchLocation }}
                                        </div>
                                        <div style="clear:both; float:none"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="in-out-tab-r3" name="tab-group-1">
                            <label for="in-out-tab-r3">{{ 'inouttimeapplicationinquiry.raw' | translate }}</label>

                            <div class="content txt_box_1 txt_grey_1 txt_size_2">
                                <div class="col col2">
                                    <div style="display:none">
                                        <div>Time</div>
                                        <div>Detail</div>
                                        <div style="clear:both; float:none"></div>
                                    </div>

                                    <div *ngFor="let rawList of inOutTimeApplication.rawPunchList">
                                        <div>
                                            {{commonUI.formatDisplayDate(rawList.PunchDateTime, 'h:mm A')}}
                                            <br>
                                            <span class="txt_size_3 txt_grey_2">{{commonUI.formatDisplayDate(rawList.PunchDateTime, 'DD MMM YYYY (A)')}}</span>
                                        </div>
                                        <div>
                                            <span class="txt_label bc_green txt_size_3" *ngIf="rawList.Status == 'O'">{{ 'inouttimeapplicationinquiry.outtime' | translate }}</span>
                                            <span class="txt_label bc_blue txt_size_3" *ngIf="rawList.Status == 'I'">{{ 'inouttimeapplicationinquiry.intime' | translate }}</span>
                                            <br>
                                            <span class="txt_size_3 txt_grey_2">{{ 'inouttimeapplicationinquiry.site' | translate }}</span>
                                            {{ rawList.PunchLocation }}
                                        </div>
                                        <div style="clear:both; float:none"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div id="btn_punch_zoom" class="btn_punch_zoom icon_zoom" (click)="showZoomPopup()"></div>
                    </div>

                </div>

                <div class="txt_grey_2 txt_size_3">{{ 'inouttimeapplication.description' | translate }}</div>
                <div class="approver_staff_remark txt_box_1" style="height:66px">{{ inOutTimeApplication.Description }}</div>

            </div>

        </div>


        <div class="detail_box_bottom_area mt0_m">

            <div class="txt_grey_2 txt_size_3">{{ 'approval.approverremarks' | translate }}</div>
            <div class="detail_box_approver_remarks_area" *ngIf='approverRemarkList'>
                <div *ngFor="let approverRemark of approverRemarkList">
                    <div>
                        <div class="txt_photo_area_small fl" [ngStyle]="{'background-image': 'url(' + commonUI.getStaffImage(approverRemark.StaffInfo) + ')'}">
                            <div class="txt_photo_mask_small p_normal_grey"></div>
                        </div>
                        <div class="approver_staff_content">
                            <div class="txt_bold">{{approverRemark.StaffInfo?.FullName}}</div>
                            <div class="txt_grey_2 txt_size_3">{{commonUI.formatDisplayDate(approverRemark.ProcessDate, 'D MMM YYYY (ddd) - h:mm A')}}</div>
                            <div class="approver_staff_remark txt_box_1">{{approverRemark.Remark}}</div>
                        </div>
                        <div style="clear:both"></div>
                    </div>

                    <hr class="hr_profile">
                </div>

            </div>
            <button class="btn_next_approver" (click)="commonUI.showNextApprover(inOutTimeApplication)">
                <img src="assets/img/btn_show_next_approver.png" width="18">
                {{'show-next-approver' | translate}}
            </button>
        </div>

    </div>

    <div *ngIf="popupmode == 'APPROVAL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="showApprovePopup()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" (click)="showRejectPopup()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

    <div *ngIf="popupmode == 'WITHDRAW'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn btn_red pp_close flag_submit_finish" (click)="showWithdrawConfirm()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'inouttimeapplicationinquiry.withdrawthisapplication' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

</div>