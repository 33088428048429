<div class="flag_submit_remarks_content" id="{{ cancelRemarkPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_company">
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'leaveapplicationinquery.remarksofcancellation' | translate }}</div>
        </div>

        <div class="submit_remarks_area">
            <div class="txt_photo_area_small fl" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(leaveapplication.StaffInfo) +')'}">
                <div class="txt_photo_mask_small p_normal_grey"></div>
            </div>
            <div class="approver_staff_content">
                <div class="txt_bold">{{leaveapplication.StaffInfo.FullName}}</div>
                <textarea id="txtCancelReason" [(ngModel)]="cancelreason" class="approver_staff_remark_input form-control txt_box_1 txt_size_2 bg_none" placeholder="{{ 'leaveapplicationinquery.pleaseinputremarks' | translate }}"></textarea>
            </div>
            <div style="clear:both"></div>
        </div>

        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="showCancelConfirmPopup()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}</button>
        </div>

    </div>
</div>
