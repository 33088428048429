import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { PopupInOutDetailComponent } from '../common/popup-in-out-detail/popup-in-out-detail.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { InOutService } from '../services/in-out.service';

declare var $: any;

@Component({
  selector: 'app-in-out-inquiry',
  templateUrl: './in-out-inquiry.component.html',
  styleUrls: ['./in-out-inquiry.component.scss']
})
export class InOutInquiryComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  @ViewChild('endPicker', { static: false }) endPicker: MatDatepicker<any>;

  title = "InOutTimeApplicationInquiry";
  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  StaffModule = [];
  startDate = moment().add(-1, 'months').toDate();
  endDate = moment().add(1, 'months').toDate();
  resultList = [];
  eInOutApplicationList = [];
  approverRemarkList = [];
  selectedInOutTimeApplication;
  selectedStatus = "All";
  isTeammateViewSelected = false;
  cancelReason = "";

  popupmode = "READONLY";
  search = {
    keywords: ""
  };
  propertyName = "Date";
  reverse = true;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  constructor(public commonUI: CommonUIService, public commonService: CommonServiceService, public inoutService: InOutService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    this.commonUI.addLoadingTask();
    this.getModule();
    this.commonUI.finishLoadingTask();
  }

  getModule() {
    this.commonUI.addLoadingTask();
    this.commonService.getModule(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.StaffModule = result.body['Data'].Module;
        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  dateValidator(date) {
    switch (date) {
      case 'start':
        if (this.startDate > this.endDate)
          this.endDate = new Date(this.startDate)
        break;
      case 'end':
        if (this.endDate < this.startDate)
          this.startDate = new Date(this.endDate)
        break;
    }
  }

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch (propertyName) {
      case 'StaffInfo.FullName':
        this.eInOutApplicationList = this.eInOutApplicationList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'Date':
        this.eInOutApplicationList = this.eInOutApplicationList.sort((a, b) => {
          return new Date(b.Date).getTime() - new Date(a.Date).getTime()
        })
        break;
    }
    if (this.reverse) this.eInOutApplicationList = this.eInOutApplicationList.slice().reverse()
    this.toggleSortMenu()
  }

  searchByInput() {
    if (this.search.keywords.length <= 0) return this.eInOutApplicationList = this.resultList
    else this.eInOutApplicationList = this.resultList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  toggleSortMenu() {
    $("#p4_content").fadeToggle();
  }

  hideSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();
  }

  clearFilter() {
    this.search.keywords = "";
    this.eInOutApplicationList = this.resultList;
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(0).addClass("active");

    this.commonUI.scrollToTop();
  }

  applyFilter(keyword, i) {
    this.search.keywords = keyword + " ";
    this.eInOutApplicationList = this.resultList.filter(o => o.StatusDesc == keyword)
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(i).addClass("active");

    this.commonUI.scrollToTop();
  }

  resetSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

    this.startDate = moment().add(-1, 'months').toDate();
    this.endDate = moment().add(1, 'months').toDate();
    this.selectedStatus = "All";
    this.isTeammateViewSelected = false;
  }

  getNumOfApplication(status) {
    var count = 0;
    if (status == "") return this.resultList.length;
    for (var i = 0; i < this.resultList.length; i++) {
      if (this.resultList[i].StatusDesc == status) count++;
    }
    return count;
  }

  showSearchResult() {
    if (this.isTeammateViewSelected) {
      this.showTeammateSearchResult();
    } else {
      this.showPersonalSearchResult();
    }
  }

  showPersonalSearchResult() {
    this.commonUI.addLoadingTask();
    this.inoutService.getSelfEInOutApplicationList(
      this.employee,
      this.commonUI.formatJsonDate(this.startDate, "YYYY-MM-DDT00:00:00"),
      this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00"),
      this.selectedStatus).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.onGetEInOutApplicationListSuccess(result.body['Data'].EInOutApplicationList)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  showTeammateSearchResult() {
    this.commonUI.addLoadingTask();
    this.inoutService.getTeammateEInOutApplicationList(
      this.employee,
      this.commonUI.formatJsonDate(this.startDate, "YYYY-MM-DDT00:00:00"),
      this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00"),
      this.selectedStatus).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.onGetEInOutApplicationListSuccess(result.body['Data'].EInOutApplicationList)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  onGetEInOutApplicationListSuccess(eInOutApplicationList) {
    this.eInOutApplicationList = eInOutApplicationList;
    this.resultList = eInOutApplicationList;
    this.prepareListInfo();
    $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", () => {
      this.commonUI.finishLoadingTask();
      $('html, body').animate({
        scrollTop: $(".list_result_area").offset().top
      }, 1000);
    });
  }

  prepareListInfo() {
    var index = 0;
    this.eInOutApplicationList.forEach((eInOutApplication, key) => {
      eInOutApplication.EInOutDateRange = this.commonUI.formatDisplayDate(eInOutApplication.Date, 'D MMM YYYY (ddd)');
      eInOutApplication.keywords = "";
      eInOutApplication.keywords += eInOutApplication.EInOutDateRange + " ";
      eInOutApplication.keywords += eInOutApplication.StaffInfo.FullName + " ";
      eInOutApplication.keywords += eInOutApplication.StatusDesc + " ";
      eInOutApplication.index = index++;
    })
  }

  showEInOutApplication(eInOutApplication) {
    this.popupmode = "READONLY";
    this.selectedInOutTimeApplication = eInOutApplication;
    if (eInOutApplication.IsWithdrawable) this.popupmode = "WITHDRAW";

    this.commonUI.addLoadingTask();
    this.commonService.getApproverRemarkList(eInOutApplication.RID, "EINOUT", eInOutApplication.Date).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.onGetApproverRemarkListSuccess(result.body['Data'].ApproverRemarkList)
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onGetApproverRemarkListSuccess(remarkList) {
    this.approverRemarkList = remarkList;
    const dialog = this.matDialog.open(PopupInOutDetailComponent, {
      panelClass: 'responsive-dialog',
      data: {
        app: this.selectedInOutTimeApplication,
        approver_remark_list: this.approverRemarkList,
        popupmode: this.popupmode,
        isDelegate: false
      }
    })

    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data.confirm) {
        this.showSearchResult();
      }
    })
    // this.popupControl.showPopup(this.selectedInOutTimeApplication);
    this.commonUI.finishLoadingTask();
    this.cancelReason = "";
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.startDate = new Date()
        instance.startPicker.close()
        break;
      case 'end':
        instance.endDate = new Date()
        instance.endPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
      case 'end':
        this.endPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
  }

  /*******************/
  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
