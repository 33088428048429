import { ErrorHandler, Injectable, Injector, NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServerMaintenanceComponent } from './server-maintenance/server-maintenance.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import { PowerByAcubeComponent } from './power-by-acube/power-by-acube.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { CalendarModule, CalendarMonthModule, DateAdapter as da, CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { formatDate } from '@angular/common';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { PopupTermsComponent } from './common/popup-terms/popup-terms.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { CommonControlComponent } from './common-control/common-control.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MenuComponent } from './common/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { ToolbarComponent } from './common/toolbar/toolbar.component';
import { PopupPasswordPolicyComponent } from './common/popup-password-policy/popup-password-policy.component';

import * as Hammer from 'hammerjs';
import { NgIdleModule } from '@ng-idle/core'
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeaveApplicationComponent } from './leave-application/leave-application.component';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import moment from 'moment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LeaveBalanceInquiryComponent } from './leave-balance-inquiry/leave-balance-inquiry.component';
import { LeaveApplicationInquiryComponent } from './leave-application-inquiry/leave-application-inquiry.component';
import { LeaveDetailPopupComponent } from './common/leave-detail-popup/leave-detail-popup.component';
import { PopupPasswordFinishedComponent } from './common/popup-password-finished/popup-password-finished.component';
import { PopupCompanySelectComponent } from './common/popup-company-select/popup-company-select.component';
import { PopupJobPositionComponent } from './common/popup-job-position/popup-job-position.component';
import { PopupLogoutComponent } from './common/popup-logout/popup-logout.component';
import { PopupSaveCompletedComponent } from './common/popup-save-completed/popup-save-completed.component';
import { PopupChangePasswordComponent } from './common/popup-change-password/popup-change-password.component';
import { PopupLanguagesComponent } from './common/popup-languages/popup-languages.component';
import { PopupResetPasswordComponent } from './common/popup-reset-password/popup-reset-password.component';
import { PopupIntroComponent } from './common/popup-intro/popup-intro.component';
import { PopupCurrentVersionComponent } from './common/popup-current-version/popup-current-version.component';
import { PopupAboutFlexsystemComponent } from './common/popup-about-flexsystem/popup-about-flexsystem.component';
import { PopupMessageComponent } from './common/popup-message/popup-message.component';
import { PopupMessageWithCallbackComponent } from './common/popup-message-with-callback/popup-message-with-callback.component';
import { PopupYesNoMessageComponent } from './common/popup-yes-no-message/popup-yes-no-message.component';
import { PopupMessageTextCallbackComponent } from './common/popup-message-text-callback/popup-message-text-callback.component'
import { PopupMessageWarningComponent } from './common/popup-message-warning/popup-message-warning.component';
import { PopupDetailOfCheckBalanceInquiryComponent } from './common/popup-detail-of-check-balance-inquiry/popup-detail-of-check-balance-inquiry.component';
import { CalendarComponent } from './calendar/calendar.component';
import '@angular/common/locales/global/zh';
import { PopupLeaveDetailComponent } from './common/popup-leave-detail/popup-leave-detail.component';
import { PopupAppointmentDetailComponent } from './common/popup-appointment-detail/popup-appointment-detail.component';
import { CalendarStaffLeaveComponent } from './calendar-staff-leave/calendar-staff-leave.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { PopupPersonalProfileContactComponent } from './common/popup-personal-profile-contact/popup-personal-profile-contact.component';
import { PopupPersonalProfileMaritalComponent } from './common/popup-personal-profile-marital/popup-personal-profile-marital.component';
import { PopupPersonalProfileAddressComponent } from './common/popup-personal-profile-address/popup-personal-profile-address.component';
import { PopupPersonalProfileBankComponent } from './common/popup-personal-profile-bank/popup-personal-profile-bank.component';
import { InterceptorService } from './services/interceptor.service';
import { PopupSubmitCancelRemarksComponent } from './common/popup-submit-cancel-remarks/popup-submit-cancel-remarks.component';
import { PopupConfirmCancelRemarksComponent } from './common/popup-confirm-cancel-remarks/popup-confirm-cancel-remarks.component';
import { PopupConfirmWithdrawComponent } from './common/popup-confirm-withdraw/popup-confirm-withdraw.component';
import { PopupErrorDialogComponent } from './common/popup-error-dialog/popup-error-dialog.component';
import { ProfileAmendInquiryComponent } from './profile-amend-inquiry/profile-amend-inquiry.component';
import { PopupAmendInquiryAddressComponent } from './common/popup-amend-inquiry/popup-amend-inquiry-address/popup-amend-inquiry-address.component';
import { PopupAmendInquiryBankComponent } from './common/popup-amend-inquiry/popup-amend-inquiry-bank/popup-amend-inquiry-bank.component';
import { PopupAmendInquiryContactComponent } from './common/popup-amend-inquiry/popup-amend-inquiry-contact/popup-amend-inquiry-contact.component';
import { PopupAmendInquiryDependtComponent } from './common/popup-amend-inquiry/popup-amend-inquiry-dependt/popup-amend-inquiry-dependt.component';
import { PopupAmendInquiryMaritalComponent } from './common/popup-amend-inquiry/popup-amend-inquiry-marital/popup-amend-inquiry-marital.component';
import { PopupAmendInquiryQualiComponent } from './common/popup-amend-inquiry/popup-amend-inquiry-quali/popup-amend-inquiry-quali.component';
import { PopupPersonalProfileDependtComponent } from './common/popup-personal-profile-dependt/popup-personal-profile-dependt.component';
import { PopupPersonalProfileQualiComponent } from './common/popup-personal-profile-quali/popup-personal-profile-quali.component';
import { PopupLeavePreviewComponent } from './common/popup-leave-preview/popup-leave-preview.component';
import { PopupSubmitRejectRemarksComponent } from './common/popup-submit-reject-remarks/popup-submit-reject-remarks.component';
import { PopupSubmitApprovalRemarksComponent } from './common/popup-submit-approval-remarks/popup-submit-approval-remarks.component';
import { ImageEnlargePreviewComponent } from './common/image-enlarge-preview/image-enlarge-preview.component';
import { ApprovalCenterPendingApprovalComponent } from './approval-center-pending-approval/approval-center-pending-approval.component';
import { ApprovalCenterOverrideApprovalComponent } from './approval-center-override-approval/approval-center-override-approval.component';
import { ApprovalCenterDelegationSettingsComponent } from './approval-center-delegation-settings/approval-center-delegation-settings.component';
import { PopupDelegationSettingsComponent } from './common/popup-delegation-settings/popup-delegation-settings.component';
import { PopupDelegationSettingsPreviewComponent } from './common/popup-delegation-settings-preview/popup-delegation-settings-preview.component';
import { PopupDelegationDeleteComponent } from './common/popup-delegation-delete/popup-delegation-delete.component';
import { DelegationBalanceInquiryComponent } from './delegation-balance-inquiry/delegation-balance-inquiry.component';
import { DelegationApplicationInquiryComponent } from './delegation-application-inquiry/delegation-application-inquiry.component';
import { DelegationPendingApprovalComponent } from './delegation-pending-approval/delegation-pending-approval.component';
import { ReportPayslipComponent } from './report-payslip/report-payslip.component';
import { ReportTaxFormComponent } from './report-tax-form/report-tax-form.component';
import { ReportTaxSummaryComponent } from './report-tax-summary/report-tax-summary.component';
import { ReportLeaveBalanceComponent } from './report-leave-balance/report-leave-balance.component';
import { PopupTimeoutDialogComponent } from './common/popup-timeout-dialog/popup-timeout-dialog.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { PopupAppointmnetConfirmComponent } from './common/popup-appointmnet-confirm/popup-appointmnet-confirm.component';
import { AppointmentInquiryComponent } from './appointment-inquiry/appointment-inquiry.component';
import { InOutComponent } from './in-out/in-out.component';
import { PopupInoutEditComponent } from './common/popup-inout-edit/popup-inout-edit.component';
import { InOutInquiryComponent } from './in-out-inquiry/in-out-inquiry.component';
import { PunchInquiryComponent } from './punch-inquiry/punch-inquiry.component';
import { PopupPunchDetailComponent } from './common/popup-punch-detail/popup-punch-detail.component';
import { PopupInOutDetailComponent } from './common/popup-in-out-detail/popup-in-out-detail.component';
import { PopupPunchZoomComponent } from './common/popup-punch-zoom/popup-punch-zoom.component';

import { ContainerLoginComponent } from './container-login/container-login.component';
import { PopupNextApproverComponent } from './common/popup-next-approver/popup-next-approver.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EclaimComponent } from './controllers_mod/eclaim/eclaim.component';
import { PopupClaimConfirmComponent } from './common_mod/popup-claim-confirm/popup-claim-confirm.component';
import { PopupEclaimDetailComponent } from './common_mod/popup-eclaim-detail/popup-eclaim-detail.component';
import { PopupDetailOfClaimBalanceComponent } from './common/popup-detail-of-claim-balance/popup-detail-of-claim-balance.component';
import { EclaimInquiryComponent } from './eclaim-inquiry/eclaim-inquiry.component';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { Router } from '@angular/router';

import { LandingPageComponent } from './landing-page/landing-page.component';
import { ACChangePasswordComponent } from './ac-change-password/ac-change-password.component';
import { ForgotPasswordACComponent } from './forgot-password-ac/forgot-password-ac.component';
// mod import component START
import { ApprovalCenterOverrideApprovalModComponent } from './controllers_mod/approval-center-override-approval-mod/approval-center-override-approval-mod.component';
import { ApprovalCenterPendingApprovalModComponent } from './controllers_mod/approval-center-pending-approval-mod/approval-center-pending-approval-mod.component';
import { DashboardModComponent } from './controllers_mod/dashboard-mod/dashboard-mod.component';
import { ReportEClaimComponent } from './controllers_mod/report-e-claim/report-e-claim.component';

// mod import component END

export function HttpLoaderFactory(http: HttpClient) {
  // mod locale START
  return new MultiTranslateHttpLoader(http, [
    {prefix: 'assets/locales/locale-', suffix: '.json'},
    {prefix: 'assets/locales-mod/locale-mod-', suffix: '.json'}
  ])
  // mod locale END
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    pan: { direction: Hammer.DIRECTION_ALL },
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  // public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
  //   return formatDate(date, moment().format('E'), (localStorage.getItem('CurrentLanguage') == 'zh_HK' || localStorage.getItem('CurrentLanguage') == 'zh_CN') ? 'zh' : 'en');
  // }

  public monthViewColumnHeader({ date }: DateFormatterParams): string {
    return localStorage.CurrentLanguage == 'en' ? moment(date).format("ddd") : moment(date).format("dd")
  }
}

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter
{
  public format(date: moment.Moment): string
  {
    const locale = localStorage.CurrentLanguage
    const format = (localStorage.CurrentLanguage == 'zh_HK' || localStorage.CurrentLanguage == 'zh_CN') ? 'll (ddd)' : 'DD MMM YYYY (ddd)'

    return date.locale(locale).format(format);
  }
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private injector: Injector, private zone: NgZone) {
    super();
  }
  handleError(error: any): void {
    const router = this.injector.get(Router);
    super.handleError(error);
    this.zone.run(() => router.navigate(['/']));
  }
}

@NgModule({
  declarations: [
    // standard declarations START
    AppComponent,
    ServerMaintenanceComponent,
    PowerByAcubeComponent,
    LoginComponent,
    PopupTermsComponent,
    CommonControlComponent,
    ForgotPasswordComponent,
    MenuComponent,
    HomeComponent,
    ToolbarComponent,
    PopupPasswordPolicyComponent,
    DashboardComponent,
    LeaveApplicationComponent,
    LeaveBalanceInquiryComponent,
    LeaveApplicationInquiryComponent,
    LeaveDetailPopupComponent,
    PopupPasswordFinishedComponent,
    PopupCompanySelectComponent,
    PopupJobPositionComponent,
    PopupLogoutComponent,
    PopupSaveCompletedComponent,
    PopupChangePasswordComponent,
    PopupLanguagesComponent,
    PopupResetPasswordComponent,
    PopupIntroComponent,
    PopupCurrentVersionComponent,
    PopupAboutFlexsystemComponent,
    PopupMessageComponent,
    PopupMessageWithCallbackComponent,
    PopupYesNoMessageComponent,
    PopupMessageTextCallbackComponent,
    PopupMessageWarningComponent,
    PopupDetailOfCheckBalanceInquiryComponent,
    CalendarComponent,
    PopupLeaveDetailComponent,
    PopupAppointmentDetailComponent,
    CalendarStaffLeaveComponent,
    MyProfileComponent,
    PopupPersonalProfileContactComponent,
    PopupPersonalProfileMaritalComponent,
    PopupPersonalProfileAddressComponent,
    PopupPersonalProfileBankComponent,
    PopupSubmitCancelRemarksComponent,
    PopupConfirmCancelRemarksComponent,
    PopupConfirmWithdrawComponent,
    PopupErrorDialogComponent,
    ProfileAmendInquiryComponent,
    PopupAmendInquiryAddressComponent,
    PopupAmendInquiryBankComponent,
    PopupAmendInquiryContactComponent,
    PopupAmendInquiryDependtComponent,
    PopupAmendInquiryMaritalComponent,
    PopupAmendInquiryQualiComponent,
    PopupPersonalProfileDependtComponent,
    PopupPersonalProfileQualiComponent,
    PopupLeavePreviewComponent,
    PopupSubmitRejectRemarksComponent,
    PopupSubmitApprovalRemarksComponent,
    ImageEnlargePreviewComponent,
    ApprovalCenterPendingApprovalComponent,
    ApprovalCenterOverrideApprovalComponent,
    ApprovalCenterDelegationSettingsComponent,
    PopupDelegationSettingsComponent,
    PopupDelegationSettingsPreviewComponent,
    PopupDelegationDeleteComponent,
    PopupAppointmnetConfirmComponent,
    DelegationBalanceInquiryComponent,
    DelegationApplicationInquiryComponent,
    DelegationPendingApprovalComponent,
    ReportPayslipComponent,
    ReportTaxFormComponent,
    ReportTaxSummaryComponent,
    ReportLeaveBalanceComponent,
    PopupTimeoutDialogComponent,
    AppointmentComponent,
    PopupAppointmnetConfirmComponent,
    PopupAppointmnetConfirmComponent,
    PopupAppointmentDetailComponent,
    AppointmentInquiryComponent,
    InOutComponent,
    PopupInoutEditComponent,
    InOutInquiryComponent,
    PunchInquiryComponent,
    PopupPunchDetailComponent,
    PopupInOutDetailComponent,
    PopupPunchZoomComponent,
    EclaimInquiryComponent,
    EclaimComponent,
    PopupDetailOfClaimBalanceComponent,
    
    ContainerLoginComponent,
    PopupNextApproverComponent,
    LandingPageComponent,
    ACChangePasswordComponent,
    ForgotPasswordACComponent,
    // standard declarations END

    // mod declarations START
    DashboardModComponent,
    PopupClaimConfirmComponent,
    PopupEclaimDetailComponent,
    ApprovalCenterOverrideApprovalModComponent,
    ApprovalCenterPendingApprovalModComponent,
    ReportEClaimComponent,
    EclaimComponent
    // mod declarations END
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    CalendarModule.forRoot({
      provide: da,
      useFactory: adapterFactory
    }),
    CalendarMonthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    MatDialogModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgIdleModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    // mod import module START
    // mod import module END
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { 
      provide: MAT_DIALOG_DATA, 
      useValue: {} 
    },
    MenuComponent,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    LeaveApplicationComponent,
    HomeComponent,
    LeaveDetailPopupComponent,
    LoginComponent,
    PopupLogoutComponent,
    ContainerLoginComponent,
    LandingPageComponent,
    ACChangePasswordComponent,
    ForgotPasswordACComponent,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: MatDatepickerModule,
      useValue: {},
    },
    {
      provide: MAT_DATE_LOCALE, 
      useValue: localStorage.CurrentLanguage
    },
    {
      provide: DateAdapter, 
      useClass: CustomDateAdapter, 
      // deps: [MAT_DATE_LOCALE]
    },
    // {
    //   provide: MAT_DATE_FORMATS, 
    //   useValue: {
    //     parse: {
    //       dateInput: 'LL',
    //     },
    //     display: {
    //       dateInput: ,
    //       monthYearLabel: 'YYYY',
    //       dateA11yLabel: 'LL',
    //       monthYearA11yLabel: 'YYYY',
    //     },
    //   }
    // },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    // standard providers START
    // standard providers END
    // mod providers START
    // mod providers END
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    // standard components START
    PopupTermsComponent,
    PopupPasswordPolicyComponent,
    PopupPasswordFinishedComponent,
    PopupCompanySelectComponent,
    PopupJobPositionComponent,
    PopupLogoutComponent,
    PopupSaveCompletedComponent,
    PopupChangePasswordComponent,
    PopupLanguagesComponent,
    PopupResetPasswordComponent,
    PopupIntroComponent,
    PopupAboutFlexsystemComponent,
    PopupCurrentVersionComponent,
    PopupMessageComponent,
    PopupMessageWithCallbackComponent,
    PopupYesNoMessageComponent,
    PopupMessageTextCallbackComponent,
    PopupMessageWarningComponent,
    PopupDetailOfCheckBalanceInquiryComponent,
    PopupLeaveDetailComponent,
    PopupAppointmentDetailComponent,
    PopupPersonalProfileContactComponent,
    PopupPersonalProfileMaritalComponent,
    PopupPersonalProfileAddressComponent,
    PopupSubmitCancelRemarksComponent,
    PopupConfirmCancelRemarksComponent,
    PopupConfirmWithdrawComponent,
    PopupPersonalProfileBankComponent,
    PopupPersonalProfileDependtComponent,
    PopupPersonalProfileQualiComponent,
    PopupAmendInquiryMaritalComponent,
    PopupAmendInquiryAddressComponent,
    PopupAmendInquiryBankComponent,
    PopupAmendInquiryContactComponent,
    PopupAmendInquiryDependtComponent,
    PopupAmendInquiryQualiComponent,
    PopupErrorDialogComponent,
    PopupLeavePreviewComponent,
    PopupSubmitApprovalRemarksComponent,
    PopupSubmitRejectRemarksComponent,
    PopupDelegationDeleteComponent,
    PopupDelegationSettingsComponent,
    PopupDelegationSettingsPreviewComponent,
    PopupTimeoutDialogComponent,
    PopupAppointmnetConfirmComponent,
    PopupAppointmnetConfirmComponent,
    PopupAppointmentDetailComponent,
    PopupInoutEditComponent,
    PopupPunchDetailComponent,
    PopupInOutDetailComponent,
    PopupPunchZoomComponent,
    ImageEnlargePreviewComponent,
    CommonControlComponent,
    ToolbarComponent,
    HomeComponent,
    LeaveDetailPopupComponent,
    PopupNextApproverComponent,
    PopupDetailOfClaimBalanceComponent,
    // standard components END
    // mod components START
    PopupEclaimDetailComponent,
    PopupClaimConfirmComponent
    // mod components END
  ],
  exports: [
    MatToolbarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    CalendarMonthModule,
	  TranslateModule
  ]
})

export class AppModule { }
