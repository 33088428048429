import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { LeaveService } from 'src/app/services/leave.service';
import { PopupConfirmCancelRemarksComponent } from '../popup-confirm-cancel-remarks/popup-confirm-cancel-remarks.component';

@Component({
  selector: 'app-popup-submit-cancel-remarks',
  templateUrl: './popup-submit-cancel-remarks.component.html',
  styleUrls: ['./popup-submit-cancel-remarks.component.scss']
})
export class PopupSubmitCancelRemarksComponent implements OnInit {

  leaveapplication
  cancelreason = '';
  cancelRemarkPopupid

  constructor(public matDialog: MatDialog,public commonUI: CommonUIService, public matDialogRef: MatDialogRef<PopupSubmitCancelRemarksComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public commonService: CommonServiceService, public leaveService: LeaveService) { 
    this.leaveapplication = data['app']
    this.cancelRemarkPopupid = data['popid']
  }

  ngOnInit(): void {
  }

  showCancelConfirmPopup() {
    const dialog = this.matDialog.open(PopupConfirmCancelRemarksComponent, {
      data: {
        app: this.leaveapplication,
        reason: this.cancelreason,
        popid: 'cancelConfirmPopup'
      }
    })

    dialog.afterClosed().subscribe((data?) => {
      if(data == null || data == undefined) return; 
      if(data.confirm) 
        this.matDialogRef.close(data)
      else 
        this.close()
    })
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

}
