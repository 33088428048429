
    <div class="login_box_abs cpw_box" id="form_forgot_pwd">

        <div class="login_bg">
            <img src="assets/img/logo_fsl.png" height="40" />
        </div>
        <span class="txt_size_0 txt_grey_2">{{ 'toolbar.resetpassword' | translate }}</span>
        <br /><br /><br />

        <div class="input_login_position">
            <input type="password" value="" [(ngModel)]="currentPassword" placeholder="{{ 'toolbar.currentpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
            <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
        </div>

        <br/>
        <br/>
        <div class="input_login_position">
            <input type="password" value="" [(ngModel)]="newPassword" placeholder="{{ 'toolbar.newpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
            <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
        </div>

        <br/>
        <div class="input_login_position">
            <input type="password" value="" [(ngModel)]="newPassword2" placeholder="{{ 'toolbar.confirmnewpassword' | translate }}" class="form-control input_txt_icon_right" maxlength="20" required>
            <div class="input_icon_1"><img src="assets/img/input_pw.png" width="18"></div>
        </div>

        <br/>
        <div class="pp2 btn_opacity txt_size_3" style="text-align: left;padding-left: 20px;" (click)="showModalPasswordPolicy()">{{ 'toolbar.passwordpolicy' | translate }}</div>
        
        <!-- <div class="pop_functions_box_content_tc"> -->
         <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img src="./img/tick_valid.png" height="20" width="20"></div> -->
            <!-- <div class="col-xs-10">{{ 'toolbar.maxpwdlen' | translate }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->
      
          <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img src="./img/tick_valid.png" height="20" width="20"></div> -->
            <!-- <div class="col-xs-10">{{ 'toolbar.minpwdlen' | translate :translationData }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->
      
          <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img ng-if="passwordPolicy.ExcludeAccountName == true" src="./img/tick_valid.png" height="20" width="20"></div> -->
            <!-- <div class="col-xs-10">{{ 'toolbar.excludeaccountname' | translate }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->

          <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img ng-if="passwordPolicy.ExcludeSpace == true" src="./img/tick_valid.png" height="20" width="20"></div> -->
            <!-- <div class="col-xs-10">{{ 'toolbar.excludespace' | translate }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->

          <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img ng-if="passwordPolicy.IncludeLowercase == true" src="./img/tick_valid.png" height="20" width="20"></div> -->
            <!-- <div class="col-xs-10">{{ 'toolbar.includelowercase' | translate }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->

          <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img ng-if="passwordPolicy.IncludeUppercase == true" src="./img/tick_valid.png" height="20" width="2div> -->
            <!-- <div class="col-x0"></div> -->
            <!-- <div class="col-xs-10">{{ 'toolbar.includeuppercase' | translate }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->

          <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img ng-if="passwordPolicy.IncludeDigit == true" src="./img/tick_valid.png" height="20" width="20"></div> -->
            <!-- <div class="col-xs-10">{{ 'toolbar.includedigit' | translate }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->

          <!-- <div class="row"> -->
            <!-- <div class="col-xs-1"><img ng-if="passwordPolicy.IncludeSymbol == true" src="./img/tick_valid.png" height="20" width="20"></s-10">{{ 'toolbar.includesymbol' | translate }}</div> -->
          <!-- </div> -->
          <!-- <br /> -->
        <!-- </div> -->

        <div class="pop_functions_one_btn_center">
            <button (click)="doChangePassword()" class="btn btn-block btn-lg one_btn maincolor_btn2 flag_submit_finish pp_close" type="submit" name="submit"><img src="img/btn_tick_w.png" width="16" alt="">{{ 'confirm' | translate }}</button>
        </div>


        
        <div style="clear: both"></div>
    </div>
    <div class="powered_by_nologin">Powered by </div>