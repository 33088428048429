import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-enlarge-preview',
  templateUrl: './image-enlarge-preview.component.html',
  styleUrls: ['./image-enlarge-preview.component.scss']
})
export class ImageEnlargePreviewComponent implements OnInit {

  dataURL;
  ext;

  constructor(public matDialogRef: MatDialogRef<ImageEnlargePreviewComponent>, @Inject(MAT_DIALOG_DATA) public data, public domSanitizer: DomSanitizer) {
    this.ext = data['ext']
    this.dataURL = data['dataURL']
    this.dataURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.dataURL)
  }

  ngOnInit(): void {
    var interval = setInterval(function () {
      let iframe = document.getElementById("pdf_target");
      if ((<HTMLIFrameElement>iframe))
        if ((<HTMLIFrameElement>iframe).contentWindow) {
          clearInterval(interval)
          let imgElements = (<HTMLIFrameElement>iframe).contentWindow.document.getElementsByTagName("img")[0];
          if (imgElements) {
            imgElements.style.maxWidth = "100%";
            imgElements.style.maxHeight = "100%";
          }
        }
    }, 100);
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch { }
  }
}
