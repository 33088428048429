<!--============ popup : Edit of Dependents ============ -->
<div [id]="displaySwitch" class="pp_dependents_content list_flag-selected" style="display: block;">
    <form id="form_application_dependents"  name="form_application_dependents" (submit)="submitEvent()">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval">
        <!--<div class="pop_functions_close maincolor_btn2 pp_close">x</div>-->

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'personalprofile.editof' | translate }} {{ 'personalprofile.tabdependents' | translate }}</div>
        </div>

        <div class="d_box_top"></div>

        <div class="detail_box_bottom_area_profile mt0_m">
            <div class="box_content_inquiry">
                <!-- <form id="form_application_dependents"  name="form_application_dependents" (submit)="submitEvent();closePopup();"> -->

                    <div class="form_area" *ngIf='obj'>


                        <!-- ========== a record =========== -->
                    	<!-- <a name="top_form-1"></a> -->
                        <div *ngFor="let dependent of obj; let i=index; trackBy:trackByIndex">
                            <div id="form-{{i}}" class="form_content form_content_popup" *ngIf='!dependent.deleted'>
                        	<div (click)="slideUp($event);dependent.onFocus = true" [ngClass]="{'form_content_title-selected': dependent.onFocus }" class="form_content_title">
                                {{ getRelationshipDesc(dependent.RelationshipCode) }}
                            	<span [ngClass]="{
                                    'color_label_keep': dependent.Action == 'K',
                                    'color_label_update': dependent.Action == 'U',
                                    'color_label_remove': dependent.Action == 'D',
                                    'color_label_new_edit': dependent.Action == 'I' }"
                                    class="txt_label txt_label_profile_action txt_size_3">{{ 'personalprofile.typeAction.' + dependent.Action | translate }}</span>
                            </div>

                        	<div class="form_content_input">

                                <a name="list_type" class="list_bar_mt"></a>
                                <div *ngIf="dependent.Action != 'I'">
                            	<div class="btn_sub_title txt_grey_2">{{ 'personalprofile.action' | translate }}</div>
                            	<div class="btn_3select1">
                                    <div id="f_keep" (click)="dependent.Action = 'K'" [ngClass]="{active: dependent.Action == 'K'}" class="btn btn-block btn-lg two_btn maincolor_btn2" title="Non-change this record">{{ 'personalprofile.typeAction.K' | translate }}</div>
                                    <div id="f_update" (click)="dependent.Action = 'U'" [ngClass]="{active: dependent.Action == 'U'}" class="btn btn-block btn-lg two_btn maincolor_btn2" title="Update this record">{{ 'personalprofile.typeAction.U' | translate }}</div>
                                    <div id="f_delete" (click)="dependent.Action = 'D'" [ngClass]="{active: dependent.Action == 'D'}" class="btn btn-block btn-lg two_btn maincolor_btn2" title="Remove this record">{{ 'personalprofile.typeAction.D' | translate }}</div>
                                    <div style="clear:both;"></div>
                                </div>
                                </div>

                                <div *ngIf="dependent.Action != 'D'">
                                    <div class="btn_3select1_form">
                                        <!-- ====== start : btn_3select1 next div ====== -->

                                        <div class="form_content_input_field">
                                            <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.relationship' | translate }}</div>
                                            <div class="input_field_large">
                                                <select class="btn btn_dropdown_1 maincolor_btn_1" [ngClass]="{'disabled': dependent.Action == 'K'}" [(ngModel)]="dependent.RelationshipCode" name='relcode_{{i}}'>
                                                    <option *ngFor="let opt of relationshipList" [value]="opt.Code">{{ opt.Desc }}</option>
                                                </select>

                                            </div>
                                        </div>

                                        <div class="form_content_input_field">
                                            <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.emergencycontact' | translate }}</div>
                                            <div class="input_field_large">
                                                <select class="btn btn_dropdown_1 maincolor_btn_1" [ngClass]="{'disabled': dependent.Action == 'K'}" [(ngModel)]="dependent.IsEmergency" name='emerg_{{i}}'>
                                                    <option selected="" value="Y">{{ 'yes' | translate }}</option>
                                                    <option value="">{{ 'no' | translate }}</option>
                                                </select>

                                            </div>
                                        </div>

                                        <div class="form_content_input_field">
                                            <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.name' | translate }}</div>
                                            <input required class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [disabled]="dependent.Action == 'K'" [(ngModel)]="dependent.FullName" name='fullname_{{i}}'>
                                        </div>

                                        <div class="form_content_input_field">
                                            <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.mobile' | translate }}</div>
                                            <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [disabled]="dependent.Action == 'K'" [(ngModel)]="dependent.Mobile" name='mobile_{{i}}'>
                                        </div>

                                        <div class="form_content_input_field">
                                            <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.idtype' | translate }}</div>
                                            <div class="input_field_large">
                                                <select class="btn btn_dropdown_1 maincolor_btn_1" [ngClass]="{'disabled': dependent.Action == 'K'}" [(ngModel)]="dependent.IdentityTypeCode" name='idcode_{{i}}'>
                                                    <option *ngFor="let opt of identityTypeList" value="{{ opt.Code }}">{{ opt.Desc }}</option>
                                                </select>

                                            </div>
                                        </div>

                                        <div class="form_content_input_field">
                                            <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.idnumber' | translate }}</div>
                                            <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [disabled]="dependent.Action == 'K'" [(ngModel)]="dependent.IdentityNum" name='id_{{i}}'>
                                        </div>

                                        <div class="form_content_input_field" [style.pointerEvents]="dependent.Action == 'K'? 'none' : ''">
                                            <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.birthday' | translate }}</div>

                                            <div [ngClass]="dependent.Action == 'K' ? 'input_new_calendar_readonly' : 'input_new_calendar' ">
                                                <div>
                                                    <mat-form-field appearance='fill' (click)="birthPicker.open()">
                                                        <input matInput [matDatepicker]="birthPicker" [(ngModel)]="dependent.Birthday" [min]='minDate' readonly name="birthPicker_{{i}}">
                                                        <mat-datepicker-toggle matSuffix [for]="birthPicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #birthPicker (opened)="openAppend(i)"></mat-datepicker>
                                                    </mat-form-field>
                                                    <div style="display: none">
                                                        <div class="datepicker-footer" #birthFooter>
                                                            <button mat-raised-button  (click)="today(dependent.Birthday, selectedIndex)" #todayButton name="startDateButton_{{selectedIndex}}">
                                                                {{'TODAY' | translate}}
                                                            </button>
                                                            <button mat-raised-button (click)="closePicker(selectedIndex)" #doneButton>
                                                                {{'close' | translate}}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form_content_input_field">
                                           <div class="btn_sub_title txt_grey_2">{{ 'personalprofile.hkid' | translate }}</div>
                                           <input class="form-control input_profile_singleline bg_none txt_grey_1 txt_size_2" [disabled]="dependent.Action == 'K'" [(ngModel)]="dependent.HKID" name='hkid_{{i}}'>
                                       </div>

                                        <div class="form_content_input_field_full">
                                            <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.attachment' | translate }}</div>
                                            <div class="fl input_field_small">
                                                <label class="btn btn-block btn-lg maincolor_btn_1" [class.disabled]="attachmentExistedFromRecord(dependent) || dependent.Action == 'K'">
                                                    <img src="assets/img/btn_add_files.png" width="18"> {{ 'leaveapplication.addfiles' | translate }}
                                                    <input type="file" multiple style="display: none;" (change)="uploadListening($event)" [attr.uuid]="dependent.uuid" name='uuid__{{i}}' [disabled]="attachmentExistedFromRecord(dependent) || dependent.Action == 'K'" />
                                                </label>
                                            </div>
                                            <div *ngFor="let attach of obj.attachments">
                                                <div id="attach_{{attach.fileID}}" *ngIf='attach.uuid.value == dependent.uuid || attach.uuid == dependent.uuid'>
                                                    <div style="clear: both"></div>
                                                    <br />

                                                    <div class="attachment_file_area bg_grey_4">

                                                        <div class="attachment_file_txt">
                                                            <div class="attachment_photo">
                                                                <img [src]="attach.dataURL">
                                                            </div>
                                                            <div>{{attach.file.name}}</div>
                                                            <div class="txt_grey_2 txt_size_3" *ngIf="attach.file.size">{{convertSizeFormat(attach.file.size)}}</div>
                                                            <div style="clear: both"></div>
                                                            <div *ngIf="fileProgress != 100">
                                                                <div class="bar_chart_data maincolor_bg2" [ngStyle]="{'width': uploadProgress + '%'}"></div><!-- sample uploading % -->
                                                            </div>
                                                        </div>

                                                        <div class="fr">
                                                            <!-- <div class="btn_attachment_position" (click)="uploadAttachment(attach)" id="btn_upload_attach_{{attach.fileID}}">
                                                                <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img src="assets/img/btn_start_upload.png" width="18">{{ 'leaveapplication.upload' | translate }}</a>
                                                            </div>
                                                            <div class="btn_attachment_position" (click)="cancelAttachment(attach)" id="btn_cancel_attach_{{attach.fileID}}">
                                                                <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_cancel_upload.png" width="18">{{ 'leaveapplication.cancel' | translate }}</a>
                                                            </div> -->
                                                            <div class="btn_attachment_position" (click)="deleteAttachment(attach)" id="btn_delete_attach_{{attach.fileID}}">
                                                                <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplication.delete' | translate }}</a>
                                                            </div>
                                                        </div>

                                                        <div style="clear: both"></div>

                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- ====== END : btn_3select1 next div ====== -->
                                </div>


                        		<div style="clear:both"></div>

                            </div> <!-- ========== end : form_content_input =========== -->

                            <div *ngIf="dependent.Action == 'I'" class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this" (click)="deleteRecord(dependent)">x</div>

                            </div>
                        </div> <!-- ========== end : form_content =========== -->




                    </div> <!-- ========== end : form_area =========== -->
                <!-- </form> -->
            </div> <!-- ========== end : form_content_inquiry =========== -->
        </div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

    </div>
    <div class="pop_functions_approval">
        <div class="pop_functions_two_btn_center">
            <div class="header_bar_icon fl list_icon_border" title="Add New" (click)="insertDependentsRecord()"><img alt="" src="assets/img/btn_addform_g.png" width="20"></div>
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" type="submit" [disabled]="isRecordsNotReady()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'submit' | translate }}</button>
            <a class="btn btn-block btn-lg two_btn btn_grey pp_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'cancel' | translate }}</a>
            <div style="clear:both"></div>
        </div>
    </div>
    </form>
</div>
