import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { InterceptorService } from './interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService) {

  }

  approveRecord(approver, approvalAction, remark, approvalRecordList) {

    var body = {
      "Action": environment.apiList.ApproveRecord,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": approver.RID,
      "ApprovalAction": approvalAction,
      "Remark": remark,
      "ApproveRecordList": JSON.stringify(approvalRecordList)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
  }

  getPendingApprovalList(employee, sourceType) {

    var body = {
      "Action": environment.apiList.GetPendingApprovalList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "SourceType": sourceType
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //   if (onSuccessCallback != undefined) {
    //     onSuccessCallback(service, sourceType, data.Data.ApprovalList);
    //   }
    // }

    // var actionFail = function (data, status, headers, config) {
    //   if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //   return;
    // }

    // commonService.JSONPostService(
    //   JSON.stringify({
    //     "Action": apiList.GetPendingApprovalList,
    //     "SessionID": sessionStorage.SessionID,
    //     "EmploymentRID": employee.RID,
    //     "SourceType": sourceType
    //   })
    // ).then(
    //   function (response) {
    //     actionSuccess(response.data);
    //   }
    //   ,
    //   function (response) {
    //     actionFail(response.data, response.status, response.headers, response.config);
    //   }
    // );
  }

  overrideApproveRecord(approver, approvalAction, remark, approvalRecordList) {

    var body = {
      "Action": environment.apiList.OverrideApproveRecord,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": approver.RID,
      "ApprovalAction": approvalAction,
      "Remark": remark,
      "ApproveRecordList": JSON.stringify(approvalRecordList)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, approvalRecordList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.OverrideApproveRecord,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": approver.RID,
    //         "ApprovalAction": approvalAction,
    //         "Remark": remark,
    //         "ApproveRecordList": JSON.stringify(approvalRecordList)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
}

getOverridePendingApprovalList(employee, sourceType) {

  var body = {
    "Action": environment.apiList.GetOverridePendingApprovalList,
    "SessionID": sessionStorage.SessionID,
    "EmploymentRID": employee.RID,
    "SourceType": sourceType
  }

  const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
    res['conn_fail'] = false
    return res
  }, err => {
    err['conn_fail'] = true
    return err
  }))

  return result
  // var service = this;

  // var actionSuccess = function (data) {
  //     if (onSuccessCallback != undefined) {
  //         onSuccessCallback(service, sourceType, data.Data.ApprovalList);
  //     }
  // }

  // var actionFail = function (data, status, headers, config) {
  //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
  //     return;
  // }

  // commonService.JSONPostService(
  //     JSON.stringify({
  //         "Action": apiList.GetOverridePendingApprovalList,
  //         "SessionID": sessionStorage.SessionID,
  //         "EmploymentRID": employee.RID,
  //         "SourceType": sourceType
  //     })
  // ).then(
  //     function (response) {
  //         actionSuccess(response.data);
  //     }
  //     ,
  //     function (response) {
  //         actionFail(response.data, response.status, response.headers, response.config);
  //     }
  // );
}

  getDelegationPendingApprovalList(employee, sourceType) {

    var body = {
      "Action": environment.apiList.GetDelegationPendingApprovalList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "SourceType": sourceType
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, sourceType, data.Data.ApprovalList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetDelegationPendingApprovalList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "SourceType": sourceType
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getDelegationSettings(employee) {

    var body = {
      "Action": environment.apiList.GetDelegationSettings,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.DelegationSettings);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetDelegationSettings,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  submitDelegationSettings(employee, settings) {

    var body = {
      "Action": environment.apiList.SubmitDelegationSettings,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "DelegationSettings": JSON.stringify(settings)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.DelegationSettings);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitDelegationSettings,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "DelegationSettings": JSON.stringify(settings)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  delegationApproveRecord(approver, approvalAction, remark, approvalRecordList) {

    var body = {
      "Action": environment.apiList.DelegationApproveRecord,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": approver.RID,
      "ApprovalAction": approvalAction,
      "Remark": remark,
      "ApproveRecordList": JSON.stringify(approvalRecordList)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, approvalRecordList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.DelegationApproveRecord,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": approver.RID,
    //         "ApprovalAction": approvalAction,
    //         "Remark": remark,
    //         "ApproveRecordList": JSON.stringify(approvalRecordList)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }
}