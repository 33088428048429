import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';

declare var $: any;

@Component({
  selector: 'app-popup-inout-edit',
  templateUrl: './popup-inout-edit.component.html',
  styleUrls: ['./popup-inout-edit.component.scss']
})
export class PopupInoutEditComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  popupid = 'inOutTimePopUp';
  popupmode = 'inOutTimePopMode';
  siteDesc;
  employee;
  date;
  idx;
  punchRecord;
  punchForEdit;
  siteDetailList;
  defaultSiteDetailListIndex;

  constructor(public matDialogRef: MatDialogRef<PopupInoutEditComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public translate: TranslateService, public commonUI: CommonUIService) {
    this.employee = data['employee'];
    this.date = data['date'];
    this.idx = data['index'];
    this.punchRecord = data['punch'];
    this.siteDetailList = data['siteDetailList'];
    this.defaultSiteDetailListIndex = data['defaultSiteDetailListIndex'];
  }

  ngOnInit(): void {
    this.punchForEdit = {};

    if (this.punchRecord == undefined) {
      this.punchForEdit.PunchDateTime = this.commonUI.formatJsonDate(this.date, "YYYY-MM-DDT01:00:00");
      this.punchForEdit.Status = "I";
      this.punchForEdit.PunchLocation = this.siteDetailList[this.defaultSiteDetailListIndex].Code;
    }

    this.punchForEdit = {...this.punchForEdit, ...this.punchRecord};
    this.punchForEdit.PunchDate = this.commonUI.parseJsonDate(this.punchForEdit.PunchDateTime);
    this.punchForEdit.Hour = this.commonUI.formatJsonDate(this.punchForEdit.PunchDateTime, 'hh');
    this.punchForEdit.Minute = this.commonUI.formatJsonDate(this.punchForEdit.PunchDateTime, 'mm');
    this.punchForEdit.DayPart = this.commonUI.formatJsonDate(this.punchForEdit.PunchDateTime, 'a').toUpperCase();
    if(this.punchForEdit.DayPart == '凌晨') this.punchForEdit.DayPart = '上午';

    this.siteChange();
    this.minDate = this.punchForEdit.PunchDate;
    this.maxDate = new Date(new Date().getFullYear() + 1, 1, 1);


    $("#" + this.popupid).fadeIn();
  }

  siteChange() {
    for (var i = 0; i < this.siteDetailList.length; i++) {
      if (this.siteDetailList[i].Code == this.punchForEdit.PunchLocation) {
        this.siteDesc = this.siteDetailList[i].Desc;
        break;
      }
    }
  }

  confirmEdit() {
    var punchHour = this.punchForEdit.DayPart == "AM" ||  this.punchForEdit.DayPart == '上午' ||  this.punchForEdit.DayPart == '凌晨'? this.punchForEdit.Hour : parseInt(this.punchForEdit.Hour) + 12;
    if (this.punchForEdit.Hour == "12") {
      punchHour = (this.punchForEdit.DayPart == "AM" ||  this.punchForEdit.DayPart == '上午' ||  this.punchForEdit.DayPart == '凌晨') ? "00" : "12";
    }

    var punch = {
      PunchStr: this.commonUI.formatJsonDate(this.punchForEdit.PunchDate, 'YYYYMMDD') + punchHour + this.punchForEdit.Minute + "00",
      PunchLocation: this.punchForEdit.PunchLocation,
      PunchDateTime: this.commonUI.formatJsonDate(this.punchForEdit.PunchDate, 'YYYY-MM-DDT') + punchHour + ":" + this.punchForEdit.Minute + ":00",
      Status: this.punchForEdit.Status,
      IsFirstIn: false,
      IsLastOut: false,
    }
    this.closePopup({ Punch: punch, Index: this.idx, Mode: 'confirm' })
  }

  deleteEdit() {
    this.closePopup({ Index: this.idx, Mode: 'delete'})
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        this.punchForEdit.PunchDate = new Date()
        instance.startPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
    }
  }

  /*******************/

  closePopup(data?) {
    try {
      this.matDialogRef.close(data)
    } catch { }
  }

}
