import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment'
import { PopupMessageTextCallbackComponent } from '../common/popup-message-text-callback/popup-message-text-callback.component';
import { PopupMessageWarningComponent } from '../common/popup-message-warning/popup-message-warning.component';
import { PopupMessageWithCallbackComponent } from '../common/popup-message-with-callback/popup-message-with-callback.component';
import { PopupMessageComponent } from '../common/popup-message/popup-message.component';
import { PopupNextApproverComponent } from '../common/popup-next-approver/popup-next-approver.component';
import { PopupYesNoMessageComponent } from '../common/popup-yes-no-message/popup-yes-no-message.component';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class CommonUIService {

    loadCounter: number;
    msgcode: string;
    msgText: string;
    translationData;
    onConfirmYesNoMsgBoxWithCallback: any /* Not sure any use */
    isOpen = false;

    constructor(public matDialog: MatDialog) {
        this.loadCounter = 0;
        this.msgcode = "";
        this.msgText = "";
        this.translationData = {
            maxUploadSize: sessionStorage.MaxUploadSize,
        };
    }

    genUUID = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    postToIframe = function (data, url, target) {
        var form = $('<form method="post" id="postToIframe" target="' + target + '" ><input type="submit"></form>');
        form.attr("action", url);
        $('#main').append(form);
        $.each(data, function (n: any, v) {
            var input = $('<input type="text" name="' + n + '" />');
            input.val(v);
            //$('#postToIframe').append('<input type="hidden" name="' + n + '" value="' + v + '" />');
            $(form).append(input);
        });
        //$('#postToIframe').submit().remove();
    }

    scrollToTop = function () {
        /*
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
        */
        if ($(".list_result_area").length > 0) {
            $('html, body').stop().animate({
                scrollTop: $(".list_result_area").offset().top
            }, 1000);
        } else {
            $('html, body').stop().animate({
                scrollTop: 0
            }, 1000);
        }
    }

    initLoading = function () {
        this.loadCounter = 0;
        // $('#popup_loading').fadeOut();
    }

    addLoadingTask = function () {
        this.loadCounter++;
        $('#popup_loading').fadeIn();
    }

    finishLoadingTask = function () {
        this.loadCounter--;
        if (this.loadCounter < 0) this.loadCounter = 0;
        if (this.loadCounter == 0) $('#popup_loading').fadeOut();
    }

    onCloseMsgBoxWithCallback = function () {
        return;
    }

    formatJsonDate = function (jsondate, format) {
        var convertDate = jsondate;
        if (!jsondate)
            convertDate = moment("0001-01-01T00:00:00");
        return moment(convertDate).format(format);
    }

    formatDisplayDate = function (jsondate, format) {
        if (jsondate == null) return "";
        if (jsondate == "") return "";
        if (jsondate == "0001-01-01T00:00:00") return "";

        var dateFormat = format;
        if (localStorage.CurrentLanguage.toLowerCase() == 'zh_hk' || localStorage.CurrentLanguage.toLowerCase() == 'zh_cn') {
            dateFormat = format.replace('DD MMM YYYY', 'll');
            dateFormat = dateFormat.replace('D MMM YYYY', 'll');
            dateFormat = dateFormat.replace('DD MM YYYY', 'll');
            dateFormat = dateFormat.replace('D MM YYYY', 'll');
            dateFormat = dateFormat.replace('hh:mm A', 'A hh:mm');
            dateFormat = dateFormat.replace('h:mm A', 'A h:mm');
            dateFormat = dateFormat.replace('h:mm a', 'a h:mm');
            dateFormat = dateFormat.replace('ddd', 'dd');
        }

        var convertDate = jsondate;
        if (!jsondate)
            convertDate = moment("0001-01-01T00:00:00");
        return moment(convertDate).locale(localStorage.CurrentLanguage).format(dateFormat);
    }

    parseJsonDate = function (jsondate) {
        if (jsondate == null) return "";
        return moment(jsondate);
    }

    isoDateStringToDate(isoDateString) {
        if (isoDateString == null) return null;
        return moment(isoDateString).toDate();
    }

    dateToIsoDateString(aDate) {
        if (aDate == null) return "0001-01-01T00:00:00";
        return moment(aDate).format("YYYY-MM-DDT00:00:00");
    }

    dateToIsoDateTimeString(aDate) {
        if (aDate == null) return "0001-01-01T00:00:00";
        return moment(aDate).format("YYYY-MM-DDTHH:mm:ss");
    }

    showMsgBox(msgcode) {
        this.msgcode = msgcode
        var dialog
        if (!this.isOpen) {
            dialog = this.matDialog.open(PopupMessageComponent, {
                panelClass: 'dialog-responsive',
                data: this.msgcode
            })
            this.isOpen = true
        }

        if (dialog != undefined) {
            dialog.afterClosed().subscribe(() => {
                this.msgcode = ''
                this.isOpen = false
            })
        }
    }

    //   showMsgBox = function (msgcode) {
    //       this.msgcode = msgcode;
    //       $('#popup_msg_box').fadeIn();
    //   }

    //   hideMsgBox = function () {
    //       this.msgcode = "";
    //       $('#popup_msg_box').fadeOut();
    //   }

    showMsgBoxWithCallback(msgcode, callback?) {
        this.msgcode = msgcode;
        const showMsgBoxCallback = this.matDialog.open(PopupMessageWithCallbackComponent, {
            panelClass: 'dialog-responsive',
            data: this.msgcode
        })
        // $('#popup_msg_box_with_callback').fadeIn();
        showMsgBoxCallback.afterClosed().subscribe((res) => {
            if(res) document.location.reload()
        })
    }

    //   showMsgBoxWithCallback = function (msgcode, callback?) {
    //       this.msgcode = msgcode;
    //       $('#popup_msg_box_with_callback').fadeIn();
    //       this.onCloseMsgBoxWithCallback = callback;
    //   }

    //   hideMsgBoxWithCallback = function () {
    //       this.msgcode = "";
    //       $('#popup_msg_box_with_callback').fadeOut();
    //       this.onCloseMsgBoxWithCallback();
    //   }

    showYesNoMsgBoxWithCallback(msgcode, callback) {
        this.msgcode = msgcode
        const showYesNoBox = this.matDialog.open(PopupYesNoMessageComponent, {
            data: this.msgcode,
            panelClass: 'dialog-responsive'
        })

        showYesNoBox.afterClosed().subscribe(() => {
            this.onConfirmYesNoMsgBoxWithCallback = callback;
        })
    }

    //   showYesNoMsgBoxWithCallback = function (msgcode, callback) {
    //       this.msgcode = msgcode;
    //       $('#popup_msg_box_yes_no_with_callback').fadeIn();
    //       this.onConfirmYesNoMsgBoxWithCallback = callback;
    //   }

    //   hideMsgBoxYesNoWithCallback = function () {
    //       this.msgcode = "";
    //       $('#popup_msg_box_yes_no_with_callback').fadeOut();
    //       this.onCloseMsgBoxWithCallback();
    //   }

    showMsgTextBoxWithCallback(msgText, callback) {
        this.msgText = msgText
        const showMsgTextBox = this.matDialog.open(PopupMessageTextCallbackComponent, {
            data: this.msgText,
            panelClass: 'dialog-responsive'
        })


        showMsgTextBox.afterClosed().subscribe(() => {
            callback;
        })
    }

    //   showMsgTextBoxWithCallback = function (msgText, callback) {
    //       this.msgText = msgText;
    //       $('#popup_msgtext_box_with_callback').fadeIn();
    //       this.onCloseMsgTextBoxWithCallback = callback;
    //   }

    //   hideMsgTextBoxWithCallback = function () {
    //       this.msgText = "";
    //       $('#popup_msgtext_box_with_callback').fadeOut();
    //       this.onCloseMsgTextBoxWithCallback();
    //   }

    showWarning(msgcode) {
        this.msgcode = msgcode
        this.matDialog.open(PopupMessageWarningComponent, {
            data: this.msgcode,
            panelClass: 'dialog-responsive'
        })
    }

    showNextApprover(application) {
        application.StartDate = this.formatJsonDate(application.StartDate ,'YYYY-MM-DDT00:00:00')
        this.matDialog.open(PopupNextApproverComponent, {
            data: {
                record: application
            },
            panelClass: 'dialog-responsive'
        })
    }

    //   showWarning = function (msgcode) {
    //       this.msgcode = msgcode;
    //       $('#popup_warning').fadeIn();
    //   }

    //   hideWarning = function () {
    //       this.msgcode = "";
    //       $('#popup_warning').fadeOut();
    //   }

    getStaffImage(staffInfo) {
        if (staffInfo == null) return "assets/img/M.png";
        if (staffInfo.ImagePath == "") {
            if (staffInfo.Gender == "F") {
                return "assets/img/F.png";
            } else {
                return "assets/img/M.png";
            }
        } else {
            return "staffPhoto/" + staffInfo.ImagePath + "?" + sessionStorage.SessionID;
        }
    }

    hourRange() {
        var hRange = [];
        for (var i = 1; i <= 12; i += 1) {
            hRange.push(i < 10 ? "0" + i : i);
        }
        return hRange;
    };

    minuteRange() {
        var mRange = [];
        for (var i = 0; i <= 59; i += 1) {
            mRange.push(i < 10 ? "0" + i : i);
        }
        return mRange;
    };

    minuteRangeMod() {
        var mRange = ['00', '30'];
        return mRange;
    }

    dataURLtoBlob = function (dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    GetFileIcon(type) {
        switch (type.toLowerCase()) {
            case "pdf":
                return "icon_pdf.png";
            case "xls":
            case "xlsx":
                return "icon_excel.png";
            default:
                return "icon_claim_maintenance.png";
        }
    }

}