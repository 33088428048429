<div class="flag_submit_remarks_content" id="{{ rejectRemarkPopupid }}">
    <div class="pop_functions_box pop_functions_box_size_company">
        <div style="clear:both"></div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'approval.remarksofreject' | translate }}</div>
        </div>

        <div class="submit_remarks_area" *ngIf='leaveapplication'>
            <div class="txt_photo_area_small fl" [ngStyle]="{'background-image' : approver_staffinfo ? 'url(' + commonUI.getStaffImage(approver_staffinfo) + ')' : ''}">
                <div class="txt_photo_mask_small p_normal_grey"></div>
            </div>
            <div class="approver_staff_content">
                <div class="txt_bold">{{ approver_staffinfo ? approver_staffinfo.FullName : ''}}</div>
                <textarea id="txtRejectReason" [(ngModel)]="rejectreason" class="approver_staff_remark_input form-control txt_box_1 txt_size_2 bg_none" placeholder="{{ 'leaveapplicationinquery.pleaseinputremarks' | translate }}"></textarea>
            </div>
            <div style="clear:both"></div>
        </div>

        <div class="pop_functions_two_btn_center">
            <button *ngIf='special == undefined' class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="rejectEvent()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}</button>
            <!-- <button *ngIf='special == "OT"' class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="rejectOTEvent()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}</button> -->
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" (click)="close()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}</button>
        </div>

    </div>
</div>