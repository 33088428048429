<div id="content_leave_application">
    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'appointmentapplication.appointment' | translate }}</span>
    </div>

    <div class="content_box">

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_application"></div>
            <div class="fl">{{ 'appointmentapplication.application' | translate }}</div>
        </div>

		<div class="box_content_inquiry">

            <div class="form_area">
                <form name="form_application" novalidate>
                    <div *ngFor="let appointment of appointments; let i = index;">
                        <div id="form_{{i}}" class="form_content" *ngIf="!appointment.deleted">

                            <div class="form_content_title bg_grey_3" (click)="slideUp(appointment, i)">
                                <div class="bg_grey_2b"><span class="txt_size_3">No.&nbsp;</span>{{appointment.index}}</div>
                                <div>
                                    <span>&nbsp;{{ appointment.Event }}</span>
                                    <span class="txt_size_3 txt_grey_2">
                                        &nbsp;{{ commonUI.formatDisplayDate(appointment.AppointStartDate, 'D MMM YYYY (ddd)') }} {{ appointment.AppointStartHour | number }} : {{ appointment.AppointStartMinute }}&nbsp;{{ appointment.AppointStartDayPart }}
                                        &nbsp;~&nbsp;
                                        {{ commonUI.formatDisplayDate(appointment.AppointEndDate, 'D MMM YYYY (ddd)') }} {{ appointment.AppointEndHour | number }} : {{ appointment.AppointEndMinute }}&nbsp;{{ appointment.AppointEndDayPart }}
                                    </span>
                                </div>
                            </div>
    
                            <div class="form_content_input" id="form_content_input_{{i}}">
    
                                <div class="btn_sub_title txt_grey_2">{{ 'appointmentapplication.startdate' | translate }}</div>
    
                                <div class="fl input_new_calendar">
                                    <div>
                                        <mat-form-field appearance='fill' (click)="startPicker.open()">
                                            <input matInput [matDatepicker]="startPicker" [(ngModel)]="appointment.AppointStartDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('start')" name='startPicker_{{i}}'>
                                            <mat-datepicker-toggle matSuffix [for]="startPicker" name='spicker'></mat-datepicker-toggle>
                                            <mat-datepicker #startPicker (opened)="openAppend('start', i)"></mat-datepicker>
                                        </mat-form-field>
                                        <div style="display: none">
                                            <div class="datepicker-footer" #startFooter>
                                                <button mat-raised-button  (click)="today('start', selectedIndex)" #todayButton name="startDateButton_{{selectedIndex}}">
                                                    {{'TODAY' | translate}}
                                                </button>
                                                <button mat-raised-button (click)="close('start', selectedIndex)" #doneButton>
                                                    {{'close' | translate}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="fl time_field btn_two_margin_left_m txt_grey_2">
    
                                    <div class="fl">
                                        <select id="h1_{{i}}" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointment.AppointStartHour" name='hour_{{i}}'>
                                            <option *ngFor="let hour of commonUI.hourRange()" title="{{hour}}" value="{{hour}}">{{hour}}</option>
                                        </select>
                                    </div>
                                    <div class="fl">&nbsp;:&nbsp;</div>
                                    <div class="fl">
                                        <select id="m1_{{i}}" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointment.AppointStartMinute" name='min_{{i}}'>
                                            <option *ngFor="let minute of commonUI.minuteRange()" title="{{minute}}" value="{{minute}}">{{minute}}</option>
                                        </select>
                                    </div>
                                    <div class="fl">&nbsp;&nbsp;</div>
                                    <div class="fl">
                                        <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointment.AppointStartDayPart" name='time_{{i}}'>
                                            <option title="00" value="AM">{{ 'appointmentapplication.am' | translate }}</option>
                                            <option title="01" value="PM">{{ 'appointmentapplication.pm' | translate }}</option>
                                        </select>
    
                                    </div>
    
                                </div>
    
    
                                <div style="clear:both"></div>
                                <br />
    
    
                                <div class="btn_sub_title txt_grey_2">{{ 'appointmentapplication.enddate' | translate }}</div>
    
                                <div class="fl input_new_calendar">
                                    <div>
                                        <mat-form-field appearance='fill' (click)="endPicker.open()">
                                            <input matInput [matDatepicker]="endPicker" [(ngModel)]="appointment.AppointEndDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('end')" name='endPicker_{{i}}'>
                                            <mat-datepicker-toggle matSuffix [for]="endPicker" name='epicker'></mat-datepicker-toggle>
                                            <mat-datepicker #endPicker (opened)="openAppend('end', i)"></mat-datepicker>
                                        </mat-form-field>
                                        <div style="display: none">
                                            <div class="datepicker-footer" #endFooter>
                                                <button mat-raised-button  (click)="today('end', selectedIndex)" #todayButton name="endDateButton_{{selectedIndex}}">
                                                    {{'TODAY' | translate}}
                                                </button>
                                                <button mat-raised-button (click)="close('end', selectedIndex)" #doneButton>
                                                    {{'close' | translate}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="fl time_field btn_two_margin_left_m txt_grey_2">
    
                                    <div class="fl">
                                        <select id="h2_{{i}}" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointment.AppointEndHour" name='hour2_{{i}}'>
                                            <option *ngFor="let hour of commonUI.hourRange()" title="{{hour}}" value="{{hour}}">{{hour}}</option>
                                        </select>
                                    </div>
                                    <div class="fl">&nbsp;:&nbsp;</div>
                                    <div class="fl">
                                        <select id="m2_{{i}}" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointment.AppointEndMinute" name='min2_{{i}}'>
                                            <option *ngFor="let minute of commonUI.minuteRange()" title="{{minute}}" value="{{minute}}">{{minute}}</option>
                                        </select>
                                    </div>
                                    <div class="fl">&nbsp;&nbsp;</div>
                                    <div class="fl">
                                        <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="appointment.AppointEndDayPart" name='time2_{{i}}'>
                                            <option title="00" value="AM">{{ 'appointmentapplication.am' | translate }}</option>
                                            <option title="01" value="PM">{{ 'appointmentapplication.pm' | translate }}</option>
                                        </select>
    
                                    </div>
    
                                </div>
    
                                <div style="clear:both"></div>
                                <br />
    
                                <div class="btn_sub_title txt_grey_2">{{ 'appointmentapplication.event' | translate }}</div>
                                <textarea [(ngModel)]="appointment.Event" class="form-control detail_staff_remark_input txt_box_1 txt_size_2" maxlength='25' name='event_{{i}}'></textarea>
                                <br />

                                <div class="btn_sub_title txt_grey_2">{{ 'appointmentapplication.details' | translate }}</div>
                                <textarea [(ngModel)]="appointment.Desc" class="form-control detail_staff_remark_input txt_box_1 txt_size_2" [required]="appointment.MustInputEventDetail" maxlength='255' name='desc_{{i}}'></textarea>
    
                            </div>  <!-- ========== end : form_content_input =========== -->
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this" *ngIf="getNumOfActiveAppointments() != 1" (click)="deleteAppointment(appointment, i)">x</div>
                        </div> <!-- ========== end : form_content =========== -->
                    </div>
                </form>
            </div> <!-- ========== end : form_area =========== -->
            <div style="clear:both"></div>
        </div> <!-- ========== end : box_content_inquiry =========== -->

        <div class="mult_select_approval_area" style="display: block;">
            <div class="pop_functions_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 add" type="button" (click)="addAppointment()"><img src="assets/img/btn_addform_w.png" width="18" />{{ 'add' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn maincolor_btn2" type="submit" (click)="submitAppointmentForm()"><img src="assets/img/btn_tick_w.png" width="18" />{{ 'submit' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey" type="button" (click)="resetAppointmentApplication()"><img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>

    </div><!-- ============ end : content_box ============ -->
    <br/><br/><br/>
</div>