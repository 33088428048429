import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'
import { CommonUIService } from '../services-UI/common-ui.service'
import { ErrorUIService } from '../services-UI/error-ui.service'
import { HttpClient, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { InterceptorService } from './interceptor.service'
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class InOutService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService, public domSanitizer: DomSanitizer) { }

  getShiftDetailList(employee, date) {

    var body = {
      "Action": environment.apiList.GetShiftDetailList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Date": date,
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;

    // var service = this;

    // var actionSuccess = function (data) {
    //   if (onSuccessCallback != undefined) {
    //     onSuccessCallback(service, data.Data.ShiftDetailList);
    //   }
    // }

    // var actionFail = function (data, status, headers, config) {
    //   if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //   return;
    // }

    // this.commonService.JSONPostService(
    //   JSON.stringify({
    //     "Action": apiList.GetShiftDetailList,
    //     "SessionID": sessionStorage.SessionID,
    //     "EmploymentRID": employee.RID,
    //     "Date": date,
    //   })
    // ).then(
    //   function (response) {
    //     actionSuccess(response.data);
    //   }
    //   ,
    //   function (response) {
    //     actionFail(response.data, response.status, response.headers, response.config);
    //   }
    // );
  }

  getStaffPunchDetailList(employee, startDate, endDate) {

    var body = {
      "Action": environment.apiList.GetStaffPunchDetailList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.ShiftDetailList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetStaffPunchDetailList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "StartDate": startDate,
    //         "EndDate": endDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getRawPunchDetailList(employee, startDate, endDate) {

    var body = {
      "Action": environment.apiList.GetRawPunchDetailList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.ShiftDetailList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetRawPunchDetailList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "StartDate": startDate,
    //         "EndDate": endDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  submitEInOutApplication(employee, inOutApplication) {

    var body = {
      "Action": environment.apiList.SubmitEInOutApplication,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "InOutApplicationForm": JSON.stringify(inOutApplication)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitEInOutApplication,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "InOutApplicationForm": JSON.stringify(inOutApplication)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getSelfEInOutApplicationList(employee, startDate, endDate, status) {
    // var service = this;
    if (status == "All") status = "";
    var body = {
      "Action": environment.apiList.GetSelfEInOutApplicationList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "Status": status
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.EInOutApplicationList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetSelfEInOutApplicationList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "StartDate": startDate,
    //         "EndDate": endDate,
    //         "Status": status
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getTeammateEInOutApplicationList(employee, startDate, endDate, status) {
    // var service = this;
    if (status == "All") status = "";
    var body = {
      "Action": environment.apiList.GetTeammateEInOutApplicationList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "Status": status
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.EInOutApplicationList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetTeammateEInOutApplicationList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "StartDate": startDate,
    //         "EndDate": endDate,
    //         "Status": status
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getTeamPunchDetailList(employee, punchDate, endDate) {
    // var service = this;
    if (status == "All") status = "";
    var body = {
      "Action": environment.apiList.GetTeammatePunchDetailList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Date": punchDate,
      "EndDate": endDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;
    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.EEmployPunchDetailList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetTeammatePunchDetailList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "Date": punchDate,
    //         "EndDate": endDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getSelfPunchDetailList(employee, punchDate, endDate) {
    // var service = this;
    if (status == "All") status = "";
    var body = {
      "Action": environment.apiList.GetSelfPunchDetailList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "Date": punchDate,
      "EndDate": endDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.EEmployPunchDetailList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetSelfPunchDetailList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "Date": punchDate,
    //         "EndDate": endDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  withdrawEInOutApplication(employee, inOutTimeApplication) {
    var body = {
      "Action": environment.apiList.WithdrawEInOutAppilcation,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "InOutApplicationRID": inOutTimeApplication.RID,
      "InOutApplicationDate": inOutTimeApplication.Date
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.WithdrawEInOutAppilcation,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "InOutApplicationRID": inOutTimeApplication.RID,
    //         "InOutApplicationDate": inOutTimeApplication.Date
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }
}