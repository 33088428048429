<!--============ popup : detail of Dependents ============ -->

<div id='Depndt' class="pp_dependents_content" style="display: block;">
	<div class="pop_functions_box pop_functions_box_size_tc pop_functions_box_size_2column">
    	<div class="pop_functions_close btn_grey_2b pp_close" title="close" (click)="close()">x</div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
              <div class="box_title_icon t_general"></div>
              <div class="fl">{{ 'personalprofileinquiry.detailof' | translate }}{{ 'personalprofile.tabdependents' | translate }}</div>
        </div>

        <div class="d_box_top"></div>

        <div class="detail_box_bottom_area_profile mt0_m">

            <div class="box_content_inquiry">
            	<form id="form_application">

                    <div class="form_area *ngIf='obj'">

                        <div *ngFor="let sub of obj.SubRecordList; let i = index">
                    	<!-- ========== a record =========== -->
                        <div id="form-{{i}}" class="form_content form_content_popup" (click)="slideUp($event)">

                        	<div class="form_content_title form_content_title-selected">{{ sub.RelationshipDesc }}
                            	<span [ngClass]="{
                                    'color_label_keep': sub.Action == 'K',
                                    'color_label_update': sub.Action == 'U',
                                    'color_label_remove': sub.Action == 'D',
                                    'color_label_new': sub.Action == 'I' }"
                                class="txt_label txt_label_profile_action txt_size_3">{{ 'personalprofileinquiry.typeAction.' + sub.Action | translate }}</span>
                            </div>

                            <div class="form_content_input">
                                <div class="full_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.name' | translate }}</span><br>
                                    {{ sub.FullName }}
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.mobile' | translate }}</span><br>
                                    {{ sub.Mobile }}
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.emergencycontact' | translate }}</span><br>
                                    <span *ngIf="sub.IsEmergency">{{ 'yes' | translate }}</span><span *ngIf="!sub.IsEmergency">{{ 'no' | translate }}</span>
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.birthday' | translate }}</span><br>
                                    {{commonUI.formatDisplayDate(sub.Birthday, 'DD MMM YYYY')}}
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.idnumber' | translate }}</span><br>
                                    {{ sub.IdentityNum }}
                                </div>
                                <div class="same_line">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.idtype' | translate }}</span><br>
                                    {{ sub.IdentityTypeDesc }}
                                </div>
								<div class="same_line">
										<span class="txt_grey_2 txt_size_3">{{ 'personalprofile.hkid' | translate }}</span><br>
										{{ sub.HKID }}
								</div>
                                <div class="full_line" *ngIf="sub.AttachmentAddr">
                                    <span class="txt_grey_2 txt_size_3">{{ 'personalprofile.attachment' | translate }}</span><br>
                                    <!-- <a href="img/letter_sample.jpg" target="_blank"> -->
                                    <div class="attachment_photo">
                                        <div><img [src]="sub.attachment.dataURL"></div>
                                        <div id="dl_attach" class="attachment_photo_touch" (click)="commonHandler.saveAttachment(sub.AttachmentLink, sub.AttachmentName)"></div>
                                    </div>
                                    <!-- </a> -->
                                    <div style="clear:both"></div>
                                </div>
                                <div style="clear:both"></div>
                            </div> <!-- ========== end : form_content_input =========== -->
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                        </div> <!-- ========== end : form_content =========== -->
                        </div>


                    </div> <!-- ========== end : form_area =========== -->

            	</form>
            </div> <!-- ========== end : form_content_inquiry =========== -->
		</div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

	</div>
</div>
