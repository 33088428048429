import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHandlerService } from 'src/app/services/common-handler.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { environment } from 'src/environments/environment';
import { CommonUIService } from '../../services-UI/common-ui.service';
import { ImageEnlargePreviewComponent } from '../../common/image-enlarge-preview/image-enlarge-preview.component';
import { PopupConfirmWithdrawComponent } from '../../common/popup-confirm-withdraw/popup-confirm-withdraw.component';
import { PopupSubmitApprovalRemarksComponent } from '../../common/popup-submit-approval-remarks/popup-submit-approval-remarks.component';
import { PopupSubmitRejectRemarksComponent } from '../../common/popup-submit-reject-remarks/popup-submit-reject-remarks.component';

@Component({
  selector: 'app-popup-eclaim-detail',
  templateUrl: './popup-eclaim-detail.component.html',
  styleUrls: ['./popup-eclaim-detail.component.scss']
})
export class PopupEclaimDetailComponent implements OnInit {

  popupid;
  popupmode;
  claim;
  approverRemarkList;
  
  approver_staffinfo;

  attachment1;
  attachment2;
  attachment3;
  attachment4;

  attachmentFile = {};

  isDelegate = false;

  constructor(public commonHandler: CommonHandlerService, public commonService: CommonServiceService, public commonUI: CommonUIService, private matDialogRef: MatDialogRef<PopupEclaimDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, private matDialog: MatDialog) { 
    this.popupmode = data['popupmode'];
    this.claim = data['app'];
    this.approverRemarkList = data['approver_remark_list'];

    this.attachment1 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment2 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment3 = { downloaded: false, dataURL: "", ext: "" };
    this.attachment4 = { downloaded: false, dataURL: "", ext: "" };

    if (this.claim.AttachmentLink1 != "") this.commonService.downloadAttachment(this.claim.AttachmentLink1, environment.GetFileExtension(this.claim.AttachmentName1), this.attachment1).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    if (this.claim.AttachmentLink2 != "") this.commonService.downloadAttachment(this.claim.AttachmentLink2, environment.GetFileExtension(this.claim.AttachmentName2), this.attachment2).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    if (this.claim.AttachmentLink3 != "") this.commonService.downloadAttachment(this.claim.AttachmentLink3, environment.GetFileExtension(this.claim.AttachmentName3), this.attachment3).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
    if (this.claim.AttachmentLink4 != "") this.commonService.downloadAttachment(this.claim.AttachmentLink4, environment.GetFileExtension(this.claim.AttachmentName4), this.attachment4).subscribe(() => {
    }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  showWithdrawConfirm() {
    const dialog = this.matDialog.open(PopupConfirmWithdrawComponent, {
      panelClass: "responsive-dialog",
      data: {
        claimapp: this.claim,
        popid: 'withdrawConfirmPopUp',
        type: 'claim'
      }
    })
    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data.confirm) {
        this.close(data)
      }
    })
  }

  showApprovePopup() {

    //approveRemarkPopup
    const dialog = this.matDialog.open(PopupSubmitApprovalRemarksComponent, {
      panelClass: 'dialog-responsive',
      data: {
        popid: 'approveRemarkPopup',
        app: this.claim,
        isDelegate: this.isDelegate,
      }
    })

    dialog.afterClosed().subscribe((data) => {
      if(data) this.close(data)
    })
    // $("#" + this.popupid).fadeOut();
    // $("#" + this.approveRemarkPopupid).fadeIn();
  }

  showRejectPopup() {
    const dialog = this.matDialog.open(PopupSubmitRejectRemarksComponent, {
      panelClass: 'dialog-responsive',
      data: {
        popid: 'rejectRemarkPopup',
        app: this.claim,
        isDelegate: this.isDelegate
      }
    })

    dialog.afterClosed().subscribe((data) => {
        if(data) this.close(data)
    })

    // $("#" + this.popupid).fadeOut();
    // $("#" + this.rejectRemarkPopupid).fadeIn();
  }

  viewAttachment(link, fileName) {
    var ext = environment.GetFileExtension(fileName);
    if (environment.isMobileDevice() || environment.isIE() || !environment.isValidatePreviewAttachment(ext)) {
      this.commonUI.finishLoadingTask();
      this.commonHandler.saveAttachment(link, 'Attachment.' + ext);
    } else {
      this.commonService.viewAttachment(link, ext, this.attachmentFile).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.matDialog.open(ImageEnlargePreviewComponent, {
            data: {
              dataURL: result.body['dataURL']
            },
            panelClass: 'dialog-responsive'
          })
        }
        else {
          this.commonUI.finishLoadingTask();
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
    }
  }

  ngOnInit(): void {
  }

  close(data?) {
    try {
      this.matDialogRef.close(data);
    } catch {}
  }

  onCallAPIFail(data, status, headers, config) {
    this.close();
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
