import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CommonUIService } from './services-UI/common-ui.service';
import { ErrorUIService } from './services-UI/error-ui.service';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppRoutingModModule, routesMod } from './app-routing.module-mod';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  
  title = 'ESS';

  constructor(public translateService: TranslateService, public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router) {
    this.translateService.setDefaultLang('en')
    this.translateService.use('en')
    this.translateService.onLangChange.subscribe(({ lang }: LangChangeEvent) => {
      localStorage.setItem('CurrentLanguage', lang)
      localStorage.CurrentLanguage == 'en' ? moment.locale('en') : moment.locale('zh-hk')
    })
  }

  ngOnInit() {
    if(environment.siteConfig.enableModMenu) this.router.resetConfig(routesMod);
    else this.router.resetConfig(routes)
  } 
  
}
