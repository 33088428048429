    <!--============ popup : company select  ============ -->
    <div id="popup_select_company">
        <div class="pop_functions_box pop_functions_box_size_company">
          <div class="pop_functions_close btn_grey_2b pp_close" (click)="close()">x</div>
          <div style="clear: both"></div>
          <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_company"></div>
            <div class="fl">{{ 'login.selectcompany' | translate }}</div>
          </div>
          <div>
      
            <div *ngIf='companyList'>
              <div *ngFor="let company of companyList" class="box_1_staff btn_opacity" title={{company.CompanyName}} (click)="openJobPosition(company)">
                  <div class="txt_photo_area_big" [ngStyle]="{'background-image':'url(' + 'companylogo/' + company.CompanyCode + '.jpg' + ')'}">
                    <div class="txt_photo_mask_big p_normal_big"></div>
                  </div>
                  <div class="box_1_staff_name">
                    <span class="txt_size_2">{{company.CompanyName}}</span>
                  </div>
                  <div style="clear: both;"></div>
                </div>
            </div>
      
          </div>
      
        </div>
      </div>