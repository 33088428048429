<div id="popup_msg_box_yes_no_with_callback" class="pop_msg">
    <div class="pop_functions_box pop_functions_box_size_1">
        <br />
        <img src="assets/img/msg_tick.png" width="48">
        <br /><br />{{ msgcode | translate:commonUI.translationData }}<br /><br />
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="commonUI.onConfirmYesNoMsgBoxWithCallback(); close()">
                <img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'submit' | translate }}
            </button>
            <button class="btn btn-block btn-lg two_btn btn_grey flag_submit_remarks_close" (click)="close()">
                <img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'cancel' | translate }}
            </button>
            <div style="clear: both"></div>
        </div>
    </div>
</div>