<!-- Login HTML -->
<!-- For Loading task -->
<app-common-control></app-common-control>
<div *ngIf="!isV11Container">
    <!-- <div class="login_box_abs">
        <div class="login_bg">
            <img src="assets/img/logo_acube_vertical.png" height='40'/>
        </div>

        <span class="txt_size_0 txt_grey_2">{{ 'app.name' | translate}} </span> <br/> <br/>
        <div class="txt_photo_area_big" [ngStyle]="{'background-image': 'url(' + userImagePath + ')' }">
            <div class="txt_photo_mask_big p_normal_big"></div>
        </div>
        <br/>

        <form id="login_submit" ngNativeValidate>
            <div class="input_login_position">
                <input  [(ngModel)]='userCode' id="txt_usercode" type='text' value="" name='uid'
                    placeholder="{{ 'login.userid' | translate}}" class="form-control input_txt_icon_right" required
                    autofocus/>
                    <div class="input_icon_1">
                        <img src="assets/img/input_id.png" width="18">
                    </div>
            </div>

            <br />
            <div class="input_login_position">
                <input [(ngModel)]="password" id="txt_password" type="password" name='pw' value="" placeholder="{{ 'login.password' | translate }}" class="form-control input_txt_icon_right"
                       required>
                <div class="input_icon_1">
                    <img src="assets/img/input_pw.png" width="18">
                </div>
            </div>
            <br />

            <div class="btn_signin_position">
                <button class="btn btn-block btn-lg maincolor_btn2 icon_arrow_r" type="submit" (click)='login()' >{{ 'login.signin' | translate }}</button>

                <div class="checkbox_reset_1">
                    <input  [ngModelOptions]="{standalone: true}" [(ngModel)]="rememberme" type='checkbox' name='rememberme' value='valuable' id="rememberme" checked='checked'/> <label for="rememberme">
                        <span class="checkbox_reset_txt txt_grey_2">{{ 'login.rememberme' | translate}}</span>
                    </label>
                </div>

            </div>
        </form>

        <a *ngIf="enableForgetPassword" ui-sref="forgot-password"><div class="btn_fp txt_grey_2 txt_size_3" routerLink="/forgot-password">{{ 'login.forgotpassword' | translate }} ?</div></a>
        <div *ngIf="enableForgetPassword" class="pp2 btn_tc txt_grey_2 txt_size_3" (click)="openModal('popup_t_and_c')">{{ 'login.termsandconditions' | translate }}</div>
        <div *ngIf="enableForgetPassword == false" style="width:100%" class="pp2 btn_tc txt_grey_2 txt_size_3" (click)="openModal('popup_t_and_c')">{{ 'login.termsandconditions' | translate }}</div>
        <div style="clear: both"></div>
        <div class="login_lang" *ngIf='languageList'>
            <select class="btn btn_dropdown_1 icon_languages" (change)="changeLanguage()" [(ngModel)]="language">
                <option *ngFor="let lang of languageList" [value]="lang.value">{{ lang.desc | translate }}</option>
            </select>
        </div>
    </div> -->

    <!--============ popup : login  ============ -->
    <!-- <div id="pp1_login_content" class="pop_functions_div">
        <div class="pop_functions_box pop_functions_box_size_company">
            <div class="pop_functions_close btn_grey_2b pp_close" click="logout()">x</div>
            <div style="clear: both"></div>
            <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
                <div class="box_title_icon t_company"></div>
                <div class="fl">{{ login.selectcompany }}</div>
            </div>
            <div>

                <div *ngFor="let company of companyList" class="box_1_staff btn_opacity" title={{company.CompanyName}} click="selectCompany(company)">
                    <div class="txt_photo_area_big" [ngStyle]="{'background-image': 'url(' + 'companylogo/' + company.CompanyCode + '.jpg' + ')'}"> 
                        <div class="txt_photo_mask_big p_normal_big"></div>
                    </div>
                    <div class="box_1_staff_name">
                        <span class="txt_size_2">{{company.CompanyName}}</span>
                    </div>
                    <div style="clear: both;"></div>
                </div>

            </div>

        </div>
    </div> -->
    <!--============ popup : job position select  ============ -->


    <!-- <div class="pp3_content pop_functions_div">
        <div class="pop_functions_box pop_functions_box_size_company">
            <div id="btn_back_company" class="pop_functions_close btn_grey_2b" click="back()">x</div>
            <div style="clear: both"></div>
            <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
                <div class="box_title_icon t_employment"></div>
                <div class="fl">{{ login.selectposition }}</div>
            </div> -->

            <!-- <span class="txt_grey_2">{{ 'login.selectposition' | translate }}</span> <br /> <br /> -->
            <!--
                  <div class="box_grid_two btn_jobpsoition company_logo_center" title="aCube Solutions Limited" ng-if="selectedCompany != null">
                    <div class="txt_photo_area_big" ng-attr-style="background-image: url(companylogo/{{selectedCompany.CompanyCode}}.png);">
                      <div class="txt_photo_mask_big p_normal_big"></div>
                    </div>
                    <div style="clear: both;"></div>
                    <div class="txt_box_label_name">{{selectedCompany.CompanyName}}</div>
                  </div>
                  <div style="clear: both;"></div>
                  <br />
              -->

            <!-- <div class="jobposition_content bg_grey_4">
                <div *ngFor="let employee of selectedCompany.StaffList">
                    <button class="btn btn-block btn-lg maincolor_btn2 icon_arrow_r" click="selectEmployee(employee)">
                        <div class="btn_txt_icon_right">{{employee.StaffID}}</div>
                    </button>
                </div>
            </div> -->

        <!-- </div>
    </div> -->
</div>