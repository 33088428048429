<div>
    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2"></span>
    </div>
    <div class="content_box">
        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_calendar"></div>
            <div class="fl">{{ 'menu.calendar' | translate }}</div>
            <div style="clear:both;"></div>
        </div>
        <div class="calendar_date_bar">
            <div class="btn_grey_linewhite" title="Last Month" (click)="goToPrevMonth()" [hidden]="viewDate <= minMonth"><img src="assets/img/btn_arrow_back.png" width="20" alt=""></div>
            <div *ngIf='viewMonthList'>
                <select class="btn btn_dropdown_1" [(ngModel)]="viewDate" (change)="refreshCalendar()">
                    <option *ngFor="let month of viewMonthList; trackBy: month;" [value]="month">{{(commonUI.formatDisplayDate(month, 'MMM') | translate) + ' ' + commonUI.formatDisplayDate(month, 'YYYY')}}</option>
                </select>
            </div>
            <div class="btn_grey_linewhite" title="Next Month" (click)="goToNextMonth()" *ngIf="viewDate < maxMonth"><img src="assets/img/btn_arrow_right.png" width="20" alt=""></div>
            <div style="clear:both;"></div>
        </div>

        <div class="calendar_remark txt_size_3">
            <div class="label_remarks"><img alt="" src="assets/img/label_subordinate.png" width="8">&nbsp;{{ 'calendar.subordinate' | translate }}</div>
            <div class="label_remarks"><img alt="" src="assets/img/label_personal.png" width="8">&nbsp;{{ 'calendar.personal' | translate }}</div>
        </div>

        <a name="back_top_page_calendar" class="list_bar_mt"></a>
        <ul class="filter_calendar_reset">
            <li>
                <a class="c_all" [ngClass]="{'active': selectedView == 'all'}" (click)="showAll()">{{ 'all' | translate }}</a>
            </li>
            <li>
                <a class="c_leave filter_calendar_options" [ngClass]="{'active': selectedView == 'leave'}" (click)="showLeave()"><img src="assets/img/icon_calendar_leave.png" width="20"> {{ 'calendar.leave' | translate }}</a>
            </li>
            <li>
                <a class="c_appointment filter_calendar_options" [ngClass]="{'active': selectedView == 'appointment'}" (click)="showAppointment()"><img src="assets/img/icon_calendar_appointment.png" width="20"> {{ 'calendar.appointment' | translate }}</a>
            </li>
        </ul>

        <div style="clear:both;"></div>

        <!-- calendar view -->

        <mwl-calendar-month-view [events]="events" [viewDate]="viewDate" [locale]='locale' [cellTemplate]="tempView"></mwl-calendar-month-view>

        <ng-template class="calendar_grid" let-weekendDays="weekendDays" let-view='view' let-locale='locale' let-day='day' #tempView>
            <ul class="days days_2">
                <div class="btn_inquiry_search">
                    <div *ngIf="day.inMonth && calendarScope.teammateCalendarData?.ECalendarDateList &&calendarScope.selfCalendarData?.ECalendarDateList">
                        <li (click)="setSelectedDay(day.date)">
                            <div id="daymarker_{{ stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) }}" [ngClass]="calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) -1].IsHoliday? 'weekday0' : '' " >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</div>
                            <div class="label_box_area"  *ngIf="getCalendarDayRecordCount(calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1]) != 0 ||
                                                                getCalendarDayRecordCount(calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1]) != 0">
                                <div class="label_box fl day_status_remark">
                                    <div></div>
                                    <div>{{ 'calendar.pending' | translate }}</div>
                                    <div>{{ 'calendar.approved' | translate }}</div>
                                </div>
                            </div>
        
                            <div class="label_box_area label_subordinate" *ngIf="getCalendarDayRecordCount(calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1]) != 0">
        
                                <div class="label_box fl c_leave" title="Leave" style="display: block;"
                                        *ngIf="calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingLeaveCount > 0 ||
                                                calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedLeaveCount">
                                    <div></div>
                                    <div title="{{ 'calendar.pending' | translate }} {{ 'calendar.leave' | translate }}{{ 'calendar.subordinate2' | translate }}"><div>{{ calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingLeaveCount }}</div></div>
                                    <div title="{{ 'calendar.approved' | translate }} {{ 'calendar.leave' | translate }}{{ 'calendar.subordinate2' | translate }}"><div>{{ calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedLeaveCount }}</div></div>
                                </div>
        
                                <div class="label_box fl c_appointment" title="Appointment" style="display: block;"
                                    *ngIf="calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingAppointmentCount > 0 ||
                                            calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedAppointmentCount">
                                    <div></div>
                                    <div title="{{ 'calendar.pending' | translate }} {{ 'calendar.appointment' | translate }}{{ 'calendar.subordinate2' | translate }}"><div>{{ calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingAppointmentCount }}</div></div>
                                    <div title="{{ 'calendar.approved' | translate }} {{ 'calendar.appointment' | translate }}{{ 'calendar.subordinate2' | translate }}"><div>{{ calendarScope.teammateCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedAppointmentCount }}</div></div>
                                </div>
        
                                <div style="clear:both;"></div>
                            </div>
        
                            <div class="label_box_area label_me" *ngIf="getCalendarDayRecordCount(calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1]) != 0">
        
                                <div class="label_box fl c_leave" title="Leave" style="display: block;"
                                    *ngIf="calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingLeaveCount > 0 ||
                                            calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedLeaveCount > 0">
                                    <div></div>
                                    <div title="{{ 'calendar.pending' | translate }} {{ 'calendar.leave' | translate }}{{ 'calendar.personal2' | translate }}"><div>{{ calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingLeaveCount }}</div></div>
                                    <div title="{{ 'calendar.approved' | translate }} {{ 'calendar.leave' | translate }}{{ 'calendar.personal2' | translate }}"><div>{{ calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedLeaveCount }}</div></div>
                                </div>
                                <div class="label_box fl c_appointment" title="Appointment" style="display: block;"
                                    *ngIf="calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingAppointmentCount > 0 ||
                                            calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedAppointmentCount">
                                    <div></div>
                                    <div title="{{ 'calendar.pending' | translate }} {{ 'calendar.appointment' | translate }}{{ 'calendar.personal2' | translate }}"><div>{{ calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].PendingAppointmentCount }}</div></div>
                                    <div title="{{ 'calendar.approved' | translate }} {{ 'calendar.appointment' | translate }}{{ 'calendar.personal2' | translate }}"><div>{{ calendarScope.selfCalendarData.ECalendarDateList[stringToInt(day.date | calendarDate:'monthViewDayNumber':locale) - 1].ApprovedAppointmentCount }}</div></div>
                                </div>
        
                                <div style="clear:both;"></div>
                            </div>
                        </li>
                    </div>
                    <div *ngIf="!day.inMonth">
                        <li style="visibility:hidden"><div>{{ day.date | calendarDate:'monthViewDayNumber':locale }}</div></li>
                    </div>
                </div>
            </ul>
        </ng-template>
        

        <!-- <mwl-calendar events="events" view="calendarView" view-date="viewDate" template-scope="calendarScope"/> -->
        
        <div style="clear:both"></div>
    </div>


    <div class="list_result_area content_box" id="listResult">
        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_calendar"></div>
            <div class="fl">{{ 'menu.calendar' | translate }}</div>
            <div style="clear:both;"></div>
        </div>

        <div class="list_bar">
            <div class="btn_small_cross_back" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_arrow_back.png" width="12" (click)="hideSearchResult()"></div>
            <div class="header_bar_icon fl list_icon_border mt0 ml0 btn_filter_inquiry" title="{{ 'leaveapplicationinquery.backtofiltertochangeinquiry' | translate }}" (click)="hideSearchResult()"><img alt="" src="assets/img/btn_filter.png" width="20" /></div>
            <div id="p4" (click)="showSortsMenuToggle()" class="header_bar_icon fl list_icon_border mt0" title="Sorts"><img alt="" src="assets/img/btn_sort.png" width="20" /></div>
            <div id="p4_content" class="alert_box_list" *ngIf="showSortsMenu" style="display: block;">
                <div class="alert_box_arrow arrow_position_4"></div>
                <div class="alert_box_content txt_grey_2">
                    <div class="alert_box_content_title txt_size_3">{{ 'approval.sorts' | translate }}</div>
                    <div class="alert_box_content_item_m">
                        <a (click)="sortByType('StaffInfo.FullName')"><div class="alert_box_content_item">{{ 'approval.sortbyname' | translate }}</div></a>
                        <a (click)="sortByType('StaffInfo.PositionCode')"><div class="alert_box_content_item">{{ 'approval.sortbyposition' | translate }}</div></a>
                        <a (click)="sortByType('StartDate')"><div class="alert_box_content_item">{{ 'approval.sortbydate' | translate }}</div></a>
                        <a (click)="sortByType('LeaveTypeDesc')"><div class="alert_box_content_item">{{ 'approval.sortbytype' | translate }}</div></a>
                    </div>
                </div>
            </div>


            <input id="myInp" type="text" name="search" placeholder="{{ 'search' | translate }}" size="15" maxlength="15" [(ngModel)]="search.keywords" (ngModelChange)='searchByInput()'><button class="btn_search btn_opacity" title="Search" type="submit"></button>


            <div style="clear:both;"></div>

        </div><!-- end list_bar -->

        <ul id="filter_options">
            <li (click)="showSearchResult('')" [ngClass]="{'active' : search.SourceType == ''}">
                <a class="f_all">{{ 'all' | translate }}</a> <!--{{ sourcetype.cssClass }} -->
                <div class="filter_icon_num">{{leaveListCount + leaveCancelListCount + appointmentListCount + claimListCount}}</div>
            </li>
            <ng-container *ngFor="let sourceType of sourceTypeList">
                <li [ngClass]="{'active' : search.SourceType == sourceType.sourceTypeCode}"
                id="filter_item_{{sourceType.sourceTypeCode}}"
                (click)="showSearchResult(sourceType.sourceTypeCode)"
                *ngIf="sourceType.sourceTypeCode == 'ELEAVE' || sourceType.sourceTypeCode == 'APPOINTMENT'">
                    <a class="{{sourceType.cssClass}}">{{ sourceType.displayName | translate }}</a>
                    <div *ngIf="sourceType.sourceTypeCode == 'ELEAVE'" class="filter_icon_num">{{leaveListCount}}</div>
                    <div *ngIf="sourceType.sourceTypeCode == 'LEAVECAN'" class="filter_icon_num">{{leaveCancelListCount}}</div>
                    <div *ngIf="sourceType.sourceTypeCode == 'APPOINTMENT'" class="filter_icon_num">{{appointmentListCount}}</div>
               
                </li>
            </ng-container>
        </ul>

        <div style="clear:both;"></div>
        <hr class="m0">
        <div class="list_bar">
            <!-- ============ list_data ============ -->
            <div id="filter_holder" class="list_data page_calendar" data-role="page">

                <!-- ============  list1box  ============ -->
                <!-- <div class="list_1_box list_swipe_flag f_leave" *ngIf="swipeMode" ng-swipe-right="multiModeToggle()"> -->
                <div *ngFor="let obj of objList; let i = index">
                    <div *ngIf="obj.SourceType == search.SourceType || search.SourceType == '' " attr.listitemsourcetype="{{obj.SourceType}}" id="pending_record_{{i}}" class="list_1_box list_swipe_flag" (click)="showPopupLeaveDetail(i)" (swipeLeft)="swipeModeEnable(obj.index)" (swipeRight)="swipeModeDisable()">


                        <div class="swipe_mode" *ngIf="swipeMode && obj.index == selectedIndex" style="display: block; opacity: 1; right: 0px;">
                            <div class="swipe_txt_hide">{{ 'approval.swiperighttohide' | translate }}</div>
                            <div class="pop_functions_two_btn_center">
                                <button id="btn_SwipeApprove" class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish" click="doApprove(obj, '')"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                                <button id="btn_SwipeReject" class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" click="doReject(obj, '')"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                                <div style="clear:both"></div>
                            </div>
                        </div>

                        <div style="clear:both;"></div>

                        <div>
                            <div class="txt_photo_area_medium" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(obj.StaffInfo) + ')'}">
                                <div class="txt_photo_mask_small p_{{obj.StatusDesc | lowercase}}"></div>
                            </div>

                            <div class="mult_select_checkbox" *ngIf="multiMode" style="display: block;">
                                <input [(ngModel)]="obj.checked" type="checkbox" disabled><label for="select1"><span></span></label>
                            </div>

                            <div *ngIf="obj.SourceType == 'ELEAVE' || obj.SourceType == 'LEAVECAN'" class="txt_content_area_2line txt_size_2">
                                <div class="txt_name txt_name_spaceforlabel">{{ obj.StaffInfo.FullName }}</div>
                                <div *ngIf="obj.SourceType == 'ELEAVE'" class="txt_label_catalog txt_size_3">{{ 'approval.leave' | translate }}</div>
                                <div *ngIf="obj.SourceType == 'LEAVECAN'" class="txt_label_catalog txt_size_3">{{ 'approval.cancellation' | translate }}</div>

                                <div style="clear:both;"></div>
                                <!--<div class="txt_jobposition txt_grey_2 txt_size_3">{{ obj.StaffInfo.PositionDesc }}</div>-->
                                <div class="txt_date txt_grey_1">
                                    {{commonUI.formatDisplayDate(obj.StartDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.StartDate, 'ddd')}})</span> - {{commonUI.formatDisplayDate(obj.EndDate, 'DD MMM YYYY')}} <span class="txt_size_3">({{commonUI.formatDisplayDate(obj.EndDate, 'ddd')}})</span>
                                </div>
                                <div class="txt_days"><span class="txt_grey_2 txt_size_3">{{ 'approval.numofday' | translate }}&nbsp;</span>{{ obj.NumOfLeaveDay }}</div>
                                <div class="txt_type"><span class="txt_grey_2 txt_size_3">{{ 'approval.type' | translate }}&nbsp;</span><span>{{ obj.LeaveTypeDesc }}</span></div>
                                <div class="txt_status"><span class="txt_grey_2 txt_size_3">{{ 'calendar.status' | translate }}&nbsp;</span>{{obj.StatusDesc | lowercase | translate}}</div>
                            </div>

                            <div *ngIf="obj.SourceType == 'APPOINTMENT'" class="txt_content_area_2line txt_size_2">
                                <div class="txt_name txt_name_spaceforlabel">{{ obj.StaffInfo.FullName }}</div>
                                <div class="txt_label_catalog txt_size_3">{{ 'approval.appointment' | translate }}</div>

                                <div style="clear:both;"></div>
                                <!--<div class="txt_jobposition txt_jobposition_approval txt_grey_2 txt_size_3">{{ obj.StaffInfo.PositionDesc }}</div>-->
                                <div class="txt_add_time_area">
                                    <div class="txt_date txt_add_time txt_grey_1">{{commonUI.formatDisplayDate(obj.StartDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(obj.StartDate, 'ddd')}})</span>
                                    {{commonUI.formatDisplayDate(obj.StartDate, ' h:mm a')}}</div>
                                    <div class="txt_add_time_hypen"></div>
                                    <div class="txt_date txt_add_time txt_grey_1">{{commonUI.formatDisplayDate(obj.EndDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(obj.EndDate, 'ddd')}})</span>
                                    {{commonUI.formatDisplayDate(obj.EndDate, ' h:mm a')}}</div>
                                    <div style="clear:both;"></div>
                                </div>
                                <div class="txt_status"><span class="txt_grey_2 txt_size_3">{{ 'calendar.status' | translate }}&nbsp;</span>{{ obj.StatusDesc | lowercase | translate}}</div>
                            </div>

                            <div style="clear:both;"></div>
                        </div>
                    </div>

                </div>

                <div class="f_all f_pending f_cancelpending f_approved f_rejected" style="clear:both;"></div>
            </div><!-- ============ end list_data ============ -->
        </div>
    </div>

<!-- <leave-detail-popup popupmode="leavePopMode"
                    popupcontrol="leavePopupControl"
                    popupid="popup_leave_details"
                    withdraw-confirm-popupid="withdrawConfirmPopUp"
                    cancel-confirm-popupid="cancelConfirmPopup"
                    approve-remark-popupid="approveRemarkPopup"
                    reject-remark-popupid="rejectRemarkPopup"
                    leave-balance-popupid="leaveBalancePopup"
                    cancel-remark-popupid="cancelRemarkPopup"
                    approvereason="reason"
                    rejectreason="reason"
                    cancelreason="cancelReason"
                    approver-remark-list="approverRemarkList"
                    withdraw-leave-event=""
                    cancel-leave-event=""
                    approve-leave-event=""
                    reject-leave-event=""
                    staff-info="staffInfo">
</leave-detail-popup>

<popup-appoindetailtment- popupmode="appointmentPopMode"
                          popupcontrol="appointmentPopupControl"
                          popupid="appointPopUp"
                          approver-remark-list="approverRemarkList"
                          staff-info="staffInfo">
</popup-appoindetailtment-> -->
</div>