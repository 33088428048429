import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { InterceptorService } from './interceptor.service';
import { environment } from '../../environments/environment'
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService) { }

  cancelLeaveApplication(employee, leaveApplication, cancelReason) {
    var body = {
      "Action": environment.apiList.CancelLeaveApplication,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "LeaveRID": leaveApplication.RID,
      "StartDate": leaveApplication.StartDate,
      "CancelReason": cancelReason
    };
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.CancelLeaveApplication,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveRID": leaveApplication.RID,
    //         "StartDate": leaveApplication.StartDate,
    //         "CancelReason": cancelReason
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }
  withdrawLeaveApplication(employee, leaveApplication) {
    var body = {
      "Action": environment.apiList.WithdrawLeaveApplication,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "LeaveRID": leaveApplication.RID,
      "StartDate": leaveApplication.StartDate
    };
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // this.commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": environment.apiList.WithdrawLeaveApplication,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveRID": leaveApplication.RID,
    //         "StartDate": leaveApplication.StartDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  calSelfLeaveBalance(employee, leaveTypeCode, asAtDate) {
    var body = {
      "Action": environment.apiList.CalSelfLeaveBalance,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "LeaveTypeCode": leaveTypeCode,
      "AsAtDate": asAtDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.LeaveBalance);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.CalSelfLeaveBalance,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "AsAtDate": asAtDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  calTeamLeaveBalance(employee, leaveTypeCode, leaveYear) {
    var body = {
      "Action": environment.apiList.CalTeamLeaveBalance,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "LeaveTypeCode": leaveTypeCode,
      "AsAtDate": leaveYear
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.LeaveBalance);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.CalTeamLeaveBalance,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "AsAtDate": leaveYear
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  calDelegationLeaveBalance(employee, leaveTypeCode, leaveYear) {
    var body = {
      "Action": environment.apiList.CalDelegationLeaveBalance,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "LeaveTypeCode": leaveTypeCode,
      "AsAtDate": leaveYear
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.LeaveBalance);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.CalDelegationLeaveBalance,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "AsAtDate": leaveYear
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getDelegationLeaveApplicationList(employee, startDate, endDate, leaveTypeCode, status) {
    var body = {
      "Action": environment.apiList.GetDelegationLeaveApplicationList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "LeaveTypeCode": leaveTypeCode,
      "Status": status
    }
    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;
    // if (status == "All") status = "";

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.LeaveApplicationList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetDelegationLeaveApplicationList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "StartDate": startDate,
    //         "EndDate": endDate,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "Status": status
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getLeaveTypeList(employee?) {

    if (!employee)
      employee = ''

    var body = {
      "Action": environment.apiList.GetLeaveTypeList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      sessionStorage.LeaveTypeList = JSON.stringify(res.body['Data'].LeaveTypeList)
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;
    // if (sessionStorage.LeaveTypeList != undefined) {
    //     onSuccessCallback(service, JSON.parse(sessionStorage.LeaveTypeList));
    //     return;
    // }

    //   var actionSuccess = function (data) {
    //       if (onSuccessCallback != undefined) {
    //           // sessionStorage.LeaveTypeList = JSON.stringify(data.Data.LeaveTypeList);
    //           onSuccessCallback(service, data.Data.LeaveTypeList);
    //       }
    //   }

    //   var actionFail = function (data, status, headers, config) {
    //       if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //       return;
    //   }

    //   var postData = {
    //       "Action": apiList.GetLeaveTypeList,
    //       "SessionID": sessionStorage.SessionID
    //   }

    //   if (employee)
    //       postData.EmploymentRID = employee.RID

    //   commonService.JSONPostService(
    //       JSON.stringify(postData)
    //   ).then(
    //       function (response) {
    //           actionSuccess(response.data);
    //       }
    //       ,
    //       function (response) {
    //           actionFail(response.data, response.status, response.headers, response.config);
    //       }
    //   );
  }

  calNumOfLeaveDays(leaveApplication) {
    var body = {
      "Action": environment.apiList.CalNumOfLeaveDays,
      "SessionID": sessionStorage.SessionID,
      "LeaveApplicationForm": JSON.stringify(leaveApplication)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.NumOfLeaveDays);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.CalNumOfLeaveDays,
    //         "SessionID": sessionStorage.SessionID,
    //         "LeaveApplicationForm": JSON.stringify(leaveApplication)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  submitLeaveApplication(leaveApplication) {
    var body = {
      "Action": environment.apiList.SubmitLeaveApplication,
      "SessionID": sessionStorage.SessionID,
      "LeaveApplicationForm": JSON.stringify(leaveApplication)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitLeaveApplication,
    //         "SessionID": sessionStorage.SessionID,
    //         "LeaveApplicationForm": JSON.stringify(leaveApplication)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getTakingUnitList(employee, leaveTypeCode, asAtDate) {
    var body = {
      "Action": environment.apiList.GetTakingUnits,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "LeaveTypeCode": leaveTypeCode,
      "AsAtDate": asAtDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;
    // if (sessionStorage.TakingUnitList != undefined) {
    //     onSuccessCallback(service, JSON.parse(sessionStorage.TakingUnitList));
    //     return;
    // }

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         sessionStorage.TakingUnitList = JSON.stringify(data.Data.TakingUnitList);
    //         onSuccessCallback(service, data.Data.TakingUnitList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetTakingUnits,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "AsAtDate": asAtDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getLeaveCutOffDate(employee, leaveTypeCode, asAtDate) {
    var body = {
      "Action": environment.apiList.GetLeaveCutOffDate,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "LeaveTypeCode": leaveTypeCode,
      "AsAtDate": asAtDate
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.CutOffDate);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetLeaveCutOffDate,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "AsAtDate": asAtDate
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getSelfLeaveApplicationList(employee, startDate, endDate, leaveTypeCode, status) {

    var body = {
      "Action": environment.apiList.GetSelfLeaveApplicationList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "LeaveTypeCode": leaveTypeCode,
      "Status": status
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var service = this;
    // if (status == "All") status = "";

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.LeaveApplicationList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetSelfLeaveApplicationList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "StartDate": startDate,
    //         "EndDate": endDate,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "Status": status
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getTeamLeaveApplicationList(employee, startDate, endDate, leaveTypeCode, status) {

    var body = {
      "Action": environment.apiList.GetTeammateLeaveApplicationList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "LeaveTypeCode": leaveTypeCode,
      "Status": status
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result
    // var service = this;
    // if (status == "All") status = "";

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service, data.Data.LeaveApplicationList);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.GetTeammateLeaveApplicationList,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "StartDate": startDate,
    //         "EndDate": endDate,
    //         "LeaveTypeCode": leaveTypeCode,
    //         "Status": status
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }
}
