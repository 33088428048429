<div id="pop_punchdetail" class="list_swipe_flag-selected">
    <div class='pop_functions_box pop_functions_box_size_2column'>
        <div class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image':'url(' + commonUI.getStaffImage(selectedEEmployPunchDetail.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
                <!-- <span class="txt_popup_staffname">{{ selectedEEmployPunchDetail.StaffInfo.FullName }}</span><br>
                <span class="txt_popup_position">{{ selectedEEmployPunchDetail.StaffInfo.PositionDesc }}</span><br>
                <span class="txt_popup_staffid">ID : {{ selectedEEmployPunchDetail.StaffInfo.StaffID }}</span><br> -->
                <span class="txt_size_1">{{ selectedEEmployPunchDetail.StaffInfo.FullName }}</span><br>
                <span class="txt_opacity_1">{{ selectedEEmployPunchDetail.StaffInfo.PositionDesc }}</span><br>
                <span class="txt_size_3 txt_opacity_1 txt_popup_staffid">ID&nbsp;:&nbsp;{{ selectedEEmployPunchDetail.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

            <div class="detail_box_bottom_1point thin">
                <div class="detail_box_catalog_area"></div>
                <!-- <div class="detail_box_session1 txt_grey_2 txt_size_3"></div> -->
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(selectedEEmployPunchDetail.PunchDate, 'DD MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(selectedEEmployPunchDetail.PunchDate, 'ddd')}})</span></div>
                <div class="detail_box_sub_icon d_box_sub_icon_appointment"></div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'punchinquiry.shift' | translate }}</div>
                <div class="detail_box_date_num maincolor_txt"><span class="txt_size_0">{{ selectedEEmployPunchDetail.ShiftDetail.Desc }}</span></div>
                <div class="detail_box_type">
                        {{commonUI.formatDisplayDate(selectedEEmployPunchDetail.ShiftDetail.ShiftInTime, 'h:mm A')}}
                        &nbsp;-&nbsp;
                        {{commonUI.formatDisplayDate(selectedEEmployPunchDetail.ShiftDetail.ShiftOutTime, 'h:mm A')}}
                    </div>
            </div>

            <div class="detail_box_other txt_size_1">
                <div class="txt_grey_2 txt_size_3">{{ 'punchinquiry.currentpunch' | translate }}</div>
                <div class="col col2 adjusted_punch_detail_left" style="border-top: 1px solid #e6e6e6;margin: 0;">
                    <div style="display:none">
                        <div>Time</div>
                        <div>Detail</div>
                        <div style="clear:both; float:none"></div>
                    </div>

                    <div *ngFor="let staffPunch of selectedEEmployPunchDetail.StaffPunchList">
                        <div>
                            {{ commonUI.formatDisplayDate(staffPunch.PunchDateTime, 'h:mm A') }}
                            <br>
                            <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(staffPunch.PunchDateTime, 'DD MMM YYYY (ddd)') }}</span>
                        </div>
                        <div>
                            <span class="txt_label bc_green txt_size_3" *ngIf="staffPunch.Status == 'O'">{{ 'punchinquiry.outtime' | translate }}</span>
                            <span class="txt_label bc_blue txt_size_3" *ngIf="staffPunch.Status == 'I'">{{ 'punchinquiry.intime' | translate }}</span>
                            <br>
                            <span class="txt_size_3 txt_grey_2">{{ 'punchinquiry.site' | translate }}</span>
                            {{ staffPunch.PunchLocation }}
                        </div>
                        <div style="clear:both; float:none"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail_box_bottom_area mt0_m">
            <div class="txt_grey_2 txt_size_3">{{ 'punchinquiry.rawpunch' | translate }}</div>
            <div class="col col2 adjusted_punch_detail_left" style="border-top: 1px solid #e6e6e6;margin: 0;">
                <div style="display:none">
                    <div>Time</div>
                    <div>Detail</div>
                    <div style="clear:both; float:none"></div>
                </div>
                <div *ngFor="let rawPunch of selectedEEmployPunchDetail.RawPunchList; let i = index;">
                    <div>
                        {{ commonUI.formatDisplayDate(rawPunch.PunchDateTime, 'h:mm A') }}
                        <br>
                        <span class="txt_size_3 txt_grey_2">{{ commonUI.formatDisplayDate(rawPunch.PunchDateTime, 'DD MMM YYYY (ddd)') }}</span>
                    </div>
                    <div>
                        <span class="txt_label bc_green txt_size_3" *ngIf="rawPunch.Status == 'O'">{{ 'punchinquiry.outtime' | translate }}</span>
                        <span class="txt_label bc_blue txt_size_3" *ngIf="rawPunch.Status == 'I'">{{ 'punchinquiry.intime' | translate }}</span>
                        <br>
                        <span class="txt_size_3 txt_grey_2">{{ 'punchinquiry.site' | translate }}</span>
                        {{ rawPunch.PunchLocation }}
                    </div>
                    <div style="clear:both; float:none"></div>
                </div>
            </div>
        </div>
        <div style="clear:both"></div>
    </div>
</div>