import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonUIService } from '../services-UI/common-ui.service';
import moment from 'moment'
import { CommonServiceService } from '../services/common-service.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupLeaveDetailComponent } from '../common/popup-leave-detail/popup-leave-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { IdleTimerService } from '../services/idle-timer.service';

declare var $: any;

@Component({
  selector: 'app-calendar-staff-leave',
  templateUrl: './calendar-staff-leave.component.html',
  styleUrls: ['./calendar-staff-leave.component.scss']
})
export class CalendarStaffLeaveComponent implements OnInit {

  employee = JSON.parse(sessionStorage.Employee);
  selected_Staff = [{
    StaffID: '',
    FullName: ''
  }]
  StaffModule = [];
  selectedLeaveApplication;
  daysList;
  viewMonth;
  viewMonthList = [];
  defaultViewMonth;
  calendarScope = {
    calendarData: {
      StaffInfoList: [],
      StaffsViewDict: []
    }
  };
  multipleSelection = {
    selectedStaff: [],
    addedStaff: []
  };
  calendarStaffListSettings = {};
  calendarStaffList = [];
  wholeCalendarStaffList = [];
  cancelReason = "";
  reason = "";
  remarkPrefixApproved = "[Approved] ";
  remarkPrefixRejected = "[Rejected] ";

  leavePopupControl = {};
  leaveMultiTypesPopupControl = {};
  approverRemarkList = {};
  isLeaveApv = false;
  leavePopMode = "READONLY";
  
  minMonth;
  maxMonth;

  language = localStorage.CurrentLanguage !== undefined ? localStorage.CurrentLanguage : "en";
  locale: string = 'en'

  dropdownSettings = {};

  constructor(public idleTimer: IdleTimerService, public commonUI: CommonUIService, public commonService: CommonServiceService, public matDialog: MatDialog, public translate: TranslateService) { 
    this.translate.onLangChange.subscribe((lang) => {
      this.language = lang.lang
      this.locale = localStorage.CurrentLanguage == 'en' ? moment.locale('en') : moment.locale('zh-hk')
    })
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
    // this.$parent.hideMenu();
    this.getLists();

    var startMonth = moment(this.viewMonth).add(-12, 'months').toDate();
    this.minMonth = startMonth;
    for (var i = 0; i < 24; i++) {
      this.viewMonthList.push(moment(startMonth).add(i, 'months').toDate());
    }
    this.maxMonth = this.viewMonthList[this.viewMonthList.length - 2];

    this.defaultViewMonth = this.viewMonthList[12];
    this.viewMonth = this.defaultViewMonth;

    this.getModule();
    this.refreshCalendar();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'StaffID',
      textField: 'FullName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };
  }

  showSearchResult() {
    var filterList = []

    for(var i=0; i<this.multipleSelection.selectedStaff.length; i++) {
      for(var j=0; j<this.calendarStaffList.length; j++) {
        if(this.calendarStaffList[j].StaffID == this.multipleSelection.selectedStaff[i].StaffID) {
          filterList.push(this.multipleSelection.selectedStaff[i])
        }
      }
    }
    this.calendarStaffList = filterList
    this.refreshCalendar();
  }

  resetSearchResult() {
    this.viewMonth = this.defaultViewMonth;
    this.multipleSelection.selectedStaff = [];
    this.multipleSelection.addedStaff = [];
  }

  refreshCalendar() {

    this.viewMonth = new Date(this.viewMonth)

    this.refreshDaysInMonth();

    var month = moment(this.viewMonth).get("month") + 1;
    var year = moment(this.viewMonth).get("year");
    var staffList = [];
    for (var i = 0; i < this.multipleSelection.selectedStaff.length; i++) {
      staffList.push(this.multipleSelection.selectedStaff[i].EmploymentRID);
    }

    this.getAllStaffsViewCalendar(year, month, staffList);
  }

  refreshDaysInMonth() {
    this.daysList = [];
    var numOfDays = this.daysInThisMonth(this.viewMonth.getFullYear(), this.viewMonth.getMonth() + 1);

    for (var i = 1; i <= numOfDays; i++) {
      var daysName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      var date = new Date(this.viewMonth.getFullYear(), this.viewMonth.getMonth(), i);

      var dateObj = { day: i, desc: daysName[date.getDay()], isSunday: "N", isHoliday: "N" };

      if (date.getDay() == 0)
        dateObj.isSunday = "Y";
      // if (sessionStorage.HolidayDateList && sessionStorage.HolidayDateList.indexOf(this.commonUI.formatJsonDate(date, "DD-MM-YYYY")) > 0)
      //   dateObj.isHoliday = "Y";

      this.daysList.push(dateObj);
    }
  }

  daysInThisMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  goToPrevMonth() {
    // this.hideSearchResult();
    this.viewMonth = moment(this.viewMonth).add(-1, 'months').toDate();
    this.refreshCalendar();
  }

  goToNextMonth() {
    // this.hideSearchResult();
    this.viewMonth = moment(this.viewMonth).add(1, 'months').toDate();
    this.refreshCalendar();
  }

  showPopupLeave(leaveApplicationList) {
    if (leaveApplicationList.length > 1) {
        this.showPopupLeaveMultiTypesDetail(leaveApplicationList);
    } else if (leaveApplicationList.length == 1) {
        this.showPopupLeaveDetail(leaveApplicationList[0]);
    }
  }

  showPopupLeaveMultiTypesDetail(leaveApplicationList) {
    // this.leaveMultiTypesPopupControl.showPopup(leaveApplicationList);
  }

  // showSelectedLeaveRecord() {
  //   this.showPopupLeaveDetail(this.leaveMultiTypesPopupControl.selectedLeaveApplication);
  // }

  showPopupLeaveDetail(leaveApplication) {

    this.selectedLeaveApplication = leaveApplication;
    this.commonUI.addLoadingTask();
    var rid = this.selectedLeaveApplication.RID;
    this.commonService.getApproverRemarkList(rid, this.selectedLeaveApplication.SourceType, this.selectedLeaveApplication.StartDate).subscribe((result) => {
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.approverRemarkList = this.commonService.getApproverRemarkTranslateList(result.body['Data'].ApproverRemarkList);
          // var isLeaveCancelApp = $.inArray("LeaveCancelApp", this.StaffModule) >= 0;
          this.leavePopMode = "READONLY";
          if (this.selectedLeaveApplication.IsCancellable) this.leavePopMode = "CANCEL"; //isLeaveCancelApp &&
          if (this.selectedLeaveApplication.IsWithdrawable) this.leavePopMode = "WITHDRAW";
          if (this.selectedLeaveApplication.IsApprovable) this.leavePopMode = "APPROVAL";
          const dialog = this.matDialog.open(PopupLeaveDetailComponent, {
              data: {
                app: leaveApplication,
                approver_remark_list: this.approverRemarkList,
                popmode: this.leavePopMode
              }
            })
          
          dialog.afterClosed().subscribe(() => {
            this.refreshCalendar()
          })
          this.commonUI.finishLoadingTask()
        }
        else {
          this.commonUI.finishLoadingTask()
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      else {
        this.commonUI.finishLoadingTask()
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    })
}

  showSearchFilter() {
    this.getLists()
    $("html,body").animate({ scrollTop: $("a[name = 'list_result']").offset().top }, 1000);

    $(".list_result_area").animate({ opacity: 0, left: "-300px" }, 800).fadeOut();
    $("#inquiry_box").fadeIn().animate({ opacity: 1, top: "0" }, 800);
    $(".btn_reset_roster ul#filter_options").animate({ opacity: 0, bottom: "-76px" }, 500).fadeOut();

    $("html,body").animate({ scrollTop: 0 }, 800);
  }

  showCalendarView() {
    $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 800);
    $("#inquiry_box").animate({ opacity: 0, top: "-300px" }, 800).fadeOut();
    $(".btn_reset_roster ul#filter_options").fadeIn().animate({ opacity: 1, bottom: "0" }, 500);

    $("html,body").animate({ scrollTop: $("a[name = 'list_result']").offset().top }, 1300);

    $('#roster_list_title_bar-month_roster').animate({ top: "0", opacity: "1" });
    $('#roster_list_title_bar-commission').animate({ top: "0", opacity: "1" });

    $('#filter_options li').removeClass('active');
    $('#filter_options li:first-child()').addClass('active');
    $('#filter_holder > div').css('display', 'none');
    $('.f_month_roster').fadeIn();

    $("html,body").animate({ scrollTop: $("a[name = 'back_top_page']").offset().top }, 800);
}

  getLists() {
    this.getCalendarStaffList();
}

  getCalendarStaffList() {
      this.commonUI.addLoadingTask();
      this.commonService.getCalendarStaffList(this.employee).subscribe((result) => 
      {
        
        if(!result['conn_fail'])
          if(result.body['ErrorMsg'] == null) {
            this.calendarStaffList = [];
            if (result.body['Data'].StaffInfoList != null) {
                for (var i = 0; i < result.body['Data'].StaffInfoList.length; i++) {
                    this.calendarStaffList.push(result.body['Data'].StaffInfoList[i]);
                }
                this.wholeCalendarStaffList = result.body['Data'].StaffInfoList
            }
            this.commonUI.finishLoadingTask();
          }
          else
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        else 
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      },err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  getModule() {
    this.commonUI.addLoadingTask();
    this.commonService.getModule(this.employee).subscribe((result) =>
    {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.StaffModule = result.body['Data'].Module;
          // this.isLeaveApv = $.inArray("LeaveApv", this.StaffModule) >= 0;
          this.isLeaveApv = this.StaffModule.find(o => o == 'LeaveApv')
          this.commonUI.finishLoadingTask();
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getAllStaffsViewCalendar(year, month, staffList) {
    this.commonUI.addLoadingTask();
    this.commonService.getAllStaffsViewCalendar(this.employee, year, month, staffList).subscribe((result) => 
    {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.calendarScope.calendarData = result.body['Data'].Calendar;
          this.commonUI.finishLoadingTask();
          this.showCalendarView()
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  onItemSelect(item: any) {
    this.multipleSelection.selectedStaff = this.wholeCalendarStaffList.filter((s) => s.StaffID === item.StaffID)
  }
  onSelectAll(items: any) {
    this.wholeCalendarStaffList.map((s) => this.multipleSelection.selectedStaff.push(s))
  }

}
