import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonHandlerService } from 'src/app/services/common-handler.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-popup-leave-preview',
  templateUrl: './popup-leave-preview.component.html',
  styleUrls: ['./popup-leave-preview.component.scss']
})
export class PopupLeavePreviewComponent implements OnInit {

  startDate;
  endDate;
  leaveApplication;
  staffInfo;
  leaveDays;
  attachments;
  startDateTakingUnit;
  endDateTakingUnit;

  constructor(public commonUI: CommonUIService, public matDiglogRef: MatDialogRef<PopupLeavePreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public translate: TranslateService, public commonService: CommonServiceService, public matDialog: MatDialog, public commonHandler: CommonHandlerService, public leaveService: LeaveService) {
    this.startDate = data['startDate']
    this.endDate = data['endDate']
    this.leaveApplication = data['leaveApplication']
    this.staffInfo = data['staffInfo']
    this.leaveDays = data['leaveDays']
    this.attachments = data['attachments']
    this.startDateTakingUnit = data['startDateTakingUnit']
    this.endDateTakingUnit = data['endDateTakingUnit']
   }

  ngOnInit(): void {
  }

  submitLeaveApplication() {
    this.setLeaveApplicationRec();

    this.commonUI.addLoadingTask();
    this.leaveService.submitLeaveApplication(this.leaveApplication).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        // $("#popup_LeavePreview").fadeOut();
        this.commonUI.finishLoadingTask();
        this.close(true)
        this.commonUI.showMsgBox("message.M0001");
      }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  close(data?) {
    try {
      this.matDiglogRef.close(data)
    } catch {}
  }

  setLeaveApplicationRec() {
    this.leaveApplication.StartDate = this.commonUI.formatJsonDate(this.startDate, "YYYY-MM-DDT00:00:00");
    this.leaveApplication.EndDate = this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00");

    var fileseq = 0;
    for (var i = 0; i < this.attachments.length; i++) {
      if (!this.attachments[i].deleted) {
        if (fileseq == 0) {
          this.leaveApplication.AttachmentName1 = this.attachments[i].file.name;
          this.leaveApplication.AttachmentAddr1 = this.attachments[i].uploadFilename;
        }
        if (fileseq == 1) {
          this.leaveApplication.AttachmentName2 = this.attachments[i].file.name;
          this.leaveApplication.AttachmentAddr2 = this.attachments[i].uploadFilename;
        }
        if (fileseq == 2) {
          this.leaveApplication.AttachmentName3 = this.attachments[i].file.name;
          this.leaveApplication.AttachmentAddr3 = this.attachments[i].uploadFilename;
        }
        fileseq++;
      }
    }

    this.leaveApplication.IsEndDateAllDay = "";
    this.leaveApplication.IsStartDateAllDay = "";
    if (this.leaveApplication.EndDateTakingUnit == "") this.leaveApplication.IsEndDateAllDay = "Y";
    if (this.leaveApplication.StartDateTakingUnit == "") this.leaveApplication.IsStartDateAllDay = "Y";

    if (this.leaveApplication.StartDate == this.leaveApplication.EndDate) {
      this.leaveApplication.EndDateTakingUnit = this.leaveApplication.StartDateTakingUnit;
      this.leaveApplication.IsEndDateAllDay = "";
      if (this.leaveApplication.StartDateTakingUnit == "") {
        this.leaveApplication.IsStartDateAllDay = "Y";
      }
    }
  }

}