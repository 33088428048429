import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-password-policy',
  templateUrl: './popup-password-policy.component.html',
  styleUrls: ['./popup-password-policy.component.scss']
})
export class PopupPasswordPolicyComponent implements OnInit {

  passwordPolicy: { 
    MinPwdLen: number, 
    RequireChangePassword: boolean, 
    IsNewAccount: boolean, 
    IsAfterReset: boolean, 
    PasswordExpiryDays: number, 
    ExcludeAccountName: boolean, 
    ExcludeSpace: boolean, 
    IncludeLowercase: boolean, 
    IncludeUppercase: boolean, 
    IncludeDigit: boolean, 
    IncludeSymbol: boolean 
  }
  translationData: { numOfChars: number }

  constructor(public matDialogRef: MatDialogRef<PopupPasswordPolicyComponent>, @Inject(MAT_DIALOG_DATA) public policy: {}) { 
    this.passwordPolicy = this.policy['pp']
    this.translationData = this.policy['td']
  }

  ngOnInit(): void {
  }
  
  close() {
    try {
      this.matDialogRef.close()
    } catch{}
  }

}
