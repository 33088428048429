import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupErrorDialogComponent } from '../common/popup-error-dialog/popup-error-dialog.component';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ErrorUIService {

  errorCode: string;
  errorMsg: string;
  isOpen: boolean

  constructor(public matDialog: MatDialog) { 
    this.errorCode = ''
    this.errorMsg = ''
    this.isOpen = false
  }

  showErrorBox(code) {
    this.errorCode = code;
    this.errorMsg = 'error.' + code;
    var dialog;
    if(!this.isOpen) {
      dialog = this.matDialog.open(PopupErrorDialogComponent, {
        data: {
          code: this.errorCode,
          msg: this.errorMsg
        }
      })
      this.isOpen = true
    }

    if(dialog != undefined) {
      dialog.afterClosed().subscribe(() => {
        this.isOpen = false
      })
    }
    // $('#popup_error_box').fadeIn();
  }

  showErrorBoxWithCallback = function (code, callback) {
    this.errorCode = code;
    this.errorMsg = 'error.' + code;
    $('#popup_error_box').fadeIn();
    this.onCloseErrorBoxWithCallback = callback;
  }

  hideErrorBox = function () {
    $('#popup_error_box').fadeOut();
    this.errorCode = '';
    this.errorMsg = '';
    this.onCloseErrorBoxWithCallback();
  }

  onCloseErrorBoxWithCallback = function () {
      return;
  }
}
