    <!--============ popup : job position select  ============ -->
    
    
    <div id="popup_menu_select_position" class="pp3_content">
        <div class="pop_functions_box pop_functions_box_size_company">
          <div id="btn_back_company" class="pop_functions_close btn_grey_2b" (click)="close()">x</div>
          <div style="clear: both"></div>
          <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_employment"></div>
            <div class="fl">{{ 'login.selectposition' | translate }}</div>
          </div>
      
          <!-- <span class="txt_grey_2">{{ 'login.selectposition' | translate }}</span> <br /> <br /> -->
      <!--
          <div class="box_grid_two btn_jobpsoition company_logo_center" title="aCube Solutions Limited" ng-if="selectedCompany != null">
            <div class="txt_photo_area_big" ng-attr-style="background-image: url(companylogo/{{selectedCompany.CompanyCode}}.png);">
              <div class="txt_photo_mask_big p_normal_big"></div>
            </div>
            <div style="clear: both;"></div>
            <div class="txt_box_label_name">{{selectedCompany.CompanyName}}</div>
          </div>
          <div style="clear: both;"></div>
          <br />
      -->
      
          <div class="jobposition_content bg_grey_4">
          <div *ngIf='selectedCompany'>
              <div *ngFor="let employee of selectedCompany.StaffList">
                  <button class="btn btn-block btn-lg maincolor_btn2 icon_arrow_r" (click)="selectEmployee(employee)">
                      <div class="btn_txt_icon_right">{{employee.StaffID}}</div>
                  </button>
              </div>
          </div>
          </div>
      
        </div>
      </div>