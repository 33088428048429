<div id="popup_error_box">
  <div class="pop_functions_box pop_functions_box_size_1">
    <br/>
    <img src="assets/img/msg_alert.png" width="48">
    <br/><br/>{{ errorCode }} : {{ errorMsg }}<br/><br/>
    <div class="pop_functions_two_btn_center">
      <a id="link_closePopup" class="btn btn-block btn-lg one_btn maincolor_btn2" (click)="close()">
      <img src="assets/img/btn_tick_w.png" width="18">{{ 'ok' | translate }}</a>
      <div style="clear: both"></div>
    </div>
  </div>
</div>