<!--============ popup : T&C  ============ -->
<div id="popup_t_and_c">
    <div class="pop_functions_box pop_functions_box_size_tc">
      <div class="pop_functions_close btn_grey_2b pp_close" (click)="closeModalTermsAndConditions()">x</div>
        <div style="clear:both"></div>
          <br/>
          <span class="txt_size_1 txt_grey_2">{{ 'toolbar.termsandconditions' | translate }}</span>
          <br/><br/>
          <div class="pop_functions_box_content_tc">
              <b class="maincolor_txt">General</b>
              <br/><br/>
              By using this Application ( as defined below ) from aCube Solutions Limited ( the “Company” ) , a subsidiary of FlexSystem Limited, you agree to be bound by these terms of use. Please read them carefully before using and/or acceptance. If you are agreeing to be bound by this Agreement on behalf of your employer or other entity, you are representing and warranting that you have full legal authority to bind your employer or such entity to this Agreement. If you do not have the necessary authority, you may not accept the Agreement.
              <br/><br/><br/>
  
              <b class="maincolor_txt">Definition</b>
              <br/><br/>
              The “Application” shall mean the software provided by the Company. You may use the Application for internal business purposes only.
              <br/><br/><br/>
  
              <b class="maincolor_txt">Intellectual Property</b>
              <br/><br/>
              The Company retains all ownership and intellectual property rights in the Application. You shall ensure that others do not (i) provide or make the Application available to any third party, (ii) decompile, disassemble or reverse engineer the Software, (iii) create derivative works based upon the Software or (iv) use any of the Company name, trademarks or logo, (v) provide any service based on the Service without prior written permission.
              <br/><br/><br/>
  
              <b class="maincolor_txt">Subscription to Trial Service</b>
              <br/><br/>
              The Company may offer free trials “Trial Service” for the purpose of testing and evaluation. You agree that we have the sole authority and discretion to determine the period of time for testing and evaluation of the “Trial Service”. The Company reserves the right to fully or partially discontinue, at any time or from time to time, temporarily or permanently, any of the “Trial Service” with or without notice to you. You agree that the “Company” will not be liable to you or to any third party for any harm related to, arising out of, or caused by the modification, suspension or discontinuance of any of the Trial Service for any reason.
              <br/><br/><br/>
  
              <b class="maincolor_txt">Personal Information and Privacy</b>
              <br/><br/>
              You are responsible for maintaining confidentiality of your username, password and other sensitive information. The “Company” is not responsible for any loss or damage to you or to any third party incurred through misuse or loss of devices.
              <br/><br/><br/>
  <!--
              <b class="maincolor_txt">Software Update</b>
              </br></br>If we upgrade, update or modify the “Application” (including bug fixes, patches and new versions ) by providing to you an “Update “, then you may be required to promptly install the “Update” in order for you to be able to continue to access and use the Application. The Company may provide you with prior notice of the “Update”. The “Company” is under no obligation to make Modifications. 
              </br></br><br/>
  
              <b class="maincolor_txt">Limitation of Liability</b>
              </br></br>Our liability for loss or damage of whatsoever nature and howsoever caused, whether in contract or tort including, but not limited to, negligence or breach of duty imposed by statute or under any other cause of action which is sustained or incurred by you or any third party shall be limited, in respect of each event or series of related events, as follows. If such loss or damage arises from the supply of the” Software”, then our liability shall not exceed the licence fee paid for the Software at or in respect of which such loss or damage is sustained or incurred and if such loss or damage arises from the supply of maintenance charges and/ or software subscriptions, then our liability shall not exceed the annual charge for the year in which and the location at or in respect of which such loss or damage is sustained or incurred.
              </br></br><br/>
  -->
              <span class="txt_size_3 txt_grey_2">©2019 aCube Solutions Limited. <br/>All Rights Reserved.</span>

      </div>
    </div>
  </div>
  