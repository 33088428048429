import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CommonServiceService } from '../../services/common-service.service';
import { CommonUIService } from '../../services-UI/common-ui.service';
import { AppointmentService } from '../../services/appointment.service';

declare var $: any;

@Component({
  selector: 'app-popup-appointmnet-confirm',
  templateUrl: './popup-appointmnet-confirm.component.html',
  styleUrls: ['./popup-appointmnet-confirm.component.scss']
})
export class PopupAppointmnetConfirmComponent implements OnInit {

  staffInfo;
  appointments;

  constructor(public commonService: CommonServiceService, public commonUI: CommonUIService, public matDialogRef: MatDialogRef<PopupAppointmnetConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: {}, public translate: TranslateService, public appointmentService: AppointmentService) { 
    this.staffInfo = data['staff'];
    this.appointments = data['appointment'];
  }

  ngOnInit(): void {
  }

  submitAppointmentApplication() {
    this.commonUI.addLoadingTask();
    var submitAppointments = [];
    this.appointments.forEach((appointment, key) => {
      if (appointment.deleted) return;

      var start = 0
      var end = 0

      if(appointment.AppointStartDayPart == 'PM' && Number(appointment.AppointStartHour) > 0 && Number(appointment.AppointStartHour) < 12) { 
        var start = parseInt(appointment.AppointStartHour); 
        start += 12 
      } else if(appointment.AppointStartDayPart == 'AM' && Number(appointment.AppointStartHour) == 12)
        start = 0
      else 
        start =  parseInt(appointment.AppointStartHour); 
      var startDateString = moment(appointment.AppointStartDate).format("YYYY-MM-DDT") +
        start.toString() + ":" + appointment.AppointStartMinute + ":00" +
        appointment.AppointStartDayPart;
      appointment.AppointStart = moment(startDateString, "YYYY-MM-DDThh:mm:ssA").format("YYYY-MM-DDTHH:mm:ss");

      if(appointment.AppointEndDayPart == 'PM' && Number(appointment.AppointEndHour) > 0 && Number(appointment.AppointEndHour) < 12) { 
        end = parseInt(appointment.AppointEndHour); 
        end += 12 
      }  else if(appointment.AppointEndDayPart == 'AM' && Number(appointment.AppointEndHour) == 12) {
        end = 0
      } else 
      end =  parseInt(appointment.AppointEndHour); 
      var endDateString = moment(appointment.AppointEndDate).format("YYYY-MM-DDT") +
        end.toString() + ":" + appointment.AppointEndMinute + ":00" +
        appointment.AppointEndDayPart;
      appointment.AppointEnd = moment(endDateString, "YYYY-MM-DDThh:mm:ssA").format("YYYY-MM-DDTHH:mm:ss");

      submitAppointments.push(appointment);
    })
    this.appointmentService.submitAppointmentApplication(submitAppointments).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.commonUI.finishLoadingTask();
        this.close({confirm: true});
        this.commonUI.showMsgBox("message.M0001");
      }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }
  
  close(data?) {
    try {
      this.matDialogRef.close(data)
    } catch { }
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}