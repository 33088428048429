import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupTermsComponent } from '../common/popup-terms/popup-terms.component'
import { CommonUIService } from '../services-UI/common-ui.service'
import { Router } from '@angular/router';
import { CommonServiceService } from '../services/common-service.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { IdleTimerService } from '../services/idle-timer.service';
import { PopupCompanySelectComponent } from '../common/popup-company-select/popup-company-select.component';
import { PopupMessageWithCallbackComponent } from '../common/popup-message-with-callback/popup-message-with-callback.component';
//import * as JSNetRSA_2 from '../../assets/js/nrsa/JSNetRSA.js';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  title = "Login";
  userCode = localStorage.UserCode;
  userImagePath = localStorage.ImagePath == undefined ? "assets/img/photo_sample_big.png" : localStorage.ImagePath;
  password = "";
  rememberme = localStorage.RememberMe == "Y" ? true : false;
  loginLoading = false;
  isLogin = true;
  isMutliEmploy = false;
  companyList;
  selectedCompany;
  loginInprogress = false;
  languageList = [];
  language = "";
  enableForgetPassword = environment.siteConfig.enableForgetPassword;
  
  isV11Container = environment.isV11Container;

  constructor(public idleTimer: IdleTimerService, public translateService: TranslateService, public matDialog: MatDialog, public commonUI: CommonUIService, public commonService: CommonServiceService, public idle: Idle, public router: Router) {
    if (localStorage.CurrentLanguage !== undefined)
      this.language = localStorage.CurrentLanguage;
    else {
      this.language = 'en';
      this.translateService.use('en');
    }
  }

  isRunningStandalone() {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  ngOnInit(): void {
    if (this.isV11Container) {
      this.commonService.navigatedLogin();
    } else {
      if (this.idleTimer.isStarting)
        this.idleTimer.idleStop();
      this.commonUI.initLoading();
      this.commonUI.addLoadingTask();
      this.checkStandAlone();
      this.setLanguageList();
      this.translateService.use(this.language);
      this.commonUI.finishLoadingTask();
    }
  }

  checkStandAlone() {
    //Mobile Safari in standalone mode
    if (this.isRunningStandalone()) {

      // If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
      var noddy, remotes = true;
      document
        .addEventListener(
          'click',
          function (event) {

            noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while (noddy.nodeName !== "A"
              && noddy.nodeName !== "HTML") {
              noddy = noddy.parentNode;
            }
            if ('href' in noddy
              && noddy.href.indexOf('http') !== -1
              && (noddy.href
                .indexOf(document.location.host) !== -1 || remotes)) {
              event.preventDefault();
              document.location.href = noddy.href;
            }
          }, false);
    }
  }

  setLanguageList() {
    var languageList = environment.siteConfig.languages.replace(/ /g, '').split(',');

    for (var i = 0; i < languageList.length; i++) {
      var desc = "";
      switch (languageList[i]) {
        case "en":
          desc = "English";
          break;
        case "zh_HK":
          desc = "繁體中文";
          break;
        case "zh_CN":
          desc = "简体中文";
          break;
      }
      this.languageList.push({ value: languageList[i], desc: desc });
    }
  }

  changeLanguage() {
    localStorage.CurrentLanguage = this.language;
    this.translateService.use(this.language);
  }

  openModal() {
    this.matDialog.open(PopupTermsComponent, {
      panelClass: 'dialog-responsive'
    })
  }

  login() {
    if (this.userCode == '' || this.password == '') return;

    if (this.loginInprogress) {
      return
    }
    this.loginInprogress = true;

    this.userCode = this.userCode.toUpperCase();

    var essPassword = essPassword !== undefined ? essPassword : '';

    this.commonUI.addLoadingTask();

    if (environment.loginCustom)
      this.commonService.getServerEncrypt().subscribe((data) => {
        this.commonService.loginCustom(data, this.userCode, this.password, essPassword).subscribe((result) => {
          if (!result['conn_fail'])
            if (result.body['ErrorMsg'] == '') {
              this.onLoginSuccess(result.body['Data'], result.body['Data'].isTickOldSession);
              this.commonUI.finishLoadingTask();
            }
            else {
              this.commonUI.finishLoadingTask();
              this.onCallLoginAPIFail(result.body, result.status, result.headers, result);
            }
          else {
            this.commonUI.finishLoadingTask();
            this.onCallLoginAPIFail(result.body, result.status, result.headers, result);
          }
        }, (err) => {
          this.commonUI.finishLoadingTask()
          this.commonUI.showMsgBoxWithCallback('error.undefined')
        })
      })
    else {
      this.commonService.getServerEncrypt().subscribe((data) => {
        sessionStorage.TempSKN = data['Data'].TempSKN
        sessionStorage.TempSKE = data['Data'].TempSKE
        this.commonService.login(data, this.userCode, this.password).subscribe((result) => {

          if (!result['conn_fail'])
            if (result.body['ErrorMsg'] == '') {
              this.onLoginSuccess(result.body['Data'], result.body['Data'].isTickOldSession);
              this.commonUI.finishLoadingTask();
            }
            else {
              this.commonUI.finishLoadingTask();
              this.onCallLoginAPIFail(result.body, result.status, result.headers, result);
            }
          else {
            this.commonUI.finishLoadingTask();
            this.onCallLoginAPIFail(result.body, result.status, result.headers, result);
          }
        })
      }, (err) => {
          this.commonUI.finishLoadingTask()
          this.commonUI.showMsgBoxWithCallback('error.undefined')
      })
    }
  }

  onLoginSuccess(loginInfo, isTickOldSession) {
    var sessionid = loginInfo.SessionID;
    var companyList = loginInfo.Company;
    var person = loginInfo.Person;
    var passwordPolicy = loginInfo.PasswordPolicy;
    var hTML5Link = loginInfo.HTML5Link;
    var v12HTML5OpenNewWindow = loginInfo.V12HTML5OpenNewWindow;
    var maxUploadSize = loginInfo.MaxUploadSize;
    var allowIOSChromeSaveAttachment = loginInfo.AllowIOSChromeSaveAttachment;
    var isSECMSEnable = loginInfo.IsSECMSEnable;

    if (loginInfo.DefaultLanguage && localStorage.CurrentLanguage == undefined) {
      this.language = loginInfo.DefaultLanguage;
    }
    this.changeLanguage();

    this.loginInprogress = false;
    if (this.rememberme) {
      localStorage.RememberMe = "Y";
      localStorage.UserCode = this.userCode;
    } else {
      localStorage.RememberMe = "";
      localStorage.UserCode = "";
    }

    if (isTickOldSession) {
      this.commonUI.showMsgBox("message.M0021");
    }

    this.companyList = companyList;
    sessionStorage.SessionID = sessionid;
    sessionStorage.companyList = JSON.stringify(companyList);
    sessionStorage.Person = JSON.stringify(person);
    sessionStorage.PasswordPolicy = JSON.stringify(passwordPolicy);
    sessionStorage.HTML5Link = JSON.stringify(hTML5Link);
    sessionStorage.V12HTML5OpenNewWindow = JSON.stringify(v12HTML5OpenNewWindow);
    sessionStorage.MaxUploadSize = maxUploadSize;
    sessionStorage.AllowIOSChromeSaveAttachment = allowIOSChromeSaveAttachment;
    sessionStorage.IsSECMSEnable = isSECMSEnable;
    this.commonUI.finishLoadingTask();
    if (companyList.length > 1) {
      this.selectCompany()
    } 
    else if(companyList.length == 1 && companyList[0].StaffList.length > 1) {
      this.selectCompany()
    }
    else if(companyList.length == 1 && companyList[0].StaffList.length == 1){
      this.selectEmployee(companyList[0].StaffList[0]);
    }
  }

  onCallLoginAPIFail(data, status, headers, config) {
    this.password = "";
    this.loginInprogress = false;
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  selectCompany() {
    this.matDialog.open(PopupCompanySelectComponent, {
      data: this.companyList
    })
    this.commonUI.finishLoadingTask();
    // if (companyList.length > 1) {
    //     $('#pp1_login_content').fadeIn();
    // } else {
    //     this.selectCompany(companyList[0]);
    // }
    // if (company.StaffList.length == 1) {
    //     this.selectEmployee(company.StaffList[0]);
    // } else {
    //     $('#pp1_login_content').fadeToggle();
    //     $('.pp3_content').fadeToggle();
    // }
  }

  selectEmployee(employee) {
    // sessionStorage.Employee = JSON.stringify(employee);
    $('#popup_menu_select_position').fadeToggle();
    this.commonUI.addLoadingTask();
    this.commonService.setCompany(this.companyList[0]).subscribe((result) => {

      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.commonUI.finishLoadingTask();

          sessionStorage.Employee = JSON.stringify(employee);
          sessionStorage.Person = JSON.stringify(employee.Person);

          sessionStorage.Company = JSON.stringify(this.companyList[0]);
          sessionStorage.Person = JSON.stringify(this.companyList[0].Person);

          this.commonUI.finishLoadingTask();
          this.router.navigateByUrl('ess/dashboard')
        }
        else {
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      else {
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  // logout() {
  //   this.commonUI.addLoadingTask();
  //   this.commonService.logout().subscribe((res) => {
  //     if(!res['conn_fail']) 
  //       this.onLogoutSuccess()
  //     else 
  //       this.onCallLoginAPIFail(res['Data'], res['Data'].status, res['Data'].headers, res['Data'].config)
  //   })
  // }

  // onLogoutSuccess() {
  //   this.Idle.unwatch();
  //   $('#pp1_login_content').fadeOut();
  //   $('.pp3_content').fadeOut();
  //   this.commonUI.finishLoadingTask();
  //   this.password = "";
  //   this.router.navigateByUrl('/')   //Return to base and redirect to login/maintenance
  // }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}