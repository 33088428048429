<div class="list_flag-selected" id="{{ popupid }}">
    <div
         [ngClass]='popupmode == "READONLY"? "pop_functions_box pop_functions_box_size_2column":"pop_functions_box pop_functions_box_size_2column pop_functions_box_size_2column_approval"'
    >
        <div class="pop_functions_close maincolor_btn2 pp_close" (click)="close()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
            <div class="detail_box_top_photo" [ngStyle]="{'background-image': 'url(' + commonUI.getStaffImage(claim.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
                <span class="txt_popup_staffname">{{claim.StaffInfo.FullName}}</span><br>
                <span class="txt_popup_position">{{claim.StaffInfo.PositionDesc}}</span><br>
                <span class="txt_popup_staffid">ID : {{ claim.StaffInfo.StaffID }}</span>
                <span class="txt_popup_staffid float-right" *ngIf='claim.TrainingApplicationNo'>{{'eclaiminquiry.trainingapplicationno' | translate}} : {{ claim.TrainingApplicationNo }}</span>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

            <div class="detail_box_bottom_2point_no_type">
                <div class="detail_box_catalog_area">
                    <div class="maincolor_bg color_label_{{ claim.StatusDesc | lowercase }}">{{ claim.StatusDesc | lowercase | translate }}</div>
                </div>
                <div class="detail_box_session1 txt_grey_2 txt_size_3">{{ 'eclaiminquiry.claimdate' | translate }}</div>
                <div class="detail_box_date1 txt_size_1">{{ commonUI.formatDisplayDate(claim.ClaimDate, 'DD MMM YYYY') }}<span class="txt_size_3">&nbsp;({{ commonUI.formatDisplayDate(claim.ClaimDate, 'ddd') }})</span></div>
                <div class="detail_box_sub_icon d_box_sub_icon_leave"></div>
                <div class="detail_box_date_num maincolor_txt"><span class="txt_size_0">{{ 'eclaiminquiry.type' | translate }}</span></div>
                <div class="detail_box_type">{{ claim.ClaimTypeDesc }}</div>
            </div>

            <div class="detail_box_other txt_size_1">

                <div>
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.submittedon' | translate }}</div>
                    {{commonUI.formatDisplayDate(claim.CreateDate, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(claim.CreateDate, 'ddd')}})</span>&nbsp;{{commonUI.formatDisplayDate(claim.CreateDate, 'h:mm A')}}
                </div>

                <!-- <div>
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.visitdate' | translate }}</div>
                    {{commonUI.formatDisplayDate(claim.VisitingDate, 'D MMM YYYY')}}<span class="txt_size_3">&nbsp;({{commonUI.formatDisplayDate(claim.VisitingDate, 'ddd')}})</span>&nbsp;{{commonUI.formatDisplayDate(claim.CreatedDate, 'h:mm A')}}
                </div> -->

                <br>

                <div class="row">
                    <div class='col-4'>
                        <div class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.amount' | translate }}</div>
                        {{claim.ClaimCcy}} {{claim.ClaimAmount}}
                    </div>
                    <div class='col-4'>
                        <div class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.adjustedamount' | translate }}</div>
                        {{claim.ClaimCcy}} {{claim.AdjustedAmount}}        
                    </div>
                    <div class='col-4'>
                        <div class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.excessivecliam' | translate }}</div>
                        {{ claim.IsExcessiveClaim == 'Y'? 'yes' : 'no' | translate}}
                    </div>
                </div>

                <br>

                <div>
                    <div class="txt_grey_2 txt_size_3">
                        {{ 'eclaiminquiry.project_code' | translate }}
                        <div class="approver_staff_remark_2 txt_grey_1 txt_size_2">{{ claim.ProjectCodeDesc }}</div>
                    </div>
                </div>

                <br>

                <div>
                    <div class="txt_grey_2 txt_size_3">
                        {{ 'eclaiminquiry.mainvo_code' | translate }}
                        <div class="approver_staff_remark_2 txt_grey_1 txt_size_2">{{ claim.MAINVOCodeDesc }}</div>
                    </div>
                </div>

                <br>

                <div>
                    <div class="txt_grey_2 txt_size_3">
                        {{ 'eclaiminquiry.car_code' | translate }}
                        <div class="approver_staff_remark_2 txt_grey_1 txt_size_2">{{ claim.CarCodeDesc }}</div>
                    </div>
                </div>

                <br>

                <div>
                    <div class="txt_grey_2 txt_size_3">{{ 'eclaiminquiry.maxamount' | translate }}</div>
                    {{claim.ClaimCcy}} {{ claim.TranMaxAmount }}
                </div>

                <br>

                <div>
                    <div class="txt_grey_2 txt_size_3">
                        {{ 'eclaiminquiry.remarks' | translate }}
                        <div class="approver_staff_remark_2 txt_grey_1 txt_size_2">{{ claim.Remarks }}</div>
                    </div>
                </div>

                <br>
                
                <div class="txt_grey_2 txt_size_3">
                    {{ 'eclaiminquiry.attachments' | translate }}
                    <div class="detail_box_attachment_area">
                        <div class="attachment_photo" *ngIf="claim.AttachmentLink1">
                            <div><img [src]="attachment1.dataURL" *ngIf="claim.AttachmentLink1"></div>
                            <div id="dl_attach1" class="attachment_photo_touch" (click)="viewAttachment(claim.AttachmentLink1, claim.AttachmentName1)"></div>
                        </div>
                        <div class="attachment_photo" *ngIf="claim.AttachmentLink2">
                            <div><img [src]="attachment2.dataURL" *ngIf="claim.AttachmentLink2"></div>
                            <div id="dl_attach2" class="attachment_photo_touch" (click)="viewAttachment(claim.AttachmentLink2, claim.AttachmentName2)"></div>
                        </div>
                        <div class="attachment_photo" *ngIf="claim.AttachmentLink3">
                            <div><img [src]="attachment3.dataURL" *ngIf="claim.AttachmentLink3"></div>
                            <div id="dl_attach3" class="attachment_photo_touch" (click)="viewAttachment(claim.AttachmentLink3, claim.AttachmentName3)"></div>
                        </div>
                        <div class="attachment_photo" *ngIf="claim.AttachmentLink4">
                            <div><img [src]="attachment4.dataURL"  *ngIf="claim.AttachmentLink4"></div>
                            <div id="dl_attach4" class="attachment_photo_touch" (click)="viewAttachment(claim.AttachmentLink4, claim.AttachmentName4)"></div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>

            </div>

        </div>


        <div class="detail_box_bottom_area mt0_m">

            <div class="txt_grey_2 txt_size_3">{{ 'approval.approverremarks' | translate }}</div>
            <div class="detail_box_approver_remarks_area" *ngIf='approverRemarkList'>
                <div *ngFor="let approverRemark of approverRemarkList">
                    <div>
                        <div class="txt_photo_area_small fl" [ngStyle]="{'background-image': 'url(' + commonUI.getStaffImage(approverRemark.StaffInfo) + ')'}">
                            <div class="txt_photo_mask_small p_normal_grey"></div>
                        </div>
                        <div class="approver_staff_content">
                            <div class="txt_bold">{{ approverRemark.StaffInfo?.FullName }}</div>
                            <div class="txt_bold">{{approverRemark.StaffInfo?.PositionDesc}}</div>
                            <div class="txt_grey_2 txt_size_3">{{commonUI.formatDisplayDate(approverRemark.ProcessDate, 'D MMM YYYY (ddd) - h:mm A')}}</div>
                            <div class="approver_staff_remark txt_box_1">{{ approverRemark.Remark }}</div>
                        </div>
                        <div style="clear:both"></div>
                    </div>

                    <hr class="hr_profile">
                </div>
            </div>
            <button class="btn_next_approver" (click)="commonUI.showNextApprover(claim)">
                <img src="assets/img/btn_show_next_approver.png" width="18">
                {{'show-next-approver' | translate}}
            </button>
        </div>
    </div>

    <div *ngIf="popupmode == 'WITHDRAWALNOEDIT'">
        <div class="pop_functions_approval">
            <div class="pop_functions_one_btn_center">
                <button class="btn btn-block btn-lg one_btn btn_red flag_submit_remarks pp_close" (click)="showWithdrawConfirm()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'eclaiminquiry.withdraw' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
    <div *ngIf="popupmode == 'WITHDRAWAL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_edit" ng-click="popupcontrol.showEditPopup()"><img src="assets/img/btn_edit_w.png" width="18">{{ 'eclaiminquiry.edit' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_red flag_submit_remarks pp_close" (click)="showWithdrawConfirm()"><img src="assets/img/btn_del_doc.png" width="18">{{ 'eclaiminquiry.withdraw' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
    <div *ngIf="popupmode == 'APPROVAL'">
        <div class="pop_functions_approval">
            <div class="pop_functions_two_btn_center">
                <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="showApprovePopup()"><img src="assets/img/btn_tick_w.png" width="18">{{ 'approve' | translate }}</button>
                <button class="btn btn-block btn-lg two_btn btn_grey pp_close flag_submit_finish" (click)="showRejectPopup()"><img src="assets/img/btn_cross_w.png" width="18">{{ 'reject' | translate }}</button>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>

</div>