import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { CommonUIService } from '../../services-UI/common-ui.service';
import { CommonServiceService } from '../../services/common-service.service';
import { EclaimService } from '../../services/eclaim.service';

@Component({
  selector: 'app-popup-detail-of-claim-balance',
  templateUrl: './popup-detail-of-claim-balance.component.html',
  styleUrls: ['./popup-detail-of-claim-balance.component.scss']
})
export class PopupDetailOfClaimBalanceComponent implements OnInit {

  employee = JSON.parse(sessionStorage.Employee);
  claimBalance;
  eclaims;
  asAtDate = new Date();
  claimType = '';
  claimTypeDesc = '';
  staffInfo;

  constructor(public commonService: CommonServiceService ,public commonUI: CommonUIService, @Inject(MAT_DIALOG_DATA) public data: {}, private matDialogRef: MatDialogRef<PopupDetailOfClaimBalanceComponent>, private eclaimService: EclaimService) { 
    this.claimType = data['type'];
    this.claimTypeDesc = data['desc'];
    this.eclaims = data['app']
    this.staffInfo = data['staffInfo'];
  }

  ngOnInit(): void {
    this.getClaimBalance();
  }

  getClaimBalance() {
    this.commonUI.addLoadingTask();
    this.eclaimService.getClaimBalance(this.employee, this.claimType, this.eclaims, this.asAtDate).subscribe((result) => {
      if(result.body["ErrorMsg"] != '') {
        this.claimBalance = result.body['Data'].CutOffBalance;
        this.commonUI.finishLoadingTask();
      } else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result);
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  close() {
    try {
      this.matDialogRef.close()
    } catch {}
  }

  onCallAPIFail(data, status, headers, config) {
    this.close();
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}
