    <!--============ popup : password-finished  ============ -->
    <div id="popup_forget_password_sent" class="pp1_content" >
        <div class="pop_functions_box pop_functions_box_size_1">
            <br />
            <img src="assets/img/msg_tick.png" width="48">
            <br /><br />
            {{ 'forgotpwd.emailsent' | translate }}
            <br /><br />
            <div class="pop_functions_two_btn_center btn_signin_position">
                <button class="btn btn-block maincolor_btn2 icon_arrow_r" (click)='backToLogin()'>
                    {{ 'forgotpwd.backtologin' | translate }}
                </button>
            </div>
        </div>
    </div>