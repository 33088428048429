import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { PopupPunchDetailComponent } from '../common/popup-punch-detail/popup-punch-detail.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { CommonServiceService } from '../services/common-service.service';
import { InOutService } from '../services/in-out.service';

declare var $: any;

@Component({
  selector: 'app-punch-inquiry',
  templateUrl: './punch-inquiry.component.html',
  styleUrls: ['./punch-inquiry.component.scss']
})
export class PunchInquiryComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  @ViewChild('endPicker', { static: false }) endPicker: MatDatepicker<any>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  title = "PunchInquiry";
  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  // StaffModule = [];
  punchDate = moment().toDate();
  endDate = this.punchDate;
  resultList = [];
  eEmployPunchDetailList = [];
  selectedEEmployPunchDetail;
  isTeammateViewSelected = false;
  popupmode = "READONLY";
  search = {
    keywords: ""
  };
  propertyName = "StaffInfo.FullName";
  reverse = true;

  constructor(public inoutService: InOutService, public commonUI: CommonUIService, public commonService: CommonServiceService, public matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  dateValidator(date) {
    switch (date) {
      case 'start':
        if (this.punchDate > this.endDate)
          this.endDate = new Date(this.punchDate)
        break;
      case 'end':
        if (this.endDate < this.punchDate)
          this.punchDate = new Date(this.endDate)
        break;
    }
  }

  hideSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();
  }

  resetSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

    this.punchDate = moment().toDate();
    this.endDate = this.punchDate;
    this.isTeammateViewSelected = false;
  }

  showSearchResult() {
    if (this.isTeammateViewSelected) {
      this.showTeammateSearchResult();
    } else {
      this.showPersonalSearchResult();
    }
  }

  showPersonalSearchResult() {
    this.commonUI.addLoadingTask();
    this.inoutService.getSelfPunchDetailList(
      this.employee,
      this.commonUI.formatJsonDate(this.punchDate, "YYYY-MM-DDT00:00:00"),
      this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.onGetEEmployPunchDetailListSuccess(result.body['Data'].EEmployPunchDetailList)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  showTeammateSearchResult() {
    this.commonUI.addLoadingTask();
    this.inoutService.getTeamPunchDetailList(
      this.employee,
      this.commonUI.formatJsonDate(this.punchDate, "YYYY-MM-DDT00:00:00"),
      this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.onGetEEmployPunchDetailListSuccess(result.body['Data'].EEmployPunchDetailList)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  onGetEEmployPunchDetailListSuccess(eEmployPunchDetailList) {
    this.eEmployPunchDetailList = eEmployPunchDetailList;
    this.prepareListInfo();
    $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", () => {
      this.commonUI.finishLoadingTask();
      $('html, body').animate({
        scrollTop: $(".list_result_area").offset().top
      }, 1000);
    });
  }

  prepareListInfo() {
      var index = 0;
      this.eEmployPunchDetailList.forEach((eEmployPunchDetail, key) => {
        eEmployPunchDetail.keywords = "";
        eEmployPunchDetail.keywords += eEmployPunchDetail.StaffInfo.FullName + " ";
        eEmployPunchDetail.keyWords += eEmployPunchDetail.StaffInfo.PositionDesc + " ";
        eEmployPunchDetail.keyWords += this.commonUI.formatDisplayDate(eEmployPunchDetail.FirstPunchIn, 'D MMM YYYY (ddd) hh:mm A') + " ";
        eEmployPunchDetail.keyWords += this.commonUI.formatDisplayDate(eEmployPunchDetail.LastPunchOut, 'D MMM YYYY (ddd) hh:mm A') + " ";
        eEmployPunchDetail.index = index++;
      });
  }

  searchByInput() {
    if (this.search.keywords.length <= 0) return this.eEmployPunchDetailList = this.resultList
    else this.eEmployPunchDetailList = this.resultList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  toggleSortMenu() {
    $("#p4_content").fadeToggle();
  }

  clearFilter() {
    this.search.keywords = "";
    this.eEmployPunchDetailList = this.resultList;
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(0).addClass("active");

    this.commonUI.scrollToTop();
  }

  applyFilter(keyword, i) {
    this.search.keywords = keyword + " ";
    this.eEmployPunchDetailList = this.resultList.filter(o => o.StatusDesc == keyword);
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(i).addClass("active");

    this.commonUI.scrollToTop();
  }

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch (propertyName) {
      case 'StaffInfo.FullName':
        this.eEmployPunchDetailList = this.eEmployPunchDetailList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'StaffInfo.PositionDesc':
        this.eEmployPunchDetailList = this.eEmployPunchDetailList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
      case 'FirstPunchIn':
        this.eEmployPunchDetailList = this.eEmployPunchDetailList.sort((a, b) => {
          return new Date(b.FirstPunchIn).getTime() - new Date(a.FirstPunchIn).getTime()
        })
        break;
      case 'LastPunchOut':
        this.eEmployPunchDetailList = this.eEmployPunchDetailList.sort((a, b) => {
          return new Date(b.LastPunchOut).getTime() - new Date(a.LastPunchOut).getTime()
        })
        break;
    }
    if (this.reverse) this.eEmployPunchDetailList = this.eEmployPunchDetailList.slice().reverse()
    this.toggleSortMenu()
  }

  showEEmployPunchDetail(eEmployPunchDetail) {
      this.selectedEEmployPunchDetail = eEmployPunchDetail;
      this.commonUI.addLoadingTask();
      const dialog = this.matDialog.open(PopupPunchDetailComponent, {
        panelClass: 'responsive-dialog',
        data: {
          selectedEEmployPunchDetail: this.selectedEEmployPunchDetail
        }
      })
      dialog.afterOpened().subscribe(() => {
        this.commonUI.finishLoadingTask();
      })
      this.commonUI.finishLoadingTask();
      return;
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.punchDate = new Date()
        instance.startPicker.close()
        break;
      case 'end':
        instance.endDate = new Date()
        instance.endPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
      case 'end':
        this.endPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
  }

  /*******************/
  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}
