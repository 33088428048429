import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';

declare var $: any;

@Component({
  selector: 'app-popup-job-position',
  templateUrl: './popup-job-position.component.html',
  styleUrls: ['./popup-job-position.component.scss']
})
export class PopupJobPositionComponent implements OnInit {

  selectedCompany;
  selectedEmployee;
  employee; person; company;
  refreshState = false

  constructor(public matDialogRef: MatDialogRef<PopupJobPositionComponent>, @Inject(MAT_DIALOG_DATA) public data, public commonUI: CommonUIService, public commonService: CommonServiceService, public router: Router) {
    this.selectedCompany = data
    if(this.selectedCompany.StaffList.length <= 1) this.selectEmployee(this.selectedCompany.StaffList[0])
  }

  ngOnInit(): void {
  }

  selectEmployee(employee) {
    this.selectedEmployee = employee;
    // sessionStorage.Employee = JSON.stringify(employee);
    $('#popup_menu_select_position').fadeToggle();
    this.commonUI.addLoadingTask();
    this.commonService.setCompany(this.selectedCompany).subscribe((result) => {
      
      if (!result['conn_fail'])
        if (result.body['ErrorMsg'] == null) {
          this.commonUI.finishLoadingTask();

          sessionStorage.Employee = JSON.stringify(this.selectedEmployee);
          sessionStorage.Person = JSON.stringify(this.selectedEmployee.Person);
    
          this.employee = this.selectedEmployee;
    
          sessionStorage.Company = JSON.stringify(this.selectedCompany);
          sessionStorage.Person = JSON.stringify(this.selectedCompany.Person);
          this.company = this.selectedCompany;
          this.person = this.company.Person;

          this.commonUI.finishLoadingTask();
          this.refreshState = true
          this.router.navigateByUrl('ess/dashboard')
          this.close();
        
    
          // if (sessionStorage.IsSECMSEnable == "Y") {
          //     this.commonUI.addLoadingTask();
    
          //     if (sessionStorage.SECMSSessionID == undefined)
          //         this.commonService.loginSECMS(this.employee, this.onLoginSECMSSuccess, this.onCallAPIFail);
          //     else
          //         this.commonService.setSECMSCompany(this.selectedCompany, this.onSetSECMSCompanySuccess, this.onCallAPIFail);
          // } else {
          //     if ($state.current.name == "home.redirect") {
          //         $state.go("home.dashboard");
          //     } else {
          //         $state.go('home.redirect');
          //     }
          // }
        }
        else {
          this.close()
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      else {
        this.close()
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.close()
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  close() {
    try {
      this.matDialogRef.close(this.refreshState)
    } catch { }
  }

}