<div class="pp_contact_content list_flag-selected" id="{{ popupid }}">
    <div class="pop_functions_box pop_functions_box_size_2column pop_functions_box_size_1column">
        <div class="pop_functions_close btn_grey_2b pp_close" (click)="closePopup()">x</div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_edit"></div>
            <div class="fl">{{ 'inouttimeapplication.editofpunchrecord' | translate }}</div>
        </div>

        <div class="d_box_top"></div>

        <div class="detail_box_bottom_area_profile mt0_m">

            <div class="box_content_inquiry">
                <form id="form_application">

                    <div class="form_area">

                        <!-- ========== a record =========== -->
                        <a name="top_form-1"></a>
                        <div id="form-1" class="edit_profile_box">

                            <div class="edit_profile_box">

                                <a name="list_type" class="list_bar_mt"></a>

                                <div>
                                    <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplication.date' | translate }}</div>
                                    <div class="fl input_new_calendar">
                                        <div>
                                            <mat-form-field appearance='fill' (click)="startPicker.open()">
                                                <input matInput [matDatepicker]="startPicker" [(ngModel)]="punchForEdit.PunchDate" [min]='minDate' [max]='maxDate' readonly name='sPicker'>
                                                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startPicker (opened)="openAppend('start')"></mat-datepicker>
                                            </mat-form-field>
                                            <div style="display: none">
                                                <div class="datepicker-footer" #startFooter>
                                                    <button mat-raised-button  (click)="today('start')" #todayButton>
                                                        {{'TODAY' | translate}}
                                                    </button>
                                                    <button mat-raised-button (click)="close('start')" #doneButton>
                                                        {{'close' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
									<div style="clear:both"></div>
                                </div>

                                <div>
                                    <div class="btn_sub_title txt_grey_2">{{ 'inouttimeapplication.time' | translate }}</div>
                                    <div class="time_field normal txt_grey_2">
                                        <div class="fl">
                                            <select id="h1" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="punchForEdit.Hour" name='hour'>
                                                <option *ngFor="let hour of commonUI.hourRange()" title="{{hour}}" value="{{hour}}">{{hour}}</option>
                                            </select>
                                        </div>
                                        <div class="fl">&nbsp;:&nbsp;</div>
                                        <div class="fl">
                                            <select id="m1" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="punchForEdit.Minute" name='minute'>
                                                <option *ngFor="let minute of commonUI.minuteRange()" title="{{minute}}" value="{{minute}}">{{minute}}</option>
                                            </select>
                                        </div>
                                        <div class="fl">&nbsp;&nbsp;</div>
                                        <div class="fl">
                                            <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="punchForEdit.DayPart" name='zone'>
                                                <option title="00" value="{{ 'inouttimeapplication.am' | translate }}">{{ 'inouttimeapplication.am' | translate }}</option>
                                                <option title="01" value="{{ 'inouttimeapplication.pm' | translate }}">{{ 'inouttimeapplication.pm' | translate }}</option>
                                            </select>
                                        </div>
                                        <div style="clear:both"></div>
                                    </div>
                                </div>

                                <div class="form_content_input_field">
                                    <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'inouttimeapplication.type' | translate }}</span><br>
                                    <select id="type" class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="punchForEdit.Status" name='status'>
                                        <option value="I" selected>{{ 'inouttimeapplication.intime' | translate }}</option>
                                        <option value="O">{{ 'inouttimeapplication.outtime' | translate }}</option>
                                    </select>
                                </div>

                                <div class="form_content_input_field">
                                    <span class="btn_sub_title txt_grey_2 txt_size_3">{{ 'inouttimeapplication.site' | translate }}</span><br>
                                    <select class="form-control btn_dropdown_withremarks btn btn-block btn-lg btn_date maincolor_btn2" [(ngModel)]="punchForEdit.PunchLocation" (change)="siteChange()" name='code'>
                                        <option *ngFor="let site of siteDetailList" title="{{site.Code}}" value="{{site.Code}}">{{site.Code}}</option>
                                    </select>
                                    <textarea id="ProjectDescription" name="ProjectDescription" class="form-control detail_staff_remark_input txt_box_1 txt_size_2" type="text" readonly [(ngModel)]="siteDesc" name='desc'></textarea>
                                </div>

                            </div> <!-- ========== end : form_content_input =========== -->
                            <!--
                            <div class="pop_functions_close btn_grey_2b fr delete_a_record" title="Delete this">x</div>-->

                        </div> <!-- ========== end : form_content =========== -->





                    </div> <!-- ========== end : form_area =========== -->

                </form>
            </div> <!-- ========== end : form_content_inquiry =========== -->
        </div> <!-- ========== end : detail_box_bottom_area_profile =========== -->

    </div>
    <div class="pop_functions_approval">
        <div class="pop_functions_two_btn_center">
            <button class="btn btn-block btn-lg two_btn maincolor_btn2 flag_submit_finish pp_close" (click)="confirmEdit()"><img src="assets/img/btn_tick_w.png" width="16" alt="">{{ 'confirm' | translate }}</button>
            <button class="btn btn-block btn-lg two_btn btn_red flag_submit_remarks_close" (click)="deleteEdit()"><img src="assets/img/btn_cross_w.png" width="16" alt="">{{ 'delete' | translate }}</button>
            <div style="clear:both"></div>
        </div>
    </div>
</div>