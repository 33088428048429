<div id="main-content">
    <a name="back_top_page"></a>

    <div id="layout" [ngClass]="toggleMenu? 'active': ''">
        <a rel="external" href="javascript:void(0)" id="menuLink" class="menu-link" (click)="toggleShowHideMenu()"></a>
        <!-- Menu toggle -->
        <!-- <a rel="external" href="javascript:void(0)" id="menuLink" class="menu-link" (click)="menu.toogleShowHideMenu()"> -->
            <!-- icon <span></span>-->
        <!-- </a> -->

        <div class="logo_desktop" style="background-image: url(assets/img/logo_acube_vertical.png);"></div>

        <!-- menu -->
        <!-- <div ng-include src="'views/common/menu.html'"></div> -->
        <!-- <div ng-include src="''+ menuTemplateUrl +''"></div> -->
        <app-navigation-menu (toggle)="toggleMenu = $event"></app-navigation-menu>
        <!-- menu -->



        <app-common-control></app-common-control>
        <div id="main">

            <!-- toolbar -->
            <!-- <div id="toolbar" ng-include src="'views/common/toolbar.html'"></div> -->
            <app-toolbar></app-toolbar>
            <!-- toolbar -->
            <!-- content -->
            <div ui-view class="content" (click)="hidePopup()">
                <router-outlet></router-outlet>
            </div>
            <!--
                <div ng-include src="'views/dashboard.html'"></div>
                <div ng-include src="'views/leave_application.html'"></div>
                -->
            <!-- content -->

        </div>
        <!-- end id=main -->
    </div>
    <!-- end id=layout -->
    <div rel="external" (click)="commonUI.scrollToTop()" title="Back the top of page">
        <div class="back_top" title="Back to top">
            <img alt="" src="assets/img/top_arrow.png" width="20" />
        </div>
    </div>
</div>