import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, Inject } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CommonUIService } from '../services-UI/common-ui.service';
import { AppointmentService } from '../services/appointment.service';
import { CommonServiceService } from '../services/common-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PopupPasswordPolicyComponent } from '../common/popup-password-policy/popup-password-policy.component';
import { PopupMessageComponent } from '../common/popup-message/popup-message.component';

@Component({
  selector: 'app-ac-change-password',
  templateUrl: './ac-change-password.component.html',
  styleUrls: ['./ac-change-password.component.scss']
})

export class ACChangePasswordComponent implements OnInit {
  
  currentPassword = "";
  newPassword = "";
  newPassword2 = "";
  passwordPolicy = JSON.parse(sessionStorage.PasswordPolicy);
  translationData = {
	    numOfChars: this.passwordPolicy.MinPwdLen
	};
  constructor(public commonService: CommonServiceService, public commonUI: CommonUIService, public appointmentService: AppointmentService, public matDialog: MatDialog, public translateService: TranslateService, public route: ActivatedRoute, public router: Router, @Inject(MAT_DIALOG_DATA) public data: {}) { }

  ngOnInit(): void {
  }
	
  showModalPasswordPolicy() {
     this.matDialog.open(PopupPasswordPolicyComponent, {
    		panelClass: 'dialog-responsive',
    		data: {
				td: this.translationData,
				pp: this.passwordPolicy
			}
  	})
  }
   
  doChangePassword() {
    this.commonUI.addLoadingTask();
    
  	if (!this.currentPassword || !this.newPassword || !this.newPassword2) return;
  	if (this.newPassword != this.newPassword2) {
      this.commonUI.finishLoadingTask();
  	  this.commonUI.showMsgBox("message.M0002");
  		return;
  	}

  	this.commonService.doChangePassword(this.currentPassword, this.newPassword).subscribe((result) => {
  	  if(result.body['ErrorMsg']) {
        this.commonUI.showMsgBox(`error.${result.body['ErrorCode']}`);
        this.commonUI.finishLoadingTask();
      }
  	 else this.onDoChangePasswordSuccess();
  	}, err => {
  	 this.onCallAPIFail(err.body, err.status, err.headers, err);
  	 this.commonUI.finishLoadingTask();
  	})
  }
  
  onDoChangePasswordSuccess() {
  	this.commonUI.addLoadingTask();
    this.matDialog.open(PopupMessageComponent, {
          panelClass: 'dialog-responsive',
          data: "message.M0003"
      }).afterClosed().subscribe(() => {
  	      this.logout();
      })

  }
  
  logout() {
      this.commonUI.addLoadingTask();
      this.commonService.logout().subscribe((res) => {
        this.onLogoutSuccess()
      }, err => {
        if(err['conn_fail']) {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
          this.onLogoutSuccess()
        }
    })

  }
  
  onLogoutSuccess() {
    this.commonUI.finishLoadingTask();    
    this.matDialog.open(PopupMessageComponent, {
          panelClass: 'dialog-responsive',
          data: "Please Login Again."
      }).afterClosed().subscribe(() => {
  	      	this.router.navigateByUrl("login");
      })
  }  

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

}