import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PopupAppointmentDetailComponent } from '../common/popup-appointment-detail/popup-appointment-detail.component';
import { CommonUIService } from '../services-UI/common-ui.service';
import { AppointmentService } from '../services/appointment.service';
import { CommonServiceService } from '../services/common-service.service';

declare var $: any;

@Component({
  selector: 'app-appointment-inquiry',
  templateUrl: './appointment-inquiry.component.html',
  styleUrls: ['./appointment-inquiry.component.scss']
})
export class AppointmentInquiryComponent implements OnInit {

  @ViewChild('startFooter', { static: false }) startFooter: ElementRef;
  @ViewChild('endFooter', { static: false }) endFooter: ElementRef;
  @ViewChild('startPicker', { static: false }) startPicker: MatDatepicker<any>;
  @ViewChild('endPicker', { static: false }) endPicker: MatDatepicker<any>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */

  reverse: boolean;
  title = "AppointmentApplicationInquiry";
  sessionId = sessionStorage.SessionID;
  employee = JSON.parse(sessionStorage.Employee);
  startDate = moment().add(-1, 'years').toDate();
  endDate = moment().add(+1, 'years').toDate();
  selectedAppointment;
  selectedAppointmentRemarkList;
  isDelegate = false;
  selectedStatus = "All";
  isTeammateViewSelected = false;
  applicationList = [];
  resultList = [];
  typeList = [];
  typeListForEdit = [];
  appointmentTypeCode = "";
  appointmentPopMode = "READONLY";
  appointmentPopupControl = {};
  propertyName = 'CreateDate';

  search = {
    keywords: "",
    SourceType: ""
  };

  constructor(public commonUI: CommonUIService, public appointmentService: AppointmentService, public commonService: CommonServiceService, public translate: TranslateService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this);
  }

  dateValidator(date) {
    switch (date) {
      case 'start':
        if (this.startDate > this.endDate)
          this.endDate = new Date(this.startDate)
        break;
      case 'end':
        if (this.endDate < this.startDate)
          this.startDate = new Date(this.endDate)
        break;
    }
  }

  hideSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();
  }

  toggleSortMenu() {
    $("#p4_content").fadeToggle();
  }

  clearFilter() {
    this.search.keywords = "";
    this.applicationList = this.resultList
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(0).addClass("active");

    this.commonUI.scrollToTop();
  }

  applyFilter(keyword, i) {
    this.search.keywords = keyword + " ";
    this.applicationList = this.resultList.filter(o => o.StatusDesc == keyword)
    $("#filter_options").children().removeClass("active");
    $("#filter_options").children().eq(i).addClass("active");

    this.commonUI.scrollToTop();
  }

  showSearchResult() {
    if (this.isTeammateViewSelected) {
      this.showTeammateSearchResult();
    } else {
      this.showPersonalSearchResult();
    }
  }

  resetSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();

    this.startDate = moment().add(-1, 'years').toDate();
    this.endDate = moment().add(+1, 'years').toDate();
    this.appointmentTypeCode = "";
    this.selectedStatus = "All";
    this.isTeammateViewSelected = false;
  }

  showPersonalSearchResult() {
    this.commonUI.addLoadingTask();
    this.appointmentService.getSelfAppointmentApplicationList(
      this.employee,
      this.commonUI.formatJsonDate(this.startDate, "YYYY-MM-DDT00:00:00"),
      this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00"),
      this.selectedStatus).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.onGetAppointmentApplicationListSuccess(result.body['Data'].AppointmentList)
        }
        else {
          this.commonUI.finishLoadingTask()
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask()
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  onGetAppointmentApplicationListSuccess(application) {
    let instance = this;
    this.applicationList = application;
    this.resultList = application;
    this.prepareListInfo();
    $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing", () => {
      instance.commonUI.finishLoadingTask();
      $('html, body').animate({
        scrollTop: $(".list_result_area").offset().top
      }, 1000);
    });
  }

  showTeammateSearchResult() {
    this.commonUI.addLoadingTask();
    this.appointmentService.getTeamAppointmentApplicationList(
      this.employee,
      this.commonUI.formatJsonDate(this.startDate, "YYYY-MM-DDT00:00:00"),
      this.commonUI.formatJsonDate(this.endDate, "YYYY-MM-DDT00:00:00"),
      this.selectedStatus).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.onGetAppointmentApplicationListSuccess(result.body['Data'].AppointmentList)
        }
        else {
          this.commonUI.finishLoadingTask()
          this.onCallAPIFail(result.body, result.status, result.headers, result)
        }
      }, err => {
        this.commonUI.finishLoadingTask()
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  getNumOfApplication(status) {
    var count = 0;
    if (status == "") return this.resultList.length;
    for (var i = 0; i < this.resultList.length; i++) {
      if (this.resultList[i].StatusDesc == status) count++;
    }
    return count;
  }
  
  prepareListInfo() {
    var index = 0;
    this.applicationList.forEach((application, key) => {
      application.DateRange = this.commonUI.formatDisplayDate(application.AppointStart, 'D MMM YYYY (ddd) hh:mm a') + " - " + this.commonUI.formatDisplayDate(application.AppointEnd, 'D MMM YYYY (ddd) hh:mm a');
      application.StartDate = this.commonUI.formatJsonDate(application.AppointStart, 'YYYY-MM-DDT00:00:00');
      application.EndDate = this.commonUI.formatJsonDate(application.AppointEnd, 'YYYY-MM-DDT00:00:00');
      application.keywords = "";
      application.keywords += application.DateRange + " ";
      application.keywords += application.StartDate + " ";
      application.keywords += application.EndDate + " ";
      application.keywords += application.StaffInfo.FullName + " ";
      application.keywords += application.Type + " ";
      application.keywords += application.StatusDesc + " ";
      application.index = index++;
    });
  }

  showAppointmentDetail(appointment) {
    this.commonUI.addLoadingTask();
    this.selectedAppointment = appointment;

    this.appointmentPopMode = "READONLY";
    if (appointment.IsEditable && !appointment.IsWithdrawable) this.appointmentPopMode = "EDIT";
    if (!appointment.IsEditable && appointment.IsWithdrawable) this.appointmentPopMode = "WITHDRAWALNOEDIT";
    if (appointment.IsEditable && appointment.IsWithdrawable) this.appointmentPopMode = "WITHDRAWAL";

    this.commonService.getApproverRemarkList(appointment.RID, appointment.SourceType, appointment.AppointStart).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.onGetApproverRemarkListSuccess(result.body['Data'].ApproverRemarkList)
      }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onGetApproverRemarkListSuccess(remarkList) {
    this.selectedAppointmentRemarkList = remarkList;

    this.selectedAppointmentRemarkList.forEach((approverRemark) => {

      this.translate.get('appointmentapplicationinquery.status_approved').subscribe((status_approved) => {
        approverRemark.Remark = approverRemark.Remark.replace("[Approved]", "[" + status_approved + "]");
      })

      this.translate.get('appointmentapplicationinquery.status_pending').subscribe((status_pending) => {
        approverRemark.Remark = approverRemark.Remark.replace("[Pending]", "[" + status_pending + "]");
      })

      this.translate.get('appointmentapplicationinquery.status_rejected').subscribe((status_rejected) => {
        approverRemark.Remark = approverRemark.Remark.replace("[Rejected]", "[" + status_rejected + "]");
      })
    });

    // this.appointmentPopupControl.showPopup(this.selectedAppointment);
    const dialog = this.matDialog.open(PopupAppointmentDetailComponent, {
      panelClass: 'responsive-dialog',
      data: {
        app: this.selectedAppointment,
        approver_remark_list: this.selectedAppointmentRemarkList,
        isDelegate: this.isDelegate,
        popupmode: this.appointmentPopMode,
      }
    })
    this.commonUI.finishLoadingTask();

    dialog.afterClosed().subscribe((data?) => {
      if (data == undefined || data == null) return
      if (data.confirm) {
        this.showSearchResult();
      }
    })
  }

  searchByInput() {
    if (this.search.keywords.length <= 0) return this.applicationList = this.resultList
    else this.applicationList = this.resultList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
  }

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch (propertyName) {
      case 'StartDate':
        this.applicationList = this.applicationList.sort((a, b) => {
          return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime()
        })
        break;
      case 'StaffInfo.FullName':
        this.applicationList = this.applicationList.sort((a, b) => {
          return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
        })
        break;
    }
    if (this.reverse) this.applicationList = this.applicationList.slice().reverse()
    this.toggleSortMenu();
  }

  /* Datepicker Footer */

  today(picker: string) {
    let instance = this
    switch (picker) {
      case 'start':
        instance.startDate = new Date()
        instance.startPicker.close()
        break;
      case 'end':
        instance.endDate = new Date()
        instance.endPicker.close()
        break;
    }
  }

  close(picker: string) {
    switch (picker) {
      case 'start':
        this.startPicker.close()
        break;
      case 'end':
        this.endPicker.close()
        break;
    }
  }

  openAppend(picker: string) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    switch (picker) {
      case 'start':
        matCalendar.appendChild(this.startFooter.nativeElement);
        break;
      case 'end':
        matCalendar.appendChild(this.endFooter.nativeElement);
        break;
    }
  }

  /*******************/

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}