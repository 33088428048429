import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonUIService } from '../services-UI/common-ui.service';
import { environment } from '../../environments/environment'
import { CommonServiceService } from '../services/common-service.service';
import { MenuComponent } from '../common/menu/menu.component'
import { IdleTimerService } from '../services/idle-timer.service';
import { AppComponent } from '../app.component';


declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  title = "Home";
  employee = sessionStorage.Employee; // JSON.parse in front
  person = sessionStorage.Person; // JSON.parse in front
  menuTemplateUrl = environment.siteConfig.menuTemplateUrl;
  toggleMenu = false

  constructor(public app: AppComponent, public idleTimer: IdleTimerService, public commonUI: CommonUIService, public router: Router, public activeRouter:ActivatedRoute, public commonService: CommonServiceService, public menu: MenuComponent) {
    this.commonUI.initLoading();
    /*
    if(sessionStorage.SessionID == null || sessionStorage.SessionID == undefined) {
      this.router.navigate(["login"]);
    }
    else if(activeRouter.children.length == 0){
      this.router.navigate(["login"]);
    }
    */
  }

  ngOnInit(): void {
    this.idleTimer.idleStart();
    this.commonUI.addLoadingTask();
    this.commonUI.finishLoadingTask();
    // this.commonService.refreshSessToScope(this);

    // $(document).ready(function () {
    //     this.getModule();
    // });
    // this.getLists();

  }

  ngOnDestroy(): void {
    this.idleTimer.idleStop();
  }

  hidePopup = function () {
    $("#p1_content").hide();
    $("#p2_content").hide();
    $("#p3_content").hide();
    $("#toolbarOutstandingApprovalPopup").hide();
    $("#toolbarNoticePopup").hide();
    $("#toolbarSettingPopup").hide();
  }

  toggleShowHideMenu() {
    this.toggleMenu = !this.toggleMenu
  }

  showMenu = function () {
      this.toogleMenu = true;
  }

  hideMenu = function() {
    this.toggleMenu = false;
  }

  // getModule = function () {
  //   this.commonUI.addLoadingTask();
  //   this.commonService.getModule(this.employee, this.onGetModuleSuccess, this.onCallAPIFail);
  // }

  // onGetModuleSuccess = function (service, module) {
  //   this.commonUI.finishLoadingTask();
  // }

  // this.onCallAPIFail = function (data, status, headers, config) {
  //   this.commonUI.finishLoadingTask();
  //   if (status == "200") {
  //       this.errorUI.showErrorBox(data.ErrorCode);
  //   } else {
  //       $location.path("login");
  //   }
  // }

  // getLists = function () {
  //     personalProfileService.getAddressTypeList(this.onAsyncCallbackSuccess, this.onAsyncCallbackFail);
  //     personalProfileService.getAreaList(this.onAsyncCallbackSuccess, this.onAsyncCallbackFail);
  //     personalProfileService.getDistrictList(this.onAsyncCallbackSuccess, this.onAsyncCallbackFail);
  //     personalProfileService.getQualiTypeList(this.onAsyncCallbackSuccess, this.onAsyncCallbackFail);
  //     personalProfileService.getRelationshipList(this.onAsyncCallbackSuccess, this.onAsyncCallbackFail);
  //     personalProfileService.getIdentityTypeList(this.onAsyncCallbackSuccess, this.onAsyncCallbackFail);
  //     this.commonService.getHolidayDateList(this.onAsyncCallbackSuccess, this.onAsyncCallbackFail);
  // }

  // onAsyncCallbackFail = function (data, status, headers, config) {
  // }

  // onAsyncCallbackSuccess = function (service, theList) {
  // }

}