<!-- Mod at CEB, CIB, Citic, GoodHope, GTJAS, HKACC, OceanGarden, Ricacorp, WilsonCom, WKF, Yang -->
<div>
    <div class="title_reset_2">
        <span class="txt_size_1 txt_grey_2">{{ 'leaveapplication.leave' | translate }}</span>
    </div>

    <!-- =========== Search ========== -->
    <div class="content_box" *ngIf='leaveApplication'>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_application"></div>
            <div class="fl">{{ 'leaveapplication.application' | translate }}</div>
        </div>

        <div class="box_content_inquiry">
            <br /> <a name="list_type" class="list_bar_mt"></a>
            <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.type' | translate }}</div>
            <div class="fl input_field_large">
                <select class="btn btn_dropdown_1 maincolor_btn_1" (change)="isNotAllowBalanceInquriy()" [(ngModel)]="leaveApplication.LeaveTypeCode">
                    <option *ngFor="let leavetype of leaveTypeList" value="{{leavetype.Code}}">{{leavetype.Desc}}</option>
                </select>


            </div>
            <div class="fl input_field_medium btn_two_margin_left_m">
                <button [disabled]="notAllowBalanceInquiry" class="btn btn-block btn_grey btn-lg" (click)="showLeaveBalance()"><img src="assets/img/btn_search_a.png" width="18" />{{ 'leaveapplication.balanceinquiry' | translate }}</button>
            </div>

            <div style="clear: both"></div>
            <br />

            <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.startdate' | translate }}</div>

            <div class="fl input_new_calendar">
                <!-- <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker" [formControl]="date" [(ngModel)]="startDate" hidden>
                    <mat-label>{{commonUI.formatDisplayDate(startDate, 'DD MMM YYYY')}}<span class="txt_size_3"> ({{commonUI.formatDisplayDate(startDate, 'ddd')}})</span></mat-label>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> -->
                <div>
                    <mat-form-field appearance='fill' (click)="startPicker.open()">
                        <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('start')">
                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                        <mat-datepicker #startPicker (opened)="openAppend('start')"></mat-datepicker>
                    </mat-form-field>
                    <div style="display: none">
                        <div class="datepicker-footer" #startFooter>
                            <button mat-raised-button  (click)="today('start')" #todayButton>
                                {{'TODAY' | translate}}
                            </button>
                            <button mat-raised-button (click)="close('start')" #doneButton>
                                {{'close' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Old version -->
                <!-- <am-date-picker ng-model="startDate" am-show-input-icon="true" am-allow-clear="false"/> -->
            </div>

            <div class="fl input_field_medium btn_two_margin_left_m" *ngIf='takingunitList'>
                <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="leaveApplication.StartDateTakingUnit">
                    <option *ngFor="let takingunit of takingunitList" value="{{takingunit.Code}}">{{ takingunit.Display }}</option>
                </select>

            </div>

            <div style="clear: both"></div>
            <br/>

            <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.enddate' | translate }}</div>

            <div class="fl input_new_calendar">
                <div>
                    <mat-form-field appearance='fill' (click)="endPicker.open()">
                        <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" [min]='minDate' [max]='maxDate' readonly (dateInput)="dateValidator('end')">
                        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                        <mat-datepicker #endPicker (opened)="openAppend('end')"></mat-datepicker>
                    </mat-form-field>
                    <div style="display: none">
                        <div class="datepicker-footer" #endFooter>
                            <button mat-raised-button  (click)="today('end')" #todayButton>
                                {{'TODAY' | translate}}
                            </button>
                            <button mat-raised-button (click)="close('end')" #doneButton>
                                {{'close' | translate}}
                            </button>
                        </div>
                    </div>
                    <!-- <am-date-picker ng-model="endDate" am-show-input-icon="true" am-allow-clear="false"/> -->
                </div>
            </div>

            <div class="fl input_field_medium btn_two_margin_left_m">
                <select class="btn btn_dropdown_1 maincolor_btn_1" [(ngModel)]="leaveApplication.EndDateTakingUnit" *ngIf="commonUI.formatDisplayDate(startDate, 'DD MMM YYYY') != commonUI.formatDisplayDate(endDate, 'DD MMM YYYY')">
                    <option *ngFor="let takingunit of takingunitList" value="{{takingunit.Code}}">{{ takingunit.Display }}</option>
                </select>

            </div>


            <div style="clear: both"></div>
            <br />

            <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.description' | translate }}</div>
            <textarea name="description" class="form-control detail_staff_remark_input txt_box_1 txt_size_2" [(ngModel)]="leaveApplication.Description"  id="txtLeaveRemark"></textarea>
            <br />

            <div class="btn_sub_title txt_grey_2">{{ 'leaveapplication.attachment' | translate }}</div>
            <div class="fl input_field_small">
                <label class="btn btn-block btn-lg maincolor_btn_1">
                    <img src="assets/img/btn_add_files.png" width="18"> {{ 'leaveapplication.addfiles' | translate }}
                    <input type="file" id="file_UploadAttachment" multiple style="display: none;" (change)="uploadListening($event)" />
                </label>
            </div>
            <!--
            <div class="fl input_field_small">
                <a class="btn btn-block btn-lg maincolor_btn_1"><img src="assets/img/btn_take_photo.png" width="18">Take photo</a>
            </div>
            -->
            <div *ngFor="let attach of attachments">
                <div id="attach_{{attach.fileID}}">
                    <div style="clear: both"></div>
                    <br />

                    <div class="attachment_file_area bg_grey_4">

                        <div class="attachment_file_txt">
                            <div class="attachment_photo">
                                <img [src]="attach.dataURL">
                            </div>
                            <div>{{attach.file.name}}</div>
                            <div class="txt_grey_2 txt_size_3" *ngIf="attach.file.size">{{convertSizeFormat(attach.file.size)}}</div>
                            <div style="clear: both"></div>
                            <div *ngIf="fileProgress != 100">
                                <div class="bar_chart_data maincolor_bg2" [ngStyle]="{'width' : fileProgress + '%' }"></div><!-- sample uploading % -->
                            </div>
                        </div>

                        <div class="fr">
                            <div class="btn_attachment_position" (click)="downloadAttachment(attach)" id="btn_download_attach_{{attach.fileID}}">
                                <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img src="assets/img/btn_download.png" width="18">{{ 'download' | translate }}</a>
                            </div>
                            <div class="btn_attachment_position" (click)="uploadAttachment(attach)" id="btn_upload_attach_{{attach.fileID}}">
                                <a class="btn btn-block btn-lg maincolor_btn_1 tl"><img src="assets/img/btn_start_upload.png" width="18">{{ 'leaveapplication.upload' | translate }}</a>
                            </div>
                            <div class="btn_attachment_position" (click)="cancelAttachment(attach)" id="btn_cancel_attach_{{attach.fileID}}">
                                <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_cancel_upload.png" width="18">{{ 'leaveapplication.cancel' | translate }}</a>
                            </div>
                            <div class="btn_attachment_position" (click)="deleteAttachment(attach)" id="btn_delete_attach_{{attach.fileID}}">
                                <a class="btn btn-block btn-lg btn_grey tl"><img src="assets/img/btn_del_doc.png" width="18">{{ 'leaveapplication.delete' | translate }}</a>
                            </div>
                        </div>

                        <div style="clear: both"></div>

                    </div>
                    <br />
                </div>
            </div>
            <br />
            <br />

            <hr>

            <div class="inquiry_box_two_btn_center">
                <button id="btnPreviewApplication" class="btn btn-block btn-lg two_btn maincolor_btn2" (click)="showPreviewApplication()">
                    <img src="assets/img/btn_tick_w.png" width="18" />{{ 'submit' | translate }}
                </button>
                <button id="btnResetApplication" (click)="resetApplication()" class="btn btn-block btn-lg two_btn btn_grey" name="reset" type="reset">
                    <img src="assets/img/btn_reset_a.png" width="18" />{{ 'reset' | translate }}
                </button>
                <div style="clear: both;"></div>
            </div>
        </div>
        <!-- ============ end : box_content_inquiry ============ -->
    </div>


<!-- Migrate to common/popup-detail-of-check-balance-inquiry -->
<!--============ popup : detail of Check Balance Inquiry ============ -->
<!-- <div class="pop_functions_div pp5_content" id="pop_LeaveBalance"  >
	<div class="pop_functions_box pop_functions_box_size_2column mask_balance_inquiry">
    	<div class="pop_functions_close maincolor_btn2 pp_close" (click)="hideLeaveBalance()">x</div>

        <div class="detail_box_top d_box_top">
            <div class="detail_box_top_photo_mask d_box_top_photo_mask"></div>
        	<div class="detail_box_top_photo" [ngStyle]="{'background-image' : 'url(' + commonUI.getStaffImage(leaveBalance.StaffInfo) + ')'}"></div>
            <div class="detail_box_name">
              <span class="txt_size_1">{{leaveBalance.StaffInfo.FullName}}</span><br>
              <span class="txt_opacity_1">{{ leaveBalance.StaffInfo.PositionDesc }}</span><br>
              <span class="txt_size_3 txt_opacity_1 txt_popup_staffid">ID&nbsp;:&nbsp;{{ leaveBalance.StaffInfo.StaffID }}</span><br>
            </div>
        </div>

        <div class="detail_box_bottom_area detail_box_bottom_area_margin_right">

        	<div class="detail_box_bottom_2point_leave_balance"> -->
                <!--
                <div class="detail_box_catalog_area">
                	<div class="maincolor_bg color_label_pending">Pending</div>
    			</div>-->
                <!-- <div class="detail_box_session1 txt_grey_2 txt_size_3">&nbsp;</div>
                <div class="detail_box_date1 txt_size_1">{{commonUI.formatDisplayDate(leaveBalance.LeaveYearStart, 'D MMM YYYY')}}
                    <span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(leaveBalance.LeaveYearStart, '(ddd)')}}</span>
                </div>
                <div class="detail_box_session2 txt_grey_2 txt_size_3">{{ 'leaveapplication.cutoffday' | translate }}</div>
                <div class="detail_box_date2 txt_size_1">{{commonUI.formatDisplayDate(leaveBalance.LeaveYearEnd, 'D MMM YYYY')}}
                    <span class="txt_size_3">&nbsp;{{commonUI.formatDisplayDate(leaveBalance.LeaveYearEnd, '(ddd)')}}</span>
                </div>
                <div class="detail_box_sub_icon_txtonly d_box_sub_icon_leave"></div>
                <div class="detail_box_type_appointment">{{leaveapplication.LeaveTypeCode}}</div>
            </div>

            <div class="detail_box_other txt_size_1">

            	<div class="txt_grey_2 txt_size_3">{{ 'leaveapplication.cutoffdayasat' | translate }}</div>


                    <div class="fl input_new_calendar">
                    <div>
                        <mat-form-field appearance='fill' (click)="balancePicker.open()">
                            <input matInput [matDatepicker]="balancePicker" [(ngModel)]="asAtDate" [min]='minDate' [max]='maxDate' readonly >
                            <mat-datepicker-toggle matSuffix [for]="balancePicker"></mat-datepicker-toggle>
                            <mat-datepicker #balancePicker (opened)="openAppend('balance')"></mat-datepicker>
                        </mat-form-field>
                        <div style="display: none">
                            <div class="datepicker-footer" #balanceFooter>
                                <button mat-raised-button  (click)="today('balance')" #todayButton>
                                    {{'TODAY' | translate}}
                                </button>
                                <button mat-raised-button (click)="close('balance')" #doneButton>
                                    {{'close' | translate}}
                                </button>
                            </div>
                        </div> -->
                        <!-- <am-date-picker ng-model="asAtDate" am-show-input-icon="true" am-allow-clear="false" ng-change="calSelfLeaveBalance()" /> -->
                    <!-- </div>
                    </div> -->
                    <!--
                	<input id="datepicker-1" class="btn btn-block btn-lg btn_date maincolor_btn2" type="text" ng-attr-placeholder="31 Dec 2016 (Thu)" readonly>
                    <div><img src="assets/img/input_date.png" width="18"></div>
                -->
<!-- 
                 <a id="btnCutOffDate" class="btn btn_grey" click="getCutOffDateBalance()">
                 {{ 'leaveapplication.cutoffday' | translate }}
                 </a>
                 <div style="clear:both"></div>

                <div class="txt_grey_2 txt_size_3">{{ 'leaveapplication.availablebalance' | translate }}</div>
                {{leaveBalance.currentYearDetail.AvailableBalance}}<span class="txt_size_3">&nbsp;{{ 'balanceinquery.days' | translate }}</span>

            </div>

        </div> -->


        <!-- <div class="detail_box_bottom_area mt0_m"> -->

                <!--<div class="txt_grey_2 txt_size_3">Detail</div>-->
                <!-- <div class="btn_balance_inquiry_search_result"> -->
                        <!--
                        <div class="btn_balance_title"></div>
                        <div class="btn_balance_title_num txt_grey_1">2016</div>
                        <div style="clear:both"></div>
                        -->
                        <!-- <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'leaveapplication.openingbalance' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1">{{leaveBalance.currentYearDetail.OpenBalance}}</div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_inquiry_search_result_break"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.graceexpiry' | translate }}</div>

                        <div *ngIf="leaveBalance.currentYearDetail.GraceExpiry == '0001-01-01T00:00:00'" class="btn_balance_title_num txt_grey_1"><span>N.A.</span>
                        </div>
                        <div *ngIf="leaveBalance.currentYearDetail.GraceExpiry != '0001-01-01T00:00:00'" class="btn_balance_title_num txt_grey_1"><span>{{commonUI.formatDisplayDate(leaveBalance.currentYearDetail.GraceExpiry, 'D MMM YYYY (ddd)')}}</span>
                        </div>

                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.graceentitlement' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceEntitlement}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.gracetaken' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceTaken}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.gracebalance' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.GraceBalance}}</div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_inquiry_search_result_break"></div>

                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.entitlement' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Entitlement}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.adjustment' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Adjustment}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.taken' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.Taken}}</div>
                        <div style="clear:both"></div>
                        <div class="btn_balance_title txt_grey_2">{{ 'leaveapplication.othertakenincate' | translate }}</div>
                        <div class="btn_balance_title_num txt_grey_1">{{leaveBalance.currentYearDetail.OtherTaken}}</div>
                        <div style="clear:both"></div>

                        <div class="btn_balance_inquiry_search_result_break"></div>

                        <div class="btn_balance_title bg_grey_2c txt_grey_2">{{ 'leaveapplication.totalbalance' | translate }}</div>
                        <div class="btn_balance_title_num bg_grey_2c txt_grey_1">{{leaveBalance.currentYearDetail.TotalBalance}}</div> -->
                        <!--
                        <div class="btn_balance_title bg_grey_2b">Available Balance</div>
                        <div class="btn_balance_title_num bg_grey_2b">5</div>
                        -->
                        <!-- <div style="clear:both"></div>

                </div>

        </div>

	</div>
</div> -->


    <!-- end id=content_box -->
</div>