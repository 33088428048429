import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { PopupCompanySelectComponent } from '../popup-company-select/popup-company-select.component'

declare var $: any;

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  @Output() toggle = new EventEmitter<Boolean>();

  // Variables here
  overrideMenuList;
  toggleMenu = false
  title = "Menu";
  sessionId = sessionStorage.SessionID;
  usercode = sessionStorage.UserCode;
  employee = JSON.parse(sessionStorage.Employee);
  company = JSON.parse(sessionStorage.Company);
  companyList = JSON.parse(sessionStorage.companyList);
  selectedCompany = this.company;
  selectedEmployee = this.employee;
  staffInfo;
  _lastMenuCode = '';
  _lastSubMenuCode = '';
  _lastestCurrentMenu: any;
  menuList = [
    {
      seq: 1, menuCode: "Leave", displayName: "menu.leave", cssClass: "m_leave", menuList:
        [
          { seq: 1, menuCode: "LeaveApp", displayName: "menu.leaveapplication", url: "/leave-application", cssClass: "mm_application" },
          { seq: 2, menuCode: "LeaveBalInq", displayName: "menu.leavebalance", url: "/leave-balance-inquiry", cssClass: "mm_balanceinquiry" },
          { seq: 3, menuCode: "LeaveAppInq", displayName: "menu.leaveapplicationinquiry", url: "/leave-application-inquiry", cssClass: "mm_applicationinquiry" },
        ],
    },
    {
      seq: 2, menuCode: "EInOut", displayName: "menu.einout", cssClass: "m_inouttime", menuList:
        [
          { seq: 1, menuCode: "EInOutApp", displayName: "menu.inouttimeapplication", url: "/in-out", cssClass: "mm_application" },
          { seq: 2, menuCode: "EInOutAppInq", displayName: "menu.inouttimeapplicationinquiry", url: "/in-out-inquiry", cssClass: "mm_applicationinquiry" },
          { seq: 3, menuCode: "PunchInq", displayName: "menu.punchinquiry", url: "/punch-inquiry", cssClass: "mm_punch_inquiry" }
        ],
    },
    {
      seq: 3, menuCode: "Appointment", displayName: "menu.appointment", cssClass: "m_appointment", menuList:
        [
          { seq: 1, menuCode: "AppointmentApp", displayName: "menu.appointmentapplication", url: "/appointment", cssClass: "mm_application" },
          { seq: 2, menuCode: "AppointmentAppInq", displayName: "menu.appointmentapplicationinquiry", url: "/appointment-inquiry", cssClass: "mm_applicationinquiry" },
        ],
    },
    {
      seq: 4, menuCode: "Claim", displayName: "menu.claim", cssClass: "m_claim", menuList:
        [
          { seq: 1, menuCode: "ClaimApp", displayName: "menu.eclaim", url: "/eclaims", cssClass: "mm_claim_application" },
          { seq: 2, menuCode: "ClaimAppInq", displayName: "menu.eclaim-inquiry", url: "/eclaims-inquiry", cssClass: "mm_claim_application_inquiry" },
          { seq: 3, menuCode: "ReportClaim", displayName: "menu.reportclaim", url: "/report-e-claim", cssClass: "mm_payslip" },
        ]
    },
    {
      seq: 5, menuCode: "Calendar", displayName: "menu.calendar", cssClass: "m_calendar", menuList:
        [
          { seq: 1, menuCode: "Calendar", displayName: "menu.monthlyview", url: "/calendar", cssClass: "mm_claim_form_report" },
          { seq: 2, menuCode: "DeptAdminCalendar", displayName: "menu.calendardept", url: "/calendardept", cssClass: "mm_claim_department_maintenance" },
          { seq: 3, menuCode: "SectCalendar", displayName: "menu.calendardept", url: "calendarsect", cssClass: "mm_claim_department_maintenance" },
          { seq: 4, menuCode: "HorizontalCalendar", displayName: "menu.staffleaveview", url: "/calendar-staff-leave-view", cssClass: "mm_applicationinquiry" },
          { seq: 5, menuCode: "HorizontalDeptCalendar", displayName: "menu.staffleaveviewdept", url: "calendarstaffsviewdept", cssClass: "mm_claim_department_maintenance" },
        ],
    },
    {
      seq: 6, menuCode: "HTML5", displayName: "menu.eclaim", cssClass: "m_claim", url: "eclaim"
    },
    {
      seq: 7, menuCode: "SECMS", displayName: "menu.eclaim", cssClass: "m_claim", url: "eclaim-secms"
    },
    {
      seq: 8, menuCode: "PersonalProfile", displayName: "menu.personalprofile", cssClass: "m_profile", menuList:
        [
          { seq: 1, menuCode: "PersnlApp", displayName: "menu.personalprofilemyprofile", url: "/my-profile", cssClass: "mm_my_profile" },
          { seq: 2, menuCode: "PersnlAppInq", displayName: "menu.personalprofileinquiry", url: "/profile-amend-inquiry", cssClass: "mm_profile_amend_inquiry" },
        ],
    },
    {
      seq: 9, menuCode: "Report", displayName: "menu.report", cssClass: "m_report", menuList:
        [
          { seq: 1, menuCode: "ReportPayslip", displayName: "menu.reportpayslip", url: "/report-payslip", cssClass: "mm_payslip" },
          { seq: 2, menuCode: "ReportTaxForm", displayName: "menu.reporttaxform", url: "/report-tax-form", cssClass: "mm_taxform" },
          { seq: 3, menuCode: "ReportLeaveBalance", displayName: "menu.reportleavebalance", url: "report-leave-balance", cssClass: "mm_balanceinquiry" },
          { seq: 4, menuCode: "ReportTaxSummary", displayName: "menu.reporttaxsummary", url: "/report-tax-summary", cssClass: "mm_taxform" },
        ]
    },
    {
      seq: 10, menuCode: "ApprovalCentre", displayName: "menu.approvalcentre", cssClass: "m_approvalcentre", menuList:
        [
          { seq: 1, menuCode: "ACPenApv", displayName: "menu.pendingapproval", url: "/approval-center-pending-approval", cssClass: "mm_pendingapproval" },
          { seq: 2, menuCode: "ACOverApv", displayName: "menu.overridependingapproval", url: "/approval-center-override-approval", cssClass: "mm_pendingapproval" },
          { seq: 3, menuCode: "DlgSetting", displayName: "menu.delegationsetting", url: "/approval-center-delegation-settings", cssClass: "mm_delegation_setting" },
          // {seq:	2,menuCode:	"ACAppInq",displayName:	"menu.applicationinquiry", url:	"approval-application-inquiry",cssClass: "mm_applicationinquiry"},
          // {seq:	3,menuCode:	"ACApvLog",displayName:	"menu.approvallog", url:	"approval-log",cssClass: "mm_approvallog"},
        ]
    },
    {
      seq: 11, menuCode: "Delegation", displayName: "menu.delegation", cssClass: "m_leave_delegation_approval", menuList:
        [
          { seq: 1, menuCode: "DlgBalInq", displayName: "menu.leavebalance", url: "/delegation-balance-inquiry", cssClass: "mm_balanceinquiry" },
          { seq: 2, menuCode: "DlgAppInq", displayName: "menu.leaveapplicationinquiry", url: "/delegation-application-inquiry", cssClass: "mm_applicationinquiry" },
          { seq: 3, menuCode: "DlgPenApv", displayName: "menu.pendingapproval", url: "/delegation-pending-approval", cssClass: "mm_pendingapproval" }
          // {seq:	2,menuCode:	"ACAppInq",displayName:	"menu.applicationinquiry", url:	"approval-application-inquiry",cssClass: "mm_applicationinquiry"},
          // {seq:	3,menuCode:	"ACApvLog",displayName:	"menu.approvallog", url:	"approval-log",cssClass: "mm_approvallog"},
        ]
    },
    {
      seq: 12, menuCode: "TeamInfo", displayName: "menu.teaminfo", cssClass: "m_teaminfo", menuList:
        [
          { seq: 1, menuCode: "", displayName: "menu.birthday", url: "dashboard", cssClass: "mm_bday" },
          { seq: 2, menuCode: "", displayName: "menu.newjoins", url: "dashboard", cssClass: "mm_newjoins" },
          { seq: 3, menuCode: "", displayName: "menu.leavetakenreview", url: "dashboard", cssClass: "mm_LTR" },
          { seq: 4, menuCode: "", displayName: "menu.annualleavebalance", url: "dashboard", cssClass: "mm_ALB" }
        ]
    }
  ];

  staffMenu;

  constructor(public commonService: CommonServiceService, public matDialog: MatDialog, public commonUI: CommonUIService, public router: Router) { }

  ngOnInit(): void {

    // angular.forEach(this.overrideMenuList, function(value, key) {
    //   for (var i = 0; i < this.menuList.length; i++) {
    //       if (this.menuList[i].seq == value.seq) {
    //           this.menuList[i] = value;
    //       }
    //   }
    // });

    // new version of above code...
    // this.overrideMenuList.forEach(obj => {
    //   for (var i = 0; i < this.menuList.length; i++) {
    //     if (this.menuList[i].seq == obj.seq) {
    //         this.menuList[i] = obj;
    //     }
    //   }
    // });

    this.getMenu();
    this.getStaffInfo();

    // if ($location.search()['fr'] == "secms" && sessionStorage.IsSECMSEnable == "Y") {
    //   this.commonService.setCompany(JSON.parse(sessionStorage.Company), this.onSetCompanySuccess, this.onCallAPIFail);
    // }

  }

  //Mod at Citic
  // onSetCompanySuccess = function (service) {
  //     this.commonUI.finishLoadingTask();

  //     sessionStorage.Employee = JSON.stringify(this.selectedEmployee);
  //     sessionStorage.Person = JSON.stringify(this.selectedEmployee.Person);

  //     this.employee = this.selectedEmployee;

  //     sessionStorage.Company = JSON.stringify(this.selectedCompany);
  //     sessionStorage.Person = JSON.stringify(this.selectedCompany.Person);
  //     this.company = this.selectedCompany;
  //     this.person = this.company.Person;

  //     this.getMenu();
  //     this.getStaffInfo();


  //     if (sessionStorage.IsSECMSEnable == "Y") {
  //       this.commonUI.addLoadingTask();

  //         if (sessionStorage.SECMSSessionID == undefined)
  //           this.commonService.loginSECMS(this.employee, this.onLoginSECMSSuccess, this.onCallAPIFail);
  //         else
  //           this.commonService.setSECMSCompany(this.selectedCompany, this.onSetSECMSCompanySuccess, this.onCallAPIFail);
  //     } else {
  //         if ($state.current.name == "home.redirect") {
  //             $state.go("home.dashboard");
  //         } else {
  //             $state.go('home.redirect');
  //         }
  //     }
  // }

  // onLoginSECMSSuccess = function (service) {
  //     this.commonUI.finishLoadingTask();
  //     this.SECMSStaff = JSON.parse(sessionStorage.Staff);

  //     this.commonUI.addLoadingTask();
  //     this.commonService.setSECMSCompany(this.selectedCompany, this.onSetSECMSCompanySuccess, this.onCallAPIFail);
  // }

  // onSetSECMSCompanySuccess = function (service) {
  //     this.commonUI.finishLoadingTask();
  //     this.SECMSStaff = JSON.parse(sessionStorage.Staff);

  //     if ($state.current.name == "home.redirect") {
  //         $state.go("home.dashboard");
  //     } else {
  //         $state.go('home.redirect');
  //     }
  // }


  getStaffInfo() {
    this.commonUI.addLoadingTask();
    this.commonService.getStaffInfo(this.employee).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.staffInfo = result.body['Data'].StaffInfo;
        this.commonUI.finishLoadingTask();
        if (localStorage.RememberMe == "Y") {
          localStorage.ImagePath = this.commonUI.getStaffImage(this.staffInfo);
        } else {
          localStorage.ImagePath = "assets/img/photo_sample_big.png";
        }
      }
      else {
        this.commonUI.finishLoadingTask();
        this.onCallAPIFail(result.body, result.status, result.headers, result)
      }
    }, err => {
      this.commonUI.finishLoadingTask();
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  getMenu() {
    this.commonUI.addLoadingTask();
    this.commonService.getMenu(this.employee).subscribe((result) => {
      if (result.body != undefined) {
        this.staffMenu = result.body['Data'].Menu;
        this.commonUI.finishLoadingTask();
      }
      else
        this.staffMenu = result
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  showMenuItem(menucode: string) {
    const list = document.getElementsByClassName('btn_menu_list');
    let isAllClosed = true;
    Object.keys(list).forEach(key => {
      const target = list[key] as HTMLElement;
      let targetPos: DOMRect;
      if (target.id === `subMenu_${menucode}` && menucode !== this._lastSubMenuCode) {
        this._lastestCurrentMenu = target;
        const targetWrapper = document.getElementById(`subMenuWrapper_${menucode}`);
        target.style.display = 'block';
        targetPos = targetWrapper.getBoundingClientRect();
        target.style.height = '0px';
        setTimeout(() => {
          target.style.height = targetPos.height + 'px';
        });
        isAllClosed = false;
      } else {
        setTimeout(() => {
          target.style.display = 'none';
        }, 250);
        target.style.height = '0px';
      }
    });
    if (isAllClosed) {
      this._lastSubMenuCode = '';
    } else {
      this._lastSubMenuCode = menucode;
    }
    return;
  }

  // showMenuItem = function(menucode) {
  //   //console.log("in menu, showDropDownCompanyList");
  //   // sessionStorage.Company = JSON.stringify(company);
  //   // $('#dropdown_menu_company_list').fadeToggle();
  //   $('.btn_menu_list').not($('#subMenu_' + menucode)).slideUp();
  //   $('#subMenu_' + menucode).slideToggle(function () {
  //       $('#subMenu_' + menucode)[0].scrollIntoView({ block: "end", behavior: "smooth" });
  //   });
  // }

  showDropDownCompanyList = function () {
    //console.log("in menu, showDropDownCompanyList");
    // sessionStorage.Company = JSON.stringify(company);
    $('#popup_select_company').fadeToggle();
  }

  closeDropDownCompanyList = function () {
    //console.log("in menu, showDropDownCompanyList");
    // sessionStorage.Company = JSON.stringify(company);
    $('#popup_select_company').fadeOut();
  }

  showPopupSelectPosition = function (company) {
    //console.log("in menu, showDropDownCompanyList");
    //console.log("in menu, company="+ company);
    this.selectedCompany = company;
    // sessionStorage.Company = JSON.stringify(company);
    $('#dropdown_menu_company_list').fadeToggle();
    $('#popup_menu_select_position').fadeToggle();
    $('#popup_select_company').fadeOut();

  }

  closePopupSelectPosition = function () {
    $('#popup_menu_select_position').fadeOut();
  }

  showcloseMenu() {
    this.toggle.emit(!this.toggleMenu)
    this.toggleMenu = !this.toggleMenu
  }

  closeMenu() {
    this.toggle.emit(false)
  }

  openCompanySelect() {
    const dialog = this.matDialog.open(PopupCompanySelectComponent, {
      data: this.companyList,
      panelClass: 'dialog-responsive'
    })

    dialog.afterClosed().subscribe((data?) => {
      if (data) {
        this.commonService.refreshSessToScope(this);
        this.selectedCompany = this.company;
        this.selectedEmployee = this.employee;

        this.getMenu();
        this.getStaffInfo();
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['ess/dashboard']);
      }); 
      }
    })
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }

  findMenuIndx(menu) {
    const i = this.staffMenu.findIndex(x => x == menu)
    return i
  }

}
