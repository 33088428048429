<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no">
<meta name="apple-mobile-web-app-capable" content="yes" />
<meta name="description" content="">

<div class="login_box_abs cpw_box ng-scope">
    <form class="ng-pristine ng-valid" id="form_forgot_password">
        <div class="login_bg">
            <img src='assets/img/logo_fsl.png' height='40'>
        </div>

        <span class="txt_size_0 txt_grey_2">{{ 'Server Maintenance' | translate}}</span>
        <br>
        <br>
        <div class="login_txt_area">
            <div class="standalone">{{ 'The system maintenance will be carried out on Dec 1-2, 2019 (Saturday and Sunday)' | translate }}</div>
            <br>
            <img src="assets/img/msg_alert.png" width='80'/>
        </div>
        <br>
    </form>
</div>