import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonServiceService } from '../services/common-service.service';
import moment from 'moment'
import { CommonUIService } from '../services-UI/common-ui.service';
import { format } from 'date-fns';
import {
  CalendarEvent,
  CalendarDateFormatter,
  CalendarView,
} from 'angular-calendar';
import { CustomDateFormatter } from '../app.module';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PopupLeaveDetailComponent } from '../common/popup-leave-detail/popup-leave-detail.component';
import { PopupAppointmentDetailComponent } from '../common/popup-appointment-detail/popup-appointment-detail.component';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { Router } from '@angular/router';
import { IdleTimerService } from '../services/idle-timer.service';

declare var $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
  ],
})

export class CalendarComponent implements OnInit {

  events = [];
  calendarView = 'month';
  viewDate = moment().startOf('month').toDate();
  viewMonthList = [];
  employee = JSON.parse(sessionStorage.Employee);
  calendarScope;
  objList = [];
  resultList = [];
  sourceTypeList = environment.SourceTypeList.slice();
  cancelReason = "";
  reason = "";
  remarkPrefixApproved = "[Approved] ";
  remarkPrefixRejected = "[Rejected] ";

  leavePopupControl = {};
  approverRemarkList = {};
  appointmentPopupControl = {};

  leaveListCount = 0;
  leaveCancelListCount = 0;
  appointmentListCount = 0;
  claimListCount = 0;

  minMonth = {};
  maxMonth = {};

  search = {
    keywords: "",
    SourceType: ""
  };
  leavePopMode = "READONLY";
  appointmentPopMode = "READONLY";

  showSortsMenu = false;
  selectedView = "all";

  language = localStorage.CurrentLanguage !== undefined ? localStorage.CurrentLanguage : "en";
  locale: string = 'en'

  selectedLeaveApplication = {
    SourceType: 'ELEAVE',
    IsCancellable: true,
    IsWithdrawable: true,
    IsApprovable: true,
    RID: '',
  }

  StaffModule;

  constructor(public idleTimer: IdleTimerService, public commonService: CommonServiceService, public commonUI: CommonUIService, public translate: TranslateService, public matDiag: MatDialog, public errorUI: ErrorUIService, public router: Router) {
    this.translate.onLangChange.subscribe((lang) => {
      this.language = lang.lang
      this.locale = localStorage.CurrentLanguage == 'en' ? moment.locale('en') : moment.locale('zh-hk')
    })
    this.calendarScope = {
      teammateCalendarData: {
        LeaveList: [],
        AppointmentList: [],
        ECalendarDateList: []
      },
      selfCalendarData:{
        LeaveList: [],
        AppointmentList: [],
        ECalendarDateList: []
      }
    }
  }

  ngOnInit(): void {
    this.commonService.refreshSessToScope(this)
    var startMonth = moment(this.viewDate).add(-12, 'months').toDate();
    this.minMonth = startMonth;
    for (var i = 0; i < 24; i++) {
      this.viewMonthList.push(moment(startMonth).add(i, 'months').toDate());
    }
    this.maxMonth = this.viewMonthList[this.viewMonthList.length - 1];
    this.refreshCalendar();
    this.getModule();
  }

  goToPrevMonth() {
    this.hideSearchResult();
    this.viewDate = moment(this.viewDate).add(-1, 'months').toDate();
    this.refreshCalendar();
  }

  goToNextMonth() {
    this.hideSearchResult();
    this.viewDate = moment(this.viewDate).add(1, 'months').toDate();
    this.refreshCalendar();
  }

  refreshCalendar() {
    this.viewDate = new Date(this.viewDate)
    var month = moment(this.viewDate).get('months') + 1;
    var year = moment(this.viewDate).get("year");

    this.calendarScope.selfCalendarData = {};
    this.calendarScope.teammateCalendarData = {};

    this.getSelfCalendar(year, month);
    this.getTeammateCalendar(year, month);
  }

  onGetSelfCalendarSuccess(calendarData) {
    this.calendarScope.selfCalendarData = calendarData;
    this.commonUI.finishLoadingTask();
    return;
  }

  getSelfCalendar(year, month) {
    this.commonUI.addLoadingTask();
    this.commonService.getSelfCalendar(this.employee, year, month).subscribe((result) => 
    {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.onGetSelfCalendarSuccess(result.body['Data'].Calendar)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    }
    )
  }

  onGetTeammateCalendarSuccess(calendarData) {
    this.calendarScope.teammateCalendarData = calendarData;
    this.commonUI.finishLoadingTask();
    return;
  }

  getTeammateCalendar(year, month) {
    this.commonUI.addLoadingTask();
    this.commonService.getTeammateCalendar(this.employee, year, month).subscribe((result) => 
    {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.onGetTeammateCalendarSuccess(result.body['Data'].Calendar)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  stringToInt(s: string) {
    return parseInt(s)
  }

  getCalendarDayRecordCount(record) {
    var totalCount = 0;
    if (record != undefined) {
      if (this.selectedView == "appointment") {
        totalCount = record.ApprovedAppointmentCount + record.PendingAppointmentCount;
      } else if (this.selectedView == "leave") {
        totalCount = record.ApprovedLeaveCount + record.PendingLeaveCount;
      } else {
        totalCount = record.ApprovedAppointmentCount + record.ApprovedLeaveCount + record.PendingAppointmentCount + record.PendingLeaveCount;
      }
    }
    return totalCount;
  }

  setSelectedDay(dayIndex) {

    dayIndex = moment(dayIndex).format('D')
    this.commonUI.addLoadingTask();

    var s = moment(this.viewDate).date(dayIndex);
    this.objList = [];
    this.leaveListCount = 0;
    this.leaveCancelListCount = 0;
    this.appointmentListCount = 0;
    this.claimListCount = 0;

    /* Rewrite */
    this.calendarScope.selfCalendarData.LeaveList.forEach((record) => {
      if (s >= moment(record.StartDate).startOf('day') && s <= moment(record.EndDate).startOf('day')) {
        record.LeaveDateRange = this.commonUI.formatDisplayDate(record.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatDisplayDate(record.EndDate, 'D MMM YYYY (ddd)');
        this.objList.push(record);
        this.leaveListCount++;
      }
    })

    this.calendarScope.selfCalendarData.AppointmentList.forEach((record) => {
      if (record.AppointStart) record.StartDate = record.AppointStart;
      if (record.AppointEnd) record.EndDate = record.AppointEnd;

      if (s >= moment(record.StartDate).startOf('day') && s <= moment(record.EndDate).startOf('day')) {
        record.LeaveDateRange = this.commonUI.formatDisplayDate(record.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatDisplayDate(record.EndDate, 'D MMM YYYY (ddd)');
        this.objList.push(record);
        this.appointmentListCount++;
      }
    })

    this.calendarScope.teammateCalendarData.LeaveList.forEach((record) => {
      if (s >= moment(record.StartDate).startOf('day') && s <= moment(record.EndDate).startOf('day')) {
        record.LeaveDateRange = this.commonUI.formatDisplayDate(record.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatDisplayDate(record.EndDate, 'D MMM YYYY (ddd)');
        this.objList.push(record);
        this.leaveListCount++;
      }
    })

    this.calendarScope.teammateCalendarData.AppointmentList.forEach((record) => {
      if (record.AppointStart) record.StartDate = record.AppointStart;
      if (record.AppointEnd) record.EndDate = record.AppointEnd;

      if (s >= moment(record.StartDate).startOf('day') && s <= moment(record.EndDate).startOf('day')) {
        record.LeaveDateRange = this.commonUI.formatDisplayDate(record.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatDisplayDate(record.EndDate, 'D MMM YYYY (ddd)');
        this.objList.push(record);
        this.appointmentListCount++;
      }
    })

    this.objList.forEach((record) => {
      // if (record.AppointStart) record.StartDate = record.AppointStart;
      // if (record.AppointEnd) record.EndDate = record.AppointEnd;

      // record.LeaveDateRange = this.commonUI.formatJsonDate(record.StartDate, 'D MMM YYYY (ddd)') + " - " + this.commonUI.formatJsonDate(record.EndDate, 'D MMM YYYY (ddd)');

      record.keywords = "";
      record.keywords += record.LeaveDateRange + " ";
      record.keywords += record.LeaveTypeCode + " ";
      record.keywords += record.StaffInfo.FullName + " ";
      record.keywords += record.StaffInfo.PositionCode + " ";
      record.keywords += record.SourceType + " ";
      //record.cssClass = cssClass;
    })

    for (var i = 0; i < this.objList.length; i++) {
      this.objList[i].index = i;
    }

    this.resultList = this.objList

    let instance = this

    $('.days li div').removeClass('selected');
    $("#daymarker_" + dayIndex).addClass('selected');

    $(".list_result_area").fadeIn().animate({ opacity: 1, left: "0" }, 1000, "swing");
    $('html, body').animate({ scrollTop: $(".list_result_area").offset().top }, 1000, function () {
      instance.commonUI.finishLoadingTask();
    });
  }

  hideSearchResult() {
    $('html, body').animate({ scrollTop: 0 }, 1000);
    $(".list_result_area").animate({ opacity: 0, left: -$(".list_result_area").width() + "px" }, 1000).fadeOut();
    this.clearSelectedDay();
  }

  clearSelectedDay() {
    $('.days li div').removeClass('selected');
  }

  showSortsMenuToggle() {
    if (!this.showSortsMenu) {
      this.showSortsMenu = true;
    } else {
      this.showSortsMenu = false;
    }
  }

  propertyName = 'StaffInfo.FullName';
  reverse = false;
  sortBy(propertyName) {
    this.reverse = (this.propertyName === propertyName) ? this.propertyName === propertyName : false;
    this.propertyName = propertyName;

    this.showSortsMenu = false;
  };

  sortByType(propertyName: string) {
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
    this.propertyName = propertyName;
    switch(propertyName) {
          case 'StaffInfo.FullName':
              this.objList = this.objList.sort((a,b) => {
                  return a.StaffInfo.FullName.localeCompare(b.StaffInfo.FullName)
              })
              break;
          case 'StaffInfo.PositionCode':
              this.objList = this.objList.sort((a,b) => {
                return a.StaffInfo.PositionCode.localeCompare(b.StaffInfo.PositionCode)
              })
              break;
          case 'StartDate':
              this.objList = this.objList.sort((a,b) => {
                return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime()
              })
              break;
          case 'LeaveTypeDesc':
            this.objList = this.objList.sort((a,b) => {
                return a.LeaveTypeDesc.localeCompare(b.LeaveTypeDesc)
            })
            break;
    }
    if(this.reverse) this.objList = this.objList.slice().reverse()
    this.showSortsMenu = false;
}

  showAll() {
    this.selectedView = "all";
    this.showSearchResult('');

    setTimeout(function () {
      $('.days li .label_box_area').children('div.c_appointment').fadeIn();
      $('.days li .label_box_area').children('div.c_leave').fadeIn();
    }, 200);
  }

  showLeave = function () {
    this.selectedView = "leave";
    this.showSearchResult('ELEAVE');

    setTimeout(function () {
      $('.days li .label_box_area').children('div.c_appointment').fadeOut();
      $('.days li .label_box_area').children('div.c_leave').fadeIn();
    }, 200);
  }

  showAppointment = function () {
    this.selectedView = "appointment";
    this.showSearchResult('APPOINTMENT');

    setTimeout(function () {
      $('.days li .label_box_area').children('div.c_appointment').fadeIn();
      $('.days li .label_box_area').children('div.c_leave').fadeOut();
    }, 200);
  }

  showSearchResult(selectedSourceType) {
    this.search.SourceType = selectedSourceType;
  }

  showPopupLeaveDetail(objIndex) {
    let hasSwiped = false
    let multiMode = false

    var obj = this.objList[objIndex];
    if (!hasSwiped) {
      if (multiMode) {
        // if (this.objList[objIndex].checked === undefined || !this.objList[objIndex].checked) this.objList[objIndex].checked = true;
        // else if (this.objList[objIndex].checked) this.objList[objIndex].checked = false;
      } else if (!hasSwiped && !multiMode) {
        this.selectedLeaveApplication = obj;

        var isLeaveCancelApp = this.StaffModule.find(o => o == 'LeaveCancelApp')
        this.leavePopMode = "READONLY";
        if ( this.selectedLeaveApplication.IsCancellable) this.leavePopMode = "CANCEL"; //isLeaveCancelApp &&
        if (this.selectedLeaveApplication.IsWithdrawable) this.leavePopMode = "WITHDRAW";
        if (this.selectedLeaveApplication.IsApprovable) this.leavePopMode = "APPROVAL";

        // if (this.selectedLeaveApplication.SourceType == "ELEAVE") {
        //   var isLeaveCancelApp = this.StaffModule.find(o => o == 'LeaveCancelApp')
        //   this.leavePopMode = "READONLY";
        //   if ( this.selectedLeaveApplication.IsCancellable) this.leavePopMode = "CANCEL"; //isLeaveCancelApp &&
        //   if (this.selectedLeaveApplication.IsWithdrawable) this.leavePopMode = "WITHDRAW";
        //   if (this.selectedLeaveApplication.IsApprovable) this.leavePopMode = "APPROVAL";
        // }

        this.commonUI.addLoadingTask();
        // $scope.commonService.getApproverRemarkList($scope.selectedLeaveApplication.RID, "ELEAVE", $scope.selectedLeaveApplication.StartDate, $scope.onGetApproverRemarkListSuccess, $scope.onCallAPIFail);
        var rid = this.selectedLeaveApplication.RID;
        this.commonService.getApproverRemarkList(rid, this.selectedLeaveApplication.SourceType, this.selectedLeaveApplication['StartDate']).subscribe((result) => 
        {
          
          if(!result['conn_fail'])
            if(result.body['ErrorMsg'] == null) {
              this.onGetApproverRemarkListSuccess(result.body['Data'].ApproverRemarkList)
            }
            else
              this.onCallAPIFail(result.body, result.status, result.headers, result)
          else 
            this.onCallAPIFail(result.body, result.status, result.headers, result)
        },err => {
          this.onCallAPIFail(err.body, err.status, err.headers, err)
        })
      }
    }
  }

  onGetApproverRemarkListSuccess(remarkList) {
    this.approverRemarkList = this.commonService.getApproverRemarkTranslateList(remarkList);

    if (this.selectedLeaveApplication.SourceType == "ELEAVE") {
      const dialog = this.matDiag.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: this.leavePopMode
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm)
          this.resetSearchResult()
      })
    } else if (this.selectedLeaveApplication.SourceType == "LEAVECAN") {
      const dialog = this.matDiag.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: this.leavePopMode
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm)
          this.resetSearchResult()
      })
    } else if (this.selectedLeaveApplication.SourceType == "APPOINTMENT") {
      if(this.leavePopMode == 'WITHDRAW') this.leavePopMode = 'WITHDRAWALNOEDIT'
      const dialog = this.matDiag.open(PopupAppointmentDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popupmode: this.leavePopMode
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm)
          this.resetSearchResult()
      })
    } else if (this.selectedLeaveApplication.SourceType == "CLAIMHDR") {
        // $scope.claimListCount = resultList.length;
    } else {
      const dialog = this.matDiag.open(PopupLeaveDetailComponent, {
        panelClass: 'dialog-responsive',
        data: {
          app: this.selectedLeaveApplication,
          approver_remark_list: this.approverRemarkList,
          popmode: this.leavePopMode
        }
      })
      dialog.afterClosed().subscribe((data?) => {
        if(data == undefined || data == null) return;
        if(data.confirm)
          this.resetSearchResult()
      })
    }
    // $scope.leavePopupControl.showPopup($scope.selectedLeaveApplication);
    this.commonUI.finishLoadingTask();
}

  getModule() {
    this.commonUI.addLoadingTask()
    this.commonService.getModule(this.employee).subscribe((result) => 
    {
      
      if(!result['conn_fail'])
        if(result.body['ErrorMsg'] == null) {
          this.onGetModuleSuccess(result.body['Data'].Module)
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      else 
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    },err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  onGetModuleSuccess(module) {
    this.StaffModule = module;
    this.commonUI.finishLoadingTask();
}

resetSearchResult() {
  this.hideSearchResult();
  this.refreshCalendar();
}

searchByInput() {
  if(this.search.keywords.length <= 0) return this.objList = this.resultList
  else this.objList = this.resultList.filter((l) => l.keywords.toLowerCase().includes(this.search.keywords.toLowerCase()))
}

onCallAPIFail(data, status, headers, config) {
  this.commonService.onCallAPIFail(data, status, headers, config);
}

}
