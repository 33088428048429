<div class="title_reset">
    <span class="txt_size_1 txt_grey_2">{{ 'app.name' | translate }}</span>
    <p>
    <span class="txt_grey_2"> {{ 'dashboard.lastlogin' | translate }} : </span><span>{{ lastLoginString }}</span><span class="txt_grey_2">.</span>
    </p>
</div>
<!--
        <div class="content_box">--> <!-- ============ home box  ============ -->
<div style="text-align: center;">

    <!-- home box -->
    <div [ngClass]="outstandingApprClass" *ngIf="dispalyApprovalBox">
    <div *ngIf="loadCounterForPending > 0" class="box_1_loading">
        <div class="txt_grey_2">Loading...</div>
    </div>

    <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
        <div class="box_title_icon t_outstanding"></div>
        <div class="fl">{{ 'dashboard.outstandingapproval' | translate }}</div>
        <div class="fr title_bar_icon" title="Refresh" (click)="reloadPending()">
        <img src="assets/img/btn_refresh.png" width="20">
        </div>
    </div>

    <!-- <div class="box_title_sub maincolor_txt">Sales Department 2016</div> -->
    <br />
    <!-- circle -->
    <div class="page txt_size_2" *ngIf='sourceTypeList'>
        <div class="clearfix">
        <div class="box_circle" *ngFor="let sourceType of sourceTypeList" (click)="gotoPendingApproval(sourceType.sourceTypeCode)">
                <div class="c100 p{{ math.round(sourceType.numOfPending / totalCount * 100) }} small orange">
                    <div class="txt_circle maincolor_txt">{{sourceType.numOfPending}}</div>
                    <div class="slice">
                        <div class="bar"></div>
                        <div class="fill"></div>
                    </div>
                    </div>
                    <div style="clear: both;"></div>
                    <div class="txt_box_label_name">{{ sourceType.displayNameForDashboard | translate }}</div>
        </div>


        <!-- SECMS circle -->
        <div class="box_circle" *ngIf="displaySECMSApprovalBox" (click)="gotoSECMSPendingApproval()">
            <div class="c100 p{{ numOfSECMSApproval / numOfSECMSApproval * 100 | number:0 }} small orange">
                <div class="txt_circle maincolor_txt">{{numOfSECMSApproval}}</div>
                <div class="slice">
                    <div class="bar"></div>
                    <div class="fill"></div>
                </div>
            </div>
            <div style="clear: both;"></div>
            <div class="txt_box_label_name">{{ 'dashboard.expenses' | translate }}</div>
        </div>
        <!-- End SECMS circle-->
        <!-- end box_grid_1 -->

        <div style="clear: both;"></div>

        <br />
        <div class="box_1_bottom_text txt_grey_2 txt_size_3" *ngIf='lastRefreshTime'>{{ 'dashboard.lastupdate' | translate }} : {{lastRefreshTime}}</div>

        </div>
    </div>
    </div>

    <div class="box_1 box_h_2" *ngIf="dispalyDelegationApprovalBox">
        <div *ngIf="loadCounterForDelegationPending > 0" class="box_1_loading">
            <div class="txt_grey_2">Loading...</div>
        </div>

        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_delegationsetting"></div>
            <div class="fl">{{ 'dashboard.outstandingdelegationapproval' | translate }}</div>
            <div class="fr title_bar_icon" title="Refresh" (click)="reloadDelegationPending()">
                <img src="assets/img/btn_refresh.png" width="20">
            </div>
        </div>

        <!-- <div class="box_title_sub maincolor_txt">Sales Department 2016</div> -->
        <br />
        <!-- circle -->
        <div class="page txt_size_2">
            <div class="clearfix">
                <div class="box_circle" (click)="gotoDelegationPendingApproval('ELEAVE')">
                    <div class="c100 p{{ totalDelegationCount / totalDelegationCount * 100.0 | number:0 }} small orange">
                        <div class="txt_circle maincolor_txt">{{totalDelegationCount}}</div>
                        <div class="slice">
                            <div class="bar"></div>
                            <div class="fill"></div>
                        </div>
                    </div>
                    <div style="clear: both;"></div>
                    <div class="txt_box_label_name">{{ 'dashboard.leave' | translate }}</div>
                </div>
                <!-- end box_grid_1 -->

                <div style="clear: both;"></div>

                <br />
                <div class="box_1_bottom_text txt_grey_2 txt_size_3" *ngIf='lastDelegationRefreshTime'>{{ 'dashboard.lastupdate' | translate }} : {{lastDelegationRefreshTime}}</div>

            </div>
        </div>
    </div>

    <div class="box_1 box_h_2">
        <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
            <div class="box_title_icon t_systemintro"></div>
            <div class="fl">{{ 'toolbar.introtohrms' | translate }}</div>
        </div>

        <div class="box_title_sub maincolor_txt"></div>
        <br/>

        <div (swipeLeft)="goToNextIntro()" (swipeRight)="goToPrevIntro()" class="intro_area_img_home">
            <div class="imgs" [ngStyle]="{ 'transform': mytransform }">
                <img *ngFor="let image of introImageList" [src]="'assets/img/' + language + '/' + image " alt="">
            </div>
        </div>

        <div class="intro_area">
            <button *ngIf="currentIntroImage > 0" (click)="goToPrevIntro()" ><img src="assets/img/btn_arrow_back.png" width="20" alt=""></button>
            <button *ngIf="currentIntroImage < introImageList.length-1" (click)="goToNextIntro()" ><img src="assets/img/btn_arrow_right.png" width="20" alt=""></button>
        </div>

        <div class="img_counter txt_grey_2">{{ currentIntroImage + 1 }}/{{ introImageList.length }}</div>

<!--
        <div class="intro_area_img_home">
            <div class="imgs" style="transition-duration: 0.5s; transform: translate(0px, 0px);">
                <img id="image1" src="assets/img/intro_1.png" alt="">
                <img id="image2" src="assets/img/intro_2.png" alt="">
                <img id="image3" src="assets/img/intro_3.png" alt="">
                <img id="image4" src="assets/img/intro_4.png" alt="">
                <img id="image5" src="assets/img/intro_5.png" alt="">
            </div>
        </div>


        <div class="intro_area">
            <button class="t_left" onclick="changeIt('image1');"><img src="assets/img/btn_arrow_back.png" width="20" alt=""></button>
            <button class="t_right" onclick="changeIt('image2');"><img src="assets/img/btn_arrow_right.png" width="20" alt=""></button>
        </div>

        <div class="img_counter txt_grey_2"></div>
-->

    </div><!-- ============ end box 1 ============ -->

    <!-- home box -->
    <!--
    <div class="box_1 box_h_1">
    <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
        <div class="box_title_icon t_bday"></div>
        <div class="fl">Birthday</div>
    </div>
    <div class="box_title_sub maincolor_txt">Oct 2016</div>
    <div class="box_1_fix_width txt_size_2">

        <div class="box_grid_1 btn_opacity">
        <div class="txt_photo_area_big" style="background-image: url(assets/img/pp3.png);">
            <div class="txt_photo_mask_big p_normal"></div>
        </div>
        <div style="clear: both;"></div>
        <div class="txt_box_label_name">
            Veronica<br />Kwok
        </div>
        </div>

        <div class="box_grid_1 btn_opacity">
        <div class="txt_photo_area_big" style="background-image: url(assets/img/pp2.png);">
            <div class="txt_photo_mask_big p_normal"></div>
        </div>
        <div style="clear: both;"></div>
        <div class="txt_box_label_name">
            Anderson<br />Leung
        </div>
        </div>

        <div class="box_grid_1 btn_opacity">
        <div class="txt_photo_area_big" style="background-image: url(assets/img/pp5.png);">
            <div class="txt_photo_mask_big p_normal"></div>
        </div>
        <div style="clear: both;"></div>
        <div class="txt_box_label_name">
            Cameron<br />Hung
        </div>
        </div>
        <div style="clear: both;"></div>
    </div>
    <div class="box_more txt_size_2">
        More&nbsp;<img src="assets/img/btn_more.png" width="20" />
    </div>
    </div>
    -->
    <!-- ============ end box 1 ============ -->


    <div style="clear: both;"></div>
    <!-- break box -->


    <!-- home box -->
    <!--
    <div class="box_1 box_h_2">
    <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
        <div class="box_title_icon t_newjoins"></div>
        <div class="fl">New Joins</div>
    </div>
    <div class="box_title_sub maincolor_txt">Oct 2016</div>

    <div class="box_1_fix_width txt_size_2">

        <div class="box_1_staff btn_opacity">
        <div class="txt_photo_area_big fl" style="background-image: url(assets/img/pp4.png);">
            <div class="txt_photo_mask_big p_normal"></div>
        </div>
        <div class="box_1_staff_name">
            <span class="txt_size_2">Seraphina Cheung</span><br> <span class="txt_size_3 txt_grey_2">Assistant Designer</span>
        </div>
        </div>

        <div class="box_1_staff btn_opacity">
        <div class="txt_photo_area_big fl" style="background-image: url(assets/img/pp5.png);">
            <div class="txt_photo_mask_big p_normal"></div>
        </div>
        <div class="box_1_staff_name">
            <span class="txt_size_2">Cameron Lam</span><br> <span class="txt_size_3 txt_grey_2">Senior Programer</span>
        </div>
        </div>

        <div class="box_1_staff btn_opacity">
        <div class="txt_photo_area_big fl" style="background-image: url(assets/img/pp6.png);">
            <div class="txt_photo_mask_big p_normal"></div>
        </div>
        <div class="box_1_staff_name">
            <span class="txt_size_2">Elizabeth Chan</span><br> <span class="txt_size_3 txt_grey_2">Accounting Accountant</span>
        </div>
        </div>

    </div>
    <div class="box_more txt_size_2">
        More&nbsp;<img src="assets/img/btn_more.png" width="20" />
    </div>

    </div>
    -->
    <!-- ============ end box 1 ============ -->

    <!-- home box -->
    <!--
    <div class="box_1 box_h_2">
    <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
        <div class="box_title_icon t_notice"></div>
        <div class="fl">Notice</div>
    </div>
    <div class="box_title_sub maincolor_txt">Oct 2016</div>
    <div class="txt_size_2">

        <div class="notice_txt_touch pp4">
        <span>Alice Kwok applied a new medical claim</span> <br>
        <span class="txt_label bc_blue txt_size_3">Claim</span>&nbsp;<span class="txt_grey_2 txt_size_3">11 Oct 2016 (Thu) - 10:23am</span>
        </div>

        <div class="notice_txt_touch pp4">
        <span>Bobo Chan approved your annual leave</span> <br>
        <span class="txt_label bc_purple txt_size_3">Leave</span>&nbsp;<span class="txt_grey_2 txt_size_3">11 Oct 2016 (Thu) - 11:10am</span>
        </div>

        <div class="notice_txt_touch pp4">
        <span class="txt_grey_2">John Lee applied a new sick leave</span> <br>
        <span class="txt_label bc_purple txt_size_3">Leave</span>&nbsp;<span class="txt_grey_2 txt_size_3">10 Oct 2016 (Wed) - 1:20pm</span>
        </div>

        <div class="notice_txt_touch">
        <span class="txt_grey_2">System maintenance completed</span> <br>
        <span class="txt_label bc_brown txt_size_3">System</span>&nbsp;<span class="txt_grey_2 txt_size_3">9 Oct 2016 (Tue) - 5:45pm</span>
        </div>

    </div>
    <div class="box_more txt_size_2">
        More&nbsp;<img src="assets/img/btn_more.png" width="20" />
    </div>
    </div>
    -->
    <!-- ============ end box 1 ============ -->


    <div style="clear: both;"></div>
    <!-- break box -->


    <!-- home box -->
    <!--
    <div class="box_1 box_h_3">
    <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
        <div class="box_title_icon t_teamLTR"></div>
        <div class="fl">Team Leave Taken Review</div>
    </div>
    <div class="box_title_sub maincolor_txt">Sales Department 2016</div>
    <br />
    <ul class="tabs">
        <li class="btn_linestyle maincolor_btn" data-tab="tab-1">2015</li>
        <li class="btn_linestyle maincolor_btn maincolor_btn-selected" data-tab="tab-2">2016</li>
        <li class="btn_linestyle maincolor_btn" data-tab="tab-3">2017</li>
    </ul>

    <div style="clear: both;"></div>
    <br />


    <div id="tab-1" class="tab-content bar_h_chart_area txt_size_2">
        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 75%;">
            <span class="txt_size_1 txt_grey_1">10.5</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 75%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Annual<br />Leave
        </div>
        </div>

        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 25%;">
            <span class="txt_size_1 txt_grey_1">3.5</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 25%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Sick<br />Leave
        </div>
        </div>

        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 100%;">
            <span class="txt_size_1 txt_grey_1">14</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 100%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Compensation<br />Leave
        </div>
        </div>
    </div>


    <div id="tab-2" class="maincolor_btn-selected tab-content bar_h_chart_area txt_size_2">
        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 50%;">
            <span class="txt_size_1 txt_grey_1">7</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 50%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Annual<br />Leave
        </div>
        </div>

        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 100%;">
            <span class="txt_size_1 txt_grey_1">14</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 100%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Sick<br />Leave
        </div>
        </div>

        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 75%;">
            <span class="txt_size_1 txt_grey_1">10.5</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 75%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Compensation<br />Leave
        </div>
        </div>
    </div>


    <div id="tab-3" class="tab-content bar_h_chart_area txt_size_2">
        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 100%;">
            <span class="txt_size_1 txt_grey_1">14</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 100%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Annual<br />Leave
        </div>
        </div>

        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 25%;">
            <span class="txt_size_1 txt_grey_1">3.5</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 25%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Sick<br />Leave
        </div>
        </div>

        <div class="bar_h_chart_item btn_opacity">
        <div class="bar_h_chart_data">
            <div class="bar_h_chart_bar_day" style="bottom: 50%;">
            <span class="txt_size_1 txt_grey_1">7</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            </div>
            <div class="bar_h_chart_bar maincolor_bg2" style="height: 50%;"></div>
        </div>
        <div class="bar_h_chart_name txt_grey_1">
            Compensation<br />Leave
        </div>
        </div>
    </div>


    </div>
    -->
    <!-- ============ end box 1 ============ -->

    <!-- home box -->
    <!--
    <div class="box_1 box_h_3">
    <div class="box_title maincolor_txt maincolor_border_bottom txt_size_1">
        <div class="box_title_icon t_teamALB"></div>
        <div class="fl">Team Annual Leave Balance</div>
    </div>
    <div class="box_title_sub maincolor_txt">Sales Department 2016</div>
    <div class="txt_size_2">
        <br />
        <br />

        <div class="bar_chart_area btn_opacity txt_grey_1 pp5">
        <div class="bar_chart_name">
            Veronica<br />Kwok
        </div>
        <div class="bar_chart_photo">
            <div class="txt_photo_area_small" style="background-image: url(assets/img/pp3.png);">
            <div class="txt_photo_mask_small p_normal"></div>
            </div>
        </div>
        <div class="bar_chart_item">
            <div class="bar_chart_data maincolor_bg2" style="width: 50%;"></div>
            <div class="bar_chart_num">
            <span class="txt_size_1">7</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            <div class="bar_chart_num_arrow"></div>
            </div>
        </div>
        </div>

        <div style="clear: both"></div>

        <div class="bar_chart_area btn_opacity txt_grey_1 pp5">
        <div class="bar_chart_name">
            Anderson<br />Leung
        </div>
        <div class="bar_chart_photo">
            <div class="txt_photo_area_small" style="background-image: url(assets/img/pp2.png);">
            <div class="txt_photo_mask_small p_normal"></div>
            </div>
        </div>
        <div class="bar_chart_item">
            <div class="bar_chart_data maincolor_bg2" style="width: 100%"></div>
            <div class="bar_chart_num">
            <span class="txt_size_1">14</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            <div class="bar_chart_num_arrow"></div>
            </div>
        </div>
        </div>

        <div style="clear: both"></div>

        <div class="bar_chart_area btn_opacity txt_grey_1 pp5">
        <div class="bar_chart_name">
            Candy<br />Wong
        </div>
        <div class="bar_chart_photo">
            <div class="txt_photo_area_small" style="background-image: url(assets/img/pp1.png);">
            <div class="txt_photo_mask_small p_normal"></div>
            </div>
        </div>
        <div class="bar_chart_item">
            <div class="bar_chart_data maincolor_bg2" style="width: 75%;"></div>
            <div class="bar_chart_num">
            <span class="txt_size_1">10.5</span>&nbsp;<span class="txt_grey_2 txt_size_3">days</span>
            <div class="bar_chart_num_arrow"></div>
            </div>
        </div>
        </div>

        <div style="clear: both"></div>

    </div>
    <div class="box_more txt_size_2">
        More&nbsp;<img src="assets/img/btn_more.png" width="20" />
    </div>
    </div>
    -->
    <!-- ============ end box 1 ============ -->


    <div style="clear: both;"></div>
    <!-- break box -->

</div>
<!-- home box --> <!--
    </div>  end id=content_box -->