import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonUIService } from '../services-UI/common-ui.service';
import { ErrorUIService } from '../services-UI/error-ui.service';
import { InterceptorService } from './interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public http: HttpClient, public translate: TranslateService, public interceptor: InterceptorService) { }

  getTeamAppointmentApplicationList(employee, startDate, endDate, status) {

    if (status == 'All') status = "";

    var body = {
      "Action": environment.apiList.GetTeammateAppointmentList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "Status": status
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result


    // var service = this;
    // if (status == "All") status = "";

    // var actionSuccess = function (data) {
    //   if (onSuccessCallback != undefined) {
    //     onSuccessCallback(service, data.Data.AppointmentList);
    //   }
    // }

    // var actionFail = function (data, status, headers, config) {
    //   if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //   return;
    // }

    // this.commonService.JSONPostService(
    //   JSON.stringify({
    //     "Action": apiList.GetTeammateAppointmentList,
    //     "SessionID": sessionStorage.SessionID,
    //     "EmploymentRID": employee.RID,
    //     "StartDate": startDate,
    //     "EndDate": endDate,
    //     "Status": status
    //   })
    // ).then(
    //   function (response) {
    //     actionSuccess(response.data);
    //   }
    //   ,
    //   function (response) {
    //     actionFail(response.data, response.status, response.headers, response.config);
    //   }
    // );
  }

  submitAppointmentApplication(appointments) {

    var body = {
      "Action": environment.apiList.SubmitAppointmentApplication,
      "SessionID": sessionStorage.SessionID,
      "AppointmentFormList": JSON.stringify(appointments)
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result


    // var service = this;

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.SubmitAppointmentApplication,
    //         "SessionID": sessionStorage.SessionID,
    //         "AppointmentFormList": JSON.stringify(appointments)
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

  getSelfAppointmentApplicationList(employee, startDate, endDate, status) {
    if (status == "All") status = "";

    var body = {
      "Action": environment.apiList.GetSelfAppointmentList,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.RID,
      "StartDate": startDate,
      "EndDate": endDate,
      "Status": status
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    //   var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //       onSuccessCallback(service, data.Data.AppointmentList);
    //     }
    //   }

    //   var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    //   }

    //   commonService.JSONPostService(
    //     JSON.stringify({
    //       "Action": apiList.GetSelfAppointmentList,
    //       "SessionID": sessionStorage.SessionID,
    //       "EmploymentRID": employee.RID,
    //       "StartDate": startDate,
    //       "EndDate": endDate,
    //       "Status": status
    //     })
    //   ).then(
    //     function (response) {
    //       actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //       actionFail(response.data, response.status, response.headers, response.config);
    //     }
    //   );
  }

  withdrawAppointmentApplication(employee, appointment) {
    var service = this;

    var body = {
      "Action": environment.apiList.WithdrawAppointmentApplication,
      "SessionID": sessionStorage.SessionID,
      "EmploymentRID": employee.EmploymentRID,
      "AppointmentRID": appointment.RID,
    }

    const result = this.http.post(environment.serverHost + '/DO', body, { observe: 'response' }).pipe(tap(res => {
      res['conn_fail'] = false
      return res
    }, err => {
      err['conn_fail'] = true
      return err
    }))

    return result

    // var actionSuccess = function (data) {
    //     if (onSuccessCallback != undefined) {
    //         onSuccessCallback(service);
    //     }
    // }

    // var actionFail = function (data, status, headers, config) {
    //     if (onFailCallback != undefined) onFailCallback(data, status, headers, config);
    //     return;
    // }

    // commonService.JSONPostService(
    //     JSON.stringify({
    //         "Action": apiList.WithdrawAppointmentApplication,
    //         "SessionID": sessionStorage.SessionID,
    //         "EmploymentRID": employee.RID,
    //         "AppointmentRID": appointment.RID,
    //     })
    // ).then(
    //     function (response) {
    //         actionSuccess(response.data);
    //     }
    //     ,
    //     function (response) {
    //         actionFail(response.data, response.status, response.headers, response.config);
    //     }
    // );
  }

}
