import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { CommonServiceServiceMod } from 'src/app/services-mod/common-service-mod.service';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { ErrorUIService } from 'src/app/services-UI/error-ui.service';
import { ApprovalService } from 'src/app/services/approval.service';
import { IdleTimerService } from 'src/app/services/idle-timer.service';

@Component({
  selector: 'app-dashboard-mod',
  templateUrl: './dashboard-mod.component.html',
  styleUrls: ['./dashboard-mod.component.scss']
})
export class DashboardModComponent extends DashboardComponent {

  sourceTypeList = this.commonService.SourceTypeList.slice();

  constructor(public commonService: CommonServiceServiceMod, public translate: TranslateService, public commonUI: CommonUIService, public errorUI: ErrorUIService, public router: Router, public approvalService: ApprovalService, public idleTimer: IdleTimerService) {
    super(commonService, translate, commonUI, errorUI, router, approvalService, idleTimer)
  }


}
