import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUIService } from 'src/app/services-UI/common-ui.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-popup-detail-of-check-balance-inquiry',
  templateUrl: './popup-detail-of-check-balance-inquiry.component.html',
  styleUrls: ['./popup-detail-of-check-balance-inquiry.component.scss']
})
export class PopupDetailOfCheckBalanceInquiryComponent implements OnInit {
  
  @ViewChild('balanceFooter', {static: false}) balanceFooter: ElementRef;
  @ViewChild('balancePicker', {static: false}) balancePicker: MatDatepicker<any>;

  /* Datepicker */
  minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  /*********** */
  leaveBalance;
  asAtDate;
  leaveapplication;
  employee = JSON.parse(sessionStorage.Employee);
  StaffInfo;
  leaveTypeCode;

  constructor(public matDialogRef: MatDialogRef<PopupDetailOfCheckBalanceInquiryComponent>, public commonUI: CommonUIService, @Inject(MAT_DIALOG_DATA) public balance: {}, public commonService: CommonServiceService, public leaveService: LeaveService) { 
    this.leaveBalance = balance['lb']
    this.asAtDate = balance['asAtDate']
    this.leaveapplication = balance['ap']
    this.leaveTypeCode = balance['ltc']
    if(this.leaveapplication) this.StaffInfo = this.leaveapplication.StaffInfo
    else this.StaffInfo = balance['staffInfo']
  }

  ngOnInit(): void {
    this.commonUI.finishLoadingTask();
  }

  close() {
    try {
      const returnVal = this.asAtDate
      this.matDialogRef.close(returnVal)
    } catch {}
  }

  /* Datepicker Footer */

  today() {
    this.asAtDate = new Date()
    this.balancePicker.close()
  }

  closePicker() {
    this.balancePicker.close()
  }

  openAppend() {
      const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
      matCalendar.appendChild(this.balanceFooter.nativeElement);
  }

  /*******************/

  getCutOffDateBalance() {
    this.commonUI.addLoadingTask();
    var employee = {
      RID: this.leaveapplication.StaffInfo.EmploymentRID,
      StaffID: this.leaveapplication.StaffInfo.StaffID,
      Position: this.leaveapplication.StaffInfo.PositionDesc
    }
    this.leaveService.getLeaveCutOffDate(employee, this.leaveapplication.LeaveTypeCode, new Date()).subscribe((result) => {
      if (result.body['ErrorMsg'] == null) {
        this.asAtDate = this.commonUI.isoDateStringToDate(result.body['Data'].CutOffDate);
        this.calSelfLeaveBalance()
        this.commonUI.finishLoadingTask();
      }
      else
        this.onCallAPIFail(result.body, result.status, result.headers, result)
    }, err => {
      this.onCallAPIFail(err.body, err.status, err.headers, err)
    })
  }

  calSelfLeaveBalance() {
    this.commonUI.addLoadingTask();
    var employee = {
      RID: this.leaveapplication.StaffInfo.EmploymentRID,
      StaffID: this.leaveapplication.StaffInfo.StaffID,
      Position: this.leaveapplication.StaffInfo.PositionDesc
    }
    this.leaveService.calSelfLeaveBalance(
      employee, this.leaveapplication.LeaveTypeCode,
      this.commonUI.formatJsonDate(this.asAtDate, "YYYY-MM-DDT00:00:00")).subscribe((result) => {
        if (result.body['ErrorMsg'] == null) {
          this.leaveBalance = result.body['Data'].LeaveBalance;
          this.commonUI.finishLoadingTask()
        }
        else
          this.onCallAPIFail(result.body, result.status, result.headers, result)
      }, err => {
        this.onCallAPIFail(err.body, err.status, err.headers, err)
      })
  }

  onCallAPIFail(data, status, headers, config) {
    this.commonService.onCallAPIFail(data, status, headers, config);
  }
}